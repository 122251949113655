import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.iterator.js";
import "core-js/modules/web.timers.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import { log } from './gtm.utils';
var _layer = 'dataLayer';
var _id = '';
function gtmClient(ctx, initialized) {
  return {
    init: function init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id] || !window._gtm_inject) {
        return;
      }
      window._gtm_inject(id);
      initialized[id] = true;
      log('init', id);
    },
    push: function push(obj) {
      if (!window[_layer]) {
        window[_layer] = [];
      }
      window[_layer].push(obj);
      log('push', obj);
    }
  };
}
function gtmServer(ctx, initialized) {
  var events = [];
  var inits = [];
  ctx.beforeNuxtRender(function () {
    if (!inits.length && !events.length) {
      return;
    }
    var gtmScript = ctx.app.head.script.find(function (s) {
      return s.hid == 'gtm-script';
    });
    gtmScript.innerHTML = "window['".concat(_layer, "']=").concat(JSON.stringify(events), ";").concat(gtmScript.innerHTML);
    if (inits.length) {
      gtmScript.innerHTML += ";".concat(JSON.stringify(inits), ".forEach(function(i){window._gtm_inject(i)})");
    }
    var gtmIframe = ctx.app.head.noscript.find(function (s) {
      return s.hid == 'gtm-noscript';
    });
    var renderIframe = function renderIframe(id) {
      return "<iframe src=\"https://www.googletagmanager.com/ns.html?id=".concat(id, "&\" height=\"0\" width=\"0\" style=\"display:none;visibility:hidden\" title=\"gtm\"></iframe>");
    };
    if (inits.length) {
      gtmIframe.innerHTML += inits.map(renderIframe);
    }
  });
  return {
    init: function init() {
      var id = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : _id;
      if (initialized[id]) {
        return;
      }
      inits.push(id);
      initialized[id] = true;
      log('init', id);
    },
    push: function push(obj) {
      events.push(obj);
      log('push', JSON.stringify(obj));
    }
  };
}
function startPageTracking(ctx) {
  ctx.app.router.afterEach(function (to) {
    setTimeout(function () {
      ctx.$gtm.push(to.gtm || {
        routeName: to.name,
        pageType: 'PageView',
        pageUrl: '' + to.fullPath,
        pageTitle: typeof document !== 'undefined' && document.title || '',
        event: 'nuxtRoute'
      });
    }, 250);
  });
}
export default function (ctx, inject) {
  var runtimeConfig = ctx.$config && ctx.$config.gtm || {};
  var autoInit = false;
  var id = '';
  var runtimeId = runtimeConfig.id;
  var initialized = autoInit && id ? _defineProperty({}, id, true) : {};
  var $gtm = process.client ? gtmClient(ctx, initialized) : gtmServer(ctx, initialized);
  if (autoInit && runtimeId && runtimeId !== id) {
    $gtm.init(runtimeId);
  }
  ctx.$gtm = $gtm;
  inject('gtm', ctx.$gtm);
  if (process.client) {
    startPageTracking(ctx);
  }
}