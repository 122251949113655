import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/web.dom-collections.for-each.js";
import useBaseUtils from "~/functions/useBaseUtils";
var stage = process.env.LAMBDA_STAGE;
export default defineComponent({
  setup: function setup() {
    var _useBaseUtils = useBaseUtils(),
      dev = _useBaseUtils.dev,
      I18nFn = _useBaseUtils.I18nFn;
    return {
      dev: dev,
      I18nFn: I18nFn
    };
  },
  data: function data() {
    return {
      alignmentsAvailable: ["start", "center", "end", "baseline", "stretch"],
      alignment: "center",
      dense: false,
      justifyAvailable: ["start", "center", "end", "space-around", "space-between"],
      justify: "center"
    };
  },
  computed: {
    authDisplayMap: function authDisplayMap() {
      return this.$store.state.authDisplayMap || {};
    }
  },
  watch: {
    authDisplayMap: function authDisplayMap(newVal, oldVal) {
      if (stage === "dev") {
        console.log("authDisplayMap changed from:", oldVal);
        console.log("to: ", newVal);
      }
    }
  },
  mounted: function mounted() {
    var _this = this;
    try {
      var gtmDataLayerVariables = {};
      this.$store.state.analytics.marketingQueryParams.forEach(function (param) {
        var marketingParamValue = _this.$store.state.analytics[param];
        if (marketingParamValue) {
          gtmDataLayerVariables["unauthsession_".concat(param)] = marketingParamValue;
        }
      });
      if (Object.keys(gtmDataLayerVariables).length > 0) {
        window.dataLayer.push(gtmDataLayerVariables);
      } // this should always be false on unauth mount... right?
      if (!window.tikrgtmInit) {
        this.$gtm.init("GTM-N6PV66S");
        window.tikrgtmInit = true;
      } else if (Object.keys(gtmDataLayerVariables).length > 0) {
        this.$gtm.dataLayer.push(gtmDataLayerVariables);
      }
    } catch (error) {
      if (this.dev) {
        console.log("Error pushing dataLayer to window.dataLayer");
      }
    }
  }
});