import { useSlots } from "vue"

export default {
  __name: 'StepBase',
  setup(__props) {

const slots = useSlots()

return { __sfc: true,slots }
}

}