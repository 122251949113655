import { isOutsideNYSEMarketHours } from "~/utils/tools/date"

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const iexToken = process.env.IEX_TOKEN

const CDK_URL = process.env.API_URL

const state = () => ({
  lastQuotesLoading: false,
  lastQuotesError: null,
  lastQuotes: {},
  lastSingleStockQuote: {},
  SingleStockLoading: false,
  lastSingleStockQuoteLoading: false,
  liveWatchlistInterval: null,
  liveSingleStockInterval: null,
})

const mutations = {
  resetState(state) {
    state.lastQuoteLoading = false
    state.lastQuoteError = null
    state.lastQuotes = {}
    state.lastSingleStockQuote = {}
    state.SingleStockLoading = false
    state.liveWatchlistInterval = null
    state.liveSingleStockInverval = null
  },
  setState(state, { type, value }) {
    state[type] = value
  },
  setIEXFetch(state, { fetch, status }) {
    state[fetch] = status
  },
  setIEXError(state, { error, status }) {
    state[error] = status
  },
  // default values for object destructing only work when
  // missing property is undefined, null, false and 0 are values
  setLastIEXQuotes(state, { newQuotes = {}, stateKey = "lastQuotes" }) {
    state[stateKey] = { ...state[stateKey], ...newQuotes }
  },
  clearLastIEXQuotes(state, { stateKey = "lastQuotes" }) {
    // you're refreshing this object each time a new watchlist is pulled
    // ugh you should be caching clientside
    state[stateKey] = {}
  },
  updateIEXQuotes(state, { map, topsArr, stateKey }) {
    const newQuotes = topsArr.reduce((acc, top) => {
      try {
        const tid = map[top.symbol]
        if (tid) {
          const existingData = state[stateKey][tid]
          if (
            existingData &&
            top.lastSalePrice &&
            existingData.latestPrice !== top.lastSalePrice
          ) {
            existingData.latestPrice = top.lastSalePrice
            existingData.latestUpdate = top.lastSaleTime
            existingData.latestSource = "IEX real time price"
            const previousClose = existingData.previousClose
            const updatedChange = top.lastSalePrice - previousClose
            existingData.change = updatedChange
            existingData.changePercent = updatedChange / previousClose
            // existingData.changePercent
            acc[tid] = existingData
          }
        }
      } catch (error) {
        console.error("error updating iex tops quotes")
      }
      return acc
    }, {})
    state[stateKey] = { ...state[stateKey], ...newQuotes }
  },
  updateLastSingleStockQuote(state, payload) {
    const prevState = state.lastSingleStockQuote
    const newState = {
      latestPrice: payload.lastSalePrice,
      latestUpdate: payload.lastSaleTime,
    }
    state.lastSingleStockQuote = { ...prevState, ...newState }
  },
  setFetchInterval(state, { interval, intervalType }) {
    // this establishes a schema for intervals
    // intervalType either `Watchlist` or `SingleStockInterval`
    // live_Watchlist_Interval
    // live_SingleStock_Interval
    state[`live${intervalType}Interval`] = interval
  },
  clearFetchInterval(state, { intervalType }) {
    const interval = state[`live${intervalType}Interval`]
    if (interval) {
      clearInterval(interval)
      // FIXME: should this be undefined because of the obj destructing stuff?
      state[`live${intervalType}Interval`] = null
    }
  },
}

const actions = {
  // add a "type" to fetchlastIEXQuotes
  // that way you know what action to initiate afterwards
  // initWatchlist, addTicker, singleStock
  // this is not stateKey which is where to store direct results from api call
  async fetchLastIEXQuotes(
    { state, commit, dispatch },
    {
      tickerObjArr,
      intervalType = "Watchlist",
      stateKey = "lastQuotes",
      addTicker,
      clearLast = true,
      showLoading = true,
    }
  ) {
    if (isOutsideNYSEMarketHours()) {
      return
    }
    // stateKey is key on state where the results of IEX Quote will be saved
    // type is metadata that is passed to fetchLiveIEX quotes which is called after this
    // instructing
    // type also determines the location of the tickerObjArr that is stored
    //
    const t0 = performance.now()
    if (state[`${stateKey}Loading`]) {
      return
    }
    // TODO: Alan can you implement another guard here which early returns
    // this function if the day is saturday or sunday and then if it is
    // during the week early return if the time is more than 1 hour outside
    // of US market hours?

    try {
      // I am thinking... if I want to create an interval which calls
      // the iex/fetchLastIEXQuotes action then I can pass showLoading = false
      // to prevent the loading state from being shown
      if (showLoading) {
        commit("setIEXFetch", { fetch: `${stateKey}Loading`, status: true })
      }
      // create object mapping IEX Symbol to rest of identifiers
      // tickerObjArr is an array of results from rkdId / getmanyrkdid
      // filter tickerObjArr to only US stocks, add symbol key from RKD
      if (!addTicker && clearLast) {
        commit("clearLastIEXQuotes", { stateKey })
      }
      const rkdIdObjArr = tickerObjArr
        .filter((f) => f.ExchangeCountry === "USA" && f.symbol)
        .map((m) => {
          return { ...m, symbol: m.symbol }
        })
      const iexSymbolToId = generate_RKD_to_IEX_map(rkdIdObjArr)
      const ids = rkdIdObjArr.map((t) => ({ cid: t.cid, tid: Number(t.tid) }))
      if (ids.length > 0) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        const body = {
          auth: user.signInUserSession.idToken.jwtToken,
          ids,
        }

        const LASTQUOTE_URL = `${CDK_URL}/lastquote_it`

        const { data } = await this.$axios.post(LASTQUOTE_URL, body)

        const tidToIexObj = data.last.reduce((acc, q) => {
          try {
            const tid = iexSymbolToId[q.symbol]?.tid
            if (tid) {
              acc[tid] = q
              // do something here to save the rkd and cid information?
            }
          } catch (error) {
            console.error("error setting iex quotes")
          }
          return acc
        }, {})

        commit("setLastIEXQuotes", {
          stateKey,
          newQuotes: tidToIexObj,
        })
        // this is a guard, if the watchlist is open, and someone
        // has added a ticker to the watchlist because how the
        // interval captures the symbols to request it should be
        // captured in the scope.
        // also prevents the initiation of an interval when someone
        // adds a ticker to the watchlist using a heart
        if (addTicker) {
          return
        }

        // .isUSMarketOpen is no longer returned from the Lambdas using Intrinio
        // data so the following if block is now dead code.
        // I think this is okay, because the fetchLiveIEXQuotes action
        // uses an IEX endpoint that is going to be retired, so I can go ahead
        // and make a new endpoint which will ping our backend at some interval instead
        // of using the IEX TOPS endpoint.
        if (data.last && data.last.length > 0 && data.last[0].isUSMarketOpen) {
          // TODO: maintain an object that contains available tickers
          // Initiate TOPS fetch Interval updating application
          dispatch("fetchLiveIEXQuotes", {
            fetchObjsArr: data.last,
            intervalType,
            stateKey,
          })
        } else {
          // TODO: the market has closed, clear all fetch Intervals
          // commit("clearAllFetchIntervals") ... just call each I guess
          if (stage === devStage) {
            console.info("US Markets have closed, clearing all intervals")
          }
          commit("clearFetchInterval", { intervalType: "Watchlist" })
          commit("clearFetchInterval", { intervalType: "SingleStock" })
        }
      }
    } catch (error) {
      console.error(error)
      commit("setIEXError", {
        error: `${stateKey}Error`,
        status: {
          loc: "fetching quotes iex/fetchLastIEXQuotes",
          error,
        },
      })
    } finally {
      commit("setIEXFetch", { fetch: `${stateKey}Loading`, status: false })
      if (stage === devStage) {
        console.log(
          `fetch iex ${stateKey} for intervalType ${intervalType} took: ${
            performance.now() - t0
          } ms`
        )
      }
    }
  },
  // dispatch this function to initiate live fetching of quotes from IEX / Tops
  // should always be called after lastQuote pulled from IEX
  // should only be called during market hours
  fetchLiveIEXQuotes({ commit, state }, { intervalType, stateKey }) {
    try {
      // take fetchObjArr, add it to state.iexTickers
      // but really, state.iexTickers is an array of symbols..
      // maybe this should also contain the map
      // so state.iexTickers is an object {}
      // and from the objet I can generate [symbol1, symbol2]
      // and {tid: symbol2} for the saving?
      // so this is an object containing all actively fetched objects..
      // but this object, if built from fetchLastIEXQuotes..
      // yeah that should also only be US objects
      // is us / international objects important? you seem to be
      // spending a lot of time thinking about it
      commit("clearFetchInterval", { intervalType })
      // is fetchObjsArr the whole watchlist
      // or, is fetchObjArr just a new ticker that has been added to a watchlist
      // or is fetchObjsArr a single ticker in an array?
      // we need to find the complete list of desired tickers to fetch
      // the tops data for
      const intervalTime = 75000
      const iexTopsInterval = setInterval(async () => {
        try {
          const activeIntervalMap = state[stateKey]
          // ativeIntervalObject is {tid: {mappingObject}, tid1: {mappingObject2}}
          const iexSymbolToObj = {}
          const symbols = Object.keys(activeIntervalMap).map((tid) => {
            // I believe symbol is the correct iex symbol
            const symbol = activeIntervalMap[tid].symbol
            iexSymbolToObj[symbol] = tid
            return symbol
          })

          const baseUrl = "https://cloud.iexapis.com"
          const url = generateTopsUrl({ baseUrl, token: iexToken, symbols })

          const { data } = await this.$axios.get(url)
          if (data && data.length === 0) {
            // market closed, no more tops info, stop interval
            throw new Error("TOPS returned no data")
          }
          // still need the map to transform iex.symbol => tikr.tid
          commit("updateIEXQuotes", {
            map: iexSymbolToObj,
            topsArr: data,
            stateKey,
          })
        } catch (error) {
          // error has occured during execution of interval function
          // clear the interval
          commit("clearFetchInterval", { intervalType })
          clearInterval(iexTopsInterval) // or should this
          // call
        }
      }, intervalTime)

      commit("setFetchInterval", { interval: iexTopsInterval, intervalType }) // TODO: write this fn
    } catch (error) {
      console.error(`Error fetching live IEX quotes for ${intervalType}`, error)
    }
  },
  genericIEXAction({ commit }, { stateKey, errorKey }) {
    const t0 = performance.now()
    try {
      commit("setIEXFetch", { fetch: stateKey, status: true })
      // business logic here
    } catch (error) {
      console.error(error)
      commit("setIEXError", {
        error: errorKey,
        status: {
          loc: `iex/${"genericIEXAction"}`,
          error,
        },
      })
    } finally {
      commit("setIEXFetch", { fetch: stateKey, status: false })
      if (stage === devStage) {
        console.log(`fetch last iex quotes took: ${performance.now() - t0} ms`)
      }
    }
  },
  setIexState({ commit }, { type, value }) {
    // simple action to trigger a mutation as vuex5 moving
    // away from any mutations being called outside of store
    commit("setState", { type, value })
  },
}

const generate_RKD_to_IEX_map = (rkdResultArr) =>
  rkdResultArr.reduce((acc, r) => {
    acc[r.symbol] = r
    return acc
  }, {})

const generateTopsUrl = ({ baseUrl, symbols, token }) => {
  // const url = `${baseUrl}/stable/stock/${symbol}/quote` // last realtime
  // const url = `${baseUrl}/stable/stock/${symbol}/intraday-prices` // intraday
  const url = `${baseUrl}/stable/tops` // last realtime
  // return `${url}?chartIEXOnly=true&token=${token}` // this doesn't seem to work for GME?
  return `${url}?symbols=${symbols
    .map((s) => encodeURIComponent(s))
    .join(",")}&token=${token}` // this doesn't seem to work for GME?
}

export { state, mutations, actions }
