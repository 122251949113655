const toggles = {
  "google-sign-in": process.env.SETTINGS === "development",
  "due-diligence": process.env.SETTINGS === "development",
  "pe-valuation-model": process.env.SETTINGS === "development",
  "morningstar-ltm": process.env.SETTINGS === "development",
  "datasource-query-params": process.env.SETTINGS === "development",
  "algolia-company-icons": process.env.SETTINGS === "development",
  "use-cdk-user-data": process.env.SETTINGS === "development",
  "use-cdk-screener": process.env.SETTINGS === "development",
  "use-cdk-ciq": process.env.SETTINGS === "development",
  "use-cdk-trkd": process.env.SETTINGS === "development",
  "morningstar-excel-export": true,
  "show-morningstar-financials": true,
  "beats-and-misses": true,
  "filter-labels": true,
  "earnings-trends": true,
  "use-worker-fmp-financials": true,
  "use-worker-ciq-financials": true,
  "use-worker-ciq-valuation": true,
  "use-worker-ciq-estimates": true,
  "use-worker-ciq-actuals": true,
  "use-onboarding-flow": true,
  "show-price-factors": true,
  "show-sale-countdown": false,
  "dd-session-storage": process.env.SETTINGS === "development",
}

// Data sources
const SHOW_MORNINGSTAR_FINANCIALS = toggles["show-morningstar-financials"]
const MORNINGSTAR_LTM = toggles["morningstar-ltm"]

// CDK Feature Flags
const USE_CDK_SCREENER = toggles["use-cdk-screener"] // endpoints: fs
const USE_CDK_USER_DATA = toggles["use-cdk-user-data"] // endpoints: ga, c, d, u
const USE_CDK_CIQ = toggles["use-cdk-ciq"] // endpoints: fin, tidobj, price, est, daily
const USE_CDK_TRKD = toggles["use-cdk-trkd"] // endpoints: listReports, companynews, sigdevs, v, newsml, headlines, getDefaultDocs, ciqids, trkdids, manytrkdids

// Features
const GOOGLE_SIGN_IN = toggles["google-sign-in"]
const FILTER_LABELS = toggles["filter-labels"]
const BEATS_AND_MISSES = toggles["beats-and-misses"]
const EARNINGS_TRENDS = toggles["earnings-trends"]
const DATASOURCE_QUERY_PARAMS = toggles["datasource-query-params"]
const VALUATION_MODEL = toggles["pe-valuation-model"]
const USE_ONBOARDING_FLOW = toggles["use-onboarding-flow"]
const USE_ALGOLIA_COMPANY_LOGOS = toggles["algolia-company-icons"]
const SHOW_PRICE_FACTORS = toggles["show-price-factors"]
const USE_SESSION_STORAGE = toggles["dd-session-storage"]
const EXCEL_EXPORT = toggles["morningstar-excel-export"]

// Web Workers
const USE_WORKER_FMP_FINANCIALS = toggles["use-worker-fmp-financials"]
const USE_WORKER_CIQ_FINANCIALS = toggles["use-worker-ciq-financials"]
const USE_WORKER_CIQ_VALUATION = toggles["use-worker-ciq-valuation"]
const USE_WORKER_CIQ_ESTIMATES = toggles["use-worker-ciq-estimates"]
const USE_WORKER_CIQ_ACTUALS = toggles["use-worker-ciq-actuals"]

// Sales Countdown
const SHOW_SALE_COUNTDOWN = toggles["show-sale-countdown"]

export {
  toggles,
  SHOW_MORNINGSTAR_FINANCIALS,
  MORNINGSTAR_LTM,
  USE_CDK_SCREENER,
  USE_CDK_USER_DATA,
  USE_CDK_CIQ,
  USE_CDK_TRKD,
  BEATS_AND_MISSES,
  GOOGLE_SIGN_IN,
  EARNINGS_TRENDS,
  DATASOURCE_QUERY_PARAMS,
  FILTER_LABELS,
  USE_WORKER_FMP_FINANCIALS,
  USE_WORKER_CIQ_FINANCIALS,
  USE_WORKER_CIQ_VALUATION,
  USE_WORKER_CIQ_ESTIMATES,
  USE_WORKER_CIQ_ACTUALS,
  SHOW_PRICE_FACTORS,
  SHOW_SALE_COUNTDOWN,
  VALUATION_MODEL,
  USE_ONBOARDING_FLOW,
  USE_ALGOLIA_COMPANY_LOGOS,
  USE_SESSION_STORAGE,
  EXCEL_EXPORT,
}
