const operations = {
  "+": (a, b) => a + b,
  "-": (a, b) => a - b,
  "*": (a, b) => a * b,
  "/": (a, b) => a / b,
  ">": (a, b) => a >= b,
  "<": (a, b) => a <= b,
  "=": (a, b) => a === b,
}

const evaluate = (expression) => {
  let e = expression.slice()
  let grouped

  do {
    grouped = e.match(/\([^()]+\)/g)

    if (grouped && grouped.length) {
      grouped.forEach((group) => {
        const result = calc(group)
        e = e.replace(group, result)
      })
    }
  } while (grouped && grouped.length)

  return calc(e)
}

const calc = (expression) => {
  const normalizedExpression = normalize(expression)
  let entities = normalizedExpression.split(" ")

  const operator = entities.shift()

  if (!Object.keys(operations).includes(operator)) {
    throw new Error("Invalid expression")
  }

  entities = entities.map((item) => Number(item))
  const first = entities.shift()

  return entities.reduce((result, item) => {
    return operations[operator](result, item)
  }, first)
}

const isGroup = (expression) => {
  return (
    expression &&
    expression[0] === "(" &&
    expression[expression.length - 1] === ")"
  )
}

const normalize = (expression) => {
  if (!isGroup(expression)) {
    return expression
  }

  return expression.slice(1, -1)
}

export default evaluate
