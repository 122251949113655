import { first, last } from "lodash"
import { financialsScheduleArr, getJWT, isCacheValid } from "~/utils/tools"
import currencies from "~/utils/fmp/currencies"
import { defaultUsdObj } from "~/utils/constants/objects"
import { findClosestDateIndex, setDateRange } from "~/utils/ciq"

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const dev = devStage === stage
const CDK_URL = process.env.API_URL

const state = () => ({
  loading: {},
  addFinancials: {},
  financials: {},
  financialsError: null,
  financialsChart: {},
  financialsChartTickers: {},
  ticker: {},
  quoteCurrencyToggle: 0,
  quoteCurrencies: [],
  estimatesCurrencyToggle: 0,
  estimatesPeriodToggle: 0,
  multiplesCurrencies: [],
  multiplesCurrencyToggle: 0,
  financialsCurrencies: [],
  financialsCurrencyToggle: 0,
})

const periodMap = {
  a: "1",
  q: "2",
  semi: "10",
  cy: "7",
  ntm: "12",
  ltm: "4",
  ytd: "3",
}

const mutations = {
  setToggle(state, payload) {
    state[payload.type] = payload.value
  },
  setTicker(state, payload) {
    state.ticker = payload
  },
  setCurrencies(state, payload) {
    state[payload.type] = payload.value
  },
  setMorningstarFetch(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.loading = { ...state.loading, ...newObj }
  },
  setMorningstarError(state, payload) {
    state[payload.error] = payload.status
  },
  setMorningstarStateKey(state, { stateKey, value }) {
    state[stateKey] = value
  },
  resetMorningstar(state) {
    state.addFinancials = {}
    state.loading = {}
    state.financials = {}
    state.ticker = {}
    state.financialschart = {}
    state.financialsChartTickers = {}
    state.quoteCurrencyToggle = 0
    state.quoteCurrencies = []
    state.estimatesCurrencyToggle = 0
    state.estimatesPeriodToggle = 0
    state.multiplesCurrencies = []
    state.multiplesCurrencyToggle = 0
    state.financialsCurrencies = []
    state.financialsCurrencyToggle = 0
  },
  setFinancials(state, { period, data }) {
    const previousState = state.financials
    const newState = {}
    newState[period] = data
    state.financials = { ...previousState, ...newState }
  },
  addToChart(state, payload) {
    const chartType = payload.chartType // 'financialsChart', 'estimatesChart' or 'multiplesChart'
    const period = payload.period
    const previousState = state[chartType] // previous state of rows to add to chart
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const newRow = {}
    newRow[payload.rowId] = payload.row
    newState[period] = { ...previousPeriodState, ...newRow }

    state[chartType] = { ...previousState, ...newState }
  },
  addTableChartData(state, payload) {
    const type = payload.type || "Financials"
    const period = payload.period
    const oldPeriodState = state[`add${type}`][period] || {}
    oldPeriodState[payload.id] = payload.data
    const newState = {}
    newState[period] = { ...oldPeriodState }
    state[`add${type}`] = { ...state[`add${type}`], ...newState }
  },
  removeFromChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period
    const previousState = state[chartType]
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const reduceObj = {}

    const rowRemoved = Object.keys(previousPeriodState)
      .filter((f) => f !== payload.rowId)
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, reduceObj)

    newState[period] = rowRemoved

    state[chartType] = { ...previousState, ...newState }
  },
  toggleSeriesType(state, payload) {
    try {
      const chartType = payload.chartType
      const period = payload.period
      const rowId = payload.rowId
      const previousState = state[chartType]
      const previousPeriodState = state[chartType][period] || {}

      const newState = {}
      const newRow = {}

      newRow[rowId] = previousPeriodState[rowId]
      newRow[rowId].seriesType = payload.seriesType
      newState[period] = { ...previousPeriodState, ...newRow }

      state[chartType] = { ...previousState, ...newState }
    } catch (error) {
      console.error("error toggling chart seriesType: ", error)
    }
  },
  clearChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period
    const previousState = state[chartType]

    const newState = {}
    newState[period] = {}
    state[chartType] = { ...previousState, ...newState }
  },
  addTickerToTableChart(state, payload) {
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const period = payload.period
    const previousState = state[chartType] || {} // {p1: {}, p2: {}} whole state for all periods

    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {} // need to replace the state {period: {companyid: {data}}}
    const newPeriodState = {}

    if (chartType === "financialsChartTickers") {
      newPeriodState[payload.data.companyid] = payload.data
    } else {
      newPeriodState[payload.data.tradingitemid] = payload.data
    }

    newState[period] = { ...previousPeriodState, ...newPeriodState }

    state[chartType] = { ...previousState, ...newState } // newState: {periodType: {}}
  },
  removeTickerFromTableChart(state, payload) {
    // TODO: verify this works
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const previousState = state[chartType]
    const period = payload.period
    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {}

    // this function different from removeFromChart because the
    // period doesn't matter for the chart
    newState[period] = Object.keys(previousPeriodState)
      .filter((f) => {
        if (chartType === "financialsChartTickers") {
          return f !== payload.cid
        } else {
          return f !== payload.tid
        }
      })
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, {})

    state[chartType] = { ...previousState, ...newState } // just replace the existing key
  },
}

const actions = {
  initialLoad({ dispatch, commit, state }, payload) {
    if (state.ticker.companyid !== payload.companyid) {
      commit("resetMorningstar")
    }
    commit("setTicker", payload)
    dispatch("fetchFinancials", payload)
  },
  async fetchFinancials(
    { state, rootState, commit },
    {
      fetchKey = "financials",
      errorKey = "financialsError",
      companyid,
      tradingitemid,
      period = "a",
      selectedDateRange,
    }
  ) {
    commit("setMorningstarError", {
      status: null,
      error: errorKey,
    })
    commit("setMorningstarFetch", { key: fetchKey, status: true })
    const maxDate = ["q"].includes(period)

    const MorningstarDataAvailable =
      Object.hasOwn(rootState.ciq.ticker, "companyid") &&
      rootState.ciq.ticker.companyid === companyid &&
      Object.hasOwn(state.financials, period) &&
      state.financials?.[period]?.dates.length > 0

    if (MorningstarDataAvailable) {
      // TODO: check if the stored financials value is hot/cold
      const dates = state.financials[period].dates

      if (selectedDateRange?.length > 0) {
        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], dates),
              findClosestDateIndex(selectedDateRange[1], dates, maxDate),
            ],
          },
          { root: true }
        )
      } else {
        commit("setToggle", {
          type: `financialsDateRange`,
          value: setDateRange(dates, rootState.config.allPeriodsDefault),
        })
      }

      commit("setMorningstarFetch", { key: fetchKey, status: false })

      return
    }

    try {
      const jwt = await getJWT(this.$Amplify)

      const requestBody = {
        auth: jwt,
        p: periodMap[period],
        cid: companyid,
        tid: tradingitemid,
        cd: "mnc",
      }

      const { data: rawMorningstarFinancials } = await this.$axios.post(
        `${CDK_URL}/ms_fin`,
        requestBody
      )

      const finData = rawMorningstarFinancials

      const [firstMetric] = Object.values(rawMorningstarFinancials.resData)

      const [firstYear] = Object.values(firstMetric)

      finData.dates = rawMorningstarFinancials.dates.map((date) => ({
        u: firstYear.u,
        dateKey: date.value,
        dateStr: date.dateStr,
        exchangerate: date.exchangerate,
        dateEpoch: `${date.dateStr}T00:00:00.000Z`,
        pc: date.priceclose,
        yearStr: date.fiscalyear,
        quarterStr: `Q${date.fiscalquarter}`,
        iso: date.currencyid,
        isocode: date.currencyid,
        timeVal: date.timeVal,
        value: date.value,
        isLTM: date.attachedLTM,
      }))

      const currenciesArr = finData.dates.reduce((acc, date) => {
        if (acc.filter((i) => i.iso === date.iso).length === 0) {
          acc.push(date)
        }
        return acc
      }, [])

      const firstItemISO = first(currenciesArr).iso || "USD"
      const lastItemISO = last(currenciesArr).iso || "USD"

      const firstCurrency = {
        code: firstItemISO,
        name: currencies[firstItemISO],
      }

      const lastCurrency = {
        code: lastItemISO,
        name: currencies[lastItemISO],
      }

      if (currenciesArr.length > 1) {
        // FIXME: reported currency changes
        lastCurrency.code = "MIXED"
        lastCurrency.name = `Mixed ${lastCurrency.name} & ${firstCurrency.name}`
      }

      if (lastCurrency.code === "USD") {
        commit("setCurrencies", {
          type: "financialsCurrencies",
          value: [lastCurrency],
        })
      } else {
        commit("setCurrencies", {
          type: "financialsCurrencies",
          value: [lastCurrency, defaultUsdObj],
        })
      }

      commit("setFinancials", {
        data: finData,
        period,
      })

      if (selectedDateRange?.length > 0) {
        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], finData.dates),
              findClosestDateIndex(selectedDateRange[1], finData.dates),
            ],
          },
          { root: true }
        )
      }
    } catch (error) {
      if (dev) {
        commit("resetMorningstar")
        console.error(JSON.stringify(error))
      }
      commit("setMorningstarError", {
        status: {
          error,
          loc: "fetching financials",
        },
        error: errorKey,
      })
    } finally {
      commit("setMorningstarFetch", { key: fetchKey, status: false })
    }
  },
  async tableChartOneStep({ commit, state }, payload) {
    const type = payload.type
    const chartType = `financialsChart`
    const period = payload.period || "a"
    const t0 = performance.now()
    const scheduleArray = financialsScheduleArr
    try {
      commit("setMorningstarError", {
        status: null,
        error: "addFinancialsError",
      })

      commit("addTickerToTableChart", {
        chartType,
        period,
        data: payload.ticker,
      })

      const now = new Date()

      commit("setMorningstarFetch", {
        fetch: `fetchingAdd${type}`,
        status: true,
      })
      const prevPeriodState = state.addFinancials?.[period] || {}

      const oldData =
        type === "Financials"
          ? prevPeriodState[payload.ticker.companyid]
          : prevPeriodState[payload.ticker.tradingitemid]

      if (oldData?.f && isCacheValid(oldData.f, now, scheduleArray)) {
        // this tradingItem already exists on the state with data
        // that is valid, no need to fetch
        return
      }

      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      commit(
        "updateCurrentUser",
        { user, from: "ciq initialLoad" },
        { root: true }
      )

      if (type === "Financials") {
        const { data } = await fetchAllFinancials({
          axios: this.$axios,
          user,
          period,
          ...payload.ticker,
        })
        data.f = now
        commit("addTableChartData", {
          id: payload.ticker.companyid,
          period,
          type,
          data,
        })
      }
    } catch (error) {
      console.error(error)
      commit("setMorningstarError", {
        status: {
          error,
          loc: `fetching additional ${type} for tableChartOneStep`,
        },
        error: `add${type}Error`,
      })
    } finally {
      commit("setMorningstarFetch", {
        fetch: `fetchingAdd${type}`,
        status: false,
      })
      if (dev) {
        console.log(
          `additional ${type} fetch took ${performance.now() - t0} ms`
        )
      }
    }
  },
}

const fetchAllFinancials = async ({
  period,
  companyid,
  tradingitemid,
  user,
  axios,
}) => {
  const jwt = user.signInUserSession.idToken.jwtToken

  const requestBody = {
    auth: jwt,
    p: periodMap[period],
    cid: companyid,
    tid: tradingitemid,
    cd: "mnc",
  }

  const { data: rawMorningstarFinancials } = await axios.post(
    `${CDK_URL}/ms_fin`,
    requestBody
  )

  const finData = rawMorningstarFinancials

  const [firstMetric] = Object.values(rawMorningstarFinancials.resData)

  const [firstYear] = Object.values(firstMetric)

  finData.dates = rawMorningstarFinancials.dates.map((date) => ({
    u: firstYear.u,
    dateKey: date.value,
    dateStr: date.dateStr,
    exchangerate: date.exchangerate,
    dateEpoch: `${date.dateStr}T00:00:00.000Z`,
    pc: date.priceclose,
    yearStr: date.fiscalyear,
    quarterStr: `Q${date.fiscalquarter}`,
    iso: date.currencyid,
    isocode: date.currencyid,
    timeVal: date.timeVal,
    value: date.value,
    isLTM: date.attachedLTM,
  }))

  const finPeriodToCalPeriod = finData.dates.reduce((acc, d) => {
    acc[d.value] = `${d.dateKey}`
    return acc
  }, {})

  const finRes = finData.financialsLineItems.reduce((outAcc, lineitems) => {
    const result = lineitems.keys
      .filter((f) => f.formula !== "h3")
      .reduce((acc, row) => {
        const rowId = row.tikrKey
        const name = row.name.trim()
        // this is assuming no overlap on actuals and estimate periods
        const financials = Object.keys(finPeriodToCalPeriod).reduce(
          (acc, key) => {
            const a = row[key]
            const newPeriodId = finPeriodToCalPeriod[key]
            if (newPeriodId && a) {
              acc[newPeriodId] = a
            }
            return acc
          },
          {}
        )
        financials.name = name

        acc[rowId] = { ...row, ...financials }
        return acc
      }, {})
    return { ...outAcc, ...result }
  }, {})

  const chartData = Object.entries(finRes).reduce(
    (accum, metric) => {
      const [keyName, metricObj] = metric
      accum.keyToName[keyName] = metricObj.name.trim()
      accum.nameToKey[metricObj.name.trim()] = keyName
      return accum
    },
    { keyToName: {}, nameToKey: {} }
  )

  if (finData.dates && finData.dates.length > 0) {
    return {
      data: { ...finData, ...chartData },
    }
  }

  throw new Error("Sorry, no financials for this ticker")
}

export { state, mutations, actions }
