const SLS_CIQ_ALT_URL = process.env.CIQ_LAMBDA_URL
const stage = process.env.LAMBDA_STAGE

const restrictResponse = process.env.USE_TIERS === "true" // TODO: Change me to true for production

export const fetchByCidTid = async ({ $axios, $AmplifyObj, tickers }) => {
  // const WATCHLIST_PRICE_URL = USE_CDK_CIQ
  //   ? `${CDK_URL}/wlp`
  //   : `${SLS_CIQ_ALT_URL}/${stage}/wlp`
  const WATCHLIST_PRICE_URL = `${SLS_CIQ_ALT_URL}/${stage}/wlp`

  const user = await $AmplifyObj.Auth.currentAuthenticatedUser()
  const jwt = await user.signInUserSession.idToken.jwtToken

  const body = {
    auth: jwt,
    tickers,
    v: restrictResponse ? "v1" : "v0",
  }

  const { data, error } = await $axios.post(WATCHLIST_PRICE_URL, body)
  if (error) {
    return { error }
  }
  return { data, error: false }
}
