import "core-js/modules/es.number.constructor.js";
import "core-js/modules/web.timers.js";
import { nextTick, useSlots } from "vue";
import useBaseUtils from "~/functions/useBaseUtils";
export default {
  __name: 'StyledDialog',
  props: {
    title: {
      type: String,
      "default": ""
    },
    visible: {
      type: Boolean,
      "default": false
    },
    overlayColor: {
      type: String,
      "default": ""
    },
    overlayOpacity: {
      type: Number,
      "default": 0
    },
    scrollable: {
      type: Boolean,
      "default": false
    },
    width: {
      type: [String, Number],
      "default": ""
    },
    contentProps: {
      type: Object,
      "default": function _default() {}
    },
    actionsProps: {
      type: Object,
      "default": function _default() {}
    },
    closeBtnWithinTitleSection: {
      type: Boolean,
      "default": false
    },
    autofocus: {
      type: Boolean,
      "default": true
    },
    outsideCloseBtnClass: {
      type: String,
      "default": "pt-3 pr-3"
    }
  },
  emits: ["update:visible"],
  setup: function setup(__props, _ref) {
    var emit = _ref.emit;
    var props = __props;
    var _useBaseUtils = useBaseUtils(),
      darkActive = _useBaseUtils.darkActive,
      I18nFn = _useBaseUtils.I18nFn;
    var slots = useSlots();
    var leastDestructiveRef = ref(null);
    var focusOnLeastDestructiveElement = function focusOnLeastDestructiveElement() {
      if (leastDestructiveRef.value) {
        setTimeout(function () {
          var _leastDestructiveRef$;
          (_leastDestructiveRef$ = leastDestructiveRef.value) === null || _leastDestructiveRef$ === void 0 || (_leastDestructiveRef$ = _leastDestructiveRef$.$el) === null || _leastDestructiveRef$ === void 0 || _leastDestructiveRef$.focus();
        }, 100); // its awkward... but it works
      }
    };
    watch(function () {
      return props.visible;
    }, function (val) {
      if (val && props.autofocus) {
        nextTick(function () {
          focusOnLeastDestructiveElement();
        });
      }
    }, {
      immediate: true
    });
    var overlayBgColor = computed(function () {
      if (props.overlayColor) {
        return props.overlayColor;
      }
      return darkActive.value ? "rgb(38, 38, 38)" : "rgb(50, 50, 50)";
    });
    var overlayOpacity = computed(function () {
      if (props.overlayOpacity) {
        return props.overlayOpacity;
      }
      return darkActive.value ? 0.85 : 0.5;
    }); // Computed property to check if the "actions" slot exists
    var hasActionsSlot = computed(function () {
      return !!slots.actions;
    });
    var onClose = function onClose() {
      emit("update:visible", false);
    };
    var onChange = function onChange() {
      emit("update:visible", !props.visible);
    };
    var onClickOutside = function onClickOutside() {
      onChange();
    };
    return {
      __sfc: true,
      darkActive: darkActive,
      I18nFn: I18nFn,
      props: props,
      slots: slots,
      emit: emit,
      leastDestructiveRef: leastDestructiveRef,
      focusOnLeastDestructiveElement: focusOnLeastDestructiveElement,
      overlayBgColor: overlayBgColor,
      overlayOpacity: overlayOpacity,
      hasActionsSlot: hasActionsSlot,
      onClose: onClose,
      onChange: onChange,
      onClickOutside: onClickOutside
    };
  }
};