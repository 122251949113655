import { isOutsideNYSEMarketHours } from "~/utils/tools/date"

const stage = process.env.LAMBDA_STAGE
const devStage = stage === "dev"

const CDK_URL = process.env.API_URL

const tickerArr = [
  { tid: "6179710", cid: "6160262", iex: "SPY", name: "S&P 500 (SPY)" },
  { tid: "37284618", cid: "8108558", iex: "QQQ", name: "NASDAQ (QQQ)" },
  { tid: "6179254", cid: "6184218", iex: "DIA", name: "Dow Jones (DIA)" },
  {
    tid: "6179419",
    cid: "8797658",
    iex: "IWM",
    name: "Russell 2000 (IWM)",
  },
]

const state = () => ({
  loading: {},
  error: {},
  lastQuote: {},
  ts: {},
  activeSymbols: [],
  intervalArrObj: {},
  interval: {},
  movers: {
    active: [],
    gainers: [],
    losers: [],
  },
})

const mutations = {
  setIexFetch(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.loading = { ...state.loading, ...newObj }
  },
  setIexError(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.error = { ...state.error, ...newObj }
  },
  setIexStateKey(state, { stateKey, value }) {
    state[stateKey] = value
  },
  setLastIEXQuotes(state, { newQuotes = {}, stateKey = "lastQuote" }) {
    state[stateKey] = { ...state[stateKey], ...newQuotes }
  },
  addSymbolsToIntervalArr(state, { symbols, intervalKey }) {
    const symbolObj = symbols.reduce((acc, s) => {
      acc[s] = s
      return acc
    }, {})
    state.intervalArrObj[intervalKey] = {
      ...state.intervalArrObj[intervalKey],
      ...symbolObj,
    }
  },
  updateIEXQuotes(state, { topsArr, stateKey = "lastQuote" }) {
    const newQuotes = topsArr.reduce((acc, top) => {
      try {
        const key = top.symbol
        if (key) {
          const existingData = state[stateKey][key] || {}
          if (
            top.lastSalePrice &&
            existingData.latestPrice !== top.lastSalePrice
          ) {
            existingData.latestPrice = top.lastSalePrice
            existingData.latestUpdate = top.lastSaleTime
            existingData.latestSource = "IEX real time price"
            const previousClose = existingData.previousClose
            const updatedChange = top.lastSalePrice - previousClose
            existingData.change = updatedChange
            existingData.changePercent = updatedChange / previousClose
            acc[key] = existingData
          }
        }
      } catch (error) {
        console.error("error updating iex tops quotes")
      }
      return acc
    }, {})
    state[stateKey] = { ...state[stateKey], ...newQuotes }
  },
  setIexInterval(state, { interval, intervalKey }) {
    state.interval[intervalKey] = interval
  },
  clearIexInterval(state, { intervalKey }) {
    const interval = state.interval[intervalKey]
    if (interval) {
      clearInterval(interval)
      state.interval[intervalKey] = null
    }
  },
}

const actions = {
  async marketsMounted({ commit, dispatch }, { tickers, tops }) {
    const t0 = performance.now()
    const user = await this.$Amplify.Auth.currentAuthenticatedUser()
    // is this an initial fetch or a fetch update?
    const fetchKey = "overview"
    const errorKey = "overview"
    commit("setIexError", { key: errorKey, status: false })
    commit("setIexFetch", { key: fetchKey, status: true })

    // change tickers to only be this.tickerArr so IexPriceChange boxes @top
    dispatch("fetchLastQuote", {
      symbolArr: tickers
        .map((m) => (m.iex ? m.iex : m.symbol))
        .filter((f) => f),
      idArr: tickers
        .map((m) => ({ cid: +m.cid, tid: +m.tid }))
        .filter((f) => f),
      showLoading: true,
    })

    try {
      const { movers, moversObj, moversArr, iexObj } = await fetchOverview({
        user,
        fetchKey,
        $axios: this.$axios,
      })
      commit("setIexStateKey", { stateKey: "movers", value: movers })
      if (iexObj) {
        commit("setLastIEXQuotes", { newQuotes: iexObj })
      }
      commit("setLastIEXQuotes", { newQuotes: moversObj })
      // TODO: add tickers and moversArr to intervalObj
      // TODO: create tops interval
      // .isUSMarketOpen no longer returned from the Lambdas using Intrinio
      if (moversArr.length > 0) {
        const symbolsSet = new Set()
        moversArr.forEach((m) => symbolsSet.add(m.symbol))
        tickers.forEach((m) => {
          const symbol = m.iex ? m.iex : m.symbol
          symbolsSet.add(symbol)
        })
        tops.forEach((s) => symbolsSet.add(s))
        const symbolsArr = Array.from(symbolsSet)
        commit("addSymbolsToIntervalArr", {
          symbols: symbolsArr,
          intervalKey: fetchKey,
        })
        dispatch("createInterval", {
          intervalKey: fetchKey,
        })
      } else if (devStage) {
        console.info("US Markets have closed, clearing all intervals")
      }
    } catch (error) {
      commit("setIexError", { key: errorKey, status: error })
    } finally {
      commit("setIexFetch", { key: fetchKey, status: false })
      if (devStage) {
        console.log(`marketsMounted took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchLastQuote(
    { commit },
    {
      symbolArr,
      idArr,
      fetchKey = "lastQuote",
      errorKey = "lastQuote",
      fetchType = "init",
      showLoading = false,
    }
  ) {
    if (isOutsideNYSEMarketHours() && fetchType !== "init") {
      return
    }
    const t0 = performance.now()
    const user = await this.$Amplify.Auth.currentAuthenticatedUser()
    commit("setIexError", { key: errorKey, status: false })
    if (showLoading) {
      commit("setIexFetch", { key: fetchKey, status: true })
    }
    try {
      const body = {
        auth: user.signInUserSession.idToken.jwtToken,
        fetchType,
        ids: idArr,
      }

      const LASTQUOTE_URL = `${CDK_URL}/lastquote_it`

      const { data } = await this.$axios.post(LASTQUOTE_URL, body)

      const iexObj = data.last.reduce((acc, q) => {
        acc[q.symbol] = q
        return acc
      }, {})

      commit("setLastIEXQuotes", {
        newQuotes: iexObj,
      })
    } catch (error) {
      commit("setIexError", { key: errorKey, status: error })
    } finally {
      commit("setIexFetch", { key: fetchKey, status: false })
      if (devStage) {
        console.log(`marketsMounted took ${performance.now() - t0} ms`)
      }
    }
  },
  async createInterval(
    { commit, state, dispatch },
    {
      intervalTime = 75000,
      intervalKey,
      tickers = tickerArr,
      fetchKey = "",
      errorKey = "",
    }
  ) {
    const user = await this.$Amplify.Auth.currentAuthenticatedUser()
    commit("setIexError", { key: errorKey, status: false })
    commit("setIexFetch", { key: fetchKey, status: true })
    try {
      // TODO @alan add an early return to this function if the market is closed
      // because it is a saturday, sunday, or weekday outside of market hours
      if (isOutsideNYSEMarketHours()) {
        return
      }
      const iexTopsInterval = setInterval(async () => {
        if (devStage) {
          console.log(`overview fetch interval ${intervalKey}`)
        }

        dispatch("fetchLastQuote", {
          symbolArr: tickers
            .map((m) => (m.iex ? m.iex : m.symbol))
            .filter((f) => f),
          idArr: tickers
            .map((m) => ({ cid: +m.cid, tid: +m.tid }))
            .filter((f) => f),
          showLoading: false,
          fetchType: "interval",
        })

        const { movers, moversObj, iexObj } = await fetchOverview({
          user,
          fetchKey,
          $axios: this.$axios,
        })
        commit("setIexStateKey", { stateKey: "movers", value: movers })
        if (iexObj) {
          commit("setLastIEXQuotes", { newQuotes: iexObj })
        }
        commit("setLastIEXQuotes", { newQuotes: moversObj })
      }, intervalTime)

      dispatch("fetchLastQuote", {
        symbolArr: tickers
          .map((m) => (m.iex ? m.iex : m.symbol))
          .filter((f) => f),
        idArr: tickers
          .map((m) => ({ cid: +m.cid, tid: +m.tid }))
          .filter((f) => f),
        showLoading: false,
        fetchType: "interval",
      })

      const { movers, moversObj, iexObj } = await fetchOverview({
        user,
        fetchKey,
        $axios: this.$axios,
      })
      commit("setIexStateKey", { stateKey: "movers", value: movers })
      if (iexObj) {
        commit("setLastIEXQuotes", { newQuotes: iexObj })
      }
      commit("setLastIEXQuotes", { newQuotes: moversObj })

      commit("clearIexInterval", { intervalKey })
      commit("setIexInterval", { intervalKey, interval: iexTopsInterval }) // TODO: write this fn
    } catch (error) {
      console.error("error creating interval: ", error)
      commit("setIexError", { key: errorKey, status: error })
    } finally {
      commit("setIexFetch", { key: fetchKey, status: false })
    }
  },
  clearInterval({ commit }, { intervalKey }) {
    if (devStage) {
      console.log(`clear interval ${intervalKey}`)
    }

    commit("clearIexInterval", { intervalKey })
  },
}

const fetchOverview = async ({ user, fetchKey, $axios }) => {
  const body = {
    auth: user.signInUserSession.idToken.jwtToken,
    type: fetchKey,
  }
  // fetch movers - mostActive, gainers, losers from /overview
  const OVERVIEW_URL = `${CDK_URL}/overview_it`

  const { data } = await $axios.post(OVERVIEW_URL, body)

  // data.overviewQuotes
  const now = new Date()
  const movers = {
    active: data.active,
    gainers: data.gainers,
    losers: data.losers,
    time: now.toISOString(),
  }

  const moversArr = [...data.active, ...data.gainers, ...data.losers]
  const moversObj = moversArr.reduce((acc, q) => {
    acc[q.symbol] = q
    return acc
  }, {})

  if (data.overview) {
    const iexObj = data.overview.reduce((acc, q) => {
      acc[q.symbol] = q
      return acc
    }, {})
    return { iexObj, movers, moversObj, moversArr }
  }
  return { movers, moversObj, moversArr }
}

export { state, mutations, actions }
