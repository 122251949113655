import dayjs from "~/utils/tools/dayjs"
import { convertToUsdObject, scaleCiqValueObject } from "~/utils/values/scale"
import {
  findFirstAvailableItemInObject,
  getIsSameIso,
} from "~/utils/tools/object"

/**
 * @function calculateCAGR
 *
 * @summary Compute the compound annual growth rate of a portfolio.
 *
 * @description This function returns compound annual growth rate of a portfolio, provided as an
 * equity curve together with its associated valuation year difference.
 *
 * The compound annual growth rate of a portfolio is defined as the geometric progression ratio
 * that provides a constant rate of return over the period on which the portfolio valuations are provided, c.f. the reference.
 *
 *
 * The algorithm automatically computes the number of (calendar) days between the first portfolio valuation date
 * and the last portfolio valuation date, which is then converted into a number of years for the cagr computation following the
 * formula of the reference.
 *
 * @see <a href="https://www.investopedia.com/terms/c/cagr.asp">https://www.investopedia.com/terms/c/cagr.asp</a>
 * @see <a href="https://en.wikipedia.org/wiki/Compound_annual_growth_rate">https://en.wikipedia.org/wiki/Compound_annual_growth_rate</a>
 *
 * @param {Object} portfolio An object with the following properties:
 * @param {number} porfolio.initialValue the initial value of the portfolio
 * @param {number} portfolio.lastValue the final value of the portfolio
 * @param {number} portfolio.firstDate the first date of the portfolio
 * @param {number} portfolio.lastDate the final date of the portfolio
 * @returns {Object} result An object with the following properties:
 * @returns {number} result.cagr The compound annual growth rate of the portfolio expressed as percentage
 * @returns {number} result.yearDiffThe The number of years between the first valuation date and the last valuation date
 * @returns {number} result.valueChange The percentage change in value of the investment
 *
 * @example
 * calculateCAGR({ initialValue: 1.0,
 *                 lastValue: 1.2,
 *                 firstDate: 1451527200000,
 *                 finalDate: 1514685600000 });
 * // 0.095, i.e. 9.5% cagr over two years
 */
const calculateCAGR = ({ initialValue, lastValue, firstDate, lastDate }) => {
  const yearDiff = dayjs(lastDate).diff(dayjs(firstDate), "years", true)

  return {
    cagr: Math.pow(lastValue / initialValue, 1 / yearDiff) - 1,
    valueChange: lastValue / initialValue - 1,
    yearDiff,
  }
}

const getObjectNormalized = (object, isSameIso) => {
  const isoVal = findFirstAvailableItemInObject({
    item: object,
    keys: ["iso", "isocode"],
    defaultEmpty: "iso",
  })
  if (isSameIso || isoVal === "USD") {
    return object
  }

  return convertToUsdObject(object)
}

const calculateCiqDivision = ({ numerator, denominator }) => {
  const isSameIso = getIsSameIso([numerator, denominator])
  const normalizedNumerator = getObjectNormalized(numerator, isSameIso)
  const normalizedDenominator = getObjectNormalized(denominator, isSameIso)

  const scaledNumerator = scaleCiqValueObject({
    dataObj: normalizedNumerator,
    isEstimateUnitId: numerator?.est,
  })
  const scaledDenominator = scaleCiqValueObject({
    dataObj: normalizedDenominator,
    isEstimateUnitId: denominator?.est,
  })

  return scaledNumerator / scaledDenominator
}

export { calculateCAGR, getObjectNormalized, calculateCiqDivision }
