const awsmobile = {
    "aws_project_region": process.env.TIKR_AWS_REGION,
     Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        // I believe this is required to enable pinpoint
        identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,

        // REQUIRED - Amazon Cognito Region
        region: process.env.TIKR_AWS_REGION,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        // identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: process.env.AWS_USER_POOL_ID,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: process.env.AWS_USER_POOL_APP_CLIENT_ID,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,
        aws_cognito_social_providers: ["GOOGLE", "FACEBOOK"],
        oauth: {
            domain: process.env.CUSTOM_COGNITO_DOMAIN,
            scope: [
                "email",
                // "openid",
                "profile",
                "aws.cognito.signin.user.admin"
            ], // what should go here for scope...
            redirectSignIn: process.env.OAUTH_SIGNIN_REDIRECT,
            redirectSignOut: process.env.OAUTH_SIGNOUT_REDIRECT,
            responseType: "code", // what are the available values here?
        }
    },
    "aws_appsync_graphqlEndpoint": process.env.AWS_APPSYNC_GQL_ENDPOINT,
    "aws_appsync_region": process.env.TIKR_AWS_REGION,
    "aws_appsync_authenticationType": process.env.AWS_APPSYNC_AUTH_TYPE
};

export const baseAnalytics = {
        // OPTIONAL - disable Analytics if true
        disabled: false,
        // OPTIONAL - Allow recording session events. Default is true.
        autoSessionRecord: true,

        AWSPinpoint: {
            // OPTIONAL -  Amazon Pinpoint App Client ID
            appId: process.env.AWS_PINPOINT_APPID,
            // OPTIONAL -  Amazon service region
            region: process.env.TIKR_AWS_REGION,

            // Buffer settings used for reporting analytics events.
            // OPTIONAL - The buffer size for events in number of items.
            // bufferSize: 1000,

            // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
            // flushInterval: 5000, // 5s

            // OPTIONAL - The number of events to be deleted from the buffer when flushed.
            // flushSize: 100,

            // OPTIONAL - The limit for failed recording retries.
            // resendLimit: 5
        }
    }

/**
 *
    "aws_cognito_region": process.env.TIKR_AWS_REGION,
    "aws_user_pools_id": process.env.AWS_USER_POOL_ID,
    "aws_user_pools_web_client_id": process.env.AWS_USER_POOL_APP_CLIENT_ID,
 */

// const oldawsmobile = {
//      Auth: {
//         // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
//         // I believe this is required to enable pinpoint
//         identityPoolId: process.env.AWS_COGNITO_IDENTITY_POOL_ID,

//         // REQUIRED - Amazon Cognito Region
//         region: process.env.TIKR_AWS_REGION,

//         // OPTIONAL - Amazon Cognito Federated Identity Pool Region
//         // Required only if it's different from Amazon Cognito Region
//         // identityPoolRegion: 'XX-XXXX-X',

//         // OPTIONAL - Amazon Cognito User Pool ID
//         userPoolId: process.env.AWS_USER_POOL_ID,

//         // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
//         userPoolWebClientId: process.env.AWS_USER_POOL_APP_CLIENT_ID,

//         // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
//         mandatorySignIn: false,
//     },
//      Analytics: {
//         // OPTIONAL - disable Analytics if true
//         // disabled: false,
//         // OPTIONAL - Allow recording session events. Default is true.
//         autoSessionRecord: true,

//         AWSPinpoint: {
//             // OPTIONAL -  Amazon Pinpoint App Client ID
//             appId: process.env.AWS_PINPOINT_APPID,
//             // OPTIONAL -  Amazon service region
//             region: process.env.TIKR_AWS_REGION,
//             // OPTIONAL -  Customized endpoint
//             // endpointId: 'XXXXXXXXXXXX',
//             // OPTIONAL - Default Endpoint Information
//             // endpoint: {
//             //     // address: 'xxxxxxx', // The unique identifier for the recipient. For example, an address could be a device token, email address, or mobile phone number.
//             //     // attributes: {
//             //     //     // Custom attributes that your app reports to Amazon Pinpoint. You can use these attributes as selection criteria when you create a segment.
//             //     //     hobbies: ['piano', 'hiking'],
//             //     // },
//             //     // channelType: 'APNS', // The channel type. Valid values: APNS, GCM
//             //     // demographic: {
//             //     //     appVersion: 'xxxxxxx', // The version of the application associated with the endpoint.
//             //     //     locale: 'xxxxxx', // The endpoint locale in the following format: The ISO 639-1 alpha-2 code, followed by an underscore, followed by an ISO 3166-1 alpha-2 value
//             //     //     make: 'xxxxxx', // The manufacturer of the endpoint device, such as Apple or Samsung.
//             //     //     model: 'xxxxxx', // The model name or number of the endpoint device, such as iPhone.
//             //     //     modelVersion: 'xxxxxx', // The model version of the endpoint device.
//             //     //     platform: 'xxxxxx', // The platform of the endpoint device, such as iOS or Android.
//             //     //     platformVersion: 'xxxxxx', // The platform version of the endpoint device.
//             //     //     timezone: 'xxxxxx' // The timezone of the endpoint. Specified as a tz database value, such as Americas/Los_Angeles.
//             //     // },
//             //     // location: {
//             //     //     city: 'xxxxxx', // The city where the endpoint is located.
//             //     //     country: 'xxxxxx', // The two-letter code for the country or region of the endpoint. Specified as an ISO 3166-1 alpha-2 code, such as "US" for the United States.
//             //     //     latitude: 0, // The latitude of the endpoint location, rounded to one decimal place.
//             //     //     longitude: 0, // The longitude of the endpoint location, rounded to one decimal place.
//             //     //     postalCode: 'xxxxxx', // The postal code or zip code of the endpoint.
//             //     //     region: 'xxxxxx' // The region of the endpoint location. For example, in the United States, this corresponds to a state.
//             //     // },
//             //     // metrics: {
//             //     //     // Custom metrics that your app reports to Amazon Pinpoint.
//             //     // },
//             //     /** Indicates whether a user has opted out of receiving messages with one of the following values:
//             //      * ALL - User has opted out of all messages.
//             //      * NONE - Users has not opted out and receives all messages.
//             //      */
//             //     optOut: 'ALL',
//             //     // Customized userId
//             //     // userId: 'XXXXXXXXXXXX',
//             //     // User attributes
//             //     // userAttributes: {
//             //     //     interests: ['football', 'basketball', 'AWS']
//             //     //     // ...
//             //     // }
//             // },

//             // Buffer settings used for reporting analytics events.
//             // OPTIONAL - The buffer size for events in number of items.
//             bufferSize: 1000,

//             // OPTIONAL - The interval in milliseconds to perform a buffer check and flush if necessary.
//             flushInterval: 5000, // 5s

//             // OPTIONAL - The number of events to be deleted from the buffer when flushed.
//             flushSize: 100,

//             // OPTIONAL - The limit for failed recording retries.
//             resendLimit: 5
//         }
//     },
//     "aws_project_region": process.env.TIKR_AWS_REGION,                      // AUTH
//     "aws_cognito_region": process.env.TIKR_AWS_REGION,                      // AUTH
//     "aws_user_pools_id": process.env.AWS_USER_POOL_ID,                      // AUTH
//     "aws_user_pools_web_client_id": process.env.AWS_USER_POOL_APP_CLIENT_ID, // AUTH
//     "aws_appsync_graphqlEndpoint": process.env.AWS_APPSYNC_GQL_ENDPOINT,    // API
//     "aws_appsync_region": process.env.TIKR_AWS_REGION,                      // API
//     "aws_appsync_authenticationType": process.env.AWS_APPSYNC_AUTH_TYPE     // API
// };

// const oldhardawsmobile = {
//     "aws_project_region": process.env.TIKR_AWS_REGION,
//     "aws_cognito_identity_pool_id": "us-east-1:a1859103-f0fd-4bd5-88ad-8a469ddd76b9",
//     "aws_cognito_region": process.env.TIKR_AWS_REGION,
//     "aws_user_pools_id": "us-east-1_r5nzDdYSv",
//     "aws_user_pools_web_client_id": "3kbcoi1pjm2enbgb7slgvdq0u7",
//     "aws_appsync_graphqlEndpoint": process.env.AWS_APPSYNC_GQL_ENDPOINT,
//     "aws_appsync_region": process.env.TIKR_AWS_REGION,
//     "aws_appsync_authenticationType": "AMAZON_COGNITO_USER_POOLS"
// };

export default awsmobile;



/**
 * Amplify Config For..
 *
 * AUTH
 * https://aws-amplify.github.io/docs/js/authentication#manual-setup
 *
 * This is what is throwing me for a loop - it looks like this configuration object has changed.
 * I should mess with this - see if it still works, and then try to add the analytics config.
 *
 * API (appsync)
 * https://aws-amplify.github.io/docs/js/api#manual-configuration
 * There are a couple options.. API_KEY, AWS_IAM, AMAZON_COGNITO_USER_POOLS, OPENID_CONNECT
Amplify.configure({
    Auth: {

        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: 'XX-XXXX-X:XXXXXXXX-XXXX-1234-abcd-1234567890ab',

        // REQUIRED - Amazon Cognito Region
        region: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: 'XX-XXXX-X_abcd1234',

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: 'a1b2c3d4e5f6g7h8i9j0k1l2m3',

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        mandatorySignIn: false,

        // OPTIONAL - Configuration for cookie storage
        // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        cookieStorage: {
        // REQUIRED - Cookie domain (only required if cookieStorage is provided)
            domain: '.yourdomain.com',
        // OPTIONAL - Cookie path
            path: '/',
        // OPTIONAL - Cookie expiration in days
            expires: 365,
        // OPTIONAL - Cookie secure flag
        // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
            secure: true
        },

        // OPTIONAL - customized storage object
        storage: new MyStorage(),

        // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        authenticationFlowType: 'USER_PASSWORD_AUTH',

        // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        clientMetadata: { myCustomKey: 'myCustomValue' },

         // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: 'your_cognito_domain',
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: 'http://localhost:3000/',
            redirectSignOut: 'http://localhost:3000/',
            responseType: 'code' // or 'token', note that REFRESH token will only be generated when the responseType is code
        }
    }
});
 *
 * I believe that my current access pattern is the cognito user pools so the required
 * config is...
 'aws_appsync_graphqlEndpoint': 'https://xxxxxx.appsync-api.us-east-1.amazonaws.com/graphql',
 'aws_appsync_region': 'us-east-1',
 'aws_appsync_authenticationType': 'AMAZON_COGNITO_USER_POOLS', // You have configured Auth with Amazon Cognito User Pool ID and Web Client Id
 *
 * ANALYTICS
 */
