// computed must be imported in order
// for all the jest tests to pass
import { computed } from "vue"
import { usePreferredLanguages } from "@vueuse/core"
import { isDev } from "./useBaseUtils"
import { scaleEstimateDataObject } from "~/utils/values/scale"

const numberFormatters = () => {
  const langs = usePreferredLanguages()
  const numberLanguage = langs.value[0] || "default"

  const formatPercent = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  })

  const formatPercentNoDecimals = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      style: "percent",
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  })

  const formatPercentTwoDecimals = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      style: "percent",
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  })

  const dynamicTwoDigitCurrencyFormatter = (currencyIso = "USD") => {
    const min = 2 // this needs to sync with the UI on formatting
    const max = 2
    return new Intl.NumberFormat(langs.value[0], {
      style: "currency",
      currency: currencyIso,
      minimumFractionDigits: min,
      maximumFractionDigits: max,
    })
  }

  const displayPercentTwoDecimals = (val, showParenth = true) => {
    const negative = val < 0
    let returnStr = formatPercentTwoDecimals.value.format(val)
    if (negative && showParenth) {
      returnStr = `(${formatPercentTwoDecimals.value.format(Math.abs(val))})`
    }
    return returnStr
  }

  const displayPercentOneDecimal = (val) => {
    const negative = val < 0
    let returnStr = formatPercentOneDecimal.value.format(val)
    if (negative) {
      returnStr = `(${formatPercentOneDecimal.value.format(Math.abs(val))})`
    }
    return returnStr
  }

  const formatPercentOneDecimal = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      style: "percent",
      minimumFractionDigits: 1,
      maximumFractionDigits: 1,
    })
  })
  // May be better name for this..
  const displayWholeNumberAsPercentTwoDecimals = (pct) => {
    if (pct === 0) {
      return "-"
    }

    if (pct > 0) {
      return `${formatPercentTwoDecimals.value.format(pct / 100)}`
    }

    return `(${formatPercentTwoDecimals.value.format(Math.abs(pct) / 100)})`
  }

  const formatInt = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    })
  })

  const displayInt = (val, showParenth = true) => {
    const negative = val < 0
    let returnStr = formatInt.value.format(val)
    if (negative && showParenth) {
      returnStr = `(${formatInt.value.format(Math.abs(val))})`
    }
    return returnStr
  }

  const displayIntOrDash = (int) => {
    if (int === 0) {
      return "-"
    }

    if (int > 0) {
      return formatInt.value.format(int)
    }

    return `(${formatInt.value.format(Math.abs(int))})`
  }

  const formatNumberTwoDecimals = computed(() => {
    return new Intl.NumberFormat(langs.value[0], {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    })
  })

  const formatPrice = computed(() => {
    const decimals = 2 // TODO: make this depend on something else in the store
    return new Intl.NumberFormat(langs.value[0], {
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  })

  const displayFloat = (val, showParenth = true, suffix = "") => {
    const negative = val < 0
    const suffixWithSpacing = suffix ? ` ${suffix}` : ""
    let returnStr = `${formatPrice.value.format(val)}${suffixWithSpacing}`
    if (negative && showParenth) {
      returnStr = `(${formatPrice.value.format(
        Math.abs(val)
      )}${suffixWithSpacing})`
    }
    return returnStr
  }

  const dynamicCurrencyFormater = (decimals = 0, isoCode = "USD") => {
    return new Intl.NumberFormat(langs.value[0], {
      style: "currency",
      currency: isoCode,
      minimumFractionDigits: decimals,
      maximumFractionDigits: decimals,
    })
  }

  const twoDigitUsdCurrencyFormatter = dynamicCurrencyFormater(2)

  const formatMarketCapValue = (mc, unitAbbr = true) => {
    // mc is always in... millions and US dollars
    const isocode = "USD"
    const negative = mc < 0
    let returnStr
    if (unitAbbr) {
      if (mc <= 1000) {
        // display in millions w/o decimals
        const formatter = dynamicCurrencyFormater(0, isocode)
        returnStr = `${formatter.format(mc)}MM`
      } else {
        // display in billions w/ decimals
        const adjustedMc = mc / 1000
        const formatter = dynamicCurrencyFormater(2, isocode)
        returnStr = `${formatter.format(adjustedMc)}B`
      }
      if (negative) {
        returnStr = `(${returnStr})`
      }
    } else {
      const formatter = dynamicCurrencyFormater(0, isocode)
      returnStr = `${formatter.format(mc)}`
    }
    return returnStr
  }

  const displayPrice = (
    data = {},
    usd = false,
    valueKey = "v",
    conversionKey = "pc",
    isoKey = "iso"
  ) => {
    try {
      let isocode = data[isoKey] || "USD"
      let closePrice = data[valueKey]
      if (closePrice) {
        if (usd) {
          const toUSD = data[conversionKey] || 1
          isocode = "USD"
          closePrice = closePrice / toUSD
        }
        const formatter = dynamicCurrencyFormater(2, isocode)
        return formatter.format(closePrice)
      } else {
        return "-"
      }
    } catch (error) {
      console.error("watchlist displayPrice Error: ", error)
      return "-"
    }
  }

  const marketCapUnits = (
    ciqPriceObj,
    usd = false,
    valueKey = "mc",
    conversionKey = "mcpc",
    isoKey = "mciso"
  ) => {
    if (!ciqPriceObj[valueKey]) {
      return "-"
    }
    let isocode = ciqPriceObj[isoKey]
    let mc = parseFloat(ciqPriceObj[valueKey])
    if (usd) {
      isocode = "USD"
      const toUSD = ciqPriceObj[conversionKey] || 1
      mc = mc / toUSD
    }
    if (mc <= 1000) {
      // display in millions w/o decimals
      const formatter = dynamicCurrencyFormater(0, isocode)
      return `${formatter.format(mc)}MM`
    }

    // display in billions w/ decimals
    const adjustedMc = mc / 1000
    const formatter = dynamicCurrencyFormater(2, isocode)
    return `${formatter.format(adjustedMc)}B`
  }

  const formatTurnsValue = (val, showTurns = true) => {
    if (val) {
      const isPositive = val >= 0
      const finalVal = isPositive ? val : Math.abs(val)
      const formatterFn = formatPrice.value.format
      const suffix = showTurns ? "x" : ""

      // TODO: standardize the formatting function names
      return isPositive
        ? `${formatterFn(finalVal)}${suffix}`
        : `(${formatterFn(finalVal)}${suffix})`
    }
  }

  // What volume Unit is this formatting?
  const volumeUnits = (stringVol) => {
    // create a loop that determines what units to attach to a volume number
    // make sure to parse vol
    if (!stringVol) {
      return "-"
    }
    const vol = parseInt(stringVol)
    if (vol <= 1e6) {
      // return number as is
      return `${formatInt.value.format(vol)}`
    } else {
      // transform into millions
      const newVol = vol / 1e6
      return `${formatPrice.value.format(newVol)}MM`
    }
  }

  const percentChange = (y0, y1) => {
    // y0 is the value of interest at t0, y1 is the value of interest at t1
    // where t0 < t1 thereform %change = (y1 - y0) / y0
    // Also assuming that y0 and y1 are numbers stored as strings so they need
    // to be parsed in order to do the math
    if (y0) {
      const v0 = parseFloat(y0)
      const v1 = parseFloat(y1)
      const delta = v1 - v0
      const percentChange = delta / v0
      return percentChange > 0
        ? formatPercent.value.format(percentChange)
        : `(${formatPercent.value.format(Math.abs(percentChange))})`
    } else {
      return "-"
    }
  }

  const calculateSimpleMultiple = (
    numerator = {},
    denominator = {},
    options = {
      format: false,
      showTurns: false,
      valueKey: "v",
      exRateKey: "pc",
    }
  ) => {
    const valueKey = options.valueKey || "v"
    const exRateKey = options.exRateKey || "pc"
    try {
      if (!(numerator[valueKey] && denominator[valueKey])) return
      const fallbackValue = 1
      const turns = options?.showTurns ? "x" : ""

      let quotient =
        numerator[valueKey] /
        scaleEstimateDataObject({ dataObj: denominator, fallbackValue })

      if (numerator.iso !== denominator.iso) {
        const numeratorUSD = numerator[valueKey] / numerator[exRateKey]
        const denominatorUSD =
          scaleEstimateDataObject({ dataObj: denominator, fallbackValue }) /
          denominator[exRateKey]
        quotient = numeratorUSD / denominatorUSD
      }

      if (!quotient) return

      // Return the quotient as a number if format is false
      if (!options?.format) {
        return quotient
      }

      if (quotient >= 0) {
        return `${formatPrice.value.format(quotient)}${turns}`
      } else {
        return `(${formatPrice.value.format(Math.abs(quotient))}${turns})`
      }
    } catch (error) {
      if (isDev()) {
        console.error("error transforming data: ", error)
      }
    }
  }

  const formatValueBasedOnType = ({
    type,
    value,
    suffix,
    nativeCurrencyFormatter,
    usdCurrencyFormatter,
    useUsdCurrencyFormatter = false,
  }) => {
    const currencyFormatter = useUsdCurrencyFormatter
      ? usdCurrencyFormatter
      : nativeCurrencyFormatter
    switch (type) {
      case "pct":
        return displayPercentOneDecimal(value)
      case "whole_pct":
        return displayPercentOneDecimal(value)
      case "turns":
        return formatTurnsValue(value, true)
      case "currency": {
        const isNegative = value < 0
        const finalValue = isNegative ? Math.abs(value) : value
        const formatted = currencyFormatter.format(finalValue)
        return isNegative ? `(${formatted})` : formatted
      }
      case "float":
        return displayFloat(value, true, suffix)
      default:
        return value
    }
  }

  const getFormattedValue = (value, formatType, currencyFormatter) => {
    switch (formatType) {
      case "pct":
        return displayPercentOneDecimal(value)
      case "whole_pct":
        return displayPercentOneDecimal(value / 100)
      case "turns":
        return formatTurnsValue(value, true)
      case "currency":
        return currencyFormatter.format(value)
    }
  }

  return {
    getFormattedValue,
    volumeUnits,
    formatPercent,
    formatPercentTwoDecimals,
    formatPercentOneDecimal,
    formatPercentNoDecimals,
    formatNumberTwoDecimals,
    displayWholeNumberAsPercentTwoDecimals,
    formatInt,
    displayIntOrDash,
    percentChange,
    formatPrice,
    formatTurnsValue,
    numberLanguage,
    dynamicCurrencyFormater,
    displayPrice,
    displayPercentOneDecimal,
    displayPercentTwoDecimals,
    displayFloat,
    displayInt,
    formatMarketCapValue,
    marketCapUnits,
    calculateSimpleMultiple,
    dynamicTwoDigitCurrencyFormatter,
    twoDigitUsdCurrencyFormatter,
    formatValueBasedOnType,
  }
}
export default numberFormatters
