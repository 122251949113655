import cloneDeep from "lodash/cloneDeep"
import { valuationTemplate } from "../templates/valuation_template"
import { scaleEstimateDataObject } from "../values/scale"
import {
  createPriceSeries,
  createTblData,
  ltmArrtoObj,
  ntmArrToObj,
  oneArrayToObject,
  streetArrToObj,
  transformMultiplesDates,
} from "./allMultiplesUtils"
import { findMetricKey, rowObjToSelectedKey } from "./common"
import dayjs from "~/utils/tools/dayjs"
import { calculateCAGR } from "~/modules/ratios"

/**
 * transform an array of arrays which are the rows from xpressfeed
 * THAT CONTAIN A dataItemId
 * arrToObjMap MUST contain a property that is "did"
 * @param {Array} dataArray
 * @param {Object} arrToObjMap
 * @param {Object} resultObj
 * LINK static/ciq_worker.js:2221
 */
const xpressfeedEstimateArrayToObject = (
  dataArray,
  arrToObjMap,
  resultObj = {}
) => {
  const orderedRawSeriesKey = "rawSeries"
  const dataObj = dataArray.reduce((acc, resArr) => {
    const rawObj = oneArrayToObject(arrToObjMap, resArr)
    if (rawObj) {
      const did = rawObj.did
      const metricKey = findMetricKey(did)
      const dT = rawObj.d // date in text format
      const dV = Date.parse(rawObj.d) // date as epoch value
      // const erc = rawObj.erc // estimate relative constant FIXME: is this needed
      const curr = rawObj.curr // ntm currency name... is this needed? Yes Differentiates between est and financials
      const iso = rawObj.iso // ntm currency ISO code
      const pc = parseFloat(rawObj.pc) // ntm currency conversion rate to USD
      const s = rawObj.s // ntm estimate scale
      const sf = parseFloat(rawObj.sf) // split factor to adjust for historical splits
      const v = parseFloat(rawObj.v)
      const unauth = !!rawObj.unauth
      let obj = {}
      if (v) {
        obj = {
          dT,
          dV,
          curr,
          iso,
          pc,
          s,
          sf,
          v: unauth ? 1 : v,
          // erc,
          est: true,
          unauth,
        }
      }

      if (Object.keys(obj).length > 0) {
        if (acc[metricKey]?.[orderedRawSeriesKey]) {
          // add ntmObj to existing rawSeriesArray
          acc[metricKey][orderedRawSeriesKey].push(obj)
        } else {
          const dataitemObj = {}
          dataitemObj[orderedRawSeriesKey] = [obj]
          acc[metricKey] = dataitemObj
        }
      }
    }

    return acc
  }, {})
  return Object.assign(resultObj, dataObj)
}

// LINK static/ciq_worker.js:2320
const xpressfeedFinancialArrayToObject = (
  dataArray,
  arrToObjMap,
  resultObj = {}
) => {
  const orderedRawSeriesKey = "rawSeries"
  const dataObj = dataArray.reduce((acc, resArr) => {
    const rawObj = oneArrayToObject(arrToObjMap, resArr)
    if (rawObj) {
      const did = rawObj.did
      const metricKey = findMetricKey(did)
      let obj = {}

      const dT = rawObj.fifilingdate // date in text format... when to start using this value
      const dV = Date.parse(rawObj.fifilingdate) // date as epoch value
      const erc = `${rawObj.calendaryear}${rawObj.calendarquarter}` // estimate relative constant for data
      // const curr = data.isocode // ltm currency name... doesn't exist on current actual fetch
      const iso = rawObj.isocode // ltm currency ISO code... if it is on these then priceclose can be added
      const pc = parseFloat(rawObj.pc) // ltm currency conversion rate to USD TODO: FIGURE THIS OUT
      const s = rawObj.unittypeid // historical financial unit type scale
      // const sf = parseFloat(data.sf) // split factor to adjust for historical splits
      const v = parseFloat(rawObj.v)
      const unauth = !!rawObj.unauth
      if (v) {
        obj = {
          dT,
          dV,
          iso,
          pc,
          v: unauth ? 1 : v,
          s,
          erc,
          est: false,
          unauth,
        }
      }

      if (Object.keys(obj).length > 0) {
        if (acc[metricKey]?.[orderedRawSeriesKey]) {
          // add ntmObj to existing rawSeriesArray
          acc[metricKey][orderedRawSeriesKey].push(obj)
        } else {
          const dataitemObj = {}
          dataitemObj[orderedRawSeriesKey] = [obj]
          acc[metricKey] = dataitemObj
        }
      }
    }

    return acc
  }, {})
  return Object.assign(resultObj, dataObj)
}

const dividePeriodicByNonPeriodic = (
  rawPeriodic,
  rawNonperiodic,
  normalDiv
) => {
  // divide a periodic time series by a nonperiodic time series
  const periodic = rawPeriodic.filter((f) => !f.unauth)
  const nonperiodic = rawNonperiodic.filter((f) => !f.unauth)

  const firstPeriod = periodic[0] // very first periodic data point
  let currentNon = nonperiodic[0] // variable holding the current nonperiod data point
  let currentNonIdx = 0 // current index of currentNon in nonperiodic
  let nextNon = nonperiodic[1] // variable holder for the next nonperiodic data point... n+1
  let nextNonIdx = 1 // variable holder for the next nonPeriodic index n+1
  const nonLength = nonperiodic.length
  if (firstPeriod.dV > currentNon.dV) {
    const lastNon = nonperiodic[nonLength - 1]
    if (firstPeriod.dV >= lastNon.dV) {
      // the two series do not overlap chronologically
      console.error("the two series do not overlap", {
        numerator: periodic,
        denomincator: nonperiodic,
      })
      return []
    }
    // Periodic starts later than nonPeriodic
    // need to figure out which nonPeriodic index begins AT or AFTER
    // the first periodic data point
    // which means the nonPeriod datapoint whose time is greater than or equal to the
    // first period
    currentNonIdx = nonperiodic.findIndex(
      (nonPeriod) => nonPeriod.dV >= firstPeriod.dV
    )
    // console.log(`currentNonIdx changed to ${currentNonIdx}`)
    if (currentNonIdx === -1) {
      return []
    }
    currentNon = nonperiodic[currentNonIdx]
    nextNonIdx = currentNonIdx + 1
    nextNon = nonperiodic[currentNonIdx + 1]
  }
  // perform division on periodic data whos time is greater than or equal to the
  // current / first nonPeriodic data point
  // FIXME: fundamentally this process relies on multiple nonPeriodic datapoints
  // so it doesn't work with only one
  const fallbackValue = 1
  return periodic
    .filter((periodFilter) => periodFilter.dV >= currentNon.dV)
    .map((period) => {
      if (nextNonIdx < nonLength && period.dV >= nextNon.dV) {
        currentNonIdx = nextNonIdx
        currentNon = nonperiodic[currentNonIdx]
        nextNonIdx = nextNonIdx + 1
        nextNon = nonperiodic[nextNonIdx]
      }
      // so scale conversion is assuming that the price time series is always
      // an absolute value and the TEV / MC are always in... millions
      // let quotient = period.v / currentNon.v
      if (normalDiv) {
        let quotient =
          period.v /
          scaleEstimateDataObject({ dataObj: currentNon, fallbackValue })
        if (period.iso !== currentNon.iso) {
          const numeratorUSD = period.v / period.pc
          const denominatorUSD =
            scaleEstimateDataObject({ dataObj: currentNon, fallbackValue }) /
            currentNon.pc
          quotient = numeratorUSD / denominatorUSD
        }
        if (period.unauth || currentNon.unauth) {
          quotient = 1
        }
        return [period.dV, quotient]
      }

      let quotient =
        scaleEstimateDataObject({ dataObj: currentNon, fallbackValue }) /
        period.v
      if (period.iso !== currentNon.iso) {
        const numeratorUSD = period.v / period.pc
        const denominatorUSD =
          scaleEstimateDataObject({ dataObj: currentNon, fallbackValue }) /
          currentNon.pc
        quotient = denominatorUSD / numeratorUSD
      }

      if (period.unauth || currentNon.unauth) {
        quotient = 1
      }
      return [period.dV, quotient]
    })
}

// LINK static/ciq_worker.js:3040
const transformForDaily = (
  template,
  rawDateArr,
  rawPriceArr,
  rawNtmArr,
  rawStreetArr,
  rawLtmArr,
  lastObj
) => {
  const dates = transformMultiplesDates(rawDateArr, lastObj)
  // TODO: Add Scale & CurrencyISOCode accumulators?
  const orderedRawSeriesKey = "rawSeries"
  const price = createPriceSeries(rawPriceArr, dates)
  const ntm = xpressfeedEstimateArrayToObject(rawNtmArr, ntmArrToObj)
  const street = xpressfeedEstimateArrayToObject(rawStreetArr, streetArrToObj)
  const ltm = xpressfeedFinancialArrayToObject(rawLtmArr, ltmArrtoObj)

  /**
   * Thoughts as of Nov 2nd 2022
   *
   * There is a potential issue here
   * is there overlap between the name of the metric
   * that is being used as a key in the transformedBackendData
   * object between NTM and LTM metrics (i.e. revenue, ebitda, ebit)
   * oh there may totally be overlap on ebitda and ebit now that
   * I think about it
   *
   * est_revenue and fin_total_revenue
   *
   * the other dimension here is the fact that currently we only
   * process ntm_est_revenue but there is also ntm_act_revenue and
   * then there are other forward time periods like
   *
   * fy+1_est_revenue, fy+2_est_revenue which is a feature we've
   * been interested in adding for some time
   */
  const transformedBackendData = Object.assign(
    {},
    price,
    Object.keys(ntm).reduce((acc, m) => {
      acc[m] = ntm[m][orderedRawSeriesKey]
      return acc
    }, {}),
    Object.keys(street).reduce((acc, m) => {
      acc[m] = street[m][orderedRawSeriesKey]
      return acc
    }, {}),
    Object.keys(ltm).reduce((acc, m) => {
      acc[m] = ltm[m][orderedRawSeriesKey]
      return acc
    }, {})
  )

  // Here is where you should generate the calculated time series
  const resData = createCalculatedTimeSeries(transformedBackendData)

  const tblDataObj = createTblData(resData, dates)

  const priceFactors = [
    "mc",
    "tev",
    "priceclose",
    "100161",
    "100162",
    "100163",
    "100164",
    "114210",
    "114212",
    "114211",
    "114213",
  ]

  const dailyMultiples = template.reduce((oAcc, t) => {
    const dailySeriesObj = t.keys.reduce((acc, lineitem) => {
      const formula = lineitem.formula
      const name = lineitem.name
      const rowId = rowObjToSelectedKey(lineitem)

      const firstMetricKey = findMetricKey(lineitem.dataitemid[0])
      const secondMetricKey = findMetricKey(lineitem.dataitemid[1])

      if (formula === "val" && resData[firstMetricKey]) {
        const val = resData[firstMetricKey]
        const isPriceFactor = priceFactors.includes(lineitem.denominator)
        let yAxis = isPriceFactor ? 3 : 1
        const isPriceClose = lineitem.denominator === "priceclose"
        if (isPriceClose) {
          yAxis = "priceclose"
        }

        if (typeof val === "object" && val.length > 0) {
          acc[rowId] = {
            data: val
              .filter((f) => f.dV && f.v && !f.unauth)
              .map((d) => [d.dV, d.v]),
            name,
            id: rowId,
            type: "line",
            yAxis,
          }
          if (lineitem.type === "float") {
            const isoCode = new Set()
            acc[rowId].curr = val
              .filter((f) => f.dV && f.v)
              .map((d) => {
                isoCode.add(d.iso)
                return [d.pc, d.iso]
              })
            acc[rowId].iso = Array.from(isoCode)
          }
        }
      }

      if (
        formula === "div" &&
        resData[firstMetricKey] &&
        resData[secondMetricKey]
      ) {
        const numerator = resData[firstMetricKey]
        const denominator = resData[secondMetricKey]
        if (
          typeof numerator === "object" &&
          typeof denominator === "object" &&
          numerator.length > 0 &&
          denominator.length > 0
        ) {
          try {
            const periodic = ["priceclose", "tev", "mc"]
            const normalDiv = Boolean(periodic.includes(firstMetricKey))
            let div
            if (normalDiv) {
              div = dividePeriodicByNonPeriodic(
                numerator,
                denominator,
                normalDiv
              )
            } else {
              // console.error("NOT A NORMAL DIVISION!")
              // this means that what is being called "numerator" is actually nonPeriodic
              // and what is being called "denomincator" is actually Periodic
              // so we need to flip the order they're given to the division function
              // and provide the flag to indicate the division order needs to be reveresed
              // FIXME: this is going to confuse you later.. I guarentee it.
              div = dividePeriodicByNonPeriodic(
                denominator,
                numerator,
                normalDiv
              )
            }
            const yAxis =
              lineitem.type === "turns" ? 0 : lineitem.type === "pct" ? 2 : 1
            acc[rowId] = {
              data: div,
              name,
              id: rowId,
              type: "line",
              yAxis,
            }
          } catch (e) {
            console.error("error dividing dailyNtm series: ", {
              error: e,
              numerator,
              denominator,
            })
          }
        }
      }
      return acc
    }, {})

    return Object.assign(oAcc, dailySeriesObj)
  }, {})

  // generate the "div-tevntm_gp" series
  // const tev = dailyMultiples["val-tev"]
  // const tevMargin = dailyMultiples["div-tev105293"]
  // const tevRev = dailyMultiples["div-tev100180"]
  // if (tev && tevMargin && tevRev) {
  //
  // }

  // FIXME: use dayjs to correctly determine the 1yr, 3yr and 5yr means
  // ALSO, use dayjs to determine the value one year ago from the most recent
  // and then calculate the YoY change
  const mean = (arr) => arr.reduce((acc, v) => acc + v[1], 0) / arr.length
  const xYearsAgo = (years) =>
    Math.floor(new Date().getTime() - years * 365 * 24 * 60 * 60 * 1000)
  const getMeanForTimePeriod = (data, years = 1) => {
    const dateCutoff = xYearsAgo(years)
    const dataSet = data.filter((itm) => itm[0] > dateCutoff)
    return mean(dataSet)
  }

  const getYoYForTimePeriod = (data, periodInYears) => {
    const today = dayjs()
    const dataSet = data.filter((item) => {
      return dayjs(item[0]).isAfter(today.subtract(periodInYears, "year"))
    })
    const firstItem = dataSet?.[0]
    const lastItem = dataSet[dataSet.length - 1]
    if (firstItem?.length && lastItem?.length) {
      const { cagr } = calculateCAGR({
        initialValue: firstItem[1],
        lastValue: lastItem[1],
        firstDate: firstItem[0],
        lastDate: lastItem[0],
      })
      return cagr
    } else {
      return 0
    }
  }

  const stats = Object.entries(dailyMultiples).reduce((acc, [key, value]) => {
    if (value?.data?.length) {
      acc[key] = {
        last: value.data[value?.data.length - 1][1],
        "1yr": getMeanForTimePeriod(value.data, 1),
        "3yr": getMeanForTimePeriod(value.data, 3),
        "5yr": getMeanForTimePeriod(value.data, 5),
        "1yrChangePct": getYoYForTimePeriod(value.data, 1),
        "3yrChangePct": getYoYForTimePeriod(value.data, 3),
        "5yrChangePct": getYoYForTimePeriod(value.data, 5),
      }
    }
    return acc
  }, {})

  return {
    dates,
    price,
    ntm,
    street,
    dailyMultiples,
    last: lastObj,
    ltm,
    resData,
    tblDataObj,
    stats,
    // TODO: for access in new overview component
    // stats: {"div-mc100180": {last: {}, 1yr: {}, 3yr: {}, 5yr: {}}
  }
}

const transformAllValuationData = (res) => {
  // transform all of the multiples data here
  if (!res.dates || res.dates.length === 0) {
    console.log("No Multiple Dates?:", res)
    throw new Error("Sorry, we do not have any Multiples Data for this ticker")
  }
  const statementTemplate = cloneDeep(valuationTemplate)

  const dailyData = transformForDaily(
    statementTemplate,
    res.dates,
    res.price,
    res.ntm,
    res.street,
    res.ltm,
    res.last
  )
  return dailyData
}

// LINK static/ciq_worker.js:2448
const createCalculatedTimeSeries = (metricObj) => {
  // const metricsToFlip = [
  //   { metricKey: "total current assets", for: "ltm_ncav" },
  //   { metricKey: "total liabilities", for: "ltm_ncav" },
  //   { metricKey: "minority interest", for: "ltm_ncav" },
  //   { metricKey: "total preferred equity", for: "ltm_ncav" },
  //   {
  //     metricKey: "weighted average diluted shares outstanding",
  //     for: "ltm_ncav",
  //   },
  //   { metricKey: "revenue", for: "ntm_gp" }, // FIXME: revenue -> fwd revenue vs. total revenue which is trailing revenue
  //   { metricKey: "gross margin (%) mean", for: "ntm_gp" },
  //   { metricKey: "eps_normalized", for: "ntmeps_dt" },
  // ]
  const ncavSeries = calculateNcav(metricObj)
  metricObj.ltm_ncav = ncavSeries

  // TODO: ntmeps_dt
  // TODO: ntm_pe ... was I doing this just to verify that it was working correctly?
  // TODO: ntm_gp
  // TODO: ltm_ncav
  return metricObj
}

/**
 * calculates the Net Current Asset Value (ncav) time series array
 * netCurrentAssets = assets - liabilities - minorityInterest - preferred
 * LINK static/ciq_worker.js:2376
 */
const calculateNcav = (metricObj) => {
  // is the `|| []` syntax correct?
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/Logical_OR
  // const assetsArr = metricObj["total current assets"] || []
  // const liabilitiesArr = metricObj["total liabilities"] || []
  // const minorityIntArr = metricObj["minority interest"] || []
  // const equityArr = metricObj["total preferred equity"] || []
  // const sharesArr = metricObj["weighted average diluted shares outstanding"] || []

  const metricKeyArr = [
    "total current assets 1008",
    "total liabilities 1276",
    "minority interest_bs 1052",
    "total preferred equity 1005",
    "weighted average diluted shares outstanding 342",
  ]

  const dateKey = "dT"
  const valueKey = "v"

  const rawDataByDates = metricKeyArr.reduce((acc, metricKey) => {
    const dataArr = metricObj[metricKey] || []
    dataArr.forEach((metricObj) => {
      const date = metricObj[dateKey]
      const value = metricObj[valueKey]
      if (Number(value)) {
        if (acc[date]) {
          acc[date][metricKey] = metricObj
        } else {
          const objectToSave = {}
          objectToSave[metricKey] = metricObj
          acc[date] = objectToSave
        }
      }
    })
    return acc
  }, {})

  const ncavSeries = Object.keys(rawDataByDates).map((dateKey) => {
    const dataObj = rawDataByDates[dateKey]
    const [assets, liabilities, minority, preferredEquity, shares] =
      metricKeyArr.map((metricKey) => {
        const defaultObj = {}
        defaultObj[valueKey] = 0
        const metricObj = dataObj[metricKey] || defaultObj
        return metricObj
      })
    const ncavObj = Object.assign(
      {},
      assets,
      liabilities,
      minority,
      preferredEquity,
      shares
    )
    // assuming .v is the dataKey
    const netCurrentAssets =
      assets.v - liabilities.v - minority.v - preferredEquity.v
    if (netCurrentAssets && shares.v) {
      const ncav = netCurrentAssets / shares.v
      ncavObj.v = ncav
    } else {
      ncavObj.v = 0
    }
    return ncavObj
  })
  // .filter((f) => f.v)

  return ncavSeries
}

export { transformAllValuationData }
