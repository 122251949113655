import { finDidToMetricKey } from "../templates/financials_templates"
import { estDidToMetricKey } from "../templates/estimates_template"
import { valDidToMetricKey } from "../templates/valuation_template"

const didToMetricKey = Object.assign(
  {},
  valDidToMetricKey,
  finDidToMetricKey,
  estDidToMetricKey
)

// LINK static/ciq_worker.js:604
const findMetricKey = (
  backendId,
  options = { idToMetricMap: didToMetricKey }
) => {
  const { idToMetricMap } = options
  return idToMetricMap[backendId] ? idToMetricMap[backendId] : backendId
}

const rowObjToSelectedKey = function (
  row,
  options = {
    prefix: false,
    suffix: false,
    delimiter: "-",
    rowPropertyForMap: "dataitemid",
    metricMap: didToMetricKey,
  }
) {
  // convert the row / lineitem object into a unique identifier for use in graphing
  const { prefix, suffix, delimiter, metricMap, rowPropertyForMap } = options
  const formula = row?.formula?.type || row?.formula
  const metricIdArray = []
  const itemToMap = row[rowPropertyForMap]
  if (typeof itemToMap === "number") {
    // can concat and return
    const metricKey = metricMap[itemToMap]
      ? metricMap[itemToMap]
      : row[itemToMap]
    metricIdArray.push(metricKey)
  } else if (typeof itemToMap === "string") {
    metricIdArray.push(itemToMap)
  } else {
    // dataitemid is an object of strings, concat the strings with formula
    try {
      itemToMap.forEach((id) => {
        const metricKey = metricMap[id] ? metricMap[id] : id
        metricIdArray.push(metricKey)
      })
    } catch (error) {
      // metricIdArray.push(id)
      console.error("error rowObjToSelectedKey: ", row)
    }
  }
  let result = formula + delimiter + metricIdArray.join(delimiter)
  if (prefix) {
    result = `${prefix}${delimiter}${result}`
  }
  if (suffix) {
    result = `${result}${delimiter}${suffix}`
  }
  return result
}

// TODO: remove getLoaderByCellType from everywhere else but here
const getCellLoaders = ({ $store, sources = [], isYield, isCurrency }) => {
  const loaders = []
  if (isYield || isCurrency) {
    loaders.push($store.state.ciq.fetchingQuotes)
  }
  if (sources.length > 0) {
    for (const element of sources) {
      loaders.push(getLoaderByCellType(element, $store))
    }
  }
  return loaders
}

const getLoaderByCellType = (cellType, $store) => {
  const $ciqStore = $store.state.ciq

  switch (cellType) {
    case "trading":
    case "company":
      return $ciqStore.fetchingQuotes

    case "financials":
      return $ciqStore.fetchingFinancials

    case "estimates":
      return $ciqStore.fetchingEstimates

    case "valuation":
    case "valuationStats":
      return $ciqStore.fetchingValuation
  }
}

const extraRows = [
  {
    dailyId: "val-mc",
    priceFactorId: "mc",
    name: "Market Cap",
  },
  {
    dailyId: "val-priceclose",
    priceFactorId: "priceclose",
    name: "Price Close",
    format: "currency",
  },
  {
    dailyId: "val-tev",
    priceFactorId: "tev",
    name: "TEV",
  },
]

const getFormulaKey = (formulaProp) => {
  return formulaProp?.type || formulaProp
}

const getQuarterDateProps = (obj, str) => {
  const [yearStr, quarterStr] = str.split("##")
  let year = parseInt(yearStr, 10)
  let quarter = parseInt(quarterStr, 10)

  const relativeQuarterKey = obj.relativeQuarterKey
  const relativeDateKey = obj.relativeDateKey

  if (
    (relativeQuarterKey && isNaN(relativeQuarterKey)) ||
    (relativeDateKey && isNaN(relativeDateKey))
  ) {
    console.warn("relativeQuarterKey and relativeDateKey should be numbers")
    return { year, quarter }
  }

  quarter += relativeQuarterKey || 0
  year += relativeDateKey || 0

  while (quarter < 1) {
    quarter += 4
    year -= 1
  }

  while (quarter > 4) {
    quarter -= 4
    year += 1
  }

  return { year, quarter }
}

const objsNeedUsdConversion = (actualIsocode = "USD", arr = []) => {
  const isos = new Set(arr.filter((item) => item?.iso).map((item) => item?.iso))

  if (isos.size === 1) {
    const [singleItemInThisArr] = Array.from(isos)
    return actualIsocode !== singleItemInThisArr
  }
  return true
}

export {
  extraRows,
  findMetricKey,
  getFormulaKey,
  getCellLoaders,
  getLoaderByCellType,
  getQuarterDateProps,
  objsNeedUsdConversion,
  rowObjToSelectedKey,
  didToMetricKey,
  finDidToMetricKey,
  estDidToMetricKey,
  valDidToMetricKey,
}
