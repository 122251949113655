import { take, takeRight } from "lodash"
import last from "lodash/last"
import { calcEstDerivative, calcEstDiv } from "./estimateMath"
import { findMetricKey } from "./common"

/**
 *
 * @typedef actualsObj
 * @type {Object}
 * @property {Object} consensusToPeriod mapping between estimateconsensusid and estimateperiodid
 * @property {Object} resData dictionary mapping dataitemid to the dataitemvalue objects for each estimateperiodids
 * @property {Object[]} estimatesLineItems Array of estimate Template Objects
 * @property {Object[]} estimateLineItems[].keys Array of lineItemObjects
 * @property {string} estimateLineItems[].statement Title of the statement
 * @property {Object} estimates
 * @property {Object[]} dates Array of Objects containing unique estimatePeriodIds
 * LINK LINK static/ciq_worker.js:751
 */
/**
 *
 * @param {Object} res Second API Response from the fetchEstimates action
 * @param {Object[]} res.dates objects containing each estimateConsensusId, associated
 * estimatePeriodId, and all date metadata for that estimatePeriod
 * @param {actualsObj} actualsObj This is the current state on the vuex store associated with
 * @param {Object} estimateToActual {estimateDataItemId: {type: "Mean/Median/Mode", actualDataItemId: "dataitemid"}}
 * @returns {Array} results Array with 2 Objects
 * @returns {Array} results[0] Actuals & Forward Estimates
 * @returns {Array} results[1] Management Guidance
 */
const transformEstimateResponse = (
  res,
  actualsObj,
  estimateToActual,
  requestPeriodId
) => {
  let hasGuidance = false
  // consensusToPeriod is a 1:1 mapping between estimateconsensusid and estimateperiod id
  // that was previously created from transformActualResponse after the first
  // api response containing the date periods & actuals
  const consensusToPeriod = actualsObj.consensusToPeriod
  // res.estimates is the estimates data [{}]
  // I need a dictionary to tell me if the estimate dataitemid is mean, median, mode
  // and then I need to know what actual dataitemid to place it on
  // make a copy of the response data from the previous response
  const resData = actualsObj.resData

  const addScale = (acc, item) => {
    // acc is an object where we will store the count
    if (acc[item.estimatescaleid]) {
      ++acc[item.estimatescaleid]
    } else {
      acc[item.estimatescaleid] = 1
    }
  }
  // this function iterates through the estimates response and adds the new data onto
  // the copy of the old data - resData
  const isoCounter = actualsObj.isoCounter

  res.estimates.forEach((item) => {
    try {
      // count the number of currencies in this response
      // FIXME: parseFloat(item.dataitemvalue) and check !item.dataitemvalue
      if (item.dataitemvalue !== "0.000000000") {
        const currency = item.isocode
        if (isoCounter[currency]) {
          // increase the count!
          ++isoCounter[currency].count
          addScale(isoCounter[currency], item)
        } else {
          // this currency isn't on the counter yet, initalize
          const newCurr = {
            name: item.currencyname,
            code: item.isocode,
            count: 1,
          }
          addScale(newCurr, item)
          isoCounter[currency] = newCurr
        }
        // so item is a row response containing estimates data
        // given the estimates data item find the actual data item which is the rowKey
        const actualDidKey = estimateToActual[item.dataitemid]
          ? estimateToActual[item.dataitemid].rowdataitem
          : null // mapping estimate dataitemid to actual dataitemid for a row
        const metricKey = findMetricKey(actualDidKey)
        const type = estimateToActual[item.dataitemid]
          ? estimateToActual[item.dataitemid].type
          : null // estimate dataitemid to data type of estimate (mean, median)
        if (type?.includes("guide")) {
          hasGuidance = true
        }
        const estimateperiod = consensusToPeriod[item.estimateconsensusid]
        const dateKey = estimateperiod.value
        item.timeVal = Date.parse(estimateperiod.periodenddate)
        if (resData[metricKey] && type) {
          // dataitemid exists on the accumulator, add the new datavalue
          if (resData[metricKey][type]) {
            // resData[dataitemid][type] should be an object
            resData[metricKey][type][dateKey] = item
          } else {
            // resData[dataitemid][type] isn't an object yet
            // but resData[dataitemid] is an object already, so just need to make
            // the object to add, then add that object
            const addObj = {}
            addObj[dateKey] = item
            resData[metricKey][type] = addObj
          }
        } else if (item.dataitemvalue !== "0.000000000") {
          // dataitemid doesn't exist on accumulator
          // create an object which will hold the {estimatePeriod: dataitemvalue Object} relationship
          const newObj = {}
          newObj[dateKey] = item

          // create an item to hold the {"actual": {estimatePeriod: dataValueObject}}
          const dataitemidObj = {}
          dataitemidObj[type] = newObj
          // Store the {actual: {firstEstimatePeriod: {dataValueObject}}} object
          // on the item.dataitemid key on the accumulator, first time adding this dataitemid to accumulator
          resData[metricKey] = dataitemidObj
        }
      }
    } catch (error) {
      console.error("Error estimatesRes: ", error)
    }
  })

  // resData contains the data from the actuals & estimates

  const estimatesLineItems = actualsObj.estimatesLineItems // maybe I should make a copy of this
  const dates = actualsObj.dates

  // FIXME: There is something wrong in this map
  const estimates = estimatesLineItems.map((statement, statementIdx) => {
    const actuals = actualsObj.estimates[statementIdx] // this is an array of estimates data
    return statement.keys.reduce((acc, rawLineItem) => {
      // check if this lineitem exists in the actuals Array
      // if the lineitem exists, procede by adding information to the lineitem from the actuals array
      // if the lineitem does not exist, procede with the rawLineItem
      let lineitem = {}
      let lineitemExists = false

      if (typeof rawLineItem.actualPosition === "number") {
        lineitemExists = true
        lineitem = actuals[rawLineItem.actualPosition] // this is a reference
      } else {
        lineitem = rawLineItem
        lineitem.actual = {}
      }

      if (lineitem.formula === "h3") {
        // this is a header, include it, no need to merge with any data
        acc.push(lineitem)
        return acc
      }

      const estimateKeys = [
        "mean",
        "median",
        "numEst",
        "guideMid",
        "guideLow",
        "guideHigh",
      ]
      estimateKeys.forEach((key) => {
        if (
          lineitem.formula === "val" ||
          lineitem.formula === "turns" ||
          lineitem.formula === "pct"
        ) {
          // lineitem.formula = "val" implies response is just a dataitemid
          // check to see if the lineitem exists
          const metricKey = findMetricKey(lineitem.dataitemid)
          if (resData[metricKey]?.[key]) {
            lineitem[key] = resData[metricKey][key]
            lineitemExists = true
          }
        } else if (lineitem.formula === "dxdt") {
          // this is a period on period calculation
          // FIXME: If I change the xlsx to json conversion so it doesn't create an array then I don't
          // need to look at the first position in dataitemid[0]
          const metricKey = findMetricKey(lineitem.dataitemid[0])
          if (resData[metricKey]?.[key]) {
            const derivative = calcEstDerivative(metricKey, resData, dates, key)
            lineitem[key] = derivative
            lineitemExists = true
          }
        } else if (lineitem.formula === "div") {
          // A calculation is occuring FIXME: change if condition if you're ever doing more than just 2 things
          // right now there are only two numbers being passed because we're only doing division
          // a list comprehenshion would be great right here.. not quite sure how to check that for javascript
          // ASSUMING THE ONLY POSSIBILITY LEFT IS A CALCULATION SO NOT CHECKING lineitem.formula
          const numeratorKey = findMetricKey(lineitem.dataitemid[0])
          const denominatorKey = findMetricKey(lineitem.dataitemid[1])
          if (
            resData[numeratorKey] &&
            resData[denominatorKey] &&
            resData[numeratorKey][key] &&
            resData[denominatorKey][key]
          ) {
            const margin = calcEstDiv(
              numeratorKey,
              denominatorKey,
              resData,
              dates,
              key
            )
            lineitem[key] = margin
            lineitemExists = true
          }
        }
      })
      // merge with the actuals data here
      if (lineitemExists) {
        acc.push(lineitem)
        return acc
      }
      return acc
    }, [])
  })

  const filteredDateArrayForActivePeriod = dates.filter(
    (date) => !date.isEstimate && date.periodtypeid === Number(requestPeriodId)
  )

  const mostRecentDateKey = last(filteredDateArrayForActivePeriod)?.value
  const lastTenPeriods = takeRight(filteredDateArrayForActivePeriod, 10)
  const fivePeriodsForward = take(
    dates.filter(
      (date) => date.isEstimate && date.periodtypeid === Number(requestPeriodId)
    ),
    5
  )

  return Object.assign({}, actualsObj, {
    resData,
    estimates,
    isoCounter,
    hasGuidance,
    mostRecentDateKey,
    lastTenPeriods,
    fivePeriodsForward,
  })
}

export { transformEstimateResponse }
