var businessModeUtils = function businessModeUtils() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var hidePrice = computed({
    get: function get() {
      return $store.state.config.hideAllSharePrice;
    },
    set: function set(val) {
      $store.dispatch("config/changeLocalStorage", {
        vuexKey: "hideAllSharePrice",
        localStorageKey: "businessMode",
        value: val
      });
    }
  });
  var checkBusinessModePreference = function checkBusinessModePreference() {
    var vuexKey = "hideAllSharePrice";
    var localStorageKey = "businessMode";
    var localStorageBoolean = localStorage.getItem(localStorageKey); // remember these are strings
    var desiredBoolFromLocalStorage = localStorageBoolean === "true";
    if ($store.state.config[vuexKey] !== desiredBoolFromLocalStorage) {
      hidePrice.value = desiredBoolFromLocalStorage;
    }
  };
  return {
    checkBusinessModePreference: checkBusinessModePreference,
    hidePrice: hidePrice
  };
};
export default businessModeUtils;