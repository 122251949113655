module.exports = {
  "Refer Friends To TIKR.com": "把TIKR.com介绍给朋友",
  "You can now provide friends immediate access to TIKR.com with your personal referral link below.":
    "你可以通过你的个人推荐链接邀请朋友加入TIKR.com",
  "As always, please reach out to <a href='mailto:support@tikr.com'>support@tikr.com</a> if you have any feedback or questions":
    "如果你有任何疑问或者反馈，请随时通过<a href='mailto:support@tikr.com'>support@tikr.com</a>联系我们",

  "Market Overview": "市场新闻",
  "Idea Generation": "选股",
  "My Watchlists": "自选股",
  "Track Investing Gurus": "追踪投资大师",

  "Company Overview": "公司概况",
  "Company News": "公司新闻",
  "Detailed Financials": "财务详细",
  Valuation: "估值",
  "Analyst Estimates": "分析师预测",
  "Call Transcripts": "电话会议记录稿",
  "Company Filings": "公司财报",
  Ownership: "股东",
  "Account Settings": "账户设定",
  "Sign Out": "登出",
  "Search Tickers...": "检索股票代码",

  "TIKR Terminal": "TIKR终端",
  "Support TIKR Beta Promo": "支持TIKR Beta版宣传",
  "Curated Stock Baskets": "精选的股票",
  "Discussion Board": "论坛",
  Screener: "筛选器",
  Search: "搜索",
  "Fundamental Analysis": "基本分析",
  "Public Filings": "业绩报告",
  "App Config": "应用设定",
  "TIKR Settings": "TIKR设定",
  Settings: "设定",
  "New Version of TIKR Available": "有TIKR新版本可用",
  "Reload Page": "重新载入页面",
  "We've updated our ": "我们已经更新了",
  "Terms of Service": "服务条款",
  " and ": "和",
  "Accept Terms": "接受条款",
  "Watchlist:": "自选股列表:",
  "Active Watchlist:": "常用自选股列表:",
  "Use top search bar to select a ticker to view":
    "使用顶部搜索栏选择要查看的股票",
  "(e.g. NFLX)": "(例:NFLX)",

  "Add Ticker": "追加股票代码",
  "Watchlist Settings": "自选股列表设定",
  "Add Watchlist": "添加自选股列表",
  "Watchlist Columns": "自选股列表项目",
  "Active Watchlist": "常用自选股列表",
  "Set As Active Watchlist": "设置为常用自选股列表",
  "Delete Watchlist": "删除自选股列表",
  "Or Click on heart next to company name to add Ticker to active Watchlist":
    "或者可以通过点击公司名旁边的心形图标将股票添加到自选股列表",
  "Watchlist Title": "自选股列表名",
  "Watchlist Description": "自选股列表说明",
  Actions: "操作",

  "Upcoming Events": "即将举行的活动",
  "Select a watchlist title above to view the watchlist":
    "在上方选择一个自选股列表标题以查看该自选股列表",
  Or: "或者",
  "Click 'Add Watchlist' to create a new watchlist":
    "点击“添加自选股列表”以创建新的自选股列表",
  "Delete Ticker": "删除股票",
  "Delete List": "删除列表",
  "Last Price": "最近收盘价",
  "% Change": "％ 变动",
  Name: "名字",

  "NTM TEV/REV": "未来12个月 总企业价值 / 营业额",
  "NTM TEV/EBITDA": "未来12个月 总企业价值 / EBITDA",
  "NTM TEV/EBIT": "未来12个月 总企业价值 / EBIT",
  "NTM P/E": "未来12个月 股价收益率",
  "NTM MC/FCF": "未来12个月 市值 / 自由现金流",
  "NTM P/CFPS": "未来12个月 股价 / 每股现金流",
  "NTM P/FFO": "未来12个月 P/FFO",
  "NTM P/AFFO": "未来12个月 P/AFFO",
  "Mean Analyst Target": "分析师平均目标价",
  "Mean Analyst NAV": "分析师平均目标纯资产价值",
  "LTM TEV/REV": "过去12个月 总企业价值 / 营业额",
  "LTM TEV/EBITDA": "过去12个月 总企业价值 / EBITDA",
  "LTM TEV/EBIT": "过去12个月 总企业价值 / EBIT",
  "LTM P/Diluted EPS before Extra":
    "过去12个月股价 / 扣除非常损益摊薄后每股收益",
  "LTM P/BV": "过去12个月股价 / 每股账面价值",
  "LTM P/Tangible BV": "过去12个月股价 / 每股有形资产账面价值",
  "LTM TEV/Unlevered FCF": "过去12个月总企业价值 / 无杠杆自由现金流",
  "LTM MC/Levered FCF": "过去12个月市值 / 杠杆自由现金流",
  "Quote Currency": "股价货币",
  "Quote Time": "股价时间",
  "Or Click on": "或点击",
  "next to company name to add Ticker to active Watchlist":
    "公司名旁边的❤️将股票添加到自选股列表",
  Watchlist: "自选股列表",
  "Are you sure you want to delete this watchlist?":
    "您确定要删除此自选股列表吗？",
  "Error: Watchlist must have a title": "错误: 自选股列表中必须有标题",
  "Error: watchlist is full - cannot add more than":
    "错误: 自选股列表已满 - 不能添加更多",
  "Please Create a new watchlist": "请创建一个新的自选股列表",
  "from exchange": "股票交易市场",
  "already exists on this watchlist!": "已经存在于该自选股列表！",
  "Error: You've reached your watchlist limit (max":
    "错误: 已达到自选股列表上限(最大值",
  "Please delete another watchlist and try again":
    "请删除另一个自选股列表，然后重试",
  "There has been an error - please try again or contact support":
    "发生错误 - 请重试或联系客服",
  "New Watchlist Title": "新的自选股列表标题",
  "This is the title for your new Watchlist (can change later)":
    "这是您新的自选股列表的标题(以后可以更改)",
  "Description of what watchlist will contain": "自选股列表内容的描述",
  "Describe what this watchlist contains (can change later)":
    "自选股列表内容的描述(以后可以更改)",
  "Select Stock Tickers": "选择股票",
  Back: "后退",
  Next: "前进",
  "Create Watchlist": "创建自选股列表",
  "Create New Watchlist": "创建新的自选股列表",
  "Add Stocks to": "添加股票",
  "Please create an active watchlist in “My Watchlists” before adding a favorite ticker!":
    "在添加收藏股票之前，请在“我的自选股列表”中创建一个自选股列表！",

  "If you want to add any other funds to these lists let us know at <a href='mailto:support@tikr.com'>support@tikr.com</a>":
    "如果你想追加其他基金到这些列表，请联系<a href='mailto:support@tikr.com'>support@tikr.com</a>",
  "Search Investing Gurus": "检索投资大师",
  "Growth at a Reasonable Price (GARP)": "混合型",
  Value: "价值型",
  "Aggressive Growth": "成长型",
  Other: "其他",
  "Investor / Fund Name": "投资人 / 基金名",
  "Rows per page": "每页行数",
  Terms: "条款",
  Privacy: "隐私",

  Zoom: "期间",
  "October 23, 2020 Close": "2020年10月23日 收盘",
  "57% price return over 3 years - 16% CAGR (Compound Annual Growth Rate)":
    "3年区间回报率57% - 复合年增长率16%",
  "Add Tickers to Chart...": "添加股票到走势图",
  "Show Relative Change %": "显示相对变化率",
  "52 WEEK HIGH": "52周高",
  "52 WEEK LOW": "52周低",
  "AVERAGE 3 MONTH DAILY VOLUME": "3个月平均日成交量",
  "SHARES OUTSTANDING": "已发行股票数量",
  "Short Interest %": "卖空比列",
  "MARKET CAP (MM)": "市值",
  "TEV (MM)": "总企业价值",
  "Show Price Chart": "显示走势图",

  "Price Chart Hidden": "价格走势图已隐藏",
  Overview: "概述",
  News: "新闻",
  Financials: "财务",
  Estimates: "预测",
  Consensus: "一致共识",
  Transcripts: "电话会议记录稿",
  Filings: "公司财报",
  Discuss: "讨论",
  Close: "关闭",
  " Close": "关闭",
  "Close Window": "閉じる",
  "Previous close": "前日收盘价",
  "IEX real time price": "IEX实时价格",
  "15 minute delayed price": "15分钟延迟的价格",
  "Business Owner Mode Activated - All Stock Prices Hidden":
    "企业主模式-所有股票价格已隐藏",
  "# of No Opinions": "不持意见的分析师数",
  "NTM = Next Twelve Months &nbsp;&nbsp;&nbsp; LTM = Last Twelve Months":
    "NTM =未来12个月 ＆nbsp;＆nbsp;＆nbsp; LTM =过去12个月",
  "Total Operating Expenses": "总营业费用",
  "Restructuring Charges": "重组费用",

  "Trust Income": "信托收入",
  "Income From Trading Activities": "交易收入",
  "Gain (Loss) on Sale of Invest. & Securities": "出售投资及证券的收益(亏损)",
  "Total Other Non Interest Income": "其他非利息收入合计",
  "Occupancy Expense": "占用费用",
  "Depreciation & Amortization - (IS) - (Collected)": "折旧及摊销",
  "Allowance For Equity Funds Used During Construction":
    "施工期间使用的股本备抵金",
  "Allowance For Borrowed Funds Used During Construction":
    "施工期间使用的借款资金免税额",
  "Depreciation, Depletion & Amortization": "折旧，折耗及摊销",
  "Total Asset Writedown": "总资产减记",
  "Restructuring Activities": "重组活动",
  "Purchase / Sale of Intangible Assets": "购买/出售无形资产",
  "Sale of Property, Plant and Equipment": "出售物业，厂房及设备",
  "Net Increase (Decrease) in Deposit Accounts": "存款账户净增加(减少)",
  "Regulatory Assets": "监管资产",
  "Net Loans": "净贷款",
  "Other Liabilities": "其他负债",
  "Total Other Investments": "其他投资总额",
  "Total investments": "总投资",
  "Investment In Debt and Equity Securities": "债务和股权证券投资",

  "N/A": "N/A",
  "There was an error fetching price quotes and market cap data":
    "获取股价和市值数据时出错",

  "Error Fetching Quotes": "数字获取失败",
  Show: "显示",
  Hide: "隐藏",
  Data: "数据",
  "Hide OHLC": "隐藏OHLC",
  "Show OHLC": "显示OHLC",
  "Show Absolute Value": "显示绝对值",
  "1d": "1天",
  "3m": "3个月",
  "6m": "6个月",
  ytd: "年初至今",
  "1yr": "1年",
  "3yr": "3年",
  "5yr": "5年",
  "10yr": "10年",
  "price return over": "股票收益率",
  Volume: "成交量",
  years: "年",

  "LTM (Last Twelve Months)": "过去12个月",
  "* Annual Financials in Millions of US Dollar from 12/31/12 to 6/30/20":
    "* 从2012年12月31日到2020年6月30日的财务年报(百万美元)",
  "Income Statement": "损益表",
  "Balance Sheet": "资产负债表",
  "Cash Flow Statement": "现金流量表",
  Ratios: "比率",
  "Chart Data": "数据图表",
  "Clear Chart": "清除图表",
  "Show Labels": "显示数据",
  "Hide Chart": "隐藏图表",

  "Error Fetching Data": "提取数据时出错",
  "Select Metric": "选择指标",
  "Select Line Item": "选择线项目",
  "Display Currency:": "显示货币:",
  "Display Units:": "显示单位:",
  "Decimals to Display:": "显示的小数位数:",
  "Reverse Dates": "反转日期",
  "Copy Table": "复制表",
  "Table Options": "表格选项",
  Annual: "年度",
  Quarterly: "季度",
  "Semi Annual": "半年",
  LTM: "LTM",
  "Displayed in thousands": "显示单位千",
  k: "k",
  "Displayed in millions": "显示单位百万",
  MM: "MM",
  "Displayed in billions": "显示单位十亿",
  B: "B",
  "Financials in": "财务显示单位",
  Thousands: "千",
  Millions: "百万",
  Billions: "十亿",
  of: "的",
  "[currencyName]": "[currencyName]",
  from: "从",
  to: "到",
  percent: "百分",
  "Copy Table to Clipboard": "将表格复制到剪贴板",
  CAGR: "复合年增长率",
  "Sorry, we have no financial data for": "非常抱歉，我们没有财务数据",
  "This is not the": "这不是",
  "primary ticker": "主要股票行情",
  "for this company which can lead to differences in per share metrics.":
    "对于这家公司而言，这可能会导致每股指标的差异。",

  "Actuals & Forward Estimates": "实际和预测",
  E: "E",
  A: "A",

  "Street Targets": "分析预测",
  "Price Close": "收盘价",
  "Street Price Targets": "目标价",
  "Target Stock Price Mean": "目标均价",
  "Target Stock Price Median": "目标价中央值",
  "Target Stock Price High": "目标最高价",
  "Target Stock Price Low": "目标最低价",
  "Target Stock Price (# Est)": "目标价(# 预测)",
  "Street Recommendations": "分析师推荐",
  "# of Buys": "买入",
  "# of Outperforms": "优于预期",
  "# of Holds": "持有",
  "# of Underperforms": "低于预期",
  "# of Sells": "卖出",

  "Street Estimate": "分析师预测",
  "Net Asset Value / Share Mean": "每股资产净值平均值",
  "Net Asset Value / Share Median": "每股资产净值中央值",
  "Net Asset Value / Share High": "每股资产净值最高值",
  "Net Asset Value / Share Low": "每股资产净值最低值",
  "Net Asset Value / Share (# Est)": "每股资产净值(# 预测)",
  "Target Stock Price / Close Price": "目标股价 / 收盘价",
  "Price / NAV per Share": "股价 / 每股资产净值",

  "Summary Statistics": "统计概要",
  Mean: "平均值",
  Median: "中央值",
  High: "高值",
  Low: "低值",
  "Standard Deviation": "标准差",
  Count: "统计数",
  "Convert to USD: False (True)": "转化成美元: 是(否)",
  "Show Summary Statistics Below Competitors": "显示统计概要: 是(否)",

  "Next Twelve Months": "未来12个月",
  "Last Twelve Months": "过去12个月",
  Event: "大事公告",
  "Event Date": "大事公告日期",
  "Q2 2020 Earnings Call, Aug 05, 2020": "2020年Q2 电话财报会议 2020年8月5日",
  "Document Title": "文件名",
  "Form Name": "表格种类",
  Language: "语言",
  "Financial Period End Date": "财务结算终止日期",
  "Release Date": "公告日期",

  "Error fetching filings": "获取业绩报告时出错",
  "Document will open in a new tab": "文档将在新标签页中打开",
  "Please make sure popups are not blocked for tikr.com":
    "请确认浏览器没有禁止tikr.com的弹出窗口",
  "Fetching Document!": "正在获取文档！",
  "Filter By Form Name": "按表格名称筛选",

  Shareholders: "股东",
  "Insider Transactions": "内线交易",
  "Filter by Investor Type": "筛选投资人类别",
  "Price Used to Value Shares Held:": "持股价值评估价格:",
  "Holdings Date": "持有日期",
  "Last Close": "最近收盘价",
  "Investor Name": "投资人名",
  "Investor Type": "投资人类别",
  "Investment Advisor": "投资顾问",
  "Investment Advisor/Hedge Fund": "投资顾问 / 对冲基金",
  "Pension Fund": "养老基金",
  "Research Firm": "调研公司",
  "Hedge Fund": "对冲基金",
  "Individual Investor": "个人投资人",
  "Bank and Trust": "银行和信托",
  "Sovereign Wealth Fund": "主权财富基金",
  Foundation: "财团",

  Insider: "内部人员",
  "Number of Shares": "股票数",
  Price: "价格",
  Date: "日期",
  "Number of Shares Outstanding Traded": "流通股交易数",
  "Insider Title": "内部人员头衔",
  "Chief Executive Officer": "首席执行官",
  Officer: "主管",
  Unknown: "不明",
  "Chief Financial Officer": "首席财务官",
  "Executive Vice President": "执行副总裁",
  Type: "类别",
  Filing: "财报",
  Investments: "投资",
  "Error Fetching Holdings for Investor ID": "获取股东信息时出错",
  "Please report to support@tikr.com": "请联系support@tikr.com",
  "Error: No holdings for": "错误: 没有相关股东信息",
  "Please report to support@tikr.com Error: investorId":
    "请联系support@tikr.com 错误:investorId",
  "no holdings": "没有股东信息",
  "Company Name": "公司名",
  Ticker: "股票代号",
  "Reported Value of Shares Held (MM)": "持股价值(百万美元)",
  "% of Shares Outstanding Held": "持股份额",
  "Filing Type": "财报类型",
  "Investment Managers": "投资经理",
  "Endowment Fund": "捐赠基金",
  "Finance Company": "金融公司",
  "Insurance Company": "保险公司",
  "Private Equity": "私募股权基金",
  "Venture Capital": "风险投资基金",
  "Brokerage Firms": "经纪公司",
  "Independent Research Firm": "独立调研公司",
  "Strategic Entities": "战略公司",
  Corporation: "企业",
  "Holding Company": "控股公司",
  "Government Agency": "政府机关",
  "Institution sub types": "机构子类型",
  "Mutual Fund": "共同基金",
  "Hedge Fund Portfolio": "对冲基金投资组合",
  "Pension Fund Portfolio": "养老基金投资组合",

  "Error fetching shareholders": "获取股东信息时出错",
  "Value of Shares Held (MM)": "持有股份价值(MM)",
  "% Of Shares Outstanding Held": "已发行股份持股比例",
  "# Shares Held": "持股数",
  "Change in # of Shares Held": "持股数变化",
  "% Change in # of Shares Held": "持股数变化率",
  "This Holding as % of Firm's Portfolio": "该持股占投资人资产百分比",

  "Financial Data Options": "财务数据选项",
  "Hide All Ticker Share Prices (Business Owner Mode)":
    "隐藏所有股价(企业主模式): 是 (or 否)",
  "Hide Ticker Price Chart after navigating away from company overview":
    "离开公司概要页面后不显示股价走势图: 是 (or 否)",
  "Default Year To Start Financials & Estimates Data":
    "默认财务及预测数据开始年份",
  "App Layout Options": "页面显示选项",
  "Dark Mode Preview": "深色模式预览: 是 (or 否)",
  "Updated Significant Developments Formatting": "格式化重要进展: 是 (or 否)",
  "Remove icons from tabs while researching an equity":
    "标签上不显示图标: 是 (or 否)",
  "News Options": "新闻选项",
  "Referral Code": "邀请码",
  True: "是",
  true: "是",
  False: "否",
  false: "否",
  "Reset Settings": "重置设定",
  "Default News Language": "默认新闻语言",
  "Include English Stories in News Feeds": "在新闻流中包括英语的新闻",
  "App Settings": "App设定",
  "Link copied to clipboard": "链接已复制到剪贴板",
  "Click to copy to clipboard": "点击复制到剪贴板",
  "There is an error with your Referral Code. Please contact with the error message: 'No Referral Code Found'":
    "您的推荐码有误。 请附加以下错误消息请联系我们：“未找到推荐码”",

  MULTIPLES: "倍数",
  Multiples: "倍数",
  Competitors: "竞争对手",
  "Forward Multiples": "预期倍数",
  "NTM Total Enterprise Value / Revenues": "未来12个月总企业价值 / 营业额",
  "NTM Total Enterprise Value / EBITDA": "未来12个月总企业价值 / EBITDA",
  "NTM Total Enterprise Value / EBIT": "未来12个月总企业价值 / EBIT",
  "NTM Price / Normalized Earnings": "未来12个月价格 / 标准化收益",
  "NTM Market Cap / Free Cash Flow": "未来12个月市值 / 自由现金流",
  "NTM Price / Cash Flow Per Share": "未来12个月股价 / 每股现金流",
  "Trailing Multiples": "实际倍数",
  "LTM Total Enterprise Value / Revenues": "过去12个月总企业价值 / 营业额",
  "LTM Total Enterprise Value / EBITDA": "过去12个月总企业价值 / EBITDA",
  "LTM Total Enterprise Value / EBIT": "过去12个月总企业价值 / EBIT",
  "LTM Price / Diluted EPS (P/E)":
    "过去12个月股价 / 扣除非常损益摊薄后每股收益",
  "LTM Price / Book Value per Share": "过去12个月股价 / 每股账面价值",
  "LTM Price / Tangible Book Value per Share":
    "过去12个月股价 / 每股有形资产账面价值",
  "LTM Total Enterprise Value / Unlevered Free Cash Flow":
    "过去12个月总企业价值 / 无杠杆自由现金流",
  "LTM Market Cap / Levered Free Cash Flow": "过去12个月市值 / 杠杆自由现金流",
  "Price Factors": "价格要素",
  "Total Enterprise Value (MM)": "总企业价值(百万美元)",
  "Market Cap (MM)": "市值(百万美元)",
  "Forward Factors": "预期要素",
  "NTM Revenues": "未来12个月营业额",
  "NTM EBITDA": "未来12个月EBITDA",
  "NTM EBIT": "未来12个月EBIT",
  "NTM Normalized Earnings Per Share": "未来12个月每股标准化收益",
  "NTM Free Cash Flow": "未来12个月自由现金流",
  "NTM Cash Flow Per Share": "未来12个月每股自由现金流",
  "Trailing Factors": "实际要素",
  "LTM Revenues": "过去12个月营业额",
  "LTM EBITDA": "过去12个月EBITDA",
  "LTM EBIT": "过去12个月EBIT",
  "LTM Diluted EPS Before Extra": "过去12个月扣除非常损益摊薄后每股收益",
  "LTM Book Value per Share": "过去12个月每股账面价值",
  "LTM Tangible Book Value per Share": "过去12个月每股有形资产账面价值",
  "LTM Unlevered Free Cash Flow": "过去12个月无杠杆自由现金流",
  "LTM Levered Free Cash Flow": "过去12个月杠杆自由现金流",

  "Return Ratios:": "回报率:",
  "Return on Assets %": "资产回报率 %",
  "Return on Capital %": "资本回报率 %",
  "Return On Equity %": "股本回报率 %",
  "Return on Common Equity %": "普通股本回报率 %",
  "Margin Analysis:": "利润分析:",
  "Gross Profit Margin %": "毛利率 %",
  "SG&A Margin %": "销售及一般管理费用 %",
  "EBITDA Margin %": "EBITDA %",
  "EBITA Margin %": "EBITA %",
  "EBIT Margin %": "EBIT %",
  "EBT Margin %": "EBT %",
  "EBT Excl. Non-Recurring Items Margin %": "扣除非经常项目EBT %",
  "Income From Continuing Operations Margin %": "营业收益 %",
  "Net Income Margin %": "纯利润 %",
  "Normalized Net Income Margin %": "标准化净收入 %",
  "Net Avail. For Common Margin %": "普通股权益净收益率 %",
  "Levered Free Cash Flow Margin %": "杠杆自由现金流率 %",
  "Unlevered Free Cash Flow Margin %": "无杠杆自由现金流率 %",
  "Asset Turnover:": "资产周转率:",
  "Asset Turnover": "资产周转率",
  "Fixed Assets Turnover": "固定资产周转率",
  "Receivables Turnover": "应收帐款周转率",
  "Inventory Turnover": "存货周转率",
  "Working Capital Turnover": "营运资金周转率",
  "Short Term Liquidity:": "短期偿债能力:",
  "Current Ratio": "流动比率",
  "Quick Ratio": "速动比率",
  "Dividend Payout %": "股息支付率 %",
  "Dividend Yield %": "股息率 %",
  "Op Cash Flow to Current Liab": "营业现金流短期负债比率",
  "Avg. Cash Conversion Cycle": "平均现金循环周期",
  "Avg. Days Sales Outstanding": "平均应收帐款周转天数",
  "Avg. Days Outstanding Inventory": "平均库存周转天数",
  "Avg. Days Payable Outstanding": "平均应付账款周转天数",
  "Long-Term Solvency:": "长期偿债能力:",
  "Total Debt / Equity": "负债股本比率",
  "Total Debt / Capital": "负债资本比率",
  "Total Liabilities / Total Assets": "负债资产比率",
  "EBIT / Interest Expense": "EBIT / 利息支出",
  "EBITDA / Interest Expense": "EBITDA / 利息支出",
  "(EBITDA - Capex) / Interest Expense": "(EBITDA - 资本性支出) / 利息支出",
  "FFO Interest Coverage": "营运现金流量利息覆盖率",
  "FFO to Total Debt (x)": "营运现金流量总负债倍率(x)",
  "Total Debt / EBITDA": "总负债 / EBITDA",
  "Net Debt / EBITDA": "净负债 / EBITDA",
  "Net Debt / (EBITDA - Capex)": "净负债 / (EBITDA - 资本性支出)",
  "Industry Metrics:": "业界指标:",
  "Nonperforming Loans / Total Loans %": "不良债权 / 总债权 %",
  "Nonperforming Loans / Total Assets %": "不良债权 / 总资产 %",
  "Nonperforming Assets / Total Assets %": "不良资产 / 总资产 %",
  "Nonperforming Assets / Equity %": "不良资产 / 总股本 %",
  "Allowance for Credit Losses / Net Charge-offs %":
    "贷款损失准备金 / 净销帐 %",
  "Allowance for Credit Losses / Nonperforming Loans %":
    "贷款损失准备金 / 不良债权 %",
  "Allowance for Credit Losses / Total Loans %": "贷款损失准备金 / 总债权 %",
  "Net Charge-offs / Total Average Loans %": "净销帐 / 平均总债权 %",
  "Provision for Loan Losses / Net Charge-offs %": "贷款损失减值 / 净销帐 %",
  "Earning Assets / Interest Bearing Liabilities %": "增值资产 / 有息负债 %",
  "Interest Income / Average Assets %": "利息收入 / 平均资产 %",
  "Interest Expense / Average Assets %": "利息支出 / 平均资产 %",
  "Net Interest Income / Average Assets %": "净利息收入 / 平均资产 %",
  "Total Average Common Equity / Total Average Assets %":
    "总平均普通股本 / 总平均资产 %",
  "Total Equity + Allowance for Loan Losses / Total Loans %":
    "总股本+贷款损失准备金 / 总债权 %",
  "Gross Loans / Total Deposits %": "贷款总额 / 总存款 %",
  "Tier 1 Capital Ratio %": "一级资本比率 %",
  "Total Capital Ratio %": "总资本比率 %",
  "Core Tier 1 Capital Ratio %": "核心一级资本比率 %",
  "Tier 2 Capital Ratio %": "二级资本比率 %",
  "Equity Tier 1 Capital Ratio %": "股权一级资本比率 %",

  Revenues: "营收",

  "Finance Div. Revenues": "财务部门收入",
  "Insurance Division Revenues": "保险部门收入",
  "Gain (Loss) on Sale of Assets (Rev)": "资产销售收入(亏损)",
  "Gain (Loss) on Sale of Investment (Rev)": "投资销售收入(亏损)",
  "Interest And Invest. Income (Rev)": "利息及投资收入",
  "Other Revenues": "其他收入",
  "Total Revenues": "总收入",
  "% Change YoY": "同比变化 %",

  "Cost of Goods Sold": "销售成本",

  "Gross Profit": "毛利",
  "% Gross Margins": "毛利率",

  "Selling General & Admin Expenses": "销售及行政开支",
  "Exploration / Drilling Costs": "勘探开采费用",
  "Provision for Bad Debts": "坏帐准备金",
  "Stock-Based Compensation": "股权激励",
  "Pre-Opening Costs": "开业前费用",
  "R&D Expense": "研发费用",
  "R&D Expenses": "研发费用",

  "Depreciation & Amortization": "折旧及摊销",
  "Amortization of Goodwill and Intangible Assets": "商誉及无形资产摊销",
  "Impairment of Oil Gas & Mineral Properties": "石油天然气及矿物资产减值损失",
  "Other Operating Expenses": "其他运营费用",

  "Operating Income": "营业收入",
  "% Operating Margins": "营业收入率",
  "Interest Expense": "利息支出",
  "Interest and Investment Income": "利息及投资收入",
  "Interest And Investment Income": "利息及投资收入",

  "Income (Loss) On Equity Invest.": "股票投资收入(损失)",
  "Currency Exchange Gains (Loss)": "汇兑损益",
  "Other Non Operating Income (Expenses)": "其他非营业收入(损失)",
  "EBT Excl. Unusual Items": "EBT(非常规项目除外)",
  "Merger & Restructuring Charges": "合并及重组费用",

  "Impairment of Goodwill": "商誉减值损失",
  "Gain (Loss) on Sale of Investments": "投资销售收入(损失)",
  "Gain (Loss) on Sale of Investments (Rev)": "投资销售收入(损失)",
  "Gain (Loss) on Sale Of Investments": "投资销售收入(损失)",
  "Gain (Loss) On Sale Of Assets": "资产销售收入(损失)",
  "Gain (Loss) on Sale of Assets": "资产销售收入(损失)",

  "Asset Writedown": "资产减记",
  "In Process R&D Expenses": "进行中研发费用",
  "Insurance Settlements": "保险结算",
  "Legal Settlements": "法律和解",
  "Other Unusual Items": "其他非常规项目",
  "EBT Incl. Unusual Items": "EBT(包含非常规项目)",
  "Income Tax Expense": "所得税费用",
  "Earnings From Continuing Operations": "持续经营收益",
  "Earnings Of Discontinued Operations": "停业部门经营收益",
  "Extraordinary Item & Accounting Change": "非经常项目及会计变更",
  "Net Income to Company": "公司净收入",
  "Minority Interest": "少数股东权益",
  "Net Income": "纯利润",
  "Preferred Dividend and Other Adjustments": "优先股戏及其他调整",
  "Net Income to Common Incl Extra Items": "普通股东纯收益(包含非经常项目)",
  "% Net Income to Common Incl Extra Items Margins":
    "普通股东纯收益率(包含非经常项目) %",
  "Net Income to Common Excl. Extra Items": "普通股东纯收益(非经常项目除外)",
  "% Net Income to Common Excl. Extra Items Margins":
    "普通股东纯收益率(非经常项目除外) %",
  "Supplementary Data:": "补充数据:",
  "Diluted EPS Excl Extra Items": "稀释后每股收益(非经常项目除外)",
  "Weighted Average Diluted Shares Outstanding": "加权平均摊薄流通股",
  "Weighted Average Basic Shares Outstanding": "加权平均流通股",
  "Dividends per Share": "每股股息",
  "Dividends Per Share": "每股股息",
  "Special Dividends Per Share": "每股特别股息",
  "Total Dividends Per Share": "每股总股息",
  "Payout Ratio %": "股息支付率",
  "Basic EPS": "基本每股收益",
  "FFO Payout Ratio %": "运营现金流派息率",
  FFO: "运营现金流",
  EBITDA: "EBITDA",
  EBITDAR: "EBITDAR",
  "Selling and Marketing Expense": "营销费用",
  "General and Administrative Expense": "一般及行政费用",
  "Effective Tax Rate %": "实际税率 %",
  "Distributable Cash per Share (Diluted)": "每股可分配现金(稀释后)",

  "Same Store Sales Growth %": "同店销售增长 %",

  "Interest Income On Loans": "贷款利息收入",
  "Interest Income On Investments": "投资利息收入",
  "Interest Income": "利息收入",
  "Interest On Deposits": "存款利息收入",
  "Total Interest On Borrowings": "借款利息总额",
  "Net Interest Income": "纯利息收入",
  "Non Interest Income Total": "非利息收入合计",
  "Revenues Before Provison For Loan Losses": "贷款损失减值前收入",
  "Provision For Loan Losses": "贷款损失准备金",
  "Compensation And Benefits": "薪酬与福利",
  "Total Other Non Interest Expense": "利息支出外总支出",
  "Non Interest Expense": "非利息支出",

  "Premiums and Annuity Revenues": "保险及年金收入",
  "Total Interest And Dividend Income": "利息及股息总收入",
  "Asset Management Fee": "资产管理费",
  "Non-Insurance Activities Revenues": "非保险业务收入",
  "Policy Benefits": "保险支付",
  "Policy Acquisition /  Underwriting Costs": "保险获客/审核费用",

  "Fuel & Purchased Power": "燃料及外购电力",
  "Operations And Maintenance": "运营及维护",

  "Rental Revenues": "租金收入",
  "Tenant Reimbursements": "租户补偿",
  "Property Management Fee": "物业管理费用",
  "Interest Income On Mortgage Securities": "抵押证券利息收入",
  "Property Expenses": "物业花费",

  "Interest and Dividend Income": "利息及股息收入",
  "Commission and Fees": "佣金及费用",
  "Mortgage Banking": "银行抵押业务",
  "Credit Card Fee": "信用卡费用",
  "Loan Servicing Revenue": "贷款服务收入",
  "Salaries And Other Employee Benefits": "薪酬及其他员工福利",
  "Cost of Services Provided": "提供服务成本",

  "Brokerage Commission": "经纪人佣金",
  "Trading and Principal Transactions": "交易及自营交易",
  "Underwriting & Investment Banking Fee": "审查及投资银行费用",

  "Impairment of Oil, Gas & Mineral Properties": "石油天然气及矿物资产减值损失",
  "Total Depreciation & Amortization": "折旧及摊销总额",
  "Amortization of Deferred Charges": "摊销递延费用",
  "Minority Interest in Earnings": "少数股东权益收益",

  "(Gain) Loss From Sale Of Asset": "资产销售收入(损失)",
  "(Gain) Loss on Sale of Investments": "投资销售收入(损失)",
  "Asset Writedown & Restructuring Costs": "资产减记及重组费用",
  "Net (Increase) Decrease in Loans Originated / Sold - Operating":
    "贷款发行/出售额纯减(增) - 运营",
  "Provision for Credit Losses": "贷款损失准备金",
  "(Income) Loss On Equity Investments": "股票投资收入(损失)",
  "Tax Benefit from Stock Options": "股票期权税收优惠",
  "Provision and Write-off of Bad Debts": "贷款损失准备金及坏账核销",
  "Net Cash From Discontinued Operations": "停业部门经营纯现金收益",
  "Other Operating Activities": "其他经营活动",
  "Change in Trading Asset Securities": "交易资产证券变动",
  "Change In Accounts Receivable": "应收帐款变动",
  "Change In Inventories": "存货变动",
  "Change In Accounts Payable": "应付帐款变动",
  "Change In Income Taxes": "所得税变动",
  "Change In Deferred Taxes": "递延税项变动",
  "Change in Other Net Operating Assets": "其他净经营资产变动",
  "Cash from Operations": "经营现金流",
  "Memo: Change in Net Working Capital": "备忘: 净营运资本变动",
  "Capital Expenditure": "资本支出",
  "Sale of Property, Plant, and Equipment": "固定资产设备出售",
  "Cash Acquisitions": "现金收购",
  Divestitures: "资产剥离",

  "Sale (Purchase) of Real Estate properties": "出售(购买)不动产资产",
  "Sale (Purchase) of Intangible assets": "出售(购买)无形资产",
  "Investment in Marketable and Equity Securities": "流通及股权证券投资",
  "Net (Increase) Decrease in Loans Originated / Sold - Investing":
    "贷款发行/出售额纯减(增) - 投资",
  "Other Investing Activities": "其他投资活动",
  "Cash from Investing": "投资性现金流",

  "Total Debt Issued": "总发行债务",

  "Total Debt Repaid": "总偿还债务",
  "Issuance of Common Stock": "普通股发行",
  "Repurchase of Common Stock": "普通股回购",
  "Issuance of Preferred Stock": "优先股发行",
  "Repurchase of Preferred Stock": "优先股回购",
  "Common Dividends Paid": "已支付普通股息",
  "Preferred Dividends Paid": "已支付优先股息",

  "Special Dividend Paid": "已支付特别股息",
  "Other Financing Activities": "其他财务活动",
  "Cash from Financing": "融资现金",
  "Foreign Exchange Rate Adjustments": "外汇汇率调整",
  "Miscellaneous Cash Flow Adjustments": "杂项现金流调整",
  "Net Change in Cash": "现金净增减额",
  "Cash and Cash Equivalents, Beginning of Period": "期初现金及现金等价物",
  "Cash and Cash Equivalents, End of Period": "期末现金及现金等价物",
  "Cash Interest Paid": "支付利息的现金",
  "Cash Taxes Paid": "支付税的现金",
  "Maintenance CAPEX": "维护性资本支出",
  "Cash Flow per Share": "每股现金流",

  "Sale / Securitization of Accounts Receivable": "应收帐款的销售/证券化",

  "Total Depreciation, Depletion & Amortization": "折旧，折耗及摊销合计",
  "Deferred Policy Acquisition Costs": "递延保单获取成本",
  "Amort. Of Deferred Policy Acq. Costs": "递延保单获取成本摊销",
  "Reinsurance Payable": "应付分保账款",
  "Change in Unearned Revenues": "预收账款变动",
  "Change in insurance Reserves / Liabilities": "保险准备金/保险责任变动",
  "Annuity Receipts": "年金收益",
  "Annuity Payments": "年金支付",

  "Nuclear Fuel Expenditures": "核燃料支出",
  "Contributions to Nuclear Decommissioning Trust": "核废弃信托基金缴款",

  "Cash And Equivalents": "现金及现金等价物",
  "Short Term Investments": "短期投资",
  "Trading Asset Securities": "交易性金融资产",
  "Total Cash And Short Term Investments": "现金及短期投资总额",
  "Accounts Receivable": "应收帐款",
  "Other Receivables": "其他应收帐款",
  "Notes Receivable": "应收票据",
  "Total Receivables": "应收帐款总额",
  Inventory: "库存",
  "Prepaid Expenses": "预付费用",
  "Finance Division Loans and Leases Current": "财务部门贷款及当前租赁",
  "Finance Division Other Current Assets": "财务部门其他流动资产",

  "Loans Held For Sale": "待售贷款",
  "Deferred Tax Assets Current": "递延所得税流动资产",
  "Restricted Cash": "限制性现金",
  "Other Current Assets": "其他流动资产",
  "Total Current Assets": "流动资产总额",
  "Gross Property Plant And Equipment": "固定资产及设备",
  "Accumulated Depreciation": "累计折旧",
  "Net Property Plant And Equipment": "净固定资产及设备",
  "Long-Term Investments": "长期投资",
  "Long-term Investments": "长期投资",
  Goodwill: "商誉",
  "Other Intangibles": "其他无形资产",
  "Finance Division Loans and Leases Long-Term": "财务部门贷款及长期租赁",
  "Finance Division Other Long-Term Assets": "财务部门其他长期资产",

  "Accounts Receivable Long-Term": "长期应收帐款",
  "Loans Receivable Long-Term": "长期应收贷款",
  "Deferred Tax Assets Long-Term": "长期递延税收资产",
  "Deferred Charges Long-Term": "长期递延资产收费",
  "Other Long-Term Assets": "其他长期资产",
  "Total Assets": "总资产",
  "Accounts Payable": "应付账款",
  "Accrued Expenses": "应付费用",
  "Short-Term Borrowings": "短期借款",
  "Short-term Borrowings": "短期借款",

  "Current Portion of Long-Term Debt": "长期债务中本期到期部分",
  "Current Portion of Capital Lease Obligations": "资本租赁中本期到期部分",
  "Finance Division Debt Current": "财务部门短期债务",
  "Finance Division Other Current Liabilities": "财务部门其他短期债务",

  "Current Income Taxes Payable": "本期应付所得税",
  "Current Unearned Revenue": "本期预收账款",
  "Deferred Tax Liability Current": "本期递延所得税负债",
  "Other Current Liabilities": "其他本期负债",
  "Total Current Liabilities": "本期总负债",
  "Long-Term Debt": "长期借款",
  "Capital Leases": "资本租赁",
  "Finance Division Debt Non Current": "财务部门非流动负债",
  "Finance Division Other Non Current Liabilities": "财务部门其他非流动负债",

  "Unearned Revenue Non Current": "长期预收账款",
  "Pension & Other Post Retirement Benefits": "养老金及其他退休后福利",
  "Deferred Tax Liability Non-Current": "长期递延所得税负债",
  "Deferred Tax Liability Non Current": "长期递延所得税负债",
  "Other Non Current Liabilities": "其他长期负债",
  "Total Liabilities": "总负债",
  "Preferred Stock Redeemable": "可赎回优先股",
  "Preferred Stock Non Redeemable": "不可赎回优先股",
  "Preferred Stock Convertible": "可转换优先股",
  "Preferred Stock - Others": "其他优先股",
  "Total Preferred Equity": "总优先股权",

  "Common Stock": "普通股",
  "Additional Paid In Capital": "资本公积",
  "Retained Earnings": "留存收益",

  "Treasury Stock": "库存股",
  "Comprehensive Income and Other": "综合收益及其他收益",
  "Total Common Equity": "普通股本总额",
  "Total Equity": "总股本",
  "Total Liabilities And Equity": "总负债及总股本",
  "Total Shares Out. on Filing Date": "截止财报提交日期时的已发行股合计",
  "Book Value/Share": "每股账面净值",
  "Tangible Book Value": "有形账面价值",
  "Tangible Book Value/Share": "每股有形账面价值",
  "Total Debt": "总负债",
  "Net Debt": "净负债",
  "Total Minority Interest": "少数股东总权益",
  "Equity Method Investments": "适用权益法的投资",
  Land: "土地",
  Buildings: "建筑物",
  "Construction In Progress": "在建工程",
  "Full Time Employees": "全职员工",

  "Investment Securities": "投资证券",
  "Mortgage Backed Securities": "住房抵押贷款证券",
  "Gross Loans": "贷款总额",
  "Allowance For Loan Losses": "贷款损失准备金",
  "Other Adjustments to Gross Loans": "贷款总额的其他调整",
  "Investment in FHLB": "对联邦住房贷款银行的投资",
  "Investment in Real Estate": "房地产投资",
  "Other Real Estate Owned And Foreclosed": "其他房地产及已赎回资产",
  "Interest Bearing Deposits": "有息存款",
  "Institutional Deposits": "机构存款",
  "Non-Interest Bearing Deposits": "无息存款",
  "Total Deposits": "总存款",
  "Federal Home Loan Bank Debt - Long-Term": "长期联邦住房贷款银行负债",
  "Trust Preferred Securities (BS)": "信托优先证券",

  "Investment In Debt Securities": "债券投资",
  "Investment In Equity and Preferred Securities": "股权及优先证券投资",
  "Policy Loans": "政策性贷款",
  "Reinsurance Recoverable - (IS)": "再保险摊回",
  "Deferred Policy Acquisition Costs - (BS)": "递延保单获取成本",
  "Separate Account Assets": "独立账户资产",
  "Insurance And Annuity Liabilities": "保险及年金负债",
  "Unpaid Claims": "未付保险金",
  "Unearned Premiums": "未满期保费",
  "Reinsurance Payable - (BS)": "应付分保账款",

  "Land Held For Development Or Sale": "为开发或出售而持有的土地",
  "Total Real Estate Assets": "总房地产资产",
  "Mortgage Loans": "住房贷款",

  "Cash & Securities Segregated": "现金及分离证券",
  "Securities Purchased Under Agreements To Resell": "根据转售协议购买的证券",
  "Securities Borrowed": "借入证券",

  "Deferred Tax Assets Long-Term (Collected)": "长期递延税收资产",

  "Revenue Actual": "实际营业额",
  "EBITDA Actual": "实际EBITDA",
  "EBIT Actual": "实际EBIT",
  "EBT Normalized Actual": "实际标准化EBT",
  "Net Income Normalized Actual": "实际标准化净收入",
  "EPS Normalized Actual": "实际标准化每股收益",
  "EPS (GAAP) Actual": "实际每股收益(美国通用会计准则)",
  "Gross Margin Actual (%)": "实际毛利率(%)",
  "Interest Expense Actual": "实际利息支出",
  "Depreciation & Amortization Actual": "实际折旧及摊销",
  "Effective Tax Rate Actual (%)": "实际有效税率(%)",
  "EBT (Excl. Excep & GW) Actual": "实际EBT(去除特殊项目及商誉)",
  "EBT (GAAP) Actual": "实际EBT(美国通用会计准则)",
  "Net Income (Excl. Excep & GW) Actual": "纯利润(去除特殊项目及商誉)",
  "Net Income (GAAP) Actual": "实际净收入(美国通用会计准则)",
  "Cash EPS Actual": "实际每股营业现金流",
  "Cash From Operations Actual": "实际运营现金流",
  "Capital Expenditure Actual": "实际资本支出",
  "Free Cash Flow Actual": "实际自由现金流",
  "Cash Flow / Share Actual": "实际每股现金流",
  "Net Debt Actual": "实际纯负债",
  "Book Value / Share Actual": "实际每股账面价值",
  "Net Asset Value Actual": "实际纯资产价值",
  "Net Asset Value / Share Actual": "实际每股纯资产价值",
  "Return on Equity Actual (%)": "实际股本回报率(%)",
  "Return on Assets Actual (%)": "实际资产回报率(%)",
  "Distributable Cash (Income Trusts) Actual": "实际可分配现金",
  "Distributable Cash / Share (Income Trust) Actual": "实际每股可分配现金",
  "FFO (REIT) Actual": "实际运营现金流量",
  "FFO / Share (REIT) Actual": "实际每股运营现金流量",
  "Adjusted FFO / Share (REIT) Actual": "实际每股调整后运营现金流量",
  "Adjusted FFO (REIT) Actual": "实际调整后运营现金流量",
  "Same Store Sales Growth Actual (%)": "实际同店销售增长(%)",
  "Average Daily Production (Oil)  Actual": "实际日均生产量(石油)",
  "Average Daily Production (Gas)  Actual": "实际日均生产量(天然气)",
  "Average Daily Production (NGL)  Actual": "实际日均生产量(液化天然气)",
  "Total Average Daily Production  Actual": "实际总日均生产量",
  "Total Production (Oil) Actual": "实际总生产量(石油)",
  "Total Production (Gas)  Actual": "实际总生产量(天然气)",
  "Total Production (NGL)  Actual": "实际总生产量(液化天然气)",
  "Total Production Actual": "实际总生产量",
  "Revenues (Oil)  Actual": "实际营业额(石油)",
  "Revenues (Gas)  Actual": "实际营业额(天然气)",
  "Revenues (NGL)  Actual": "实际营业额(液化天然气)",
  "Exploration Expense Actual": "实际勘探支出",

  Revenue: "营业额",

  "% EBITDA Margins": "EBITDA营业额比率",
  EBIT: "EBIT",
  "% EBIT Margins": "EBIT营业额比率",
  "EBT Normalized": "标准化EBT",
  "% EBT Margins": "EBT营业额比率",
  "Net Income Normalized": "标准化净收入",
  "% Net Income Margins": "纯利润营业额比率",
  "EPS Normalized": "标准化每股收益",
  "EPS (GAAP)": "每股收益(美国通用会计准则)",
  "Dividend Per Share": "每股股息",
  "IS Supplementary Data:": "损益表补充数据:",
  "Gross Margin (%)": "毛利率(%)",
  "Effective Tax Rate (%)": "有效税率(%)",
  "EBT (Excl. Excep & GW)": "EBT(去除特殊项目及商誉)",
  "EBT (GAAP)": "EBT(美国通用会计准则)",
  "Net Income (Excl. Excep & GW)": "纯利润(去除特殊项目及商誉)",
  "Net Income (GAAP)": "净收入(美国通用会计准则)",
  "Cash EPS": "每股营业现金流",
  "CF Supplementary Data:": "现金流补充数据",
  "Cash From Operations": "运营现金流",
  "Free Cash Flow": "自由现金流",
  "Cash Flow / Share": "每股现金流",
  "BS Supplementary Data:": "资产负载表补充数据",
  "Net Debt/EBITDA": "纯负债 / EBITDA",
  "Book Value / Share": "每股账面价值",
  "Net Asset Value": "纯资产价值",
  "Net Asset Value / Share": "每股纯资产价值",
  "Return on Equity (%)": "股本回报率(%)",
  "Return on Assets (%)": "资产回报率(%)",
  "Other Supplementary Data:": "其他补充数据:",
  "Price Target Mean (Bottom Up)": "目标股价平均值",
  "Distributable Cash (Income Trusts)": "可分配现金",
  "Distributable Cash / Share (Income Trust)": "每股可分配现金",
  "FFO (REIT)": "运营现金流量",
  "FFO / Share (REIT)": "每股运营现金流量",
  "Adjusted FFO / Share (REIT)": "每股调整后运营现金流量",
  "Adjusted FFO (REIT)": "调整后运营现金流量",
  "Same Store Sales Growth (%)": "同店销售增长(%)",
  "Average Daily Production (Oil)": "日均生产量(石油)",
  "Average Daily Production (Gas)": "日均生产量(天然气)",
  "Average Daily Production (NGL)": "日均生产量(液化天然气)",
  "Total Average Daily Production": "总日均生产量",
  "Total Production (Oil)": "总生产量(石油)",
  "Total Production (Gas)": "总生产量(天然气)",
  "Total Production (NGL)": "总生产量(液化天然气)",
  "Total Production": "总生产量",
  "Revenues (Oil)": "营业额(石油)",
  "Revenues (Gas)": "营业额(天然气)",
  "Revenues (NGL)": "营业额(液化天然气)",
  "Exploration Expense": "勘探支出",

  "Sign In to Your Account": "登录到您的帐户",
  Email: "电子邮件",
  "Enter your email": "请输入你的电子邮箱",
  Password: "密码",
  "Enter your password": "请输入密码",
  "Forgot Password?": "忘记密码？",
  "Reset Password": "重设密码",
  "Sign In": "登入",
  "No Account?": "没有账号？",
  "Create Account": "创建帐号",
  "Required Input Missing": "请填写所有必填项",
  "Email Address Invalid": "无效电邮地址",
  "Create New Account": "创建新帐户",
  "Verify Email": "验证邮件",
  "Re-Enter Email": "重新输入电子邮件",
  "Verify Password": "验证密码",
  "Re-Enter Password": "请重新输入密码",
  "Invite Code": "邀请码",
  "Required: Invite Code": "必填：邀请码",
  "Optional: Invite Code": "可选：邀请码",
  "Have an account?": "已有账户？",
  "I accept the": "我接受",
  "Privacy Policy": "隐私政策",
  "and the": "和",
  "Terms of Use": "使用条款",
  "Emails Do Not Match": "电子邮件不匹配",
  "Passwords Do Not Match": "密码不匹配",
  "Must Accept Privacy Policy and Terms of Use": "必须接受隐私政策和使用条款",
  "Verification Code": "验证码",
  "Lost your code? Check Spam Folder or":
    "没找到认证码？ 请检查垃圾邮件文件夹或",
  "Resend Code": "重新发送验证码",
  "Code Resent to": "代码寄给",
  "Back to Sign In": "返回登录",
  "User is already confirmed.": "用户已被确认。",
  "Code Resent": "认证码已重发",
  "Send Reset Code": "发送重置码",
  "Enter Email to Reset Password": "请输入电子邮件重置密码",
  "Confirm Email Verification Code Sent To": "电子邮件验证码已发送至",
  "Username cannot be empty": "用户名不能为空",
  "Must be at least 6 characters long": "必须至少6个字符长",
  Required: "必填项",
  "Incorrect username or password.": "用户名或密码错误。",
  "User does not exist.": "用户不存在。",
  "User already exists": "用户已存在",
  "Username/client id combination not found.": "找不到用户名/客户ID组合。",
  "Invalid verification code provided, please try again.":
    "提供的验证码无效，请重试。",
  "Financial Data": "财务数据",

  Filter: "筛选",
  "Clear Filter": "解除筛选",
  "Error Fetching News ... Try Refreshing the Page!":
    "提取新闻时出错，请尝试刷新页面！",
  "Close Story": "关闭新闻",
  "Loading News Story...": "正在载入新闻...",

  "Sorry, We couldn't find any news for": "非常抱歉，我们找不到任何新闻",
  "Significant Developments": "重大进展",
  "Error Fetching Significant Developments": "获取重大进展时出错",
  reuters: "路透社",
  "More News": "更多新闻",

  "Hide Labels": "隐藏数据",
  "Click on row in table below to chart": "点击下表中的行以绘制图表",
  "Convert to USD:": "转换为美元：",
  column: "列",
  line: "线",
  Average: "平均",
  Last: "收盘价",

  Region: "地区",
  "Select Region": "选择地区",
  "Asia / Pacific": "亚太地区",
  "United States and Canada": "美国和加拿大",
  Europe: "欧洲",
  "Africa / Middle East": "非洲/中东",
  "Latin America and Caribbean": "拉丁美洲和加勒比",

  "Select Period": "选择期间",
  "Select Relative Period": "选择相对期间",
  "Select Fiscal Year": "选择会计年度",
  "Select Calendar Year": "选择日历年",
  "Select Quarter": "选择季度",
  "Fiscal Year": "财年",
  FY: "财年",
  "Calendar Year": "历年",
  CY: "历年",
  "Fiscal Quarter": "财政季度",
  FQ: "财政季度",

  "Select Value Type": "选择值类型",
  "Mean Consensus Estimate": "一致预期平均值",
  "Median Consensus Estimate": "一致预期中央值",
  Actual: "实际",
  "Reported Actual": "实际报告",
  "High Consensus Estimate": "一致预期高值",
  "Low Consensus Estimate": "一致预期低值",
  "Number of Consensus Estimates": "一致预期数",
  "Consensus Estimate Standard Deviation": "一致预期标准偏差",
  "Management Guidance Mid": "公司预期中值",
  "Management Guidance High": "公司预期高值",
  "Management Guidance Low": "公司预期低值",
  "Market Capitalization": "市值",
  "Total Enterprise Value": "企业总价值",
  "Last Close Price": "最后收盘价",
  "Year Founded": "成立年份",

  "equal to": "等于",
  "not equal to": "不等于",
  "greater than": "大于",
  "greater than or equal to": "大于或等于",
  "less than": "少于",
  "less than or equal to": "小于或等于",
  between: "之间",
  is: "是",
  "is not": "不是",
  "is one of": "是其中之一",
  "is not one of": "不是其中之一",
  any: "任何",
  all: "全部",
  and: "和",
  or: "或者",
  ">": ">",
  ">=": ">=",
  "<": "<",
  "<=": "<=",
  "=": "=",

  "Sorry! Error searching for": "很抱歉！检索出错",

  Industry: "行业",
  "Select Industry": "选择行业",
  "Capital Markets": "资本市场",
  "Metals and Mining": "金属和采矿",
  "Real Estate Management and Development": "房地产管理与开发",
  "Food Products": "食品",
  Machinery: "机械",
  Chemicals: "化学",
  "Electronic Equipment, Instruments and Components": "电子设备，仪器及零件",
  Banks: "银行",
  Software: "软件",
  "Oil, Gas and Consumable Fuels": "石油，天然气和消耗性燃料",
  Pharmaceuticals: "医药品",
  "Textiles, Apparel and Luxury Goods": "纺织品，服装和奢侈品",
  "Construction and Engineering": "建筑与土木工程",
  "Hotels, Restaurants and Leisure": "酒店，餐厅和休闲",
  "IT Services": "IT服务",
  Biotechnology: "生物技术",
  "Commercial Services and Supplies": "商业服务和用品",
  "Electrical Equipment": "电子设备",
  "Semiconductors and Semiconductor Equipment": "半导体及半导体制造设备",
  "Healthcare Equipment and Supplies": "医疗设备及用品",
  "Equity Real Estate Investment Trusts (REITs)": "房地产投资信托(REITs)",
  Media: "媒体",
  "Auto Components": "汽车零部件",
  Entertainment: "娱乐",
  "Household Durables": "家用耐用品",
  "Trading Companies and Distributors": "贸易公司和分销商",
  "Specialty Retail": "零售专卖",
  "Healthcare Providers and Services": "医疗保健提供者和医疗服务",
  Insurance: "保险",
  "Diversified Financial Services": "各种金融服务",
  "Professional Services": "专业服务",
  "Communications Equipment": "通讯设备",
  "Construction Materials": "建筑材料",
  "Food and Staples Retailing": "食品和生活必需品零售",
  "Building Products": "建筑相关产品",
  "Containers and Packaging": "容器与包装",
  "Personal Products": "个人用品",
  "Independent Power and Renewable Electricity Producers":
    "独立电力和可再生电力生产商",
  "Diversified Consumer Services": "各种消费者服务",
  "Energy Equipment and Services": "能源设备与服务",
  "Consumer Finance": "消费金融",
  "Internet and Direct Marketing Retail": "互联网和直销零售",
  Distributors: "分销商",
  "Interactive Media and Services": "互动媒体与服务",
  Beverages: "饮料",
  "Technology Hardware, Storage and Peripherals": "硬件，存储和外围设备",
  "Diversified Telecommunication Services": "各种电信服务",
  "Paper and Forest Products": "纸和林产品",
  "Road and Rail": "陆运及铁道运输",
  "Electric Utilities": "电力",
  "Transportation Infrastructure": "运输基础设施",
  "Aerospace and Defense": "航空航天与国防",
  "Thrifts and Mortgage Finance": "储蓄和抵押金融",
  "Air Freight and Logistics": "空运和物流",
  "Health Care Technology": "卫生保健技术",
  "Industrial Conglomerates": "工业集团",
  Marine: "海运",
  "Leisure Products": "休闲用品",
  "Life Sciences Tools and Services": "生命科学工具和服务",
  "Multiline Retail": "多元化零售",
  Automobiles: "汽车",
  "Gas Utilities": "煤气公用事业",
  "Water Utilities": "水道",
  "Wireless Telecommunication Services": "无线电信服务",
  Airlines: "旅客航空运输业",
  "Household Products": "家庭用品",
  Tobacco: "烟草",
  "Mortgage Real Estate Investment Trusts (REITs)":
    "抵押房地产投资信托（REIT）",
  "Multi-Utilities": "综合公益事业",

  Subscription: "订阅",
  "Current TIKR Subscription": "当前的TIKR订阅",
  "If your subscription expires your account will automatically be downgraded to free TIKR.com access":
    "如果您的订阅到期，您的帐户将自动降级为免费版TIKR.com",
  "Subscription Level": "订阅等级",
  "Subscription Expiration": "订阅有效期",
  "Subscription Auto Renew": "订阅自动续订",
  "Subscription Will Not Automatically Renew": "订阅不会自动续订",
  "Subscription Will Renew On": "订阅将续订",
  "This Subscription Does Not Expire": "此订阅不会过期",
  "Premium TIKR.com Membership": "高级TIKR.com会员资格",
  "Premium TIKR.com Trial Membership": "高级TIKR.com试用会员资格",
  "U.S. and Canada Basic TIKR.com Membership":
    "美国和加拿大的基础版TIKR.com会员资格",
  "Europe Basic TIKR.com Membership": "欧洲的基础版TIKR.com会员资格",
  "Asia Basic TIKR.com Membership": "亚洲的基础版TIKR.com会员资格",
  "LatAm / Africa / Other Basic TIKR.com Membership":
    "拉美/非洲/其他地区的基础版TIKR.com会员资格",
  "Beta Trial TIKR.com Membership": "Beta试用版TIKR.com会员资格",
  "Free TIKR.com Membership": "免费TIKR.com会员资格",

  "Exclusive Beta User Discount": "独家Beta版用户折扣",
  "30% Off Annual Membership!": "年度会员可享受30％的折扣！",
  "25% Off Annual Membership!": "年度会员可享受25％的折扣！",
  "20% Off Annual Membership!": "年度会员可享受20％的折扣！",
  "10% Off Annual Membership!": "年度会员可享受10％的折扣！",

  "To thank you for your feedback and contributions during our beta. We want to offer you a <b>special reduced price for a 12 month subscription</b>":
    "感谢您在Beta测试期间的反馈和贡献。 我们想为您提供<b>12个月的订阅特价优惠</ b>",
  "This subscription fee will go directly to paying the substantial server and data costs that power TIKR. To be completely transparent we have been funding these large costs out of our own pockets thus far as we really believe in what we are doing! Your support before our launch would go a long way.":
    "该订阅费将直接用于支付为TIKR的服务器费用以及获取数据成本。因为我们坚信自己正在做的事业，所以到目前为止我们一直自掏腰包来支付这些费用！在我们正式推出这款产品之前，您的支持将对我们有巨大的帮助。",
  "You have been a very important part of our journey so far, and we are excited to continue building TIKR in the coming months and years to be exactly the product you have always wanted (and more!).":
    "到目前为止，您一直是我们旅程中非常重要的一部分，我们很高兴在接下来的几个月甚至几年继续开发TIKR，使其成为您真正想要的产品（甚至更多！）。",

  "Get <b>20% off TIKR.com Premium or TIKR.com Basic 12 month membership</b> when you purchase today!":
    "立即购买，即可享受<b>TIKR.com高级或基础版12个月会员资格20％的折扣</ b>！",
  "Select your membership level below and click 'Proceed to Payment":
    "在下面选择您的会员级别，然后点击“继续付款",

  "Step 1 of 2": "步骤1/2",
  "Step 2 of 2": "步骤2/2",
  PREMIUM: "高级会员",
  "Select Exclusive Prelaunch Subscription": "选择特别优惠的预发行订阅",
  "Purchase TIKR.com subscription": "购买TIKR.com订阅",
  "Purchase Subscription": "购买订阅",
  "Select Membership to Continue": "选择会员资格以继续",
  "Proceed to Payment": "继续付款",
  "Select Region to Proceed": "选择地区",
  "Select Payment Option Above": "选择付款方式",
  "U.S. and Canada": "美国和加拿大",
  Asia: "亚洲",
  "LatAm / Africa / Other": "拉美/非洲/其他地区",
  "You have successfully created subscription": "您已成功创建订阅",

  "Global Data Coverage (50,000+ companies)": "全球数据覆盖（50,000多家公司）",
  "<b>15 years</b> Detailed Data": "<b>15年</ b>的详细数据",
  "Industry Metrics (<b>NAV / FFO / AFFO</b>)":
    "行业指标（<b> NAV / FFO / AFFO </ b>）",
  "Good through <s>June 1st 2022</s> <b>Dec 31st 2022</b>":
    "</s>2022年6月1日</s><b>2022年12月31日</b>前有效",
  "Good through": "有效",
  "Less than <s>$42</s> <b>$28 / Month</b>": "少于<s>$42</s><b>$28 /月</b>",
  BASIC: "基础版",
  "All Data For 1 Region (choose below)": "1个地区的所有数据（在下面选择）",
  "<b>10 years</b> Detailed Data": "<b>10年</ b>详细数据",
  "Please select from the options to complete": "请从选项中选择以完成",
  payment: "支付",
  "By completing payment you agree to the": "完成付款，即表示您同意",
  "Upgrade your subscription to copy data. 3 years at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    '升级您的订阅以复制数据。主TIKR数据 "每次3年，"辅助数据 "每次30年',
  "Upgrade your subscription to copy data up to 3 years at a time":
    "升级你的订阅，一次可复制多达3年的数据",
  "You can copy 3 years of data at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    '您可以为 "主TIKR数据 "一次复制3年的数据，为 "辅助数据 "一次复制多达30年的数据。',
  "You can copy only 3 years of data at a time": "你一次只能复制3年的数据",
}
