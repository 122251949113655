/**
 * finDidToMetricKey
 *
 * as of Nov 2nd 2022, after switching this app to utilize the finDidToMetricKey
 * object in an attempt to remove dataitemid from use on the frontend and make
 * the vuex store easier to visually inspect for new developers we have a problem
 *
 * There were a couple metrics that appear multiple times for one templatetypeid
 * that have the exact same name - "net income" and "minority interest" tipped us
 * off to the problem.
 *
 * The complciation is that, in the finDidtoMetricKey object, due to the various
 * reportingtemplatetypeid's there are and should be repeating metric names
 * due to the crazy part where capiq will use 2 different dataitemids for the same
 * metric across different template types.
 *
 * But, if within one template... there are two dataitems being accessed that have
 * the same name... then the metricObj will overwrite a metric with data.
 * So... I kind of need to figure that out, are there repeats for a metric within
 * is / bs / cf for a given reportingtemplateid?
 *
 * the financialTemplate flow is not set up well to figure this
 * out... hmm
 *
 * When an entry of finDidtoMetricKey is commented out we have had an
 * issue with that dataitemid probably because the assigned metric is
 * repeated for another dataitemid we do have data for
 */
// LINK static/ciq_worker.js:14

const finDidToMetricKey = {
  4: "ebt excl. unusual items 4",
  5: "ebt excl. unusual items 5",
  7: "earnings from continuing operations 7",
  9: "basic eps 9",
  10: "gross profit 10",
  11: "total interest on borrowings 11",
  13: "interest expense 13",
  15: "net income_is 15",
  16: "net income to common incl extra items 16",
  17: "net interest income 17",
  21: "operating income 21",
  22: "operating income 22",
  24: "interest expense 24",
  25: "interest income 25",
  26: "non interest expense 26",
  27: "non interest income total 27",
  28: "total revenues 28",
  29: "total revenues 29",
  31: "amortization of goodwill and intangible assets_is 31",
  32: "asset writedown 32",
  33: "asset writedown 33",
  34: "cost of goods sold 34",
  35: "property expenses 35",
  36: "policy acquisition /  underwriting costs 36",
  37: "cost of services provided 37",
  38: "currency exchange gains (loss) 38",
  39: "currency exchange gains (loss) 39",
  40: "earnings of discontinued operations 40",
  41: "depreciation & amortization_is 41",
  42: "extraordinary item & accounting change 42",
  43: "extraordinary item & accounting change 43",
  46: "(income) loss on equity invest. 46",
  47: "income (loss) on equity invest. 47",
  48: "income (loss) on equity invest. 48",
  49: "exploration / drilling costs 49",
  50: "interest expense - finance division 50",
  51: "finance div. operating exp. 51",
  52: "finance div. revenues 52",
  55: "gain (loss) on sale of assets_is 55",
  56: "gain (loss) on sale of assets 56",
  57: "gain (loss) on sale of assets 57",
  60: "gain (loss) on sale of investments 60",
  61: "gain (loss) on sale of invest. & securities 61",
  62: "gain (loss) on sale of investments 62",
  63: "premiums and annuity revenues 63",
  64: "total interest and dividend income 64",
  65: "interest and investment income 65",
  66: "interest and investment income 66",
  67: "(income) loss on real estate property 67",
  68: "income (loss) on real estate property 68",
  69: "insurance division operating expenses 69",
  70: "insurance division revenues 70",
  71: "impairment of oil gas & mineral properties 71",
  72: "in process r&d expenses 72",
  73: "insurance settlements 73",
  74: "insurance settlements 74",
  75: "income tax expense 75",
  76: "interest income on investments 76",
  77: "legal settlements 77",
  78: "legal settlements 78",
  79: "total mortgage banking activities 79",
  82: "interest expense 82",
  83: "minority interest_is 83",
  84: "non operating (income) expenses 84",
  85: "other non operating income (expenses) 85",
  86: "other non operating income (expenses) 86",
  87: "other unusual items 87",
  88: "other unusual items 88",
  89: "other operating expenses 89",
  90: "other revenues 90",
  91: "other revenues 91",
  92: "total other non interest expense 92",
  93: "total other non interest income 93",
  94: "policy benefits 94",
  95: "provision for bad debts 95",
  96: "pre-opening costs 96",
  97: "preferred dividend and other adjustments 97",
  99: "restructuring charges 99",
  100: "r&d expenses 100",
  101: "stock-based compensation (is) 101",
  102: "selling general & admin expenses 102",
  103: "selling general & admin expenses 103",
  104: "gain (loss) on sale of assets (rev) 104",
  105: "gain (loss) on sale of assets 105",
  106: "gain (loss) on sale of investment (rev) 106",
  107: "gain (loss) on sale of investments 107",
  108: "gain (loss) on sale of loans & receivables 108",
  109: "interest and dividend income 109",
  110: "interest and invest. income (rev) 110",
  111: "interest and investment income 111",
  112: "revenues 112",
  114: "allowance for borrowed funds used during construction 114",
  116: "allowance for equity funds used during construction 116",
  120: "asset management fee 120",
  125: "brokerage commission 125",
  126: "credit card fee 126",
  128: "commission and fees 128",
  135: "depreciation & amortization_is 135",
  139: "ebt incl. unusual items 139",
  142: "diluted eps excl extra items 142",
  152: "income (loss) on equity invest. 152",
  153: "salaries and other employee benefits 153",
  160: "federal deposit insurance 160",
  195: "(gain) loss on sale of loans 195",
  196: "gain (loss) on sale of loans 196",
  197: "amort. of goodwill & intang. assets 197",
  199: "underwriting & investment banking fee 199",
  205: "interest on deposits 205",
  209: "impairment of goodwill 209",
  212: "interest income on loans 212",
  215: "interest income on mortgage securities 215",
  237: "loan servicing revenue 237",
  241: "mortgage banking 241",
  244: "property management fee 244",
  247: "non-insurance activities expenses 247",
  248: "non-insurance activities revenues 248",
  250: "occupancy expense 250",
  255: "non operating income (expenses) 255",
  260: "other operating expenses 260",
  277: "provision for loan losses 277",
  278: "operations and maintenance 278",
  290: "trading and principal transactions 290",
  293: "tenant reimbursements 293",
  294: "reinsurance income or expense 294",
  298: "service charges on deposits 298",
  334: "rental revenues 334",
  336: "special dividends per share 336",
  337: "income from trading activities 337",
  338: "trust income 338",
  342: "weighted average diluted shares outstanding 342",
  356: "operating income 356",
  358: "other revenues 358",
  359: "provision for loan losses 359",
  360: "revenues before provison for loan losses 360",
  362: "credit card fee 362",
  363: "merger & restructuring charges 363",
  364: "total operating expenses 364",
  365: "total revenues 365",
  366: "compensation and benefits 366",
  367: "merger & restructuring charges 367",
  370: "net interest income 370",
  372: "other non operating income (expenses) 372",
  379: "net income to common excl. extra items 379",
  380: "total operating expenses 380",
  395: "amortization of goodwilland intangible assets_is 395",
  410: "revenues 410",
  411: "fuel & purchased power 411",
  1001: "total receivables 1001",
  1002: "total cash and short term investments 1002",
  1003: "net loans 1003",
  1004: "net property plant and equipment 1004",
  1005: "total preferred equity 1005",
  1006: "total common equity 1006",
  1007: "total assets 1007",
  1008: "total current assets 1008",
  1009: "total current liabilities 1009",
  1010: "total deposits 1010",
  1011: "total investments 1011",
  1012: "total liabilities 1012",
  1013: "total liabilities and equity 1013",
  1015: "total real estate assets 1015",
  1016: "accrued expenses 1016",
  1017: "accrued expenses 1017",
  1018: "accounts payable 1018",
  1019: "accounts payable 1019",
  1020: "accounts receivable 1020",
  1021: "accounts receivable 1021",
  1022: "comprehensive income and other 1022",
  1024: "current portion of long-term debt 1024",
  1025: "deferred charges long-term 1025",
  1026: "deferred tax assets long-term 1026",
  1027: "deferred tax liability non current 1027",
  1028: "comprehensive income and other 1028",
  1029: "finance division other current assets 1029",
  1030: "finance division debt current 1030",
  1031: "finance division other current liabilities 1031",
  1032: "finance division loans and leases current 1032",
  1033: "finance division loans and leases long-term 1033",
  1034: "finance division other long-term assets 1034",
  1035: "finance division debt non current 1035",
  1036: "finance division other non current liabilities 1036",
  1037: "insurance and annuity liabilities 1037",
  1038: "long-term investments 1038",
  1039: "investment in equity and preferred securities 1039",
  1040: "other intangibles 1040",
  1041: "other intangibles 1041",
  1043: "inventory 1043",
  1044: "investment securities 1044",
  1045: "loans and lease receivables 1045",
  1046: "short-term borrowings 1046",
  1047: "short-term borrowings 1047",
  1048: "notes receivable 1048",
  1049: "long-term debt 1049",
  1050: "loans receivable long-term 1050",
  1051: "mortgage backed securities 1051",
  1052: "minority interest_bs 1052",
  1053: "minority interest_bs 1053",
  1054: "long-term investments 1054",
  1055: "other current assets 1055",
  1056: "other current assets 1056",
  1057: "other current liabilities 1057",
  1058: "other current liabilities 1058",
  1059: "total other investments 1059",
  1060: "other long-term assets 1060",
  1061: "other long-term assets 1061",
  1062: "other non current liabilities 1062",
  1063: "other non current liabilities 1063",
  1064: "other receivables 1064",
  1065: "preferred stock - others 1065",
  1066: "preferred stock - others 1066",
  1067: "reinsurance payable - (bs) 1067",
  1068: "reinsurance recoverable - (is) 1068",
  1069: "short term investments 1069",
  1071: "trust preferred securities (bs) 1071",
  1073: "unpaid claims 1073",
  1074: "unearned revenue current 1074",
  1075: "accumulated depreciation 1075",
  1078: "accrued interest receivable 1078",
  1080: "accrued interest payable 1080",
  1081: "allowance for loan losses 1081",
  1084: "additional paid in capital 1084",
  1088: "accounts receivable long-term 1088",
  1090: "current portion of capital lease obligations 1090",
  1091: "interest bearing deposits 1091",
  1092: "non-interest bearing deposits 1092",
  1094: "current income taxes payable 1094",
  1096: "cash and equivalents 1096",
  1103: "common stock 1103",
  1104: "restricted cash 1104",
  1108: "assets of discontinued operations current 1108",
  1112: "deferred charges long-term 1112",
  1114: "distributions in excess of earnings 1114",
  1115: "deferred policy acquisition costs - (bs) 1115",
  1117: "deferred tax assets current 1117",
  1118: "deferred tax assets long-term (collected) 1118",
  1119: "deferred tax liability current 1119",
  1120: "deferred tax liability non-current 1120",
  1165: "federal home loan bank debt - long-term 1165",
  1167: "investment in fhlb 1167",
  1169: "gross property plant and equipment 1169",
  1171: "goodwill 1171",
  1175: "investment in debt securities 1175",
  1176: "investment in debt and equity securities 1176",
  1179: "inventory 1179",
  1181: "investment in real estate 1181",
  1183: "capital leases 1183",
  1184: "land held for development or sale 1184",
  1185: "loans held for sale 1185",
  1188: "gross loans 1188",
  1189: "short-term borrowings 1189",
  1190: "notes receivable (collected) 1190",
  1191: "loans receivable long-term 1191",
  1192: "assets of discontinued operations long-term 1192",
  1193: "long-term debt 1193",
  1194: "long-term investments 1194",
  1197: "mortgage loans 1197",
  1200: "net nuclear fuel 1200",
  1202: "other current liabilities 1202",
  1205: "other non current liabilities 1205",
  1206: "other receivables 1206",
  1207: "other real estate owned and foreclosed 1207",
  1208: "other adjustments to gross loans 1208",
  1211: "policy loans 1211",
  1212: "prepaid expenses 1212",
  1213: "pension & other post retirement benefits 1213",
  1214: "preferred stock convertible 1214",
  1216: "preferred stock non redeemable 1216",
  1217: "preferred stock redeemable 1217",
  1220: "regulatory assets 1220",
  1222: "retained earnings 1222",
  1223: "real estate owned 1223",
  1230: "separate account assets 1230",
  1231: "separate account liability 1231",
  1234: "securities borrowed 1234",
  1235: "cash & securities segregated 1235",
  1236: "securities owned 1236",
  1240: "securities purchased under agreements to resell 1240",
  1242: "short term investments 1242",
  1244: "trading asset securities 1244",
  1245: "institutional deposits 1245",
  1248: "treasury stock 1248",
  1252: "unearned premiums 1252",
  1255: "current unearned revenue 1255",
  1256: "unearned revenue non current 1256",
  1268: "other liabilities 1268",
  1275: "total equity 1275",
  1276: "total liabilities 1276",
  1277: "deposits 1277",
  1297: "current portion of long-term debt 1297",
  1310: "tangible book value 1310",
  1312: "total minority interest 1312",
  2002: "depreciation, depletion & amortization 2002",
  2004: "cash from financing 2004",
  2005: "cash from investing 2005",
  2006: "cash from operations 2006",
  2010: "memo: change in net working capital 2010",
  2014: "amortization of deferred charges 2014",
  2015: "amortization of deferred charges 2015",
  2016: "amortization of goodwill and intangible assets 2016",
  2017: "change in accounts payable 2017",
  2018: "change in accounts receivable 2018",
  2019: "asset writedown & restructuring costs 2019",
  2020: "total asset writedown 2020",
  2021: "capital expenditure 2021",
  2022: "common & preferred stock dividends paid 2022",
  2026: "(gain) loss from sale of asset 2026",
  2027: "investment in marketable and equity securities 2027",
  2028: "investment in marketable and equity securities 2028",
  2029: "sale (purchase) of intangible assets 2029",
  2030: "purchase / sale of intangible assets 2030",
  2031: "impairment of oil, gas & mineral properties 2031",
  2032: "net (increase) decrease in loans originated / sold - investing 2032",
  2033: "net (increase) decrease in loans originated / sold - operating 2033",
  2040: "sale (purchase) of real estate properties 2040",
  2041: "special dividend paid 2041",
  2042: "sale of property, plant, and equipment 2042",
  2045: "change in other net operating assets 2045",
  2046: "other operating activities 2046",
  2047: "other operating activities 2047",
  2048: "other investing activities 2048",
  2049: "other financing activities 2049",
  2050: "other financing activities 2050",
  2051: "other investing activities 2051",
  2055: "cash and cash equivalents, beginning of period 2055",
  2056: "cash and cash equivalents, end of period 2056",
  2057: "cash acquisitions 2057",
  2059: "amort. of deferred policy acq. costs 2059",
  2060: "changes in accrued interest payable 2060",
  2061: "changes in accrued interest receivable 2061",
  2065: "annuity payments 2065",
  2066: "change in accounts payable 2066",
  2067: "change in accounts receivable 2067",
  2068: "annuity receipts 2068",
  2074: "common dividends paid 2074",
  2077: "divestitures 2077",
  2078: "net increase (decrease) in deposit accounts 2078",
  2079: "deferred charges 2079",
  2081: "net cash from discontinued operations 2081",
  2082: "deferred policy acquisition costs 2082",
  2084: "change in deferred taxes 2084",
  2086: "(income) loss on equity investments 2086",
  2089: "(gain) loss on sale of asset 2089",
  2090: "(gain) loss on sale of investments 2090",
  2091: "gain (loss) on sale of loans & receivables 2091",
  2093: "net change in cash 2093",
  2095: "change in insurance reserves / liabilities 2095",
  2099: "change in inventories 2099",
  2101: "change in income taxes 2101",
  2103: "net (increase) decrease in loans originated / sold operating - 2103",
  2107: "minority interest in earnings 2107",
  2108: "contributions to nuclear decommissioning trust 2108",
  2109: "nuclear fuel expenditures 2109",
  2111: "provision and write-off of bad debts 2111",
  2112: "provision for credit losses 2112",
  2114: "capital expenditure 2114",
  2116: "preferred dividends paid 2116",
  2120: "restructuring activities 2120",
  2121: "reinsurance payable 2121",
  2122: "reinsurance recoverable 2122",
  2123: "sale (purchase) of real estate properties 2123",
  2124: "acquisition of real estate assets 2124",
  2125: "sale of real estate assets 2125",
  2126: "sale / securitization of accounts receivable 2126",
  2127: "stock-based compensation 2127",
  2130: "sale of property, plant and equipment 2130",
  2134: "change in trading asset securities 2134",
  2135: "tax benefit from stock options 2135",
  2139: "change in unearned revenues 2139",
  2140: "change in other net operating assets 2140",
  2144: "foreign exchange rate adjustments 2144",
  2149: "miscellaneous cash flow adjustments 2149",
  2150: "net income_cf 2150",
  2159: "issuance of common stock 2159",
  2160: "total depreciation & amortization 2160",
  2161: "total debt issued 2161",
  2162: "total debt issued 2162",
  2163: "total debt repaid 2163",
  2164: "repurchase of common stock 2164",
  2165: "repurchase of common stock 2165",
  2166: "total debt repaid 2166",
  2169: "issuance of common stock 2169",
  2170: "repurchase of preferred stock 2170",
  2171: "depreciation & amortization 2171",
  2172: "repurchase of preferred stock 2172",
  2174: "issuance of preferred stock 2174",
  2181: "issuance of preferred stock 2181",
  2182: "amortization of goodwill and intangible assets 2182",
  2185: "total depreciation, depletion & amortization 2185",
  3023: "buildings 3023",
  3028: "cash interest paid 3028",
  3053: "cash taxes paid 3053",
  3058: "dividends per share 3058",
  3063: "equity method investments 3063",
  3074: "ffo 3074",
  3085: "full time employees 3085",
  3098: "land 3098",
  3168: "r&d expense 3168",
  3195: "same store sales growth % 3195",
  3217: "weighted average basic shares outstanding 3217",
  4001: "receivables turnover 4001",
  4020: "book value/share 4020",
  4030: "current ratio 4030",
  4034: "total debt / equity 4034",
  4035: "avg. days outstanding inventory 4035",
  4037: "dividend payout % 4037",
  4038: "dividend yield % 4038",
  4042: "avg. days sales outstanding 4042",
  4047: "ebitda margin % 4047",
  4051: "ebitda 4051",
  4053: "ebit margin % 4053",
  4066: "fixed assets turnover 4066",
  4074: "gross profit margin % 4074",
  4082: "inventory turnover 4082",
  4094: "net income margin % 4094",
  4117: "ebt excl. non-recurring items margin % 4117",
  4121: "quick ratio 4121",
  4128: "return on equity % 4128",
  4156: "tangible book value/share 4156",
  4173: "total debt 4173",
  4178: "return on assets % 4178",
  4181: "income from continuing operations margin % 4181",
  4182: "net avail. for common margin % 4182",
  4183: "avg. days payable outstanding 4183",
  4184: "avg. cash conversion cycle 4184",
  4185: "op cash flow to current liab 4185",
  4188: "total liabilities / total assets 4188",
  4189: "ebit / interest expense 4189",
  4190: "ebitda / interest expense 4190",
  4191: "(ebitda - capex) / interest expense 4191",
  4192: "total debt / ebitda 4192",
  4193: "net debt / ebitda 4193",
  4278: "ebt margin % 4278",
  4279: "nonperforming loans / total loans % 4279",
  4280: "nonperforming loans / total assets % 4280",
  4281: "nonperforming assets / total assets % 4281",
  4282: "allowance for credit losses / net charge-offs % 4282",
  4283: "allowance for credit losses / nonperforming loans % 4283",
  4284: "allowance for credit losses / total loans % 4284",
  4285: "net charge-offs / total average loans % 4285",
  4286: "provision for loan losses / net charge-offs % 4286",
  4287: "total average common equity / total average assets % 4287",
  4288: "total equity + allowance for loan losses / total loans % 4288",
  4290: "gross loans / total deposits % 4290",
  4292: "tier 1 capital ratio % 4292",
  4293: "total capital ratio % 4293",
  4364: "net debt 4364",
  4375: "sg&a margin % 4375",
  4376: "effective tax rate % 4376",
  4377: "payout ratio % 4377",
  4419: "ebita margin % 4419",
  4420: "normalized net income margin % 4420",
  4436: "levered free cash flow margin % 4436",
  4437: "unlevered free cash flow margin % 4437",
  21561: "selling and marketing expense 21561",
  21562: "general and administrative expense 21562",
  21674: "ebitdar 21674",
  22985: "maintenance capex 22985",
  23314: "net debt / (ebitda - capex) 23314",
  23315: "annualized distributions per unit 23315",
  24138: "ffo payout ratio % 24138",
  24153: "total shares out. on filing date 24153",
  24216: "construction in progress 24216",
  33320: "return on common equity % 33320",
  33384: "distributable cash per share (diluted) 33384",
  34819: "core tier 1 capital ratio % 34819",
  34823: "tier 2 capital ratio % 34823",
  34827: "equity tier 1 capital ratio % 34827",
  34838: "interest income / average assets % 34838",
  34839: "interest expense / average assets % 34839",
  34840: "net interest income / average assets % 34840",
  34843: "earning assets / interest bearing liabilities % 34843",
  34860: "nonperforming assets / equity % 34860",
  41571: "net income to company 41571",
  43903: "ffo interest coverage 43903",
  43904: "ffo to total debt (x) 43904",
  43905: "return on capital % 43905",
  43907: "total debt / capital 43907",
  43913: "working capital turnover 43913",
  43917: "asset turnover 43917",
  43923: "cash flow per share 43923",
  99999: "priceclose",
}

const financialTemplates = [
  {
    1: {
      keys: [
        {
          dataitemid: 112,
          name: "Revenues",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 52,
          name: "Finance Div. Revenues",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 70,
          name: "Insurance Division Revenues",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 104,
          name: "Gain (Loss) on Sale of Assets (Rev)",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 106,
          name: "Gain (Loss) on Sale of Investment (Rev)",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 110,
          name: "Interest And Invest. Income (Rev)",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 90,
          name: "Other Revenues",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 28,
          name: "Total Revenues",
          lineorder: 8,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["28"],
          name: "% Change YoY",
          lineorder: 9,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 34,
          name: "Cost of Goods Sold",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 51,
          name: "Finance Div. Operating Exp.",
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: -1,
        },
        {
          dataitemid: 69,
          name: "Insurance Division Operating Expenses",
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: -1,
        },
        {
          dataitemid: 50,
          name: "Interest Expense - Finance Division",
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: -1,
        },
        {
          dataitemid: 10,
          name: "Gross Profit",
          lineorder: 15,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["10"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["10", "28"],
          name: "% Gross Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 102,
          name: "Selling General & Admin Expenses",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 49,
          name: "Exploration / Drilling Costs",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 96,
          name: "Pre-Opening Costs",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 100,
          name: "R&D Expenses",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 41,
          name: "Depreciation & Amortization",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 31,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 71,
          name: "Impairment of Oil Gas & Mineral Properties",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 260,
          name: "Other Operating Expenses",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 380,
          name: "Total Operating Expenses",
          lineorder: 30,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 21,
          name: "Operating Income",
          lineorder: 31,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["21"],
          name: "% Change YoY",
          lineorder: 32,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["21", "28"],
          name: "% Operating Margins",
          lineorder: 33,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 82,
          name: "Interest Expense",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 65,
          name: "Interest And Investment Income",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 47,
          name: "Income (Loss) On Equity Invest.",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 38,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 85,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4,
          name: "EBT Excl. Unusual Items",
          lineorder: 41,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 363,
          name: "Merger & Restructuring Charges",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 62,
          name: "Gain (Loss) On Sale Of Investments",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 56,
          name: "Gain (Loss) On Sale Of Assets",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 32,
          name: "Asset Writedown",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 73,
          name: "Insurance Settlements",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 77,
          name: "Legal Settlements",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 87,
          name: "Other Unusual Items",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 54,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 42,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 63,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "28"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 64,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 65,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "28"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 66,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 67,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 69,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 71,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 73,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends Per Share",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 75,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 9,
          name: "Basic EPS",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 83,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 84,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 85,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 86,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3168,
          name: "R&D Expense",
          lineorder: 87,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 21561,
          name: "Selling and Marketing Expense",
          lineorder: 88,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 21562,
          name: "General and Administrative Expense",
          lineorder: 89,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 90,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 91,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 92,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 93,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Price Factors:",
          lineorder: 94,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    2: {
      keys: [
        {
          dataitemid: 212,
          name: "Interest Income On Loans",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 76,
          name: "Interest Income On Investments",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 25,
          name: "Interest Income",
          lineorder: 2,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 205,
          name: "Interest On Deposits",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 11,
          name: "Total Interest On Borrowings",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 24,
          name: "Interest Expense",
          lineorder: 5,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 17,
          name: "Net Interest Income",
          lineorder: 6,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["17"],
          name: "% Change YoY",
          lineorder: 7,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 298,
          name: "Service Charges On Deposits",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 338,
          name: "Trust Income",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 126,
          name: "Credit Card Fee",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 79,
          name: "Total Mortgage Banking Activities",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 337,
          name: "Income From Trading Activities",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 196,
          name: "Gain (Loss) on Sale of Loans",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 68,
          name: "Income (Loss) on Real Estate Property",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 55,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 61,
          name: "Gain (Loss) on Sale of Invest. & Securities",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 152,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 93,
          name: "Total Other Non Interest Income",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 255,
          name: "Non Operating Income (Expenses)",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 27,
          name: "Non Interest Income Total",
          lineorder: 20,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 360,
          name: "Revenues Before Provison For Loan Losses",
          lineorder: 21,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 359,
          name: "Provision For Loan Losses",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 365,
          name: "Total Revenues",
          lineorder: 23,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["365"],
          name: "% Change YoY",
          lineorder: 24,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 366,
          name: "Compensation And Benefits",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 197,
          name: "Amort. of Goodwill & Intang. Assets",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 250,
          name: "Occupancy Expense",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 160,
          name: "Federal Deposit Insurance",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 103,
          name: "Selling General & Admin Expenses",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 195,
          name: "(Gain) Loss on Sale of Loans",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 67,
          name: "(Income) Loss on Real Estate Property",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 46,
          name: "(Income) Loss on Equity Invest.",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 92,
          name: "Total Other Non Interest Expense",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 84,
          name: "Non Operating (Income) Expenses",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 26,
          name: "Non Interest Expense",
          lineorder: 38,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 39,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["5"],
          name: "% Change YoY",
          lineorder: 40,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 53,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 62,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "365"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 63,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 64,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "365"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 65,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 66,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 68,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 70,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 72,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 74,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 81,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 83,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 84,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 85,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 86,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 87,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 88,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    3: {
      keys: [
        {
          dataitemid: 63,
          name: "Premiums and Annuity Revenues",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 64,
          name: "Total Interest And Dividend Income",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 120,
          name: "Asset Management Fee",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 105,
          name: "Gain (Loss) on Sale of Assets (Rev)",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 107,
          name: "Gain (Loss) on Sale of Investments (Rev)",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 248,
          name: "Non-Insurance Activities Revenues",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 91,
          name: "Other Revenues",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 29,
          name: "Total Revenues",
          lineorder: 8,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["29"],
          name: "% Change YoY",
          lineorder: 9,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 94,
          name: "Policy Benefits",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 36,
          name: "Policy Acquisition /  Underwriting Costs",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 135,
          name: "Depreciation & Amortization",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 395,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 103,
          name: "Selling General & Admin Expenses",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 277,
          name: "Provision For Loan Losses",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 153,
          name: "Salaries And Other Employee Benefits",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 89,
          name: "Other Operating Expenses",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 247,
          name: "Non-Insurance Activities Expenses",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 294,
          name: "Reinsurance Income Or Expense",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 364,
          name: "Total Operating Expenses",
          lineorder: 24,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 356,
          name: "Operating Income",
          lineorder: 25,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["356"],
          name: "% Change YoY",
          lineorder: 26,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["356", "29"],
          name: "% Operating Margins",
          lineorder: 27,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 13,
          name: "Interest Expense",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 48,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 39,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 86,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 33,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 99,
          name: "Restructuring Charges",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 46,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 48,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 51,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 53,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "29"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "29"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 61,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 63,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 65,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 67,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 74,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 76,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    4: {
      keys: [
        {
          dataitemid: 410,
          name: "Revenues",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 105,
          name: "Gain (Loss) on Sale of Assets (Rev)",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 107,
          name: "Gain (Loss) on Sale of Investments (Rev)",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 111,
          name: "Interest and Investment Income (Revenue Block)",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 91,
          name: "Other Revenues",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 29,
          name: "Total Revenues",
          lineorder: 6,
          tikrdisplay: "1",
          format: "bold ",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["29"],
          name: "% Change YoY",
          lineorder: 7,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 411,
          name: "Fuel & Purchased Power",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 278,
          name: "Operations And Maintenance",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 103,
          name: "Selling General & Admin Expenses",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 135,
          name: "Depreciation & Amortization - (IS) - (Collected)",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 395,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 100,
          name: "R&D Expenses",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 49,
          name: "Exploration / Drilling Costs",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 277,
          name: "Provision For Loan Losses",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 96,
          name: "Pre-Opening Costs",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 89,
          name: "Other Operating Expenses",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 364,
          name: "Total Operating Expenses",
          lineorder: 22,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 22,
          name: "Operating Income",
          lineorder: 23,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["22"],
          name: "% Change YoY",
          lineorder: 24,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["22", "29"],
          name: "% Operating Margins",
          lineorder: 25,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 13,
          name: "Interest Expense",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 66,
          name: "Interest and Investment Income",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 48,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 39,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 116,
          name: "Allowance For Equity Funds Used During Construction",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 114,
          name: "Allowance For Borrowed Funds Used During Construction",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 86,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 35,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 99,
          name: "Restructuring Charges",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 48,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 50,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 53,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "29"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "29"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 63,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 65,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 67,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 69,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 76,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 78,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 83,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    5: {
      keys: [
        {
          dataitemid: 334,
          name: "Rental Revenues",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 293,
          name: "Tenant Reimbursements",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 244,
          name: "Property Management Fee",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 215,
          name: "Interest Income On Mortgage Securities",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 111,
          name: "Interest and Investment Income",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 105,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 107,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 91,
          name: "Other Revenues",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 29,
          name: "Total Revenues",
          lineorder: 9,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["29"],
          name: "% Change YoY",
          lineorder: 10,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 35,
          name: "Property Expenses",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 103,
          name: "Selling General & Admin Expenses",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 135,
          name: "Depreciation & Amortization",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 395,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 277,
          name: "Provision For Loan Losses",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 96,
          name: "Pre-Opening Costs",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 89,
          name: "Other Operating Expenses",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 364,
          name: "Total Operating Expenses",
          lineorder: 22,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 22,
          name: "Operating Income",
          lineorder: 23,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["22"],
          name: "% Change YoY",
          lineorder: 24,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["22", "29"],
          name: "% Operating Margins",
          lineorder: 25,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 13,
          name: "Interest Expense",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 66,
          name: "Interest and Investment Income",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 48,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 39,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 86,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 33,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 99,
          name: "Restructuring Charges",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 46,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 48,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 51,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 53,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "29"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "29"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 61,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 63,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 65,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 67,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 74,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 76,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    7: {
      keys: [
        {
          dataitemid: 109,
          name: "Interest and Dividend Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 13,
          name: "Interest Expense",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 370,
          name: "Net Interest Income",
          lineorder: 2,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["370"],
          name: "% Change YoY",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 128,
          name: "Commission and Fees",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 120,
          name: "Asset Management Fee",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 241,
          name: "Mortgage Banking",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 362,
          name: "Credit Card Fee",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 237,
          name: "Loan Servicing Revenue",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 108,
          name: "Gain (Loss) on Sale of Loans & Receivables",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 105,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 107,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 91,
          name: "Other Revenues",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 360,
          name: "Revenues Before Provison For Loan Losses",
          lineorder: 14,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 359,
          name: "Provision For Loan Losses",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 29,
          name: "Total Revenues",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["29"],
          name: "% Change YoY",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 153,
          name: "Salaries And Other Employee Benefits",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 37,
          name: "Cost of Services Provided",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 135,
          name: "Depreciation & Amortization",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 395,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 89,
          name: "Other Operating Expenses",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 364,
          name: "Total Operating Expenses",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 356,
          name: "Operating Income",
          lineorder: 29,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["356"],
          name: "% Change YoY",
          lineorder: 30,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["356", "29"],
          name: "% Operating Margins",
          lineorder: 31,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 372,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 48,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 39,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 36,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 99,
          name: "Restructuring Charges",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 49,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 51,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 54,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "29"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "29"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 62,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 64,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 66,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 68,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 70,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 77,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 79,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 83,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 84,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
    8: {
      keys: [
        {
          dataitemid: 109,
          name: "Interest and Dividend Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 13,
          name: "Interest Expense",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 370,
          name: "Net Interest Income",
          lineorder: 2,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["370"],
          name: "% Change YoY",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 125,
          name: "Brokerage Commission",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 290,
          name: "Trading and Principal Transactions",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 120,
          name: "Asset Management Fee",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 199,
          name: "Underwriting & Investment Banking Fee",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 358,
          name: "Other Revenues",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 105,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 360,
          name: "Revenues Before Provison For Loan Losses",
          lineorder: 12,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 359,
          name: "Provision For Loan Losses",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 29,
          name: "Total Revenues",
          lineorder: 14,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["29"],
          name: "% Change YoY",
          lineorder: 15,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 153,
          name: "Salaries And Other Employee Benefits",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 101,
          name: "Stock-Based Compensation (IS)",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 37,
          name: "Cost of Services Provided",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 135,
          name: "Depreciation & Amortization",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 395,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 95,
          name: "Provision for Bad Debts",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 89,
          name: "Other Operating Expenses",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 364,
          name: "Total Operating Expenses",
          lineorder: 26,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 356,
          name: "Operating Income",
          lineorder: 27,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["356"],
          name: "% Change YoY",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["356", "29"],
          name: "% Operating Margins",
          lineorder: 29,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 48,
          name: "Income (Loss) on Equity Invest.",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 39,
          name: "Currency Exchange Gains (Loss)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 86,
          name: "Other Non Operating Income (Expenses)",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 5,
          name: "EBT Excl. Unusual Items",
          lineorder: 34,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 367,
          name: "Merger & Restructuring Charges",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 99,
          name: "Restructuring Charges",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 209,
          name: "Impairment of Goodwill",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 60,
          name: "Gain (Loss) on Sale of Investments",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 57,
          name: "Gain (Loss) on Sale of Assets",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 33,
          name: "Asset Writedown",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 72,
          name: "In Process R&D Expenses",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 74,
          name: "Insurance Settlements",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 78,
          name: "Legal Settlements",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 88,
          name: "Other Unusual Items",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 139,
          name: "EBT Incl. Unusual Items",
          lineorder: 47,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 75,
          name: "Income Tax Expense",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 7,
          name: "Earnings From Continuing Operations",
          lineorder: 49,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 40,
          name: "Earnings Of Discontinued Operations",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43,
          name: "Extraordinary Item & Accounting Change",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 41571,
          name: "Net Income to Company",
          lineorder: 52,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 83,
          name: "Minority Interest",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 15,
          name: "Net Income",
          lineorder: 54,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 97,
          name: "Preferred Dividend and Other Adjustments",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: "-1",
        },
        {
          dataitemid: 16,
          name: "Net Income to Common Incl Extra Items",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["16", "29"],
          name: "% Net Income to Common Incl Extra Items Margins",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 379,
          name: "Net Income to Common Excl. Extra Items",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["379", "29"],
          name: "% Net Income to Common Excl. Extra Items Margins",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 142,
          name: "Diluted EPS Excl Extra Items",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["142"],
          name: "% Change YoY",
          lineorder: 62,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 342,
          name: "Weighted Average Diluted Shares Outstanding",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["342"],
          name: "% Change YoY",
          lineorder: 64,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3217,
          name: "Weighted Average Basic Shares Outstanding",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3217"],
          name: "% Change YoY",
          lineorder: 66,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 3058,
          name: "Dividends per share",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3058"],
          name: "% Change YoY",
          lineorder: 68,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 336,
          name: "Special Dividends Per Share",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4377,
          name: "Payout Ratio %",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 24138,
          name: "FFO Payout Ratio %",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 3074,
          name: "FFO",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["3074"],
          name: "% Change YoY",
          lineorder: 75,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 4051,
          name: "EBITDA",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: ["4051"],
          name: "% Change YoY",
          lineorder: 77,
          tikrdisplay: "1",
          format: "italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: 21674,
          name: "EBITDAR",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4376,
          name: "Effective Tax Rate %",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
        {
          dataitemid: 33384,
          name: "Distributable Cash per Share (Diluted)",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 23315,
          name: "Annualized Distributions per Unit",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3195,
          name: "Same Store Sales Growth %",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "pct",
          multiplyby: 1,
        },
      ],
      statement: "Income Statement",
    },
  },
  {
    1: {
      keys: [
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1069,
          name: "Short Term Investments",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1002,
          name: "Total Cash And Short Term Investments",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1021,
          name: "Accounts Receivable",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1048,
          name: "Notes Receivable",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1001,
          name: "Total Receivables",
          lineorder: 7,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1043,
          name: "Inventory",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1212,
          name: "Prepaid Expenses",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1032,
          name: "Finance Division Loans and Leases Current",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1029,
          name: "Finance Division Other Current Assets",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1055,
          name: "Other Current Assets",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1008,
          name: "Total Current Assets",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 20,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1054,
          name: "Long-term Investments",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1040,
          name: "Other Intangibles",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1033,
          name: "Finance Division Loans and Leases Long-Term",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1034,
          name: "Finance Division Other Long-Term Assets",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1088,
          name: "Accounts Receivable Long-Term",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1050,
          name: "Loans Receivable Long-Term",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1026,
          name: "Deferred Tax Assets Long-Term",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1025,
          name: "Deferred Charges Long-Term",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1060,
          name: "Other Long-Term Assets",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 32,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1018,
          name: "Accounts Payable",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1016,
          name: "Accrued Expenses",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1046,
          name: "Short-term Borrowings",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1297,
          name: "Current Portion of Long-Term Debt",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1030,
          name: "Finance Division Debt Current",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1031,
          name: "Finance Division Other Current Liabilities",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1074,
          name: "Unearned Revenue Current",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Deferred Tax Liability Current",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1057,
          name: "Other Current Liabilities",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1009,
          name: "Total Current Liabilities",
          lineorder: 46,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1049,
          name: "Long-Term Debt",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1035,
          name: "Finance Division Debt Non Current",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1036,
          name: "Finance Division Other Non Current Liabilities",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1027,
          name: "Deferred Tax Liability Non Current",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1062,
          name: "Other Non Current Liabilities",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1276,
          name: "Total Liabilities",
          lineorder: 56,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1065,
          name: "Preferred Stock - Others",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1028,
          name: "Comprehensive Income and Other",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 69,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 71,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 72,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 73,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 83,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 84,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 85,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    2: {
      keys: [
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1044,
          name: "Investment Securities",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1051,
          name: "Mortgage Backed Securities",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1188,
          name: "Gross Loans",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1081,
          name: "Allowance For Loan Losses",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1208,
          name: "Other Adjustments to Gross Loans",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1003,
          name: "Net Loans",
          lineorder: 8,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 11,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1167,
          name: "Investment in FHLB",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1181,
          name: "Investment in Real Estate",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1078,
          name: "Accrued Interest Receivable",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1207,
          name: "Other Real Estate Owned And Foreclosed",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 27,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1091,
          name: "Interest Bearing Deposits",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1245,
          name: "Institutional Deposits",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1092,
          name: "Non-Interest Bearing Deposits",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1010,
          name: "Total Deposits",
          lineorder: 33,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1047,
          name: "Short-Term Borrowings",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1165,
          name: "Federal Home Loan Bank Debt - Long-Term",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1053,
          name: "Minority Interest",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1268,
          name: "Other Liabilities",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1080,
          name: "Accrued Interest Payable",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Current Deferred Tax Liability",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1012,
          name: "Total Liabilities",
          lineorder: 53,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 66,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 68,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 69,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 70,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 82,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    3: {
      keys: [
        {
          dataitemid: 1175,
          name: "Investment In Debt Securities",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1039,
          name: "Investment In Equity and Preferred Securities",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1223,
          name: "Real Estate Owned",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1197,
          name: "Mortgage Loans",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1211,
          name: "Policy Loans",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1059,
          name: "Total Other Investments",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1011,
          name: "Total investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1068,
          name: "Reinsurance Recoverable - (IS)",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1064,
          name: "Other Receivables",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1115,
          name: "Deferred Policy Acquisition Costs - (BS)",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1230,
          name: "Separate Account Assets",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 15,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 26,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1037,
          name: "Insurance And Annuity Liabilities",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1073,
          name: "Unpaid Claims",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1252,
          name: "Unearned Premiums",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1067,
          name: "Reinsurance Payable - (BS)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1047,
          name: "Short-Term Borrowings",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1231,
          name: "Separate Account Liability",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1053,
          name: "Minority Interest",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Current Deferred Tax Liability",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1202,
          name: "Other Current Liabilities",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1205,
          name: "Other Non Current Liabilities",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1012,
          name: "Total Liabilities",
          lineorder: 52,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 65,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 67,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 68,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 69,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    4: {
      keys: [
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1242,
          name: "Short Term Investments",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1020,
          name: "Accounts Receivable",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1179,
          name: "Inventory",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1212,
          name: "Prepaid Expenses",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1190,
          name: "Notes Receivable (Collected)",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1108,
          name: "Assets Of Discontinued Operations Current",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1008,
          name: "Total Current Assets",
          lineorder: 15,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 18,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1200,
          name: "Net Nuclear Fuel",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1220,
          name: "Regulatory Assets",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1194,
          name: "Long-Term Investments",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1088,
          name: "Accounts Receivable Long-Term",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1191,
          name: "Loans Receivable Long-Term",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1192,
          name: "Assets of Discontinued Operations Long-Term",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 31,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1047,
          name: "Short-Term Borrowings",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Deferred Tax Liability Current",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1058,
          name: "Other Current Liabilities",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1009,
          name: "Total Current Liabilities",
          lineorder: 43,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1063,
          name: "Other Non Current Liabilities",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1276,
          name: "Total Liabilities",
          lineorder: 52,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 65,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 67,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 68,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 69,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 81,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    5: {
      keys: [
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1184,
          name: "Land Held For Development Or Sale",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1015,
          name: "Total Real Estate Assets",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1020,
          name: "Accounts Receivable",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1176,
          name: "Investment In Debt and Equity Securities",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1190,
          name: "Notes Receivable (Collected)",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1197,
          name: "Mortgage Loans",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 22,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1189,
          name: "Short-Term Borrowings",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Deferred Tax Liability Current",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1202,
          name: "Other Current Liabilities",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1205,
          name: "Other Non Current Liabilities",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1012,
          name: "Total Liabilities",
          lineorder: 43,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 48,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1114,
          name: "Distributions In Excess Of Earnings",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    7: {
      keys: [
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1176,
          name: "Investment In Debt and Equity Securities",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1038,
          name: "Long-Term Investments",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1045,
          name: "Loans and Lease Receivables",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 8,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 19,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1091,
          name: "Interest Bearing Deposits",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1092,
          name: "Non-Interest Bearing Deposits",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1277,
          name: "Deposits",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1047,
          name: "Short-Term Borrowings",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Deferred Tax Liability Current",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1202,
          name: "Other Current Liabilities",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1205,
          name: "Other Non Current Liabilities",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1012,
          name: "Total Liabilities",
          lineorder: 42,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 47,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
    8: {
      keys: [
        {
          dataitemid: 1096,
          name: "Cash And Equivalents",
          lineorder: 0,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1235,
          name: "Cash & Securities Segregated",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1236,
          name: "Securities Owned",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1240,
          name: "Securities Purchased Under Agreements To Resell",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1234,
          name: "Securities Borrowed",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1020,
          name: "Accounts Receivable",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1206,
          name: "Other Receivables",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1169,
          name: "Gross Property Plant And Equipment",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1075,
          name: "Accumulated Depreciation",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1004,
          name: "Net Property Plant And Equipment",
          lineorder: 10,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1171,
          name: "Goodwill",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1041,
          name: "Other Intangibles",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1176,
          name: "Investment In Debt and Equity Securities",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1244,
          name: "Trading Asset Securities",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1185,
          name: "Loans Held For Sale",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1117,
          name: "Deferred Tax Assets Current",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1104,
          name: "Restricted Cash",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1056,
          name: "Other Current Assets",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1118,
          name: "Deferred Tax Assets Long-Term (Collected)",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1112,
          name: "Deferred Charges Long-Term",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1061,
          name: "Other Long-Term Assets",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1007,
          name: "Total Assets",
          lineorder: 23,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1019,
          name: "Accounts Payable",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1017,
          name: "Accrued Expenses",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1047,
          name: "Short-Term Borrowings",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1024,
          name: "Current Portion of Long-Term Debt",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1090,
          name: "Current Portion of Capital Lease Obligations",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1193,
          name: "Long-Term Debt",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1183,
          name: "Capital Leases",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1071,
          name: "Trust Preferred Securities (BS)",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1094,
          name: "Current Income Taxes Payable",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1255,
          name: "Current Unearned Revenue",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1119,
          name: "Deferred Tax Liability Current",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1202,
          name: "Other Current Liabilities",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1256,
          name: "Unearned Revenue Non Current",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1213,
          name: "Pension & Other Post Retirement Benefits",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1120,
          name: "Deferred Tax Liability Non-Current",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1205,
          name: "Other Non Current Liabilities",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1012,
          name: "Total Liabilities",
          lineorder: 42,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1217,
          name: "Preferred Stock Redeemable",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1216,
          name: "Preferred Stock Non Redeemable",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1214,
          name: "Preferred Stock Convertible",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1066,
          name: "Preferred Stock - Others",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1005,
          name: "Total Preferred Equity",
          lineorder: 47,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1103,
          name: "Common Stock",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1084,
          name: "Additional Paid In Capital",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1222,
          name: "Retained Earnings",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1248,
          name: "Treasury Stock",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1022,
          name: "Comprehensive Income and Other",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1006,
          name: "Total Common Equity",
          lineorder: 55,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1052,
          name: "Minority Interest",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1275,
          name: "Total Equity",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1013,
          name: "Total Liabilities And Equity",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 59,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: 24153,
          name: "Total Shares Out. on Filing Date",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4020,
          name: "Book Value / Share",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1310,
          name: "Tangible Book Value",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4156,
          name: "Tangible Book Value / Share",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4173,
          name: "Total Debt",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 4364,
          name: "Net Debt",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 1312,
          name: "Total Minority Interest",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3063,
          name: "Equity Method Investments",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3098,
          name: "Land",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3023,
          name: "Buildings",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 24216,
          name: "Construction In Progress",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3085,
          name: "Full Time Employees",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Balance Sheet",
    },
  },
  {
    1: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold ",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2171,
          name: "Depreciation & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2182,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2031,
          name: "Impairment of Oil, Gas & Mineral Properties",
          lineorder: 3,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2160,
          name: "Total Depreciation & Amortization",
          lineorder: 4,
          tikrdisplay: "1",
          format: "bold ",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2014,
          name: "Amortization of Deferred Charges",
          lineorder: 5,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2026,
          name: "(Gain) Loss From Sale Of Asset",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2019,
          name: "Asset Writedown & Restructuring Costs",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2047,
          name: "Other Operating Activities",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2018,
          name: "Change In Accounts Receivable",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2017,
          name: "Change In Accounts Payable",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2045,
          name: "Change in Other Net Operating Assets",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 27,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2010,
          name: "Memo: Change in Net Working Capital",
          lineorder: 28,
          tikrdisplay: "1",
          format: "italic pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2021,
          name: "Capital Expenditure",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2040,
          name: "Sale (Purchase) of Real Estate properties",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2029,
          name: "Sale (Purchase) of Intangible assets",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2027,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2051,
          name: "Other Investing Activities",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 39,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2161,
          name: "Total Debt Issued",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2166,
          name: "Total Debt Repaid",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2169,
          name: "Issuance of Common Stock",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2164,
          name: "Repurchase of Common Stock",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2181,
          name: "Issuance of Preferred Stock",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2172,
          name: "Repurchase of Preferred Stock",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2050,
          name: "Other Financing Activities",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 57,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2021"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "28"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    2: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2091,
          name: "Gain (Loss) on Sale of Loans & Receivables",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2061,
          name: "Changes in Accrued Interest Receivable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2060,
          name: "Changes in Accrued Interest Payable",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 31,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2114,
          name: "Capital Expenditure",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2130,
          name: "Sale of Property, Plant and Equipment",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2123,
          name: "Sale (Purchase) of Real Estate Properties",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 44,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2078,
          name: "Net Increase (Decrease) in Deposit Accounts",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 67,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 70,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 71,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2114"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "365"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    3: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2082,
          name: "Deferred Policy Acquisition Costs",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2059,
          name: "Amort. Of Deferred Policy Acq. Costs",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2122,
          name: "Reinsurance Recoverable",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2121,
          name: "Reinsurance Payable",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2095,
          name: "Change in insurance Reserves / Liabilities",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 33,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2114,
          name: "Capital Expenditure",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2130,
          name: "Sale of Property, Plant and Equipment",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2123,
          name: "Sale (Purchase) of Real Estate Properties",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 46,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2068,
          name: "Annuity Receipts",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2065,
          name: "Annuity Payments",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 70,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 73,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 74,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2114"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "29"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 78,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 79,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 80,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    4: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2114,
          name: "Capital Expenditure",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2123,
          name: "Sale (Purchase) of Real Estate Properties",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2109,
          name: "Nuclear Fuel Expenditures",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2108,
          name: "Contributions to Nuclear Decommissioning Trust",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 42,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 60,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 62,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 63,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 64,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2114"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "29"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    5: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2124,
          name: "Acquisition of Real Estate Assets",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2125,
          name: "Sale of Real Estate Assets",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 40,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 45,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 53,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 62,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 65,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 66,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2021"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "29"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 70,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 71,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    7: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2091,
          name: "Gain (Loss) on Sale of Loans & Receivables",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2033,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Operating",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2061,
          name: "Changes in Accrued Interest Receivable",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2060,
          name: "Changes in Accrued Interest Payable",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 28,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 32,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2114,
          name: "Capital Expenditure",
          lineorder: 33,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2130,
          name: "Sale of Property, Plant and Equipment",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2123,
          name: "Sale (Purchase) of Real Estate Properties",
          lineorder: 40,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 41,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 42,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 44,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 45,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 55,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 58,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 61,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 67,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 69,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 70,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 71,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2114"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "29"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 72,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 73,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 74,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 75,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 76,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 77,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
    8: {
      keys: [
        {
          dataitemid: 2150,
          name: "Net Income",
          lineorder: 0,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2002,
          name: "Depreciation, Depletion & Amortization",
          lineorder: 1,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2016,
          name: "Amortization of Goodwill and Intangible Assets",
          lineorder: 2,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2185,
          name: "Total Depreciation, Depletion & Amortization",
          lineorder: 3,
          tikrdisplay: "1",
          format: "bold",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2015,
          name: "Amortization of Deferred Charges",
          lineorder: 4,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2089,
          name: "(Gain) Loss On Sale of Asset",
          lineorder: 6,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2090,
          name: "(Gain) Loss on Sale of Investments",
          lineorder: 7,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2020,
          name: "Total Asset Writedown",
          lineorder: 8,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2120,
          name: "Restructuring Activities",
          lineorder: 9,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2112,
          name: "Provision for Credit Losses",
          lineorder: 10,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2086,
          name: "(Income) Loss On Equity Investments",
          lineorder: 11,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2127,
          name: "Stock-Based Compensation",
          lineorder: 12,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2135,
          name: "Tax Benefit from Stock Options",
          lineorder: 13,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2111,
          name: "Provision and Write-off of Bad Debts",
          lineorder: 14,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2107,
          name: "Minority Interest in Earnings",
          lineorder: 15,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2103,
          name: "Net (Increase) Decrease in Loans Originated / Sold Operating -",
          lineorder: 16,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2134,
          name: "Change in Trading Asset Securities",
          lineorder: 17,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2067,
          name: "Change in Accounts Receivable",
          lineorder: 18,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2126,
          name: "Sale / Securitization of Accounts Receivable",
          lineorder: 19,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2099,
          name: "Change In Inventories",
          lineorder: 20,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2066,
          name: "Change in Accounts Payable",
          lineorder: 21,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2139,
          name: "Change in Unearned Revenues",
          lineorder: 22,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2101,
          name: "Change In Income Taxes",
          lineorder: 23,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2084,
          name: "Change In Deferred Taxes",
          lineorder: 24,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2140,
          name: "Change in Other Net Operating Assets",
          lineorder: 25,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2046,
          name: "Other Operating Activities",
          lineorder: 26,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2081,
          name: "Net Cash From Discontinued Operations",
          lineorder: 27,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2006,
          name: "Cash from Operations",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2114,
          name: "Capital Expenditure",
          lineorder: 29,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2042,
          name: "Sale of Property, Plant, and Equipment",
          lineorder: 30,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2057,
          name: "Cash Acquisitions",
          lineorder: 31,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2077,
          name: "Divestitures",
          lineorder: 32,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2030,
          name: "Purchase / Sale of Intangible Assets",
          lineorder: 34,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2123,
          name: "Sale (Purchase) of Real Estate Properties",
          lineorder: 35,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2028,
          name: "Investment in Marketable and Equity Securities",
          lineorder: 36,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2032,
          name: "Net (Increase) Decrease in Loans Originated / Sold - Investing",
          lineorder: 37,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2079,
          name: "Deferred Charges",
          lineorder: 38,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2048,
          name: "Other Investing Activities",
          lineorder: 39,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2005,
          name: "Cash from Investing",
          lineorder: 40,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2162,
          name: "Total Debt Issued",
          lineorder: 43,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2163,
          name: "Total Debt Repaid",
          lineorder: 46,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2159,
          name: "Issuance of Common Stock",
          lineorder: 47,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2165,
          name: "Repurchase of Common Stock",
          lineorder: 48,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2174,
          name: "Issuance of Preferred Stock",
          lineorder: 49,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2170,
          name: "Repurchase of Preferred Stock",
          lineorder: 50,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2074,
          name: "Common Dividends Paid",
          lineorder: 51,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2116,
          name: "Preferred Dividends Paid",
          lineorder: 52,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2022,
          name: "Common & Preferred Stock Dividends Paid",
          lineorder: 54,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2041,
          name: "Special Dividend Paid",
          lineorder: 56,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2049,
          name: "Other Financing Activities",
          lineorder: 57,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2004,
          name: "Cash from Financing",
          lineorder: 58,
          tikrdisplay: "1",
          format: "bold lineabove pb-3",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2144,
          name: "Foreign Exchange Rate Adjustments",
          lineorder: 59,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2149,
          name: "Miscellaneous Cash Flow Adjustments",
          lineorder: 60,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2093,
          name: "Net Change in Cash",
          lineorder: 61,
          tikrdisplay: "1",
          format: "bold lineabove",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: [""],
          name: "Supplementary Data:",
          lineorder: 62,
          tikrdisplay: "1",
          format: "bold italic pb-3 pt-3",
          formula: "h3",
          multiplyby: 1,
        },
        {
          dataitemid: ["2006", "2021"],
          name: "Free Cash Flow",
          lineorder: 28,
          tikrdisplay: "1",
          format: "bold",
          formula: "sum",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum"],
          name: "% Change YoY",
          lineorder: 16,
          tikrdisplay: "1",
          format: "bold italic",
          formula: "dxdt",
          multiplyby: 1,
        },
        {
          dataitemid: ["free cash flow_sum", "29"],
          name: "% Free Cash Flow Margins",
          lineorder: 17,
          tikrdisplay: "1",
          format: "bold italic pb-3",
          formula: "div",
          multiplyby: 1,
        },
        {
          dataitemid: 2055,
          name: "Cash and Cash Equivalents, Beginning of Period",
          lineorder: 63,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 2056,
          name: "Cash and Cash Equivalents, End of Period",
          lineorder: 64,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3028,
          name: "Cash Interest Paid",
          lineorder: 65,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 3053,
          name: "Cash Taxes Paid",
          lineorder: 66,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 22985,
          name: "Maintenance CAPEX",
          lineorder: 67,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
        {
          dataitemid: 43923,
          name: "Cash Flow per Share",
          lineorder: 68,
          tikrdisplay: "1",
          format: "normal",
          formula: "val",
          multiplyby: 1,
        },
      ],
      statement: "Cash Flow Statement",
    },
  },
  {
    keys: [
      {
        dataitemid: [""],
        name: "Return Ratios:",
        lineorder: 0,
        tikrdisplay: "1",
        format: "bold",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 4178,
        name: "Return on Assets %",
        lineorder: 1,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 43905,
        name: "Return on Capital %",
        lineorder: 2,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4128,
        name: "Return On Equity %",
        lineorder: 3,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 33320,
        name: "Return on Common Equity %",
        lineorder: 4,
        tikrdisplay: "1",
        format: "normal ",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: [""],
        name: "Margin Analysis:",
        lineorder: 5,
        tikrdisplay: "1",
        format: "bold pt-3",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 4074,
        name: "Gross Profit Margin %",
        lineorder: 6,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4375,
        name: "SG&A Margin %",
        lineorder: 7,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4047,
        name: "EBITDA Margin %",
        lineorder: 8,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4419,
        name: "EBITA Margin %",
        lineorder: 9,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4053,
        name: "EBIT Margin %",
        lineorder: 10,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4278,
        name: "EBT Margin %",
        lineorder: 11,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4117,
        name: "EBT Excl. Non-Recurring Items Margin %",
        lineorder: 12,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4181,
        name: "Income From Continuing Operations Margin %",
        lineorder: 13,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4094,
        name: "Net Income Margin %",
        lineorder: 14,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4420,
        name: "Normalized Net Income Margin %",
        lineorder: 15,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4182,
        name: "Net Avail. For Common Margin %",
        lineorder: 16,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4436,
        name: "Levered Free Cash Flow Margin %",
        lineorder: 17,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4437,
        name: "Unlevered Free Cash Flow Margin %",
        lineorder: 18,
        tikrdisplay: "1",
        format: "normal ",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: [""],
        name: "Asset Turnover:",
        lineorder: 19,
        tikrdisplay: "1",
        format: "bold pt-3",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 43917,
        name: "Asset Turnover",
        lineorder: 20,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4066,
        name: "Fixed Assets Turnover",
        lineorder: 21,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4001,
        name: "Receivables Turnover ",
        lineorder: 22,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4082,
        name: "Inventory Turnover ",
        lineorder: 23,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 43913,
        name: "Working Capital Turnover",
        lineorder: 24,
        tikrdisplay: "1",
        format: "normal ",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: [""],
        name: "Short Term Liquidity:",
        lineorder: 25,
        tikrdisplay: "1",
        format: "bold pt-3",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 4030,
        name: "Current Ratio",
        lineorder: 26,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4121,
        name: "Quick Ratio",
        lineorder: 27,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4037,
        name: "Dividend Payout %",
        lineorder: 28,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4038,
        name: "Dividend Yield %",
        lineorder: 29,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4185,
        name: "Op Cash Flow to Current Liab",
        lineorder: 30,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4184,
        name: "Avg. Cash Conversion Cycle",
        lineorder: 31,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
      },
      {
        dataitemid: 4042,
        name: "Avg. Days Sales Outstanding",
        lineorder: 32,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
      },
      {
        dataitemid: 4035,
        name: "Avg. Days Outstanding Inventory",
        lineorder: 33,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
      },
      {
        dataitemid: 4183,
        name: "Avg. Days Payable Outstanding",
        lineorder: 34,
        tikrdisplay: "1",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
      },
      {
        dataitemid: [""],
        name: "Long-Term Solvency:",
        lineorder: 35,
        tikrdisplay: "1",
        format: "bold pt-3",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 4034,
        name: "Total Debt / Equity",
        lineorder: 36,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 43907,
        name: "Total Debt / Capital",
        lineorder: 37,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4188,
        name: "Total Liabilities / Total Assets",
        lineorder: 38,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4189,
        name: "EBIT / Interest Expense",
        lineorder: 39,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4190,
        name: "EBITDA / Interest Expense",
        lineorder: 40,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4191,
        name: "(EBITDA - Capex) / Interest Expense",
        lineorder: 41,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 43903,
        name: "FFO Interest Coverage ",
        lineorder: 42,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 43904,
        name: "FFO to Total Debt (x)",
        lineorder: 43,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4192,
        name: "Total Debt / EBITDA",
        lineorder: 44,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 4193,
        name: "Net Debt / EBITDA",
        lineorder: 45,
        tikrdisplay: "1",
        format: "normal",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: 23314,
        name: "Net Debt / (EBITDA - Capex)",
        lineorder: 46,
        tikrdisplay: "1",
        format: "normal ",
        formula: "turns",
        multiplyby: 1,
      },
      {
        dataitemid: [""],
        name: "Industry Metrics:",
        lineorder: 47,
        tikrdisplay: "1",
        format: "bold pt-3",
        formula: "h3",
        multiplyby: 1,
      },
      {
        dataitemid: 4279,
        name: "Nonperforming Loans / Total Loans %",
        lineorder: 48,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4280,
        name: "Nonperforming Loans / Total Assets %",
        lineorder: 49,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4281,
        name: "Nonperforming Assets / Total Assets %",
        lineorder: 50,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34860,
        name: "Nonperforming Assets / Equity %",
        lineorder: 51,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4282,
        name: "Allowance for Credit Losses / Net Charge-offs %",
        lineorder: 52,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4283,
        name: "Allowance for Credit Losses / Nonperforming Loans %",
        lineorder: 53,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4284,
        name: "Allowance for Credit Losses / Total Loans %",
        lineorder: 54,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4285,
        name: "Net Charge-offs / Total Average Loans %",
        lineorder: 55,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4286,
        name: "Provision for Loan Losses / Net Charge-offs %",
        lineorder: 56,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34843,
        name: "Earning Assets / Interest Bearing Liabilities %",
        lineorder: 57,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34838,
        name: "Interest Income / Average Assets %",
        lineorder: 58,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34839,
        name: "Interest Expense / Average Assets %",
        lineorder: 59,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34840,
        name: "Net Interest Income / Average Assets %",
        lineorder: 60,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4287,
        name: "Total Average Common Equity / Total Average Assets %",
        lineorder: 61,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4288,
        name: "Total Equity + Allowance for Loan Losses / Total Loans %",
        lineorder: 62,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4290,
        name: "Gross Loans / Total Deposits %",
        lineorder: 63,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4292,
        name: "Tier 1 Capital Ratio %",
        lineorder: 64,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 4293,
        name: "Total Capital Ratio %",
        lineorder: 65,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34819,
        name: "Core Tier 1 Capital Ratio %",
        lineorder: 66,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34823,
        name: "Tier 2 Capital Ratio %",
        lineorder: 67,
        tikrdisplay: "1",
        format: "normal",
        formula: "pct",
        multiplyby: 1,
      },
      {
        dataitemid: 34827,
        name: "Equity Tier 1 Capital Ratio %",
        lineorder: 68,
        tikrdisplay: "1",
        format: "normal ",
        formula: "pct",
        multiplyby: 1,
      },
    ],
    statement: "Ratios",
  },
]

export { financialTemplates, finDidToMetricKey }
