import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { difference } from "lodash/array";
import useBaseUtils from "~/functions/useBaseUtils";
var defaultProperties = ["userId", "refCode", "event", "subscriptionString"];
var companyData = ["companyid", "companyname", "companystatustypeid", "companytypeid", "countryid", "currencyname", "exchangecountryid", "exchangecurrencyid", "exchangeid", "exchangename", "exchangesymbol", "isocode", "primarytradingitemid", "reportingtemplatetypeid", "simpleindustrydescription", "tickersymbol", "tradingitemid", "tradingitemstatusid", "webpage", "yearfounded", "simpleindustryid", "businessdescription"];
var events = {
  "2companyViews": {},
  "3companyViews": {},
  "4companyViews": {},
  "5companyViews": {},
  "6companyViews": {},
  "7companyViews": {},
  "8companyViews": {},
  "9companyViews": {},
  "15companyViews": {},
  "20companyViews": {},
  "25companyViews": {},
  "30companyViews": {},
  "40companyViews": {},
  "50companyViews": {},
  "60companyViews": {},
  "70companyViews": {},
  "80companyViews": {},
  "90companyViews": {},
  "100companyViews": {},
  tickerSelected: {
    properties: [].concat(companyData)
  },
  periodToggleClick: {
    properties: [].concat(companyData, ["value", "fetchKey", "dataSource", "periodKey", "numYears", "firstDateSelected", "lastDateSelected"])
  },
  addPaymentSuccess: {
    properties: ["value", "curr_iso", "planId"]
  },
  userLogin: {},
  addScreen: {},
  deleteScreen: {},
  runScreen: {
    properties: ["query"]
  },
  addWatchlist: {},
  upgradeSelected: {},
  addTickerToWatchlist: {
    properties: ["businessdescription", "companyid", "companyname", "currencyname", "exchangecountryid", "exchangecurrencyid", "exchangeid", "exchangename", "exchangesymbol", "isocode", "simpleindustrydescription", "tickersymbol", "tradingitemid"]
  },
  deleteWatchlist: {},
  addNewsfeed: {},
  newsPageView: {
    properties: [].concat(companyData)
  },
  deleteNewsfeed: {}
};
var gtmDataLayerVariables = function gtmDataLayerVariables() {
  var _useBaseUtils = useBaseUtils(),
    refCode = _useBaseUtils.refCode,
    userId = _useBaseUtils.userId,
    idtoken = _useBaseUtils.idtoken,
    customGroup = _useBaseUtils.customGroup;
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var dataLayerObj = {
    userId: userId.value,
    refCode: refCode.value,
    subscriptionString: customGroup.value
  };
  $store.state.analytics.cognitoMarketingQueryParams.forEach(function (param) {
    var _idtoken$value;
    var marketingParamValue = (_idtoken$value = idtoken.value) === null || _idtoken$value === void 0 || (_idtoken$value = _idtoken$value.payload) === null || _idtoken$value === void 0 ? void 0 : _idtoken$value["custom:".concat(param)];
    if (marketingParamValue) {
      dataLayerObj["signup_".concat(param)] = marketingParamValue;
    }
  });
  $store.state.analytics.marketingQueryParams.forEach(function (param) {
    var marketingParamValue = $store.state.analytics[param];
    if (marketingParamValue) {
      dataLayerObj["authsession_".concat(param)] = marketingParamValue;
    }
  });
  return dataLayerObj;
};
var Analytics = function Analytics() {
  var _useNuxtApp2 = useNuxtApp(),
    $gtm = _useNuxtApp2.$gtm;
  var userData = gtmDataLayerVariables(); // dev does not appear to be working in this composable
  // analytics events are still firing in production
  var _useBaseUtils2 = useBaseUtils(),
    dev = _useBaseUtils2.dev,
    customGroup = _useBaseUtils2.customGroup;
  var keys = Object.keys(events);
  for (var _i = 0, _keys = keys; _i < _keys.length; _i++) {
    var key = _keys[_i];
    var eventProperties = events[key].properties;
    events[key].event = key;
    events[key].properties = [].concat(defaultProperties, _toConsumableArray(eventProperties || []));
  }
  var buildProperties = function buildProperties(event, inputProperties) {
    if (!event.properties) return event;
    var propertiesWithUserData = _objectSpread(_objectSpread(_objectSpread({}, inputProperties), userData), {}, {
      subscriptionString: customGroup.value
    });
    var diff = difference(Object.keys(propertiesWithUserData), events[event.event].properties);
    if (dev.value && diff.length > 0) {
      console.info("Properties not defined: " + diff.join(", "));
    }
    var properties = event.properties.reduce(function (result, propertyName) {
      if (dev.value && propertiesWithUserData[propertyName] === undefined) {
        console.info("Missing property: " + propertyName);
      } else {
        result[propertyName] = propertiesWithUserData[propertyName];
      }
      return result;
    }, {});
    return _objectSpread({
      event: event.event
    }, properties);
  };
  var sendEvent = function sendEvent(event, inputProperties) {
    if (dev.value && event === undefined) {
      console.error("Event not defined");
      return;
    }
    var finalProperties = buildProperties(event, inputProperties);
    try {
      var gtmEventObj = _objectSpread({
        event: event.event
      }, finalProperties);
      $gtm.push(gtmEventObj);
      if (dev.value) {
        console.info("Analytics Event:", event.event, finalProperties);
      }
      return true;
    } catch (error) {
      if (dev.value) {
        console.error(event, error);
      }
    }
  };
  return {
    sendEvent: sendEvent,
    events: events,
    gtmDataLayerVariables: userData
  };
};
export default Analytics;