import { USE_CDK_TRKD } from "../feature-toggle"
const stage = process.env.LAMBDA_STAGE
const userurl = process.env.USER_LAMBDA_URL
const restrictResponse = process.env.USE_TIERS === "true"

const SLS_TRKD_URL = process.env.TRKD_LAMBDA_URL
const CDK_URL = process.env.API_URL

const state = () => ({
  user: null,
  idtoken: null,
  authError: null,
  fetchingAuth: false,
  roleError: null,
  roles: [],
  chartColor: "#FF9900",
  authDisplayMap: {
    showSignIn: true,
    showSignUp: false,
    showConfirmSignUp: false,
    showConfirmSignIn: false,
    showForgotPassword: false,
    showSignOut: false,
    showMfa: false,
    showRequireNewPassword: false,
  },
  email: "",
  inviteCode: "organic",
  homePath: "/markets?fid=1",
  password: "",
  authRules: {
    required: (value) => Boolean(value) || "Required",
    email: (value) => {
      const pattern =
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return pattern.test(value) || "Email Address Invalid"
    },
    pwReq: (value) => {
      return value.length >= 6 ? true : "Must be at least 6 characters long"
    },
  },
  appVer: "v2.3.2",
  ppVer: "first",
  tosVer: "second",
  latestAppVer: null,
  latestPPVer: null,
  latestTOSVer: null,
  lastVerFetch: new Date("2022-08-29T02:21:48.008Z"),
  fetchingVer: false,
  fetchingAgreeToTerms: false,
  creatingRefCode: false,
  updatingSubscription: false,
  regions: ["U.S. and Canada", "Europe", "Asia", "LatAm / Africa / Other"],
  regionMap: {
    "U.S. and Canada": "basic1",
    Europe: "basic2",
    Asia: "basic3",
    "LatAm / Africa / Other": "basic4",
  },
  paidGroups: ["premium", "basic1", "basic2", "basic3", "basic4"],
  subTypeToDescription: {
    "2021_v0-global": "TIKR.com Pro Membership",
    "2022_v0-global": "TIKR.com Pro Membership",
    "2022_v0-pro": "TIKR.com Pro Membership",
    "2022_v0-plus": "TIKR.com Plus Membership",
    "2023_v0-pro": "TIKR.com Pro Membership",
    "2023_v0-plus": "TIKR.com Plus Membership",
    "2021_v0-transition": "Beta Transition to Freemium TIKR.com Membership",
    "2021_v0-free": "Free TIKR.com Membership",
    "2022_v0-free": "Free TIKR.com Membership",
    "2023_v0-free": "Free TIKR.com Membership",
  },
  launchStr: "2020-06-01T23:00:00.000Z",
  autocompleteCharacterLimit: 45,
  restrictResponse,
})

const mutations = {
  updateStore(state, payload) {
    // const property = payload.property
    state[payload.property] = payload.value
  },
  setChartColor(state, payload) {
    state.chartColor = payload.color
  },
  resetuser(state) {
    // why would I ever use this?
    state.user = null
    // FIXME: set the site to light mode?
  },
  clearAuthError(state) {
    state.error = null
    state.roleError = null
  },
  setAuthError(state, payload) {
    // expecting payload = {error: try/catch error, loc: "error location"}
    state.authError = payload
  },
  setRoleError(state, payload) {
    state.roleError = payload
  },
  signOut(state) {
    // Reset the Auth Display Map?
    // If everything is right it should be in sync
    state.authDisplayMap = {
      showSignIn: true,
      showSignUp: false,
      showConfirmSignUp: false,
      showConfirmSignIn: false,
      showForgotPassword: false,
      showSignOut: false,
      showMfa: false,
      showRequireNewPassword: false,
    }
    state.user = null
    state.idtoken = null
    state.error = null
    state.roleError = null
    state.roles = []
    state.email = ""
    state.password = ""
  },
  updateCurrentUser(state, payload) {
    // payload.user should be user object returned from Cognito
    try {
      const logInDev =
        state.idtoken &&
        payload.user &&
        payload.user.signInUserSession &&
        payload.user.signInUserSession.idToken.jwtToken !==
          state.idtoken.jwtToken &&
        stage === "dev" &&
        payload.from === "getAppInfo"

      if (logInDev) {
        console.log("updateCurrentUser called idtoken claims reset")
      } // The token has actually been refreshed
      state.authError = null
      state.user = payload.user // any reason to store this whole use object?
      state.idtoken = payload.user
        ? payload.user.signInUserSession.idToken
        : null
    } catch (error) {
      console.error("Error updateCurrentUser: ", error)
    } finally {
      state.authError = null
      state.user = payload.user // any reason to store this whole use object?
      state.idtoken = payload.user?.signInUserSession
        ? payload.user.signInUserSession.idToken
        : null
    }
    // only using parts of it, perhaps just focus it down to what you need?
    // seems like an optimization perhaps for later. for now start destructuring
    // the user object saving the top level things that you need...
    // TODO: set the accepted tos and pp from
    // payload.user.signInUserSession.idToken.payload
    //
    // try {

    // } catch (error) {

    // }
  },
  setRoles(state, payload) {
    state.roles = payload.roles
  },
  updateAuthDisplayMap(state, { data }) {
    // This mutation keeps the state in sync with the required Login UI when the
    // Login path is shown
    const newMap = {
      showSignIn: data === "signedOut" || data === "signIn",
      showSignUp: data === "signUp",
      showConfirmSignUp: data === "confirmSignUp",
      showConfirmSignIn: data === "confirmSignIn",
      showForgotPassword: data === "forgotPassword",
      showSignOut: data === "signedIn",
      showMfa: data === "setMfa",
      showRequireNewPassword: data === "requireNewPassword",
    }
    state.authDisplayMap = { ...{}, ...newMap }
  },
}

const actions = {
  async updateSubscription({ commit }, payload) {
    try {
      commit("updateStore", { property: "updatingSubscription", value: true })
      // TODO: what things does the updateSub handler need?
      const user = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      // FIXME: how will you guard against multiple windows trying to execute
      // the same payment transaction multiple times? they have two windows open
      // on accounts - fill out one, then close it. Then later they see the
      // other which hasn't updated to show the user purchased an account
      // and they go to purchase it again thinking thats weird why am I being
      // prompted to pay / not being shown my upgraaded account status.
      const body = {
        auth: user.signInUserSession.idToken.jwtToken,
        capture: payload.captureDetais,
        subType: payload.subType,
        promoGroup: payload.group,
      }
      // Fetch date periods and estimate actuals associated with those date periods
      await this.$axios.post(`${userurl}/${stage}/clisu`, body)
    } catch (error) {
      console.error("Error updating subscription: ", error)
    } finally {
      // toggle fetch to null
      const user = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      commit("updateCurrentUser", { user, from: "updateSubscription" })
      commit("updateStore", { property: "updatingSubscription", value: false })
    }
  },
  async agreeToTerms({ commit, state }) {
    try {
      // do your work here
      commit("updateStore", { property: "fetchingAgreeToTerms", value: true })
      const user = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      const body = {
        auth: user.signInUserSession.idToken.jwtToken,
        pp: state.ppVer,
        tos: state.tosVer,
      }
      // Fetch date periods and estimate actuals associated with those date periods
      await this.$axios.post(`${userurl}/${stage}/uterms`, body)
    } catch (error) {
      console.error("Error Accepting to Terms")
    } finally {
      // toggle fetch to null
      const user = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      commit("updateCurrentUser", { user, from: "agreeToTerms" })
      commit("updateStore", { property: "fetchingAgreeToTerms", value: false })
    }
  },
  async createRefCode({ commit, state }) {
    // if you're not already creating the refCode...
    if (!state.creatingRefCode) {
      try {
        // do your work here
        commit("updateStore", { property: "creatingRefCode", value: true })
        const user = await this.$Amplify.Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        const body = {
          auth: user.signInUserSession.idToken.jwtToken,
        }
        // Fetch date periods and estimate actuals associated with those date periods
        await this.$axios.post(`${userurl}/${stage}/updateBeta`, body)
      } catch (error) {
        console.error("Error Creating Ref Code")
      } finally {
        // toggle fetch to null
        const user = await this.$Amplify.Auth.currentAuthenticatedUser({
          bypassCache: true,
        })
        commit("updateCurrentUser", { user, from: "creatRefCode" })
        commit("updateStore", { property: "creatingRefCode", value: false })
      }
    }
  },
  // getUser({ commit }) {
  //   commit("updateStore", { property: "fetchingAuth", value: true })
  //   commit("clearAuthError")
  //   return this.$Amplify.Auth.currentAuthenticatedUser()
  //     .then(user => {
  //       if (!user) {
  //         // I'm not sure if this is ever a situation?
  //         throw new Error("currentAuthenticatedUser resolved without a user")
  //       }
  //       // update the current user with the new user
  //       commit("updateCurrentUser", { user })
  //       // reset authError to null
  //       commit("setAuthError", null)
  //     })
  //     .catch(e => {
  //       commit("setAuthError", { error: e, loc: "getUser Action" })
  //     })
  //     .finally(() => {
  //       commit("updateStore", { property: "fetchingAuth", value: false })
  //     })
  // },
  currentUser({ commit }, payload) {
    // FIXME: Is this code before returning a promise
    // executed synchronously?
    // if (payload.location && process.env.LAMBDA_STAGE === "dev") {
    //   console.log("checking current user from:", payload.location)
    // }
    // if (state.fetchingAuth) {
    //   // already fetching current user... no need to do this twice?
    //   return
    // }
    commit("updateStore", { property: "fetchingAuth", value: true })
    commit("clearAuthError")
    return this.$Amplify.Auth.currentAuthenticatedUser()
      .then((user) => {
        const location = payload.location || "store action currentUser"
        commit("updateCurrentUser", { user, from: location })
      })
      .catch((e) => {
        commit("setAuthError", { error: e, loc: "currentUser action" })
      })
      .finally(() => {
        commit("updateStore", { property: "fetchingAuth", value: false })
      })
  },
  async getAppInfo({ state, commit }) {
    // const timeDiff = 0
    // do I need to see if the user is authenticated first?
    // who the fuck is authenticating
    // guess I could put this on a header
    try {
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      commit("updateCurrentUser", { user, from: "getAppInfo" })

      const body = {
        auth: user.signInUserSession.idToken.jwtToken,
      }
      const lastFetch = state.lastVerFetch
      const now = new Date()
      const timeThreshold = 10 // 10 minutes
      const timeDiff = lastFetch
        ? (now - lastFetch) / 1000 / 60 // convert to minutes?
        : timeThreshold + 10

      if (timeDiff <= timeThreshold || state.fetchingVer) {
        // not enough time has elapsed or version is currently
        // being fetched
        return
      }

      commit("updateStore", { property: "fetchingVer", value: true })

      const VERSION_URL = USE_CDK_TRKD
        ? `${CDK_URL}/v`
        : `${SLS_TRKD_URL}/${stage}/v`

      const response = await this.$axios.post(VERSION_URL, body)

      commit("updateStore", {
        property: "latestAppVer",
        value: response.data.appVer,
      })
      commit("updateStore", {
        property: "latestPPVer",
        value: response.data.ppVer,
      })
      commit("updateStore", {
        property: "latestTOSVer",
        value: response.data.tosVer,
      })
      commit("updateStore", {
        property: "lastVerFetch",
        value: new Date(),
      })
    } catch (error) {
      console.error("Error getting recent app info: ", error)
      // I need to set up stuff here
    } finally {
      // change loading state, errors, etc
      commit("updateStore", { property: "fetchingVer", value: false })
    }
  },
}

// const getters = {
//   isAlphaAuth(state) {
//     const desiredGroup = "alpha"
//     if (!state.user) {
//       return false
//     }
//     const groups =
//       state.user.signInUserSession.idToken.payload["cognito:groups"] || []
//     const hasGroup = groups.map((g) => g === desiredGroup)
//     if (hasGroup.length > 0) {
//       return true
//     } else {
//       return false
//     }
//   },
//   roles(state) {
//     if (!state.user) {
//       return []
//     }
//     return state.user.signInUserSession.idToken.payload["cognito:groups"] || []
//   },
// }

/**
 *
 * @param {string[]} userRoles - Array of Strings which are cognito groups
 * @param {string[]} desiredRoles - Array of Strings which are desired cognito groups
 * Checks if user has any of the desired roles. Returns true if user has at least one
 * role, false if the user does not have any of the desired roles
 */
// const isRole = (userRoles, desiredRoles) => {
//   if (userRoles.length === 0) {
//     // No User Groups
//     return false
//   }
//   let doesUserHaveRole = false
//   userRoles.map(g => {
//     // check if user group is any of the desired roles
//     const hasGroup = desiredRoles.filter(role => role === g)
//     if (hasGroup.length === 1) {
//       // because g is just one role hasGroup should never be longer than 1
//       doesUserHaveRole = true
//       return true
//     } else {
//       return false
//     }
//   })
//   return doesUserHaveRole
// }

export { state, mutations, actions }
