const segmentTemplate = {
  keys: [
    {
      dataitemid: [""],
      dataitemname: "Business Segments",
      tikrdisplay: "1",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: 3508,
      dataitemname: "Revenues",
      tikrdisplay: "1",
      format: "bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24107,
      dataitemname: "Total Revenues",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3505,
      dataitemname: "Gross Profit",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24110,
      dataitemname: "Total Gross Profit",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3501,
      dataitemname: "Operating Income",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24108,
      dataitemname: "Total Operating Income",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21550,
      dataitemname: "Interest Expense",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24114,
      dataitemname: "Total Interest Expense",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3503,
      dataitemname: "EBT",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24111,
      dataitemname: "Total EBT",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21551,
      dataitemname: "Income Tax Expense",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24115,
      dataitemname: "Total Income Tax Expense",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3502,
      dataitemname: "NOPAT",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 45939,
      dataitemname: "EBITDA",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 45940,
      dataitemname: "Total EBITDA",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21552,
      dataitemname: "D&A",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24116,
      dataitemname: "Total D&A",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3504,
      dataitemname: "Net Income",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24112,
      dataitemname: "Total Net Income",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21553,
      dataitemname: "CAPEX",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24117,
      dataitemname: "Total CAPEX",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3507,
      dataitemname: "Total Assets",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24113,
      dataitemname: "Total Assets, Total",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: [""],
      dataitemname: "Business Segments % Change Yoy",
      tikrdisplay: "wip",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: ["3508"],
      dataitemname: "Revenues",
      tikrdisplay: "wip",
      format: "bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3505"],
      dataitemname: "Gross Profit",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3501"],
      dataitemname: "Operating Income",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21550"],
      dataitemname: "Interest Expense",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3503"],
      dataitemname: "EBT",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21551"],
      dataitemname: "Income Tax Expense",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3502"],
      dataitemname: "NOPAT",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["45939"],
      dataitemname: "EBITDA",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21552"],
      dataitemname: "D&A",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3504"],
      dataitemname: "Net Income",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21553"],
      dataitemname: "CAPEX",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3507"],
      dataitemname: "Total Assets",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: [""],
      dataitemname: "Geographic Segments",
      tikrdisplay: "1",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: 3515,
      dataitemname: "Revenues",
      tikrdisplay: "1",
      format: "bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24118,
      dataitemname: "Total Revenues",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3511,
      dataitemname: "Gross Profit",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24121,
      dataitemname: "Total Gross Profit",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3516,
      dataitemname: "Operating Income",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24119,
      dataitemname: "Total Operating Income",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21554,
      dataitemname: "Interest Expense",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24125,
      dataitemname: "Total Interest Expense",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3513,
      dataitemname: "EBT",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24122,
      dataitemname: "Total EBT",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21555,
      dataitemname: "Income Tax Expense",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24126,
      dataitemname: "Total Income Tax Expense",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 45941,
      dataitemname: "EBITDA",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 21556,
      dataitemname: "D&A",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24127,
      dataitemname: "Total D&A",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3512,
      dataitemname: "Net Income",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24123,
      dataitemname: "Total Net Income",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 21557,
      dataitemname: "CAPEX",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24128,
      dataitemname: "Total CAPEX",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: 3510,
      dataitemname: "Total Assets",
      tikrdisplay: "1",
      format: "pt-3 bold",
      formula: "val",
      multiplyby: 1,
    },
    {
      dataitemid: 24124,
      dataitemname: "Total Assets, Total",
      tikrdisplay: "1",
      format: "bold lineabove",
      formula: "total-val",
      multiplyby: 1,
    },
    {
      dataitemid: [""],
      dataitemname: "Geographic Segments % Change YoY",
      tikrdisplay: "wip",
      format: "bold italic pb-1 pt-3",
      formula: "h3",
      multiplyby: 1,
    },
    {
      dataitemid: ["3515"],
      dataitemname: "Revenues",
      tikrdisplay: "wip",
      format: "bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3511"],
      dataitemname: "Gross Profit",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3516"],
      dataitemname: "Operating Income",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21554"],
      dataitemname: "Interest Expense",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3513"],
      dataitemname: "EBT",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21555"],
      dataitemname: "Income Tax Expense",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["45941"],
      dataitemname: "EBITDA",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21556"],
      dataitemname: "D&A",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3512"],
      dataitemname: "Net Income",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["21557"],
      dataitemname: "CAPEX",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
    {
      dataitemid: ["3510"],
      dataitemname: "Total Assets",
      tikrdisplay: "wip",
      format: "pt-3 bold",
      formula: "dxdt",
      multiplyby: 1,
    },
  ],
  statement: "Segments, As Reported",
}

export default segmentTemplate
