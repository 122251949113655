import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.concat.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.find.js";
import "core-js/modules/es.array.flat-map.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.array.unscopables.flat-map.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.map.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-finite.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.object.values.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.set.js";
import "core-js/modules/es.string.includes.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/esnext.map.delete-all.js";
import "core-js/modules/esnext.map.every.js";
import "core-js/modules/esnext.map.filter.js";
import "core-js/modules/esnext.map.find.js";
import "core-js/modules/esnext.map.find-key.js";
import "core-js/modules/esnext.map.includes.js";
import "core-js/modules/esnext.map.key-of.js";
import "core-js/modules/esnext.map.map-keys.js";
import "core-js/modules/esnext.map.map-values.js";
import "core-js/modules/esnext.map.merge.js";
import "core-js/modules/esnext.map.reduce.js";
import "core-js/modules/esnext.map.some.js";
import "core-js/modules/esnext.map.update.js";
import "core-js/modules/esnext.set.add-all.js";
import "core-js/modules/esnext.set.delete-all.js";
import "core-js/modules/esnext.set.difference.js";
import "core-js/modules/esnext.set.every.js";
import "core-js/modules/esnext.set.filter.js";
import "core-js/modules/esnext.set.find.js";
import "core-js/modules/esnext.set.intersection.js";
import "core-js/modules/esnext.set.is-disjoint-from.js";
import "core-js/modules/esnext.set.is-subset-of.js";
import "core-js/modules/esnext.set.is-superset-of.js";
import "core-js/modules/esnext.set.join.js";
import "core-js/modules/esnext.set.map.js";
import "core-js/modules/esnext.set.reduce.js";
import "core-js/modules/esnext.set.some.js";
import "core-js/modules/esnext.set.symmetric-difference.js";
import "core-js/modules/esnext.set.union.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _toConsumableArray(r) {
  return _arrayWithoutHoles(r) || _iterableToArray(r) || _unsupportedIterableToArray(r) || _nonIterableSpread();
}
function _nonIterableSpread() {
  throw new TypeError("Invalid attempt to spread non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArray(r) {
  if ("undefined" != typeof Symbol && null != r[Symbol.iterator] || null != r["@@iterator"]) return Array.from(r);
}
function _arrayWithoutHoles(r) {
  if (Array.isArray(r)) return _arrayLikeToArray(r);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
function _createForOfIteratorHelper(r, e) {
  var t = "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (!t) {
    if (Array.isArray(r) || (t = _unsupportedIterableToArray(r)) || e && r && "number" == typeof r.length) {
      t && (r = t);
      var _n = 0,
        F = function F() {};
      return {
        s: F,
        n: function n() {
          return _n >= r.length ? {
            done: !0
          } : {
            done: !1,
            value: r[_n++]
          };
        },
        e: function e(r) {
          throw r;
        },
        f: F
      };
    }
    throw new TypeError("Invalid attempt to iterate non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
  }
  var o,
    a = !0,
    u = !1;
  return {
    s: function s() {
      t = t.call(r);
    },
    n: function n() {
      var r = t.next();
      return a = r.done, r;
    },
    e: function e(r) {
      u = !0, o = r;
    },
    f: function f() {
      try {
        a || null == t["return"] || t["return"]();
      } finally {
        if (u) throw o;
      }
    }
  };
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
import { isArray, isEmpty, isString, isObject, get } from "lodash";
import useCiqQuotesState from "./useCiqQuotesState";
import useNumberFormatters from "./useNumberFormatters";
import useCommonStrings from "./useCommonStrings";
import { isDev } from "./useBaseUtils";
import dayjs from "~/utils/tools/dayjs";
import { hasProp } from "~/utils/screener/screenerConditionUtils";
import { findFirstAvailableItemInObject, getLastKeyInObject, isObjectEmpty } from "~/utils/tools/object";
import { convertToUsdValue } from "~/utils/values/scale";
import { calculateCAGR, calculateCiqDivision } from "~/modules/ratios";
import { getCellLoaders, getFormulaKey, getQuarterDateProps } from "~/utils/ciq/common";
import polishNotationEvaluate from "~/utils/tools/polishNotation";
var dev = isDev();
var emptyObj = {};
var valueIdKeys = ["v", "dataitemvalue"];
var getIsAnyCellEmpty = function getIsAnyCellEmpty(mappedCells) {
  var _iterator = _createForOfIteratorHelper(mappedCells),
    _step;
  try {
    for (_iterator.s(); !(_step = _iterator.n()).done;) {
      var _step$value = _slicedToArray(_step.value, 2),
        val = _step$value[1];
      if (Array.isArray(val) && (val.includes(undefined) || Object.keys(val).length === 1)) {
        return true;
      } else if (val === undefined) {
        return true;
      } else if (isObject(val) && Object.keys(val).length === 0) {
        return true;
      } else if (!val) {
        return true;
      }
    }
  } catch (err) {
    _iterator.e(err);
  } finally {
    _iterator.f();
  }
  return false;
};
var getAllSources = function getAllSources() {
  var src = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : [];
  var sources = Object.values(src).flatMap(function (item) {
    return isArray(item.source) ? item.source.map(function (subitem) {
      return subitem.source;
    }) : item.source;
  });
  return _toConsumableArray(new Set(sources));
};
var getNtmValidation = function getNtmValidation(cellValue, cellList) {
  var isFinite = Number.isFinite(cellValue);
  var oneYearBackwards = dayjs.utc().add(-1, "year").valueOf();
  var isFreshData = cellList.every(function (cell) {
    return (cell === null || cell === void 0 ? void 0 : cell.dV) > oneYearBackwards;
  });
  return isFinite && isFreshData;
};
var formatOverviewMetricByType = function formatOverviewMetricByType(_ref) {
  var type = _ref.type,
    value = _ref.value,
    suffix = _ref.suffix,
    needsUsdConversion = _ref.needsUsdConversion,
    _ref$displayParenthes = _ref.displayParenthesisWhenNegative,
    displayParenthesisWhenNegative = _ref$displayParenthes === void 0 ? true : _ref$displayParenthes,
    currencyFormatter = _ref.currencyFormatter,
    usdCurrencyFormatter = _ref.usdCurrencyFormatter,
    _ref$formattingFn = _ref.formattingFn,
    formattingFn = _ref$formattingFn === void 0 ? function (_ref2) {
      var value = _ref2.value;
      return value;
    } : _ref$formattingFn; // the turns formatter already does
  // the parenthesis inclusion
  var typeDoesntNeedParenthesis = ["turns"].includes(type);
  var shouldRemoveNegativeMinusPrefix = value < 0 && !typeDoesntNeedParenthesis && displayParenthesisWhenNegative;
  var actualValue = shouldRemoveNegativeMinusPrefix ? Math.abs(value) : value;
  var formatted = formattingFn({
    type: type,
    value: actualValue,
    suffix: suffix,
    nativeCurrencyFormatter: currencyFormatter,
    usdCurrencyFormatter: usdCurrencyFormatter,
    useUsdCurrencyFormatter: needsUsdConversion
  });
  if (value < 0 && displayParenthesisWhenNegative && !typeDoesntNeedParenthesis) {
    return "(".concat(formatted, ")");
  } else {
    return formatted;
  }
};
var getCagrValue = function getCagrValue(cells) {
  if (!cells) {
    return;
  }
  var firstDateObj = cells[0];
  var lastDateObj = cells[1];
  var firstVal = findFirstAvailableItemInObject({
    item: firstDateObj,
    keys: ["dataitemvalue", "v"]
  });
  var lastVal = findFirstAvailableItemInObject({
    item: lastDateObj,
    keys: ["dataitemvalue", "v"]
  });
  var initialValue = Number(firstVal);
  var lastValue = Number(lastVal);
  var isAnyEmpty = isObjectEmpty(firstDateObj) || isObjectEmpty(lastDateObj);
  if (!firstDateObj || !lastDateObj || isAnyEmpty) {
    return NaN;
  } else {
    var firstDate = firstDateObj.timeVal;
    var lastDate = lastDateObj.timeVal;
    var _calculateCAGR = calculateCAGR({
        initialValue: initialValue,
        lastValue: lastValue,
        firstDate: firstDate,
        lastDate: lastDate
      }),
      cagr = _calculateCAGR.cagr;
    return cagr;
  }
};
var normalizeLastQuote = function normalizeLastQuote(quotation) {
  var lastQuote = {
    pc: Number(quotation === null || quotation === void 0 ? void 0 : quotation[9]) || 1,
    iso: String(quotation === null || quotation === void 0 ? void 0 : quotation[8]),
    // || "USD" ?
    dataitemvalue: Number(quotation === null || quotation === void 0 ? void 0 : quotation[5]),
    unittypeid: 0
  };
  return lastQuote;
};
var evaluateSum = function evaluateSum(objects) {
  var items = [];
  objects.forEach(function (item) {
    var value = findFirstAvailableItemInObject({
      item: item,
      keys: ["v", "dataitemvalue"]
    });
    items.push(value);
  });
  var expression = "+ ".concat(items.join(" "));
  return polishNotationEvaluate(expression);
};
var parseCustomFormula = function parseCustomFormula(evaluateStr, mappedObjs, isAnyCellEmpty) {
  if (isAnyCellEmpty) {
    return NaN;
  } else {
    var replaced = evaluateStr.replace(/{{\s*(.*?)\s*}}/g, function (match, p1) {
      var valueItem = mappedObjs.get(p1);
      var value = findFirstAvailableItemInObject({
        item: valueItem,
        keys: valueIdKeys
      });
      return value !== undefined ? value : match;
    });
    return polishNotationEvaluate(replaced);
  }
};
var getValueByFormula = function getValueByFormula(mappedObjs, formulaObj, metricKeys) {
  var suffix = arguments.length > 3 && arguments[3] !== undefined ? arguments[3] : "";
  var formatType = arguments.length > 4 && arguments[4] !== undefined ? arguments[4] : "val";
  var currentCurrency = arguments.length > 5 ? arguments[5] : undefined;
  var nonAvailableString = arguments.length > 6 ? arguments[6] : undefined;
  var isAnyCellEmpty = arguments.length > 7 ? arguments[7] : undefined;
  var isCurrency = formatType === "currency";
  var isUSD = currentCurrency.code === "USD";
  var firstItem = metricKeys[0];
  var aliasId = firstItem.aliasId,
    name = firstItem.name;
  var key = aliasId || name;
  var numerator = mappedObjs.get(key);
  var formula = (formulaObj === null || formulaObj === void 0 ? void 0 : formulaObj.type) || formulaObj;
  switch (formula) {
    case "cagr":
      {
        return getCagrValue(numerator);
      }
    case "val":
      {
        if (isString(numerator) && numerator !== nonAvailableString) {
          return numerator + (suffix ? " ".concat(suffix) : "");
        } else {
          var value = findFirstAvailableItemInObject({
            item: numerator,
            keys: valueIdKeys
          });
          var numeratorIso = findFirstAvailableItemInObject({
            item: numerator,
            keys: ["iso", "isocode"]
          });
          var needsUsdConversion = numeratorIso !== "USD" && isCurrency && isUSD;
          return needsUsdConversion ? convertToUsdValue({
            originalValue: value,
            priceClose: numerator.pc
          }) : Number(value);
        }
      }
    case "div":
      {
        var denominatorKey = metricKeys[1].name;
        return calculateCiqDivision({
          numerator: numerator,
          denominator: mappedObjs.get(denominatorKey)
        });
      }
    case "YoY":
      {
        return getYoY(numerator);
      }
    case "QoQ":
      {
        // using the YoY formula here cause its the same
        return getYoY(numerator);
      }
    case "custom":
      {
        return parseCustomFormula(formulaObj.evaluate, mappedObjs, isAnyCellEmpty);
      }
    case "sum":
      {
        return evaluateSum(mappedObjs);
      }
    default:
      console.log("default", formula);
      return 789;
  }
};
var getYieldValue = function getYieldValue(mappedObjs, metricKeys, lastClosePrice) {
  var firstItem = metricKeys[0];
  var aliasId = firstItem.aliasId,
    name = firstItem.name;
  var key = aliasId || name;
  var numerator = mappedObjs.get(key);
  return calculateCiqDivision({
    numerator: numerator,
    denominator: normalizeLastQuote(lastClosePrice)
  });
};
var getCellByAvailableSource = function getCellByAvailableSource(_ref3) {
  var _ref3$sources = _ref3.sources,
    sources = _ref3$sources === void 0 ? [] : _ref3$sources,
    props = _ref3.props,
    $store = _ref3.$store;
  var formulaKey = getFormulaKey(props.formula);
  var isCagr = formulaKey === "cagr";
  var _iterator2 = _createForOfIteratorHelper(sources.entries()),
    _step2;
  try {
    for (_iterator2.s(); !(_step2 = _iterator2.n()).done;) {
      var _step2$value = _slicedToArray(_step2.value, 2),
        index = _step2$value[0],
        element = _step2$value[1];
      var sourceName = element.source;
      var cellName = element.metricKey || element.name;
      var tableNameId = element.tableNameId;
      var exact = element === null || element === void 0 ? void 0 : element.exact;
      var item = getCellItemBySource({
        sourceName: sourceName,
        cellName: cellName,
        tableNameId: tableNameId,
        props: props,
        $store: $store,
        valuePropKey: element.valuePropKey,
        index: index,
        exact: exact
      });
      var isArrayItem = isArray(item);
      var isValid = isArrayItem ? item.filter(function (obj) {
        return isObject(obj) && !isObjectEmpty(obj);
      }).length === item.length : isObject(item) && !isObjectEmpty(item);
      if (isValid) {
        return item;
      }
    }
  } catch (err) {
    _iterator2.e(err);
  } finally {
    _iterator2.f();
  }
  return isCagr ? [emptyObj, emptyObj] : emptyObj;
};
var getTradingObject = function getTradingObject(_ref4) {
  var cellName = _ref4.cellName,
    priceStats = _ref4.priceStats,
    tableNameId = _ref4.tableNameId;
  var _findObjectInStore = findObjectInStore(cellName, priceStats, "valuation"),
    cellObj = _findObjectInStore.cellObj,
    matchingKey = _findObjectInStore.matchingKey,
    matchingSourceName = _findObjectInStore.matchingSourceName;
  if (isString(cellObj)) {
    return cellObj;
  }
  return fillWithCellMetaData({
    object: {
      unauth: false,
      v: cellObj
    },
    cellName: matchingKey,
    sourceName: matchingSourceName,
    tableNameId: tableNameId
  });
};
var getCompanyObject = function getCompanyObject(_ref5) {
  var cellName = _ref5.cellName,
    companyStockValues = _ref5.companyStockValues,
    tableNameId = _ref5.tableNameId;
  var _findObjectInStore2 = findObjectInStore(cellName, companyStockValues, "valuation"),
    cellObj = _findObjectInStore2.cellObj,
    matchingKey = _findObjectInStore2.matchingKey,
    matchingSourceName = _findObjectInStore2.matchingSourceName;
  return fillWithCellMetaData({
    object: cellObj || {},
    cellName: matchingKey,
    sourceName: matchingSourceName,
    tableNameId: tableNameId
  });
};
var findObjectInStore = function findObjectInStore() {
  var cellName = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : "";
  var object = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : {};
  var matchingSourceName = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : "";
  var exact = arguments.length > 3 ? arguments[3] : undefined;
  var matchingKey = Object.keys(object).find(function (key) {
    return exact ? key === cellName : key.startsWith(cellName);
  });
  if (!matchingKey) {
    return {};
  }
  return {
    cellObj: object[matchingKey],
    matchingKey: matchingKey,
    matchingSourceName: matchingSourceName
  };
};
var getValuationKey = function getValuationKey(cellObj, relativeDate) {
  var lastKey = getLastKeyInObject(cellObj);
  if (!relativeDate) {
    return lastKey;
  }
  var keys = Object.keys(cellObj);
  var objIndex = keys.length + relativeDate;
  return keys[objIndex - 1];
};
var getValuationObject = function getValuationObject(_ref6) {
  var _$store$state$ciq;
  var cellName = _ref6.cellName,
    tid = _ref6.tid,
    $store = _ref6.$store,
    tableNameId = _ref6.tableNameId,
    relativeDateKey = _ref6.relativeDateKey,
    index = _ref6.index,
    exact = _ref6.exact;
  var dataObj = (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.addMultiples) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq[tid]) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.tblDataObj;
  var _findObjectInStore3 = findObjectInStore(cellName, dataObj, "valuation", exact),
    cellObj = _findObjectInStore3.cellObj,
    matchingKey = _findObjectInStore3.matchingKey,
    matchingSourceName = _findObjectInStore3.matchingSourceName;
  var objKey = getValuationKey(cellObj, relativeDateKey);
  return fillWithCellMetaData({
    object: cellObj[objKey] || emptyObj,
    cellName: matchingKey,
    sourceName: matchingSourceName,
    tableNameId: tableNameId,
    index: index
  });
};
var getValuationStatsObject = function getValuationStatsObject(_ref7) {
  var _$store$state$ciq2;
  var cellName = _ref7.cellName,
    tid = _ref7.tid,
    $store = _ref7.$store,
    _ref7$valuePropKey = _ref7.valuePropKey,
    valuePropKey = _ref7$valuePropKey === void 0 ? null : _ref7$valuePropKey,
    tableNameId = _ref7.tableNameId;
  var dataObj = (_$store$state$ciq2 = $store.state.ciq) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2.addMultiples) === null || _$store$state$ciq2 === void 0 || (_$store$state$ciq2 = _$store$state$ciq2[tid]) === null || _$store$state$ciq2 === void 0 ? void 0 : _$store$state$ciq2.stats;
  var _findObjectInStore4 = findObjectInStore(cellName, dataObj, "valuation"),
    cellObj = _findObjectInStore4.cellObj,
    matchingKey = _findObjectInStore4.matchingKey,
    matchingSourceName = _findObjectInStore4.matchingSourceName;
  if (!valuePropKey) {
    if (isDev()) {
      console.warn("prop valuePropKey missing");
    }
  } else {
    var cell = cellObj ? {
      u: 0,
      unauth: false,
      v: (cellObj === null || cellObj === void 0 ? void 0 : cellObj[valuePropKey]) || NaN
    } : emptyObj;
    return fillWithCellMetaData({
      object: cell,
      cellName: matchingKey,
      sourceName: matchingSourceName,
      tableNameId: tableNameId
    });
  }
};
var getFinancialItemByCellName = function getFinancialItemByCellName(_ref8) {
  var _quotesData$cellObj;
  var cellName = _ref8.cellName,
    quotesFinObj = _ref8.quotesFinObj,
    tableNameId = _ref8.tableNameId,
    storeFinancials = _ref8.storeFinancials,
    fiscalYear = _ref8.fiscalYear;
  var quotesData = findObjectInStore(cellName, quotesFinObj, "financials");
  if ((_quotesData$cellObj = quotesData.cellObj) !== null && _quotesData$cellObj !== void 0 && _quotesData$cellObj.dataitemid) {
    return fillWithCellMetaData({
      object: quotesData.cellObj,
      cellName: quotesData.matchingKey,
      sourceName: quotesData.matchingSourceName,
      tableNameId: tableNameId,
      fiscalYear: fiscalYear
    });
  } else {
    var _findObjectInStore5 = findObjectInStore(cellName, storeFinancials === null || storeFinancials === void 0 ? void 0 : storeFinancials.resData, "financials"),
      cellObj = _findObjectInStore5.cellObj,
      matchingKey = _findObjectInStore5.matchingKey,
      matchingSourceName = _findObjectInStore5.matchingSourceName;
    return fillWithCellMetaData({
      object: (cellObj === null || cellObj === void 0 ? void 0 : cellObj[fiscalYear]) || emptyObj,
      cellName: matchingKey,
      sourceName: matchingSourceName,
      tableNameId: tableNameId,
      fiscalYear: fiscalYear
    });
  }
};
var getFinancialsDateKey = function getFinancialsDateKey(_ref9) {
  var _ref9$relativeDateKey = _ref9.relativeDateKey,
    relativeDateKey = _ref9$relativeDateKey === void 0 ? 0 : _ref9$relativeDateKey,
    recentFiscalYearFinancials = _ref9.recentFiscalYearFinancials,
    desiredDateKey = _ref9.desiredDateKey,
    formula = _ref9.formula;
  var _recentFiscalYearFina = recentFiscalYearFinancials.split("##"),
    _recentFiscalYearFina2 = _slicedToArray(_recentFiscalYearFina, 2),
    year = _recentFiscalYearFina2[0],
    periodStr = _recentFiscalYearFina2[1];
  var desiredYear = Number(year) + relativeDateKey;
  var isYoY = formula === "YoY";
  if (isYoY) {
    var start = "".concat(desiredYear - 1, "##").concat(periodStr);
    var end = "".concat(desiredYear, "##").concat(periodStr);
    return [start, end];
  }
  return desiredDateKey || "".concat(desiredYear, "##").concat(periodStr);
};
var getFinancialsObject = function getFinancialsObject(_ref10) {
  var cellName = _ref10.cellName,
    tableNameId = _ref10.tableNameId,
    quotesFinObj = _ref10.quotesFinObj,
    storeFinancials = _ref10.storeFinancials,
    recentFiscalYearFinancials = _ref10.recentFiscalYearFinancials,
    formula = _ref10.formula,
    relativeDateKey = _ref10.relativeDateKey,
    desiredDateKey = _ref10.desiredDateKey,
    period = _ref10.period,
    $store = _ref10.$store;
  var formulaKey = (formula === null || formula === void 0 ? void 0 : formula.type) || formula;
  var isYoY = formulaKey === "YoY";
  var isCagr = formulaKey === "cagr";
  var isLtm = period === "a" && relativeDateKey === "ltm";
  var date = getFinancialsDateKey({
    relativeDateKey: relativeDateKey,
    recentFiscalYearFinancials: recentFiscalYearFinancials,
    desiredDateKey: desiredDateKey,
    formulaKey: formulaKey
  });
  if (isCagr) {
    var startRelativePeriod = formula.startRelativePeriod,
      endRelativePeriod = formula.endRelativePeriod;
    return getCagr({
      startRelativePeriod: relativeDateKey + startRelativePeriod,
      endRelativePeriod: relativeDateKey + endRelativePeriod,
      key: cellName,
      sourceName: "financials",
      period: period,
      $store: $store,
      financialFiscalYearKey: isLtm ? "mostRecentLtmKey" : "mostRecentDateKey"
    });
  } else if (isYoY) {
    var first = getFinancialItemByCellName({
      cellName: cellName,
      tableNameId: tableNameId,
      quotesFinObj: quotesFinObj,
      storeFinancials: storeFinancials,
      fiscalYear: date[0]
    });
    var last = getFinancialItemByCellName({
      cellName: cellName,
      tableNameId: tableNameId,
      quotesFinObj: quotesFinObj,
      storeFinancials: storeFinancials,
      fiscalYear: date[1]
    });
    return [first, last];
  } else {
    return getFinancialItemByCellName({
      cellName: cellName,
      tableNameId: tableNameId,
      quotesFinObj: quotesFinObj,
      storeFinancials: storeFinancials,
      fiscalYear: date
    });
  }
};
var fillWithCellMetaData = function fillWithCellMetaData(_ref11) {
  var _ref11$object = _ref11.object,
    object = _ref11$object === void 0 ? {} : _ref11$object,
    _ref11$cellName = _ref11.cellName,
    cellName = _ref11$cellName === void 0 ? "" : _ref11$cellName,
    _ref11$sourceName = _ref11.sourceName,
    sourceName = _ref11$sourceName === void 0 ? "" : _ref11$sourceName,
    _ref11$tableNameId = _ref11.tableNameId,
    tableNameId = _ref11$tableNameId === void 0 ? "" : _ref11$tableNameId,
    _ref11$fiscalYear = _ref11.fiscalYear,
    fiscalYear = _ref11$fiscalYear === void 0 ? "" : _ref11$fiscalYear,
    _ref11$index = _ref11.index,
    index = _ref11$index === void 0 ? 0 : _ref11$index;
  if (isEmpty(object) || !cellName || !sourceName) {
    return emptyObj;
  }
  return _objectSpread(_objectSpread({}, object), {}, {
    _cellName: cellName,
    _sourceName: sourceName,
    _tableNameId: tableNameId,
    _fiscalYear: fiscalYear,
    _index: index
  });
};
var getEstimatesObject = function getEstimatesObject(_ref12) {
  var cellName = _ref12.cellName,
    tableNameId = _ref12.tableNameId,
    storeEstimates = _ref12.storeEstimates,
    actualOrEstimate = _ref12.actualOrEstimate,
    dateKey = _ref12.dateKey,
    formula = _ref12.formula,
    period = _ref12.period,
    $store = _ref12.$store,
    relativeDateKey = _ref12.relativeDateKey,
    exact = _ref12.exact;
  var formulaKey = (formula === null || formula === void 0 ? void 0 : formula.type) || formula;
  var yoyFormulas = ["YoY", "YoYdiv"];
  var isQoQ = formulaKey === "QoQ";
  var isYoY = yoyFormulas.includes(formulaKey);
  var _findObjectInStore6 = findObjectInStore(cellName, storeEstimates, "estimates", exact),
    cellObj = _findObjectInStore6.cellObj,
    matchingKey = _findObjectInStore6.matchingKey,
    matchingSourceName = _findObjectInStore6.matchingSourceName;
  var isCAGR = formulaKey === "cagr";
  var cell = cellObj === null || cellObj === void 0 ? void 0 : cellObj[actualOrEstimate];
  if (isCAGR) {
    var startRelativePeriod = formula.startRelativePeriod,
      endRelativePeriod = formula.endRelativePeriod;
    return getCagr({
      startRelativePeriod: relativeDateKey + startRelativePeriod,
      endRelativePeriod: relativeDateKey + endRelativePeriod,
      key: cellName,
      sourceName: "estimates",
      period: period,
      $store: $store
    });
  } else if (isYoY || isQoQ) {
    var first = fillWithCellMetaData({
      object: (cell === null || cell === void 0 ? void 0 : cell[dateKey[0]]) || emptyObj,
      cellName: matchingKey,
      sourceName: matchingSourceName,
      tableNameId: tableNameId,
      fiscalYear: dateKey[0]
    });
    var last = fillWithCellMetaData({
      object: (cell === null || cell === void 0 ? void 0 : cell[dateKey[1]]) || emptyObj,
      cellName: matchingKey,
      sourceName: matchingSourceName,
      tableNameId: tableNameId,
      fiscalYear: dateKey[1]
    });
    return [first, last];
  } else {
    return fillWithCellMetaData({
      object: (cell === null || cell === void 0 ? void 0 : cell[dateKey]) || emptyObj,
      cellName: matchingKey,
      sourceName: matchingSourceName,
      tableNameId: tableNameId,
      fiscalYear: dateKey
    });
  }
};
var getEstimatesDateKey = function getEstimatesDateKey(props, estimates) {
  var _estimates$period;
  var period = (props === null || props === void 0 ? void 0 : props.periodKey) || "a";
  var mostRecentDateKey = (_estimates$period = estimates[period]) === null || _estimates$period === void 0 ? void 0 : _estimates$period.mostRecentDateKey;
  var _mostRecentDateKey$sp = mostRecentDateKey.split("##"),
    _mostRecentDateKey$sp2 = _slicedToArray(_mostRecentDateKey$sp, 2),
    year = _mostRecentDateKey$sp2[0],
    periodStr = _mostRecentDateKey$sp2[1];
  var desiredYear = Number(year) + (props.relativeDateKey || 0);
  var formulaKey = getFormulaKey(props.formula);
  var periodRef = periodStr.slice();
  var opts, data;
  if (period === "q") {
    opts = {
      relativeQuarterKey: props.relativeQuarterKey,
      relativeDateKey: props.relativeDateKey
    };
    data = getQuarterDateProps(opts, mostRecentDateKey);
    desiredYear = data.year;
    periodRef = data.quarter;
  }
  if (formulaKey === "YoY") {
    var firstDate = "".concat(desiredYear - 1, "##").concat(periodRef);
    var lastDate = "".concat(desiredYear, "##").concat(periodRef);
    return [firstDate, lastDate];
  } else if (formulaKey === "QoQ") {
    var firstDateData = getQuarterDateProps(_objectSpread(_objectSpread({}, opts), {}, {
      relativeQuarterKey: props.relativeQuarterKey - 1
    }), mostRecentDateKey);
    var _firstDate = "".concat(firstDateData.year, "##").concat(firstDateData.quarter);
    var _lastDate = "".concat(desiredYear, "##").concat(periodRef);
    return [_firstDate, _lastDate];
  } else {
    return (props === null || props === void 0 ? void 0 : props.desiredDateKey) || "".concat(desiredYear, "##").concat(periodRef);
  }
};
var getCellItemBySource = function getCellItemBySource(_ref13) {
  var sourceName = _ref13.sourceName,
    cellName = _ref13.cellName,
    props = _ref13.props,
    $store = _ref13.$store,
    valuePropKey = _ref13.valuePropKey,
    tableNameId = _ref13.tableNameId,
    index = _ref13.index,
    exact = _ref13.exact;
  var formulaKey = getFormulaKey(props.formula);
  var isCagr = formulaKey === "cagr";
  try {
    var period = (props === null || props === void 0 ? void 0 : props.periodKey) || "a";
    var isLtm = period === "a" && props.relativeDate === "ltm";
    var relativeDate = props.relativeDateKey || 0;
    switch (sourceName) {
      case "trading":
        {
          var _useCiqQuotesState = useCiqQuotesState(props),
            priceStats = _useCiqQuotesState.priceStats;
          return getTradingObject({
            cellName: cellName,
            priceStats: priceStats.value,
            tableNameId: tableNameId
          });
        }
      case "company":
        {
          var _useCiqQuotesState2 = useCiqQuotesState(props),
            companyStockValues = _useCiqQuotesState2.companyStockValues;
          return getCompanyObject({
            cellName: cellName,
            companyStockValues: companyStockValues.value,
            tableNameId: tableNameId
          });
        }
      case "valuation":
        {
          var tid = $store.state.ciq.ticker.tradingitemid;
          return getValuationObject({
            cellName: cellName,
            tid: tid,
            $store: $store,
            tableNameId: tableNameId,
            relativeDateKey: relativeDate,
            index: index,
            exact: exact
          });
        }
      case "valuationStats":
        {
          var _tid = $store.state.ciq.ticker.tradingitemid;
          return getValuationStatsObject({
            cellName: cellName,
            tid: _tid,
            $store: $store,
            valuePropKey: valuePropKey,
            tableNameId: tableNameId
          });
        }
      case "financials":
        {
          var _$store$state$ciq3 = $store.state.ciq,
            financials = _$store$state$ciq3.financials,
            quotes = _$store$state$ciq3.quotes;
          var storeFinancials = financials[period] || emptyObj;
          var fiscalYearKey = isLtm ? "mostRecentLtmKey" : "mostRecentDateKey";
          var recentFiscalYearFinancials = storeFinancials === null || storeFinancials === void 0 ? void 0 : storeFinancials[fiscalYearKey];
          var quotesFinObj = quotes === null || quotes === void 0 ? void 0 : quotes.fin;
          return getFinancialsObject({
            cellName: cellName,
            quotesFinObj: quotesFinObj,
            storeFinancials: storeFinancials,
            recentFiscalYearFinancials: recentFiscalYearFinancials,
            formula: props.formula,
            relativeDateKey: relativeDate,
            desiredDateKey: props.desiredDateKey,
            period: period,
            $store: $store,
            tableNameId: tableNameId
          });
        }
      case "estimates":
        {
          var _estimates$period2;
          var estimates = $store.state.ciq.estimates;
          var storeEstimates = (_estimates$period2 = estimates[period]) === null || _estimates$period2 === void 0 ? void 0 : _estimates$period2.resData;
          var actualOrEstimate = props.actualOrEstimateKey;
          var dateKey = getEstimatesDateKey(props, estimates);
          return getEstimatesObject({
            cellName: cellName,
            storeEstimates: storeEstimates,
            actualOrEstimate: actualOrEstimate,
            dateKey: dateKey,
            formula: props.formula,
            relativeDateKey: relativeDate,
            period: period,
            $store: $store,
            tableNameId: tableNameId,
            exact: exact
          });
        }
      default:
        {
          console.log("else", sourceName);
        }
    }
  } catch (error) {
    if (dev) {
      console.log("error", {
        error: error,
        sourceName: sourceName,
        cellName: cellName
      });
    }
    return isCagr ? [emptyObj, emptyObj] : emptyObj;
  }
};
var getCagrKeyByRelativePeriod = function getCagrKeyByRelativePeriod(num) {
  return num <= 0 ? "actual" : "mean";
};
var getCagr = function getCagr(_ref14) {
  var _ciq$estimates, _ciq$financials;
  var startRelativePeriod = _ref14.startRelativePeriod,
    endRelativePeriod = _ref14.endRelativePeriod,
    key = _ref14.key,
    sourceName = _ref14.sourceName,
    _ref14$period = _ref14.period,
    period = _ref14$period === void 0 ? "a" : _ref14$period,
    financialFiscalYearKey = _ref14.financialFiscalYearKey,
    $store = _ref14.$store,
    tableNameId = _ref14.tableNameId;
  var startKey = getCagrKeyByRelativePeriod(startRelativePeriod);
  var endKey = getCagrKeyByRelativePeriod(endRelativePeriod);
  var ciq = $store.state.ciq;
  var cellNameKey = getCurrentCellName({
    cellName: key,
    sourceName: sourceName
  });
  var isEstimatesSource = sourceName === "estimates";
  var storeObj = get(ciq, "".concat(sourceName, ".").concat(period, ".resData"));
  var _findObjectInStore7 = findObjectInStore(cellNameKey, storeObj, sourceName),
    cellObj = _findObjectInStore7.cellObj,
    matchingKey = _findObjectInStore7.matchingKey,
    matchingSourceName = _findObjectInStore7.matchingSourceName;
  var mostRecentFiscalYear = isEstimatesSource ? (_ciq$estimates = ciq.estimates) === null || _ciq$estimates === void 0 || (_ciq$estimates = _ciq$estimates[period]) === null || _ciq$estimates === void 0 ? void 0 : _ciq$estimates.mostRecentDateKey : (_ciq$financials = ciq.financials) === null || _ciq$financials === void 0 || (_ciq$financials = _ciq$financials[period]) === null || _ciq$financials === void 0 ? void 0 : _ciq$financials[financialFiscalYearKey];
  var _getCagrStartEndKeys = getCagrStartEndKeys({
      fiscalYear: mostRecentFiscalYear,
      startRelativePeriod: startRelativePeriod,
      endRelativePeriod: endRelativePeriod
    }),
    _getCagrStartEndKeys2 = _slicedToArray(_getCagrStartEndKeys, 2),
    desiredDateKeyStart = _getCagrStartEndKeys2[0],
    desiredDateKeyEnd = _getCagrStartEndKeys2[1];
  var startPath = isEstimatesSource ? [startKey, desiredDateKeyStart] : [desiredDateKeyStart];
  var endPath = isEstimatesSource ? [endKey, desiredDateKeyEnd] : [desiredDateKeyEnd];
  var cagrStart = fillWithCellMetaData({
    object: get(cellObj, startPath),
    cellName: matchingKey,
    sourceName: matchingSourceName,
    tableNameId: tableNameId
  });
  var cagrEnd = fillWithCellMetaData({
    object: get(cellObj, endPath),
    cellName: matchingKey,
    sourceName: matchingSourceName,
    tableNameId: tableNameId
  });
  return [cagrStart, cagrEnd];
};
var getCagrStartEndKeys = function getCagrStartEndKeys(_ref15) {
  var fiscalYear = _ref15.fiscalYear,
    startRelativePeriod = _ref15.startRelativePeriod,
    endRelativePeriod = _ref15.endRelativePeriod;
  var _fiscalYear$split = fiscalYear.split("##"),
    _fiscalYear$split2 = _slicedToArray(_fiscalYear$split, 2),
    year = _fiscalYear$split2[0],
    periodStr = _fiscalYear$split2[1];
  var startYear = Number(year) + startRelativePeriod;
  var endYear = Number(year) + endRelativePeriod;
  var desiredDateKeyStart = "".concat(startYear, "##").concat(periodStr);
  var desiredDateKeyEnd = "".concat(endYear, "##").concat(periodStr);
  return [desiredDateKeyStart, desiredDateKeyEnd];
};
var getCurrentCellName = function getCurrentCellName(_ref16) {
  var cellName = _ref16.cellName,
    dataSourceName = _ref16.dataSourceName;
  if (isObject(cellName)) {
    var _cellName$find$name, _cellName$find;
    return (_cellName$find$name = (_cellName$find = cellName.find(function (item) {
      return (item === null || item === void 0 ? void 0 : item.source) === dataSourceName;
    })) === null || _cellName$find === void 0 ? void 0 : _cellName$find.name) !== null && _cellName$find$name !== void 0 ? _cellName$find$name : cellName;
  } else {
    return cellName;
  }
};
var getMappedCellItemsByAvailableSource = function getMappedCellItemsByAvailableSource(_ref17) {
  var _ref17$keys = _ref17.keys,
    keys = _ref17$keys === void 0 ? [] : _ref17$keys,
    _ref17$props = _ref17.props,
    props = _ref17$props === void 0 ? {} : _ref17$props,
    $store = _ref17.$store;
  var mappedItems = new Map();
  keys.forEach(function (key) {
    var aliasId = key.aliasId,
      name = key.name,
      source = key.source,
      valuePropKey = key.valuePropKey,
      tableNameId = key.tableNameId,
      exact = key.exact;
    var mappedKey = aliasId || name;
    var isSingleSource = isString(source);
    var itemObject = isSingleSource ? getCellItemBySource({
      sourceName: source,
      cellName: name,
      tableNameId: tableNameId,
      props: props,
      $store: $store,
      valuePropKey: valuePropKey,
      index: 0,
      exact: exact
    }) : getCellByAvailableSource({
      sources: source,
      props: props,
      $store: $store
    });
    mappedItems.set(mappedKey, itemObject);
  });
  return mappedItems;
};
var getYoY = function getYoY(cells) {
  var cellA = cells[0];
  var cellB = cells[1];
  if (isObjectEmpty(cellA) || isObjectEmpty(cellB)) {
    return NaN;
  }
  var cellAValue = Number(findFirstAvailableItemInObject({
    item: cellA,
    keys: valueIdKeys
  }));
  var cellBValue = findFirstAvailableItemInObject({
    item: cellB,
    keys: valueIdKeys
  });
  var value = cellBValue / cellAValue - 1;
  if (cellBValue < cellAValue) {
    value = -Math.abs(value);
  } else {
    value = Math.abs(value);
  }
  return value;
};
var getIsAnyCellUnauth = function getIsAnyCellUnauth(cells) {
  var _iterator3 = _createForOfIteratorHelper(cells),
    _step3;
  try {
    for (_iterator3.s(); !(_step3 = _iterator3.n()).done;) {
      var _step3$value = _slicedToArray(_step3.value, 2),
        val = _step3$value[1];
      if (Array.isArray(val) && val.find(function (obj) {
        return hasProp(obj, "unauth") && (obj === null || obj === void 0 ? void 0 : obj.unauth);
      })) {
        return true;
      } else if (isObject(val) && hasProp(val, "unauth") && val !== null && val !== void 0 && val.unauth) {
        return true;
      }
    }
  } catch (err) {
    _iterator3.e(err);
  } finally {
    _iterator3.f();
  }
  return false;
};
var getCellValue = function getCellValue(cell) {
  return findFirstAvailableItemInObject({
    item: cell,
    keys: valueIdKeys
  });
};
var getIsAnyCellNegative = function getIsAnyCellNegative(cells) {
  var _iterator4 = _createForOfIteratorHelper(cells),
    _step4;
  try {
    for (_iterator4.s(); !(_step4 = _iterator4.n()).done;) {
      var _step4$value = _slicedToArray(_step4.value, 2),
        val = _step4$value[1];
      if (Array.isArray(val) && val.find(function (obj) {
        return getCellValue(obj) < 0;
      })) {
        return true;
      } else if (isObject(val) && getCellValue(val) < 0) {
        return true;
      }
    }
  } catch (err) {
    _iterator4.e(err);
  } finally {
    _iterator4.f();
  }
  return false;
};
var unloadedSourceObj = {
  iso: "USD",
  u: 2,
  unauth: false,
  v: 0,
  formatted: ""
}; /**
   * @typedef {Object} CellItem
   * @property {string} iso - The ISO code.
   * @property {number} u - The unit type id.
   * @property {boolean} unauth - The unauthorized flag.
   * @property {number} v - The value.
   * @property {string} _cellName - The cell's key name.
   * @property {string} _sourceName - The cell's source name
   * @property {string} [formatted] - The formatted value.
   * @property {number} [dV] - The date value.
   * @property {number} [pc] - The percentage change.
   * @property {boolean} [est] - The estimate flag
   */ /**
      * @typedef {Object} MetricKey
      * @property {string} name - The name of the metric.
      * @property {Array.<MetricSource>} source - The source(s) of the metric.
      */ /**
         * @typedef {Object} MetricSource
         * @property {string} metricKey - A specific metric key.
         * @property {string} source - The source of the metric.
         */ /**
            * Vue composable that provides various computed properties related to a cell's data source.
            *
            * @param {Object} props - The properties object.
            * @param {Object} props.formula - The formula object.
            * @param {Array.<MetricKey>} props.metricKeys - The metric keys array.
            * @param {boolean} props.formattedValue - The formatted value flag.
            * @param {string} props.formatType - The format type.
            * @param {string} props.units - The units.
            *
            * @returns {Object} Computed properties.
            * @returns {Map.<string, CellItem | CellItem[]>} .mappedCellItems - A Map object where the key is a string and the value can be either a single CellItem or an array of CellItem objects. This represents the mapped cell items.
            * @returns {CellItem} .firstCell - The first cell item.
            * @returns {string} .cellValue - The cell value.
            * @returns {boolean} .isInvalid - The flag indicating if the cell value is invalid.
            * @returns {boolean} .isCellLoading - The cell loading state.
            * @returns {boolean} .isAnyCellEmpty - The flag indicating if any cell is empty.
            * @returns {boolean} .isAnyCellUnauth - The flag indicating if any cell is unauthorized.
            * @returns {boolean} .isAnyCellNegative - The flag indicating if any cell is negative.
            */
var useCiqDataSource = function useCiqDataSource(props) {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useCiqQuotesState3 = useCiqQuotesState(),
    quotesCurrencyFormatter = _useCiqQuotesState3.quotesCurrencyFormatter,
    selectedQuotesCurrency = _useCiqQuotesState3.selectedQuotesCurrency,
    lastClosePriceQuotes = _useCiqQuotesState3.lastClosePriceQuotes;
  var _useCommonStrings = useCommonStrings(),
    nonAvailableString = _useCommonStrings.nonAvailableString;
  var _useNumberFormatters = useNumberFormatters(),
    dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter,
    formatValueBasedOnType = _useNumberFormatters.formatValueBasedOnType,
    twoDigitUsdCurrencyFormatter = _useNumberFormatters.twoDigitUsdCurrencyFormatter;
  var isCellLoading = computed(function () {
    var allSources = getAllSources(props.metricKeys);
    var isCurrency = props.formatType === "currency";
    var formulaKey = getFormulaKey(props.formula);
    var isYield = formulaKey === "yield";
    var loaders = getCellLoaders({
      $store: $store,
      isYield: isYield,
      isCurrency: isCurrency,
      sources: allSources
    });
    var loading = !loaders.every(function (item) {
      return item === false;
    });
    return loading;
  });
  var mappedCellItems = computed(function () {
    if (isCellLoading.value) {
      return props.metricKeys.reduce(function (acc, item) {
        var aliasId = item.aliasId,
          name = item.name;
        var mappedKey = aliasId || name;
        acc.set(mappedKey, unloadedSourceObj);
        return acc;
      }, new Map());
    }
    return getMappedCellItemsByAvailableSource({
      keys: props.metricKeys,
      props: props,
      $store: $store
    });
  });
  var firstCell = computed(function () {
    if (isCellLoading.value) {
      return {};
    }
    var cells = mappedCellItems.value;
    var firstKey = cells.keys().next().value;
    return cells.get(firstKey);
  });
  var isAnyCellEmpty = computed(function () {
    return getIsAnyCellEmpty(mappedCellItems.value);
  });
  var isAnyCellUnauth = computed(function () {
    return getIsAnyCellUnauth(mappedCellItems.value);
  });
  var isAnyCellNegative = computed(function () {
    return getIsAnyCellNegative(mappedCellItems.value);
  });
  var cellValue = computed(function () {
    var _selectedQuotesCurren;
    if (isCellLoading.value) {
      return "";
    }
    var formula = props.formula,
      formatType = props.formatType,
      _props$metricKeys = props.metricKeys,
      metricKeys = _props$metricKeys === void 0 ? [] : _props$metricKeys,
      _props$formattedValue = props.formattedValue,
      formattedValue = _props$formattedValue === void 0 ? true : _props$formattedValue;
    var formulaKey = (formula === null || formula === void 0 ? void 0 : formula.type) || formula;
    var isYield = formulaKey === "yield";
    var numeratorObj = metricKeys === null || metricKeys === void 0 ? void 0 : metricKeys[0];
    var aliasId = numeratorObj.aliasId,
      name = numeratorObj.name;
    var cells = mappedCellItems.value;
    var numerator = cells.get(aliasId || name);
    var isUSDobject = (numerator === null || numerator === void 0 ? void 0 : numerator.iso) === "USD";
    var needsUsdConversion = formatType === "currency" && selectedQuotesCurrency.value.code === "USD";
    var value;
    if (isYield) {
      value = getYieldValue(cells, props.metricKeys, lastClosePriceQuotes.value);
    } else {
      value = getValueByFormula(cells, formula, props.metricKeys, props.units, formatType, selectedQuotesCurrency.value, nonAvailableString.value, isAnyCellEmpty.value);
    }
    var isAnyCellInvalid = !value || isAnyCellEmpty.value;
    var isWholePct = formatType === "whole_pct"; // since this now expects either a number, NaN or undefined
    // we should maybe try to remove the if (isString) later
    if (!formattedValue || isString(value)) {
      return value;
    } else if (isAnyCellInvalid) {
      return "-";
    }
    var currentCurrencyIso = (_selectedQuotesCurren = selectedQuotesCurrency.value) === null || _selectedQuotesCurren === void 0 ? void 0 : _selectedQuotesCurren.code;
    var objIso = numerator === null || numerator === void 0 ? void 0 : numerator.iso;
    var isSameCurrency = currentCurrencyIso === objIso;
    var currencyFormatter = isSameCurrency ? quotesCurrencyFormatter.value : dynamicTwoDigitCurrencyFormatter(objIso);
    return formatOverviewMetricByType({
      type: ["yield", "cagr"].includes(formulaKey) ? "pct" : formatType,
      value: isWholePct ? value / 100 : value,
      suffix: props.units,
      needsUsdConversion: needsUsdConversion || isUSDobject,
      currencyFormatter: currencyFormatter,
      usdCurrencyFormatter: twoDigitUsdCurrencyFormatter,
      formattingFn: formatValueBasedOnType
    });
  });
  var unformattedCellValue = computed(function () {
    var _props$formula;
    var formulaKey = ((_props$formula = props.formula) === null || _props$formula === void 0 ? void 0 : _props$formula.type) || props.formula;
    var isYield = formulaKey === "yield";
    var cells = mappedCellItems.value;
    if (isYield) {
      return getYieldValue(cells, props.metricKeys, lastClosePriceQuotes.value);
    }
    return getValueByFormula(cells, props.formula, props.metricKeys, props.units, props.formatType, selectedQuotesCurrency.value, nonAvailableString.value, isAnyCellEmpty.value);
  });
  var isValid = computed(function () {
    // a couple of metrics always returns as a string
    // ex: 52 week high/low, avg 3 month
    if (typeof unformattedCellValue.value === "string") {
      var _unformattedCellValue;
      return ((_unformattedCellValue = unformattedCellValue.value) === null || _unformattedCellValue === void 0 ? void 0 : _unformattedCellValue[0]) !== "∞";
    }
    if (props.ntm) {
      return getNtmValidation(unformattedCellValue.value, mappedCellItems.value);
    }
    return Number.isFinite(unformattedCellValue.value);
  });
  var fallbackCheck = function fallbackCheck(cells) {
    return Array.from(cells.values()).some(function (cell) {
      return cell._index > 0;
    });
  };
  var label = computed(function () {
    if (props.ntm) {
      if (!isValid.value) {
        return "NTM ".concat(props.title);
      }
      var usesFallback = fallbackCheck(mappedCellItems.value);
      var prefix = usesFallback ? "LTM" : "NTM";
      return "".concat(prefix, " ").concat(props.title);
    }
    return props.title;
  });
  return {
    label: label,
    mappedCellItems: mappedCellItems,
    firstCell: firstCell,
    cellValue: cellValue,
    isValid: isValid,
    isCellLoading: isCellLoading,
    isAnyCellEmpty: isAnyCellEmpty,
    isAnyCellUnauth: isAnyCellUnauth,
    isAnyCellNegative: isAnyCellNegative
  };
};
export default useCiqDataSource;