const state = () => ({
  allPeriodsDefault: 2016,
  defaultNewsLang: "EN",
  defaultAppLang: null,
  appLanguages: ["en", "es", "ja", "zh", "ko"],
  includeEnglishNews: true,
  fixedAllPeriodsDefault: true, // sigh this is backwards
  autoOpenChartOnRowClick: true,
  hideAllSharePrice: false,
  reverseDates: false,
  companyFinancialDataSource: "ciq",
  companyNews: true,
  ciqSearch: false,
  marketNews: true,
  unfinished: true,
  autoHidePriceChart: true,
  navColor: false,
  navBarToShow: 1,
  darkMode: false,
  denseStockTabs: false,
  sigDevFormat: false,
  authFavsError: null,
  searchReady: false,
  ciqDisplayFin: false,
  ciqExtraFin: false,
  forumsActive: false,
  ownershipActive: true,
  technicalAnalysis: false,
  refinitiv: true,
  showPayments: false,
  splitComps: true,
  usdComps: false,
  showRefCard: true,
  searchSymbols: [],
  subscriptionIntervalInt: 1,
  subTier: "global",
  selectedRegionSubTier: "",
  subTiers: [
    "2022_v0-free",
    "2022_v0-plus",
    // "2022_v0-pro",
    "2021_v0-global",
    "2022_v0-global",
    "2021_v0-transition",
    "2021_v0-region-nam",
    "2021_v0-region-europe",
    "2021_v0-region-apac",
    "2021_v0-region-other",
    "2021_v0-country-australasia",
    "2021_v0-country-canada",
    "2021_v0-country-china",
    "2021_v0-country-uk",
    "2021_v0-country-japan",
    "2021_v0-country-korea",
    "2021_v0-country-singapore",
    "2021_v0-country-spain",
    "2021_v0-country-us",
  ],
  paymentDialog: false,
  selectedPlanCode: "a-2022_v0-global",
  navItems: [
    {
      icon: "apps",
      title: "Market Overview",
      to: "/markets",
    },
    {
      icon: "bubble_chart",
      title: "Curated Stocks",
      to: "/curated",
    },
    {
      icon: "playlist_add",
      title: "My Watchlists",
      to: "/watchlist",
    },
    {
      icon: "clear_all",
      title: "Screen Stocks",
      to: "/screener",
    },
    {
      icon: "settings",
      title: "Account Settings",
      to: "/profile",
    },
    // {
    //   icon: "view_list",
    //   title: "Peer Table",
    //   to: "/peers"
    // },
    // {
    //   icon: "assessments",
    //   title: "Chart Builder",
    //   to: "/charts"
    // },
  ],
  favIconType: "favorite",
  favs: [],
  favsData: [],
  authFavs: [],
  authFavsData: [],
  nestedNav: [
    { title: "Market Overview", icon: "apps", to: "/markets" },
    {
      title: "Idea Generation",
      icon: "bubble_chart",
      children: [
        { title: "Watchlists", icon: "", to: "" },
        { title: "Screener", icon: "", to: "" },
        { title: "Curated Baskets", icon: "", to: "" },
        { title: "Track Famous Investors", icon: "", to: "" },
      ],
    },
    {
      title: "Fundamental Analysis",
      icon: "",
      children: [
        {
          title: "Detailed Financials",
          icon: "",
          children: [
            { title: "Financial Metrics", icon: "", to: "" },
            { title: "Consensus Estimates", icon: "", to: "" },
            { title: "Valuation Metrics", icon: "", to: "" },
            { title: "Key Ratios", icon: "", to: "" },
            { title: "Peer Tables", icon: "", to: "" },
          ],
        },
        {
          title: "Filings",
          icon: "",
          children: [
            { title: "10Ks & 10Qs", icon: "", to: "" },
            { title: "Transcripts", icon: "", to: "" },
          ],
        },
        { title: "Ownership / Insider Transactions", icon: "", to: "" },
      ],
    },
    { title: "Technical Analysis", icon: "", to: "/technical" },
    { title: "Portfolio Management", icon: "portfolio", to: "/portfolio" },
  ],
})

const mutations = {
  setConfig(state, payload) {
    state[payload.type] = payload.value
  },
  toggleSwitch(state, payload) {
    state[payload.type] = !state[payload.type]
  },
  setCompanyFinancialDataSource(state, payload) {
    state.companyFinancialDataSource = payload
  },
  setRefinitiv(state) {
    state.refinitiv = !state.refinitiv
  },
  setForum(state) {
    state.forumsActive = !state.forumsActive
  },
  setTechnicalAnalysis(state) {
    state.technicalAnalysis = !state.technicalAnalysis
  },
  setCompanyNews(state) {
    state.companyNews = !state.companyNews
  },
  setCiqSearch(state) {
    state.ciqSearch = !state.ciqSearch
  },
  setCiqDisplayFin(state) {
    state.ciqDisplayFin = !state.ciqDisplayFin
  },
  setCiqExtraFin(state) {
    state.ciqExtraFin = !state.ciqExtraFin
  },
  setMarketNews(state) {
    state.marketNews = !state.marketNews
  },
  setUnfinished(state) {
    state.unfinished = !state.unfinished
  },
  setDarkMode(state, payload) {
    if (Object.hasOwn(payload, "dark")) {
      // this condition is for initial load - looking at preferences
      // from local storage. Also payload.vuetify is the vuetify instance
      // and you're setting it in vuex
      payload.vuetify.theme.dark = payload.dark
      state.darkMode = payload.dark
    } else {
      payload.vuetify.theme.dark = !state.darkMode
      localStorage.setItem("tikrDarkMode", !state.darkMode)
      state.darkMode = !state.darkMode
    }
  },
  setLocalStorage(state, { vuexKey, localStorageKey, value }) {
    // payload.localStorageKey - desired localStorageKey
    // payload.vuexKey - desired vuexKey
    // payload.value - the desired value
    const desiredValue = value
    if (localStorageKey && vuexKey) {
      state[vuexKey] = desiredValue
      localStorage.setItem(localStorageKey, desiredValue)
    } else {
      console.error("missing key for config/setlocalStorage")
    }
  },
  setNavColor(state) {
    state.navColor = !state.navColor
  },
  setDenseStockTabs(state) {
    state.denseStockTabs = !state.denseStockTabs
  },
  setautoHidePriceChart(state) {
    state.autoHidePriceChart = !state.autoHidePriceChart
  },
  setNavBarToShow(state, payload) {
    state.navBarToShow = payload.number
  },
  initializeSymbols(state, payload) {
    // this is just replacing the array
    state.searchSymbols = payload.data
    state.searchReady = true
  },
  addFav(state, payload) {
    // make these actions?
    // 1. Call async fetch for watchlist
    // 2. Add/Remove from list
    state.favs.push(payload.symbol)
  },
  removeFav(state, payload) {
    state.favs = state.favs.filter((fav) => fav !== payload.symbol)
  },
  updateFavData(state, payload) {
    state.favsData = payload.data
  },
  signOut(state) {
    state.authFavs = []
    state.authFavsData = []
  },
}

const actions = {
  changeLocalStorage({ commit }, { vuexKey, localStorageKey, value }) {
    commit("setLocalStorage", { vuexKey, localStorageKey, value })
  },
  updateConfigSync({ commit }, { type, value }) {
    commit("setConfig", { type, value })
  },
  // async initalizeIEXSymbols({ commit }) {
  //   const data = await this.$axios.$get(url.iex.symbols)
  //   commit("initializeSymbols", { data })
  // },
  // async addFav({ commit, state }, payload) {
  //   commit("addFav", payload)
  //   // there should probably always be one favorite now
  //   const data = await this.$axios.$get(url.iex.watchList(state.favs))
  //   commit("updateFavData", { data })
  // },
  // async removeFav({ commit, state }, payload) {
  //   commit("removeFav", payload)
  //   if (state.favs.length === 0) {
  //     commit("updateFavData", { data: [] })
  //   } else {
  //     // fetch data, update favs
  //     const data = await this.$axios.$get(url.iex.watchList(state.favs))
  //     commit("updateFavData", { data })
  //   }
  // },
}

export { state, mutations, actions }
