module.exports = {
  "Refer Friends To TIKR.com": "지인에게 TIKR.com 추천하기",
  "You can now provide friends immediate access to TIKR.com with your personal referral link below.":
    "아래의 개인 추천 링크를 통해 지인도 TIKR를 즉시 사용 가능합니다.",
  "As always, please reach out to <a href='mailto:support@tikr.com'>support@tikr.com</a> if you have any feedback or questions":
    "피드백이나 질문이 있으시면 support@tikr.com 로 이메일 보내주세요.",

  "Market Overview": "시장 뉴스",
  "Idea Generation": "주식 아이디어 찾기",
  "My Watchlists": "나의 관심종목 리스트",
  "Track Investing Gurus": "헤지펀드 / 투자 전문가의 포트폴리오 트랙하기",

  "Company Overview": "회사 개요",
  "Company News": "회사 뉴스",
  "Detailed Financials": "상세 재무 정보",
  Valuation: "밸류에이션 / 가치 평가",
  "Analyst Estimates": "애널리스트 실적 전망",
  "Call Transcripts": "실적발표 컨퍼런스 콜 스크립트",
  "Company Filings": "회사 재무제표 및 공시 정보",
  Ownership: "주주 리스트",
  "Account Settings": "계정 설정",
  "Sign Out": "로그 아웃",
  "Search Tickers...": "주식 티커 검색",

  "TIKR Terminal": "TIKR 터미널",
  "Support TIKR Beta Promo": "TIKR 베타 프로모션 지원",
  "Curated Stock Baskets": "정리된 주식 바구니",
  "Discussion Board": "토론 게시판",
  Screener: "스크리너",
  Search: "검색",
  "Fundamental Analysis": "펀더멘탈 분석",
  "Public Filings": "회사 재무제표 및 공시 정보",
  "App Config": "앱 구성",
  "TIKR Settings": "TIKR 설정",
  Settings: "설정",
  "New Version of TIKR Available": "새 버전의 TIKR 사용 가능",
  "Reload Page": "페이지 새로 고침",
  "We've updated our ": "가 업데이트 되었습니다.",
  "Terms of Service": "서비스 약관",
  " and ": "그리고",
  "Accept Terms": "약관 동의",
  "Watchlist:": "관심목록 :",
  "Active Watchlist:": "활성된 관심 종목 리스트:",
  "Use top search bar to select a ticker to view":
    "상단 검색 창을 사용하여 원하는 티커를 선택하세요.",
  "(e.g. NFLX)": "(예 : NFLX)",

  "Add Ticker": "주식 티커 추가",
  "Watchlist Settings": "관심 종목 리스트 설정",
  "Add Watchlist": "관심 종목 리스트 추가",
  "Watchlist Columns": "관심 종목 리스트 열",
  "Active Watchlist": "활성된 관심 종목 리스트",
  "Set As Active Watchlist": "관심목록으로 설정",
  "Delete Watchlist": "관심 종목 리스트 삭제",
  "Or Click on heart next to company name to add Ticker to active Watchlist":
    "회사 이름 옆 하트를 클릭하면 활성된 관심 종목 리스트에 추가 가능",
  "Watchlist Title": "관심 종목 리스트 제목",
  "Watchlist Description": "관심 종목 리스트 설명",
  Actions: "액션 아이템",

  "Upcoming Events": "다가오는 이벤트",
  "Select a watchlist title above to view the watchlist":
    "관심목록을 보기 위해서는 위의 관심목록 제목을 선택하십시오.",
  Or: "또는",
  "Click 'Add Watchlist' to create a new watchlist":
    "새 관심 목록을 생성하려면 '관심 목록 추가'를 클릭하십시오.",
  "Delete Ticker": "티커 삭제",
  "Delete List": "목록 삭제",
  "Last Price": "마지막 주가",
  "% Change": "% 변화",
  Name: "이름",

  "NTM TEV/REV": "총기업가치/매출 (다음 12개월)",
  "NTM TEV/EBITDA": "총기업가치/EBITDA (다음 12개월)",
  "NTM TEV/EBIT": "총기업가치/영업이익 (다음 12개월)",
  "NTM P/E": "PER (다음 12개월)",
  "NTM MC/FCF": "시가총액/잉여현금흐름 (다음 12개월)",
  "NTM P/CFPS": "주가/주당현금흐름 (다음 12개월)",
  "NTM P/FFO": "주가/FFO (다음 12개월)",
  "NTM P/AFFO": "주가/AFFO (다음 12개월)",
  "Mean Analyst Target": "애널리스트 목표가 평균",
  "Mean Analyst NAV": "애널리스트 NAV 평균",
  "LTM TEV/REV": "총기업가치/매출 (지난 12개월)",
  "LTM TEV/EBITDA": "총기업가치/EBITDA (지난 12개월)",
  "LTM TEV/EBIT": "총기업가치/영업이익 (지난 12개월)",
  "LTM P/Diluted EPS before Extra": "PER (지난 12개월) - 특수항목 전 EPS",
  "LTM P/BV": "주가 / 주당순자산 (지난 12개월)",
  "LTM P/Tangible BV": "주가/주당유형순자산 (지난 12개월)",
  "LTM TEV/Unlevered FCF": "총기업가치/이자비용 전 잉여현금흐름 (지난 12개월)",
  "LTM MC/Levered FCF": "총기업가치/잉여현금흐름 (지난 12개월)",
  "Quote Currency": "견적 통화",
  "Quote Time": "견적 시간",
  "Or Click on": "혹은 XX를 누르세요.",
  "next to company name to add Ticker to active Watchlist":
    "활성된 관심목록에 티커를 추가하십시오.",
  Watchlist: "관심 목록",
  "Are you sure you want to delete this watchlist?":
    "이 관심 목록을 삭제 하시겠습니까?",
  "Error: Watchlist must have a title":
    "오류 : 관심 목록에는 제목이 있어야합니다.",
  "Error: watchlist is full - cannot add more than":
    "오류 : 관심 목록이 가득 찼습니다. 더 이상 추가 할 수 없습니다.",
  "Please Create a new watchlist": "새로운 관심 목록을 만드십시오",
  "from exchange": "거래소에서 온",
  "already exists on this watchlist!": "이 주시 목록에 이미 있습니다!",
  "Error: You've reached your watchlist limit (max":
    "오류 : 관심 목록 한도 (최대",
  "Please delete another watchlist and try again":
    "다른 관심 목록을 삭제하고 다시 시도하십시오.",
  "There has been an error - please try again or contact support":
    "오류가 발생했습니다. 다시 시도하거나 지원팀에 문의하세요.",
  "New Watchlist Title": "새로운 관심 목록 제목",
  "This is the title for your new Watchlist (can change later)":
    "새 관심 목록의 제목입니다 (나중에 변경 가능).",
  "Description of what watchlist will contain":
    "감시 목록에 포함될 내용에 대한 설명",
  "Describe what this watchlist contains (can change later)":
    "이 관심 목록에 포함 된 내용을 설명하십시오 (나중에 변경 가능).",
  "Select Stock Tickers": "주식 티커 선택",
  Back: "뒤",
  Next: "다음",
  "Create Watchlist": "관심 목록 만들기",
  "Create New Watchlist": "새 관심 목록 만들기",
  "Add Stocks to": "XX에 주식 추가",
  "Please create an active watchlist in “My Watchlists” before adding a favorite ticker!":
    "새로운 티커를 추가하기 전에 '내 관심 목록'에 활성 관심 목록을 만드십시오!",

  "If you want to add any other funds to these lists let us know at <a href='mailto:support@tikr.com'>support@tikr.com</a>":
    "이 목록에 다른 펀드를 추가하고 싶으시면 support@tikr.com으로 문의 주세요.",
  "Search Investing Gurus": "헤지펀드 / 투자 전문가 검색",
  "Growth at a Reasonable Price (GARP)": "GARP (성장 대비 가치 투자)",
  Value: "가치 투자",
  "Aggressive Growth": "공격적 성장 투자",
  Other: "그 외",
  "Investor / Fund Name": "투자자 / 펀드 명",
  "Rows per page": "페이지 당 펀드 개수",
  Terms: "이용 약관",
  Privacy: "개인 정보 정책",

  Zoom: "확대하기",
  "October 23, 2020 Close": "2020 년 10 월 23 일 장 마감",
  "57% price return over 3 years - 16% CAGR (Compound Annual Growth Rate)":
    "3 년 동안 57 % 수익 - 16 % CAGR (연간 복합 성장률)",
  "Add Tickers to Chart...": "차트에 티커 추가",
  "Show Relative Change %": "상대적 변화율 표시",
  "52 WEEK HIGH": "52 주 최고가",
  "52 WEEK LOW": "52 주 최저가",
  "AVERAGE 3 MONTH DAILY VOLUME": "3 개월 평균 일일 거래량",
  "SHARES OUTSTANDING": "발행 주식수",
  "Short Interest %": "공매도 비율",
  "MARKET CAP (MM)": "시가 총액",
  "TEV (MM)": "총 기업 가치 (TEV)",
  "Show Price Chart": "가격 차트 표시",

  "Price Chart Hidden": "가격 차트 숨김",
  Overview: "회사 개요",
  News: "회사 뉴스",
  Financials: "상세 재무 정보",
  Estimates: "애널리스트 실적 전망",
  Consensus: "일치",
  Transcripts: "실적발표 컨퍼런스 콜 스크립트",
  Filings: "회사 재무제표 및 공시 정보",
  Discuss: "논의하다",
  Close: "닫기",
  " Close": "닫기",
  "Close Window": "창 닫기",
  "Previous close": "이전 마감",
  "IEX real time price": "IEX 실시간 가격",
  "15 minute delayed price": "15 분 지연된 가격",
  "Business Owner Mode Activated - All Stock Prices Hidden":
    "비즈니스 소유자 모드 활성화 됨-모든 주가 숨김",
  "# of No Opinions": "의견 없음의 갯수",
  "NTM = Next Twelve Months &nbsp;&nbsp;&nbsp; LTM = Last Twelve Months":
    "NTM = 다음 12 개월; LTM = 지난 12 개월",
  "Total Operating Expenses": "총 운영 비용",
  "Restructuring Charges": "구조 조정 비용",
  "Annualized Distributions per Unit": "단위당 연간 분포",
  "Trust Income": "신뢰 소득",
  "Income From Trading Activities": "거래 활동 수입",
  "Gain (Loss) on Sale of Invest. & Securities":
    "Invest 매각시 이익 (손실). 및 증권",
  "Total Other Non Interest Income": "기타 비이자 수익",
  "Occupancy Expense": "점유 비용",
  "Depreciation & Amortization - (IS) - (Collected)":
    "감가 상각 및 상각 - (IS)",
  "Allowance For Equity Funds Used During Construction":
    "건설 중 사용 된 주식 자금에 대한 수당",
  "Allowance For Borrowed Funds Used During Construction":
    "건설 중 사용 된 차입금에 대한 수당",
  "Depreciation, Depletion & Amortization": "감가 상각, 고갈 및 상각",
  "Total Asset Writedown": "총 자산 감액",
  "Restructuring Activities": "구조 조정 활동",
  "Purchase / Sale of Intangible Assets": "무형 자산 매매",
  "Sale of Property, Plant and Equipment": "부동산, 플랜트 및 장비 판매",
  "Net Increase (Decrease) in Deposit Accounts": "예금 계좌의 순증가 (감소)",
  "Regulatory Assets": "규제 자산",
  "Net Loans": "순 대출",
  "Other Liabilities": "기타 부채",
  "Total Other Investments": "총 기타 투자",
  "Total investments": "총 투자",
  "Investment In Debt and Equity Securities": "부채 및 지분 증권에 대한 투자",

  "N/A": "N/A",
  "There was an error fetching price quotes and market cap data":
    "가격 견적 및 시가 총액 데이터를 가져 오는 중에 오류가 발생했습니다.",

  "Error Fetching Quotes": "견적을 가져 오는 중에 오류가 발생했습니다.",
  Show: "보여 주다",
  Hide: "숨는 장소",
  Data: "데이터",
  "Hide OHLC": "OHLC 숨기기",
  "Show OHLC": "OHLC 표시",
  "Show Absolute Value": "절대 값 표시",
  "1d": "1 일",
  "3m": "3 분",
  "6m": "6 분",
  ytd: "ytd",
  "1yr": "1 년",
  "3yr": "3 년",
  "5yr": "5 년",
  "10yr": "10 년",
  "price return over": "가격 반환",
  Volume: "음량",
  years: "년",

  "LTM (Last Twelve Months)": "LTM (지난 12 개월)",
  "* Annual Financials in Millions of US Dollar from 12/31/12 to 6/30/20":
    "* 12/31/12부터 6/30/20까지의 연간 재무정보 ($백만 달러)",
  "Income Statement": "손익 계산서",
  "Balance Sheet": "대차 대조표",
  "Cash Flow Statement": "현금 흐름표",
  Ratios: "재무 비율 정보",
  "Chart Data": "차트로 표시",
  "Clear Chart": "차트 리셋하기",
  "Show Labels": "래이블 표시하기",
  "Hide Chart": "차트 숨기기",

  "Error Fetching Data": "데이터를 가져 오는 동안 오류가 발생했습니다.",
  "Select Metric": "메트릭 선택",
  "Select Line Item": "라인 항목 선택",
  "Display Currency:": "통화 표시",
  "Display Units:": "유닛 표시",
  "Decimals to Display:": "표시할 소수",
  "Reverse Dates": "날짜 반대로 표시",
  "Copy Table": "테이블 복사",
  "Table Options": "테이블 옵션",
  Annual: "연간",
  Quarterly: "분기별",
  "Semi Annual": "반기별",
  LTM: "지난 12개월",
  "Displayed in thousands": "천 단위로 표시",
  k: "천",
  "Displayed in millions": "백만 단위로 표시",
  MM: "백만",
  "Displayed in billions": "십억 단위로 표시",
  B: "십억",
  "Financials in": "재무",
  Thousands: "천",
  Millions: "백만",
  Billions: "십억",
  of: "의",
  "[currencyName]": "[화폐 이름]",
  from: "...에서",
  to: "...에",
  percent: "퍼센트",
  "Copy Table to Clipboard": "테이블을 클립 보드로 복사",
  CAGR: "CAGR (연평균 성장률)",
  "Sorry, we have no financial data for":
    "죄송합니다. 다음에 대한 재무 데이터가 없습니다.",
  "This is not the": "이것은 XX가 아닙니다.",
  "primary ticker": "기본 티커",
  "for this company which can lead to differences in per share metrics.":
    "주당 메트릭의 차이로 이어질 수 있습니다.",
  "This is not the <i><a>primary ticker</a></i> for this company which can lead to differences in per share metrics":
    "이 회사의 <i> <a> 기본 시세 </a> </ i>가 아니므로 주당 측정 항목에 차이가 발생할 수 있습니다.",
  "Actuals & Forward Estimates": "실제 및 미래 추정",
  E: "예상치",
  A: "실제",

  "Street Targets": "애널리스트 목표치",
  "Price Close": "종가",
  "Street Price Targets": "애널리스트 목표주가",
  "Target Stock Price Mean": "애널리스트 목표주가 (평균)",
  "Target Stock Price Median": "애널리스트 목표주가 (중앙값)",
  "Target Stock Price High": "애널리스트 목표주가 (최고)",
  "Target Stock Price Low": "애널리스트 목표주가 (최저)",
  "Target Stock Price (# Est)": "포함된 목표주가 개수",
  "Street Recommendations": "애널리스트 추천 정보",
  "# of Buys": "매수 추천 개수",
  "# of Outperforms": "아웃퍼폼 추천 개수",
  "# of Holds": "보유 추천 개수",
  "# of Underperforms": "언더퍼폼 추천 개수",
  "# of Sells": "매도 추천 개수",

  "Street Estimate": "애널리스트 실적 전망",
  "Net Asset Value / Share Mean": "주당 순자산가치 (평균)",
  "Net Asset Value / Share Median": "주당 순자산가치 (중앙값)",
  "Net Asset Value / Share High": "주당 순자산가치 (최고)",
  "Net Asset Value / Share Low": "주당 순자산가치 (최저)",
  "Net Asset Value / Share (# Est)": "주당 순자산가치",
  "Target Stock Price / Close Price": "목표 주가 / 종가",
  "Price / NAV per Share": "주가 / 주당 순자산가치",

  "Summary Statistics": "정보 요약",
  Mean: "평균",
  Median: "중앙값",
  High: "최고치",
  Low: "최저치",
  "Standard Deviation": "표준 편차",
  Count: "개수",
  "Convert to USD: False (True)": "미국 달러로 변환 : 끄기 (켜기)",
  "Show Summary Statistics Below Competitors":
    "경쟁사 아래에 요약 정보 표시하기",

  "Next Twelve Months": "다음 12 개월",
  "Last Twelve Months": "지난 12 개월",
  Event: "주요 이벤트",
  "Event Date": "주요 이벤트 날짜",
  "Q2 2020 Earnings Call, Aug 05, 2020": "Q2 2020 실적발표, 2020 년 8 월 5 일",
  "Document Title": "문서 제목",
  "Form Name": "양식 이름",
  Language: "언어",
  "Financial Period End Date": "회계 기간 종료 날짜",
  "Release Date": "공시일",

  "Error fetching filings": "파일을 가져 오는 중에 오류가 발생했습니다.",
  "Document will open in a new tab": "문서가 새 탭에서 열립니다.",
  "Please make sure popups are not blocked for tikr.com":
    "tikr.com의 팝업이 차단되지 않았는지 확인하세요.",
  "Fetching Document!": "문서를 가져 오는 중입니다!",
  "Filter By Form Name": "양식 이름으로 필터링",

  Shareholders: "주주",
  "Insider Transactions": "내부자 거래내역",
  "Filter by Investor Type": "투자자 유형으로 필터하기",
  "Price Used to Value Shares Held:": "보유 가치 계산에 사용되는 가격",
  "Holdings Date": "보유 날짜",
  "Last Close": "종가",
  "Investor Name": "투자자 이름",
  "Investor Type": "투자자 유형",
  "Investment Advisor": "펀드 매니저",
  "Investment Advisor/Hedge Fund": "펀드 매니저 / 헤지펀드",
  "Pension Fund": "연금 기금",
  "Research Firm": "리서치 회사",
  "Hedge Fund": "헤지펀드",
  "Individual Investor": "개인투자자",
  "Bank and Trust": "은행 및 신탁 펀드",
  "Sovereign Wealth Fund": "소버린 웰스 펀드",
  Foundation: "재단",

  Insider: "내부자",
  "Number of Shares": "주식 수",
  Price: "주가",
  Date: "날짜",
  "Number of Shares Outstanding Traded": "거래 된 주식 수",
  "Insider Title": "내부자 직함",
  "Chief Executive Officer": "최고 경영자",
  Officer: "임원",
  Unknown: "정보 없음",
  "Chief Financial Officer": "최고 재무 책임자 (CFO)",
  "Executive Vice President": "부사장",
  Type: "유형",
  Filing: "공시문서",
  Investments: "투자",
  "Error Fetching Holdings for Investor ID":
    "투자자 ID에 대한 보유를 가져 오는 중에 오류가 발생했습니다.",
  "Please report to support@tikr.com": "support@tikr.com으로 신고하세요",
  "Error: No holdings for": "오류 : 보유 항목 없음",
  "Please report to support@tikr.com Error: investorId":
    "support@tikr.com으로 신고 해주세요 오류 : investorId",
  "no holdings": "보유주식 없음",
  "Company Name": "회사 이름",
  Ticker: "티커",
  "Reported Value of Shares Held (MM)": "보고 된 보유 주식 가치 (MM)",
  "% of Shares Outstanding Held": "소유 주식 비율",
  "Filing Type": "파일링 유형",
  "Investment Managers": "투자 매니저",
  "Endowment Fund": "기부 기금",
  "Finance Company": "금융 회사",
  "Insurance Company": "보험 회사",
  "Private Equity": "사모 펀드",
  "Venture Capital": "벤처 캐피탈",
  "Brokerage Firms": "중개 회사",
  "Independent Research Firm": "독립 연구 기관",
  "Strategic Entities": "전략적 실체",
  Corporation: "법인",
  "Holding Company": "지주 회사",
  "Government Agency": "정부 기관",
  "Institution sub types": "기관 하위 유형",
  "Mutual Fund": "뮤추얼 펀드",
  "Hedge Fund Portfolio": "헤지 펀드 포트폴리오",
  "Pension Fund Portfolio": "연금 기금 포트폴리오",

  "Error fetching shareholders": "주주를 가져 오는 중에 오류가 발생했습니다.",
  "Value of Shares Held (MM)": "보유 주식의 가치",
  "% Of Shares Outstanding Held": "보유 주식의 가치",
  "# Shares Held": "보유 주식 수",
  "Change in # of Shares Held": "보유 주식수 변동",
  "% Change in # of Shares Held": "주식 보유량 변동 비율",
  "This Holding as % of Firm's Portfolio":
    "전체 포트폴리오 대비 보유 주식 가치",

  "Financial Data Options": "재무 데이터 옵션",
  "Hide All Ticker Share Prices (Business Owner Mode)":
    "모든 티커의 주식 가격 정보 숨기기 (비즈니스 포커스 모드)",
  "Hide Ticker Price Chart after navigating away from company overview":
    "회사 개요에서 다른 탭으로 이동한 후, 자동으로 주식 가격 차트 숨겨지게 하기",
  "Default Year To Start Financials & Estimates Data":
    "재무 및 추정 정보가 시작되는 연도 기본 세팅",
  "App Layout Options": "앱 레이아웃 옵션",
  "Dark Mode Preview": "다크 모드 프리뷰",
  "Updated Significant Developments Formatting": "중요 이벤트 형식 업데이트",
  "Remove icons from tabs while researching an equity":
    "주식 리서치하는 동안 탭에서 아이콘 제거하기",
  "News Options": "뉴스 옵션",
  "Referral Code": "지인 추천 코드",
  True: "진실",
  true: "진실",
  False: "거짓",
  false: "거짓",
  "Reset Settings": "설정 재설정",
  "Default News Language": "기본 뉴스 언어",
  "Include English Stories in News Feeds": "뉴스 피드에 영어 이야기 포함",
  "App Settings": "앱 설정",
  "Link copied to clipboard": "링크가 클립 보드에 복사되었습니다.",
  "Click to copy to clipboard": "클립 보드에 복사하려면 클릭하세요.",
  "There is an error with your Referral Code. Please contact with the error message: 'No Referral Code Found'":
    "추천 코드에 오류가 있습니다. 오류 메시지로 문의하십시오 : '추천 코드를 찾을 수 없음'",

  MULTIPLES: "멀티플 (배수)",
  Multiples: "멀티플 (배수)",
  Competitors: "경쟁자",
  "Forward Multiples": "포워드 멀티플",
  "NTM Total Enterprise Value / Revenues": "총기업가치/매출 (다음 12개월)",
  "NTM Total Enterprise Value / EBITDA": "총기업가치/EBITDA (다음 12개월)",
  "NTM Total Enterprise Value / EBIT": "총기업가치/영업이익 (다음 12개월)",
  "NTM Price / Normalized Earnings": "PER - 정규화된 수익 (다음 12개월)",
  "NTM Market Cap / Free Cash Flow": "시가총액/잉여현금흐름 (다음 12개월)",
  "NTM Price / Cash Flow Per Share": "주가/주당현금흐름 (다음 12개월)",
  "Trailing Multiples": "후행 멀티플",
  "LTM Total Enterprise Value / Revenues": "총기업가치/매출 (지난 12개월)",
  "LTM Total Enterprise Value / EBITDA": "총기업가치/EBITDA (지난 12개월)",
  "LTM Total Enterprise Value / EBIT": "총기업가치/영업이익 (지난 12개월)",
  "LTM Price / Diluted EPS (P/E)": "PER (지난 12개월) - 특수항목 전 EPS (P/E)",
  "LTM Price / Book Value per Share": "주가 / 주당순자산 (지난 12개월)",
  "LTM Price / Tangible Book Value per Share":
    "주가/주당유형순자산 (지난 12개월)",
  "LTM Total Enterprise Value / Unlevered Free Cash Flow":
    "총기업가치/이자비용 전 잉여현금흐름 (지난 12개월)",
  "LTM Market Cap / Levered Free Cash Flow":
    "총기업가치/잉여현금흐름 (지난 12개월)",
  "Price Factors": "가격 요인",
  "Total Enterprise Value (MM)": "총 기업 가치 (백만)",
  "Market Cap (MM)": "시가 총액 (백만)",
  "Forward Factors": "포워드 요인",
  "NTM Revenues": "매출 (다음 12개월)",
  "NTM EBITDA": "EBITDA (다음 12개월)",
  "NTM EBIT": "영업이익 (다음 12개월)",
  "NTM Normalized Earnings Per Share": "정규화 된 주당 순이익 (다음 12개월)",
  "NTM Free Cash Flow": "잉여현금흐름 (다음 12개월)",
  "NTM Cash Flow Per Share": "주당현금흐름 (다음 12개월)",
  "Trailing Factors": "후행 요인",
  "LTM Revenues": "매출 (지난 12개월)",
  "LTM EBITDA": "EBITDA (지난 12개월)",
  "LTM EBIT": "영업이익 (지난 12개월)",
  "LTM Diluted EPS Before Extra": "특수항목 전 EPS(지난 12개월)",
  "LTM Book Value per Share": "주당순자산 (지난 12개월)",
  "LTM Tangible Book Value per Share": "주당유형순자산 (지난 12개월)",
  "LTM Unlevered Free Cash Flow": "이자비용 차감 전 잉여현금흐름 (지난 12개월)",
  "LTM Levered Free Cash Flow": "잉여현금흐름 (지난 12개월)",
  "NTM = Next Twelve Months LTM = Last Twelve Months":
    "NTM = 다음 12 개월 LTM = 지난 12 개월",

  "Return Ratios:": "수익률 관련 비율",
  "Return on Assets %": "자산 수익률 (%)",
  "Return on Capital %": "자산 수익률 (%)",
  "Return On Equity %": "자본 수익률 (%)",
  "Return on Common Equity %": "자본 수익률 (%)",
  "Margin Analysis:": "마진 분석",
  "Gross Profit Margin %": "매출 총 이익률 (%)",
  "SG&A Margin %": "판관비 마진율 (%)",
  "EBITDA Margin %": "EBITDA 마진율 (%)",
  "EBITA Margin %": "EBITA 마진율 (%)",
  "EBIT Margin %": "EBIT 마진율 (%)",
  "EBT Margin %": "EBT 마진율 (%)",
  "EBT Excl. Non-Recurring Items Margin %": "EBT 마진율 (비반복 항목 제외)",
  "Income From Continuing Operations Margin %": "계속영업 이익률 (%)",
  "Net Income Margin %": "순이익률 (%)",
  "Normalized Net Income Margin %": "정규화된 순이익률 (%)",
  "Net Avail. For Common Margin %": "Net Avail. 공통 증거금 %",
  "Levered Free Cash Flow Margin %": "이자가 지급된 현금흐름 마진율 (%)",
  "Unlevered Free Cash Flow Margin %": "이자가 지급되기전 현금흐름 마진율 (%)",
  "Asset Turnover:": "자산 회전율 :",
  "Asset Turnover": "자산 회전율",
  "Fixed Assets Turnover": "고정 자산 회전율",
  "Receivables Turnover": "매출채권 회전율",
  "Inventory Turnover": "재고 회전율",
  "Working Capital Turnover": "운전자본 회전율",
  "Short Term Liquidity:": "단기 유동성 :",
  "Current Ratio": "유동비율",
  "Quick Ratio": "당좌비율",
  "Dividend Payout %": "배당금 %",
  "Dividend Yield %": "배당 수익률",
  "Op Cash Flow to Current Liab": "영업현금흐름 / 유동부채",
  "Avg. Cash Conversion Cycle": "평균 현금 전환주기",
  "Avg. Days Sales Outstanding": "평균 선급금 일수",
  "Avg. Days Outstanding Inventory": "평균 미결 재고 일수",
  "Avg. Days Payable Outstanding": "장기 지급여력:",
  "Long-Term Solvency:": "장기 용해성 :",
  "Total Debt / Equity": "총 부채 / 자본",
  "Total Debt / Capital": "총 부채 / 자본",
  "Total Liabilities / Total Assets": "총 부채 / 총자산",
  "EBIT / Interest Expense": "EBIT /이자 비용",
  "EBITDA / Interest Expense": "EBITDA /이자 비용",
  "(EBITDA - Capex) / Interest Expense": "(EBITDA-Capex) /이자 비용",
  "FFO Interest Coverage": "FFO이자 커버리지",
  "FFO to Total Debt (x)": "FFO / 총부채",
  "Total Debt / EBITDA": "총 부채 / EBITDA",
  "Net Debt / EBITDA": "순부채 / EBITDA",
  "Net Debt / (EBITDA - Capex)": "순 부채 / (EBITDA-Capex)",
  "Industry Metrics:": "산업별 통계 :",
  "Nonperforming Loans / Total Loans %": "부실 대출 / 총 대출 (%)",
  "Nonperforming Loans / Total Assets %": "부실 대출 / 총 자산 (%)",
  "Nonperforming Assets / Total Assets %": "부실 자산 / 총 자산 (%)",
  "Nonperforming Assets / Equity %": "부실 자산 / 자기 자본 (%)",
  "Allowance for Credit Losses / Net Charge-offs %":
    "대출손실/손실처리를 위한 충당금 (%)",
  "Allowance for Credit Losses / Nonperforming Loans %":
    "대출손실/부실대출금을 위한 충당금 (%)",
  "Allowance for Credit Losses / Total Loans %":
    "대출손실/총대출금을 위한 충당금 (%)",
  "Net Charge-offs / Total Average Loans %": "손실처리 / 총 평균 대출금 (%)",
  "Provision for Loan Losses / Net Charge-offs %": "대손충당금 / 손실처리 (%)",
  "Earning Assets / Interest Bearing Liabilities %":
    "수익 자산 /이자 부담 부채 (%)",
  "Interest Income / Average Assets %": "이자 소득 / 평균 자산 (%)",
  "Interest Expense / Average Assets %": "이자 비용 / 평균 자산 (%)",
  "Net Interest Income / Average Assets %": "순이자 소득 / 평균 자산 (%)",
  "Total Average Common Equity / Total Average Assets %":
    "총 평균 자본 / 총 평균 자산 (%)",
  "Total Equity + Allowance for Loan Losses / Total Loans %":
    "총 자본 + 대손 충당금 / 총 대출 (%)",
  "Gross Loans / Total Deposits %": "총 대출 / 총 예금 (%)",
  "Tier 1 Capital Ratio %": "Tier 1 자본 비율",
  "Total Capital Ratio %": "총 자본 비율",
  "Core Tier 1 Capital Ratio %": "핵심 Tier 1 자본 비율",
  "Tier 2 Capital Ratio %": "Tier 2 자본 비율",
  "Equity Tier 1 Capital Ratio %": "Equity Tier 1 자본 비율",

  Revenues: "매출",

  "Finance Div. Revenues": "재무부 매출",
  "Insurance Division Revenues": "보험부 매출",
  "Gain (Loss) on Sale of Assets (Rev)": "자산 매각에 대한 이익 (손실)",
  "Gain (Loss) on Sale of Investment (Rev)": "투자 매각에 대한 이익 (손실)",
  "Interest And Invest. Income (Rev)": "이자 / 투자 이익",
  "Other Revenues": "기타 매출",
  "Total Revenues": "총 매출",
  "% Change YoY": "연간 성장/하락률",

  "Cost of Goods Sold": "매출원가",

  "Gross Profit": "매출총이익",
  "% Gross Margins": "매출총이익률",

  "Selling General & Admin Expenses": "판매비와 관리비",
  "Exploration / Drilling Costs": "탐사 / 시추 비용",
  "Provision for Bad Debts": "대손 충당금",
  "Stock-Based Compensation": "주식기준보상",
  "Pre-Opening Costs": "개장 전 비용",
  "R&D Expense": "연구 개발 비용",
  "R&D Expenses": "연구 개발 비용",

  "Depreciation & Amortization": "감가 상각과 감모 상각",
  "Amortization of Goodwill and Intangible Assets": "영업권 및 무형 자산 상각",
  "Impairment of Oil Gas & Mineral Properties": "석유 가스 및 광물 자산 상각",
  "Other Operating Expenses": "기타 운영 비용",

  "Operating Income": "영업 이익",
  "% Operating Margins": "영업이익률",
  "Interest Expense": "이자 비용",
  "Interest and Investment Income": "이자 및 투자 소득",
  "Interest And Investment Income": "이자 및 투자 소득",

  "Income (Loss) On Equity Invest.": "주식 투자에 대한 이익 (손실)",
  "Currency Exchange Gains (Loss)": "환전 이익 (손실)",
  "Other Non Operating Income (Expenses)": "기타 영업 외 수익 (비용)",
  "EBT Excl. Unusual Items": "법인세비용차감전순이익(손실) - 특이사항 제외",
  "Merger & Restructuring Charges": "합병 및 구조 조정 비용",

  "Impairment of Goodwill": "영업권 자산 손실",
  "Gain (Loss) on Sale of Investments": "투자 매각에 대한 이익 (손실)",
  "Gain (Loss) on Sale of Investments (Rev)": "투자 매각에 대한 이익 (손실)",
  "Gain (Loss) on Sale Of Investments": "투자 매각에 대한 이익 (손실)",
  "Gain (Loss) On Sale Of Assets": "자산 매각에 대한 이익 (손실)",
  "Gain (Loss) on Sale of Assets": "자산 매각에 대한 이익 (손실)",

  "Asset Writedown": "자산 감액",
  "In Process R&D Expenses": "진행중인 R & D 비용",
  "Insurance Settlements": "보험 처리 비용",
  "Legal Settlements": "법적 합의 비용",
  "Other Unusual Items": "기타 특이한 항목",
  "EBT Incl. Unusual Items": "법인세비용차감전순이익(손실) - 특이사항 포함",
  "Income Tax Expense": "세금 비용",
  "Earnings From Continuing Operations": "계속영업이익",
  "Earnings Of Discontinued Operations": "중단 된 운영의 수입",
  "Extraordinary Item & Accounting Change": "특이사항 및 회계 변경",
  "Net Income to Company": "회사 순이익",
  "Minority Interest": "비지배지분",
  "Net Income": "순이익",
  "Preferred Dividend and Other Adjustments": "우선 배당금 및 기타 조정",
  "Net Income to Common Incl Extra Items":
    "지배기업의 소유주에게 귀속되는 순이익",
  "% Net Income to Common Incl Extra Items Margins":
    "지배기업의 소유주에게 귀속되는 순이익률%",
  "Net Income to Common Excl. Extra Items":
    "지배기업의 소유주에게 귀속되는 순이익 (특별사항 포함)",
  "% Net Income to Common Excl. Extra Items Margins":
    "지배기업의 소유주에게 귀속되는 순이익률% (특별사항 포함)",
  "Supplementary Data:": "보충 데이터",
  "Diluted EPS Excl Extra Items": "희석주당이익",
  "Weighted Average Diluted Shares Outstanding": "평균 희석 주식수",
  "Weighted Average Basic Shares Outstanding": "평균 기본 주식수",
  "Dividends per Share": "주당 배당금",
  "Dividends Per Share": "주당 배당금",
  "Special Dividends Per Share": "주당 특별 배당금",
  "Total Dividends Per Share": "주당 총 배당금",
  "Payout Ratio %": "지불 비율 %",
  "Basic EPS": "기본주당이익",
  "FFO Payout Ratio %": "FFO 지불 비율 %",
  FFO: "FFO",
  EBITDA: "EBITDA",
  EBITDAR: "EBITDAR",
  "Selling and Marketing Expense": "판매 및 마케팅 비용",
  "General and Administrative Expense": "일반 및 관리 비용",
  "Effective Tax Rate %": "가중 평균 적용 세율 %",
  "Distributable Cash per Share (Diluted)": "희석 주당 분배 가능한 현금",

  "Same Store Sales Growth %": "동일한 매장 판매 증가율",

  "Interest Income On Loans": "대출이자 소득",
  "Interest Income On Investments": "투자에 대한 이자 소득",
  "Interest Income": "이자 소득",
  "Interest On Deposits": "예금이자",
  "Total Interest On Borrowings": "차입금에 대한 총이자",
  "Net Interest Income": "이자 소득",
  "Non Interest Income Total": "비이자 소득 합계",
  "Revenues Before Provison For Loan Losses": "대출 손실 충당금 전 수익",
  "Provision For Loan Losses": "대출 손실 충당금",
  "Compensation And Benefits": "보상 및 직원 혜택",
  "Total Other Non Interest Expense": "기타 비이자 비용",
  "Non Interest Expense": "비이자 비용",

  "Premiums and Annuity Revenues": "보험료 및 연금 수익",
  "Total Interest And Dividend Income": "총이자 및 배당 소득",
  "Asset Management Fee": "자산 관리 수익",
  "Non-Insurance Activities Revenues": "비보험 활동 수익",
  "Policy Benefits": "보험 혜택",
  "Policy Acquisition /  Underwriting Costs": "보험 인수관련 비용",

  "Fuel & Purchased Power": "연료 및 구매 전력",
  "Operations And Maintenance": "운영 및 유지 관리",

  "Rental Revenues": "임대 수익",
  "Tenant Reimbursements": "임차인 환급",
  "Property Management Fee": "부동산 관리 수수료",
  "Interest Income On Mortgage Securities": "모기지 증권에 대한 이자 소득",
  "Property Expenses": "부동산 비용",

  "Interest and Dividend Income": "이자 및 배당 소득",
  "Commission and Fees": "수수료 및 수수료",
  "Mortgage Banking": "모기지 뱅킹",
  "Credit Card Fee": "신용 카드 수수료",
  "Loan Servicing Revenue": "대출 서비스 수익",
  "Salaries And Other Employee Benefits": "급여 및 기타 직원 혜택",
  "Cost of Services Provided": "제공되는 서비스 비용",

  "Brokerage Commission": "중개 수수료",
  "Trading and Principal Transactions": "거래 및 주요 거래",
  "Underwriting & Investment Banking Fee": "투자 은행 수수료",

  "Impairment of Oil, Gas & Mineral Properties":
    "석유, 가스 및 광물 자산의 손상",
  "Total Depreciation & Amortization": "총 감가 상각 및 감모 상각",
  "Amortization of Deferred Charges": "이연 이익 상각",
  "Minority Interest in Earnings": "비지배지분 소득",

  "(Gain) Loss From Sale Of Asset": "자산 매각에 대한 이익 (손실)",
  "(Gain) Loss on Sale of Investments": "투자 매각에 대한 이익 (손실)",
  "Asset Writedown & Restructuring Costs": "자산 감액 및 구조 조정 비용",
  "Net (Increase) Decrease in Loans Originated / Sold - Operating":
    "순 (증가) 대출 발생 / 매도 감소-운영",
  "Provision for Credit Losses": "대손 충당금",
  "(Income) Loss On Equity Investments": "주식 투자에 대한 이익 (손실)",
  "Tax Benefit from Stock Options": "스톡 옵션에 의한 세금 혜택",
  "Provision and Write-off of Bad Debts": "대손 충당금 및 상각",
  "Net Cash From Discontinued Operations": "중단 영업 순 현금흐름",
  "Other Operating Activities": "기타 영업 활동",
  "Change in Trading Asset Securities": "거래 자산 증권의 변동",
  "Change In Accounts Receivable": "매출채권의 증감액",
  "Change In Inventories": "재고의 증감액",
  "Change In Accounts Payable": "매출채무 증감액",
  "Change In Income Taxes": "소득세 증감액",
  "Change In Deferred Taxes": "이연 법인세 증감액",
  "Change in Other Net Operating Assets": "기타 순 영업 자산의 증감액",
  "Cash from Operations": "영업활동 현금흐름",
  "Memo: Change in Net Working Capital": "메모 : 순 운전 자본의 변동",
  "Capital Expenditure": "유형자산의 취득",
  "Sale of Property, Plant, and Equipment": "유형자산의 처분",
  "Cash Acquisitions": "현금 인수 자금",
  Divestitures: "매각",

  "Sale (Purchase) of Real Estate properties": "부동산 매각 (구매)",
  "Sale (Purchase) of Intangible assets": "무형 자산 매각 (구매)",
  "Investment in Marketable and Equity Securities":
    "유가 증권 및 주식에 대한 투자",
  "Net (Increase) Decrease in Loans Originated / Sold - Investing":
    "부채증권 (증가) 감소",
  "Other Investing Activities": "기타 투자 활동",
  "Cash from Investing": "투자활동 현금흐름",

  "Total Debt Issued": "발행 된 총 부채",

  "Total Debt Repaid": "상환 된 총 부채",
  "Issuance of Common Stock": "보통주 발행",
  "Repurchase of Common Stock": "보통주 환매",
  "Issuance of Preferred Stock": "우선주 발행",
  "Repurchase of Preferred Stock": "우선주 환매",
  "Common Dividends Paid": "보통주 배당금 지급",
  "Preferred Dividends Paid": "우선 배당금 지급",

  "Special Dividend Paid": "특별 배당금 지급",
  "Other Financing Activities": "기타 금융 활동",
  "Cash from Financing": "재무활동 현금흐름",
  "Foreign Exchange Rate Adjustments": "외화환산으로 인한 현금의 변동",
  "Miscellaneous Cash Flow Adjustments": "기타 현금 흐름 조정",
  "Net Change in Cash": "현금및현금성자산의 순증감",
  "Cash and Cash Equivalents, Beginning of Period": "기초의 현금및현금성자산",
  "Cash and Cash Equivalents, End of Period": "기말의 현금및현금성자산",
  "Cash Interest Paid": "현금 이자 지급",
  "Cash Taxes Paid": "현금 세금 지급",
  "Maintenance CAPEX": "유지 유형자산의 취득",
  "Cash Flow per Share": "주당 현금 흐름",

  "Sale / Securitization of Accounts Receivable": "매출 채권의 매각 / 유동화",

  "Total Depreciation, Depletion & Amortization":
    "총 감가 상각, 고갈 및 감모상각",
  "Deferred Policy Acquisition Costs": "지연된 정책 취득 비용",
  "Amort. Of Deferred Policy Acq. Costs": "상각. 지연된 정책 Acq. 소송 비용",
  "Reinsurance Payable": "재보험 지급",
  "Change in Unearned Revenues": "불로 수익의 변화",
  "Change in insurance Reserves / Liabilities": "보험 준비금 / 부채 변경",
  "Annuity Receipts": "연금 영수증",
  "Annuity Payments": "연금 지불",

  "Nuclear Fuel Expenditures": "핵연료 관련 지출",
  "Contributions to Nuclear Decommissioning Trust":
    "원자력 해체 신탁에 대한 기여 비용",

  "Cash And Equivalents": "현금 및 현금성자산",
  "Short Term Investments": "단기투자자산",
  "Trading Asset Securities": "증권거래 가능 자산",
  "Total Cash And Short Term Investments": "현금 및 단기투자 자산",
  "Accounts Receivable": "단기 선급금",
  "Other Receivables": "기타미수금",
  "Notes Receivable": "받을 어음",
  "Total Receivables": "총미수금",
  Inventory: "재고자산",
  "Prepaid Expenses": "선급비용",
  "Finance Division Loans and Leases Current": "금융부 대출 및 임차금 (단기)",
  "Finance Division Other Current Assets": "금융부 기타 유동 자산",

  "Loans Held For Sale": "매도가능 부채증권",
  "Deferred Tax Assets Current": "이연법인세 자산 (유동)",
  "Restricted Cash": "제한 현금",
  "Other Current Assets": "기타 유동 자산",
  "Total Current Assets": "총 유동 자산",
  "Gross Property Plant And Equipment": "유형자산 (역사적 원가)",
  "Accumulated Depreciation": "감가 상각 누계액",
  "Net Property Plant And Equipment": "유형자산",
  "Long-Term Investments": "장기 투자",
  "Long-term Investments": "장기 투자",
  Goodwill: "영업권",
  "Other Intangibles": "기타 무형 자산",
  "Finance Division Loans and Leases Long-Term": "금융부 대출 및 임차금 (단기)",
  "Finance Division Other Long-Term Assets": "금융부 기타 비유동 자산",

  "Accounts Receivable Long-Term": "장기 선급금",
  "Loans Receivable Long-Term": "받을 어음",
  "Deferred Tax Assets Long-Term": "이연법인세 자산 (비유동)",
  "Deferred Charges Long-Term": "이연 청구 미수금 (비유동)",
  "Other Long-Term Assets": "기타 장기 자산",
  "Total Assets": "총 자산",
  "Accounts Payable": "외상 매입 계정",
  "Accrued Expenses": "미지급 비용",
  "Short-Term Borrowings": "단기 차입금",
  "Short-term Borrowings": "단기 차입금",

  "Current Portion of Long-Term Debt": "장기 부채의 현재 부분",
  "Current Portion of Capital Lease Obligations": "자본 임대 의무의 현재 부분",
  "Finance Division Debt Current": "재무부 부채 (단기)",
  "Finance Division Other Current Liabilities": "금융부 기타 유동 부채",

  "Current Income Taxes Payable": "미지급 세금",
  "Current Unearned Revenue": "선수 수익 (유동)",
  "Deferred Tax Liability Current": "이연법인세 부채 (유동)",
  "Other Current Liabilities": "기타 유동 부채",
  "Total Current Liabilities": "총 유동 부채",
  "Long-Term Debt": "장기 부채",
  "Capital Leases": "자본리스",
  "Finance Division Debt Non Current": "재무부 비유동 부채",
  "Finance Division Other Non Current Liabilities": "금융부 기타 비유동 부채",

  "Unearned Revenue Non Current": "선수 수익 (비유동)",
  "Pension & Other Post Retirement Benefits": "연금 및 기타 퇴직 후 혜택",
  "Deferred Tax Liability Non-Current": "이연법인세 부채 (비유동)",
  "Deferred Tax Liability Non Current": "이연법인세 부채 (비유동)",
  "Other Non Current Liabilities": "기타 비유동 부채",
  "Total Liabilities": "총부채",
  "Preferred Stock Redeemable": "상환가능 우선주",
  "Preferred Stock Non Redeemable": "상환불가 우선주",
  "Preferred Stock Convertible": "전환 가능 우선주",
  "Preferred Stock - Others": "우선주-기타",
  "Total Preferred Equity": "총 우선 주식",

  "Common Stock": "보통주",
  "Additional Paid In Capital": "추가 자본금",
  "Retained Earnings": "이익 잉여금",

  "Treasury Stock": "자기 주식",
  "Comprehensive Income and Other": "종합 소득 및 기타",
  "Total Common Equity": "총 보통주",
  "Total Equity": "총자본",
  "Total Liabilities And Equity": "총부채 및 자본",
  "Total Shares Out. on Filing Date": "발행주식수",
  "Book Value/Share": "장부자산 / 주",
  "Tangible Book Value": "유형 장부자산",
  "Tangible Book Value/Share": "유형 장부자산 / 주",
  "Total Debt": "총 부채",
  "Net Debt": "순 부채",
  "Total Minority Interest": "비지배지분",
  "Equity Method Investments": "지분법 투자 자산",
  Land: "땅",
  Buildings: "건물",
  "Construction In Progress": "진행중인 건설자산",
  "Full Time Employees": "정규직 직원",

  "Investment Securities": "증권자산",
  "Mortgage Backed Securities": "모기지 담보 증권",
  "Gross Loans": "총 대출",
  "Allowance For Loan Losses": "대출 손실 충당",
  "Other Adjustments to Gross Loans": "총 대출에 대한 기타 조정",
  "Investment in FHLB": "FHLB에 대한 투자",
  "Investment in Real Estate": "부동산 투자",
  "Other Real Estate Owned And Foreclosed": "매도가능 부채증권",
  "Interest Bearing Deposits": "소유 및 압류 된 기타 부동산",
  "Institutional Deposits": "이자 예금",
  "Non-Interest Bearing Deposits": "기관 예금",
  "Total Deposits": "무이자 예금",
  "Federal Home Loan Bank Debt - Long-Term": "총 예금",
  "Trust Preferred Securities (BS)": "연방 주택 융자 - 장기",

  "Investment In Debt Securities": "부채증권",
  "Investment In Equity and Preferred Securities": "주식 및 우선 증권",
  "Policy Loans": "정책 대출",
  "Reinsurance Recoverable - (IS)": "재보험 회수 가능-(IS)",
  "Deferred Policy Acquisition Costs - (BS)": "지연된 정책 취득 비용-(BS)",
  "Separate Account Assets": "별도의 계정 자산",
  "Insurance And Annuity Liabilities": "보험 및 연금 부채",
  "Unpaid Claims": "미지급 청구",
  "Unearned Premiums": "미 근로 보험료",
  "Reinsurance Payable - (BS)": "재보험 미지급금-(BS)",

  "Land Held For Development Or Sale": "개발 또는 판매를 위해 보유한 토지",
  "Total Real Estate Assets": "총 부동산 자산",
  "Mortgage Loans": "모기지 대출",

  "Cash & Securities Segregated": "현금 및 증권",
  "Securities Purchased Under Agreements To Resell":
    "재판매 계약에 따라 구매 한 증권",
  "Securities Borrowed": "차입 된 증권",

  "Deferred Tax Assets Long-Term (Collected)": "이연법인세 자산 (유동)",

  "Revenue Actual": "매출 (실제)",
  "EBITDA Actual": "EBITDA (실제)",
  "EBIT Actual": "EBIT (실제)",
  "EBT Normalized Actual": "정규화된 EBT (실제)",
  "Net Income Normalized Actual": "정규화된 순이익 (실제)",
  "EPS Normalized Actual": "정규화된 EPS (실제)",
  "EPS (GAAP) Actual": "EPS (GAAP) (실제)",
  "Gross Margin Actual (%)": "매출 총 이익률 (실제)",
  "Interest Expense Actual": "이자 비용 (실제)",
  "Depreciation & Amortization Actual": "감가 상각 및 감모상각 (실제)",
  "Effective Tax Rate Actual (%)": "가중 평균 적용 세율 % (실제)",
  "EBT (Excl. Excep & GW) Actual": "EBT (Excep 및 GW 제외) (실제)",
  "EBT (GAAP) Actual": "EBT (GAAP) (실제)",
  "Net Income (Excl. Excep & GW) Actual":
    "당기 순이익 (Excep 및 GW 제외) (실제)",
  "Net Income (GAAP) Actual": "순이익 (GAAP) (실제)",
  "Cash EPS Actual": "현금 EPS (실제)",
  "Cash From Operations Actual": "영업활동 현금흐름 (실제)",
  "Capital Expenditure Actual": "유형자산의 취득 (실제)",
  "Free Cash Flow Actual": "현금 흐름 (실제)",
  "Cash Flow / Share Actual": "주당 현금 흐름 (실제)",
  "Net Debt Actual": "실제 순부채",
  "Book Value / Share Actual": "주당 장부 자산",
  "Net Asset Value Actual": "실제 순자산 가치",
  "Net Asset Value / Share Actual": "주당 실제 순자산 가치",
  "Return on Equity Actual (%)": "자기 자본 수익률 (%) (실제)",
  "Return on Assets Actual (%)": "자산 수익률 (%) (실제)",
  "Distributable Cash (Income Trusts) Actual": "분배 가능한 현금 (실제)",
  "Distributable Cash / Share (Income Trust) Actual":
    "주당 분배 가능한 현금 (실제)",
  "FFO (REIT) Actual": "FFO (REIT) (실제)",
  "FFO / Share (REIT) Actual": "주당 FFO (REIT) (실제)",
  "Adjusted FFO / Share (REIT) Actual": "조정된 주당 FFO (REIT) 실제",
  "Adjusted FFO (REIT) Actual": "조정된 FFO (REIT) 실제",
  "Same Store Sales Growth Actual (%)": "동일한 매장 매출 증가 (실제) (%)",
  "Average Daily Production (Oil)  Actual": "평균 일일 생산량 (석유) 실제",
  "Average Daily Production (Gas)  Actual": "평균 일일 생산량 (가스) 실제",
  "Average Daily Production (NGL)  Actual": "평균 일일 생산량 (NGL) 실제",
  "Total Average Daily Production  Actual": "총 평균 일일 생산량 (실제)",
  "Total Production (Oil) Actual": "총 생산량 (석유) 실제",
  "Total Production (Gas)  Actual": "총 생산량 (가스) 실제",
  "Total Production (NGL)  Actual": "총 생산량 (NGL) 실제",
  "Total Production Actual": "총 생산량 (실제)",
  "Revenues (Oil)  Actual": "매출 (석유) 실제",
  "Revenues (Gas)  Actual": "매출 (가스) 실제",
  "Revenues (NGL)  Actual": "매출 (NGL) 실제",
  "Exploration Expense Actual": "탐사 비용 (실제)",

  Revenue: "매출",

  "% EBITDA Margins": "EBITDA 마진율",
  EBIT: "EBIT",
  "% EBIT Margins": "EBIT 마진율",
  "EBT Normalized": "정규화된 EBT",
  "% EBT Margins": "EBT 마진율",
  "Net Income Normalized": "정규화된 순이익",
  "% Net Income Margins": "순이익 마진율",
  "EPS Normalized": "정규화된 EPS",
  "EPS (GAAP)": "EPS (GAAP)",
  "Dividend Per Share": "주당 배당금",
  "IS Supplementary Data:": "손익계산 보충 데이터 :",
  "Gross Margin (%)": "매출 총 이익률",
  "Effective Tax Rate (%)": "가중 평균 적용 세율 %",
  "EBT (Excl. Excep & GW)": "EBT (Excep 및 GW 제외)",
  "EBT (GAAP)": "EBT (GAAP)",
  "Net Income (Excl. Excep & GW)": "당기 순이익 (Excep 및 GW 제외)",
  "Net Income (GAAP)": "순이익 (GAAP)",
  "Cash EPS": "현금 EPS",
  "CF Supplementary Data:": "현금흐름 보충 데이터",
  "Cash From Operations": "영업활동 현금흐름",
  "Free Cash Flow": "현금 흐름",
  "Cash Flow / Share": "주당 현금 흐름",
  "BS Supplementary Data:": "재무상태 보충 데이터",
  "Net Debt/EBITDA": "순 부채 / EBITDA",
  "Book Value / Share": "주당 장부 자산",
  "Net Asset Value": "순자산 가치",
  "Net Asset Value / Share": "주당 순자산 가치",
  "Return on Equity (%)": "자기 자본 수익률 (%)",
  "Return on Assets (%)": "자산 수익률 (%)",
  "Other Supplementary Data:": "기타 추가 데이터 :",
  "Price Target Mean (Bottom Up)": "목표주가 평균 (하향식)",
  "Distributable Cash (Income Trusts)": "분배 가능한 현금",
  "Distributable Cash / Share (Income Trust)": "주당 분배 가능한 현금",
  "FFO (REIT)": "FFO (REIT)",
  "FFO / Share (REIT)": "주당 FFO (REIT)",
  "Adjusted FFO / Share (REIT)": "조정된 주당 FFO (REIT)",
  "Adjusted FFO (REIT)": "조정된 FFO (REIT)",
  "Same Store Sales Growth (%)": "동일한 매장 매출 증가 (%)",
  "Average Daily Production (Oil)": "평균 일일 생산량 (석유)",
  "Average Daily Production (Gas)": "평균 일일 생산량 (가스)",
  "Average Daily Production (NGL)": "평균 일일 생산량 (NGL)",
  "Total Average Daily Production": "총 평균 일일 생산량",
  "Total Production (Oil)": "총 생산량 (석유)",
  "Total Production (Gas)": "총 생산량 (가스)",
  "Total Production (NGL)": "총 생산량 (NGL)",
  "Total Production": "총 생산량",
  "Revenues (Oil)": "매출 (석유)",
  "Revenues (Gas)": "매출 (가스)",
  "Revenues (NGL)": "매출 (NGL)",
  "Exploration Expense": "탐사 비용",

  "Sign In to Your Account": "계정에 로그인",
  Email: "이메일",
  "Enter your email": "이메일을 입력하세요",
  Password: "비밀번호",
  "Enter your password": "비밀번호를 입력하세요",
  "Forgot Password?": "비밀번호를 잊으셨나요?",
  "Reset Password": "비밀번호 재설정",
  "Sign In": "로그인",
  "No Account?": "계정이 없으십니까?",
  "Create Account": "계정 생성",
  "Required Input Missing": "필수 입력 누락",
  "Email Address Invalid": "잘못된 이메일 주소",
  "Create New Account": "새 계정 만들기",
  "Verify Email": "이메일 확인",
  "Re-Enter Email": "이메일을 다시 입력하세요",
  "Verify Password": "비밀번호 확인",
  "Re-Enter Password": "비밀번호를 다시 입력하세요",
  "Invite Code": "초대 코드",
  "Required: Invite Code": "필수 : 초대 코드",
  "Optional: Invite Code": "선택 사항 : 초대 코드",
  "Have an account?": "계정이 있으신가요?",
  "I accept the": "동의합니다",
  "Privacy Policy": "개인 정보 정책",
  "and the": "그리고",
  "Terms of Use": "이용 약관",
  "Emails Do Not Match": "이메일이 일치하지 않음",
  "Passwords Do Not Match": "비밀번호가 일치하지 않습니다",
  "Must Accept Privacy Policy and Terms of Use":
    "개인 정보 보호 정책 및 이용 약관에 동의해야 합니다.",
  "Verification Code": "확인 코드",
  "Lost your code? Check Spam Folder or":
    "코드를 분실하셨습니까? 스팸 폴더 확인 또는",
  "Resend Code": "코드 재전송",
  "Code Resent to": "재전송 된 코드",
  "Back to Sign In": "로그인으로 돌아가기",
  "User is already confirmed.": "사용자는 이미 확인되었습니다.",
  "Code Resent": "코드 재전송",
  "Send Reset Code": "재설정 코드 보내기",
  "Enter Email to Reset Password": "비밀번호 재설정을 위한 이메일 입력",
  "Confirm Email Verification Code Sent To": "보낸 이메일 확인 코드 확인",
  "Username cannot be empty": "사용자 이름은 비워 둘 수 없습니다.",
  "Must be at least 6 characters long": "6 자 이상이어야 합니다.",
  Required: "필수",
  "Incorrect username or password.":
    "사용자 이름 또는 비밀번호가 잘못되었습니다.",
  "User does not exist.": "사용자가 존재하지 않습니다.",
  "User already exists": "사용자가 이미 존재합니다",
  "Username/client id combination not found.":
    "사용자 이름 / 클라이언트 ID 조합을 찾을 수 없습니다.",
  "Invalid verification code provided, please try again.":
    "잘못된 인증 코드가 제공되었습니다. 다시 시도하십시오.",
  "Financial Data ∀": "재무 데이터 ∀",

  Filter: "필터",
  "Clear Filter": "필터 지우기",
  "Error Fetching News ... Try Refreshing the Page!":
    "뉴스를 가져 오는 중에 오류가 발생했습니다 ... 페이지를 새로 고침하세요!",
  "Close Story": "스토리 닫기",
  "Loading News Story...": "뉴스 기사로드 중 ...",
  "reuters.com": "reuters.com",
  "Sorry, We couldn't find any news for":
    "죄송합니다.에 대한 뉴스를 찾을 수 없습니다.",
  "Significant Developments": "중요한 내용",
  "Error Fetching Significant Developments":
    "중요한 내용을 가져 오는 동안 오류가 발생했습니다.",
  reuters: "로이터",
  "More News": "더 많은 뉴스",

  "Hide Labels": "라벨 숨기기",
  "Click on row in table below to chart":
    "차트를 작성하려면 아래 표의 행을 클릭하십시오.",
  "Convert to USD:": "USD로 변환 :",
  column: "기둥",
  line: "선",
  Average: "평균",
  Last: "마지막",

  Region: "지역",
  "Select Region": "지역 선택",
  "Asia / Pacific": "아시아 태평양",
  "United States and Canada": "미국 및 캐나다",
  Europe: "유럽",
  "Africa / Middle East": "아프리카 / 중동",
  "Latin America and Caribbean": "라틴 아메리카 및 카리브해",

  "Select Period": "기간 선택",
  "Select Relative Period": "상대 기간 선택",
  "Select Fiscal Year": "회계 연도 선택",
  "Select Calendar Year": "달력 연도 선택",
  "Select Quarter": "분기 선택",
  "Fiscal Year": "회계 연도",
  FY: "FY",
  "Calendar Year": "달력 연도",
  CY: "달력 연도",
  "Fiscal Quarter": "회계 분기",
  FQ: "회계 분기",

  "Select Value Type": "값 유형 선택",
  "Mean Consensus Estimate": "평균 합의 추정",
  "Median Consensus Estimate": "중앙값 합의 추정",
  Actual: "실제값",
  "Reported Actual": "보고된 실제값",
  "High Consensus Estimate": "컨센서스 추정치 - High",
  "Low Consensus Estimate": "컨센서스 추정치 - Low",
  "Number of Consensus Estimates": "컨센서스 추정치 갯수",
  "Consensus Estimate Standard Deviation": "컨센서스 추정치 표준 편차",
  "Management Guidance Mid": "경영진 가이던스 - 중간값",
  "Management Guidance High": "경영진 가이던스 - High",
  "Management Guidance Low": "경영진 가이던스 - Low",
  "Market Capitalization": "시가 총액",
  "Total Enterprise Value": "총 기업 가치",
  "Last Close Price": "마지막 종가",
  "Year Founded": "설립 연도",

  "equal to": "동일",
  "not equal to": "같지 않음",
  "greater than": "~보다 큰",
  "greater than or equal to": "보다 크거나 같음",
  "less than": "이하",
  "less than or equal to": "보다 작거나 같음",
  between: "사이에서",
  is: "이다",
  "is not": "아니다",
  "is one of": "중 하나이다",
  "is not one of": "다음 중 하나가 아닙니다",
  any: "어떤",
  all: "모두",
  and: "과",
  or: "또는",
  ">": ">",
  ">=": "> =",
  "<": "<",
  "<=": "<=",
  "=": "=",

  "Sorry! Error searching for": "죄송합니다! 검색 오류",

  Industry: "산업",
  "Select Industry": "산업 선택",
  "Capital Markets": "자본 시장",
  "Metals and Mining": "금속 및 광업",
  "Real Estate Management and Development": "부동산 관리 및 개발",
  "Food Products": "식품",
  Machinery: "기계",
  Chemicals: "화학",
  "Electronic Equipment, Instruments and Components": "전자 장비, 기기 및 부품",
  Banks: "은행",
  Software: "소프트웨어",
  "Oil, Gas and Consumable Fuels": "석유, 가스 및 소모성 연료",
  Pharmaceuticals: "제약",
  "Textiles, Apparel and Luxury Goods": "섬유, 의류 및 명품",
  "Construction and Engineering": "건설 및 엔지니어링",
  "Hotels, Restaurants and Leisure": "호텔, 레스토랑 및 레저",
  "IT Services": "IT 서비스",
  Biotechnology: "생명 공학",
  "Commercial Services and Supplies": "상업 서비스 및 용품",
  "Electrical Equipment": "전기 장비",
  "Semiconductors and Semiconductor Equipment": "반도체 및 반도체 장비",
  "Healthcare Equipment and Supplies": "의료 장비 및 용품",
  "Equity Real Estate Investment Trusts (REITs)":
    "주식 부동산 투자 신탁 (REITs)",
  Media: "미디어",
  "Auto Components": "자동차 부품",
  Entertainment: "환대",
  "Household Durables": "가정용 내구재",
  "Trading Companies and Distributors": "무역 회사 및 유통 업체",
  "Specialty Retail": "전문 소매",
  "Healthcare Providers and Services": "의료 제공자 및 서비스",
  Insurance: "보험",
  "Diversified Financial Services": "다양한 금융 서비스",
  "Professional Services": "전문적인 서비스",
  "Communications Equipment": "통신 장비",
  "Construction Materials": "건축 자재",
  "Food and Staples Retailing": "식품 및 필수품 소매",
  "Building Products": "건축 제품",
  "Containers and Packaging": "용기 및 포장",
  "Personal Products": "개인 제품",
  "Independent Power and Renewable Electricity Producers":
    "독립 전력 및 재생 가능 전기 생산 업체",
  "Diversified Consumer Services": "다양한 소비자 서비스",
  "Energy Equipment and Services": "에너지 장비 및 서비스",
  "Consumer Finance": "소비자 금융",
  "Internet and Direct Marketing Retail": "인터넷 및 다이렉트 마케팅 소매",
  Distributors: "대리점",
  "Interactive Media and Services": "인터랙티브 미디어 및 서비스",
  Beverages: "음료",
  "Technology Hardware, Storage and Peripherals":
    "기술 하드웨어, 스토리지 및 주변 장치",
  "Diversified Telecommunication Services": "다양한 통신 서비스",
  "Paper and Forest Products": "종이 및 임산물",
  "Road and Rail": "도로 및 철도",
  "Electric Utilities": "전기 유틸리티",
  "Transportation Infrastructure": "교통 인프라",
  "Aerospace and Defense": "항공 우주 및 방위",
  "Thrifts and Mortgage Finance": "저축 및 모기지 금융",
  "Air Freight and Logistics": "항공화물 및 물류",
  "Health Care Technology": "건강 관리 기술",
  "Industrial Conglomerates": "산업 대기업",
  Marine: "선박",
  "Leisure Products": "레저 제품",
  "Life Sciences Tools and Services": "생명 과학 도구 및 서비스",
  "Multiline Retail": "멀티라인 리테일",
  Automobiles: "자동차",
  "Gas Utilities": "가스 유틸리티",
  "Water Utilities": "수도 시설",
  "Wireless Telecommunication Services": "무선 통신 서비스",
  Airlines: "항공사",
  "Household Products": "가정 용품들",
  Tobacco: "담배",
  "Mortgage Real Estate Investment Trusts (REITs)":
    "모기지 부동산 투자 신탁 (REITs)",
  "Multi-Utilities": "다중 유틸리티",
  "Upgrade your subscription to copy data. 3 years at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "구독을 업그레이드하여 데이터를 복사하세요. '기본 TIKR 데이터'의 경우 한 번에 3년, '보조 데이터'의 경우 최대 30년까지 사용 가능",
  "Upgrade your subscription to copy data up to 3 years at a time":
    "구독을 업그레이드하여 한 번에 최대 3년의 데이터를 복사하세요.",
  "You can copy 3 years of data at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "'메인 TIKR 데이터'의 경우 한 번에 3년, '보조 데이터'의 경우 최대 30년까지 데이터를 복사할 수 있습니다.",
  "You can copy only 3 years of data at a time":
    "한 번에 3년치 데이터만 복사할 수 있습니다.",
}
