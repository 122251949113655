const capitalize = (s) => {
  if (typeof s !== "string") return ""
  return s.charAt(0).toUpperCase() + s.slice(1)
}

const generatePeriodString = (periodValue) => {
  return periodValue > 2000
    ? periodValue
    : periodValue > 0
    ? `+${periodValue}`
    : periodValue
}

const wholeSinglePeriodString = (periodType, periodStr, I18n) => {
  const noPeriodStr = ["ntm", "ltm"]
  const formPeriodType =
    periodType === "annual" ? "" : I18n(periodType.toUpperCase())
  if (noPeriodStr.includes(periodType)) {
    return `${formPeriodType}`
  } else {
    return `(${formPeriodType}${periodStr})`
  }
}

const didTypeString = (didType, I18n) => {
  return didType !== "actual" ? I18n(capitalize(didType)) : ""
}

const valueTypeString = (valueType, I18n) => {
  return valueType === "yoyChange"
    ? I18n("% Change YoY")
    : I18n(capitalize(valueType))
}

const generateOnePeriodNameString = (
  screenMap,
  I18n,
  didType,
  dataType,
  periodType,
  firstPeriodValue,
  valueType
) => {
  const formDidType = didTypeString(didType, I18n)
  const firstPeriodText = generatePeriodString(firstPeriodValue)
  const periodString = wholeSinglePeriodString(
    periodType,
    firstPeriodText,
    I18n
  )

  const dataTypeObj = screenMap[dataType]
  const dataTypeText = dataTypeObj
    ? dataTypeObj.abbr
      ? dataTypeObj.abbr
      : dataTypeObj.text
    : I18n(dataType.split("_")[1])
  if (valueType) {
    return `${formDidType} ${dataTypeText} ${valueTypeString(
      valueType,
      I18n
    )} ${periodString}`
  } else {
    return `${formDidType} ${dataTypeText} ${periodString}`
  }
}
const generateTwoPeriodNameString = (
  screenMap,
  I18n,
  didType,
  dataType,
  periodType,
  valueType,
  firstPeriodValue,
  secondPeriodValue
) => {
  const formDidType = didTypeString(didType, I18n)
  const formPeriodType =
    periodType === "annual" ? "" : I18n(periodType.toUpperCase())
  const firstPeriodText = generatePeriodString(firstPeriodValue)
  const secondPeriodText = generatePeriodString(secondPeriodValue)

  const dataTypeObj = screenMap[dataType]
  const dataTypeText = dataTypeObj
    ? dataTypeObj.abbr
      ? dataTypeObj.abbr
      : dataTypeObj.text
    : I18n(dataType.split("_")[1])

  return `${formDidType} ${dataTypeText} ${I18n(
    valueType.toUpperCase()
  )} (${formPeriodType}${firstPeriodText} ${I18n(
    "to"
  )} ${formPeriodType}${secondPeriodText})`
}

export { generateOnePeriodNameString, generateTwoPeriodNameString }
