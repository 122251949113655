import { getPublicKey } from "~/services/valuationModel"

export * from "./cache"

export const getYearKey = (dateKeyStr, yearOffset) => {
  if (!dateKeyStr) return null
  const [year] = dateKeyStr.split("##")
  return `${Number(year) + yearOffset}##FY`
}

export const getJWT = async ($AmplifyObj) => {
  const user = await $AmplifyObj.Auth.currentAuthenticatedUser()
  return user.signInUserSession.idToken.jwtToken
}

const sha256 = async (str) => {
  const encoder = new TextEncoder()
  const data = encoder.encode(str)
  const hashBuffer = await crypto.subtle.digest("SHA-256", data)
  const hashArray = Array.from(new Uint8Array(hashBuffer))
  const hashHex = hashArray.map((b) => b.toString(16).padStart(2, "0")).join("")
  return hashHex
}

export const createSecretKey = async ({ $axiosObj }) => {
  const publicKey = await getPublicKey({ $axiosObj })
  const finalKey = `${process.env.MODEL_KEY}${publicKey}`
  const digest = await sha256(finalKey)
  return digest
}
