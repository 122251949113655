const stage = process.env.LAMBDA_STAGE

/**
 * Right now this auth guard is synchronously calling the auth function...
 * This could be blocking in the event that this function needs to call
 * somewhere else.
 */

export default async function ({ store, app, redirect, route, from }) {
  if (route.path === "/oauth") {
    debugger
  }

  const loginPath = (code, redirect) => {
    if (redirect) {
      return `/login?ref=${code}&redirect=${redirect}`
    }

    return `/login?ref=${code}`
  }

  const allowedPaths = [
    "/privacypolicy",
    "/terms",
    "/legal/cookiepolicy",
    "/legal/gdpraddendum",
    "/legal/privacypolicy",
    "/legal/subprocessor",
    "/legal/terms",
    "/pricing",
    "/model-preview",
  ]
  const loginPaths = ["/login", "/forgot", "/register", "/confirm", "/mfa"]
  const homePath = store.state.homePath
  const queryParams = route.query

  // GOAL: capture any marketing query parameters in vuex if on the route
  // will start to send and use these marketingParams when
  // user creates an account (just utm params)
  // hmm, on every page view we are now for eaching through marketingQueryParams
  // is this a bad idea? TODO:

  const marketingQueryParams = store.state.analytics.marketingQueryParams
  marketingQueryParams.forEach((param) => {
    if (queryParams[param]) {
      store.commit("analytics/updateAnalytics", {
        property: param,
        value: queryParams[param],
      })
    }
  })
  try {
    // get the current user from Amplify, active code will query localStorage I believe
    // and it is supposed to also fetch from AWS if out of date but I have my hestiations
    // about how reliabile that actually is.
    // if there is no current user error is thrown and execution continues from catch block
    const user = await app.$Amplify.Auth.currentAuthenticatedUser()
    // const currentSession = await app.$Amplify.Auth.currentSession()

    const attributes = user.signInUserSession.idToken.payload
    // // get the user's refCode
    const userRefCode = attributes["custom:refCode"]
    const sub = attributes.sub
    app.$ga.set("userId", sub)
    // TODO: send userID and set userID with gtag manager
    store.commit("clearAuthError")
    store.commit("updateCurrentUser", { user, from: "authguard" })

    if (loginPaths.includes(route.path) || route.path === "/") {
      // User is logged in and attempting to reach "/" - redirect to homePath
      // or user is attempting to reach login paths

      redirect(`${homePath}&ref=${userRefCode}`)
    } else {
      // User is logged in attempting to reach another page - allow
      // TODO: strip the existing refcode out of here, replacing with
    }
  } catch (error) {
    // Amplify Library does not have a user signed in on this device/browser
    // which throws an error and the user ends up here, storing the error for some reason
    if (stage === "dev") {
      console.log("amplify authGuard error: ", error)
    }
    store.commit("setAuthError", {
      loc: "authGuard middleware",
      error,
    })
    store.commit("updateCurrentUser", { user: null, from: "authguard signout" })

    // TIKR has 3 default inviteCodes depending how user arrived at app.tikr
    const defaultInviteCodes = ["organic", "organics", "price21"]

    // Capture any refCode in vuex
    const queryInviteCode = queryParams.ref

    if (queryInviteCode && !defaultInviteCodes.includes(queryInviteCode)) {
      // if the ?ref inviteCode is not a default code make sure to
      // save it in the store for correct user creation attribution
      if (queryInviteCode) {
        store.commit("updateStore", {
          property: "inviteCode",
          value: queryInviteCode,
        })
      }
    } else if (
      defaultInviteCodes.includes(store.state.inviteCode) &&
      store.state.analytics.utm_source
    ) {
      // If the app has captured a utm_source param AND current saved inviteCode
      //  is default code.. change the captured inviteCode to the utm_source
      store.commit("updateStore", {
        property: "inviteCode",
        value: store.state.analytics.utm_source,
      })
    } else if (
      defaultInviteCodes.includes(store.state.inviteCode) &&
      from.path &&
      from.path === "/pricing"
    ) {
      // if the inviteCode is a default inviteCode but there is no utm_source
      // captured make sure to set the code to price21 if the user is coming from the
      // pricing page
      store.commit("updateStore", {
        property: "inviteCode",
        value: "price21",
      })
    }

    const isAllowedPath = allowedPaths.includes(route.path)

    if (isAllowedPath || loginPaths.includes(route.path)) {
      // any user can view the login page, end middleware function
      // represented in the allowed path array, what is the O of includes...
    } else {
      // user is not authenticated and is trying to go to a page which
      // requires authentication, store the desiredPath to the store
      if (route.path.includes("stock") || route.path.includes("investor")) {
        store.commit("updateStore", {
          property: "homePath",
          value: route.fullPath,
        })
      }
      // No authentication - redirect to allowed page
      // TODO: save the desired navigation route to the store
      // utilize this desired nav route as the destination
      // redirect(registrationPath(store.state.inviteCode))

      redirect(
        loginPath(store.state.inviteCode, encodeURIComponent(route.fullPath))
      )
    }
  }
}
