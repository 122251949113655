export default function ({ params, redirect, store }) {
  // If its not a valid UUID, redirect to the models page
  if (params.modelId.length !== 36) {
    const idToken = store.state.user.signInUserSession.idToken
    const refCode = idToken?.payload["custom:refCode"]
    if (refCode) {
      return redirect(`/models?ref=${refCode}`)
    }
    return redirect("/models")
  }
}
