import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.from.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.function.name.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.entries.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.regexp.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.starts-with.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
function _slicedToArray(r, e) {
  return _arrayWithHoles(r) || _iterableToArrayLimit(r, e) || _unsupportedIterableToArray(r, e) || _nonIterableRest();
}
function _nonIterableRest() {
  throw new TypeError("Invalid attempt to destructure non-iterable instance.\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _unsupportedIterableToArray(r, a) {
  if (r) {
    if ("string" == typeof r) return _arrayLikeToArray(r, a);
    var t = {}.toString.call(r).slice(8, -1);
    return "Object" === t && r.constructor && (t = r.constructor.name), "Map" === t || "Set" === t ? Array.from(r) : "Arguments" === t || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(t) ? _arrayLikeToArray(r, a) : void 0;
  }
}
function _arrayLikeToArray(r, a) {
  (null == a || a > r.length) && (a = r.length);
  for (var e = 0, n = Array(a); e < a; e++) n[e] = r[e];
  return n;
}
function _iterableToArrayLimit(r, l) {
  var t = null == r ? null : "undefined" != typeof Symbol && r[Symbol.iterator] || r["@@iterator"];
  if (null != t) {
    var e,
      n,
      i,
      u,
      a = [],
      f = !0,
      o = !1;
    try {
      if (i = (t = t.call(r)).next, 0 === l) {
        if (Object(t) !== t) return;
        f = !1;
      } else for (; !(f = (e = i.call(t)).done) && (a.push(e.value), a.length !== l); f = !0);
    } catch (r) {
      o = !0, n = r;
    } finally {
      try {
        if (!f && null != t["return"] && (u = t["return"](), Object(u) !== u)) return;
      } finally {
        if (o) throw n;
      }
    }
    return a;
  }
}
function _arrayWithHoles(r) {
  if (Array.isArray(r)) return r;
}
import Vue from 'vue';
import yn from 'yn';
var parseQueryToggles = function parseQueryToggles() {
  var queryObject = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {};
  var queryStringPrefix = arguments.length > 1 && arguments[1] !== undefined ? arguments[1] : '';
  return Object.entries(queryObject).filter(function (_ref) {
    var _ref2 = _slicedToArray(_ref, 1),
      key = _ref2[0];
    return key.startsWith("".concat(queryStringPrefix, "_"));
  }).reduce(function (toggles, _ref3) {
    var _ref4 = _slicedToArray(_ref3, 2),
      key = _ref4[0],
      value = _ref4[1];
    return _objectSpread(_objectSpread({}, toggles), {}, _defineProperty({}, key.replace("".concat(queryStringPrefix, "_"), ''), value));
  }, {});
};
var parseYn = function parseYn(t) {
  return Object.entries(t).reduce(function (acc, _ref5) {
    var _yn;
    var _ref6 = _slicedToArray(_ref5, 2),
      key = _ref6[0],
      value = _ref6[1];
    acc[key] = (_yn = yn(value)) !== null && _yn !== void 0 ? _yn : value;
    return acc;
  }, {});
};
export default (function (ctx, inject) {
  var $config = ctx.$config,
    route = ctx.route,
    router = ctx.app.router;
  var _JSON$parse = JSON.parse('{"toggles":{"google-sign-in":true,"due-diligence":true,"pe-valuation-model":true,"morningstar-ltm":true,"datasource-query-params":true,"algolia-company-icons":true,"use-cdk-user-data":true,"use-cdk-screener":true,"use-cdk-ciq":true,"use-cdk-trkd":true,"morningstar-excel-export":true,"show-morningstar-financials":true,"beats-and-misses":true,"filter-labels":true,"earnings-trends":true,"use-worker-fmp-financials":true,"use-worker-ciq-financials":true,"use-worker-ciq-valuation":true,"use-worker-ciq-estimates":true,"use-worker-ciq-actuals":true,"use-onboarding-flow":true,"show-price-factors":true,"show-sale-countdown":false,"dd-session-storage":true},"queryString":true,"queryStringPrefix":"toggle"}'),
    _toggles = _JSON$parse.toggles,
    _queryString = _JSON$parse.queryString,
    queryStringPrefix = _JSON$parse.queryStringPrefix;
  var runtimeConfig = $config && $config.featureToggle || {};
  var queryString = typeof runtimeConfig.queryString !== 'undefined' ? runtimeConfig.queryString : _queryString;
  var queryToggles = {};
  if (queryString) {
    if (router && route) {
      queryToggles = parseQueryToggles(route.query, queryStringPrefix);
      router.afterEach(function (to) {
        featureToggle.toggles = parseYn(_objectSpread(_objectSpread(_objectSpread({}, _toggles), runtimeConfig.toggles || {}), parseQueryToggles(to.query, queryStringPrefix)));
      });
    }
  }
  var toggles = _objectSpread(_objectSpread(_objectSpread({}, _toggles), runtimeConfig.toggles || {}), queryToggles);
  Vue.component('feature-toggle', function () {
    return import('./feature-toggle.vue');
  });
  var featureToggle = {
    toggles: parseYn(toggles),
    queryString: queryString,
    queryStringPrefix: queryStringPrefix,
    isQueryStringAllowed: function isQueryStringAllowed(fn) {
      featureToggle.isQueryStringAllowedFn = fn;
    }
  };
  ctx.$featureToggle = featureToggle;
  inject('featureToggle', featureToggle);
});