import "core-js/modules/es.array.includes.js";
import "core-js/modules/es.array.join.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.regexp.exec.js";
import "core-js/modules/es.string.replace.js";
import "core-js/modules/es.string.split.js";
import "core-js/modules/es.string.trim.js";
import "core-js/modules/es.string.sub.js";
import "core-js/modules/web.dom-collections.for-each.js";
import { useStorage } from "@vueuse/core";
var lambdaStage = process.env.LAMBDA_STAGE;
var devStage = "dev";
var baseUtils = function baseUtils() {
  var _useNuxtApp = useNuxtApp(),
    $Amplify = _useNuxtApp.$Amplify,
    $store = _useNuxtApp.$store,
    $vuetify = _useNuxtApp.$vuetify;
  var stage = computed(function () {
    return lambdaStage;
  });
  var dev = computed(isDev);
  var hasOwnProperty = function hasOwnProperty(obj, key) {
    return Object.hasOwn(obj, key);
  }; /** Auth Computed Properties Below */
  var idtoken = computed(function () {
    var userAccount = $store.state.user || {
      attributes: {},
      signInUserSession: {}
    };
    var userSession = userAccount.signInUserSession || {
      accessToken: {},
      idToken: {}
    };
    var idToken = userSession.idToken || {
      payload: {}
    };
    return idToken;
  });
  var isDrawerHidden = computed(function () {
    return $vuetify.breakpoint.width < 825;
  });
  var refCode = computed(function () {
    return idtoken.value.payload["custom:refCode"];
  });
  var restrictResponse = computed(function () {
    return $store.state.restrictResponse;
  }); // is this the correct claim to pull the sub... off of the idtoken?
  var userId = computed(function () {
    var sub = idtoken.value.payload.sub;
    return sub;
  });
  var customGroup = computed(function () {
    var group = idtoken.value.payload["custom:group"];
    if (restrictResponse.value) {
      return group;
    } else {
      return "2022_v0-free"; // fixme: 2021_v0-transition
    }
  });
  var mainTier = computed(function () {
    if (restrictResponse.value) {
      var splitGroup = customGroup.value ? customGroup.value.split("-") : ["", "free"];
      var _mainTier = splitGroup[1];
      return _mainTier;
    } else {
      return "free";
    }
  });
  var payingCustomer = computed(function () {
    return mainTier.value === "global" || mainTier.value === "plus" || mainTier.value === "pro";
  });
  var freeTier = computed(function () {
    return mainTier.value === "free";
  });
  var plusTier = computed(function () {
    return mainTier.value === "plus";
  });
  var proTier = computed(function () {
    return mainTier.value === "global";
  });
  var numWatchlists = computed(function () {
    var numObj = {
      free: -1,
      country: 10,
      region: -1,
      transition: -1,
      plus: -1,
      pro: -1,
      global: -1
    };
    return numObj[mainTier.value];
  });
  var numScreeners = computed(function () {
    var numObj = {
      free: 1,
      country: 3,
      region: 5,
      transition: -1,
      plus: 5,
      pro: -1,
      global: -1
    };
    return numObj[mainTier.value];
  });
  var I18nFn = function I18nFn(text) {
    return $Amplify.I18n.get(text);
  };
  var darkActive = computed(function () {
    return !!$store.state.config.darkMode;
  }); /**
      * Function to toggle between light and dark modes.
      *
      * @param {any} lightModeValue - The value to be returned when light mode is active.
      * @param {any} darkModeValue - The value to be returned when dark mode is active.
      * @returns {any} - Returns the `darkModeValue` if dark mode is currently active, otherwise returns the `lightModeValue`.
      */
  var toggleLightDarkMode = function toggleLightDarkMode(lightModeValue, darkModeValue) {
    return darkActive.value ? darkModeValue : lightModeValue;
  };
  var freezeFirstColumn = useStorage("tikrFreezeFirstColumn", true);
  var tenYearCutoff = function tenYearCutoff() {
    var year = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2023;
    return year - 10;
  };
  var threeYearCutoff = function threeYearCutoff() {
    var year = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : 2023;
    return year - 3;
  };
  var specialUser = computed(function () {
    return customGroup.value === "2021_v0-global";
  });
  var ultimateUser = computed(function () {
    return ["2024_v0-ultimate"].includes(customGroup.value);
  }); // const onlyShowOrigEstMetrics = computed(() => false || freeTier.value)
  var onlyShowOrigEstMetrics = computed(function () {
    return false;
  });
  var moneybackGuarantee = computed(function () {
    return true;
  });
  var syncStoreWithLocalStorageBoolean = function syncStoreWithLocalStorageBoolean(_ref) {
    var vuexKey = _ref.vuexKey,
      localStorageKey = _ref.localStorageKey,
      _ref$storeModule = _ref.storeModule,
      storeModule = _ref$storeModule === void 0 ? "config" : _ref$storeModule;
    var localStorageBoolean = localStorage.getItem(localStorageKey); // remember these are strings
    var desiredBoolFromLocalStorage = localStorageBoolean === "true";
    if ($store.state[storeModule][vuexKey] !== desiredBoolFromLocalStorage) {
      $store.dispatch("".concat(storeModule, "/changeLocalStorage"), {
        vuexKey: vuexKey,
        localStorageKey: localStorageKey,
        value: desiredBoolFromLocalStorage
      });
    }
  };
  var buildGtmEventFromCognito = function buildGtmEventFromCognito(eventName, cognitoDataResponse) {
    var gtmEventObj = {
      event: eventName,
      refCode: cognitoDataResponse.attributes["custom:refCode"],
      userId: cognitoDataResponse.attributes.sub,
      subscriptionString: cognitoDataResponse.attributes.customGroup
    };
    try {
      $store.state.analytics.cognitoMarketingQueryParams.forEach(function (param) {
        var utmParamValue = cognitoDataResponse.attributes["custom:".concat(param)];
        if (utmParamValue) {
          gtmEventObj["signup_".concat(param)] = utmParamValue;
        }
      });
      $store.state.analytics.marketingQueryParams.forEach(function (param) {
        var marketingParamValue = $store.state.analytics[param];
        if (marketingParamValue) {
          gtmEventObj["authsession_".concat(param)] = marketingParamValue;
        }
      });
    } catch (error) {
      console.log("error building gtm obj from cognitoRes", error);
    }
    return gtmEventObj;
  };
  var defaultYear = computed(function () {
    return $store.state.config.allPeriodsDefault;
  });
  var stylesDiligenceScore = computed(function () {
    return {
      0: {
        label: "Bearish",
        color: toggleLightDarkMode("#4B2020", "#CE6E66"),
        background: toggleLightDarkMode("#FFCBCB", "#362B2C"),
        border: "1px solid ".concat(toggleLightDarkMode("#D48E8E", "#78403B"))
      },
      1: {
        label: "Neutral",
        color: toggleLightDarkMode("#634B34", "#C6B776"),
        background: toggleLightDarkMode("#F6F0BC", "#36342A"),
        border: "1px solid ".concat(toggleLightDarkMode("#F0E368", "#786D08"))
      },
      2: {
        label: "Bullish",
        color: toggleLightDarkMode("#23372A", "#76BA59"),
        background: toggleLightDarkMode("#CCE1BB", "#2C3526"),
        border: "1px solid ".concat(toggleLightDarkMode("#769A7F", "#4C7839"))
      }
    };
  });
  var slugify = function slugify(str) {
    return str.toLowerCase().replace(/[^a-z0-9]+/g, "-").replace(/^-|-$/g, "");
  };
  var toCamelCase = function toCamelCase(str) {
    var slugified = slugify(str);
    return slugified.replace(/[-_](.)/g, function (_, _char) {
      return _char.toUpperCase();
    });
  };
  var toPascalCase = function toPascalCase(str) {
    str = str.trim();
    var words = str.split(/\s+|_/);
    return words.map(function (word) {
      return word.charAt(0).toUpperCase() + word.slice(1);
    }).join("");
  };
  var sectionWrapperStyle = {
    border: "1px solid var(--section-border-color)",
    background: "var(--section-bg)"
  };
  return {
    stage: stage,
    dev: dev,
    hasOwnProperty: hasOwnProperty,
    idtoken: idtoken,
    userId: userId,
    refCode: refCode,
    restrictResponse: restrictResponse,
    I18nFn: I18nFn,
    customGroup: customGroup,
    mainTier: mainTier,
    freeTier: freeTier,
    plusTier: plusTier,
    proTier: proTier,
    numWatchlists: numWatchlists,
    numScreeners: numScreeners,
    darkActive: darkActive,
    freezeFirstColumn: freezeFirstColumn,
    payingCustomer: payingCustomer,
    tenYearCutoff: tenYearCutoff,
    threeYearCutoff: threeYearCutoff,
    onlyShowOrigEstMetrics: onlyShowOrigEstMetrics,
    syncStoreWithLocalStorageBoolean: syncStoreWithLocalStorageBoolean,
    moneybackGuarantee: moneybackGuarantee,
    buildGtmEventFromCognito: buildGtmEventFromCognito,
    defaultYear: defaultYear,
    toCamelCase: toCamelCase,
    toPascalCase: toPascalCase,
    sectionWrapperStyle: sectionWrapperStyle,
    isDrawerHidden: isDrawerHidden,
    stylesDiligenceScore: stylesDiligenceScore,
    toggleLightDarkMode: toggleLightDarkMode,
    specialUser: specialUser,
    ultimateUser: ultimateUser
  };
};
export default baseUtils;
export var isDev = function isDev() {
  return lambdaStage === devStage;
};