/**
 * Utility Functions that transform screener response to
 * format the frontend requires
 */
import {
  generateOnePeriodNameString,
  generateTwoPeriodNameString,
} from "./screenerFormatUtils"

const createScreenerColumns = (req, data, screenItemMap, I18nFn) => {
  // TODO: switch from req to the sqlMetaObj... this is a good idea
  const newColumns = req.map((m, idx) => {
    // const [condKeyName, uniqueCondStr] = m
    const uniqueCondStr = m[1]
    const meta = data.sqlMetaObj.condArr[idx][2] // this is so ugly
    let value = meta.dataId
    // let value = data.fields.findIndex((e) => e.name === dataId)
    const splitUniqueConditions = uniqueCondStr.split("_")
    // determine what the value is
    // metaObj[condKeyName] .
    // const colName = data.sqlMetaObj[condKeyName] // first iteration
    const histPriceKeys = ["pricechange", "price"]
    const condType = splitUniqueConditions[1]

    // TODO: determine the type of column for appropriate formatting
    // FOR NOW ONLY EST ARE RETURNED HERE
    if (histPriceKeys.includes(condType)) {
      const periodType = splitUniqueConditions[2]
      const columntype = condType === "pricechange" ? "pct" : "float"
      const colValue = `${condType}_${periodType}`
      const colText = screenItemMap[colValue]
      return {
        text: colText
          ? colText.abbr
            ? colText.abbr
            : colText.text
          : `${condType} ${periodType}`,
        // value: `${colName}_v`,
        value,
        columntype,
      }
    }

    if (condType === "company") {
      // const textCols = ["isgroup", "isnotgroup", "is", "isnot"]
      const stringColumns = [
        "ticker",
        "currencyid",
        "exchangename",
        "exchangesymbol",
        "securityfeaturename",
        "country",
        "isocountry2",
        "isocountry3",
        "city",
        "simpleindustryid",
        "yearfounded",
        "companycountryid",
        "incorprationcountryid",
        "region",
        "exchangesymbol",
        "sector",
      ]
      const floatCols = ["usdc"]
      const countryCols = ["country", "companycountryid", "countryid"]
      let columntype = stringColumns.includes(meta.column)
        ? "str"
        : floatCols.includes(value)
        ? "float"
        : "mc"
      if (meta.column === "simpleindustryid") {
        columntype = "industry"
      } else if (countryCols.includes(meta.column)) {
        columntype = "country"
      } else if (meta.column === "sector") {
        columntype = "sector"
        value = "simpleindustryid" // FIXME: how are you going to handle this?
        // duplicate valueid problem
        // dataId = "simpleindustryid"
        // value = data.fields.findIndex((e) => e.name === dataId)
      }
      const colText = screenItemMap[meta.column]
      if (meta.column === "companycountryid") {
        // FIXME: trying to redirect companycountryid to the companyid field..
        // need to make sure to update screener backend before deploying
        // so that countryid is returned.
        value = "countryid"
      }
      return {
        text: colText?.text ? colText.text : `${meta.column}`,
        // value: `${colName}_v`,
        value,
        columntype,
      }
    }

    // FIXME: add else block make it specific to come into this code

    const dataTypeKey = splitUniqueConditions[1]
    const dataType = splitUniqueConditions[2]
    const didType = splitUniqueConditions[3]
    const valueType = splitUniqueConditions[4]
    const periodType = splitUniqueConditions[5]
    const firstPeriodValue = splitUniqueConditions[6]
    const secondPeriodValue = splitUniqueConditions[7]
    const conditionUnits = splitUniqueConditions[10]

    let nameStr = generateOnePeriodNameString(
      screenItemMap,
      I18nFn,
      didType,
      `${dataTypeKey}_${dataType}`,
      periodType,
      firstPeriodValue
    )

    if (conditionUnits === "x") {
      return {
        text: nameStr,
        // value: `${colName}_v`,
        value,
        columntype: "turns",
      }
    }

    if (valueType === "yoyChange" || valueType === "margins") {
      const nameStr = generateOnePeriodNameString(
        screenItemMap,
        I18nFn,
        didType,
        `${dataTypeKey}_${dataType}`,
        periodType,
        firstPeriodValue,
        valueType
      )
      return {
        text: nameStr,
        // value: `${colName}_v`,
        value,
        columntype: "pct",
      }
    }
    if (valueType === "cagr") {
      nameStr = generateTwoPeriodNameString(
        screenItemMap,
        I18nFn,
        didType,
        `${dataTypeKey}_${dataType}`,
        periodType,
        valueType,
        firstPeriodValue,
        secondPeriodValue
      )
      return {
        text: nameStr,
        //  value: `${colName}_v`,
        value,
        columntype: "pct",
      }
    }
    if (conditionUnits === "%") {
      return {
        text: nameStr,
        // value: `${colName}_v`,
        value,
        columntype: "whole_pct", // WIP: jesus dude
      }
    }

    if (valueType === "v") {
      if (didType === "numEst") {
        return {
          text: nameStr,
          // value: `${colName}_usdv`,
          value,
          columntype: "int",
        }
      } else if (conditionUnits === "MM USD") {
        return {
          text: nameStr,
          // value: `${colName}_usdv`,
          value,
          columntype: "mc", // FIXME: should this be floatint?... doesn't look like that is an option in the resTable
        }
      } else {
        return {
          text: nameStr,
          // value: `${colName}_usdv`,
          value,
          columntype: "float",
        }
      }
    } else {
      const nameStr = `${didType} ${dataType} ${valueType} ${periodType}${firstPeriodValue}`
      return {
        text: nameStr,
        // value: `${colName}_v`,
        value,
        columntype: "pct",
      }
    }
  })
  const colValueSet = new Set()
  const dedupeColumns = newColumns.filter((f) => {
    if (colValueSet.has(f.text)) {
      return false
    } else {
      colValueSet.add(f.text)
      return true
    }
  })
  const companyIdColumns = [
    {
      text: "",
      value: "fav",
      columntype: "fav",
      width: "20px",
      align: "left",
      sortable: false,
    },
    {
      text: I18nFn("Ticker"),
      value: "ticker",
      columntype: "ts",
      align: "center",
    },
    {
      text: I18nFn("Company Name"),
      value: "companyname",
      columntype: "name",
      align: "center",
    },
  ]
  return companyIdColumns.concat(dedupeColumns)
}

export { createScreenerColumns }
