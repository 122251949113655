import ciqCurrencyMap from "../templates/ciqcurrency.json"
import { isCacheValid, priceScheduleArr } from "./../tools"

const transformWatchlistResponse = (data, cidTidArray) => {
  const allTid = cidTidArray.map((t) => t[1])
  const cidToTid = cidTidArray.reduce((acc, arr) => {
    const cid = arr[0]
    const tid = arr[1]
    if (acc[cid]) {
      acc[cid].push(tid)
    } else {
      acc[cid] = [tid]
    }
    return acc
  }, {})
  if (data && data.rowCount > 0 && data.price) {
    const parseTime = new Date()
    const prevPrice = data.prevPrice.reduce((acc, d) => {
      try {
        // const qpc = d.qpc
        // const a = d.a
        // const c = d.c
        // const mcpc = d.mcpc
        // const tev = d.tev
        const cObj = {
          est: true,
          curr: d.qcurr, // string, name of currency
          d: d.d,
          // erc: d.erc, // always 500
          iso: d.qiso, // string, ISOCODE of currency
          pc: parseFloat(d.qpc), // forex conversion to USD
          s: 3, // estimateScaleid, its a number
          sf: parseFloat(d.a), // the splitfactor... not sure if this is needed
          v: parseFloat(d.c), // actual datavalue
        }
        const tevObj = {
          est: true,
          curr: d.mccurr, // string, name of currency
          d: d.mcd,
          iso: d.mciso, // string, ISOCODE of currency
          pc: parseFloat(d.mcpc), // forex conversion to USD
          s: 0, // estimateScaleid, its a number
          sf: 1, // the splitfactor... not sure if this is needed
          v: parseFloat(d.tev), // actual datavalue
        }
        const mcObj = {
          est: true,
          curr: d.mccurr, // string, name of currency
          d: d.mcd,
          iso: d.mciso, // string, ISOCODE of currency
          pc: parseFloat(d.mcpc), // forex conversion to USD
          s: 0, // estimateScaleid, its a number
          sf: 1, // the splitfactor... not sure if this is needed
          v: parseFloat(d.mc), // actual datavalue
        }
        acc[d.ptid.toString()] = {
          ...d,
          cObj,
          tevObj,
          mcObj,
        }
        return acc
      } catch (error) {
        console.error("watchlist prevPrice error: ", error)
        console.error("with obj", d)
        return acc
      }
    }, {})

    const price = data.price.reduce((acc, d) => {
      try {
        const lastClose = prevPrice[d.ptid.toString()]
        d.o = lastClose ? lastClose.c : undefined
        // d.o = prevPrice[d.ptid.toString()].c
        const cObj = {
          est: true,
          curr: d.qcurr, // string, name of currency
          d: d.d,
          // erc: d.erc, // always 500
          iso: d.qiso, // string, ISOCODE of currency
          pc: parseFloat(d.qpc), // forex conversion to USD
          s: 3, // estimateScaleid, its a number
          sf: parseFloat(d.a), // the splitfactor... not sure if this is needed
          v: parseFloat(d.c), // actual datavalue
        }
        const tevObj = {
          est: true,
          curr: d.mccurr, // string, name of currency
          d: d.mcd,
          iso: d.mciso, // string, ISOCODE of currency
          pc: parseFloat(d.mcpc), // forex conversion to USD
          s: 0, // estimateScaleid, its a number
          sf: 1, // the splitfactor... not sure if this is needed
          v: parseFloat(d.tev), // actual datavalue
        }
        const mcObj = {
          est: true,
          curr: d.mccurr, // string, name of currency
          d: d.mcd,
          iso: d.mciso, // string, ISOCODE of currency
          pc: parseFloat(d.mcpc), // forex conversion to USD
          s: 0, // estimateScaleid, its a number
          sf: 1, // the splitfactor... not sure if this is needed
          v: parseFloat(d.mc), // actual datavalue
        }
        acc[d.ptid.toString()] = {
          ...d,
          cObj,
          tevObj,
          mcObj,
          f: parseTime,
        }
        return acc
      } catch (error) {
        console.error("watchlist price error: ", error)
        console.error("with object: ", d)
        return acc
      }
    }, {})

    allTid.forEach((tid) => {
      if (!price[tid]) {
        price[tid] = {
          f: parseTime,
        }
      }
    })

    // TODO: add to price Object the tid's that did not return any data
    // because you really ought to stop fetching these.

    // check reduction - starts with price object
    const withNtm = data.ntm.reduce((acc, d) => {
      try {
        const tid = d.tid
        const did = d.did
        const dataObj = {}
        const objToSave = {
          est: true,
          curr: d.curr, // string, name of currency
          d: d.d, // maybe I do want to incorporate the effectivedate
          // erc: d.erc, // always 500
          iso: d.iso, // string, ISOCODE of currency
          pc: parseFloat(d.pc), // forex conversion to USD
          s: d.s, // estimateScaleid, its a number
          sf: parseFloat(d.sf), // the splitfactor... not sure if this is needed
          v: parseFloat(d.v), // actual datavalue
        }
        if (d.unauth) {
          objToSave.unauth = d.unauth
        }
        dataObj[did] = objToSave
        // acc[tid] should have the price spread over it
        acc[tid] = Object.assign({}, acc[tid], dataObj)
        return acc
      } catch (error) {
        console.error("watchlist ntm error: ", error)
        console.error("with object: ", d)
        return acc
      }
    }, price)

    // check reduction - starts with withNtm object which was built ontop of price
    const withStreet = data.street.reduce((acc, d) => {
      try {
        const tid = d.tid
        const did = d.did
        const dataObj = {}
        const objToSave = {
          est: true, // true for estimate meaning s has different meaning from financials
          curr: d.curr, // name of currency
          d: d.d, // maybe I do want to incorporate the effectiveDate here..
          // erc: d.erc, // undefined
          iso: d.iso, // string, ISOCODE of currency
          pc: parseFloat(d.pc),
          s: d.s, // estimateScaleID, it is a number anyways
          sf: parseFloat(d.sf), // splitfactor
          v: parseFloat(d.v), // actual data value
        }
        if (d.unauth) {
          objToSave.unauth = d.unauth
        }
        dataObj[did] = objToSave
        // acc[tid] should have the price spread over it
        acc[tid] = Object.assign({}, acc[tid], dataObj)
        return acc
      } catch (error) {
        console.error("watchlist ntm error: ", error)
        console.error("with object: ", d)
        return acc
      }
    }, withNtm)

    // transform the LTM data into an object that can be used with how the watchlist is laid out.
    const ciq = data.ltm.reduce((acc, d) => {
      try {
        const cid = d[0]
        // I need to figure out the appropriate tids for each cid that is returned.
        // as the ltm data is by cid instead of tid. jesus
        const did = d[4]
        const dataObj = {}
        // TODO: check d[7] which is the nmflag, if 1 the data is not meaningful
        dataObj[did] = {
          est: false, // true for estimate meaning s has different meaning from financials
          curr: ciqCurrencyMap[d[1]].name, // name of currency TODO: create the map here
          d: d[3], // maybe I do want to incorporate the effectiveDate here.. think this is a financialperiodid
          // erc: d.erc, // undefined
          iso: ciqCurrencyMap[d[1]].iso, // string, ISOCODE of currency TODO: create map here
          pc: parseFloat(d[2]),
          s: d[6], // unittypeid, it is a number anyways TODO: figure out which is the financialScaleId
          sf: 1, // parseFloat(d.sf), // splitfactor
          v: parseFloat(d[5]), // actual data value
        }
        // ltm data comes back by cid - Must map it to Tid using the cidToTid Object
        cidToTid[cid].forEach((tid) => {
          acc[tid] = Object.assign({}, acc[tid], dataObj)
        })
        // acc[tid] should have the price spread over it
        return acc
      } catch (error) {
        console.error("watchlist ntm error: ", error)
        console.error("with object: ", d)
        return acc
      }
    }, withStreet)

    return ciq
  }
}

const tickersToFetch = (wholeList = [], existingState = {}) => {
  const now = new Date()
  const toDelete = []
  const toFetch = wholeList.filter((f) => {
    // f = [cid, tid]
    const tid = f[1]
    // previous data exists for this tid && tid has a fetchTime .f property
    const oldData = existingState[tid]
    if (oldData?.f) {
      if (isCacheValid(oldData.f, now, priceScheduleArr)) {
        return false
      }
      toDelete.push(tid)
    }
    return true
  })
  return [toFetch, toDelete]
}

export { transformWatchlistResponse, tickersToFetch }
