import { utcParse } from "d3-time-format"
import { calcEstDerivative, calcEstDiv } from "./estimateMath"
import { findMetricKey } from "./common"

const parseTime = utcParse("%Y-%m-%dT%H:%M:%S.%LZ")

const mapEstimates = function (estimatesLineItems, resData, transformedDates) {
  // remember estimateTemplate is [{keys: [], statement: "str"}]
  return estimatesLineItems.map((statement) => {
    // mapping over each statement contained in estimatesLineItems
    return statement.keys.reduce((acc, lineitem, idx) => {
      // iterating over each lineitem inside of a statement template
      // length of accumulator will be the index value of the lineitem
      // in the event the lineitem is pushed into the accumulator
      const length = acc.length
      if (["val", "turns", "pct"].includes(lineitem.formula)) {
        // check to see if the lineitem exists in the returned data resData
        const metricKey = findMetricKey(lineitem.dataitemid)
        if (resData[metricKey]) {
          // so the question here... is statement a reference? Are we manipulating
          // the underlying? I have a feeling it is being passed by reference
          const existingLineItem = statement.keys[idx] // this is a reference to the lineitem we're currently on

          // because the dataitem exists in the response add to the estimatesLineItem template
          // metadata that will allow us when iterating over this array later that the
          // lineitem has actual data associated with it
          statement.keys[idx] = { ...existingLineItem, actualPosition: length }
          // Merge the available dataitemvalue objects (by estimateperiod id) with the lineitem
          // and push into the accumulator
          const { name, format, formula, tikrdisplay } = lineitem
          Object.assign(resData[metricKey], {
            name,
            format,
            formula,
            tikrdisplay,
          })
          acc.push(Object.assign({}, resData[metricKey], lineitem))
        }
        // return the accumulator terminating the loop
        return acc
      }

      if (lineitem.formula === "h3") {
        // this is a header, include it, no need to merge with any data
        // do we need to perform this manipulation of the estimateTemplate?
        // it will always be there...
        const existingLineItem = statement.keys[idx] // this is a reference to the lineitem we're currently on
        statement.keys[idx] = {
          ...existingLineItem,
          actualPosition: length,
        }
        acc.push(lineitem)
        return acc
      }

      if (lineitem.formula === "dxdt") {
        // this is a period on period calculation, thus dataitemid is an array
        // need to look at the first position in dataitemid[0]
        const metricKey = findMetricKey(lineitem.dataitemid[0])
        if (resData[metricKey]) {
          // dataitemid was returned in the response
          // modify the lineitem template to signal that this row was been included in the accumulator
          const existingLineItem = statement.keys[idx]
          statement.keys[idx] = {
            ...existingLineItem,
            actualPosition: length,
          }

          // what are we expecting derivative to be... an object associating estimatePeriodIds
          // to dataitemvalue objects where the dataitemvalue is the calculated derivative
          // this is why the function needs... the lineitem
          // is this function making manipulations to the lineitem?
          const derivative = calcEstDerivative(
            metricKey,
            resData,
            transformedDates,
            "actual"
          )
          // these were my outside of vuex modification issues lineitem was the target
          const addCalc = {}
          addCalc.actual = derivative
          // {...lineItemMetaData, {actual: {periodId: {dataObj} } } }
          acc.push(Object.assign({}, lineitem, addCalc))
        }
        return acc
      }
      // A calculation is occuring FIXME: change if condition if you're ever doing more than just 2 things
      // right now there are only two numbers being passed because we're only doing division
      // a list comprehenshion would be great right here.. not quite sure how to check that for javascript
      // ASSUMING THE ONLY POSSIBILITY LEFT IS A CALCULATION SO NOT CHECKING lineitem.formula
      if (lineitem.formula === "div") {
        const numeratorKey = findMetricKey(lineitem.dataitemid[0])
        const denominatorKey = findMetricKey(lineitem.dataitemid[1])
        if (resData[numeratorKey] && resData[denominatorKey]) {
          // modify lineitem to alert next process it is included
          // so what are you going to do when there is actuals data but no estimate?
          // that is a good question
          const existingLineItem = statement.keys[idx]
          statement.keys[idx] = {
            ...existingLineItem,
            actualPosition: length,
          }
          const margin = calcEstDiv(
            numeratorKey,
            denominatorKey,
            resData,
            transformedDates,
            "actual"
          )
          // these were my outside of vuex modification issues lineitem was the target
          const addCalc = {}
          addCalc.actual = margin
          acc.push(Object.assign({}, lineitem, addCalc))
          return acc
        }
      }

      return acc
    }, [])
  })
}

const mapActualsDataItemID = function (actuals, consensusToPeriod) {
  // create a object/dictionary mapping dataitemid's in the response (Object[] from res.actuals)
  // to the estimatePeriodId. dataitemvalues are 1:1 associated with estimateConsensusId's, thus
  // the consensusToPeriod dictionary is needed to map them accordingly
  // {"dataitemid": {"consensusestimateid": {dataitemvalue object}}}
  // this is where I am wrong -> because this is by dataitemid, we don't need to differentiate
  // even though there are different dataitemids those different dataitemids
  // will end up on the same lineitem object
  // and I want to just look at the lineitem[dataitemid][periodEst ? actual: mean ? mean : median]
  // especially because I'm spreading these {estimateperiod: {datavalObj}} onto the lineitem
  const addScale = (acc, item) => {
    // acc is an object where we will store the count
    if (acc[item.estimatescaleid]) {
      ++acc[item.estimatescaleid]
    } else {
      acc[item.estimatescaleid] = 1
    }
  }

  const isoCounter = {}
  const resData = actuals.reduce((acc, item) => {
    // count the number of currencies in this response
    // FIXME: parse dataitemvalue to a float & fix a bunch of downstream shit
    if (item.dataitemvalue === "0.000000000") {
      return acc
    }
    const currency = item.isocode
    if (currency && isoCounter[currency]) {
      // increase the count!
      ++isoCounter[currency].count
      addScale(isoCounter[currency], item)
    } else {
      // this currency isn't on the counter yet, initalize
      const newCurr = { name: item.currencyname, code: item.isocode, count: 1 }
      addScale(newCurr, item)
      isoCounter[currency] = newCurr
    }

    const estimateperiod = consensusToPeriod[item.estimateconsensusid]
    const dateKey = estimateperiod.value
    const metricKey = findMetricKey(item.dataitemid)
    item.timeVal = Date.parse(estimateperiod.periodenddate)
    if (acc[metricKey]) {
      // dataitemid exists on the accumulator
      // as this is only for actuals, the actual key exists on acc[item.dataitem]
      // FIXME: this is where I could standardize to estimates
      acc[metricKey].actual[dateKey] = item
    } else {
      // dataitemid doesn't exist on accumulator
      // create an object which will hold the {estimatePeriod: dataitemvalue Object} relationship
      const newObj = {}
      newObj[dateKey] = item

      // create an item to hold the {"actual": {estimatePeriod: dataValueObject}}
      const dataitemidObj = {}
      dataitemidObj.actual = newObj
      // Store the {actual: {firstEstimatePeriod: {dataValueObject}}} object
      // on the item.dataitemid key on the accumulator, first time adding this dataitemid to accumulator
      // FIXME: this is where I could standardize to estimates
      acc[metricKey] = dataitemidObj
    }
    return acc
  }, {})
  return { resData, isoCounter }
}

const mapConsensusToPeriod = (dates) => {
  // create an object/dictionary to associate estimateconsensusid to estimateperiodid
  // because estimateperiodid will be used as the time domain for display
  return dates.reduce((acc, row) => {
    const estPeriodId = row.periodtypeid
    if (estPeriodId === 1) {
      row.value = `${row.calendaryear}##FY` // should this be row.fiscalyear?

      // row.value = `${row.calendaryear}##${row.calendarquarter}`
    } else if (estPeriodId === 7) {
      row.value = `${row.calendaryear}##CY`
      // row.value = `${row.calendaryear}##${row.calendarquarter}`
    } else {
      // quarterly, semi annual, ntm
      row.value = `${row.calendaryear}##${row.calendarquarter}`
    }
    acc[row.estimateconsensusid] = row
    return acc
  }, {})
}

const transformActualDates = (dates) => {
  // from res.dates create an array of unique dates (unique estimatePeriodIds)
  // There are many estimateConsensusIds per estimatePeriodId (difference is tradingitemid?)
  const dateSet = new Set()

  const transformedDates = dates.reduce((acc, d) => {
    if (dateSet.has(d.estimateperiodid)) {
      return acc
    }

    dateSet.add(d.estimateperiodid)
    const estPeriodId = d.periodtypeid
    // d.value = d.estimateperiodid
    if (estPeriodId === 1) {
      d.value = `${d.calendaryear}##FY` // should this be row.fiscalyear?

      // d.value = `${d.calendaryear}##${d.calendarquarter}`
    } else if (estPeriodId === 7) {
      d.value = `${d.calendaryear}##CY`
      // d.value = `${d.calendaryear}##${d.calendarquarter}`
    } else {
      // quarterly, semi annual, ntm
      d.value = `${d.calendaryear}##${d.calendarquarter}`
    }

    const hasEstimateCompareValue =
      estPeriodId === 1
        ? 998
        : estPeriodId === 7
        ? 9998
        : estPeriodId === 2 || estPeriodId === 12 || estPeriodId === 10
        ? 492
        : 998
    const isEstimateCompareValue =
      estPeriodId === 1
        ? 1000
        : estPeriodId === 7
        ? 10000
        : estPeriodId === 2 || estPeriodId === 12 || estPeriodId === 10
        ? 500
        : 1000

    const newData = {
      hasEstimate: d.relativeconstant >= hasEstimateCompareValue,
      isEstimate: d.relativeconstant > isEstimateCompareValue,
      ...d,
    }

    newData.epoch = parseTime(d.periodenddate)
    newData.timeVal = Date.parse(d.periodenddate)

    acc.push(newData)
    return acc
  }, [])
  return transformedDates
}

/**
 *
 * @param {Object} res First API Response from the fetchEstimates action
 * @param {Object[]} res.dates objects containing each estimateConsensusId, associated
 * estimatePeriodId, and all date metadata for that estimatePeriod
 * @param {Object[]} res.actuals objects containing the individual dataitemvalues for
 * actuals associated with the returned date periods
 * @param {Object[]} estimateTemplate Array of Estimate Template Objects
 * @param {Object[]} estimateTemplate[].keys Array of lineItemObjects
 * @param {string} estimateTemplate[].statement Title of the statement
 */
const transformActualResponse = (res, estimateTemplate) => {
  const { actuals, dates } = res
  const transformedDates = transformActualDates(dates)

  const consensusToPeriod = mapConsensusToPeriod(dates)

  const { resData, isoCounter } = mapActualsDataItemID(
    actuals,
    consensusToPeriod
  )

  const estimatesLineItems = estimateTemplate
  const estimates = mapEstimates(estimatesLineItems, resData, transformedDates)

  return {
    consensusToPeriod,
    resData,
    estimatesLineItems,
    estimates,
    dates: transformedDates,
    isoCounter,
  }
}

export {
  transformActualResponse,
  transformActualDates,
  mapConsensusToPeriod,
  mapActualsDataItemID,
  mapEstimates,
}
