import "core-js/modules/es.symbol.js";
import "core-js/modules/es.symbol.description.js";
import "core-js/modules/es.symbol.iterator.js";
import "core-js/modules/es.symbol.to-primitive.js";
import "core-js/modules/es.array.filter.js";
import "core-js/modules/es.array.map.js";
import "core-js/modules/es.array.slice.js";
import "core-js/modules/es.date.to-primitive.js";
import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.number.is-nan.js";
import "core-js/modules/es.object.get-own-property-descriptor.js";
import "core-js/modules/es.object.get-own-property-descriptors.js";
import "core-js/modules/es.object.keys.js";
import "core-js/modules/es.object.to-string.js";
import "core-js/modules/es.string.iterator.js";
import "core-js/modules/web.dom-collections.for-each.js";
import "core-js/modules/web.dom-collections.iterator.js";
function _typeof(o) {
  "@babel/helpers - typeof";

  return _typeof = "function" == typeof Symbol && "symbol" == typeof Symbol.iterator ? function (o) {
    return typeof o;
  } : function (o) {
    return o && "function" == typeof Symbol && o.constructor === Symbol && o !== Symbol.prototype ? "symbol" : typeof o;
  }, _typeof(o);
}
function ownKeys(e, r) {
  var t = Object.keys(e);
  if (Object.getOwnPropertySymbols) {
    var o = Object.getOwnPropertySymbols(e);
    r && (o = o.filter(function (r) {
      return Object.getOwnPropertyDescriptor(e, r).enumerable;
    })), t.push.apply(t, o);
  }
  return t;
}
function _objectSpread(e) {
  for (var r = 1; r < arguments.length; r++) {
    var t = null != arguments[r] ? arguments[r] : {};
    r % 2 ? ownKeys(Object(t), !0).forEach(function (r) {
      _defineProperty(e, r, t[r]);
    }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) {
      Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r));
    });
  }
  return e;
}
function _defineProperty(e, r, t) {
  return (r = _toPropertyKey(r)) in e ? Object.defineProperty(e, r, {
    value: t,
    enumerable: !0,
    configurable: !0,
    writable: !0
  }) : e[r] = t, e;
}
function _toPropertyKey(t) {
  var i = _toPrimitive(t, "string");
  return "symbol" == _typeof(i) ? i : i + "";
}
function _toPrimitive(t, r) {
  if ("object" != _typeof(t) || !t) return t;
  var e = t[Symbol.toPrimitive];
  if (void 0 !== e) {
    var i = e.call(t, r || "default");
    if ("object" != _typeof(i)) return i;
    throw new TypeError("@@toPrimitive must return a primitive value.");
  }
  return ("string" === r ? String : Number)(t);
}
import mean from "lodash/mean";
import dayjs from "~/utils/tools/dayjs";
import useNumberFormatters from "~/functions/useNumberFormatters";
import useCommonStrings from "~/functions/useCommonStrings";
import { calculateCAGR } from "~/modules/ratios";
import { MILLION } from "~/utils/constants/numbers";
import { convertToUsdValue } from "~/utils/values/scale";
import { defaultUsdObj } from "~/utils/constants/objects";
import { hasProp } from "~/utils/screener/screenerConditionUtils";
var useCiqQuotesState = function useCiqQuotesState() {
  var _useNuxtApp = useNuxtApp(),
    $store = _useNuxtApp.$store;
  var _useCommonStrings = useCommonStrings(),
    nonAvailableString = _useCommonStrings.nonAvailableString;
  var _useNumberFormatters = useNumberFormatters(),
    formatNumberTwoDecimals = _useNumberFormatters.formatNumberTwoDecimals,
    dynamicTwoDigitCurrencyFormatter = _useNumberFormatters.dynamicTwoDigitCurrencyFormatter;
  var quoteCurrencyToggle = computed({
    get: function get() {
      return $store.state.ciq.quoteCurrencyToggle;
    },
    set: function set(value) {
      $store.commit("ciq/setToggle", {
        type: "quoteCurrencyToggle",
        value: value
      });
    }
  });
  var selectedQuotesCurrency = computed(function () {
    var current = $store.state.ciq.quoteCurrencyToggle;
    return $store.state.ciq.quoteCurrencies[current] || defaultUsdObj;
  });
  var quotesCurrencyFormatter = computed(function () {
    return dynamicTwoDigitCurrencyFormatter(selectedQuotesCurrency.value.code);
  });
  var quoteList = computed(function () {
    var _$store$state$ciq$quo;
    return ((_$store$state$ciq$quo = $store.state.ciq.quotes) === null || _$store$state$ciq$quo === void 0 ? void 0 : _$store$state$ciq$quo.price) || [];
  });
  var companyStockValues = computed(function () {
    var _$store$state$ciq$quo2;
    var storeData = ((_$store$state$ciq$quo2 = $store.state.ciq.quotes) === null || _$store$state$ciq$quo2 === void 0 ? void 0 : _$store$state$ciq$quo2.company) || {};
    var data = Object.assign({
      tev: false,
      marketcap: false,
      sharesoutstanding: false
    }, storeData);
    data.tev = getProperty("tev", data);
    data.marketcap = getProperty("marketcap", data);
    data.sharesoutstanding = getSharesOutstanding(data);
    return data;
  });
  var validProp = function validProp(obj, propName) {
    return hasProp(obj, propName) && !Number.isNaN(obj === null || obj === void 0 ? void 0 : obj[propName]);
  };
  var priceStats = computed(function () {
    var stats = {
      high: nonAvailableString.value,
      low: nonAvailableString.value,
      vol3mo: nonAvailableString.value,
      price5YrCagr: nonAvailableString.value,
      price3YrCagr: nonAvailableString.value,
      price1YrCagr: nonAvailableString.value,
      lastPriceDiv52WeekHigh: nonAvailableString.value
    };
    if (quoteList.value.length > 0) {
      var today = dayjs();
      var oneYear = quoteList.value.filter(function (item) {
        return dayjs(item.d).isAfter(today.subtract(1, "year"));
      });
      var threeYearArr = quoteList.value.filter(function (item) {
        return dayjs(item.d).isAfter(today.subtract(3, "year"));
      });
      var fiveYearArr = quoteList.value.filter(function (item) {
        return dayjs(item.d).isAfter(today.subtract(5, "year"));
      });
      var validOneYearQuotes = oneYear.filter(function (obj) {
        return validProp(obj, "l") && validProp(obj, "h") && validProp(obj, "pc");
      });
      var lastValue = oneYear[oneYear.length - 1];
      var cagrOneYear = calculateCAGR({
        initialValue: oneYear[0].c,
        lastValue: lastValue.c,
        firstDate: oneYear[0].d,
        lastDate: lastValue.d
      });
      var cagr3Years = calculateCAGR({
        initialValue: threeYearArr[0].c,
        lastValue: lastValue.c,
        firstDate: threeYearArr[0].d,
        lastDate: lastValue.d
      });
      var cagr5Years = calculateCAGR({
        initialValue: fiveYearArr[0].c,
        lastValue: lastValue.c,
        firstDate: fiveYearArr[0].d,
        lastDate: lastValue.d
      });
      var threeMonths = quoteList.value.slice(quoteList.value.length - 64);
      var validHighLow = validOneYearQuotes.length > 0;
      var highLowObj;
      if (validHighLow) {
        highLowObj = validOneYearQuotes.reduce(function (acc, p) {
          // test low
          if (p.l <= acc.low.l) {
            acc.low = Object.assign({}, p);
          }
          if (p.h >= acc.high.h) {
            acc.high = Object.assign({}, p);
          } // test high
          return acc;
        }, {
          high: validOneYearQuotes[0],
          low: validOneYearQuotes[0]
        });
      }
      var volumeArr = threeMonths.map(function (p) {
        return p.v;
      }).filter(function (f) {
        return !isNaN(f);
      });
      if (quoteCurrencyToggle.value) {
        var convertedHigh = convertToUsdValue({
          originalValue: highLowObj.high.h,
          priceClose: highLowObj.high.pc
        });
        var convertedLow = convertToUsdValue({
          originalValue: highLowObj.low.l,
          priceClose: highLowObj.low.pc
        });
        highLowObj.high.h = convertedHigh;
        highLowObj.low.l = convertedLow;
      }
      var lastPrice52WeekHighDiv = lastValue.c / highLowObj.high.h - 1;
      var updated = {
        high: validHighLow ? quotesCurrencyFormatter.value.format(highLowObj.high.h) : nonAvailableString.value,
        low: validHighLow ? quotesCurrencyFormatter.value.format(highLowObj.low.l) : nonAvailableString.value,
        vol3mo: formatNumberTwoDecimals.value.format(mean(volumeArr) / 1000000) || nonAvailableString.value,
        price5YrCagr: fiveYearArr.length >= 1217 ? cagr5Years.cagr : NaN,
        price3YrCagr: cagr3Years.cagr || nonAvailableString.value,
        price1YrCagr: cagrOneYear.cagr || nonAvailableString.value,
        lastPriceDiv52WeekHigh: lastPrice52WeekHighDiv || nonAvailableString.value
      };
      stats = _objectSpread(_objectSpread({}, stats), updated);
    }
    return stats;
  });
  var lastClosePriceQuotes = computed(function () {
    var _$store$state$ciq;
    var tid = $store.state.ciq.ticker.tradingitemid;
    return (_$store$state$ciq = $store.state.ciq) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq.addMultiples) === null || _$store$state$ciq === void 0 || (_$store$state$ciq = _$store$state$ciq[tid]) === null || _$store$state$ciq === void 0 ? void 0 : _$store$state$ciq.last;
  });
  var getProperty = function getProperty(key, data) {
    var scaleId = arguments.length > 2 && arguments[2] !== undefined ? arguments[2] : 2;
    if (data !== null && data !== void 0 && data[key]) {
      return {
        v: data[key],
        pc: data.priceclose,
        iso: data.isocode,
        name: data.currencyname,
        s: scaleId
      };
    } else {
      return nonAvailableString.value;
    }
  };
  var getSharesOutstanding = function getSharesOutstanding(dataObj) {
    if (dataObj.sharesoutstanding) {
      return _objectSpread(_objectSpread({}, dataObj.sharesoutstanding), {}, {
        v: dataObj.sharesoutstanding / MILLION
      });
    } else {
      return null;
    }
  };
  return {
    selectedQuotesCurrency: selectedQuotesCurrency,
    quotesCurrencyFormatter: quotesCurrencyFormatter,
    companyStockValues: companyStockValues,
    priceStats: priceStats,
    lastClosePriceQuotes: lastClosePriceQuotes
  };
};
export default useCiqQuotesState;