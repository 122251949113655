import { hasProp } from "~/utils/screener/screenerConditionUtils"
import { convertToUsdValue } from "~/utils/values/scale"

const findFirstAvailableItemInObject = ({
  item = {},
  keys = [],
  defaultEmpty = NaN,
}) => {
  const key = keys.find((key) => hasProp(item, key))
  return key ? item[key] : defaultEmpty
}

const findFirstAvailableKeyInObject = ({
  item = {},
  keys = [],
  defaultEmpty = null,
}) => {
  const key = keys.find((key) => hasProp(item, key))
  return key || defaultEmpty
}

const getLastKeyInObject = (obj = {}) => Object.keys(obj).pop()

const getLastKeyItemInObject = (obj = {}) =>
  obj[getLastKeyInObject(obj)] ?? null

const isObjectEmpty = (obj = {}) => Object.keys(obj).length === 0

const getNumericValueFromItemKey = ({
  keys = ["dataitemvalue", "v"],
  item = {},
  formatType = null,
  desiredCurrencyCode = "USD",
}) => {
  const isCurrency = formatType === "currency"
  const value = findFirstAvailableItemInObject({ item, keys })
  const shouldDoCurrencyConversion =
    isCurrency && item?.iso !== desiredCurrencyCode
  const actualValue = shouldDoCurrencyConversion
    ? convertToUsdValue({ originalValue: value, priceClose: item?.pc })
    : value

  return Number(actualValue)
}

/**
 * Checks if an object has all the keys provided in the keys array
 *
 * @param {Object} obj - The object to check
 * @param {Array} keys - The array of keys to check in the object
 * @returns {boolean} - Returns true if the object has all the keys, false otherwise
 */
const objectHasAllKeys = (obj = {}, keys = []) => {
  for (const key of keys) {
    if (!hasProp(obj, key)) {
      return false
    }
  }
  return true
}

/**
 * Rename a property of an object
 *
 * @param {string} oldProp - The old property name
 * @param {string} newProp - The new property name
 * @param {Object} obj - The object with the property to be renamed
 * @returns {Object} - The object with the renamed property
 */
const renameObjectProp = (oldProp, newProp, { [oldProp]: old, ...others }) => {
  if (old) {
    return {
      [newProp]: old,
      ...others,
    }
  } else {
    return { ...others }
  }
}

const getIsSameIso = (dataObjectsArray) => {
  const isocodes = dataObjectsArray.reduce((acc, obj) => {
    const iso = findFirstAvailableItemInObject({
      item: obj,
      keys: ["iso", "isocode"],
      defaultEmpty: "iso",
    })
    if (iso && !acc.includes(iso)) {
      acc.push(iso)
    }
    return acc
  }, [])
  return isocodes.length === 1
}

export {
  findFirstAvailableItemInObject,
  findFirstAvailableKeyInObject,
  getIsSameIso,
  getLastKeyInObject,
  getLastKeyItemInObject,
  isObjectEmpty,
  getNumericValueFromItemKey,
  objectHasAllKeys,
  renameObjectProp,
}
