import { onDeactivated, onBeforeMount, onBeforeUnmount, onBeforeUpdate, onUpdated, onRenderTriggered } from "vue";
var devStage = "dev";
var lambdaStage = process.env.LAMBDA_STAGE;
var useLifecycleLog = function useLifecycleLog(_ref) {
  var _ref$logName = _ref.logName,
    logName = _ref$logName === void 0 ? "Missing parameter" : _ref$logName,
    _ref$showBeforeHooks = _ref.showBeforeHooks,
    showBeforeHooks = _ref$showBeforeHooks === void 0 ? false : _ref$showBeforeHooks,
    _ref$showUpdatedHooks = _ref.showUpdatedHooks,
    showUpdatedHooks = _ref$showUpdatedHooks === void 0 ? false : _ref$showUpdatedHooks;
  var isDev = lambdaStage === devStage;
  if (isDev) {
    console.log("".concat(logName, " created called"), {
      showUpdatedHooks: showUpdatedHooks,
      lambdaStage: lambdaStage,
      test: process.env.LAMBDA_STAGE
    });
  }
  onMounted(function () {
    if (isDev) {
      console.log("".concat(logName, " onMounted called"));
    }
  });
  onUpdated(function () {
    if (isDev && showUpdatedHooks) {
      console.log("".concat(logName, " onUpdated called"));
    }
  });
  onDeactivated(function () {
    if (isDev) {
      console.log("".concat(logName, " onDeactivated called"));
    }
  });
  onBeforeMount(function () {
    if (isDev && showBeforeHooks) {
      console.log("".concat(logName, " onBeforeMount called"));
    }
  });
  onBeforeUnmount(function () {
    if (isDev && showBeforeHooks) {
      console.log("".concat(logName, " onBeforeUnmount called"));
    }
  });
  onBeforeUpdate(function () {
    if (isDev && showUpdatedHooks) {
      console.log("".concat(logName, " beforeUpdate called"));
    }
  });
  onRenderTriggered(function () {
    if (isDev) {
      console.log("".concat(logName, " onRenderTriggered called"));
    }
  });
};
export default useLifecycleLog;