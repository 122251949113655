const calcFinDiv = (
  numeratorMetricKey,
  denominatorMetricKey,
  resData,
  dates
) => {
  // const formula = lineitem.formula // right now I'm just going to assume its $1 div $2
  // FIXME: So if the formula ever changes then you've got to figure out how to handle it here
  const numeratorData = Object.assign({}, resData[numeratorMetricKey])
  const denominatorData = Object.assign({}, resData[denominatorMetricKey])
  const division = dates.reduce((acc, date) => {
    const period = date.value
    if (numeratorData[period] && denominatorData[period]) {
      const calc = numeratorData[period].v / denominatorData[period].v
      const res = { v: calc, u: 2 }
      if (numeratorData[period].unauth || denominatorData[period].unauth) {
        res.unauth = true
      }
      acc[period] = res
    }
    return acc
  }, {})
  return division
}

const addTwoFinLineItems = (firstDataObj, secondDataObj, dates) => {
  const sum = dates.reduce((acc, date) => {
    const period = date.value
    if (firstDataObj[period] && secondDataObj[period]) {
      const calc =
        Number(firstDataObj[period].v) + Number(secondDataObj[period].v) // FIXME: you seem to have lost control over if the .v is a number or string
      const resObj = { v: calc } // FIXME: nothing about units or currency
      if (firstDataObj[period].unauth || secondDataObj[period].unauth) {
        resObj.unauth = true
      }
      acc[period] = Object.assign({}, firstDataObj[period], resObj)
    }
    return acc
  }, {})
  return sum
}

const calculatePercentChange = (
  currentData,
  lastData,
  currentExRate,
  lastExRate
) => {
  // FIXME: Check the u for each value and normalize?
  const currentValue = parseFloat(currentData.v)
  const lastValue = parseFloat(lastData.v)
  const calc = (currentValue - lastValue) / lastValue
  const truthy = currentValue > lastValue
  const rightCalc = truthy ? Math.abs(calc) : calc
  // double check exchangeRatesWere Present
  let exRightCalc = 0

  // right now I'm worried about the possibilty of no exchange rate on the date
  if (currentExRate && lastExRate) {
    const exCalc =
      (currentValue / currentExRate - lastValue / lastExRate) /
      (lastValue / lastExRate)
    exRightCalc = truthy ? Math.abs(exCalc) : exCalc
  } else {
    exRightCalc = false
  }
  const result = { v: rightCalc, u: 0, vex: exRightCalc } // FIXME: figure out what to put for u...
  if (currentData.unauth || lastData.unauth) {
    result.unauth = true
  }
  return result
}

const calcFinDerivative = (metricKey, resData, dates, requestPeriod) => {
  // this function requires access to resData... maybe I should save it on the object
  const idxMap = {
    1: 0, // annual, need 1 data point for calculation
    2: 3, // quarterly, need 4 datapoints for calc
    10: 1, // semiannual need 2 datapoints for calc
    3: 3, // ytd quarterly, need 4 datapoints for calc
    4: 3, // ltm quarterly, need 4 datapoints for calc
  }
  const idxOffset = idxMap[requestPeriod]
  const requestPeriodDates = dates.filter(
    (f) => f.periodtypeid === Number(requestPeriod)
  )
  // commented out code below is what you used to use before filtering dates to request period
  // const derivative = dates.reduce((acc, date, idx, arr) => {
  // const periodTypeId = date.periodtypeid
  // if (requestPeriod === 1 && periodTypeId === 4) {
  //   // LTM period for annual financials, return acc
  //   return acc
  // }
  const data = Object.assign({}, resData[metricKey]) // make copy of data so you're not messing up reference

  // lineitemDateObj is used for currency conversion and seems to have
  // its own schema for dateKeys used to access the information within the
  // accumulator object
  const lineitemDateObj = requestPeriodDates.reduce((acc, dateObj) => {
    const fid = dateObj.value
    const periodData = data[fid]
    if (periodData) {
      const quarter = dateObj.fiscalquarter
      const year = dateObj.fiscalyear
      const dateKey = `${quarter}Q${year}`
      acc[dateKey] = periodData
    }
    return acc
  }, {})
  const derivative = requestPeriodDates.reduce((acc, date, idx, arr) => {
    // if (idx <= idxOffset) {
    //   // no derivative for the first period
    //   return acc
    // }
    const lastPeriodIndex = idx - 1 - idxOffset
    const lastPeriod = arr[lastPeriodIndex]
    const currentFinPeriod = date.value
    // const lastFinPeriod = lastPeriod.value
    // const currentData = data[currentFinPeriod]
    // const lastData = data[lastFinPeriod]
    const quarter = date.fiscalquarter
    const currentYear = date.fiscalyear
    const lastYear = currentYear - 1
    const currentDateKey = `${quarter}Q${currentYear}`
    const lastDateKey = `${quarter}Q${lastYear}`
    const currentData = lineitemDateObj[currentDateKey]
    const lastData = lineitemDateObj[lastDateKey]
    if (currentData && lastData) {
      const currentExRate = date.priceclose ? date.priceclose : false
      const lastExRate = lastPeriod
        ? lastPeriod.priceclose
          ? lastPeriod.priceclose
          : false
        : false
      const result = calculatePercentChange(
        currentData,
        lastData,
        currentExRate,
        lastExRate
      )
      acc[currentFinPeriod] = result
    }

    return acc
  }, {}) // {financialperiodid: (thisFinperiod / lastFinperiod) - 1}
  return derivative
}

export { calcFinDiv, calcFinDerivative, addTwoFinLineItems }
