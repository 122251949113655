const state = () => ({
  utm_source: false,
  utm_medium: false,
  utm_campaign: false,
  utm_term: false,
  utm_content: false,
  gclid: false,
  msclkid: false,
  fbclid: false,
  marketingQueryParams: [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
    "gclid",
    "msclkid",
    "fbclid",
  ],
  cognitoMarketingQueryParams: [
    "utm_source",
    "utm_medium",
    "utm_campaign",
    "utm_term",
    "utm_content",
  ],
})

const mutations = {
  updateAnalytics(state, payload) {
    // const property = payload.property
    state[payload.property] = payload.value
  },
}

export { state, mutations }
