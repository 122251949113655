import "core-js/modules/es.object.to-string.js";
// import Amplify, * as AmplifyModules from "aws-amplify"
// import Amplify, { Auth, Logger, I18n, API, graphqlOperation } from "aws-amplify"
// import { graphqlOperation } from "aws-amplify"
import { Amplify, Logger } from "@aws-amplify/core";
import { I18n } from "aws-amplify";
import Auth from "@aws-amplify/auth";
import API, { graphqlOperation } from "@aws-amplify/api";
import Analytics from "@aws-amplify/analytics";
import awsExports from "./aws-exports";
import ja from "./ja-translations";
import zh from "./zh-translation";
import ko from "./ko-translation";
import es from "./es-translations"; // import dict from "./tikr-translations"
var dict = {};
var AmplifyModules = {};
AmplifyModules.Core = Amplify;
AmplifyModules.Auth = Auth;
AmplifyModules.Logger = Logger;
AmplifyModules.I18n = I18n;
AmplifyModules.API = API;
AmplifyModules.Analytics = Analytics;
AmplifyModules.graphqlOperation = graphqlOperation; // Amplify.Analytics.disable()
AmplifyModules.Core.configure(awsExports);
AmplifyModules.Auth.configure(awsExports); // does adding the whole Amplify bundle increase the size ?
// disabling analytics because I want the endpoints registered...
// and I'm seeing weird stuff when its on - it doesn't seem to
// be picking up the user?
// enable page view tracking
AmplifyModules.Analytics.autoTrack("pageView", {
  enable: true,
  type: "SPA"
});
dict.ja = ja;
dict.zh = zh;
dict.ko = ko;
dict.es = es;
AmplifyModules.I18n.putVocabularies(dict); // to enable Page event tracking (event = user interaction with elements on page)
// attach the specified selectors to the dom element and engage autotracking
// Analytics.autoTrack("event", {
//   enable: true,
//   events: ["click"],
//   sleectorPrefix: "data-amplify-analytics"
// })
if (!process.browser) {
  global.fetch = require("node-fetch");
} // Hiding more appsync code
// const appSyncClientOptions = {
//   url: awsExports.aws_appsync_graphqlEndpoint,
//   region: awsExports.aws_appsync_region,
//   auth: {
//     type: awsExports.aws_appsync_authenticationType,
//     jwtToken: async () =>
//       (await AmplifyModules.Auth.currentSession()).idToken.jwtToken
//   },
//   disableOffline: true
// }
/**
 * disableOffline skips the creation of the local store
 * to persist the cache and mutations made while offline
 * can use the `offlineConfig` key to provide config for appsync sdk
 *  error handling (callback)
 * custom storage engine (storage)
 */ // import fetch from "node-fetch"
// import { createHttpLink } from "apollo-link-http"
// const link = createHttpLink({ uri: "/graphql", fetch: fetch })
// const apolloClientOptions = {
//   link: createAppSyncLink({
//     ...appSyncClientOptions,
//     resultsFetcherLink: createHttpLink({ uri: appSyncClientOptions.url, fetch })
//   }),
//   defaultOptions: {
//     watchQuery: {
//       fetchPolicy: "network-only"
//     }
//   }
// }
// 'cache-and-network'
// https://medium.com/@galen.corey/understanding-apollo-fetch-policies-705b5ad71980
// const client = new AWSAppSyncClient(appSyncClientOptions, apolloClientOptions)
// const appSyncProvider = new VueApollo({
//   defaultClient: client
// })
export default (function (_, inject) {
  inject("Amplify", AmplifyModules); // inject("client", appSyncProvider)
});