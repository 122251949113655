export default {
  AAD: "Arab Accounting Dinar",
  ADP: "Andorran Peseta",
  AED: "United Arab Emirates Dirham",
  AFA: "Afghani",
  AFN: "Afghanistan Afghani",
  ALL: "Albanian Lek",
  AMD: "Armenia Dram",
  ANG: "Netherlands Antilles Guilder",
  AOA: "Angola Kwanza",
  AON: "Angolan New Kwanza",
  ARS: "Argentine Peso",
  ATS: "Austrian Schilling",
  AUD: "Australian Dollar",
  AWG: "Aruban Guilder",
  AZM: "Azerbaijan Manet",
  AZN: "New Azerbaijan Manat",
  BAM: "Convertible Mark",
  BBD: "Barbados Dollar",
  BDT: "Bangladesh Taka",
  BEF: "Belgian Franc",
  BGL: "Bulgarian Lev",
  BGN: "Bulgarian Lev",
  BHD: "Bahraini Dinar",
  BIF: "Burundi Franc",
  BMD: "Bermuda Dollar",
  BND: "Brunei Dollar",
  BOB: "Bolivian Boliviano",
  BRL: "Brazilian Real",
  BSD: "Bahamas Dollar",
  BTN: "Bhutan Ngultrum",
  BWP: "Botswana Pula",
  BYN: "Belarusian Ruble",
  BYR: "Belarus Rouble",
  BZD: "Belize Dollar",
  CAD: "Canadian Dollar",
  CDF: "Congo (Dem. Rep.) Franc",
  CHF: "Swiss Franc",
  CLF: "Chilean Unidades De Fomento",
  CLP: "Chilean Peso",
  CNH: "Chinese Offshore Yuan",
  CNY: "Chinese Renminbi (Yuan)",
  COP: "Colombian Peso",
  CRC: "Costa Rica Colon",
  CSD: "Serbian Dinar",
  CUP: "Cuban Peso",
  CVE: "Cape Verde Escudo",
  CYP: "Cyprus Pound",
  CZK: "Czech Koruna",
  DDM: "East German Ostmark",
  DEM: "German Deutschmark",
  DJF: "Djibouti Franc",
  DKK: "Danish Krone",
  DOP: "Dominican Rep Peso",
  DZD: "Algerian Dinar",
  ECS: "Ecuador Sucre",
  EEK: "Estonian Kroon",
  EGP: "Egyptian Pound",
  ERN: "Nafka",
  ESP: "Spanish Peseta",
  ETB: "Ethiopian Birr",
  EUR: "Euro",
  FIM: "Finnish Markka",
  FJD: "Fiji Dollar",
  FKP: "Falkland Island Pound",
  FRF: "French Franc",
  GBP: "British Pound",
  GBX: "British Pence",
  GEL: "Georgia Lari",
  GHC: "Ghana Cedi",
  GHS: "New Ghana Cedi",
  GIP: "Gibraltar Pound",
  GMD: "Gambia Dalasi",
  GNF: "Guinea Franc",
  GRD: "Greek Drachma",
  GTQ: "Guatemala Quetzal",
  GWP: "Peso",
  GYD: "Guyana Dollar",
  HKD: "Hong Kong Dollar",
  HNL: "Honduras Lempira",
  HRK: "Croatian Kuna",
  HTG: "Haiti Gourde",
  HUF: "Hungarian Forint",
  IDN: "Islamic Dinar",
  IDR: "Indonesian Rupiah",
  IEP: "Irish Punt",
  ILA: "Israeli Agorot",
  ILS: "Israeli Shekel",
  INR: "Indian Rupee",
  IQD: "Iraqi Dinar",
  IRR: "Iran Rial",
  ISK: "Icelandic Krona",
  ITL: "Italian Lire",
  JMD: "Jamaican Dollar",
  JOD: "Jordanian Dinar",
  JPY: "Japanese Yen",
  KES: "Kenya Shilling",
  KGS: "Kyrgyzstan Som",
  KHR: "Cambodian (Kampuchea) Riel",
  KMF: "Comoros Franc",
  KPW: "North Korean Won",
  KRW: "South Korean Won",
  KWD: "Kuwait Dinar",
  KWF: "Kuwait Fils",
  KYD: "Cayman Islands Dollar",
  KZT: "Kazakhstan Tenge",
  LAK: "Laos New Kip",
  LBP: "Lebanese Pound",
  LKR: "Sri Lanka Rupee",
  LRD: "Liberian Dollar",
  LSL: "Lesotho Loti",
  LTL: "Lithuanian Litas",
  LUF: "Luxembourg Franc",
  LVL: "Latvian Lats",
  LYD: "Libyan Dinar",
  MAD: "Moroccan Dirham",
  MDL: "Moldovan Leu",
  MGA: "Ariary",
  MGF: "Malagasy Franc",
  MKD: "Macedonian Denar",
  MMK: "Myanmar (Burma) Kyat",
  MNT: "Mongolian Tugrik",
  MOP: "Macau Pataca",
  MRO: "Mauritania Ouguiya",
  MRU: "New Mauritania Ouguiya",
  MTL: "Maltese Pound",
  MUR: "Mauritian Rupee",
  MVR: "Maldive Is Rufiyaa",
  MWK: "Malawi Kwacha",
  MXN: "Mexican Peso",
  MXV: "Mexican Unidad de Inversion",
  MYR: "Malaysian Ringgit",
  MZM: "Old Mozambique Metical",
  MZN: "Mozambique Metical",
  NAD: "Namibian Dollar",
  NGN: "Nigerian Naira",
  NIO: "Nicaragua Cordoba Oro",
  NLG: "Netherlands Guilder",
  NOK: "Norwegian Krone",
  NPR: "Nepalese Rupee",
  NZD: "New Zealand Dollar",
  OMR: "Oman Rial",
  PAB: "Panama Balboa",
  PEN: "Peruvian Sol",
  PGK: "Papua New Guinea Kina",
  PHP: "Philippines Peso",
  PKR: "Pakistan Rupee",
  PLN: "Polish Zloty",
  PPP: "Percentage of Par",
  PTE: "Portuguese Escudo",
  PYG: "Paraguay Guarani",
  QAR: "Qatari Rial",
  ROL: "Romanian Leu (old)",
  RON: "Romanian Leu",
  RSD: "Serbian Dinar",
  RUB: "Russian Rouble",
  RUR: "Old Russian Ruble",
  RWF: "Rwanda Franc",
  SAC: "S. African Rand Commerc.",
  SAR: "Saudi Arabian Riyal",
  SBD: "Solomon Island Dollar",
  SCR: "Seychelles Rupee",
  SDD: "Sudanese Dinar",
  SDG: "Sudanese Pound",
  SDP: "Sudanese Pound",
  SEK: "Swedish Krona",
  SGD: "Singapore Dollar",
  SHP: "St Helena Pound",
  SIT: "Slovenian Tolar",
  SKK: "Slovakian Koruna",
  SLL: "Sierra Leone Leone",
  SOS: "Somali Shilling",
  SRD: "Surinam Dollar",
  SRG: "Surinam Guilder",
  STD: "Sao Tome & Principe Dobra",
  SVC: "El Salvador Colon",
  SYP: "Syrian Pound",
  SZL: "Swaziland Lilangeni",
  THB: "Thai Baht",
  TJR: "Tajikistan Rouble",
  TJS: "Somoni",
  TMM: "Turkmenistan Manet",
  TMT: "Turkmenistan New Manet",
  TND: "Tunisian Dinar",
  TOF: "Thai Baht Offshore",
  TOP: "Tonga Pa'Anga",
  TPE: "Timor Escudo",
  TRL: "Old Turkish Lira",
  TRY: "Turkish Lira",
  TTD: "Trinidad and Tobago Dollar",
  TWD: "Taiwan Dollar",
  TZS: "Tanzania Shilling",
  UAH: "Ukraine Hryvna",
  UGX: "Uganda Shilling",
  USD: "US Dollar",
  USX: "US Cent",
  UYU: "Peso Uruguayo",
  UZS: "Uzbekistan Sum",
  VEB: "Venezuelan Bolivar",
  VEF: "Venezuelan Bolivar Fuerte",
  VES: "Venezuelan Bolivar Soberano",
  VND: "Vietnamese Dong",
  VUV: "Vanuatu Vatu",
  WST: "Western Samoa Tala",
  XAF: "CFA Franc BEAC",
  XBA: "European Composite Unit (EURCO)",
  XBB: "European Monetary Unit (EMU)",
  XCD: "East Caribbean Dollar",
  XDR: "Special Drawing Rights",
  XEU: "European Currency Unit",
  XOF: "CFA (BCEAO) Franc",
  XPF: "CFP Franc",
  XUA: "Unit of Account",
  YER: "Yemeni Rial",
  YUM: "Yugoslav Dinar",
  ZAC: "South African Cent",
  ZAR: "South African Rand",
  ZMK: "Zambian Kwacha",
  ZMW: "Zambian New Kwacha",
  ZWD: "Zimbabwe Dollar",
  ZWL: "New Zimbabwe Dollar",
}
