import { hasProp } from "~/utils/screener/screenerConditionUtils"
import {
  findFirstAvailableItemInObject,
  findFirstAvailableKeyInObject,
} from "~/utils/tools/object"

const convertToUsdObject = (
  dataObj,
  fallbackValue = 1,
  valueKeys = ["v", "dataitemvalue"],
  exchangeRateKeys = ["pc", "priceclose", "exRate"],
  isoCodeKeys = ["iso", "isocode"]
) => {
  if (!dataObj || Object.keys(dataObj).length === 0) {
    return fallbackValue
  }

  const valueKey = findFirstAvailableKeyInObject({
    item: dataObj,
    keys: valueKeys,
  })

  const isoKey = findFirstAvailableKeyInObject({
    item: dataObj,
    keys: isoCodeKeys,
    defaultEmpty: "iso",
  })

  if (isoKey === "USD") {
    return dataObj
  }

  const exRateKey = findFirstAvailableKeyInObject({
    item: dataObj,
    keys: exchangeRateKeys,
  })

  const fallback = dataObj?.[valueKey] || fallbackValue

  const converted = convertToUsdValue({
    originalValue: dataObj[valueKey],
    priceClose: parseFloat(dataObj[exRateKey]),
    fallback,
  })

  return {
    ...dataObj,
    [valueKey]: converted,
    [isoKey]: "USD",
  }
}

const convertToUsdValue = ({
  originalValue,
  priceClose,
  fallbackValue = 1,
}) => {
  if (!originalValue || !priceClose) {
    return fallbackValue
  }

  return originalValue / priceClose
}

/**
 * scale ciq value object to millions if the value unit type
 * is not millions or absolute. Accepts data from estimates
 * financials and valuation data sources.
 */
const scaleCiqValueObject = ({
  dataObj,
  fallbackValue = 1,
  scaleIdKeys = ["s", "u", "estimatescaleid", "unittypeid"],
  valueIdKeys = ["v", "dataitemvalue"],
  // valueKey = "v", // priceObj value key is .c
  isEstimateUnitId = false,
}) => {
  if (!dataObj) {
    return fallbackValue
  }

  const valueKey = findFirstAvailableKeyInObject({
    item: dataObj,
    keys: valueIdKeys,
  })

  const unitTypeId = findFirstAvailableItemInObject({
    item: dataObj,
    keys: scaleIdKeys,
  })

  const isEstimate =
    isEstimateUnitId ||
    dataObj.est ||
    hasProp(dataObj, "estimatescaleid") ||
    dataObj.isEst

  if (isEstimate) {
    return getEstimatesScaledValue(dataObj[valueKey], unitTypeId)
  }
  // valueObj is from financials data source
  // scale to either millions or absolute value
  // if it is in thousands ... scale to millions
  if (unitTypeId === 0 || unitTypeId === 2) {
    return dataObj[valueKey]
  } else {
    // value is in thousands, divide by 1000 to get to millions
    return dataObj[valueKey] / 1000
  }
}

const getEstimatesScaledValue = (value, unitTypeId, fallbackValue = 1) => {
  if (unitTypeId === 0 || unitTypeId === 3) {
    return value
  }

  // unit is not millions or absolute, scale it to millions
  if (unitTypeId === 1) {
    // units are in billions, multiply by 1000 to get to millions
    return value * 1000
  }

  if (unitTypeId === 2) {
    // units are in thousands, divide by 1000
    return value / 1000
  }

  if (unitTypeId === 4) {
    // units are in 1 / 100ths .01
    // its like a percent but we want it to be more absolute so I think
    // this is right? idk if this ever runs
    return value / 100
  }

  console.error("Do not recognize multiples scaletypeid: ", unitTypeId)
  return fallbackValue
}

const scaleEstimateDataObject = ({
  dataObj,
  fallbackValue = 1,
  estimateScaleIdKeys = ["s", "u", "estimatescaleid", "unittypeid", "exRate"],
  valueKey = "v",
}) => {
  if (!dataObj) {
    return fallbackValue
  }

  // scale the dataObj[valueKey] to the estimateScaleId of scaleId
  // returning the scaled number dataObj[valueKey] as type number
  const isEstimate = dataObj.est
  const estimateScaleId = findFirstAvailableItemInObject({
    item: dataObj,
    keys: estimateScaleIdKeys,
  })

  if (!estimateScaleId || !isEstimate) {
    // no estimateScaleId or is an Financials number in millions
    return dataObj[valueKey]
  }

  if (estimateScaleId === 0 || estimateScaleId === 3) {
    // Hoping that 3 is always in millions... do I need to parse this to a number?
    return dataObj[valueKey]
  }

  if (estimateScaleId === 1) {
    // units are in billions, multiple by 1000 to get to millions
    return dataObj[valueKey] * 1000
  }

  if (estimateScaleId === 2) {
    // units are in thousands, divide by 1000
    return dataObj[valueKey] / 1000
  }

  if (estimateScaleId === 4) {
    // units are in 1 / 100ths .01
    // its like a percent
    // if you're trying to go to... millions? no shot
    return dataObj[valueKey] / 100
  }

  console.error("Do not recognize multiples scaletypeid: ", estimateScaleId)
}

/**
 * Function to scale a value from its current unit to the desired unit.
 *
 * @param {Object} params - An object containing the value, currentUnit, and desiredUnit.
 * @param {number} params.value - The value to be scaled.
 * @param {number} params.currentUnit - The current unit of the value.
 * @param {number} params.desiredUnit - The unit to which the value needs to be scaled.
 *
 * @returns {number} The scaled value.
 */
const scaleEstimateValue = ({ value, currentUnit, desiredUnit }) => {
  if ([3, 4].includes(currentUnit)) {
    return value
  }

  const unitTypeTransform = {
    0: 2, // millions,
    1: 3, // billions,
    2: 1, // thousands,
    3: -1, // absolute, TODO: everything below!
    4: -3, // 1/100th 1e4 10/1
    5: -4, // 1/1000th
    6: -5, // 1/10000th
    7: 4, // Trillion
    8: -2, // 1/10th
    9: 0, // Hundred
  }
  const firstVal = unitTypeTransform[currentUnit]
  const lastVal = desiredUnit + 1
  const unitConversion = firstVal - lastVal // results the 10^unitConversion needed

  switch (unitConversion) {
    case -4:
      // multiply 1e-9     1e(-4 * 3)
      return value / 1000000000000
    case -3:
      return value / 1000000000
    case -2:
      return value / 1000000
    case -1:
      return value / 1000
    case 0: // 1e(0 * 3)
      return value
    case 1: // 1e(1*3)
      // 1 e3
      return value * 1000
    case 2: // 1e(2*3)
      // 1 e6
      return value * 1000000
    case 3: // 1e(3*3)
      // 1 e9
      return value * 1000000000
    case 4: // 1e(4*3)
      // 1 e12
      return value * 1000000000000
    default:
      console.log("Converting Units Logic Error: ", unitConversion, desiredUnit) // ah this is what was 4
    // return this.print(result, dataObj)
  }
}

export {
  getEstimatesScaledValue,
  scaleEstimateValue,
  scaleEstimateDataObject,
  convertToUsdObject,
  convertToUsdValue,
  scaleCiqValueObject,
}
