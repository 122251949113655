import debounce from "lodash/debounce"

const stage = process.env.LAMBDA_STAGE
const devStage = stage === "dev"

// const testLocalStorageKeys = [
//   "tikrTestScreenDataObj",
//   "tikrTestScreenOrder",
//   "tikrTestactive",
// ]
// const testLocalStorageMap = {
//   screenDataObj: "tikrTestScreenDataObj",
//   order: "tikrTestScreenOrder",
//   active: "tikrTestactive",
// }

// const default = {
//   title: "My First Global Screen",
//   description: "",
//   sortBy: ["usdmarketcap"],
//   sortDesc: [true],
//   itemsPerPage: 15,
//   page: 1,
//   condArr: [
//     {
//       dataType: "usdmarketcap",
//       valueType: null,
//       periodType: null,
//       firstPeriodValue: null,
//       secondPeriodValue: null,
//       periodValue: null,
//       didType: null,
//       conditionType: "gte",
//       conditionValue: "2000",
//       conditionUnit: "MM USD",
//       created: "2021-04-17T19:48:25.364Z",
//     },
//     {
//       dataType: "est_Revenue",
//       valueType: "yoyChange",
//       periodType: "fy",
//       firstPeriodValue: 0,
//       secondPeriodValue: null,
//       periodValue: null,
//       didType: "actual",
//       conditionType: "gte",
//       conditionValue: "30",
//       conditionUnit: "MM USD",
//       created: "first",
//     },
//   ],
//   error: null,
// }

const setLocalStorage = (localStorageKey, desiredValue) => {
  if (desiredValue) {
    if (typeof desiredValue === "object") {
      const str = JSON.stringify(desiredValue)
      localStorage.setItem(localStorageKey, str)
    } else {
      localStorage.setItem(localStorageKey, desiredValue)
    }
  }
}

const getStrFromLocalStorage = (localStorageKey) => {
  return localStorage.getItem(localStorageKey)
}

const getObjectFromLocalStorage = (localStorageKey) => {
  const strRes = localStorage.getItem(localStorageKey)
  try {
    const objRes = JSON.parse(strRes)
    return objRes
  } catch (e) {
    if (devStage) {
      console.error("error getting object from LocalStorage")
    }
    return null
  }
}

const getManyFromLocalStorage = (keyArr = []) => {
  const res = {}
  for (const key of keyArr) {
    // get item
    const strRes = localStorage.getItem(key)
    if (strRes) {
      const objRes = JSON.parse(strRes)
      res[key] = objRes
    }
  }
  return res
}

const debounceSetLocalStorage = debounce(setLocalStorage, 150)

export {
  getManyFromLocalStorage,
  setLocalStorage,
  getStrFromLocalStorage,
  getObjectFromLocalStorage,
  debounceSetLocalStorage,
}
