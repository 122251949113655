import dayjs from "~/utils/tools/dayjs"
import { findFirstAvailableItemInObject } from "~/utils/tools/object"
export { findMetricKey, rowObjToSelectedKey } from "./common"
export { transformFirstActualResponse } from "./old"
export { transformEstimateResponse } from "./estimatesRes"
export { transformActualResponse } from "./actualsRes"
export { transformNtmResponse } from "./ntmRes"
export { transformFinancialResponse } from "./financialsRes"
export { transformWatchlistResponse, tickersToFetch } from "./watchlist"
export { transformAllValuationData } from "./allMultiplesRes"

const setDateRange = (dates, yearStart) => {
  /**
   * estimates dates has the structure:
   * {
  "hasEstimate": false,
  "isEstimate": false,
  "estimateperiodid": 39766,
  "periodtypeid": 1,
  "companyid": 29096,
  "fiscalchainseriesid": 0,
  "fiscalquarter": 4,
  "fiscalyear": 2004,
  "calendarquarter": 4,
  "calendaryear": 2004,
  "periodenddate": "2004-12-31T00:00:00.000Z",
  "advancedate": "2005-02-01T21:02:00.000Z",
  "relativeconstant": 985,
  "estimateconsensusid": 24663,
  "tradingitemid": 11311662,
  "accountingstandardid": 2,
  "parentflag": 0,
  "primaryparentconsolflag": 1
}

    inancials dates has the structure:
{
  "financialperiodid": 114975116,
  "companyid": 29096,
  "periodtypeid": 1,
  "calendaryear": 2004,
  "calendarquarter": 4,
  "fiscalyear": 2004,
  "fiscalquarter": 4,
  "latestperiodflag": 0,
  "fiscalchainseriesid": 1,
  "fiperiodenddate": "2004-12-31T00:00:00.000Z",
  "fifilingdate": "2007-03-01T00:00:00.000Z",
  "currencyid": 160,
  "pricedate": "2004-12-31T00:00:00.000Z",
  "snapid": 6,
  "priceclose": "1.000000000",
  "bestpricedate": "2004-12-31T00:00:00.000Z",
  "latestsnapflag": 1,
  "carryforwardflag": 0,
  "currencyname": "US Dollar",
  "isocode": "USD",
  "countryid": 213,
  "majorcurrencyflag": 1
}
   */
  const max = dates.length - 1
  // always want to show 4 periods... no more. now you want to show based on date.
  // lets start by something like, 2011
  // const calcMin = periodType === "1" ? max - 4 : max - 5
  const calcMin = dates
    .map((m) => m?.calendaryear || m?.yearStr)
    .findIndex((y) => y >= yearStart)

  // but calcMin can't be less than 0
  // const actualMin = showAllPeriods ? 0 : calcMin >= 0 ? calcMin : 0

  // Now that we have the max and CalcMin, set them on
  // component data
  // this.dateMin = actualMin
  // this.dateMax = max
  return [calcMin, max]
}

const findClosestDateIndex = (date, dates, maxDate = false) => {
  if (maxDate) {
    return dates.length - 1
  }
  const dateEpoch = dayjs(date.timeVal).valueOf()

  const closestDateIndex = dates.reduce((acc, curr, index) => {
    const currEpoch = dayjs(curr.timeVal).valueOf()
    const diff = Math.abs(currEpoch - dateEpoch)
    const prevEpoch = dayjs(dates[acc].timeVal).valueOf()
    const prevDiff = Math.abs(prevEpoch - dateEpoch)
    return diff <= prevDiff ? index : acc
  }, 0)

  return closestDateIndex
}

const dontConvertIds = [342, 3217, 24153, 3085, 3195, 4184, 4042, 4035, 4183]

const findExRate = (item) => {
  return findFirstAvailableItemInObject({
    item,
    keys: ["exRate", "pc", "priceclose"],
    defaultEmpty: 1,
  })
}

export { dontConvertIds, setDateRange, findClosestDateIndex, findExRate }
