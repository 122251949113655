import timezone from "dayjs/plugin/timezone"
import dayjs from "~/utils/tools/dayjs"

dayjs.extend(timezone)

const createUtcEpochToLongDateTimeFormatter = (lang) => {
  return Intl.DateTimeFormat(lang, {
    timeZone: "UTC",
    year: "numeric",
    month: "long",
    day: "numeric",
  })
}

const createUtcEpochToShortDateTimeFormatter = (lang) => {
  return new Intl.DateTimeFormat(lang, {
    timeZone: "UTC",
    year: "2-digit",
    month: "numeric",
    day: "numeric",
  })
}

const isOutsideNYSEMarketHours = () => {
  const TIMEZONE = "America/New_York"
  const nyTime = () => dayjs().tz(TIMEZONE)
  const now = nyTime()
  const dayOfWeek = now.day()

  // (0 = Sunday, 6 = Saturday)
  if (dayOfWeek === 0 || dayOfWeek === 6) {
    return true
  }

  const start = now.hour(7).minute(30) // 7:30 AM
  const end = now.hour(19).minute(0) // 7:00 PM

  return now.isBefore(start) || now.isAfter(end)
}

export {
  createUtcEpochToLongDateTimeFormatter,
  createUtcEpochToShortDateTimeFormatter,
  isOutsideNYSEMarketHours,
}
