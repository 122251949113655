const DEFAULT_INTERVAL_TIME = 100
const MAX_ATTEMPTS = 100

export const MaxAttemptsReached = new Error(
  "Maximum number of attempts reached"
)

/**
 * Retrieves an element from the DOM and waits for it to be created before resolving the promise.
 * @param {String} selector - HTML selector for the element to be observed.
 * @param {Number} interval - time interval in milliseconds to wait before checking for element existence.
 *
 * @returns {{ element?: HTMLElement, error?: Error }} - An object with either the HTMLElement or an error
 */
export const getElementAndRetryIfNotExistsYet = (
  selector,
  interval = DEFAULT_INTERVAL_TIME
) => {
  return new Promise((resolve) => {
    let attempts = 0

    const intervalId = setInterval(() => {
      attempts += 1
      if (attempts > MAX_ATTEMPTS) {
        clearInterval(intervalId)
        resolve({ error: MaxAttemptsReached })
      }

      const element = document.querySelector(selector)
      if (element) {
        clearInterval(intervalId)
        resolve({ element, error: null })
      }
    }, interval)
  })
}
