export const ONBOARDING_STEP_INITIAL_PAGE_ID = "ONBOARDING_STEP_INITIAL_PAGE_ID"
export const ONBOARDING_STEP_MARKET_OVERVIEW_ID =
  "ONBOARDING_STEP_MARKET_OVERVIEW_ID"
export const ONBOARDING_STEP_MY_WATCHLIST_ID = "ONBOARDING_STEP_MY_WATCHLIST_ID"
export const ONBOARDING_STEP_TRACK_INVESTING_GURUS_ID =
  "ONBOARDING_STEP_TRACK_INVESTING_GURUS_ID"
export const ONBOARDING_STEP_GLOBAL_SCREENER_ID =
  "ONBOARDING_STEP_GLOBAL_SCREENER_ID"
export const ONBOARDING_DETAILED_FINANCIALS_ID =
  "ONBOARDING_DETAILED_FINANCIALS_ID"
export const ONBOARDING_ANALYST_ESTIMATES_ID = "ONBOARDING_ANALYST_ESTIMATES_ID"
export const ONBOARDING_WANT_TO_LEARN_MORE_ID =
  "ONBOARDING_WANT_TO_LEARN_MORE_ID"
