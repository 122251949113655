const valDidToMetricKey = {
  10: "fin gross profit",
  28: "fin total revenues",
  142: "fin diluted eps excl extra items",
  400: "fin ebit",
  3058: "fin dividends per share",
  4020: "fin book value per share",
  4051: "fin ebitda",
  4156: "fin tangible book value/share",
  4422: "fin levered free cash flow",
  4423: "fin unlevered free cash flow",
  21635: "ntm gaap earnings",
  100160: "# of recommendations 100160",
  100161: "target stock price mean",
  100162: "target stock price median",
  100163: "target stock price high",
  100164: "target stock price low",
  100165: "target stock price (# est)",
  100173: "eps normalized",
  100180: "revenue",
  100187: "ebitda",
  100194: "ffo / share (reit)",
  100208: "dividend per share",
  100215: "ebit",
  100312: "# of no opinions",
  100313: "# of buys",
  100314: "# of sells",
  100315: "# of holds",
  100316: "# of outperforms",
  100317: "# of underperforms",
  104055: "book value / share",
  105076: "distributable cash / share (income trust)",
  105316: "net asset value / share",
  114155: "adjusted ffo / share (reit)",
  114210: "net asset value / share mean",
  114211: "net asset value / share high",
  114212: "net asset value / share median",
  114213: "net asset value / share low",
  114214: "net asset value / share (# est)",
  114221: "free cash flow",
  114362: "# of recommendations 114362",
  114368: "# of recommendations 114368",
  105293: "gross margin (%) mean",
  100201: "cash flow / share mean",
  // priceclose: "price close",
  // tev: "total enterprise value (mm)",
  // mc: "market cap (mm)",
  // ntm_gp: "ntm gross profit",
  // ntmeps_dt: "ntm normalized eps yoy growth",
  // ntm_pe: "ntm normalized pe multiple",
  // ltm_ncav: "ltm net current asset value",
}

// LINK static/ciq_worker.js:960
const valuationTemplate = [
  {
    statement: "Multiples",
    keys: [
      {
        dataitemid: [""],
        name: "Forward Multiples",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 0,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["tev", "100180"],
        name: "NTM Total Enterprise Value / Revenues",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 1,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100180",
      },
      // {
      //   dataitemid: ["tev", "ntm_gp"],
      //   name: "NTM Total Enterprise Value / Margin",
      //   format: "normal",
      //   formula: "div",
      //   type: "turns",
      //   lineorder: 1,
      //   multiplyby: 1,
      //   tikrdisplay: "1",
      //   numerator: "tev",
      //   denominator: "ntm_gp",
      // },
      {
        dataitemid: ["mc", "100180"],
        name: "NTM Price / Sales (P/S)",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 2,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "100180",
      },
      {
        dataitemid: ["tev", "ntm_gp"],
        name: "NTM Total Enterprise Value / Gross Profit",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 3,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "tev",
        denominator: "ntm_gp",
      },
      {
        dataitemid: ["tev", "100187"],
        name: "NTM Total Enterprise Value / EBITDA",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 4,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100187",
      },
      {
        dataitemid: ["tev", "100215"],
        name: "NTM Total Enterprise Value / EBIT",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 5,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100215",
      },
      {
        dataitemid: ["priceclose", "100173"],
        name: "NTM Price / Normalized Earnings (P/E)",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 6,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100173",
      },
      {
        dataitemid: ["ntm_pe", "ntmeps_dt"],
        name: "PEG Ratio",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 6,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "ntm_pe",
        denominator: "ntmeps_dt",
      },
      {
        dataitemid: ["mc", "114221"],
        name: "NTM Market Cap / Free Cash Flow",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 8,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "114221",
      },
      {
        dataitemid: ["114221", "mc"],
        name: "NTM Levered Free Cash Flow Yield",
        format: "normal",
        formula: "div",
        type: "pct",
        lineorder: 9,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "114221",
        denominator: "mc",
      },
      {
        dataitemid: ["priceclose", "100194"],
        name: "NTM Price / FFO Per Share",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 11,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100194",
      },
      {
        dataitemid: ["priceclose", "114155"],
        name: "NTM Price / AFFO Per Share",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 12,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114155",
      },
      {
        dataitemid: ["100208", "priceclose"],
        name: "NTM Dividend Yield",
        format: "normal",
        formula: "div",
        type: "pct",
        lineorder: 7,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "100208",
        denominator: "priceclose",
      },
      {
        dataitemid: [""],
        name: "Trailing Multiples",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 9,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["tev", "28"],
        name: "LTM Total Enterprise Value / Revenues",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 10,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "28",
      },
      {
        dataitemid: ["mc", "28"],
        name: "LTM Price / Sales (P/S)",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 10,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "28",
      },
      {
        dataitemid: ["tev", "10"],
        name: "LTM Total Enterprise Value / Gross Profit",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 10,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "10",
      },
      {
        dataitemid: ["tev", "4051"],
        name: "LTM Total Enterprise Value / EBITDA",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 11,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "4051",
      },
      {
        dataitemid: ["tev", "400"],
        name: "LTM Total Enterprise Value / EBIT",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 12,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "400",
      },
      {
        dataitemid: ["priceclose", "142"],
        name: "LTM Price / Diluted EPS (P/E)",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 13,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "142",
      },
      {
        dataitemid: ["priceclose", "4020"],
        name: "LTM Price / Book Value per Share",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 14,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "4020",
      },
      {
        dataitemid: ["priceclose", "4156"],
        name: "LTM Price / Tangible Book Value per Share",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 15,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "4156",
      },
      {
        dataitemid: ["tev", "4423"],
        name: "LTM Total Enterprise Value / Unlevered Free Cash Flow",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 16,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "4423",
      },
      {
        dataitemid: ["mc", "4422"],
        name: "LTM Market Cap / Levered Free Cash Flow",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 17,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "4422",
      },
      {
        dataitemid: ["priceclose", "ltm_ncav"],
        name: "LTM Price / Net Current Asset Value",
        format: "normal",
        formula: "div",
        type: "turns",
        lineorder: 17,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "ltm_ncav",
      },
      {
        dataitemid: ["3058", "priceclose"],
        name: "LTM Dividend Yield",
        format: "normal",
        formula: "div",
        type: "pct",
        lineorder: 16,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "3058",
        denominator: "priceclose",
      },
      {
        dataitemid: [""],
        name: "Price Factors",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 18,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["priceclose"],
        name: "Price",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 19,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "priceclose",
      },
      {
        dataitemid: ["tev"],
        name: "Total Enterprise Value (MM)",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 20,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "tev",
      },
      {
        dataitemid: ["mc"],
        name: "Market Cap (MM)",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 21,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "mc",
      },
      {
        dataitemid: [""],
        name: "Forward Factors",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 22,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["100180"],
        name: "NTM Revenues",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 23,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100180",
      },
      {
        dataitemid: ["ntm_gp"],
        name: "NTM Gross Profit",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 23,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "tev",
        denominator: "ntm_gp",
      },
      {
        dataitemid: ["100187"],
        name: "NTM EBITDA",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 24,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100187",
      },
      {
        dataitemid: ["100215"],
        name: "NTM EBIT",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 25,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "100215",
      },
      {
        dataitemid: ["100173"],
        name: "NTM Normalized Earnings Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 26,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100173",
      },
      {
        dataitemid: ["ntmeps_dt"],
        name: "NTM Normalized EPS YoY Growth",
        format: "normal",
        formula: "val",
        type: "pct",
        lineorder: 26,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "priceclose",
        denominator: "ntmeps_dt",
      },
      {
        dataitemid: ["ntm_pe"],
        name: "NTM Normalized PE Multiple",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 26,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "priceclose",
        denominator: "ntm_pe",
      },
      {
        dataitemid: ["21635"],
        name: "NTM GAAP Earnings",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 27,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "21635",
      },
      {
        dataitemid: ["100208"],
        name: "NTM Dividend / Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 28,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100208",
      },
      {
        dataitemid: ["114221"],
        name: "NTM Levered Free Cash Flow",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 28,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "114221",
      },
      {
        dataitemid: ["105076"],
        name: "NTM Distributable Cash Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 30,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "105076",
      },
      {
        dataitemid: ["100194"],
        name: "NTM FFO Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 31,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100194",
      },
      {
        dataitemid: ["114155"],
        name: "NTM AFFO Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 32,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114155",
      },
      {
        dataitemid: ["104055"],
        name: "NTM BV Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 33,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "104055",
      },
      {
        dataitemid: ["105316"],
        name: "NTM NAV Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 34,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "105316",
      },
      {
        dataitemid: [""],
        name: "Trailing Factors",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 35,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["28"],
        name: "LTM Revenues",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 36,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "28",
      },
      {
        dataitemid: ["10"],
        name: "LTM Gross Profit",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 36,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "10",
      },
      {
        dataitemid: ["4051"],
        name: "LTM EBITDA",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 37,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "4051",
      },
      {
        dataitemid: ["400"],
        name: "LTM EBIT",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 38,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "400",
      },
      {
        dataitemid: ["142"],
        name: "LTM Diluted EPS Before Extra",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 39,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "142",
      },
      {
        dataitemid: ["4020"],
        name: "LTM Book Value per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 40,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "4020",
      },
      {
        dataitemid: ["4156"],
        name: "LTM Tangible Book Value per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 41,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "4156",
      },
      {
        dataitemid: ["3058"],
        name: "LTM Dividend Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 42,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "3058",
      },
      {
        dataitemid: ["4423"],
        name: "LTM Unlevered Free Cash Flow",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 42,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "tev",
        denominator: "4423",
      },
      {
        dataitemid: ["4422"],
        name: "LTM Levered Free Cash Flow",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 43,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "mc",
        denominator: "4422",
      },
      {
        dataitemid: ["ltm_ncav"],
        name: "LTM Net Current Asset Value Per Share",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 43,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "ltm_ncav",
      },
    ],
  },
  {
    statement: "Street Targets",
    keys: [
      {
        dataitemid: ["priceclose"],
        name: "Price Close",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 0,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "priceclose",
      },
      {
        dataitemid: [""],
        name: "Street Price Targets",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 1,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["100161"],
        name: "Target Stock Price Mean",
        format: "bold",
        formula: "val",
        type: "float",
        lineorder: 2,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100161",
      },
      {
        dataitemid: ["100162"],
        name: "Target Stock Price Median",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 3,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100162",
      },
      {
        dataitemid: ["100163"],
        name: "Target Stock Price High",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 4,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100163",
      },
      {
        dataitemid: ["100164"],
        name: "Target Stock Price Low",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 5,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100164",
      },
      {
        dataitemid: ["100165"],
        name: "Target Stock Price (# Est)",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 6,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100165",
      },
      {
        dataitemid: [""],
        name: "Street Recommendations",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 7,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["100313"],
        name: "# of Buys",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 8,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100313",
      },
      {
        dataitemid: ["100316"],
        name: "# of Outperforms",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 9,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100316",
      },
      {
        dataitemid: ["100315"],
        name: "# of Holds",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 10,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100315",
      },
      {
        dataitemid: ["100312"],
        name: "# of No Opinions",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 11,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100312",
      },
      {
        dataitemid: ["100317"],
        name: "# of Underperforms",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 12,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100317",
      },
      {
        dataitemid: ["100314"],
        name: "# of Sells",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 13,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "100314",
      },
      {
        dataitemid: ["100160"],
        name: "# of Recommendations 100160",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 14,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "priceclose",
        denominator: "100160",
      },
      {
        dataitemid: ["114362"],
        name: "# of Recommendations 114362",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 15,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "priceclose",
        denominator: "114362",
      },
      {
        dataitemid: ["114368"],
        name: "# of Recommendations 114368",
        format: "normal",
        formula: "val",
        type: "int",
        lineorder: 16,
        multiplyby: 1,
        tikrdisplay: "0",
        numerator: "priceclose",
        denominator: "114368",
      },
      {
        dataitemid: [""],
        name: "Street Estimate",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        lineorder: 17,
        multiplyby: 1,
        tikrdisplay: "1",
      },
      {
        dataitemid: ["114210"],
        name: "Net Asset Value / Share Mean",
        format: "bold",
        formula: "val",
        type: "float",
        lineorder: 18,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114210",
      },
      {
        dataitemid: ["114212"],
        name: "Net Asset Value / Share Median",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 19,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114212",
      },
      {
        dataitemid: ["114211"],
        name: "Net Asset Value / Share High",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 20,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114211",
      },
      {
        dataitemid: ["114213"],
        name: "Net Asset Value / Share Low",
        format: "normal",
        formula: "val",
        type: "float",
        lineorder: 21,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114213",
      },
      {
        dataitemid: ["114214"],
        name: "Net Asset Value / Share (# Est)",
        format: "normal pb-3",
        formula: "val",
        type: "int",
        lineorder: 22,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114214",
      },
      {
        dataitemid: ["100161", "priceclose"],
        name: "Target Stock Price / Close Price",
        format: "bold",
        formula: "div",
        type: "pct",
        lineorder: 23,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "100161",
        denominator: "priceclose",
      },
      {
        dataitemid: ["priceclose", "114210"],
        name: "Price / NAV per Share",
        format: "bold",
        formula: "div",
        type: "turns",
        lineorder: 24,
        multiplyby: 1,
        tikrdisplay: "1",
        numerator: "priceclose",
        denominator: "114210",
      },
    ],
  },
]

export { valuationTemplate, valDidToMetricKey }
