import { allScreenMap } from "~/components/screener/screener_lineitems"

const hasProp = (obj, key) => {
  return obj && Object.hasOwn(obj, key)
}

const strOrNum = (varToCheck) => {
  const validType = ["string", "number"]
  const varType = typeof varToCheck
  return validType.includes(varType)
}

const translateDataTypeObj = (obj, I18nFn) => {
  obj.text = I18nFn(obj.text)
  if (obj.abbr) {
    obj.abbr = I18nFn(obj.abbr)
  }
  if (obj.dataTypeGroup) {
    obj.dataTypeGroup = I18nFn(obj.dataTypeGroup)
  }
  return obj
}

const isConditionValueValid = (
  dataType,
  valueType,
  conditionValue,
  conditionType
) => {
  /**
   * abstracted fn to determine if conditionValue is valid
   * based on the dataType and valueType
   * @returns Boolean
   */
  // valueType may be null

  const numberTypes = [
    "floatint",
    "float",
    "int",
    "pct",
    "turns",
    "ogprod",
    "ogprodrate",
  ]
  if (conditionType === "do") {
    // if this function is being called and conditionType is display only
    // then I believe the condition should be valid
    return true
  }
  if (dataType.selType === "company_attr") {
    // valueType may be null, ignore it
    if (numberTypes.includes(dataType.type)) {
      // FIXME: is this the best way to detect if conditionValue is a number?
      return !isNaN(parseFloat(conditionValue)) // NEGATION OPERATIOR !
    } else {
      // FIXME: I think the other possibility here is that conditionValue is an array
      // assuming the condition value just needs one condition
      return conditionValue?.length && conditionValue.length > 0
    }
  }
  if (
    dataType.selType === "est_lineitem" ||
    dataType.selType === "est_multiple" ||
    dataType.selType === "hist_multiple" ||
    dataType.selType === "fin_lineitem"
  ) {
    // FIXME: right now we're just going to assume that all
    // est_lineitems are supposed to be numbers?
    return !isNaN(parseFloat(conditionValue)) // NEGATION OPERATIOR !
  }
  return false
}

const isConditionComplete = ({
  dataType: dataTypeValue,
  valueType,
  periodType,
  firstPeriodValue,
  secondPeriodValue,
  didType,
  conditionType,
  conditionValue,
}) => {
  const twoPeriodValueTypes = ["cagr"]
  const estimateSelTypes = ["est_lineitem", "est_multiple"]
  const financialSelTypes = ["fin_lineitem", "hist_multiple"]
  const dataType = allScreenMap[dataTypeValue]
  // const conditionUnit = conditionUnitChoice.value // TODO: think about units not an object, just a string for now?

  // all references to "value" below are the unique key property
  // minimum of dataType and valueType needed to determine what properties
  // are required to determine if the condition is complete
  if (hasProp(dataType, "value")) {
    if (dataType.selType === "company_attr") {
      const requirePeriodTypes = ["pricechange"]
      // we're looking at a company attribute... there are many options here
      // but luckily there are many attributes that are not needed like
      // periodType, periodValue, didType
      if (requirePeriodTypes.includes(dataType.value) && !periodType) {
        return false // you're going... wildy fast
      }
      if (conditionType && (conditionValue || conditionType === "do")) {
        // now based on the type of dataType and the conditionType determine
        // appropriate conditonValue and check if it exists
        return isConditionValueValid(
          dataType,
          valueType,
          conditionValue,
          conditionType
        )
      }
    }

    // if it isn't a company attribute there will be a valueType which is needed to determine required attributes
    if (valueType) {
      if (
        estimateSelTypes.includes(dataType.selType) ||
        financialSelTypes.includes(dataType.selType)
      ) {
        // we're looking at a estimate lineitem, now determine if we're dealing with a one or two period type
        if (periodType) {
          // for now, lets just check that the periodType and didType exist...
          // FIXME: make sure that the firstPeriodValue aligns with the periodType.value
          if (twoPeriodValueTypes.includes(valueType)) {
            // two period type... DOES NOT REQUIRE A DIDTYPE
            if (
              strOrNum(firstPeriodValue) &&
              strOrNum(secondPeriodValue) &&
              firstPeriodValue < secondPeriodValue
            ) {
              return isConditionValueValid(
                dataType,
                valueType,
                conditionValue,
                conditionType
              )
            }
          } else if (
            periodType === "ltm" ||
            (strOrNum(firstPeriodValue) && didType && conditionType)
          ) {
            // one period type
            // FIXME: make sure that the didType is available for the periodType and firstPeriodValue...
            // will also probably need to do for conditionType if I introduce the BETWEEN operator
            return isConditionValueValid(
              dataType,
              valueType,
              conditionValue,
              conditionType
            )
          }
        }
        // FIXME: if you ever have an input that has more than 2 inputs.. you'll need to figure this out
      } else {
        // could be an unknown type w/o a valuetype.. nah you can just trigger it by selecting a value type
        console.error(
          `unknown dataType.selType ${dataType.selType} determining if screener condition is compelte`
        )
      }
    }
  }
  // to determine if a condition is complete
  return false
}

export {
  hasProp,
  isConditionValueValid,
  isConditionComplete,
  translateDataTypeObj,
  strOrNum,
}
