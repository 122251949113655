const commonREITs = [
  "Mortgage Real Estate Investment Trusts (REITs)",
  "Health Care REITs",
  "Hotel and Resort REITs",
  "Industrial REITs",
  "Office REITs",
  "Residential REITs",
  "Retail REITs",
  "Specialized REITs",
]

export const cyclicalDescriptionIdMatch = [
  "Energy Equipment and Services",
  "Oil, Gas and Consumable Fuels",
  "Metals and Mining",
  "Paper and Forest Products",
]

export const highlySpecializedDescriptionMatch = [
  "Biotechnology",
  "Pharmaceuticals",
  "Banks",
  "Financial Services",
  "Consumer Finance",
  "Insurance",
  "Diversified REITs",
  "Electric Utilities",
  "Gas Utilities",
  "Multi-Utilities",
  "Water Utilities",
  "Independent Power and Renewable Electricity Producers",
  ...commonREITs,
]

export const bookValuePerShareDescriptionMatch = [
  "Banks",
  "Insurance",
  "Consumer Finance",
  "Financial Services",
]

export const ffoDescriptionMatch = [...commonREITs, "Diversified REITs"]
