import Vue from "vue"

import * as indicators from "highcharts/indicators/indicators"
import * as indicatorsAll from "highcharts/indicators/indicators-all"
import * as dragPanes from "highcharts/modules/drag-panes"
import * as accessibility from "highcharts/modules/accessibility"
import * as advancedAnnotations from "highcharts/modules/annotations-advanced"
import * as priceIndicator from "highcharts/modules/price-indicator"
import * as fullScreen from "highcharts/modules/full-screen"
import * as stockTools from "highcharts/modules/stock-tools"
import exportingInit from "highcharts/modules/exporting"
import exportingData from "highcharts/modules/export-data"
import highchartsMore from "highcharts/highcharts-more"

import "highcharts/css/stocktools/gui.css"
import "highcharts/css/annotations/popup.css"
import HighchartsVue from "highcharts-vue"
import Highcharts from "highcharts"
import stockInit from "highcharts/modules/stock"
import histInit from "highcharts/modules/histogram-bellcurve"

/**
 * https://stackoverflow.com/questions/55483079/highcharts-cannot-read-property-parts-globals-js-of-undefined
 * https://github.com/highcharts/highcharts/issues/10588
 * if on the serverside then Highcharts will be of type function
 */
if (typeof Highcharts === "object") {
  // Highcharts.SVGRenderer.prototype.symbols.customcircle = function(x, y, w, h) {
  Highcharts.SVGRenderer.prototype.symbols.customcircle = function () {
    const r = 8

    return [
      //
      "M",
      0,
      9,
      "m",
      -r,
      0,
      "a",
      r,
      r,
      0,
      1,
      0,
      2 * r,
      0,
      "a",
      r,
      r,
      0,
      1,
      0,
      -2 * r,
      0,
    ]
  }

  Highcharts.setOptions({ lang: { thousandsSep: "," } })

  stockInit(Highcharts)
  histInit(Highcharts)
  indicators(Highcharts)
  indicatorsAll(Highcharts)
  dragPanes(Highcharts)
  advancedAnnotations(Highcharts)
  priceIndicator(Highcharts)
  fullScreen(Highcharts)
  exportingInit(Highcharts)
  exportingData(Highcharts)
  stockTools(Highcharts)
  accessibility(Highcharts)
  highchartsMore(Highcharts)
}

export default () => {
  // highcharts is the default tag
  Vue.use(HighchartsVue, { tagName: "highcharts" })
}
