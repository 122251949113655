import { useIDBKeyval } from "@vueuse/integrations/useIDBKeyval"
import { fetchLogoData } from "~/services/valuationModel"

const logos = useIDBKeyval("logos", {})

const save = async (obj) => {
  for (const [key, val] of Object.entries(obj)) {
    const logoData = {
      ...logos.data.value,
      [key]: {
        hideLogo: false,
        data: val,
      },
    }
    await logos.set(logoData)
  }
}

const getLogo = (cid) => {
  return logos.data.value?.[String(cid)]
}

const isValidLogo = (cid) => {
  return (
    logos.data.value?.[String(cid)]?.data &&
    !logos.data.value?.[String(cid)]?.hideLogo
  )
}

const getAllLogos = () => {
  return logos.data.value
}

const fetchLogo = async (cid, $axios, $Amplify) => {
  const cidString = String(cid)
  const { data, error } = await fetchLogoData({
    $AmplifyObj: $Amplify,
    $axiosObj: $axios,
    cid,
  })
  if (error) {
    return
  }
  save({
    [cidString]: data[0]?.logo,
  })
  return data
}

export { save, fetchLogo, getLogo, getAllLogos, isValidLogo }
