export default {
  theme: {
    themes: {
      dark: {
        primary: "#2F3033",
        primaryAction: "#FF9900",
        toolbarText: "#232F3E",
        accent: "#CFD2D6", // "#E1E4EA",
        secondary: "#828282",
        anchor: "#CFD2D6", // "#FFFFFF"
        // info: colors.teal.lighten1,
        // warning: colors.amber.base,
        // error: colors.deepOrange.accent4,
        // success: colors.green.accent3
      },
      light: {
        primary: "#2F3033", // Squid Ink #232F3E
        primaryAction: "#FF9900", // "#7FBF4F" = the green
        toolbarText: "#2F3033", // Squid Ink #232F3E
        accent: "#E1E4EA",
        secondary: "#828282",
        anchor: "#2F3033", // Squid Ink #232F3E
      },
    },
  },
}
