// LINK static/ciq_worker.js:552
const estDidToMetricKey = {
  100179: "eps normalized act.",
  100186: "revenue act.",
  100193: "ebitda act.",
  100200: "ffo / share (reit) act.",
  100207: "cash flow / share act.",
  100214: "dividend per share act.",
  100221: "ebit act.",
  100235: "ebt normalized act.",
  100242: "ebt (excl. excep & gw) act.",
  100249: "ebt (gaap) actual act.",
  100256: "net income normalized act.",
  100263: "net income (excl. excep & gw) actual act.",
  100270: "net income (gaap) act.",
  100284: "eps (gaap) act.",
  104061: "book value / share act.",
  104082: "cash from operations act.",
  104096: "capital expenditure act.",
  104103: "net debt act.",
  104117: "return on equity (%) act.",
  104124: "return on assets (%) act.",
  105075: "distributable cash (income trusts) act.",
  105082: "distributable cash / share (income trust) act.",
  105103: "adjusted ffo (reit) act.",
  105114: "ffo (reit) act.",
  105125: "net asset value act.",
  105284: "price target mean act.",
  105299: "gross margin (%) act.",
  105307: "cash eps act.",
  105321: "net asset value / share act.",
  114154: "adjusted ffo / share (reit) act.",
  114165: "effective tax rate (%) act.",
  114176: "interest expense act.",
  114187: "depreciation & amortization act.",
  114198: "same store sales growth (%) act.",
  114220: "free cash flow act.",
  115403: "total oil production (mbbl) act.",
  115416: "avg. oil production (mbbl/d) act.",
  115429: "total ngl production (mmbbl) act.",
  115442: "avg. ngl production (mbbl/d) act.",
  115455: "total gas production (mmscf) act.",
  115468: "avg. gas production (mmscf/d) act.",
  115481: "total avg. production (mboe/d) act.",
  115494: "oil revenues act.",
  115507: "ngl revenues act.",
  115520: "gas revenues act.",
  115533: "exploration expense act.",
  115591: "total production (mboe) act.",
}

const estimateTemplate = [
  {
    keys: [
      {
        dataitemid: 100186,
        name: "Revenue ",
        lineorder: 0,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100180,
        median: 100181,
        high: 100182,
        low: 100183,
        numEst: 100184,
        stdDev: 100185,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["100186"],
        name: "% Change YoY",
        lineorder: 1,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100180"],
        median: ["100181"],
        high: ["100182"],
        low: ["100183"],
        numEst: ["100184"],
        stdDev: ["100185"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 100193,
        name: "EBITDA ",
        lineorder: 2,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100187,
        median: 100188,
        high: 100189,
        low: 100190,
        numEst: 100191,
        stdDev: 100192,
        guideMid: 22323,
        guideHigh: 22391,
        guideLow: 22451,
      },
      {
        dataitemid: ["100193"],
        name: "% Change YoY",
        lineorder: 3,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100187"],
        median: ["100188"],
        high: ["100189"],
        low: ["100190"],
        numEst: ["100191"],
        stdDev: ["100192"],
        guideMid: ["22323"],
        guideHigh: ["22391"],
        guideLow: ["22451"],
      },
      {
        dataitemid: ["100193", "100186"],
        name: "% EBITDA Margins",
        lineorder: 4,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100187", "100180"],
        median: ["100188", "100181"],
        high: ["100189", "100182"],
        low: ["100190", "100183"],
        numEst: ["100191", "100184"],
        stdDev: ["100192", "100185"],
        guideMid: ["22323", "22322"],
        guideHigh: ["22391", "22390"],
        guideLow: ["22451", "22450"],
      },
      {
        dataitemid: 100221,
        name: "EBIT ",
        lineorder: 5,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100215,
        median: 100216,
        high: 100217,
        low: 100218,
        numEst: 100219,
        stdDev: 100220,
        guideMid: 22325,
        guideHigh: 22393,
        guideLow: 22453,
      },
      {
        dataitemid: ["100221"],
        name: "% Change YoY",
        lineorder: 6,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100215"],
        median: ["100216"],
        high: ["100217"],
        low: ["100218"],
        numEst: ["100219"],
        stdDev: ["100220"],
        guideMid: ["22325"],
        guideHigh: ["22393"],
        guideLow: ["22453"],
      },
      {
        dataitemid: ["100221", "100186"],
        name: "% EBIT Margins",
        lineorder: 7,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100215", "100180"],
        median: ["100216", "100181"],
        high: ["100217", "100182"],
        low: ["100218", "100183"],
        numEst: ["100219", "100184"],
        stdDev: ["100220", "100185"],
        guideMid: ["22325", "22322"],
        guideHigh: ["22393", "22390"],
        guideLow: ["22453", "22450"],
      },
      {
        dataitemid: 100235,
        name: "EBT Normalized ",
        lineorder: 8,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100229,
        median: 100230,
        high: 100231,
        low: 100232,
        numEst: 100233,
        stdDev: 100234,
        guideMid: 22326,
        guideHigh: 22394,
        guideLow: 22454,
      },
      {
        dataitemid: ["100235"],
        name: "% Change YoY",
        lineorder: 9,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100229"],
        median: ["100230"],
        high: ["100231"],
        low: ["100232"],
        numEst: ["100233"],
        stdDev: ["100234"],
        guideMid: ["22326"],
        guideHigh: ["22394"],
        guideLow: ["22454"],
      },
      {
        dataitemid: ["100235", "100186"],
        name: "% EBT Margins",
        lineorder: 10,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100229", "100180"],
        median: ["100230", "100181"],
        high: ["100231", "100182"],
        low: ["100232", "100183"],
        numEst: ["100233", "100184"],
        stdDev: ["100234", "100185"],
        guideMid: ["22326", "22322"],
        guideHigh: ["22394", "22390"],
        guideLow: ["22454", "22450"],
      },
      {
        dataitemid: 100256,
        name: "Net Income Normalized ",
        lineorder: 11,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100250,
        median: 100251,
        high: 100252,
        low: 100253,
        numEst: 100254,
        stdDev: 100255,
        guideMid: 22329,
        guideHigh: 22397,
        guideLow: 22457,
      },
      {
        dataitemid: ["100256"],
        name: "% Change YoY",
        lineorder: 12,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100250"],
        median: ["100251"],
        high: ["100252"],
        low: ["100253"],
        numEst: ["100254"],
        stdDev: ["100255"],
        guideMid: ["22329"],
        guideHigh: ["22397"],
        guideLow: ["22457"],
      },
      {
        dataitemid: ["100256", "100186"],
        name: "% Net Income Margins",
        lineorder: 13,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100250", "100180"],
        median: ["100251", "100181"],
        high: ["100252", "100182"],
        low: ["100253", "100183"],
        numEst: ["100254", "100184"],
        stdDev: ["100255", "100185"],
        guideMid: ["22329", "22322"],
        guideHigh: ["22397", "22390"],
        guideLow: ["22457", "22450"],
      },
      {
        dataitemid: 100179,
        name: "EPS Normalized ",
        lineorder: 14,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100173,
        median: 100174,
        high: 100175,
        low: 100176,
        numEst: 100177,
        stdDev: 100178,
        guideMid: 22315,
        guideHigh: 22383,
        guideLow: 22443,
      },
      {
        dataitemid: ["100179"],
        name: "% Change YoY",
        lineorder: 15,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100173"],
        median: ["100174"],
        high: ["100175"],
        low: ["100176"],
        numEst: ["100177"],
        stdDev: ["100178"],
        guideMid: ["22315"],
        guideHigh: ["22383"],
        guideLow: ["22443"],
      },
      {
        dataitemid: 100284,
        name: "EPS (GAAP) ",
        lineorder: 16,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100278,
        median: 100279,
        high: 100280,
        low: 100281,
        numEst: 100282,
        stdDev: 100283,
        guideMid: 22316,
        guideHigh: 22384,
        guideLow: 22444,
      },
      {
        dataitemid: ["100284"],
        name: "% Change YoY",
        lineorder: 17,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100278"],
        median: ["100279"],
        high: ["100280"],
        low: ["100281"],
        numEst: ["100282"],
        stdDev: ["100283"],
        guideMid: ["22316"],
        guideHigh: ["22384"],
        guideLow: ["22444"],
      },
      {
        dataitemid: 114220,
        name: "Free Cash Flow ",
        lineorder: 40,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114221,
        median: 114223,
        high: 114222,
        low: 114224,
        numEst: 114225,
        stdDev: 114226,
        guideMid: 114228,
        guideHigh: 114227,
        guideLow: 114229,
      },
      {
        dataitemid: ["114220"],
        name: "% Change YoY",
        lineorder: 41,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114221"],
        median: ["114223"],
        high: ["114222"],
        low: ["114224"],
        numEst: ["114225"],
        stdDev: ["114226"],
        guideMid: ["114228"],
        guideHigh: ["114227"],
        guideLow: ["114229"],
      },
      {
        dataitemid: ["114220", "100186"],
        name: "% Free Cash Flow Margins",
        lineorder: 4,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["114221", "100180"],
        median: ["114223", "100181"],
        high: ["114222", "100182"],
        low: ["114224", "100183"],
        numEst: ["114225", "100184"],
        stdDev: ["114226", "100185"],
        guideMid: ["114228", "22322"],
        guideHigh: ["114227", "22390"],
        guideLow: ["114229", "22450"],
      },
      {
        dataitemid: 100214,
        name: "Dividend Per Share",
        lineorder: 18,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100208,
        median: 100209,
        high: 100210,
        low: 100211,
        numEst: 100212,
        stdDev: 100213,
        guideMid: 22320,
        guideHigh: 22388,
        guideLow: 22448,
      },
      {
        dataitemid: ["100214"],
        name: "% Change YoY",
        lineorder: 19,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100208"],
        median: ["100209"],
        high: ["100210"],
        low: ["100211"],
        numEst: ["100212"],
        stdDev: ["100213"],
        guideMid: ["22320"],
        guideHigh: ["22388"],
        guideLow: ["22448"],
      },
      {
        dataitemid: [""],
        name: "IS Supplementary Data:",
        lineorder: 20,
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 105299,
        name: "Gross Margin (%)",
        lineorder: 21,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105293,
        median: 105294,
        high: 105295,
        low: 105296,
        numEst: 105297,
        stdDev: 105298,
        guideMid: 114231,
        guideHigh: 114230,
        guideLow: 114232,
      },
      {
        dataitemid: 114176,
        name: "Interest Expense ",
        lineorder: 22,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114177,
        median: 114179,
        high: 114178,
        low: 114180,
        numEst: 114181,
        stdDev: 114182,
        guideMid: 114184,
        guideHigh: 114183,
        guideLow: 114185,
      },
      {
        dataitemid: 114187,
        name: "Depreciation & Amortization ",
        lineorder: 23,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114188,
        median: 114190,
        high: 114189,
        low: 114191,
        numEst: 114192,
        stdDev: 114193,
        guideMid: 114195,
        guideHigh: 114194,
        guideLow: 114196,
      },
      {
        dataitemid: ["114187"],
        name: "% Change YoY",
        lineorder: 87,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114188"],
        median: ["114190"],
        high: ["114189"],
        low: ["114191"],
        numEst: ["114192"],
        stdDev: ["114193"],
        guideMid: ["114195"],
        guideHigh: ["114194"],
        guideLow: ["114196"],
      },
      {
        dataitemid: 114165,
        name: "Effective Tax Rate (%)",
        lineorder: 24,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "val",
        multiplyby: 1,
        mean: 114166,
        median: 114168,
        high: 114167,
        low: 114169,
        numEst: 114170,
        stdDev: 114171,
        guideMid: 114173,
        guideHigh: 114172,
        guideLow: 114174,
      },
      {
        dataitemid: 100242,
        name: "EBT (Excl. Excep & GW) ",
        lineorder: 25,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100236,
        median: 100237,
        high: 100238,
        low: 100239,
        numEst: 100240,
        stdDev: 100241,
        guideMid: 22328,
        guideHigh: 22396,
        guideLow: 22456,
      },
      {
        dataitemid: ["100242"],
        name: "% Change YoY",
        lineorder: 26,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100236"],
        median: ["100237"],
        high: ["100238"],
        low: ["100239"],
        numEst: ["100240"],
        stdDev: ["100241"],
        guideMid: ["22328"],
        guideHigh: ["22396"],
        guideLow: ["22456"],
      },
      {
        dataitemid: 100249,
        name: "EBT (GAAP) Actual",
        lineorder: 27,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100243,
        median: 100244,
        high: 100245,
        low: 100246,
        numEst: 100247,
        stdDev: 100248,
        guideMid: 22327,
        guideHigh: 22395,
        guideLow: 22455,
      },
      {
        dataitemid: ["100249"],
        name: "% Change YoY",
        lineorder: 28,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100243"],
        median: ["100244"],
        high: ["100245"],
        low: ["100246"],
        numEst: ["100247"],
        stdDev: ["100248"],
        guideMid: ["22327"],
        guideHigh: ["22395"],
        guideLow: ["22455"],
      },
      {
        dataitemid: 100263,
        name: "Net Income (Excl. Excep & GW) Actual",
        lineorder: 29,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100257,
        median: 100258,
        high: 100259,
        low: 100260,
        numEst: 100261,
        stdDev: 100262,
        guideMid: 22331,
        guideHigh: 22399,
        guideLow: 22459,
      },
      {
        dataitemid: ["100263"],
        name: "% Change YoY",
        lineorder: 30,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100257"],
        median: ["100258"],
        high: ["100259"],
        low: ["100260"],
        numEst: ["100261"],
        stdDev: ["100262"],
        guideMid: ["22331"],
        guideHigh: ["22399"],
        guideLow: ["22459"],
      },
      {
        dataitemid: ["100263", "100186"],
        name: "% Net Income (Excl. Excep & GW) Margins",
        lineorder: 13,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100257", "100180"],
        median: ["100258", "100181"],
        high: ["100259", "100182"],
        low: ["100260", "100183"],
        numEst: ["100261", "100184"],
        stdDev: ["100262", "100185"],
        guideMid: ["22331", "22322"],
        guideHigh: ["22399", "22390"],
        guideLow: ["22459", "22450"],
      },
      {
        dataitemid: 100270,
        name: "Net Income (GAAP) ",
        lineorder: 31,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100264,
        median: 100265,
        high: 100266,
        low: 100267,
        numEst: 100268,
        stdDev: 100269,
        guideMid: 22330,
        guideHigh: 22398,
        guideLow: 22458,
      },
      {
        dataitemid: ["100270"],
        name: "% Change YoY",
        lineorder: 32,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100264"],
        median: ["100265"],
        high: ["100266"],
        low: ["100267"],
        numEst: ["100268"],
        stdDev: ["100269"],
        guideMid: ["22330"],
        guideHigh: ["22398"],
        guideLow: ["22458"],
      },
      {
        dataitemid: 105307,
        name: "Cash EPS ",
        lineorder: 33,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105302,
        median: 105303,
        high: 105304,
        low: 105305,
        numEst: 105308,
        stdDev: 105306,
        guideMid: 114234,
        guideHigh: 114233,
        guideLow: 114235,
      },
      {
        dataitemid: ["105307"],
        name: "% Change YoY",
        lineorder: 34,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105302"],
        median: ["105303"],
        high: ["105304"],
        low: ["105305"],
        numEst: ["105308"],
        stdDev: ["105306"],
        guideMid: ["114234"],
        guideHigh: ["114233"],
        guideLow: ["114235"],
      },
      {
        dataitemid: [""],
        name: "CF Supplementary Data:",
        lineorder: 35,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 104082,
        name: "Cash From Operations ",
        lineorder: 36,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104076,
        median: 104077,
        high: 104078,
        low: 104079,
        numEst: 104080,
        stdDev: 104081,
        guideMid: 22341,
        guideHigh: 22409,
        guideLow: 22469,
      },
      {
        dataitemid: ["104082"],
        name: "% Change YoY",
        lineorder: 37,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104076"],
        median: ["104077"],
        high: ["104078"],
        low: ["104079"],
        numEst: ["104080"],
        stdDev: ["104081"],
        guideMid: ["22341"],
        guideHigh: ["22409"],
        guideLow: ["22469"],
      },
      {
        dataitemid: 104096,
        name: "Capital Expenditure ",
        lineorder: 38,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104090,
        median: 104091,
        high: 104092,
        low: 104093,
        numEst: 104094,
        stdDev: 104095,
        guideMid: 22334,
        guideHigh: 22402,
        guideLow: 22462,
      },
      {
        dataitemid: ["104096"],
        name: "% Change YoY",
        lineorder: 39,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104090"],
        median: ["104091"],
        high: ["104092"],
        low: ["104093"],
        numEst: ["104094"],
        stdDev: ["104095"],
        guideMid: ["22334"],
        guideHigh: ["22402"],
        guideLow: ["22462"],
      },
      {
        dataitemid: 100207,
        name: "Cash Flow / Share ",
        lineorder: 42,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100201,
        median: 100202,
        high: 100203,
        low: 100204,
        numEst: 100205,
        stdDev: 100206,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["100207"],
        name: "% Change YoY",
        lineorder: 43,
        tikrdisplay: "out",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100201"],
        median: ["100202"],
        high: ["100203"],
        low: ["100204"],
        numEst: ["100205"],
        stdDev: ["100206"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: [""],
        name: "BS Supplementary Data:",
        lineorder: 44,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 104103,
        name: "Net Debt ",
        lineorder: 45,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104097,
        median: 104098,
        high: 104099,
        low: 104100,
        numEst: 104101,
        stdDev: 104102,
        guideMid: 22335,
        guideHigh: 22403,
        guideLow: 22463,
      },
      {
        dataitemid: ["104103", "100193"],
        name: "Net Debt/EBITDA",
        lineorder: 46,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["104097", "100187"],
        median: ["104098", "100188"],
        high: ["104099", "100189"],
        low: ["104100", "100190"],
        numEst: ["104101", "100191"],
        stdDev: ["104102", "100192"],
        guideMid: ["22335", "22323"],
        guideHigh: ["22403", "22391"],
        guideLow: ["22463", "22451"],
      },
      {
        dataitemid: 104061,
        name: "Book Value / Share ",
        lineorder: 47,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104055,
        median: 104056,
        high: 104057,
        low: 104058,
        numEst: 104059,
        stdDev: 104060,
        guideMid: 22319,
        guideHigh: 22387,
        guideLow: 22447,
      },
      {
        dataitemid: ["104061"],
        name: "% Change YoY",
        lineorder: 48,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104055"],
        median: ["104056"],
        high: ["104057"],
        low: ["104058"],
        numEst: ["104059"],
        stdDev: ["104060"],
        guideMid: ["22319"],
        guideHigh: ["22387"],
        guideLow: ["22447"],
      },
      {
        dataitemid: 104117,
        name: "Return on Equity (%)",
        lineorder: 52,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104111,
        median: 104112,
        high: 104113,
        low: 104114,
        numEst: 104115,
        stdDev: 104116,
        guideMid: 22338,
        guideHigh: 22406,
        guideLow: 22466,
      },
      {
        dataitemid: 104124,
        name: "Return on Assets (%)",
        lineorder: 53,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104118,
        median: 104119,
        high: 104120,
        low: 104121,
        numEst: 104122,
        stdDev: 104123,
        guideMid: 22339,
        guideHigh: 22407,
        guideLow: 22467,
      },
      {
        dataitemid: [""],
        name: "Other Supplementary Data:",
        lineorder: 54,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 105125,
        name: "Net Asset Value ",
        lineorder: 49,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105119,
        median: 105120,
        high: 105121,
        low: 105122,
        numEst: 105123,
        stdDev: 105124,
        guideMid: 22504,
        guideHigh: 22505,
        guideLow: 22506,
      },
      {
        dataitemid: 105321,
        name: "Net Asset Value / Share ",
        lineorder: 50,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105316,
        median: 105317,
        high: 105318,
        low: 105319,
        numEst: 105123,
        stdDev: 105320,
        guideMid: 22504,
        guideHigh: 22505,
        guideLow: 22506,
      },
      {
        dataitemid: ["105321"],
        name: "% Change YoY",
        lineorder: 51,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105316"],
        median: ["105317"],
        high: ["105318"],
        low: ["105319"],
        numEst: ["105123"],
        stdDev: ["105320"],
        guideMid: ["22504"],
        guideHigh: ["22505"],
        guideLow: ["22506"],
      },
      {
        dataitemid: 105284,
        name: "Price Target Mean ",
        lineorder: 55,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: null,
        median: null,
        high: null,
        low: null,
        numEst: null,
        stdDev: null,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 105075,
        name: "Distributable Cash (Income Trusts) ",
        lineorder: 56,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105069,
        median: 105070,
        high: 105071,
        low: 105072,
        numEst: 105073,
        stdDev: 105074,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["105075"],
        name: "% Change YoY",
        lineorder: 57,
        tikrdisplay: "out",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105069"],
        median: ["105070"],
        high: ["105071"],
        low: ["105072"],
        numEst: ["105073"],
        stdDev: ["105074"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 105082,
        name: "Distributable Cash / Share (Income Trust) ",
        lineorder: 58,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105076,
        median: 105077,
        high: 105078,
        low: 105079,
        numEst: 105080,
        stdDev: 105081,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["105082"],
        name: "% Change YoY",
        lineorder: 59,
        tikrdisplay: "out",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105076"],
        median: ["105077"],
        high: ["105078"],
        low: ["105079"],
        numEst: ["105080"],
        stdDev: ["105081"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 105114,
        name: "FFO (REIT) ",
        lineorder: 60,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105108,
        median: 105109,
        high: 105110,
        low: 105111,
        numEst: 105112,
        stdDev: 105113,
        guideMid: 22340,
        guideHigh: 22408,
        guideLow: 22468,
      },
      {
        dataitemid: ["105114"],
        name: "% Change YoY",
        lineorder: 61,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105108"],
        median: ["105109"],
        high: ["105110"],
        low: ["105111"],
        numEst: ["105112"],
        stdDev: ["105113"],
        guideMid: ["22340"],
        guideHigh: ["22408"],
        guideLow: ["22468"],
      },
      {
        dataitemid: 100200,
        name: "FFO / Share (REIT)",
        lineorder: 62,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100194,
        median: 100195,
        high: 100196,
        low: 100197,
        numEst: 100199,
        stdDev: 100198,
        guideMid: 22370,
        guideHigh: 22437,
        guideLow: 22497,
      },
      {
        dataitemid: ["100200"],
        name: "% Change YoY",
        lineorder: 63,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100194"],
        median: ["100195"],
        high: ["100196"],
        low: ["100197"],
        numEst: ["100199"],
        stdDev: ["100198"],
        guideMid: ["22370"],
        guideHigh: ["22437"],
        guideLow: ["22497"],
      },
      {
        dataitemid: 114154,
        name: "Adjusted FFO / Share (REIT) ",
        lineorder: 64,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114155,
        median: 114157,
        high: 114156,
        low: 114158,
        numEst: 114159,
        stdDev: 114160,
        guideMid: 114162,
        guideHigh: 114161,
        guideLow: 114163,
      },
      {
        dataitemid: ["114154"],
        name: "% Change YoY",
        lineorder: 65,
        tikrdisplay: "1",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114155"],
        median: ["114157"],
        high: ["114156"],
        low: ["114158"],
        numEst: ["114159"],
        stdDev: ["114160"],
        guideMid: ["114162"],
        guideHigh: ["114161"],
        guideLow: ["114163"],
      },
      {
        dataitemid: 105103,
        name: "Adjusted FFO (REIT) ",
        lineorder: 66,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105097,
        median: 105098,
        high: 105099,
        low: 105100,
        numEst: 105101,
        stdDev: 105102,
        guideMid: 22368,
        guideHigh: 22436,
        guideLow: 22496,
      },
      {
        dataitemid: ["105103"],
        name: "% Change YoY",
        lineorder: 67,
        tikrdisplay: "1",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105097"],
        median: ["105098"],
        high: ["105099"],
        low: ["105100"],
        numEst: ["105101"],
        stdDev: ["105102"],
        guideMid: ["22368"],
        guideHigh: ["22436"],
        guideLow: ["22496"],
      },
      {
        dataitemid: 114198,
        name: "Same Store Sales Growth (%)",
        lineorder: 68,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114199,
        median: 114201,
        high: 114200,
        low: 114202,
        numEst: 114203,
        stdDev: 114204,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115416,
        name: "Avg. Oil Production (MBBL/d)  ",
        lineorder: 69,
        tikrdisplay: "out",
        format: "normal pt-3",
        formula: "val",
        multiplyby: 1,
        mean: 115410,
        median: 115411,
        high: 115412,
        low: 115413,
        numEst: 115414,
        stdDev: 115415,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115468,
        name: "Avg. Gas Production (MMSCF/d)  ",
        lineorder: 70,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115462,
        median: 115463,
        high: 115464,
        low: 115465,
        numEst: 115466,
        stdDev: 115467,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115442,
        name: "Avg. NGL Production (MBBL/d)  ",
        lineorder: 71,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115436,
        median: 115437,
        high: 115438,
        low: 115439,
        numEst: 115440,
        stdDev: 115441,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115481,
        name: "Total Avg. Production (MBOE/d)",
        lineorder: 72,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 115475,
        median: 115476,
        high: 115477,
        low: 115478,
        numEst: 115479,
        stdDev: 115480,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["115481"],
        name: "% Change YoY",
        lineorder: 73,
        tikrdisplay: "out",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["115475"],
        median: ["115476"],
        high: ["115477"],
        low: ["115478"],
        numEst: ["115479"],
        stdDev: ["115480"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 115403,
        name: "Total Oil Production (MBBL) ",
        lineorder: 74,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115397,
        median: 115398,
        high: 115399,
        low: 115400,
        numEst: 115401,
        stdDev: 115402,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115455,
        name: "Total Gas Production (MMSCF)  ",
        lineorder: 75,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115449,
        median: 115450,
        high: 115451,
        low: 115452,
        numEst: 115453,
        stdDev: 115454,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115429,
        name: "Total NGL Production (MMBBL)",
        lineorder: 76,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115423,
        median: 115424,
        high: 115425,
        low: 115426,
        numEst: 115427,
        stdDev: 115428,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115591,
        name: "Total Production (MBOE)",
        lineorder: 77,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 115585,
        median: 115586,
        high: 115587,
        low: 115588,
        numEst: 115589,
        stdDev: 115590,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["115591"],
        name: "% Change YoY",
        lineorder: 78,
        tikrdisplay: "out",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["115585"],
        median: ["115586"],
        high: ["115587"],
        low: ["115588"],
        numEst: ["115589"],
        stdDev: ["115590"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 115494,
        name: "Oil Revenues",
        lineorder: 79,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115488,
        median: 115489,
        high: 115490,
        low: 115491,
        numEst: 115492,
        stdDev: 115493,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115520,
        name: "Gas Revenues",
        lineorder: 80,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115514,
        median: 115515,
        high: 115516,
        low: 115517,
        numEst: 115518,
        stdDev: 115519,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115507,
        name: "NGL Revenues ",
        lineorder: 81,
        tikrdisplay: "out",
        format: "normal pb-3",
        formula: "val",
        multiplyby: 1,
        mean: 115501,
        median: 115502,
        high: 115503,
        low: 115504,
        numEst: 115505,
        stdDev: 115506,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: 115533,
        name: "Exploration Expense ",
        lineorder: 82,
        tikrdisplay: "out",
        format: "normal ",
        formula: "val",
        multiplyby: 1,
        mean: 115527,
        median: 115528,
        high: 115529,
        low: 115530,
        numEst: 115531,
        stdDev: 115532,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["115533"],
        name: "% Change YoY",
        lineorder: 83,
        tikrdisplay: "out",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["115527"],
        median: ["115528"],
        high: ["115529"],
        low: ["115530"],
        numEst: ["115531"],
        stdDev: ["115532"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: [""],
        name: "Price Factors",
        lineorder: 84,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
    ],
    statement: "Actuals & Forward Estimates",
  },
  {
    keys: [
      {
        dataitemid: 100186,
        name: "Revenue ",
        lineorder: 0,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100180,
        median: 100181,
        high: 100182,
        low: 100183,
        numEst: 100184,
        stdDev: 100185,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["100186"],
        name: "% Change YoY",
        lineorder: 1,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100180"],
        median: ["100181"],
        high: ["100182"],
        low: ["100183"],
        numEst: ["100184"],
        stdDev: ["100185"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 100193,
        name: "EBITDA ",
        lineorder: 2,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100187,
        median: 100188,
        high: 100189,
        low: 100190,
        numEst: 100191,
        stdDev: 100192,
        guideMid: 22323,
        guideHigh: 22391,
        guideLow: 22451,
      },
      {
        dataitemid: ["100193"],
        name: "% Change YoY",
        lineorder: 3,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100187"],
        median: ["100188"],
        high: ["100189"],
        low: ["100190"],
        numEst: ["100191"],
        stdDev: ["100192"],
        guideMid: ["22323"],
        guideHigh: ["22391"],
        guideLow: ["22451"],
      },
      {
        dataitemid: ["100193", "100186"],
        name: "% EBITDA Margins",
        lineorder: 4,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100187", "100180"],
        median: ["100188", "100181"],
        high: ["100189", "100182"],
        low: ["100190", "100183"],
        numEst: ["100191", "100184"],
        stdDev: ["100192", "100185"],
        guideMid: ["22323", "22322"],
        guideHigh: ["22391", "22390"],
        guideLow: ["22451", "22450"],
      },
      {
        dataitemid: 100221,
        name: "EBIT ",
        lineorder: 5,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100215,
        median: 100216,
        high: 100217,
        low: 100218,
        numEst: 100219,
        stdDev: 100220,
        guideMid: 22325,
        guideHigh: 22393,
        guideLow: 22453,
      },
      {
        dataitemid: ["100221"],
        name: "% Change YoY",
        lineorder: 6,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100215"],
        median: ["100216"],
        high: ["100217"],
        low: ["100218"],
        numEst: ["100219"],
        stdDev: ["100220"],
        guideMid: ["22325"],
        guideHigh: ["22393"],
        guideLow: ["22453"],
      },
      {
        dataitemid: ["100221", "100186"],
        name: "% EBIT Margins",
        lineorder: 7,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100215", "100180"],
        median: ["100216", "100181"],
        high: ["100217", "100182"],
        low: ["100218", "100183"],
        numEst: ["100219", "100184"],
        stdDev: ["100220", "100185"],
        guideMid: ["22325", "22322"],
        guideHigh: ["22393", "22390"],
        guideLow: ["22453", "22450"],
      },
      {
        dataitemid: 100235,
        name: "EBT Normalized ",
        lineorder: 8,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100229,
        median: 100230,
        high: 100231,
        low: 100232,
        numEst: 100233,
        stdDev: 100234,
        guideMid: 22326,
        guideHigh: 22394,
        guideLow: 22454,
      },
      {
        dataitemid: ["100235"],
        name: "% Change YoY",
        lineorder: 9,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100229"],
        median: ["100230"],
        high: ["100231"],
        low: ["100232"],
        numEst: ["100233"],
        stdDev: ["100234"],
        guideMid: ["22326"],
        guideHigh: ["22394"],
        guideLow: ["22454"],
      },
      {
        dataitemid: ["100235", "100186"],
        name: "% EBT Margins",
        lineorder: 10,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100229", "100180"],
        median: ["100230", "100181"],
        high: ["100231", "100182"],
        low: ["100232", "100183"],
        numEst: ["100233", "100184"],
        stdDev: ["100234", "100185"],
        guideMid: ["22326", "22322"],
        guideHigh: ["22394", "22390"],
        guideLow: ["22454", "22450"],
      },
      {
        dataitemid: 100256,
        name: "Net Income Normalized ",
        lineorder: 11,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100250,
        median: 100251,
        high: 100252,
        low: 100253,
        numEst: 100254,
        stdDev: 100255,
        guideMid: 22329,
        guideHigh: 22397,
        guideLow: 22457,
      },
      {
        dataitemid: ["100256"],
        name: "% Change YoY",
        lineorder: 12,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100250"],
        median: ["100251"],
        high: ["100252"],
        low: ["100253"],
        numEst: ["100254"],
        stdDev: ["100255"],
        guideMid: ["22329"],
        guideHigh: ["22397"],
        guideLow: ["22457"],
      },
      {
        dataitemid: ["100256", "100186"],
        name: "% Net Income Margins",
        lineorder: 13,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100250", "100180"],
        median: ["100251", "100181"],
        high: ["100252", "100182"],
        low: ["100253", "100183"],
        numEst: ["100254", "100184"],
        stdDev: ["100255", "100185"],
        guideMid: ["22329", "22322"],
        guideHigh: ["22397", "22390"],
        guideLow: ["22457", "22450"],
      },
      {
        dataitemid: 100179,
        name: "EPS Normalized ",
        lineorder: 14,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100173,
        median: 100174,
        high: 100175,
        low: 100176,
        numEst: 100177,
        stdDev: 100178,
        guideMid: 22315,
        guideHigh: 22383,
        guideLow: 22443,
      },
      {
        dataitemid: ["100179"],
        name: "% Change YoY",
        lineorder: 15,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100173"],
        median: ["100174"],
        high: ["100175"],
        low: ["100176"],
        numEst: ["100177"],
        stdDev: ["100178"],
        guideMid: ["22315"],
        guideHigh: ["22383"],
        guideLow: ["22443"],
      },
      {
        dataitemid: 100284,
        name: "EPS (GAAP) ",
        lineorder: 16,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100278,
        median: 100279,
        high: 100280,
        low: 100281,
        numEst: 100282,
        stdDev: 100283,
        guideMid: 22316,
        guideHigh: 22384,
        guideLow: 22444,
      },
      {
        dataitemid: ["100284"],
        name: "% Change YoY",
        lineorder: 17,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100278"],
        median: ["100279"],
        high: ["100280"],
        low: ["100281"],
        numEst: ["100282"],
        stdDev: ["100283"],
        guideMid: ["22316"],
        guideHigh: ["22384"],
        guideLow: ["22444"],
      },
      {
        dataitemid: 114220,
        name: "Free Cash Flow ",
        lineorder: 40,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114221,
        median: 114223,
        high: 114222,
        low: 114224,
        numEst: 114225,
        stdDev: 114226,
        guideMid: 114228,
        guideHigh: 114227,
        guideLow: 114229,
      },
      {
        dataitemid: ["114220"],
        name: "% Change YoY",
        lineorder: 41,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114221"],
        median: ["114223"],
        high: ["114222"],
        low: ["114224"],
        numEst: ["114225"],
        stdDev: ["114226"],
        guideMid: ["114228"],
        guideHigh: ["114227"],
        guideLow: ["114229"],
      },
      {
        dataitemid: 100214,
        name: "Dividend Per Share",
        lineorder: 18,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100208,
        median: 100209,
        high: 100210,
        low: 100211,
        numEst: 100212,
        stdDev: 100213,
        guideMid: 22320,
        guideHigh: 22388,
        guideLow: 22448,
      },
      {
        dataitemid: ["100214"],
        name: "% Change YoY",
        lineorder: 19,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100208"],
        median: ["100209"],
        high: ["100210"],
        low: ["100211"],
        numEst: ["100212"],
        stdDev: ["100213"],
        guideMid: ["22320"],
        guideHigh: ["22388"],
        guideLow: ["22448"],
      },
      {
        dataitemid: [""],
        name: "IS Supplementary Data:",
        lineorder: 20,
        tikrdisplay: "1",
        format: "bold italic pb-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 105299,
        name: "Gross Margin (%)",
        lineorder: 21,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105293,
        median: 105294,
        high: 105295,
        low: 105296,
        numEst: 105297,
        stdDev: 105298,
        guideMid: 114231,
        guideHigh: 114230,
        guideLow: 114232,
      },
      {
        dataitemid: 114176,
        name: "Interest Expense ",
        lineorder: 22,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114177,
        median: 114179,
        high: 114178,
        low: 114180,
        numEst: 114181,
        stdDev: 114182,
        guideMid: 114184,
        guideHigh: 114183,
        guideLow: 114185,
      },
      {
        dataitemid: 114187,
        name: "Depreciation & Amortization ",
        lineorder: 23,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114188,
        median: 114190,
        high: 114189,
        low: 114191,
        numEst: 114192,
        stdDev: 114193,
        guideMid: 114195,
        guideHigh: 114194,
        guideLow: 114196,
      },
      {
        dataitemid: ["114187"],
        name: "% Change YoY",
        lineorder: 87,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114188"],
        median: ["114190"],
        high: ["114189"],
        low: ["114191"],
        numEst: ["114192"],
        stdDev: ["114193"],
        guideMid: ["114195"],
        guideHigh: ["114194"],
        guideLow: ["114196"],
      },
      {
        dataitemid: 114165,
        name: "Effective Tax Rate (%)",
        lineorder: 24,
        tikrdisplay: "1",
        format: "normal pb-3",
        formula: "val",
        multiplyby: 1,
        mean: 114166,
        median: 114168,
        high: 114167,
        low: 114169,
        numEst: 114170,
        stdDev: 114171,
        guideMid: 114173,
        guideHigh: 114172,
        guideLow: 114174,
      },
      {
        dataitemid: 100242,
        name: "EBT (Excl. Excep & GW) ",
        lineorder: 25,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100236,
        median: 100237,
        high: 100238,
        low: 100239,
        numEst: 100240,
        stdDev: 100241,
        guideMid: 22328,
        guideHigh: 22396,
        guideLow: 22456,
      },
      {
        dataitemid: ["100242"],
        name: "% Change YoY",
        lineorder: 26,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100236"],
        median: ["100237"],
        high: ["100238"],
        low: ["100239"],
        numEst: ["100240"],
        stdDev: ["100241"],
        guideMid: ["22328"],
        guideHigh: ["22396"],
        guideLow: ["22456"],
      },
      {
        dataitemid: 100249,
        name: "EBT (GAAP) Actual",
        lineorder: 27,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100243,
        median: 100244,
        high: 100245,
        low: 100246,
        numEst: 100247,
        stdDev: 100248,
        guideMid: 22327,
        guideHigh: 22395,
        guideLow: 22455,
      },
      {
        dataitemid: ["100249"],
        name: "% Change YoY",
        lineorder: 28,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100243"],
        median: ["100244"],
        high: ["100245"],
        low: ["100246"],
        numEst: ["100247"],
        stdDev: ["100248"],
        guideMid: ["22327"],
        guideHigh: ["22395"],
        guideLow: ["22455"],
      },
      {
        dataitemid: 100263,
        name: "Net Income (Excl. Excep & GW) Actual",
        lineorder: 29,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100257,
        median: 100258,
        high: 100259,
        low: 100260,
        numEst: 100261,
        stdDev: 100262,
        guideMid: 22331,
        guideHigh: 22399,
        guideLow: 22459,
      },
      {
        dataitemid: ["100263"],
        name: "% Change YoY",
        lineorder: 30,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100257"],
        median: ["100258"],
        high: ["100259"],
        low: ["100260"],
        numEst: ["100261"],
        stdDev: ["100262"],
        guideMid: ["22331"],
        guideHigh: ["22399"],
        guideLow: ["22459"],
      },
      {
        dataitemid: ["100263", "100186"],
        name: "% Net Income (Excl. Excep & GW) Margins",
        lineorder: 13,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["100257", "100180"],
        median: ["100258", "100181"],
        high: ["100259", "100182"],
        low: ["100260", "100183"],
        numEst: ["100261", "100184"],
        stdDev: ["100262", "100185"],
        guideMid: ["22331", "22322"],
        guideHigh: ["22399", "22390"],
        guideLow: ["22459", "22450"],
      },
      {
        dataitemid: 100270,
        name: "Net Income (GAAP) ",
        lineorder: 31,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100264,
        median: 100265,
        high: 100266,
        low: 100267,
        numEst: 100268,
        stdDev: 100269,
        guideMid: 22330,
        guideHigh: 22398,
        guideLow: 22458,
      },
      {
        dataitemid: ["100270"],
        name: "% Change YoY",
        lineorder: 32,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100264"],
        median: ["100265"],
        high: ["100266"],
        low: ["100267"],
        numEst: ["100268"],
        stdDev: ["100269"],
        guideMid: ["22330"],
        guideHigh: ["22398"],
        guideLow: ["22458"],
      },
      {
        dataitemid: 105307,
        name: "Cash EPS ",
        lineorder: 33,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105302,
        median: 105303,
        high: 105304,
        low: 105305,
        numEst: 105308,
        stdDev: 105306,
        guideMid: 114234,
        guideHigh: 114233,
        guideLow: 114235,
      },
      {
        dataitemid: ["105307"],
        name: "% Change YoY",
        lineorder: 34,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105302"],
        median: ["105303"],
        high: ["105304"],
        low: ["105305"],
        numEst: ["105308"],
        stdDev: ["105306"],
        guideMid: ["114234"],
        guideHigh: ["114233"],
        guideLow: ["114235"],
      },
      {
        dataitemid: [""],
        name: "CF Supplementary Data:",
        lineorder: 35,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 104082,
        name: "Cash From Operations ",
        lineorder: 36,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104076,
        median: 104077,
        high: 104078,
        low: 104079,
        numEst: 104080,
        stdDev: 104081,
        guideMid: 22341,
        guideHigh: 22409,
        guideLow: 22469,
      },
      {
        dataitemid: ["104082"],
        name: "% Change YoY",
        lineorder: 37,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104076"],
        median: ["104077"],
        high: ["104078"],
        low: ["104079"],
        numEst: ["104080"],
        stdDev: ["104081"],
        guideMid: ["22341"],
        guideHigh: ["22409"],
        guideLow: ["22469"],
      },
      {
        dataitemid: 104096,
        name: "Capital Expenditure ",
        lineorder: 38,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104090,
        median: 104091,
        high: 104092,
        low: 104093,
        numEst: 104094,
        stdDev: 104095,
        guideMid: 22334,
        guideHigh: 22402,
        guideLow: 22462,
      },
      {
        dataitemid: ["104096"],
        name: "% Change YoY",
        lineorder: 39,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104090"],
        median: ["104091"],
        high: ["104092"],
        low: ["104093"],
        numEst: ["104094"],
        stdDev: ["104095"],
        guideMid: ["22334"],
        guideHigh: ["22402"],
        guideLow: ["22462"],
      },
      {
        dataitemid: 100207,
        name: "Cash Flow / Share ",
        lineorder: 42,
        tikrdisplay: "out",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100201,
        median: 100202,
        high: 100203,
        low: 100204,
        numEst: 100205,
        stdDev: 100206,
        guideMid: 22322,
        guideHigh: 22390,
        guideLow: 22450,
      },
      {
        dataitemid: ["100207"],
        name: "% Change YoY",
        lineorder: 43,
        tikrdisplay: "out",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100201"],
        median: ["100202"],
        high: ["100203"],
        low: ["100204"],
        numEst: ["100205"],
        stdDev: ["100206"],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: [""],
        name: "BS Supplementary Data:",
        lineorder: 44,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: ["22322"],
        guideHigh: ["22390"],
        guideLow: ["22450"],
      },
      {
        dataitemid: 104103,
        name: "Net Debt ",
        lineorder: 45,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104097,
        median: 104098,
        high: 104099,
        low: 104100,
        numEst: 104101,
        stdDev: 104102,
        guideMid: 22335,
        guideHigh: 22403,
        guideLow: 22463,
      },
      {
        dataitemid: ["104103", "100193"],
        name: "Net Debt/EBITDA",
        lineorder: 46,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "div",
        multiplyby: 1,
        mean: ["104097", "100187"],
        median: ["104098", "100188"],
        high: ["104099", "100189"],
        low: ["104100", "100190"],
        numEst: ["104101", "100191"],
        stdDev: ["104102", "100192"],
        guideMid: ["22335", "22323"],
        guideHigh: ["22403", "22391"],
        guideLow: ["22463", "22451"],
      },
      {
        dataitemid: 104061,
        name: "Book Value / Share ",
        lineorder: 47,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104055,
        median: 104056,
        high: 104057,
        low: 104058,
        numEst: 104059,
        stdDev: 104060,
        guideMid: 22319,
        guideHigh: 22387,
        guideLow: 22447,
      },
      {
        dataitemid: ["104061"],
        name: "% Change YoY",
        lineorder: 48,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["104055"],
        median: ["104056"],
        high: ["104057"],
        low: ["104058"],
        numEst: ["104059"],
        stdDev: ["104060"],
        guideMid: ["22319"],
        guideHigh: ["22387"],
        guideLow: ["22447"],
      },
      {
        dataitemid: 104117,
        name: "Return on Equity (%)",
        lineorder: 52,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104111,
        median: 104112,
        high: 104113,
        low: 104114,
        numEst: 104115,
        stdDev: 104116,
        guideMid: 22338,
        guideHigh: 22406,
        guideLow: 22466,
      },
      {
        dataitemid: 104124,
        name: "Return on Assets (%)",
        lineorder: 53,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 104118,
        median: 104119,
        high: 104120,
        low: 104121,
        numEst: 104122,
        stdDev: 104123,
        guideMid: 22339,
        guideHigh: 22407,
        guideLow: 22467,
      },
      {
        dataitemid: [""],
        name: "Other Supplementary Data:",
        lineorder: 54,
        tikrdisplay: "1",
        format: "bold italic pb-3 pt-3",
        formula: "h3",
        multiplyby: 1,
        mean: [""],
        median: [""],
        high: [""],
        low: [""],
        numEst: [""],
        stdDev: [""],
        guideMid: [""],
        guideHigh: [""],
        guideLow: [""],
      },
      {
        dataitemid: 105125,
        name: "Net Asset Value ",
        lineorder: 49,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105119,
        median: 105120,
        high: 105121,
        low: 105122,
        numEst: 105123,
        stdDev: 105124,
        guideMid: 22504,
        guideHigh: 22505,
        guideLow: 22506,
      },
      {
        dataitemid: 105321,
        name: "Net Asset Value / Share ",
        lineorder: 50,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105316,
        median: 105317,
        high: 105318,
        low: 105319,
        numEst: 105123,
        stdDev: 105320,
        guideMid: 22504,
        guideHigh: 22505,
        guideLow: 22506,
      },
      {
        dataitemid: ["105321"],
        name: "% Change YoY",
        lineorder: 51,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105316"],
        median: ["105317"],
        high: ["105318"],
        low: ["105319"],
        numEst: ["105123"],
        stdDev: ["105320"],
        guideMid: ["22504"],
        guideHigh: ["22505"],
        guideLow: ["22506"],
      },
      {
        dataitemid: 105114,
        name: "FFO (REIT) ",
        lineorder: 60,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105108,
        median: 105109,
        high: 105110,
        low: 105111,
        numEst: 105112,
        stdDev: 105113,
        guideMid: 22340,
        guideHigh: 22408,
        guideLow: 22468,
      },
      {
        dataitemid: ["105114"],
        name: "% Change YoY",
        lineorder: 61,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105108"],
        median: ["105109"],
        high: ["105110"],
        low: ["105111"],
        numEst: ["105112"],
        stdDev: ["105113"],
        guideMid: ["22340"],
        guideHigh: ["22408"],
        guideLow: ["22468"],
      },
      {
        dataitemid: 100200,
        name: "FFO / Share (REIT)",
        lineorder: 62,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 100194,
        median: 100195,
        high: 100196,
        low: 100197,
        numEst: 100199,
        stdDev: 100198,
        guideMid: 22370,
        guideHigh: 22437,
        guideLow: 22497,
      },
      {
        dataitemid: ["100200"],
        name: "% Change YoY",
        lineorder: 63,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["100194"],
        median: ["100195"],
        high: ["100196"],
        low: ["100197"],
        numEst: ["100199"],
        stdDev: ["100198"],
        guideMid: ["22370"],
        guideHigh: ["22437"],
        guideLow: ["22497"],
      },
      {
        dataitemid: 114154,
        name: "Adjusted FFO / Share (REIT) ",
        lineorder: 64,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 114155,
        median: 114157,
        high: 114156,
        low: 114158,
        numEst: 114159,
        stdDev: 114160,
        guideMid: 114162,
        guideHigh: 114161,
        guideLow: 114163,
      },
      {
        dataitemid: ["114154"],
        name: "% Change YoY",
        lineorder: 65,
        tikrdisplay: "0",
        format: "italic",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["114155"],
        median: ["114157"],
        high: ["114156"],
        low: ["114158"],
        numEst: ["114159"],
        stdDev: ["114160"],
        guideMid: ["114162"],
        guideHigh: ["114161"],
        guideLow: ["114163"],
      },
      {
        dataitemid: 105103,
        name: "Adjusted FFO (REIT) ",
        lineorder: 66,
        tikrdisplay: "1",
        format: "normal",
        formula: "val",
        multiplyby: 1,
        mean: 105097,
        median: 105098,
        high: 105099,
        low: 105100,
        numEst: 105101,
        stdDev: 105102,
        guideMid: 22368,
        guideHigh: 22436,
        guideLow: 22496,
      },
      {
        dataitemid: ["105103"],
        name: "% Change YoY",
        lineorder: 67,
        tikrdisplay: "0",
        format: "italic pb-3",
        formula: "dxdt",
        multiplyby: 1,
        mean: ["105097"],
        median: ["105098"],
        high: ["105099"],
        low: ["105100"],
        numEst: ["105101"],
        stdDev: ["105102"],
        guideMid: ["22368"],
        guideHigh: ["22436"],
        guideLow: ["22496"],
      },
    ],
    statement: "Management Guidance",
  },
]

export { estimateTemplate, estDidToMetricKey }
