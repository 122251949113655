import customParseFormat from "dayjs/plugin/customParseFormat"
import dayjs from "~/utils/tools/dayjs"

dayjs.extend(customParseFormat)

const calculatePercentage = (num, pct) => {
  if ((!num && num !== 0) || (!pct && pct !== 0)) {
    return 0
  }
  if (Number.isNaN(num) || Number.isNaN(pct)) {
    return 0
  }
  const normalizedPct = pct * 100
  const value = (num * normalizedPct) / 100
  return value
}

const calculateDateDifference = (dateLabel, dpsValue) => {
  const today = dayjs().startOf("day")
  const date = dayjs(dateLabel, "DD/MM/YY")
  const differenceInDays = date.diff(today, "day")
  return differenceInDays / (365 * dpsValue)
}

const calculateIrr = (pricePctDiff, realizedAtDate, initialDate) => {
  const initialDateInst = dayjs(initialDate).startOf("day")
  const realizedAt = dayjs(realizedAtDate, "DD/MM/YY").startOf("day")
  const daysDiff = realizedAt.diff(initialDateInst, "day")
  const yearsDiff = daysDiff / 365
  if (yearsDiff === 0) {
    return
  }
  const base = 1 + pricePctDiff
  const exponent = 1 / yearsDiff
  return base ** exponent - 1
}

/**
 * Calculates the cumulative DPS for a specified time period.
 *
 * @param {number} dps - The total DPS for the year.
 * @param {string} dateToCompare - The end date to calculate the cumulative DPS for, in "DD/MM/YYYY" format.
 * @param {Date} [initialDate=new Date()] - The start date from which to calculate the cumulative DPS. Defaults to the current date.
 *
 * @returns {number} The cumulative DPS for the time period between `initialDate` and `dateToCompare`.
 */
const calculateCumulativeDps = (
  dps,
  dateToCompare,
  initialDate = new Date()
) => {
  const dpsPerDay = dps / 365
  const initial = dayjs(initialDate).startOf("day")
  const final = dayjs(dateToCompare, "MM/DD/YYYY").endOf("day")
  const differenceInDays = final.diff(initial, "day")
  return differenceInDays * dpsPerDay
}

export {
  calculatePercentage,
  calculateDateDifference,
  calculateIrr,
  calculateCumulativeDps,
}
