import { USE_CDK_TRKD } from "../feature-toggle"
import { tickersToFetch, transformWatchlistResponse } from "~/utils/ciq"

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const dev = devStage === stage

const restrictResponse = process.env.USE_TIERS === "true" // TODO: Change me to true for production

const SLS_CIQ_ALT_URL = process.env.CIQ_LAMBDA_URL
const SLS_CIQ_URL = process.env.NEW_CIQ_LAMBDA_URL
const CDK_URL = process.env.API_URL

const state = () => ({
  initialWatchlistFetched: false,
  fetchingCiqPrice: false,
  ciqPriceError: null,
  error: null,
  ciq: {},
  fetchingWatchlists: false,
  watchlistError: null,
  tickerError: null,
  activeWatchlist: {},
  watchlists: {},
  watchlistData: {},
  rkdids: {},
  fetchingQuotes: false,
  quotes: {},
  nextToken: null,
  activeWatchlistError: null,
  maxWatchlists: 15,
  maxTickers: 75,
  fetchingRkdIds: true,
  rkdIdsError: null,
  iexWatchlistInterval: null,
  iexTickers: [],
})

const mutations = {
  resetState(state) {
    state.fetchingCiqPrice = false
    state.fetchingWatchlists = false
    state.watchlistError = null
    state.ciqPriceError = null
    state.ciq = {}
    state.error = null
    state.activeWatchlist = {}
    state.watchlists = {}
    state.watchlistData = {}
    state.rkdids = {}
    state.fetchingQuotes = false
    state.quotes = {}
    state.nextToken = null
    state.addWatchlist = null
    state.tickerError = null
    state.activeWatchlistError = null
    state.fetchingRkdIds = true
    state.rkdIdsError = null
    state.iexWatchlistInterval = null
    state.iexTickers = []
  },
  clearIexWatchlistInterval(state) {
    if (state.iexWatchlistInterval) {
      console.log("clearing IEXWatchistInterval")
      clearInterval(state.iexWatchlistInterval)
    }
    state.iexTickers = []
  },
  setIexWatchlistInterval(state, interval) {
    if (state.iexWatchlistInterval) {
      clearInterval(state.iexWatchlistInterval)
    }
    state.iexWatchlistInterval = interval
  },
  addTickerToIEXArr(state, { tickersArr }) {
    state.iexTickers = state.iexTickers.concat(tickersArr)
  },
  clearError(state, payload) {
    if (payload) {
      state[payload.error] = null
    } else {
      state.watchlistError = null
      state.ciqPriceError = null
      state.tickerError = null
      state.activeWatchlistError = null
    }
  },
  setWatchlistError(state, payload) {
    state[payload.error] = payload.status
  },
  setIEXQuotes(state, { quotesArr, map }) {
    const newQuotes = quotesArr.reduce((acc, q) => {
      try {
        const tid = map[q.symbol].tid
        if (tid) {
          acc[tid] = q
        }
      } catch (error) {
        console.error("error setting iex quotes")
      }
      return acc
    }, {})
    state.quotes = { ...state.quotes, ...newQuotes }
  },
  updateIexQuotes(state, { topsArr, map }) {
    const newQuotes = topsArr.reduce((acc, top) => {
      try {
        const tid = map[top.symbol].tid
        if (tid) {
          const existingData = state.quotes[tid]
          if (existingData) {
            existingData.latestPrice = top.lastSalePrice
            existingData.latestUpdate = top.lastSaleTime
            existingData.latestSource = "IEX real time price"
            const previousClose = existingData.previousClose
            const updatedChange = top.lastSalePrice - previousClose
            existingData.change = updatedChange
            existingData.changePercent = updatedChange / previousClose
            // existingData.changePercent
            acc[tid] = existingData
          }
        }
      } catch (error) {
        console.error("error updating iex tops quotes")
      }
      return acc
    }, {})
    state.quotes = { ...state.quotes, ...newQuotes }
  },
  clearQuotes(state) {
    state.quotes = {}
  },
  addTicker(state, { ticker, wid }) {
    // I have to do this because the ticker is currently not diretly associated with the watchlist
    // otherwise I could pull the whole watchlist on return and just push that into the array?
    const oldWatchlist = state.watchlists[wid]
    oldWatchlist.tickers.push(ticker)
    const updatedWatchlist = {}
    updatedWatchlist[wid] = Object.assign({}, oldWatchlist)
    state.watchlists = Object.assign({}, state.watchlists, updatedWatchlist)
  },
  deleteTicker(state, { wid, tid }) {
    const oldWatchlist = state.watchlists[wid]
    oldWatchlist.tickers = oldWatchlist.tickers.filter((f) => f.tid !== tid) // this returns a new object
    const updatedWatchlist = {}
    updatedWatchlist[wid] = Object.assign({}, oldWatchlist)

    state.watchlists = Object.assign({}, state.watchlists, updatedWatchlist)
  },
  setWatchlists(state, { watchlists }) {
    // go through array and sort watchlists by
    const orderedWatchlists = watchlists
      .map((wl) => {
        wl.tickers = wl.tickers.sort((a, b) =>
          a.created >= b.created ? 1 : -1
        )
        return wl
      })
      .sort((a, b) => (a.created >= b.created ? 1 : -1))

    const watchlistObject = orderedWatchlists.reduce((acc, wl) => {
      acc[wl.id] = wl
      return acc
    }, {})
    const watchlistDataObject = orderedWatchlists.reduce((acc, wl) => {
      const dataObj = {}
      dataObj.headlines = {
        headlines: {},
        order: [],
        meta: {},
      }
      dataObj.transcripts = { list: [] }
      dataObj.filings = {
        filings: [],
        totalCount: 0,
      }
      dataObj.sigdevs = {
        list: [],
      }
      acc[wl.id] = dataObj
      return acc
    }, {})

    state.watchlistData = Object.assign(
      {},
      state.watchlistData,
      watchlistDataObject
    )
    state.watchlists = Object.assign({}, state.watchlists, watchlistObject)
  },
  setWatchlistData(state, payload) {
    const wid = payload.wid
    const watchlistData = state.watchlistData[wid]
    watchlistData[payload.key] = payload.value
    const newDataObj = {}
    newDataObj[wid] = watchlistData

    state.watchlistData = Object.assign({}, state.watchlistData, newDataObj)
  },
  updateWatchlist(state, { watchlist }) {
    const updatedWatchlist = {}
    updatedWatchlist[watchlist.id] = Object.assign(
      {},
      state.watchlists[watchlist.id],
      watchlist
    )
    state.watchlists = Object.assign({}, state.watchlists, updatedWatchlist)
  },
  deleteWatchlist(state, payload) {
    // payload.id = watchlist id to delete
    const updatedWatchlists = Object.assign({}, state.watchlists)
    delete updatedWatchlists[payload.wid]
    const updatedWatchlistData = Object.assign({}, state.watchlistData)
    delete updatedWatchlistData[payload.wid]
    state.watchlistData = updatedWatchlistData
    state.watchlists = updatedWatchlists
  },
  createWatchlist(state, { watchlist }) {
    const dataObj = {
      headlines: {
        headlines: {},
        order: [],
        meta: {},
      },
      transcripts: { list: [] },
      filings: {
        filings: [],
        totalCount: 0,
      },
      sigdevs: {
        list: [],
      },
    }
    const newDataObj = {}
    newDataObj[watchlist.id] = dataObj

    const newWatchlist = {}
    watchlist.tickers = []
    newWatchlist[watchlist.id] = watchlist

    state.watchlistData = Object.assign({}, state.watchlistData, newDataObj)
    state.watchlists = Object.assign({}, state.watchlists, newWatchlist)
  },
  setNextWatchlistToken(state, payload) {
    state.nextToken = payload.nextToken
  },
  setActiveWatchlist(state, { watchlist }) {
    state.activeWatchlist = Object.assign({}, watchlist)
  },
  updateActiveWatchlist(state, { watchlist }) {
    state.activeWatchlist = Object.assign({}, state.activeWatchlist, watchlist)
  },
  setWatchlistFetch(state, payload) {
    state[payload.fetch] = payload.status
  },
  setCiq(state, payload) {
    // okay.. so what is contained within these objects?
    state.ciq = Object.assign({}, state.ciq, payload.ciq)
  },

  setRkdIds(state, { idsToAdd }) {
    try {
      state.rkdids = Object.assign({}, state.rkdids, idsToAdd)
    } catch (error) {
      console.error("error adding idMap: ", error)
    }
  },
  removeCiq(state, payload) {
    payload.tidArr.forEach((e) => {
      delete state.ciq[e]
    })
    state.ciq = Object.assign({}, state.ciq)
  },
}

const actions = {
  async fetchCiqPrice({ state, commit, dispatch }, payload) {
    const t0 = performance.now()
    try {
      // stuff here
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      dispatch("fetchRkdIds", {
        tickers: payload.tickers.map((t) => {
          const [cid, tid] = t
          return {
            cid,
            tid,
          }
        }),
        user,
        addTicker: payload.addTicker,
      })
      const [tickers, toDelete] = tickersToFetch(payload.tickers, state.ciq)
      if (toDelete.length > 0) {
        // there are keys to delete from the state to show they're loading
        commit("removeCiq", { tidArr: toDelete })
      }
      // FIXME: check token is still valid... if there is no user need to error out
      if (tickers.length > 0) {
        const body = {
          auth: user.signInUserSession.idToken.jwtToken,
          tickers,
          v: restrictResponse ? "v1" : "v0",
        }
        commit("setWatchlistFetch", { fetch: "fetchingCiqPrice", status: true })
        // TODO: only fetch watchlist if its after a time the watchlist data has refreshed
        // ... opposed to all of the time

        // const WATCHLIST_PRICE_URL = USE_CDK_CIQ
        //   ? `${CDK_URL}/wlp`
        //   : `${SLS_CIQ_ALT_URL}/${stage}/wlp`

        const WATCHLIST_PRICE_URL = `${SLS_CIQ_ALT_URL}/${stage}/wlp`

        const res = await this.$axios.post(WATCHLIST_PRICE_URL, body)

        const ciq = transformWatchlistResponse(res.data, tickers)

        commit("setCiq", { ciq })
      }
    } catch (error) {
      console.error(error)
      commit("setWatchlistError", {
        status: {
          error,
          loc: "fetching watchlist CIQ data",
        },
        error: "ciqPriceError",
      })
    } finally {
      commit("setWatchlistFetch", { fetch: "fetchingCiqPrice", status: false })
      if (dev) {
        console.log(
          `fetch watchlist ciq price took ${performance.now() - t0} ms`
        )
      }
    }
  },
  async fetchRkdIds({ commit, state, dispatch }, { tickers, user, addTicker }) {
    const t0 = performance.now()
    try {
      // stuff here
      // if (payload.addTicker) {
      //   // set fetchingRkdIds to true to unmount the News / SigDevs / Filings / Transcripts
      //   // from DOM forcing them to rerender & run create again?
      //   // May also need to JSON.stringify the tickers to determine if something has changed?
      //   commit("setWatchlistFetch", { fetch: "fetchingRkdIds", status: true })
      // }
      // determine which rkdids have already been fetched & which need to be fetched
      const [tickersToFetch, existingRkdIds] = tickers.reduce(
        (acc, cidTidObj) => {
          const { tid, cid } = cidTidObj
          const rkdid = state.rkdids[tid]
          if (rkdid) {
            acc[1].push(rkdid)
          } else {
            acc[0].push({ tid, cid })
          }
          return acc
        },
        [[], []]
      )
      if (tickersToFetch.length > 0) {
        const body = {
          auth: user.signInUserSession.idToken.jwtToken,
          tids: tickersToFetch.map((obj) => obj.tid),
          v: "v1",
        }
        commit("setWatchlistFetch", { fetch: "fetchingRkdIds", status: true })

        const MANYTRKDIDS_URL = USE_CDK_TRKD
          ? `${CDK_URL}/manytrkdids`
          : `${SLS_CIQ_URL}/${stage}/manytrkdids`

        const res = await this.$axios.post(MANYTRKDIDS_URL, body)

        const idsToAdd = res.data.reduce((acc, res) => {
          acc[res.tid] = res
          return acc
        }, {})
        commit("setRkdIds", { idsToAdd })
        // THIS IS WHERE YOU WILL CALL IEX
        // dispatch("fetchQuotes", {
        //   fetchObjsArr: existingRkdIds.concat(res.data),
        //   addTicker,
        // })
        const correctSymbolArr = res.data.map((m) => {
          if (m.ExchangeCountry === "USA" && m.iex) {
            let symbol = m.TickerSymbol
            const iex = m.iex || []

            if (iex.length === 1) {
              // this is the happy path...
              // https://iexcloud.io/docs/api/#international-exchanges
              // checkout mic - market identifier code ISO 10383
              symbol = iex[0].symbol
            } else if (iex.length > 1) {
              const iexSymbolSet = new Set()
              iex.forEach((i) => {
                iexSymbolSet.add(i.symbol)
              })
              const uniqueIexSymbols = Array.from(iexSymbolSet)
              if (uniqueIexSymbols.length === 1) {
                symbol = uniqueIexSymbols[0]
              } else if (dev) {
                console.error("More than one iex symbol: ", uniqueIexSymbols)
              }
            }
            const cidFromArr = tickers.find(
              (i) => String(i.tid) === String(m.tid)
            )?.cid
            m.cid = Number(cidFromArr)
            m.symbol = symbol
          }
          return m
        })
        dispatch(
          "iex/fetchLastIEXQuotes",
          {
            tickerObjArr: existingRkdIds.concat(correctSymbolArr),
            intervalType: "Watchlist",
            stateKey: "lastQuotes",
            addTicker,
          },
          { root: true }
        )
      } else if (existingRkdIds.length > 0) {
        // refetch prices? but this means that prices already existed
        // do I want to be firing this over and over again?
        // this is where you will call IEX
        // dispatch("fetchQuotes", { fetchObjsArr: existingRkdIds, addTicker })
        dispatch(
          "iex/fetchLastIEXQuotes",
          {
            tickerObjArr: existingRkdIds,
            intervalType: "Watchlist",
            stateKey: "lastQuotes",
            addTicker,
          },
          { root: true }
        )
      }
    } catch (error) {
      console.error(error)
      // TODO: Change error type
      commit("setWatchlistError", {
        status: {
          error,
          loc: "fetching watchlist CIQ data",
        },
        error: "rkdIdsError",
      })
    } finally {
      // TODO: Change Error Type
      commit("setWatchlistFetch", { fetch: "fetchingRkdIds", status: false })
      if (dev) {
        console.log(`fetch RKD Id's took ${performance.now() - t0} ms`)
      }
    }
  },
  async getWatchlists({ commit, dispatch, state }) {
    try {
      commit("clearError")
      if (state.fetchingWatchlists) {
        // already fetching the watchlist, don't do it again!
        return
      }
      await this.$Amplify.Auth.currentAuthenticatedUser()
      commit("setWatchlistFetch", { fetch: "fetchingWatchlists", status: true })
      const { data } = await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(listWatchlists)
      )
      // TODO: Check for a nextToken
      const activeWid = data.listWatchlists.settings.activeWid
      const watchlists = data.listWatchlists.watchlists || []
      // TODO: If there are no watchlists, don't perform the rest
      // of the code below - instead create a default watchlist..?
      if (data.listWatchlists.nextToken && devStage === stage) {
        // TODO: Figure out how to alert me if this error occurs.
        // Ask the user to send support an email with some code..?
        // shouldn't you be reacting tot his first?
        const { data: data1 } = await this.$Amplify.API.graphql(
          this.$Amplify.graphqlOperation(listWatchlists, {
            nextToken: data.listWatchlists.nextToken,
          })
        )
        console.error("NEXT TOKEN FOR WATCHLIST FETCH")
        console.log("listWatchlists returned a nextToken!: ", data1)
        // FIXME: we'll figure this out soon enough...
      }

      commit("setWatchlists", { watchlists })
      commit("setWatchlistFetch", {
        fetch: "initialWatchlistFetched",
        status: true,
      })

      // move into the async function that calls setWatchlists?
      /// I need to create a default of activeWid
      if (activeWid !== "None") {
        const activeWatchlist = watchlists.filter((wl) => wl.id === activeWid)
        // this may be redundent because it isn't filter that is failing
        if (activeWatchlist && activeWatchlist.length === 1) {
          const activeList = activeWatchlist[0]
          if (state.activeWatchlist.id === activeList.id) {
            // updating the activewatchlist from the database
            commit("updateActiveWatchlist", { watchlist: activeList })
          } else {
            // setting the active watchlist from the database
            commit("setActiveWatchlist", { watchlist: activeList })
          }
        }
      } else if (
        watchlists.length === 1 &&
        state.activeWatchlist.id !== watchlists[0].id
      ) {
        // setting the active watchlist from the database
        dispatch("setActiveWatchlist", { watchlist: watchlists[0] })
      }

      const tids = {} // object to store tid's which have already been added to the symbol object
      const symbols = watchlists.reduce((acc, list) => {
        const watchlistSymbols = list.tickers.reduce((innerAcc, stock) => {
          // check if the tid has already been added to the symbolArray
          if (!tids[stock.tid] && stock.cid && stock.tid) {
            innerAcc.push([stock.cid, stock.tid])
            tids[stock.tid] = true
          }
          return innerAcc
        }, [])
        return [...watchlistSymbols, ...acc]
      }, [])
      // FIXME: only run this... sometimes
      if (symbols && symbols.length > 0) {
        // FIXME: don't get all data for tickers.
        // dispatch("fetchCiqPrice", { tickers: symbols })
      }
    } catch (error) {
      console.error(error)
      commit("setWatchlistError", {
        error: "watchlistError",
        status: {
          error,
          loc: "getting watchlists - watchlist/getwatchList",
        },
      })
    } finally {
      commit("setWatchlistFetch", {
        fetch: "fetchingWatchlists",
        status: false,
      })
    }
  },
  async updateWatchlist({ commit, state }, payload) {
    // Watch List Should Exist
    try {
      commit("clearError")
      // this input is { id: ID!, title: String, description: String, icon: String }
      const input = { ...payload }
      const { data } = await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(updateWatchlist, { input })
      )

      commit("updateWatchlist", { watchlist: data.updateWatchlist })
      // move into the async function that calls updateWatchlist?
      if (state.activeWatchlist.id === data.updatedWatchlist.id) {
        commit("updateActiveWatchlist", { watchlist: data.updateWatchlist })
      }
    } catch (error) {
      commit("setWatchlistError", {
        error: "watchlistError",
        status: {
          error,
          loc: "update watchlists - watchlist/updateWatchlist",
        },
      })
    }
  },
  async updateWatchlistColumns({ commit }, payload) {
    try {
      commit("clearError")
      if (payload.columns && payload.columns.length === 0) {
        payload.columns.push({
          text: "Ticker",
          value: "ts",
          columntype: "ts",
          align: "center",
        })
      }
      const input = { ...payload }

      commit("updateWatchlist", { watchlist: input })

      await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(updateWatchlist, { input })
      )
    } catch (error) {
      commit("setWatchlistError", {
        error: "watchlistError",
        status: {
          error,
          loc: "update watchlists - watchlist/updateWatchlistColumns",
        },
      })
    }
  },
  async deleteWatchlist({ commit, state, dispatch }, payload) {
    try {
      commit("clearError")
      const tickers = state.watchlists[payload.wid].tickers.map((t) => t.tid)
      const chunkSize = 24
      if (tickers.length <= chunkSize) {
        const input = { wid: payload.wid, tickers, tickerOnly: false }

        await this.$Amplify.API.graphql(
          this.$Amplify.graphqlOperation(deleteWatchlist, { input })
        )
      } else {
        // split array greater than 24 tickers into arrays of 24 - 25 tickers

        const tickersToDelete = []

        for (let i = 0; i < tickers.length; i += chunkSize) {
          const chunk = tickers.slice(i, i + chunkSize)
          tickersToDelete.push(chunk)
        }

        const inputsToDelete = tickersToDelete.map((t, i) => {
          const input = { wid: payload.wid }
          input.tickerOnly = i !== 0
          input.tickers = t
          return input
        })

        inputsToDelete.forEach(async (input) => {
          await this.$Amplify.API.graphql(
            this.$Amplify.graphqlOperation(deleteWatchlist, { input })
          )
        })
      }

      commit("deleteWatchlist", { wid: payload.wid }) // data.deleteWatchlist.id

      if (state.activeWatchlist.id === payload.wid) {
        dispatch("setActiveWatchlist", { watchlist: {} })
      }
    } catch (error) {
      console.log("delete watchlist error: ", error)
      commit("setWatchlistError", {
        error: "watchlistError",
        status: {
          error,
          loc: "delete watchlists - watchlist/deleteWatchList",
        },
      })
    }
  },
  async createWatchlist({ commit }, payload) {
    // Watch List Should Exist
    try {
      commit("clearError")
      const input = payload.list
      const { data } = await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(addWatchlist, { input })
      )
      const watchlist = data.addWatchlist
      commit("createWatchlist", { watchlist })
    } catch (error) {
      console.error("create watchlist error: ", error)
      commit("setWatchlistError", {
        error: "watchlistError",
        status: {
          error,
          loc: "watchlist/createWatchlist",
        },
      })
    }
  },
  async addTicker({ commit, dispatch }, { wid, ticker }) {
    // Watch List Should Exist
    try {
      commit("clearError", { error: "tickerError" })
      // payload should have the watchlist id and the ticker to add
      // FIXME: this will need to be changed when the underlying ticker
      // data changes

      // freak this is information that isn't present... I can add it?
      // here we go we are already changing the search bar interface
      const input = {
        wid,
        ts: ticker.tickersymbol || ticker.ts || ticker.ticker,
        cid: ticker.companyid || ticker.cid,
        tid: ticker.tradingitemid || ticker.tid,
        name: ticker.companyname || ticker.name,
        // ex: ticker.exchangesymbol, // FIXME: Do I need exchangesymbol and excurrid?
      }

      const { data } = await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(addTicker, { input })
      )
      commit("addTicker", {
        ticker: data.addTicker,
        wid,
      })
      dispatch("fetchCiqPrice", {
        tickers: [[data.addTicker.cid, data.addTicker.tid]],
        addTicker: true,
      })
    } catch (error) {
      console.error(error)
      commit("setWatchlistError", {
        error: "tickerError",
        status: {
          error,
          loc: "add ticker gql call",
        },
      })
    }
  },
  async deleteTicker({ commit, dispatch }, { tid, wid }) {
    // Watch List Should Exist
    try {
      commit("clearError")
      // payload should have the watchlist id and the ticker to add
      // FIXME: this will need to be changed when the underlying ticker
      // data changes
      const input = { wid, tid }
      // this should be a watchlist, would be easier to update..
      //   tid: data.deleteTicker.tid
      // const { data } = await this.$client.defaultClient.mutate({
      //   mutation: deleteTicker,
      //   variables: { input }
      // })
      // console.log("delete successful for: ", data)
      const { data } = await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(deleteTicker, { input })
      )
      commit("deleteTicker", {
        wid,
        tid: data.deleteTicker.tid,
      })
      // run fetchRkdIds to toggle state.fetchingRkdIds from false to true
      // but don't actually fetch anything - goal to make the components mount and remount
      // TODO: you really need to improve this logic
      dispatch("fetchRkdIds", {
        tickers: [],
        addTicker: true,
      })
    } catch (error) {
      console.error(error)
      commit("setWatchlistError", {
        error: "tickerError",
        status: {
          loc: "delete ticker = watchlist/deleteTicker",
          error,
        },
      })
    }
  },
  async setActiveWatchlist({ commit }, { watchlist }) {
    // the payload is going to be a watchlist object
    // I will need to pluck the id off of the watchlist object
    // and perform the mutation then set the state
    try {
      const input = { activeWid: watchlist.id } // for the delete this will be undefined
      await this.$Amplify.API.graphql(
        this.$Amplify.graphqlOperation(updateWatchlistSettings, { input })
      )
      commit("setActiveWatchlist", { watchlist })
    } catch (error) {
      console.error(error)
      commit("setWatchlistError", {
        error: "activeWatchlistError",
        status: {
          loc: "setActiveWatchlist",
          error,
        },
      })
    }
  },
}

export { state, mutations, actions }

const addTicker = `
  mutation addTicker($input: TickerAddInput!) {
    addTicker(input: $input) {
      ts
      cid
      tid
      name
      ex
      exCurrId
    }
  }
`
const deleteTicker = `
  mutation deleteTicker($input: TickerDeleteInput!) {
    deleteTicker(input: $input) {
      ts
      cid
      tid
      name
      ex
      exCurrId
    }
  }
`

const addWatchlist = `
  mutation addWatchlist($input: WatchlistAddInput!) {
    addWatchlist(input: $input) {
      id
      title
      description
      public
      created
      updated
    }
  }
`

const deleteWatchlist = `
  mutation deleteWatchlist($input: WatchlistDeleteInput!) {
    deleteWatchlist(input: $input) {
      id
    }
  }
`

const updateWatchlist = `
  mutation updateWatchlist($input: WatchlistUpdateInput!) {
    updateWatchlist(input: $input) {
      id
      title
      description
      columns {
        text
        header
        value
        columntype
        width
        align
        num
        denom
        did
      }
      icon
      public
      created
      updated
    }
  }
`

const listWatchlists = `
  query listWatchlists {
    listWatchlists {
      settings {
        activeWid
        updated
      }
      watchlists {
        id
        title
        description
        tickers {
          ts
          cid
          tid
          name
          ex
          exCurrId
          pTrId
          pTrOA
          qTrId
          qTrOA
          iexId
          avId
          updated
          created
        }
        columns {
          text
          header
          value
          columntype
          width
          align
          num
          denom
          did
          }
          icon
          created
          updated
      }
      nextToken
    }
  }
`

const updateWatchlistSettings = `
mutation updateWatchlistSettings($input: WatchlistSettingsInput!) {
  updateWatchlistSettings(input: $input){
    activeWid
    updated
  }
}`
