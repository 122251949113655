module.exports = {
  "Refer Friends To TIKR.com": "友達にTIKR.comを紹介する",
  "You can now provide friends immediate access to TIKR.com with your personal referral link below.":
    "以下のリンクから、TIKR.comにアクセスしていただけます。",
  "As always, please reach out to <a href='mailto:support@tikr.com'>support@tikr.com</a> if you have any feedback or questions":
    "ご意見やご質問は、<a href='mailto:support@tikr.com'>support@tikr.com</a>までご連絡ください。",

  "Market Overview": "市況・概況",
  "Idea Generation": "株式のアイデアを見つける",
  "My Watchlists": "ウォッチリスト",
  "Track Investing Gurus": "著名投資家をトラッキング",

  "Company Overview": "会社概要",
  "Company News": "企業ニュース",
  "Detailed Financials": "財務情報",
  Valuation: "時価総額",
  "Analyst Estimates": "アナリスト予測",
  "Call Transcripts": "業績発表書き起こし",
  "Company Filings": "会社業績報告",
  Ownership: "所有権",
  "Account Settings": "アカウント設定",
  "Sign Out": "サインアウト",
  "Search Tickers...": "銘柄を検索",

  "TIKR Terminal": "TIKRターミナル",
  "Support TIKR Beta Promo": "TIKRベータ版のプロモをサポート",
  "Curated Stock Baskets": "厳選の銘柄",
  "Discussion Board": "ディスカッションボード",
  Screener: "スクリーナー",
  Search: "検索",
  "Fundamental Analysis": "基本な分析",
  "Public Filings": "業績報告",
  "App Config": "アプリ設定",
  "TIKR Settings": "TIKR設定",
  Settings: "設定",
  "New Version of TIKR Available": "TIKRの新しいバージョンが利用可能",
  "Reload Page": "ページをリロード",
  "We've updated our ": "が更新された",
  "Terms of Service": "利用規約",
  " and ": "と",
  "Accept Terms": "利用規約に同意",
  "Watchlist:": "ウォッチリスト:",
  "Active Watchlist:": "アクティブなウォッチリスト:",
  "Use top search bar to select a ticker to view":
    "上部の検索バーを使用して、表示するティッカーを選択してください",
  "(e.g. NFLX)": "(例:NFLX)",

  "Add Ticker": "銘柄を追加",
  "Watchlist Settings": "ウォッチリスト設定",
  "Add Watchlist": "ウォッチリストを追加",
  "Watchlist Columns": "ウォッチリストの列",
  "Active Watchlist": "アクティブなウォッチリスト",
  "Set As Active Watchlist": "アクティブなウォッチリストに設定",
  "Delete Watchlist": "ウォッチリストを削除する",
  "Or Click on heart next to company name to add Ticker to active Watchlist":
    "または、会社名の隣のハートをクリックして、アクティブなウォッチリストに銘柄を追加する",
  "Watchlist Title": "ウォッチリストのタイトル",
  "Watchlist Description": "ウォッチリストの説明",
  Actions: "アクション",

  "Upcoming Events": "今後のイベント",
  "Select a watchlist title above to view the watchlist":
    "ウォッチリストを表示するには、上のウォッチリストのタイトルを選択してください",
  Or: "または",
  "Click 'Add Watchlist' to create a new watchlist":
    "[ウォッチリストの追加]をクリックして、新しいウォッチリストを作成",
  "Delete Ticker": "銘柄を削除",
  "Delete List": "リストを削除",
  "Last Price": "直近終値",
  "% Change": "％ 変化",
  Name: "名前",

  "NTM TEV/REV": "未来12ヶ月 総企業価値/収益",
  "NTM TEV/EBITDA": "未来12ヶ月 総企業価値/EBITDA",
  "NTM TEV/EBIT": "未来12ヶ月 総企業価値/EBIT",
  "NTM P/E": "未来12ヶ月 株価収益率",
  "NTM MC/FCF": "未来12ヶ月 時価総額/フリーキャッシュフロー",
  "NTM P/CFPS": "未来12ヶ月 価格/ 1株当たりキャッシュフロー",
  "NTM P/FFO": "未來12ヶ月 P/FFO",
  "NTM P/AFFO": "未來12ヶ月 P/AFFO",
  "Mean Analyst Target": "アナリストの平均目標価格",
  "Mean Analyst NAV": "アナリストの平均目標純資産価値",
  "LTM TEV/REV": "過去12ヶ月 総企業価値/収益",
  "LTM TEV/EBITDA": "過去12ヶ月 総企業価値/EBITDA",
  "LTM TEV/EBIT": "過去12ヶ月 総企業価値/EBIT",
  "LTM P/Diluted EPS before Extra":
    "過去12ヶ月 株価/特別損益前の希薄化1株あたり利益",
  "LTM P/BV": "過去12ヶ月 価格/ 1株当たり簿価",
  "LTM P/Tangible BV": "過去12ヶ月 価格/ 1株当たりの有形簿価",
  "LTM TEV/Unlevered FCF":
    "過去12ヶ月 総企業価値/アンレバードフリーキャッシュフロー",
  "LTM MC/Levered FCF": "過去12ヶ月 時価総額/レバードフリーキャッシュフロー",
  "Quote Currency": "株価通貨",
  "Quote Time": "株価時間",
  "Or Click on": "または",
  "next to company name to add Ticker to active Watchlist":
    "会社名の隣の❤️をクリックしてウォッチリストに追加してください",
  Watchlist: "ウォッチリスト",
  "Are you sure you want to delete this watchlist?":
    "このウォッチリストを削除してもよろしいですか？",
  "Error: Watchlist must have a title":
    "エラー: ウォッチリストのタイトルが必要",
  "Error: watchlist is full - cannot add more than":
    "エラー: ウォッチリストがいっぱい-これ以上追加することはできない",
  "Please Create a new watchlist": "新しいウォッチリストを作成してください",
  "from exchange": "株式市場",
  "already exists on this watchlist!":
    "このウォッチリストにはすでに存在している！",
  "Error: You've reached your watchlist limit (max":
    "エラー: ウォッチリストの上限に達した(最大",
  "Please delete another watchlist and try again":
    "別のウォッチリストを削除して、再試行してください",
  "There has been an error - please try again or contact support":
    "エラーが発生した - 再試行するか、サポートに連絡してください",
  "New Watchlist Title": "新しいウォッチリストのタイトル",
  "This is the title for your new Watchlist (can change later)":
    "これは新しいウォッチリストのタイトル(後で変更可能)",
  "Description of what watchlist will contain":
    "ウォッチリストに含まれる内容の説明",
  "Describe what this watchlist contains (can change later)":
    "ウォッチリストに含まれる内容の説明(後で変更可能)",
  "Select Stock Tickers": "銘柄を選択",
  Back: "戻る",
  Next: "次へ",
  "Create Watchlist": "ウォッチリストを作成",
  "Create New Watchlist": "新しいウォッチリストを作成",
  "Add Stocks to": "銘柄を追加",
  "Please create an active watchlist in “My Watchlists” before adding a favorite ticker!":
    "お気に入りの銘柄を追加する前に、「マイウォッチリスト」でアクティブなウォッチリストを作成してください！",

  "If you want to add any other funds to these lists let us know at <a href='mailto:support@tikr.com'>support@tikr.com</a>":
    "これらのリストに他のファンドを追加したい場合は、<a href='mailto:support@tikr.com'>support@tikr.com</a>までお知らせください。",
  "Search Investing Gurus": "著名投資家を検索",
  "Growth at a Reasonable Price (GARP)": "GARP型",
  Value: "バリュー型",
  "Aggressive Growth": "グロース型",
  Other: "その他",
  "Investor / Fund Name": "投資家/ファンド名",
  "Rows per page": "ページあたりの行数",
  Terms: "条項",
  Privacy: "プライバシー",

  Zoom: "期間",
  "October 23, 2020 Close": "2020年10月23日締め",
  "57% price return over 3 years - 16% CAGR (Compound Annual Growth Rate)":
    "3年間で57％の価格リターン -  CAGR 16%（年平均成長率）",
  "Add Tickers to Chart...": "チャートに銘柄を追加",
  "Show Relative Change %": "相対変化率を表示",
  "52 WEEK HIGH": "52週高値",
  "52 WEEK LOW": "52週安値",
  "AVERAGE 3 MONTH DAILY VOLUME": "3ヶ月間の平均ボリューム",
  "SHARES OUTSTANDING": "発行済株式数",
  "Short Interest %": "空売り比率",
  "MARKET CAP (MM)": "時価総額",
  "TEV (MM)": "総潜在価値",
  "Show Price Chart": "チャートを表示",

  "Price Chart Hidden": "価格チャート非表示",
  Overview: "概要",
  News: "ニュース",
  Financials: "財務",
  Estimates: "予測",
  Consensus: "コンセンサス",
  Transcripts: "業績発表書き起こし",
  Filings: "会社業績報告",
  Discuss: "議論",
  Close: "終値",
  " Close": "終値",
  "Close Window": "閉じる",
  "Previous close": "前日終値",
  "IEX real time price": "IEXリアルタイム価格",
  "15 minute delayed price": "15分遅延の価格",
  "Business Owner Mode Activated - All Stock Prices Hidden":
    "事業主モード-すべての株価が非表示",
  "# of No Opinions": "意見なしアナリストの数",
  "NTM = Next Twelve Months &nbsp;&nbsp;&nbsp; LTM = Last Twelve Months":
    "NTM =次の12か月＆nbsp;＆nbsp;＆nbsp; LTM =過去12か月",
  "Total Operating Expenses": "総営業費用",
  "Restructuring Charges": "再建費用",

  "Trust Income": "信託収入",
  "Income From Trading Activities": "取引活動収入",
  "Gain (Loss) on Sale of Invest. & Securities":
    "投資と証券の売却による利益(損失)",
  "Total Other Non Interest Income": "他の無利息収入の合計",
  "Occupancy Expense": "入居費",
  "Depreciation & Amortization - (IS) - (Collected)": "減価償却費",
  "Allowance For Equity Funds Used During Construction":
    "建設中に使用される株式資金の引当金",
  "Allowance For Borrowed Funds Used During Construction":
    "建設中に使用された借入金の引当金",
  "Depreciation, Depletion & Amortization": "減価償却費、減耗償却費、及び償却",
  "Total Asset Writedown": "総資産評価減",
  "Restructuring Activities": "再建活動",
  "Purchase / Sale of Intangible Assets": "無形資産の購入/売却",
  "Sale of Property, Plant and Equipment": "有形固定資産の売却",
  "Net Increase (Decrease) in Deposit Accounts": "預金口座の純増(減少)",
  "Regulatory Assets": "規制資産",
  "Net Loans": "純借入",
  "Other Liabilities": "その他の負債",
  "Total Other Investments": "その他の投資合計",
  "Total investments": "総投資額",
  "Investment In Debt and Equity Securities": "債券および株式への投資",

  "N/A": "N/A",
  "There was an error fetching price quotes and market cap data":
    "株価と時価総額のデータ取得時にエラーが発生",

  "Error Fetching Quotes": "数字取得失敗",
  Show: "表示",
  Hide: "隠す",
  Data: "データ",
  "Hide OHLC": "OHLCを隠す",
  "Show OHLC": "OHLCを表示",
  "Show Absolute Value": "絶対値を表示",
  "1d": "1日",
  "3m": "3か月",
  "6m": "6か月",
  ytd: "YTD",
  "1yr": "1年",
  "3yr": "3年",
  "5yr": "5年",
  "10yr": "10年",
  "price return over": "株収益率",
  Volume: "ボリューム",
  years: "年",

  "LTM (Last Twelve Months)": "過去12ヶ月",
  "* Annual Financials in Millions of US Dollar from 12/31/12 to 6/30/20":
    "* 12/31/12から6/30/20までの年間財務 (百万米ドル)",
  "Income Statement": "損益計算書",
  "Balance Sheet": "バランスシート",
  "Cash Flow Statement": "キャッシュフロー計算書",
  Ratios: "比率",
  "Chart Data": "チャートデータ",
  "Clear Chart": "チャートをクリア",
  "Show Labels": "ラベルを表示",
  "Hide Chart": "チャートを隠す",

  "Error Fetching Data": "データ取得時にエラーが発生",
  "Select Metric": "指標を選択",
  "Select Line Item": "ライン項目を選択",
  "Display Currency:": "表示通貨:",
  "Display Units:": "表示単位:",
  "Decimals to Display:": "小数表示桁数:",
  "Reverse Dates": "日付を逆にする",
  "Copy Table": "テーブルをコピー",
  "Table Options": "テーブルオプション",
  Annual: "年度",
  Quarterly: "四半期",
  "Semi Annual": "半年",
  LTM: "LTM",
  "Displayed in thousands": "表示単位千",
  k: "k",
  "Displayed in millions": "表示単位百万",
  MM: "MM",
  "Displayed in billions": "表示単位十億",
  B: "B",
  "Financials in": "財務表示単位",
  Thousands: "千",
  Millions: "百万",
  Billions: "十億",
  of: "の",
  "[currencyName]": "[currencyName]",
  from: "から",
  to: "に",
  percent: "パーセント",
  "Copy Table to Clipboard": "テーブルをクリップボードにコピーする",
  CAGR: "年平均成長率",
  "Sorry, we have no financial data for": "申し訳ないが、財務データはない",

  "for this company which can lead to differences in per share metrics.":
    "この会社の場合、1株あたりの指標に違いが生じる可能性があります。",

  "Actuals & Forward Estimates": "実績と予測",
  E: "E",
  A: "A",

  "Street Targets": "分析予測",
  "Price Close": "価格終値",
  "Street Price Targets": "ウォール街の価格目標",
  "Target Stock Price Mean": "目標株価平均",
  "Target Stock Price Median": "目標株価中央値",
  "Target Stock Price High": "目標株価高",
  "Target Stock Price Low": "目標株価低",
  "Target Stock Price (# Est)": "目標株価 (# 推定 )",
  "Street Recommendations": "アナリスト推奨",
  "# of Buys": "購入数",
  "# of Outperforms": "アウトパフォーム数",
  "# of Holds": "保留数",
  "# of Underperforms": "アンダーパフォーム数",
  "# of Sells": "販売数",

  "Street Estimate": "ウォール街の推定",
  "Net Asset Value / Share Mean": "純資産価値/株式平均",
  "Net Asset Value / Share Median": "純資産価値/株式中央値",
  "Net Asset Value / Share High": "純資産価値/高シェア",
  "Net Asset Value / Share Low": "純資産価値/低シェア",
  "Net Asset Value / Share (# Est)": "純資産価値/シェア（＃推定）",
  "Target Stock Price / Close Price": "目標株価/終値",
  "Price / NAV per Share": "価格/ 1株当たりNAV",

  "Summary Statistics": "統計概要",
  Mean: "平均値",
  Median: "中央値",
  High: "高",
  Low: "低",
  "Standard Deviation": "標準偏差",
  Count: "カウント",
  "Convert to USD: False (True)": "米ドルで表示: はい(いいえ)",
  "Show Summary Statistics Below Competitors": "統計概要を表示: はい(いいえ)",

  "Next Twelve Months": "次の12か月",
  "Last Twelve Months": "過去12か月",
  Event: "イベント",
  "Event Date": "イベント開催日",
  "Q2 2020 Earnings Call, Aug 05, 2020": "Q2 2020年の収支報告は2020年8月5日",
  "Document Title": "ドキュメント名",
  "Form Name": "フォーム名",
  Language: "言語",
  "Financial Period End Date": "会計期間終了日",
  "Release Date": "リリース日",

  "Error fetching filings": "業績報告取得時にエラーが発生",
  "Document will open in a new tab": "文書を新しいタブで開く",
  "Please make sure popups are not blocked for tikr.com":
    "tikr.comのポップアップがブロックされていないことを確認してください",
  "Fetching Document!": "文書取得中！",
  "Filter By Form Name": "フォーム名でフィルタリング",

  Shareholders: "株主",
  "Insider Transactions": "インサイダー取引",
  "Filter by Investor Type": "投資家種別でフィルターをかける",
  "Price Used to Value Shares Held:": "保有株式の評価に使用される価格：",
  "Holdings Date": "保有日",
  "Last Close": "前日終値",
  "Investor Name": "投資家名",
  "Investor Type": "投資家種別",
  "Investment Advisor": "投資顧問",
  "Investment Advisor/Hedge Fund": "投資顧問/ヘッジファンド",
  "Pension Fund": "年金基金",
  "Research Firm": "調査会社",
  "Hedge Fund": "ヘッジファンド",
  "Individual Investor": "個人投資家",
  "Bank and Trust": "銀行と信託",
  "Sovereign Wealth Fund": "政府系ファンド",
  Foundation: "財団",

  Insider: "インサイダー",
  "Number of Shares": "株式数",
  Price: "価格",
  Date: "日付",
  "Number of Shares Outstanding Traded": "発行済株式数",
  "Insider Title": "インサイダータイトル",
  "Chief Executive Officer": "最高経営責任者",
  Officer: "役員",
  Unknown: "不明",
  "Chief Financial Officer": "最高財務責任者",
  "Executive Vice President": "エグゼクティブバイスプレジデント",
  Type: "タイプ",
  Filing: "ファイリング",
  Investments: "投資",
  "Error Fetching Holdings for Investor ID": "株主情報取得時エラーが発生",
  "Please report to support@tikr.com": "support@tikr.comに連絡してください",
  "Error: No holdings for": "エラー: 株主情報なし",
  "Please report to support@tikr.com Error: investorId":
    "support@tikr.comに報告してください エラー:investorId",
  "no holdings": "株主情報なし",
  "Company Name": "会社名",
  Ticker: "ティッカー",
  "Reported Value of Shares Held (MM)": "保有株式の価値 (MM)",
  "% of Shares Outstanding Held": "保有株式の割合",
  "Filing Type": "業績報告タイプ",
  "Investment Managers": "投資マネージャー",
  "Endowment Fund": "寄付基金",
  "Finance Company": "金融会社",
  "Insurance Company": "保険会社",
  "Private Equity": "PEファンド",
  "Venture Capital": "ベンチャーキャピタル",
  "Brokerage Firms": "証券会社",
  "Independent Research Firm": "独立リサーチ会社",
  "Strategic Entities": "戦略会社",
  Corporation: "企業",
  "Holding Company": "持ち株会社",
  "Government Agency": "政府機関",
  "Institution sub types": "機関のサブタイプ",
  "Mutual Fund": "投資信託",
  "Hedge Fund Portfolio": "ヘッジファンドポートフォリオ",
  "Pension Fund Portfolio": "年金基金ポートフォリオ",

  "Error fetching shareholders": "株主情報取得時エラーが発生",
  "Value of Shares Held (MM)": "保有株式価値(MM)",
  "% Of Shares Outstanding Held": "発行済株の保有割合",
  "# Shares Held": "保有株式数",
  "Change in # of Shares Held": "保有株式数の変動",
  "% Change in # of Shares Held": "保有株式数の変化率",
  "This Holding as % of Firm's Portfolio": "所有資産に占める保有株価値比率",

  "Financial Data Options": "財務データオプション",
  "Hide All Ticker Share Prices (Business Owner Mode)":
    "すべての株価を非表示にする（ビジネスオーナーモード）: はい (or いいえ)",
  "Hide Ticker Price Chart after navigating away from company overview":
    "会社概要から遷移後、株価チャートを非表示にする",
  "Default Year To Start Financials & Estimates Data":
    "デフォルトの財務と予測データ開始年",
  "App Layout Options": "アプリのレイアウトオプション",
  "Dark Mode Preview": "ダークモードプレビュー",
  "Updated Significant Developments Formatting":
    "重要な進展を整形する: はい(or いいえ)",
  "Remove icons from tabs while researching an equity":
    "エクイティを調べるときにタブからアイコンを除く",
  "News Options": "ニュースオプション",
  "Referral Code": "紹介コード",
  True: "はい",
  true: "はい",
  False: "いいえ",
  false: "いいえ",
  "Reset Settings": "設定を初期化",
  "Default News Language": "ニュースのデフォルト言語",
  "Include English Stories in News Feeds":
    "ニュースフィードに英語の記事を含める",
  "App Settings": "アプリの設定",
  "Link copied to clipboard": "リンクがクリップボードにコピーされました",
  "Click to copy to clipboard": "クリックしてクリップボードにコピー",
  "There is an error with your Referral Code. Please contact with the error message: 'No Referral Code Found'":
    "紹介コードにエラーがあります。 エラーメッセージ付きでお問い合わせください：「紹介コードが見つかりません」",

  MULTIPLES: "マルチプル",
  Multiples: "マルチプル",
  Competitors: "競合",
  "Forward Multiples": "フォワードマルチプル",
  "NTM Total Enterprise Value / Revenues": "NTM総企業価値/収益",
  "NTM Total Enterprise Value / EBITDA": "NTM総企業価値/ EBITDA",
  "NTM Total Enterprise Value / EBIT": "NTM総企業価値/ EBIT",
  "NTM Price / Normalized Earnings": "NTM価格/正規化された収益",
  "NTM Market Cap / Free Cash Flow": "NTM時価総額/フリーキャッシュフロー",
  "NTM Price / Cash Flow Per Share": "NTM価格/ 1株当たりキャッシュフロー",
  "Trailing Multiples": "実績基準マルチプル",
  "LTM Total Enterprise Value / Revenues": "LTM総企業価値/収益",
  "LTM Total Enterprise Value / EBITDA": "LTM総企業価値/ EBITDA",
  "LTM Total Enterprise Value / EBIT": "LTM総企業価値/ EBIT",
  "LTM Price / Diluted EPS (P/E)": "LTM価格/追加前の希薄化後EPS (P/E)",
  "LTM Price / Book Value per Share": "LTM価格/ 1株当たり簿価",
  "LTM Price / Tangible Book Value per Share": "LTM価格/ 1株当たりの有形簿価",
  "LTM Total Enterprise Value / Unlevered Free Cash Flow":
    "LTMの総企業価値/アンレバードフリーキャッシュフロー",
  "LTM Market Cap / Levered Free Cash Flow":
    "LTM時価総額/レバードフリーキャッシュフロー",
  "Price Factors": "価格要因",
  "Total Enterprise Value (MM)": "総企業価値（MM）",
  "Market Cap (MM)": "時価総額（MM）",
  "Forward Factors": "フォワードファクター",
  "NTM Revenues": "NTM 収益",
  "NTM EBITDA": "NTM EBITDA",
  "NTM EBIT": "NTM EBIT",
  "NTM Normalized Earnings Per Share": "NTMの正規化された1株当たり利益",
  "NTM Free Cash Flow": "NTMフリーキャッシュフロー",
  "NTM Cash Flow Per Share": "1株当たりのNTMキャッシュフロー",
  "Trailing Factors": "トレーリングファクター",
  "LTM Revenues": "LTM収益",
  "LTM EBITDA": "LTM EBITDA",
  "LTM EBIT": "LTM EBIT",
  "LTM Diluted EPS Before Extra": "追加前の希釈EPSのLTM",
  "LTM Book Value per Share": "1株当たりのLTM簿価",
  "LTM Tangible Book Value per Share": "1株あたりのLTM有形簿価",
  "LTM Unlevered Free Cash Flow": "LTMアンレバードフリーキャッシュフロー",
  "LTM Levered Free Cash Flow": "LTMレバードフリーキャッシュフロー",

  "Return Ratios:": "利益率",
  "Return on Assets %": "総資産利益率",
  "Return on Capital %": "資本収益率",
  "Return On Equity %": "自己資本収益率",
  "Return on Common Equity %": "普通株主資本利益率",
  "Margin Analysis:": "利益分析",
  "Gross Profit Margin %": "売上総利益率",
  "SG&A Margin %": "販売及び一般管理費",
  "EBITDA Margin %": "EBITDAマージン",
  "EBITA Margin %": "EBITAマージン",
  "EBIT Margin %": "EBITマージン",
  "EBT Margin %": "EBTマージン",
  "EBT Excl. Non-Recurring Items Margin %": "経常外損益を除くEBT",
  "Income From Continuing Operations Margin %": "継続事業部門からの利益",
  "Net Income Margin %": "純利益率",
  "Normalized Net Income Margin %": "標準化純利益率",
  "Net Avail. For Common Margin %": "普通株主純利益率",
  "Levered Free Cash Flow Margin %": "レバードフリーキャッシュフロー",
  "Unlevered Free Cash Flow Margin %": "アンレバードフリーキャッシュフロー",
  "Asset Turnover:": "資産回転率",
  "Asset Turnover": "資産回転率",
  "Fixed Assets Turnover": "固定資産回転率",
  "Receivables Turnover": "売上債権回転率",
  "Inventory Turnover": "在庫回転率",
  "Working Capital Turnover": "運転資本回転率",
  "Short Term Liquidity:": "短期流動性",
  "Current Ratio": "流動比率",
  "Quick Ratio": "当座比率",
  "Dividend Payout %": "配当性向",
  "Dividend Yield %": "配当利回り",
  "Op Cash Flow to Current Liab": "営業キャッシュフローに対する流動負債",
  "Avg. Cash Conversion Cycle": "平均キャッシュコンバージョンサイクル",
  "Avg. Days Sales Outstanding": "平均売上債権回転日数",
  "Avg. Days Outstanding Inventory": "平均在庫回転日数",
  "Avg. Days Payable Outstanding": "平均仕入債務回転日数",
  "Long-Term Solvency:": "長期支払い能力",
  "Total Debt / Equity": "総負債比率",
  "Total Debt / Capital": "負債総資本比率",
  "Total Liabilities / Total Assets": "負債及び資本合計",
  "EBIT / Interest Expense": "EBIT / 支払利息",
  "EBITDA / Interest Expense": "EBITDA / 支払利息",
  "(EBITDA - Capex) / Interest Expense": "(EBITDA - Capex) / 支払利息",
  "FFO Interest Coverage": "FFOインタレストカバレッジ",
  "FFO to Total Debt (x)": "FFOに対する総負債比率",
  "Total Debt / EBITDA": "EBITDA総有利子負債倍率",
  "Net Debt / EBITDA": "EBITDA純有利子負債倍率",
  "Net Debt / (EBITDA - Capex)": "純負債 / (EBITDA - Capex)",
  "Industry Metrics:": "産業指標",
  "Nonperforming Loans / Total Loans %": "不良債権/総貸付金",
  "Nonperforming Loans / Total Assets %": "不良債権/総資産",
  "Nonperforming Assets / Total Assets %": "不良資産/総資産",
  "Nonperforming Assets / Equity %": "不良資産/資本",
  "Allowance for Credit Losses / Net Charge-offs %": "貸倒引当金/純貸倒償却",
  "Allowance for Credit Losses / Nonperforming Loans %": "貸倒引当金/不良債権",
  "Allowance for Credit Losses / Total Loans %": "貸倒引当金/総貸付金",
  "Net Charge-offs / Total Average Loans %": "純貸倒償却/総平均貸付金",
  "Provision for Loan Losses / Net Charge-offs %": "貸倒引当金/純貸倒償却",
  "Earning Assets / Interest Bearing Liabilities %": "収益資産/有利子負債",
  "Interest Income / Average Assets %": "金利収入/平均資産",
  "Interest Expense / Average Assets %": "支払利息/平均資産",
  "Net Interest Income / Average Assets %": "純金利収入/平均資産",
  "Total Average Common Equity / Total Average Assets %":
    "総平均株主資本/総平均資産",
  "Total Equity + Allowance for Loan Losses / Total Loans %":
    "総資産+貸倒引当金/総貸付金",
  "Gross Loans / Total Deposits %": "総貸付金/総預金",
  "Tier 1 Capital Ratio %": "自己資本比率",
  "Total Capital Ratio %": "総資本率",
  "Core Tier 1 Capital Ratio %": "中核自己資本比率",
  "Tier 2 Capital Ratio %": "補完的自己資本率",
  "Equity Tier 1 Capital Ratio %": "株主資本自己資本比率",

  Revenues: "売上",

  "Finance Div. Revenues": "財務部門収益",
  "Insurance Division Revenues": "保険部門収益",
  "Gain (Loss) on Sale of Assets (Rev)": "資産売却収益(損失)",
  "Gain (Loss) on Sale of Investment (Rev)": "投資売却収益(損失)",
  "Interest And Invest. Income (Rev)": "投資利息益（収益=rev）",
  "Other Revenues": "その他の収益",
  "Total Revenues": "総収益",
  "% Change YoY": "前年比変化率",

  "Cost of Goods Sold": "売上原価",

  "Gross Profit": "粗利益",
  "% Gross Margins": "粗利益率",

  "Selling General & Admin Expenses": "一般販管費",
  "Exploration / Drilling Costs": "探鉱/掘削費",
  "Provision for Bad Debts": "貸倒引当金",
  "Stock-Based Compensation": "株式に基づく報酬",
  "Pre-Opening Costs": "開業前の費用",
  "R&D Expense": "研究開発費",
  "R&D Expenses": "研究開発費",

  "Depreciation & Amortization": "減価償却費",
  "Amortization of Goodwill and Intangible Assets":
    "のれんおよび無形資産の償却",
  "Impairment of Oil Gas & Mineral Properties": "石油ガスおよび鉱物特性の減損",
  "Other Operating Expenses": "その他の営業費用",

  "Operating Income": "営業利益",
  "% Operating Margins": "営業利益率",
  "Interest Expense": "支払利息",
  "Interest and Investment Income": "利息と投資収益",
  "Interest And Investment Income": "利息と投資収益",

  "Income (Loss) On Equity Invest.": "株式投資収益（損失）",
  "Currency Exchange Gains (Loss)": "為替差益（損失）",
  "Other Non Operating Income (Expenses)": "その他の営業外収益（費用）",
  "EBT Excl. Unusual Items": "EBTを除く特別項目",
  "Merger & Restructuring Charges": "合併およびリストラ費用",

  "Impairment of Goodwill": "のれんの減損",
  "Gain (Loss) on Sale of Investments": "投資売却収益(損失)",
  "Gain (Loss) on Sale of Investments (Rev)": "投資売却収益(損失)",
  "Gain (Loss) on Sale Of Investments": "投資売却収益(損失)",
  "Gain (Loss) On Sale Of Assets": "資産売却収益(損失)",
  "Gain (Loss) on Sale of Assets": "資産売却収益(損失)",

  "Asset Writedown": "資産評価減",
  "In Process R&D Expenses": "仕掛研究開発費",
  "Insurance Settlements": "保険料の清算",
  "Legal Settlements": "訴訟和解金",
  "Other Unusual Items": "その他の特別項目",
  "EBT Incl. Unusual Items": "EBTを含む特別項目",
  "Income Tax Expense": "所得税費用",
  "Earnings From Continuing Operations": "継続事業からの収益",
  "Earnings Of Discontinued Operations": "非継続事業の収益",
  "Extraordinary Item & Accounting Change": "特別項目と会計の変更",
  "Net Income to Company": "会社への純利益",
  "Minority Interest": "非支配株主持分",
  "Net Income": "当期純利益",
  "Preferred Dividend and Other Adjustments": "優先配当およびその他の調整",
  "Net Income to Common Incl Extra Items": "追加項目を含む一般的な純利益",
  "% Net Income to Common Incl Extra Items Margins":
    "追加項目のマージンを含む一般的な純利益率",
  "Net Income to Common Excl. Extra Items": "共通の例外の当期純利益追加項目",
  "% Net Income to Common Excl. Extra Items Margins":
    "共通の例外に対する純利益率。追加項目のマージン",
  "Supplementary Data:": "補足データ：",
  "Diluted EPS Excl Extra Items": "希薄化後EPS（追加項目を除く）",
  "Weighted Average Diluted Shares Outstanding": "加重平均希薄化後発行済株式数",
  "Weighted Average Basic Shares Outstanding": "加重平均発行済普通株式数",
  "Dividends per Share": "1株当たり配当金",
  "Dividends Per Share": "1株当たり配当金",
  "Special Dividends Per Share": "1株当たりの特別配当",
  "Total Dividends Per Share": "1株当たり配当金合計",
  "Payout Ratio %": "配当性向(%)",
  "Basic EPS": "基本EPS",
  "FFO Payout Ratio %": "FFO配当性向(%)",
  FFO: "FFO",
  EBITDA: "EBITDA",
  EBITDAR: "EBITDAR",
  "Selling and Marketing Expense": "販売費およびマーケティング費",
  "General and Administrative Expense": "一般管理費",
  "Effective Tax Rate %": "実効税率％",
  "Distributable Cash per Share (Diluted)": "1株当たり分配可能現金（希薄化後）",
  "Annualized Distributions per Unit": "ユニットあたりの年間分布",
  "Same Store Sales Growth %": "既存店売上高伸び率",

  "Interest Income On Loans": "ローンの利息収入",
  "Interest Income On Investments": "投資の利息収入",
  "Interest Income": "金利収入",
  "Interest On Deposits": "預金利息",
  "Total Interest On Borrowings": "借入に対する総利息",
  "Net Interest Income": "純利息収入",
  "Non Interest Income Total": "利息以外の収入合計",
  "Revenues Before Provison For Loan Losses": "貸倒引当金控除前の収益",
  "Provision For Loan Losses": "貸倒引当金",
  "Compensation And Benefits": "給与・福利厚生",
  "Total Other Non Interest Expense": "その他の利息以外の費用の合計",
  "Non Interest Expense": "利息以外の費用",

  "Premiums and Annuity Revenues": "保険料と年金収入",
  "Total Interest And Dividend Income": "総利息と配当収入",
  "Asset Management Fee": "資産運用報酬",
  "Non-Insurance Activities Revenues": "保険料以外の収入",
  "Policy Benefits": "保険給付",
  "Policy Acquisition /  Underwriting Costs": "保険集客/審査コスト",

  "Fuel & Purchased Power": "燃料と購入電力",
  "Operations And Maintenance": "運用とメンテナンス",

  "Rental Revenues": "賃貸収入",
  "Tenant Reimbursements": "テナントの償還",
  "Property Management Fee": "不動産管理費",
  "Interest Income On Mortgage Securities": "住宅ローン証券の受取利息",
  "Property Expenses": "不動産経費",

  "Interest and Dividend Income": "利息と配当収入",
  "Commission and Fees": "手数料",
  "Mortgage Banking": "住宅ローンバンキング",
  "Credit Card Fee": "クレジットカード手数料",
  "Loan Servicing Revenue": "ローンサービス収入",
  "Salaries And Other Employee Benefits": "給与およびその他の従業員福利厚生",
  "Cost of Services Provided": "サービス経費",

  "Brokerage Commission": "仲介手数料",
  "Trading and Principal Transactions": "トレーディングと主な取引",
  "Underwriting & Investment Banking Fee": "引受および投資銀行手数料",

  "Impairment of Oil, Gas & Mineral Properties": "石油、ガス、鉱物の減損",
  "Total Depreciation & Amortization": "減価償却費",
  "Amortization of Deferred Charges": "繰延費用の償却",
  "Minority Interest in Earnings": "少数株主利益",

  "(Gain) Loss From Sale Of Asset": "資産売却収益(損失)",
  "(Gain) Loss on Sale of Investments": "投資売却収益(損失)",
  "Asset Writedown & Restructuring Costs": "資産評価損とリストラ費用",
  "Net (Increase) Decrease in Loans Originated / Sold - Operating":
    "組成/売却されたローンの純減少(増加)",
  "Provision for Credit Losses": "貸倒引当金",
  "(Income) Loss On Equity Investments": "株式投資収益（損失）",
  "Tax Benefit from Stock Options": "ストックオプションの税制優遇",
  "Provision and Write-off of Bad Debts": "貸倒引当金および貸倒償却",
  "Net Cash From Discontinued Operations": "非継続事業からの純現金",
  "Other Operating Activities": "その他の営業活動",
  "Change in Trading Asset Securities": "資産有価証券取引変動",
  "Change In Accounts Receivable": "売掛金の変化",
  "Change In Inventories": "在庫の変化",
  "Change In Accounts Payable": "買掛金の変化",
  "Change In Income Taxes": "所得税の変化",
  "Change In Deferred Taxes": "繰延税金の変化",
  "Change in Other Net Operating Assets": "その他の純営業資産の変化",
  "Cash from Operations": "営業活動によるキャッシュフロー",
  "Memo: Change in Net Working Capital": "メモ：正味運転資本の変動",
  "Capital Expenditure": "資本的支出",
  "Sale of Property, Plant, and Equipment": "有形固定資産の売却",
  "Cash Acquisitions": "現金取得",
  Divestitures: "売却",

  "Sale (Purchase) of Real Estate properties": "不動産の売却（購入）",
  "Sale (Purchase) of Intangible assets": "無形資産の売却（購入）",
  "Investment in Marketable and Equity Securities": "上場/非上場株式への投資",
  "Net (Increase) Decrease in Loans Originated / Sold - Investing":
    "創出/売却されたローンの純減（増）-投資",
  "Other Investing Activities": "その他の投資活動",
  "Cash from Investing": "投資による現金",

  "Total Debt Issued": "発行した負債の合計",

  "Total Debt Repaid": "返済された負債の合計",
  "Issuance of Common Stock": "普通株式の発行",
  "Repurchase of Common Stock": "普通株式の買戻し",
  "Issuance of Preferred Stock": "優先株式の発行",
  "Repurchase of Preferred Stock": "優先株式の買戻し",
  "Common Dividends Paid": "支払配当金",
  "Preferred Dividends Paid": "優先配当支払い",

  "Special Dividend Paid": "特別配当金支払い",
  "Other Financing Activities": "その他の財務活動",
  "Cash from Financing": "資金調達による現金",
  "Foreign Exchange Rate Adjustments": "外国為替レートの調整",
  "Miscellaneous Cash Flow Adjustments": "その他のキャッシュフロー調整",
  "Net Change in Cash": "現金の純増減額",
  "Cash and Cash Equivalents, Beginning of Period":
    "期首における現金および現金同等物",
  "Cash and Cash Equivalents, End of Period":
    "期末における現金および現金同等物",
  "Cash Interest Paid": "現金払い利息",
  "Cash Taxes Paid": "現金による税金支払い",
  "Maintenance CAPEX": "メンテナンスによる資本的支出",
  "Cash Flow per Share": "1株当たりのキャッシュフロー",

  "Sale / Securitization of Accounts Receivable": "売掛金の売却/証券化",

  "Total Depreciation, Depletion & Amortization":
    "減価償却費、減耗償却費、及び償却費の合計",
  "Deferred Policy Acquisition Costs": "繰延新契約費",
  "Amort. Of Deferred Policy Acq. Costs": "繰延新契約費の償却",
  "Reinsurance Payable": "再保険の支払い",
  "Change in Unearned Revenues": "未稼得収入の変動",
  "Change in insurance Reserves / Liabilities": "保険準備金/負債の変動",
  "Annuity Receipts": "年金領収",
  "Annuity Payments": "年金支払い",

  "Nuclear Fuel Expenditures": "核燃料支出",
  "Contributions to Nuclear Decommissioning Trust": "核廃棄信託への出資",

  "Cash And Equivalents": "現金及び現金同等物",
  "Short Term Investments": "短期投資",
  "Trading Asset Securities": "商品有価証券",
  "Total Cash And Short Term Investments": "現金及び短期投資の合計",
  "Accounts Receivable": "売掛金",
  "Other Receivables": "その他未収入金",
  "Notes Receivable": "受取手形",
  "Total Receivables": "総売掛金",
  Inventory: "在庫",
  "Prepaid Expenses": "前払費用",
  "Finance Division Loans and Leases Current": "財務部門貸付金及びリース",
  "Finance Division Other Current Assets": "財務部門その他流動資産",

  "Loans Held For Sale": "売却予定貸出金",
  "Deferred Tax Assets Current": "繰延税金資産",
  "Restricted Cash": "制限付預金",
  "Other Current Assets": "その他流動資産",
  "Total Current Assets": "総流動資産",
  "Gross Property Plant And Equipment": "総有形固定資産",
  "Accumulated Depreciation": "減価償却累計額",
  "Net Property Plant And Equipment": "純有形固定資産",
  "Long-Term Investments": "長期投資",
  "Long-term Investments": "長期投資",
  Goodwill: "のれん",
  "Other Intangibles": "その他無形資産",
  "Finance Division Loans and Leases Long-Term": "財務部門長期貸付金及びリース",
  "Finance Division Other Long-Term Assets": "財務部門その他長期流動資産",

  "Accounts Receivable Long-Term": "長期売掛金",
  "Loans Receivable Long-Term": "長期貸付金",
  "Deferred Tax Assets Long-Term": "長期繰延税金資産",
  "Deferred Charges Long-Term": "長期繰延費用",
  "Other Long-Term Assets": "その他固定資産",
  "Total Assets": "総資産",
  "Accounts Payable": "買掛金",
  "Accrued Expenses": "未払費用",
  "Short-Term Borrowings": "短期借入金",
  "Short-term Borrowings": "短期借入金",

  "Current Portion of Long-Term Debt": "1年以内返済予定長期債務",
  "Current Portion of Capital Lease Obligations":
    "1年以内返済予定キャピタル・リース債務",
  "Finance Division Debt Current": "財務部流動債務",
  "Finance Division Other Current Liabilities": "財務部門その他流動負債",

  "Current Income Taxes Payable": "未払い法人税",
  "Current Unearned Revenue": "前受収益",
  "Deferred Tax Liability Current": "繰延税金負債",
  "Other Current Liabilities": "その他流動負債",
  "Total Current Liabilities": "総流動負債",
  "Long-Term Debt": "長期借入金",
  "Capital Leases": "キャピタルリース",
  "Finance Division Debt Non Current": "財務部門固定債務",
  "Finance Division Other Non Current Liabilities": "その他財務部門固定負債",

  "Unearned Revenue Non Current": "長期前受収益",
  "Pension & Other Post Retirement Benefits": "年金及びその他退職後給付",
  "Deferred Tax Liability Non-Current": "長期繰延税金負債",
  "Deferred Tax Liability Non Current": "長期繰延税金負債",
  "Other Non Current Liabilities": "その他長期負債",
  "Total Liabilities": "総負債",
  "Preferred Stock Redeemable": "償還可能優先株",
  "Preferred Stock Non Redeemable": "非償還優先株",
  "Preferred Stock Convertible": "普通株転換可能優先株",
  "Preferred Stock - Others": "その他優先株",
  "Total Preferred Equity": "優先株合計",

  "Common Stock": "普通株",
  "Additional Paid In Capital": "株式払込余剰金",
  "Retained Earnings": "内部留保",

  "Treasury Stock": "自己株式",
  "Comprehensive Income and Other": "その他包括利益",
  "Total Common Equity": "総自己資本",
  "Total Equity": "総資本",
  "Total Liabilities And Equity": "負債・資本合計",
  "Total Shares Out. on Filing Date": "発行済み株式の出願日",
  "Book Value/Share": "純資産/株",
  "Tangible Book Value": "有形資産価値",
  "Tangible Book Value/Share": "有形資産価値/株",
  "Total Debt": "総負債",
  "Net Debt": "純負債",
  "Total Minority Interest": "総少数株主持分",
  "Equity Method Investments": "持分法適応した投資",
  Land: "土地",
  Buildings: "建物",
  "Construction In Progress": "建設仮勘定",
  "Full Time Employees": "正社員",

  "Investment Securities": "投資有価証券",
  "Mortgage Backed Securities": "不動産担保証券",
  "Gross Loans": "総貸付金",
  "Allowance For Loan Losses": "貸倒引当金",
  "Other Adjustments to Gross Loans": "その他総貸付金への調整",
  "Investment in FHLB": "連邦住宅貸付銀行への投資",
  "Investment in Real Estate": "不動産投資",
  "Other Real Estate Owned And Foreclosed": "その他所有不動産及び担保",
  "Interest Bearing Deposits": "利息付預金",
  "Institutional Deposits": "機関預金",
  "Non-Interest Bearing Deposits": "無利息預金",
  "Total Deposits": "総預金",
  "Federal Home Loan Bank Debt - Long-Term": "連邦住宅貸付銀行長期債務",
  "Trust Preferred Securities (BS)": "トラスト型優先証券",

  "Investment In Debt Securities": "債券投資",
  "Investment In Equity and Preferred Securities":
    "持分証券及び優先出資証券への投資",
  "Policy Loans": "約款貸付",
  "Reinsurance Recoverable - (IS)": "未収再保険金",
  "Deferred Policy Acquisition Costs - (BS)": "繰延新契約費",
  "Separate Account Assets": "分離勘定資産",
  "Insurance And Annuity Liabilities": "保険及び年金負債",
  "Unpaid Claims": "未払保険金",
  "Unearned Premiums": "未経過保険料",
  "Reinsurance Payable - (BS)": "再保険料",

  "Land Held For Development Or Sale": "売却目的の土地及び開発",
  "Total Real Estate Assets": "総不動産資産",
  "Mortgage Loans": "住宅ローン",

  "Cash & Securities Segregated": "現金と有価証券区分",
  "Securities Purchased Under Agreements To Resell": "再販契約条件付有価証券",
  "Securities Borrowed": "借入有価証券",

  "Deferred Tax Assets Long-Term (Collected)": "長期繰延税金資産",

  "Revenue Actual": "売上実績",
  "EBITDA Actual": "EBITDA実績",
  "EBIT Actual": "EBIT実績",
  "EBT Normalized Actual": "EBT正規化実績",
  "Net Income Normalized Actual": "標準化純利益実績",
  "EPS Normalized Actual": "EPS正規化実績",
  "EPS (GAAP) Actual": "EPS（GAAP）実績",
  "Gross Margin Actual (%)": "粗利益率実績",
  "Interest Expense Actual": "支払利息実績",
  "Depreciation & Amortization Actual": "減価償却費実績",
  "Effective Tax Rate Actual (%)": "実効税率実績",
  "EBT (Excl. Excep & GW) Actual": "EBT実績(特別項目及びのれんを除く)",
  "EBT (GAAP) Actual": "EBT（GAAP）実績",
  "Net Income (Excl. Excep & GW) Actual": "純利益(特別項目及びのれんを除く)",
  "Net Income (GAAP) Actual": "純利益（米国会計基準）",
  "Cash EPS Actual": "一株当たり営業キャッシュフロー",
  "Cash From Operations Actual": "営業活動による現金",
  "Capital Expenditure Actual": "資本的支出",
  "Free Cash Flow Actual": "フリーキャッシュフロー",
  "Cash Flow / Share Actual": "現金流出入/株式",
  "Net Debt Actual": "純負債実績",
  "Book Value / Share Actual": "一株当たりの帳簿価格",
  "Net Asset Value Actual": "純資産価値",
  "Net Asset Value / Share Actual": "一株当たりの純資産価値",
  "Return on Equity Actual (%)": "自己資本利益率",
  "Return on Assets Actual (%)": "総資産利益率",
  "Distributable Cash (Income Trusts) Actual": "配当可能利益（信託収入）",
  "Distributable Cash / Share (Income Trust) Actual":
    "一株当たりの配当可能利益（信託収入）",
  "FFO (REIT) Actual": "FFO (不動産投資信託)",
  "FFO / Share (REIT) Actual": "FFO / 株 (不動産投資信託)",
  "Adjusted FFO / Share (REIT) Actual": "調整FFO/株(不動産投資信託)",
  "Adjusted FFO (REIT) Actual": "調整FFO(不動産投資信託)",
  "Same Store Sales Growth Actual (%)": "既存店増収率",
  "Average Daily Production (Oil)  Actual": "一日の平均生産量（石油）",
  "Average Daily Production (Gas)  Actual": "一日の平均生産量（ガス）",
  "Average Daily Production (NGL)  Actual": "一日の平均生産量（天然ガス液）",
  "Total Average Daily Production  Actual": "一日の平均生産合計量",
  "Total Production (Oil) Actual": "総生産量（石油）",
  "Total Production (Gas)  Actual": "総生産量（ガス）",
  "Total Production (NGL)  Actual": "総生産量（天然ガス液）",
  "Total Production Actual": "総生産量",
  "Revenues (Oil)  Actual": "収益（石油）",
  "Revenues (Gas)  Actual": "収益（ガス）",
  "Revenues (NGL)  Actual": "収益（天然ガス液）",
  "Exploration Expense Actual": "探鉱支出",

  Revenue: "売上",

  "% EBITDA Margins": "EBITDAマージン比率",
  EBIT: "EBIT",
  "% EBIT Margins": "EBITマージン比率",
  "EBT Normalized": "EBT正規化",
  "% EBT Margins": "EBTマージン比率",
  "Net Income Normalized": "標準化純利益",
  "% Net Income Margins": "純利益マージン比率",
  "EPS Normalized": "EPS正規化",
  "EPS (GAAP)": "EPS（GAAP）",
  "Dividend Per Share": "1株当たり配当金",
  "IS Supplementary Data:": "損益計算書補足データ：",
  "Gross Margin (%)": "粗利益率",
  "Effective Tax Rate (%)": "実効税率",
  "EBT (Excl. Excep & GW)": "EBT(特別項目及びのれんを除く)",
  "EBT (GAAP)": "EBT（GAAP）",
  "Net Income (Excl. Excep & GW)": "純利益(特別項目及びのれんを除く)",
  "Net Income (GAAP)": "純利益（米国会計基準）",
  "Cash EPS": "一株当たり営業キャッシュフロー",
  "CF Supplementary Data:": "キャッシュフロー補足データ",
  "Cash From Operations": "営業活動による現金",
  "Free Cash Flow": "フリーキャッシュフロー",
  "Cash Flow / Share": "現金流出入/株式",
  "BS Supplementary Data:": "貸借対照表補足データ：",
  "Net Debt/EBITDA": "純負債/EBITDA",
  "Book Value / Share": "一株当たりの帳簿価格",
  "Net Asset Value": "純資産価値",
  "Net Asset Value / Share": "一株当たりの純資産価値",
  "Return on Equity (%)": "自己資本利益率",
  "Return on Assets (%)": "総資産利益率",
  "Other Supplementary Data:": "その他補足データ",
  "Price Target Mean (Bottom Up)": "価格目標（ボトムアップ）",
  "Distributable Cash (Income Trusts)": "配当可能利益（信託収入）",
  "Distributable Cash / Share (Income Trust)":
    "一株当たりの配当可能利益（信託収入）",
  "FFO (REIT)": "FFO (不動産投資信託)",
  "FFO / Share (REIT)": "FFO / 株 (不動産投資信託)",
  "Adjusted FFO / Share (REIT)": "調整FFO/株(不動産投資信託)",
  "Adjusted FFO (REIT)": "調整FFO(不動産投資信託)",
  "Same Store Sales Growth (%)": "既存店増収率",
  "Average Daily Production (Oil)": "一日の平均生産量（石油）",
  "Average Daily Production (Gas)": "一日の平均生産量（ガス）",
  "Average Daily Production (NGL)": "一日の平均生産量（天然ガス液）",
  "Total Average Daily Production": "一日の平均生産合計量",
  "Total Production (Oil)": "総生産量（石油）",
  "Total Production (Gas)": "総生産量（ガス）",
  "Total Production (NGL)": "総生産量（天然ガス液）",
  "Total Production": "総生産量",
  "Revenues (Oil)": "収益（石油）",
  "Revenues (Gas)": "収益（ガス）",
  "Revenues (NGL)": "収益（天然ガス液）",
  "Exploration Expense": "探鉱支出",

  "Sign In to Your Account": "アカウントにログインする",
  Email: "Eメール",
  "Enter your email": "メールアドレスを入力してください",
  Password: "パスワード",
  "Enter your password": "パスワードを入力してください",
  "Forgot Password?": "パスワード忘れ？",
  "Reset Password": "パスワードを再設定する",
  "Sign In": "ログイン",
  "No Account?": "アカウントを持っていない？",
  "Create Account": "アカウントを新規作成する",
  "Required Input Missing": "必須な項目が入力されていない",
  "Email Address Invalid": "無効なメールアドレス",
  "Create New Account": "アカウントを新規作成する",
  "Verify Email": "Eメールを確認する",
  "Re-Enter Email": "メールアドレスを再入力してください",
  "Verify Password": "パスワードを確認する",
  "Re-Enter Password": "パスワードを再入力してください",
  "Invite Code": "招待コード",
  "Required: Invite Code": "必須：招待コード",
  "Optional: Invite Code": "オプション：招待コード",
  "Have an account?": "アカウントを持っている？",
  "I accept the": "私は",
  "Privacy Policy": "個人情報保護方針",
  "and the": "と",
  "Terms of Use": "利用規約に同意する",
  "Emails Do Not Match": "メールが一致しない",
  "Passwords Do Not Match": "パスワードが一致していない",
  "Must Accept Privacy Policy and Terms of Use":
    "プライバシーポリシーと利用規約に同意する必要がある",
  "Verification Code": "認証コード",
  "Lost your code? Check Spam Folder or":
    "認証コードを紛失した？ 迷惑メールフォルダを確認するか、",
  "Resend Code": "認証コードを再送する",
  "Code Resent to": "認証コードを再送",
  "Back to Sign In": "ログインに戻る",
  "User is already confirmed.": "ユーザー確認済み。",
  "Code Resent": "認証コード再送済み",
  "Send Reset Code": "リセットコードを送信する",
  "Enter Email to Reset Password": "メールを入力してパスワードをリセットする",
  "Confirm Email Verification Code Sent To": "メール認証コードを送信済み",
  "Username cannot be empty": "ユーザー名を入力してください",
  "Must be at least 6 characters long": "6文字以上である必要がある",
  Required: "必須項目",
  "Incorrect username or password.": "ユーザー名またはパスワードが誤っている。",
  "User does not exist.": "ユーザーは存在しない。",
  "User already exists": "ユーザーは既に存在している",
  "Username/client id combination not found.":
    "ユーザー名/クライアントIDの組み合わせが見つからない。",
  "Invalid verification code provided, please try again.":
    "確認コードが無効、再試行してください。",
  "Financial Data": "財務データ",

  Filter: "フィルタ",
  "Clear Filter": "フィルタをクリア",
  "Error Fetching News ... Try Refreshing the Page!":
    "ニュース取得中にエラーが発生しました...ページを更新してみてください！",
  "Close Story": "記事を閉じる",
  "Loading News Story...": "ニュース記事を読み込んでいます...",

  "Sorry, We couldn't find any news for": "申し訳ないが、ニュースがない",
  "Significant Developments": "重要な進展",
  "Error Fetching Significant Developments": "重要な進展取得時にエラーが発生",
  reuters: "ロイター",
  "More News": "その他のニュース",

  "Hide Labels": "ラベルを非表示",
  "Click on row in table below to chart":
    "下の表の行をクリックしてグラフを作成します",
  "Convert to USD:": "米ドルに換算：",
  column: "カラム",
  line: "ライン",
  Average: "平均",
  Last: "終値",

  Region: "地域",
  "Select Region": "地域を選択",
  "Asia / Pacific": "アジア太平洋地域",
  "United States and Canada": "アメリカ合衆国とカナダ",
  Europe: "ヨーロッパ",
  "Africa / Middle East": "アフリカ/中東",
  "Latin America and Caribbean": "ラテンアメリカとカリブ海",

  "Select Period": "期間を選択",
  "Select Relative Period": "相対期間を選択",
  "Select Fiscal Year": "会計年度を選択",
  "Select Calendar Year": "暦年を選択",
  "Select Quarter": "四半期を選択",
  "Fiscal Year": "会計年度",
  FY: "会計年度",
  "Calendar Year": "暦年",
  CY: "暦年",
  "Fiscal Quarter": "会計四半期",
  FQ: "会計四半期",

  "Select Value Type": "値タイプを選択",
  "Mean Consensus Estimate": "コンセンサス予測平均値",
  "Median Consensus Estimate": "コンセンサス予測中央値",
  Actual: "実際",
  "Reported Actual": "実際報告",
  "High Consensus Estimate": "コンセンサス予測高",
  "Low Consensus Estimate": "コンセンサス予測低",
  "Number of Consensus Estimates": "コンセンサス予測数",
  "Consensus Estimate Standard Deviation": "コンセンサス予測標準偏差",
  "Management Guidance Mid": "会社予測中央値",
  "Management Guidance High": "会社予測高",
  "Management Guidance Low": "会社予測低",
  "Market Capitalization": "時価総額",
  "Total Enterprise Value": "総企業価値",
  "Last Close Price": "前日終値",
  "Year Founded": "設立年",

  "equal to": "等しい",
  "not equal to": "等しくない",
  "greater than": "より大きい",
  "greater than or equal to": "以上",
  "less than": "未満",
  "less than or equal to": "以下",
  between: "の間に",
  is: "同じ",
  "is not": "同じではない",
  "is one of": "の一つである",
  "is not one of": "の1つではない",
  any: "どれか",
  all: "すべて",
  and: "と",
  or: "または",
  ">": ">",
  ">=": ">=",
  "<": "<",
  "<=": "<=",
  "=": "=",

  "Sorry! Error searching for": "申し訳ありません。検索エラー",

  Industry: "業界",
  "Select Industry": "業界を選択",
  "Capital Markets": "資本市場",
  "Metals and Mining": "金属と鉱業",
  "Real Estate Management and Development": "不動産管理と開発",
  "Food Products": "食品",
  Machinery: "機械",
  Chemicals: "化学",
  "Electronic Equipment, Instruments and Components": "電子装置・機器・部品",
  Banks: "銀行",
  Software: "ソフトウェア",
  "Oil, Gas and Consumable Fuels": "石油・ガス・消耗燃料",
  Pharmaceuticals: "医薬品",
  "Textiles, Apparel and Luxury Goods": "繊維・アパレル・贅沢品",
  "Construction and Engineering": "建設・土木",
  "Hotels, Restaurants and Leisure": "ホテル・レストラン・レジャー",
  "IT Services": "情報技術サービス",
  Biotechnology: "バイオテクノロジー",
  "Commercial Services and Supplies": "商業サービス・用品",
  "Electrical Equipment": "電気設備",
  "Semiconductors and Semiconductor Equipment": "半導体・半導体製造装置",
  "Healthcare Equipment and Supplies": "ヘルスケア機器・用品",
  "Equity Real Estate Investment Trusts (REITs)": "不動産投資信託(REITs)",
  Media: "メディア",
  "Auto Components": "自動車部品",
  Entertainment: "エンターテインメント",
  "Household Durables": "家庭用耐久財",
  "Trading Companies and Distributors": "商社・流通業",
  "Specialty Retail": "専門小売",
  "Healthcare Providers and Services":
    "ヘルスケア・プロバイダー/ヘルスケア・サービス",
  Insurance: "保険",
  "Diversified Financial Services": "各種金融サービス",
  "Professional Services": "専門サービス",
  "Communications Equipment": "通信機器",
  "Construction Materials": "建設資材",
  "Food and Staples Retailing": "食品・生活必需品小売",
  "Building Products": "建設関連製品",
  "Containers and Packaging": "容器・包装",
  "Personal Products": "パーソナル用品",
  "Independent Power and Renewable Electricity Producers":
    "独立系発電事業者・エネルギー販売業者",
  "Diversified Consumer Services": "各種消費者サービス",
  "Energy Equipment and Services": "エネルギー設備・サービス",
  "Consumer Finance": "消費者金融",
  "Internet and Direct Marketing Retail": "インターネット販売・カタログ販売",
  Distributors: "販売",
  "Interactive Media and Services": "インタラクティブメディアとサービス",
  Beverages: "飲料",
  "Technology Hardware, Storage and Peripherals":
    "テクノロッジー ハードウェア・コンピュータ記憶装置・周辺機器",
  "Diversified Telecommunication Services": "各種電気通信サービス",
  "Paper and Forest Products": "紙製品・林産品",
  "Road and Rail": "陸運・鉄道",
  "Electric Utilities": "電力",
  "Transportation Infrastructure": "運送インフラ",
  "Aerospace and Defense": "航空宇宙・防衛",
  "Thrifts and Mortgage Finance": "貯蓄・抵当金融",
  "Air Freight and Logistics": "航空貨物・物流サービス",
  "Health Care Technology": "ヘルスケアテクノロジー",
  "Industrial Conglomerates": "コングロマリット",
  Marine: "海運業",
  "Leisure Products": "レジャー製品",
  "Life Sciences Tools and Services": "ライフサイエンス・ツール/サービス",
  "Multiline Retail": "複合小売",
  Automobiles: "自動車",
  "Gas Utilities": "ガス",
  "Water Utilities": "水道",
  "Wireless Telecommunication Services": "無線通信サービス",
  Airlines: "旅客航空輸送業",
  "Household Products": "家庭用品",
  Tobacco: "タバコ",
  "Mortgage Real Estate Investment Trusts (REITs)": "抵当不動産投資信託(REITs)",
  "Multi-Utilities": "総合公益事業",

  Subscription: "サブスクリプション",
  "Current TIKR Subscription": "現在のTIKRサブスクリプション",
  "If your subscription expires your account will automatically be downgraded to free TIKR.com access":
    "サブスクリプションの有効期限が切れると、アカウントは自動的にダウングレードされ、TIKR.comに無料版になる",
  "Subscription Level": "サブスクリプションレベル",
  "Subscription Expiration": "サブスクリプションの有効期限",
  "Subscription Auto Renew": "サブスクリプションの自動更新",
  "Subscription Will Not Automatically Renew":
    "サブスクリプションは自動的に更新されない",
  "Subscription Will Renew On": "サブスクリプションは更新される",
  "This Subscription Does Not Expire":
    "このサブスクリプションは期限切れになれない",
  "Premium TIKR.com Membership": "プレミアム版TIKR.comメンバーシップ",
  "Premium TIKR.com Trial Membership":
    "プレミアムトライアル版TIKR.comメンバーシップ",
  "U.S. and Canada Basic TIKR.com Membership":
    "米国およびカナダのベーシック版TIKR.comメンバーシップ",
  "Europe Basic TIKR.com Membership":
    "ヨーロッパのベーシック版TIKR.comメンバーシップ",
  "Asia Basic TIKR.com Membership": "アジアベーシック版TIKR.comメンバーシップ",
  "LatAm / Africa / Other Basic TIKR.com Membership":
    "ラテンアメリカ/アフリカ/その他のエリアのベーシック版TIKR.comメンバーシップ",
  "Beta Trial TIKR.com Membership": "Betaトライアル版TIKR.comメンバーシップ",
  "Free TIKR.com Membership": "無料のTIKR.comメンバーシップ",

  "Exclusive Beta User Discount": "独占ベータユーザー割引",
  "30% Off Annual Membership!": "年間会員数が30％オフ！",
  "25% Off Annual Membership!": "年間メンバーシップが25％オフ！",
  "20% Off Annual Membership!": "年間会員数が20％オフ！",
  "10% Off Annual Membership!": "年間メンバーシップが10％オフ！",

  "To thank you for your feedback and contributions during our beta. We want to offer you a <b>special reduced price for a 12 month subscription</b>":
    "Beta版の試用期間中のフィードバックと貢献に感謝します。 私たちはあなたに<b>特別割引価格で12か月のサブスクリプション</ b>を提供したいと思います",
  "This subscription fee will go directly to paying the substantial server and data costs that power TIKR. To be completely transparent we have been funding these large costs out of our own pockets thus far as we really believe in what we are doing! Your support before our launch would go a long way.":
    "このサブスクリプション料金は、TIKRのサーバーおよびデータの取得コストに使用されます。私たちは今自分たちがしていることを本当に信じているため、今まで自分たちのお金でこれらのコストを賄ってきました。プロダクト正式リリース前にあなたのサポートは本当にありがたいです。",
  "You have been a very important part of our journey so far, and we are excited to continue building TIKR in the coming months and years to be exactly the product you have always wanted (and more!).":
    "あなたはこれまで私たちの旅においてとても重要な一部でして、そして私たちはいつも精一杯であなたが本当に欲しい（そしてもっと！）TIKRを今後の数ヶ月、数年で作り続けていきたいと思います。",

  "Get <b>20% off TIKR.com Premium or TIKR.com Basic 12 month membership</b> when you purchase today!":
    "今日購入すると、<b>TIKR.comプレミアムまたはTIKR.comベーシック版12か月メンバーシップが20％オフ</ b>になります！",
  "Select your membership level below and click 'Proceed to Payment":
    "以下のメンバーシップレベルを選択し、'支払いに進む'をクリックしてください",

  "Step 1 of 2": "ステップ1/2",
  "Step 2 of 2": "ステップ2/2",
  PREMIUM: "プレミアム",
  "Select Exclusive Prelaunch Subscription":
    "リリース前の特別なサブスクリプションを選択する",
  "Purchase TIKR.com subscription": "TIKR.comサブスクリプションを購入する",
  "Purchase Subscription": "サブスクリプションの購入",
  "Select Membership to Continue": "メンバーシップを選択してください",
  "Proceed to Payment": "支払いに進む",
  "Select Region to Proceed": "地域を選択します",
  "Select Payment Option Above": "支払い方法を選択してください",
  "U.S. and Canada": "米国およびカナダ",
  Asia: "アジア",
  "LatAm / Africa / Other": "ラテンアメリカ/アフリカ/その他のエリア",
  "You have successfully created subscription":
    "サブスクリプションが正常に処理されました",

  "Global Data Coverage (50,000+ companies)":
    "グローバルデータカバレッジ（50,000社以上）",
  "<b>15 years</b> Detailed Data": "<b>15年間</ b>の詳細データ",
  "Industry Metrics (<b>NAV / FFO / AFFO</b>)":
    "業界指標（<b> NAV / FFO / AFFO </ b>）",
  "Good through <s>June 1st 2022</s> <b>Dec 31st 2022</b>":
    "<s>2022年6月1日</ s><b>2022年12月31日</ b>まで有効",
  "Good through": "有効",
  "Less than <s>$42</s> <b>$28 / Month</b>": "<s>$42</ s><b>$28/月</b>未満",

  BASIC: "ベーシック版",

  "All Data For 1 Region (choose below)":
    "1つの地域のすべてのデータ（以下から選択）",
  "<b>10 years</b> Detailed Data": "<b>10年間</ b>の詳細データ",
  "Please select from the options to complete":
    "オプションから選択して完了してください",
  payment: "支払い",

  "By completing payment you agree to the":
    "支払いを完了することにより、あなたは下記に同意する",
  "Upgrade your subscription to copy data. 3 years at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "契約期間をアップグレードしてデータをコピー メインTIKRデータ」は一度に3年間、「セカンダリーデータ」は最大30年間。",
  "Upgrade your subscription to copy data up to 3 years at a time":
    "サブスクリプションをアップグレードすると、一度に最大3年分のデータをコピーできます。",
  "You can copy 3 years of data at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "メインTIKRデータ」は一度に3年分、「セカンダリデータ」は最大30年分のデータをコピーできます。",
  "You can copy only 3 years of data at a time":
    "一度にコピーできるのは3年分のデータのみ",
}
