"use strict"

/**
 *
 * @param {Date} now
 * @param {*} timeArr
 */
const generateUpdateTimeArray = (now, timeObjArr) => {
  // timeArry = [{hr: xx, min: xx}]
  return timeObjArr.map((m) =>
    Date.UTC(
      now.getUTCFullYear(),
      now.getUTCMonth(),
      now.getUTCDate(),
      m.hours,
      m.minutes
    )
  )
}

/**
 *  Determine if a cache, which is updated daily according to the schedule specified
 * in `updatedArr` has been updated between the previous time the cache was updated
 * and the current time
 * @param {Date} timeUpdated time cache was updated
 * @param {Date} now time controller is executed, "current time"
 * @param {*} updatedArr Array of UTC times throughout the day the cache is updated [ { hours: xx, minutes: xx } ]
 */
const isCacheValid = (timeUpdated, now, updatedArr) => {
  let cacheValid = false
  const updatedDiff = now - timeUpdated
  const timeArr = generateUpdateTimeArray(now, updatedArr)

  timeArr.forEach((e) => {
    const diff = now - e
    if (diff > 0) {
      // current time is after e
      cacheValid = diff >= updatedDiff
    }
  })

  return cacheValid
}

const priceScheduleArr = [
  { hours: 0, minutes: 15 },
  { hours: 3, minutes: 15 },
  { hours: 8, minutes: 45 },
  { hours: 16, minutes: 45 },
  { hours: 22, minutes: 15 },
]

const financialsScheduleArr = [
  { hours: 1, minutes: 45 },
  { hours: 12, minutes: 45 },
]

const estimatesScheduleArr = [
  { hours: 3, minutes: 45 },
  { hours: 11, minutes: 45 },
  { hours: 19, minutes: 45 },
]

export {
  isCacheValid,
  priceScheduleArr,
  financialsScheduleArr,
  estimatesScheduleArr,
}
