import * as Comlink from "comlink"
import cloneDeep from "lodash/cloneDeep"
import {
  USE_WORKER_CIQ_VALUATION,
  USE_WORKER_CIQ_ESTIMATES,
  USE_WORKER_CIQ_ACTUALS,
  USE_WORKER_CIQ_FINANCIALS,
  USE_CDK_CIQ,
  USE_CDK_TRKD,
} from "../feature-toggle"
import estimateDataItems from "~/utils/templates/estimates_dataitems.json"
import { financialTemplates } from "~/utils/templates/financials_templates"
import { estimateTemplate } from "~/utils/templates/estimates_template"
import {
  rowObjToSelectedKey,
  setDateRange,
  findClosestDateIndex,
} from "~/utils/ciq"
import {
  estimatesScheduleArr,
  financialsScheduleArr,
  getJWT,
  isCacheValid,
  priceScheduleArr,
} from "~/utils/tools"
import { defaultUsdObj } from "~/utils/constants/objects"

const ciqPeriodMap = {
  1: "1", // annual est & fin
  2: "2", // quarter est & fin
  10: "10", // semi annual est & fin
  7: "7", // calendar year est
  12: "12", // next twelve months est
  4: "4", // LTM fin
  3: "3", // YTD fin
  a: "1",
  q: "2",
  semi: "10",
  cy: "7",
  ntm: "12", // TODO: lookup "12" through codebase
  ltm: "4",
  ytd: "3",
}

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const dev = devStage === stage
const restrictResponse = process.env.USE_TIERS === "true"

const SLS_CIQ_ALT_URL = process.env.CIQ_LAMBDA_URL
const SLS_CIQ_URL = process.env.NEW_CIQ_LAMBDA_URL
const CDK_URL = process.env.API_URL

let ciqWorkerObj

if (
  USE_WORKER_CIQ_VALUATION ||
  USE_WORKER_CIQ_ESTIMATES ||
  USE_WORKER_CIQ_ACTUALS ||
  USE_WORKER_CIQ_FINANCIALS
) {
  const ciqWorker = new Worker("../ciq_worker.js", {
    name: "ciqWorker",
  })

  ciqWorkerObj = Comlink.wrap(ciqWorker)
}

const state = () => ({
  financialsError: null,
  actualsError: null,
  valuationError: null,
  dailyMultiplesError: null,
  quotesError: null,
  getTranscriptError: null,
  listTranscriptError: null,
  estimatesError: null,
  competitorsError: null,
  fetchingFinancials: true,
  fetchingActuals: true,
  fetchingValuation: true,
  fetchingDailyMultiples: true,
  fetchingQuotes: true,
  fetchingSingleTranscript: false,
  fetchingTranscriptList: true,
  fetchingEstimates: true,
  fetchingCompetitors: true,
  ticker: {
    // companyid: 29096,
    // companyname: "Alphabet Inc.",
    // tickersymbol: "GOOGL",
    // tradingitemid: 11311662,
    // tradingitemstatusid: 15,
  },
  quotes: {},
  financials: {},
  financialsChart: {},
  showFinancialsChart: true,
  showFinancialsChartLabels: false,
  actuals: {},
  estimates: {},
  estimatesChart: {},
  showEstimatesChart: true,
  showEstimatesChartLabels: false,
  estimatesCurrencies: [],
  estimatesCurrencyToggle: 0,
  estimatesPeriodToggle: 0,
  estimatesTableSelection: "ciq",
  estimatesDateRange: [0, 1],
  estimatesUnits: 1,
  estimatesDecimals: 2,
  ntm: {},
  valuationDecimals: 2,
  dailyMultiples: {},
  competitors: {},
  multiplesChart: {},
  showMultiplesChart: true,
  showMultiplesChartLabels: false,
  multiplesCurrencies: [],
  multiplesCurrencyToggle: 0,
  multiplesPeriodToggle: 1,
  multiplesTableSelection: "ciq",
  multiplesDateRange: [0, 1],
  multiplesUnits: 1,
  multiplesDecimals: 2,
  quoteCurrencyToggle: 0,
  quoteCurrencies: [],
  //
  financialsCurrencies: [],
  financialsCurrencyToggle: 0,
  // financialsPeriods: ["1", "2", "10"],
  financialsPeriodToggle: 0,
  financialsTableSelection: "ciq",
  financialsDateRange: [0, 1],
  financialsUnits: 1,
  financialsDecimals: 2,
  //
  transcripts: {},
  transcriptList: [],
  eventList: [],
  //
  multiplesChartTickers: {},
  financialsChartTickers: {},
  estimatesChartTickers: {},
  quotesChartTickers: {},
  //
  addMultiples: {}, // where additional multiples will be stored by TID
  compareMultiplesToUSD: false,
  fetchingAddMultiples: false,
  addMultiplesError: null,
  //
  fetchingAddFinancials: false,
  fetchingAddEstimates: false,
  fetchingAddQuotes: false,
  addFinancialsError: null,
  addEstimatesError: null,
  addQuotesError: null,
  compareFinancialsToUSD: false,
  compareEstimatesToUSD: false,
  addFinancials: {},
  addEstimates: {},
  addQuotes: {},
  companyViews: 0,
  usCompanyViews: 0,
  intlCompanyViews: 0,
})
// Estimates, Quotes, Transcripts, Events, Guidance

const mutations = {
  resetCIQ(state) {
    state.competitorsError = null
    state.fetchingCompetitors = true
    state.competitors = {}
    state.valuationError = null
    state.fetchingValuation = true
    state.dailyMultiplesError = null
    state.fetchingDailyMultiples = true
    state.getTranscriptError = null
    // state.fetchingSingleTranscript = true
    state.listTranscriptError = null
    state.fetchingTranscriptList = true
    state.financialsError = null
    state.fetchingFinancials = true
    state.quotesError = null
    state.fetchingQuotes = true
    state.actualsError = null
    state.fetchingActuals = true
    state.estimatesError = null
    state.fetchingEstimates = true
    state.estimates = {}
    state.estimatesChart = {}
    state.showEstimatesChartLabels = false
    state.ntm = {}
    state.dailyMultiples = {}
    state.multiplesChart = {}
    state.showMultiplesChartLabels = false
    state.ticker = {}
    state.quotes = {}
    state.financials = {} // don't reset this if you're going to store company financials in this object
    state.financialsChart = {} // don't reset this if you're going to store company financials in this object
    state.showFinancialsChartLabels = false
    state.actuals = {}
    state.quoteCurrencyToggle = 0
    state.quoteCurrencies = []
    state.estimatesCurrencies = []
    state.estimatesCurrencyToggle = 0
    state.estimatesPeriodToggle = 0
    state.estimatesTableSelection = "ciq"
    state.estimatesDateRange = [0, 1]
    state.financialsCurrencies = []
    state.financialsCurrencyToggle = 0
    state.financialsPeriodToggle = 0
    state.financialsTableSelection = "ciq"
    state.financialsDateRange = [0, 1]
    state.multiplesCurrencies = []
    state.multiplesCurrencyToggle = 0
    // state.multiplesPeriodToggle = 1 // this isn't tied to a fetch... yet
    state.multiplesTableSelection = "ciq"
    state.multiplesDateRange = [0, 1]
    // state.transcripts = {}
    state.transcriptList = []
    state.eventList = []
    state.fetchingAddMultiples = false
    state.addMultiplesError = null
    state.compareMultiplesToUSD = false

    state.fetchingAddQuotes = false
    state.addQuotesError = null
    state.fetchingAddFinancials = false
    state.addFinancialsError = null
    state.compareFinancialsToUSD = false
    state.fetchingAddEstimates = false
    state.addEstimatesError = null
    state.compareEstimatesToUSD = false
    // TODO:
    state.addMultiples = {}
    state.addFinancials = {}
    state.addEstimates = {}
    state.addQuotes = {}
    // but I don't really want to delete data that might be used later.. hmm

    state.multiplesChartTickers = {}
    state.financialsChartTickers = {}
    state.estimatesChartTickers = {}
    state.quotesChartTickers = {}

    // TODO: set the following based on the module
    // config.autoOpenChartOnRowClick
    // state.showFinancialsChart = true
    // state.showEstimatesChart = true
    // state.showMultiplesChart = true
  },
  setToggle(state, payload) {
    state[payload.type] = payload.value
  },
  setCurrencies(state, payload) {
    state[payload.type] = payload.value
  },
  setCIQFetch(state, payload) {
    state[payload.fetch] = payload.status
  },
  setTicker(state, payload) {
    state.ticker = payload
  },
  setCIQError(state, payload) {
    state[payload.error] = payload.status
  },
  setDailyMultiples(state, payload) {
    state.dailyMultiples = payload.data
  },
  addDailyChartData(state, payload) {
    const key = `add${payload.type}`
    const newState = {}
    newState[payload.tid] = payload.data
    state[key] = { ...state[key], ...newState }
  },
  addTableChartData(state, payload) {
    const type = payload.type || "Financials"
    const period = payload.period
    const oldPeriodState = state[`add${type}`][period] || {}
    oldPeriodState[payload.id] = payload.data
    const newState = {}
    newState[period] = { ...oldPeriodState }
    state[`add${type}`] = { ...state[`add${type}`], ...newState }
  },
  setFinancials(state, payload) {
    // I could even nest the companyId onto this financial object and save the financials.
    // 1st check if the payload.companyId is on state.financials.companyId
    // If not, add the companyId to state.financials object
    // then add state.financials.companyid[payload.period] = payload.data
    const previousState = state.financials
    const newState = {}
    newState[payload.period] = payload.data
    state.financials = { ...previousState, ...newState }
  },
  addToChart(state, payload) {
    const chartType = payload.chartType // 'financialsChart', 'estimatesChart' or 'multiplesChart'
    const period = payload.period
    const previousState = state[chartType] // previous state of rows to add to chart
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const newRow = {}
    newRow[payload.rowId] = payload.row
    newState[period] = { ...previousPeriodState, ...newRow }

    state[chartType] = { ...previousState, ...newState }
  },
  addTickerToDailyChart(state, payload) {
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const previousState = state[chartType]
    const newState = {}
    newState[payload.data.tradingitemid] = payload.data
    state[chartType] = { ...previousState, ...newState }
  },
  removeTickerFromDailyChart(state, payload) {
    // TODO: verify this works
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const previousState = state[chartType]
    const reduceObj = {}

    // this function different from removeFromChart because the
    // period doesn't matter for the chart
    state[chartType] = Object.keys(previousState)
      .filter((f) => f !== payload.tid)
      .reduce((acc, rowId) => {
        acc[rowId] = previousState[rowId] || {}
        return acc
      }, reduceObj)
  },
  addTickerToTableChart(state, payload) {
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const period = payload.period
    const previousState = state[chartType] || {} // {p1: {}, p2: {}} whole state for all periods

    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {} // need to replace the state {period: {companyid: {data}}}
    const newPeriodState = {}

    if (chartType === "financialsChartTickers") {
      newPeriodState[payload.data.companyid] = payload.data
    } else {
      newPeriodState[payload.data.tradingitemid] = payload.data
    }

    newState[period] = { ...previousPeriodState, ...newPeriodState }

    state[chartType] = { ...previousState, ...newState } // newState: {periodType: {}}
  },
  removeTickerFromTableChart(state, payload) {
    // TODO: verify this works
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const previousState = state[chartType]
    const period = payload.period
    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {}

    // this function different from removeFromChart because the
    // period doesn't matter for the chart
    newState[period] = Object.keys(previousPeriodState)
      .filter((f) => {
        if (chartType === "financialsChartTickers") {
          return f !== payload.cid
        } else {
          return f !== payload.tid
        }
      })
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, {})

    state[chartType] = { ...previousState, ...newState } // just replace the existing key
  },
  removeFromChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period.value ?? payload.period
    const previousState = state[chartType]
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const reduceObj = {}

    const rowRemoved = Object.keys(previousPeriodState)
      .filter((f) => f !== payload.rowId)
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, reduceObj)

    newState[period] = rowRemoved

    state[chartType] = { ...previousState, ...newState }
  },
  toggleSeriesType(state, payload) {
    try {
      const chartType = payload.chartType
      const period = payload.period
      const rowId = payload.rowId
      const previousState = state[chartType]
      const previousPeriodState = state[chartType][period] || {}

      const newState = {}
      const newRow = {}

      newRow[rowId] = previousPeriodState[rowId]
      newRow[rowId].seriesType = payload.seriesType
      newState[period] = { ...previousPeriodState, ...newRow }

      state[chartType] = { ...previousState, ...newState }
    } catch (error) {
      console.error("error toggling chart seriesType: ", error)
    }
  },
  clearChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period
    const previousState = state[chartType]

    const newState = {}
    newState[period] = {}
    state[chartType] = { ...previousState, ...newState }
  },
  setChartBool(state, payload) {
    const chartType = payload.chartType // showFinancialsChart, showEstimatesChart
    state[chartType] = !state[chartType]
  },
  setEstimates(state, payload) {
    /**
     * payload = {data: {fetchEstimates API response}, type: str, period: num, companyid: num, tradingitemid: num}
     * payload.type is either actuals or estimates
     * where actuals is the first response returned from the action fetchEstimates
     *  and estimates is the second response returned from the action fetchEstimates
     */
    const newState = {}
    if (payload.type === "actuals") {
      // transform the actual reponse into an object which has keys corresponding
      // to the dictionary of dataitems and an array of objects that can
      // be used to build the table UI FIXME: Do I need to try catch this?

      newState[payload.period] = {
        ...payload.data,
        type: payload.type,
        period: payload.period,
        companyid: payload.companyid,
        tradingItemId: payload.tradingitemid,
      }
    } else if (payload.type === "estimates") {
      // transform the estimates response
      // merge the estimate response with the response from the actuals to create
      // the final object that will contain a dictionary of dataitems {dataitem: {periodID: {v: value: u: units: c: currency: split: }}}
      // FIXME: Do I need to try catch this?
      newState[payload.period] = {
        ...payload.data,
        type: payload.type,
        period: payload.period,
        companyid: payload.companyid,
        tradingItemId: payload.tradingitemid,
      }
    } else {
      console.error("Error with estimates response")
    }

    // const previousState = state.estimates[payload.type] ... don't know why I had [payload.type]
    const previousState = state.estimates
    state.estimates = { ...previousState, ...newState }
  },
  setQuotes(state, payload) {
    state.quotes = payload
  },
  setTranscripts(state, payload) {
    state[payload.type] = payload.data
  },
  setSingleTranscript(state, payload) {
    const oldState = state.transcripts
    const newState = {}
    if (payload.unauth) {
      newState[payload.transcriptid] = "unauth"
      state.transcripts = { ...oldState, ...newState }
    } else {
      newState[payload.transcriptid] = payload.data
      state.transcripts = { ...oldState, ...newState }
    }
  },
  setCompetitors(state, payload) {
    state.competitors = { ...state.competitors, ...payload }
  },
  addCompanyView(state, payload) {
    state.companyViews = state.companyViews + 1
    if (payload.countryid === 213) {
      state.usCompanyViews = state.usCompanyViews + 1
    } else {
      state.intlCompanyViews = state.intlCompanyViews + 1
    }
  },
}

const actions = {
  async initialLoad({ commit, state, dispatch }, payload) {
    const t0 = performance.now()

    // initialLoad called when switching to a new security
    if (state.ticker.companyid !== payload.companyid) {
      commit("resetCIQ") // Reset the previous state to default values
    }
    // FIXME: If I wanted to store more than one companies information then I'd need to update this resetCIQ mutation
    commit("setTicker", payload) // 1st thing, set the new security on the store to reference in following code

    // check/refresh the current user token then allow fetching of resources
    // I have to keep checking the token otherwise after long breaks (6-12 hours) resorces will be requested
    // if there is no user token or it errors, just continue along routing change without the
    // user token and the persistant middleware will redirect to the appropriate place eh?
    try {
      const user = await this.$Amplify.Auth.currentAuthenticatedUser({
        bypassCache: true,
      })
      const jwt = user.signInUserSession.idToken.jwtToken
      commit(
        "updateCurrentUser",
        { user, from: "ciq initialLoad" },
        { root: true }
      )
      dispatch("fetchOrigQuotes", { ...payload, jwt }) // Get Quote chain to display historical price of security
      dispatch("fetchCompetitors", { ...payload, jwt })
      dispatch("fetchFinancials", { ...payload, period: "a", jwt }) // Get annual financials for the company
      dispatch("fetchEstimatesFromCache", { ...payload, period: "a", jwt }) // Get annual estimates of company financial performance
      dispatch("fetchTranscriptList", { ...payload, jwt }) // Get list of transcripts for the company
      dispatch("fetchValuation", { ...payload, period: "a", jwt }) // Get annual street estimates & historical actuals
      commit("addCompanyView", payload)
    } catch (error) {
      // I think this loop will only fire if there is an error with
      // this.$Amplify.Auth.currentAuthenticatedUser()...
      // I don't think any of the dispatch throw an error that bubbles up here
      // so we need to sign the user out if there is an error and reset
      // the state of the app
      commit("signOut", null, { root: true })
      commit("trkd/resetTRKD", null, { root: true })
      commit("ciq/resetCIQ", null, { root: true })
      commit("watchlist/resetState", null, { root: true })

      commit(
        "updateCurrentUser",
        {
          user: null,
          from: "logout",
        },
        { root: true }
      )
      commit("updateAuthDisplayMap", { data: "signedOut" }, { root: true })
    } finally {
      if (dev) {
        console.log(`initialLoad took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchEstimatesFromCache({ state, rootState, commit }, payload) {
    // payload is result of algolia search with additional property period
    const t0 = performance.now()
    // FIXME: this should be tradingitemid not company id? yes but its only looking
    // at the requested payload and the company that has been selected
    // or should it be a combinaton of both? Hmm that is too complicated
    if (
      Object.hasOwn(state.ticker, "tradingitemid") &&
      state.ticker.tradingitemid === payload.tradingitemid &&
      Object.hasOwn(state.estimates, payload.period)
    ) {
      // the estimates have already been populated, exit this action
      // TODO: Check if the stored estimates value is hot/cold
      const dates = state.estimates[payload.period].dates

      commit("setToggle", {
        type: `estimatesDateRange`,
        value: setDateRange(dates, rootState.config.allPeriodsDefault),
      })
      return
    }

    try {
      const backendPeriod = ciqPeriodMap[payload.period]
      const jwt = await getJWT(this.$Amplify)
      const body = {
        auth: jwt,
        cid: payload.companyid,
        tid: payload.tradingitemid,
        p: backendPeriod,
        v: restrictResponse ? "v1" : "v0",
      }
      commit("setCIQFetch", { fetch: "fetchingActuals", status: true })
      commit("setCIQFetch", { fetch: "fetchingEstimates", status: true })
      // Fetch date periods and estimate actuals associated with those date periods

      const ESTIMATES_URL = USE_CDK_CIQ
        ? `${CDK_URL}/est`
        : `${SLS_CIQ_URL}/${stage}/est`

      const { data } = await this.$axios.post(ESTIMATES_URL, body)

      // Create array of estimateConsensusId's that are only associated with estimatePeriodIds
      const estimatePeriods = data.dates
        ? data.dates.filter((p) => {
            // if annual 999, if quarterly 496
            // const compareValue =
            //   p.periodtypeid === 1 ? 999 : p.periodtypeid === 2 ? 496 : 999
            // Only grab estimates forward until you're doing something with previous
            // const compareValue =
            //   p.periodtypeid === 1 ? 1001 : p.periodtypeid === 2 ? 501 : 1001
            const compareValue =
              p.periodtypeid === 1 ? 1001 : p.periodtypeid === 7 ? 10001 : 501
            return p.relativeconstant >= compareValue
          })
        : []
      if (estimatePeriods.length > 0) {
        // transform the actual reponse into an object which has keys corresponding
        // to the dictionary of dataitems and an array of objects that can
        // be used to build the table UI
        // Save these actuals on the store, will use the actual reponse to incorporate
        // the estimates later

        const estimateTemplateCopy = cloneDeep(estimateTemplate)

        let actuals

        if (USE_WORKER_CIQ_ACTUALS) {
          await ciqWorkerObj.transformActualResponse(data, estimateTemplateCopy)

          actuals = await ciqWorkerObj.actuals
        } else {
          const { transformActualResponse } = await import("~/utils/ciq")

          actuals = transformActualResponse(data, estimateTemplateCopy)
        }

        commit("setToggle", {
          type: `estimatesDateRange`,
          value: setDateRange(
            actuals.dates,
            rootState.config.allPeriodsDefault
          ),
        })
        commit("setEstimates", {
          data: actuals,
          type: "actuals",
          period: payload.period,
          companyid: payload.companyid,
          tradingitemid: payload.tradingitemid,
        })
        estimateCurrencies(actuals.isoCounter, commit)
        // I'm not going to use this in case there are more estimates lineitems than actuals
        // const availableActualIds = new Set()
        // res.data.actuals.forEach(value => {
        //   availableActualIds.add(value.dataitemid)
        // })
        // determine the available estimate data items based on the actuals that were
        // returned from the first fetch in an attempt to speed up the query
        // const availableEstimateDataItems = Array.from(availableActualIds).reduce(
        //   (acc, actual) => {
        //     return acc.concat(estimateDataItems[2][actual])
        //   },
        //   []
        // )
        commit("setCIQFetch", { fetch: "fetchingActuals", status: false })
        if (dev) {
          console.log(
            `fetching estimatePeriods took ${performance.now() - t0} ms`
          )
        }

        // FIXME: cloning these objects deep to prevent Do not Mutate Vuex Items outside of
        // mutation handlers but damn now these deep clones are adding time
        // Can I build with that error? At least it isn't causing jank and sync calc now

        const actData = cloneDeep(state.estimates[payload.period])
        const estMap = cloneDeep(estimateDataItems[3])

        let estimates

        if (USE_WORKER_CIQ_ESTIMATES) {
          await ciqWorkerObj.transformEstimateResponse(
            data,
            actData,
            estMap,
            backendPeriod
          )

          estimates = await ciqWorkerObj.estimates
        } else {
          const { transformEstimateResponse } = await import("~/utils/ciq")

          estimates = transformEstimateResponse(
            data,
            actData,
            estMap,
            backendPeriod
          )
        }

        estimateCurrencies(estimates.isoCounter, commit)

        commit("setEstimates", {
          data: estimates,
          type: "estimates",
          period: payload.period,
          companyid: payload.companyid,
          tradingitemid: payload.tradingitemid,
        })
      } else {
        // No Estimate Periods for this period type
        // TODO: Figure out how to handle and display this error
        commit("setEstimates", {
          data: { error: "No estimateconsensusids for the period" },
          type: "estimates",
          period: payload.period,
          companyid: payload.companyid,
          tradingitemid: payload.tradingitemid,
        })
      }
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching Estimates",
        },
        error: "estimatesError",
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingActuals", status: false })
      commit("setCIQFetch", { fetch: "fetchingEstimates", status: false })
      if (dev) {
        console.log(`fetchEstimates took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchTranscriptList({ commit }, payload) {
    // fetch a list of available transcripts
    // payload is the results of a algolia search
    const t0 = performance.now()
    try {
      const body = {
        auth: payload.jwt,
        cid: payload.companyid,
        tid: payload.tradingitemid,
        v: restrictResponse ? "v1" : "v0",
      }
      commit("setCIQFetch", { fetch: "fetchingTranscriptList", status: true })

      const TRANSCRIPTS_URL = USE_CDK_CIQ
        ? `${CDK_URL}/transcripts`
        : `${SLS_CIQ_ALT_URL}/${stage}/transcripts`

      const res = await this.$axios.post(TRANSCRIPTS_URL, body)

      commit("setTranscripts", { type: "transcriptList", data: res.data.data })
      commit("setTranscripts", { type: "eventList", data: res.data.events })
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching transcript list",
        },
        error: "transcriptListError",
      })
    } finally {
      commit("setCIQFetch", {
        fetch: "fetchingTranscriptList",
        status: false,
      })
      if (dev) {
        console.log(`fetch transcript list took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchTranscript({ state, rootState, commit }, payload) {
    // payload is the results of a algolia search
    const t0 = performance.now()
    try {
      if (state.transcripts[payload.transcriptid]) {
        // transcript exits
        return
      }
      const body = {
        auth: rootState.user.signInUserSession.idToken.jwtToken,
        eid: payload.keydevid,
        tid: payload.transcriptid,
        v: restrictResponse ? "v1" : "v0",
        id: payload.tradingitemid,
        cid: payload.companyid,
      }
      commit("setCIQFetch", { fetch: "fetchingSingleTranscript", status: true })

      const TRANSCRIPT_URL = USE_CDK_CIQ
        ? `${CDK_URL}/transcript`
        : `${SLS_CIQ_ALT_URL}/${stage}/transcript`

      const { data } = await this.$axios.post(TRANSCRIPT_URL, body)

      if (data.unauth) {
        commit("setSingleTranscript", {
          transcriptid: payload.transcriptid,
          unauth: true,
        })
      } else {
        commit("setSingleTranscript", {
          transcriptid: payload.transcriptid,
          data: data.data,
        })
      }
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: `fetching single transcript`,
        },
        error: "single transcript error",
      })
    } finally {
      commit("setCIQFetch", {
        fetch: "fetchingSingleTranscript",
        status: false,
      })
      if (dev) {
        console.log(`fetch single transcript took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchOrigQuotes({ commit }, payload) {
    const t0 = performance.now()
    // payload is the results of a algolia search
    try {
      commit("setCIQFetch", { fetch: "fetchingQuotes", status: true })
      const parsedRes = await ogQuotesFetch(this.$axios, payload)
      // setQuotes(state, payload) {
      //   state.quotes = payload
      // }
      // parsedRes = {price: [], company: {}, fin: }
      commit("setQuotes", parsedRes)
      // FIXME: Before going into this object check if things exist to prevent throwing error
      // and generate better (more informed) error messages (No Data! opposed to ERROR)
      const defaultCurrency = {
        code: payload.isocode,
        name: payload.currencyname,
      }
      if (defaultCurrency.code === "USD") {
        commit("setCurrencies", {
          type: "quoteCurrencies",
          value: [defaultCurrency],
        })
      } else {
        commit("setCurrencies", {
          type: "quoteCurrencies",
          value: [defaultCurrency, defaultUsdObj],
        })
      }
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching quotes",
        },
        error: "quotesError",
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingQuotes", status: false })
      if (dev) {
        console.log(`fetchOrigQuotes took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchCompetitors({ commit }, payload) {
    const t0 = performance.now()
    try {
      const body = {
        auth: payload.jwt,
        companyids: [[payload.companyid, payload.tradingitemid]],
        // tid: payload.tradingitemid,
        // currency: payload.exchangecurrencyid
        v: restrictResponse ? "v1" : "v0",
      }

      commit("setCIQFetch", { fetch: "fetchingCompetitors", status: true })

      // const WATCHLIST_PRICE_URL = USE_CDK_CIQ
      //   ? `${CDK_URL}/wlp`
      //   : `${SLS_CIQ_ALT_URL}/${stage}/wlp`

      const WATCHLIST_PRICE_URL = `${SLS_CIQ_ALT_URL}/${stage}/wlp`

      const res = await this.$axios.post(WATCHLIST_PRICE_URL, body)

      if (res.data && res.data.comp.length > 0) {
        // competitors exist!
        // const ciq = transformWatchlistResponse(res.data)
        // now I probably need to do some sort of join
        // commit("setCompetitors", { comp: res.data.comp, ...ciq })
        commit("setCompetitors", { comp: res.data.comp })
      } else {
        commit("setCompetitors", { comp: [] })
      }
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching valuation competitors",
        },
        error: "competitorsError",
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingCompetitors", status: false })
      if (dev) {
        console.log(`fetchCompetitors took ${performance.now() - t0} ms`)
      }
    }
  },
  async valuationOneStep({ commit, state }, payload) {
    /**
     * dispatch multiplesTwoStep to get histMultiples and dailyMultiples for a
     * Ticker selected through the addTicker button
     */
    const t0 = performance.now()
    try {
      commit("setCIQError", {
        status: null,
        error: "addMultiplesError",
      })

      commit("addTickerToDailyChart", {
        chartType: "multiplesChart",
        data: payload,
      })
      // TODO: Add payload to Additional Tickers Array
      // check if the data already exists on the state
      const now = new Date()
      const oldData = state.addMultiples[payload.tradingitemid] // oldData.f = Date oldData was fetched
      if (oldData?.f && isCacheValid(oldData.f, now, priceScheduleArr)) {
        // this tradingItem already exists on the state with data
        // that is valid, no need to fetch
        return
      }
      commit("setCIQFetch", { fetch: "fetchingAddMultiples", status: true })
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      commit(
        "updateCurrentUser",
        { user, from: "ciq initialLoad" },
        { root: true }
      )
      const { dailyMultiples } = await fetchAllMultiples(
        this.$axios,
        payload,
        user
      )
      dailyMultiples.f = now
      commit("addDailyChartData", {
        tid: payload.tradingitemid,
        data: dailyMultiples,
        type: "Multiples",
      })
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching additional multiples for addTicker",
        },
        error: "addMultiplesError", // TODO: change the error type
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingAddMultiples", status: false })
      if (dev) {
        console.log(
          `addTicker Multiple fetch took ${performance.now() - t0} ms`
        )
      }
    }
  },
  async tableChartOneStep({ commit, state }, payload) {
    const type = payload.type
    const chartType = `${type.toLowerCase()}Chart`
    const period = payload.period || "a"
    const t0 = performance.now()
    const scheduleArray =
      type === "Estimates" ? estimatesScheduleArr : financialsScheduleArr
    try {
      commit("setCIQError", {
        status: null,
        error: `add${type}Error`,
      })

      commit("addTickerToTableChart", {
        chartType,
        period,
        data: payload.ticker,
      })
      const now = new Date()
      // TODO FIX THIS
      commit("setCIQFetch", { fetch: `fetchingAdd${type}`, status: true })
      const prevPeriodState = state[`add${type}`][period] || {}
      const oldData =
        type === "Financials"
          ? prevPeriodState[payload.ticker.companyid]
          : prevPeriodState[payload.ticker.tradingitemid]
      if (oldData?.f && isCacheValid(oldData.f, now, scheduleArray)) {
        // this tradingItem already exists on the state with data
        // that is valid, no need to fetch
        return
      }

      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      commit(
        "updateCurrentUser",
        { user, from: "ciq initialLoad" },
        { root: true }
      )
      if (type === "Financials") {
        const { data } = await fetchAllFinancials(
          this.$axios,
          { period, ...payload.ticker },
          user
        )
        data.f = now
        commit("addTableChartData", {
          id: payload.ticker.companyid,
          period,
          type,
          data,
        })
      } else {
        // I have no idea why this isn't destructuring
        const est = await fetchAllEstimates(
          this.$axios,
          { period, ...payload.ticker },
          user
        )
        const result = est.data
        result.f = now // i see no f thats why
        commit("addTableChartData", {
          id: payload.ticker.tradingitemid,
          period,
          type,
          data: result,
        })
      }
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: `fetching additional ${type} for tableChartOneStep`,
        },
        error: `add${type}Error`,
      })
    } finally {
      commit("setCIQFetch", { fetch: `fetchingAdd${type}`, status: false })
      if (dev) {
        console.log(
          `additional ${type} fetch took ${performance.now() - t0} ms`
        )
      }
    }
  },
  async quotesOneStep({ commit, state }, payload) {
    const t0 = performance.now()
    try {
      commit("setCIQError", {
        status: null,
        error: "addQuotesError",
      })

      commit("addTickerToDailyChart", {
        chartType: payload.chartType,
        data: payload.ticker,
      })
      const id = payload.ticker
        ? payload.ticker.tradingitemid
        : payload.tradingitemid
      // TODO: Add payload to Additional Tickers Array
      // check if the data already exists on the state
      const now = new Date()
      const oldData = state.addQuotes[id] // oldData.f = Date oldData was fetched
      if (oldData?.f && isCacheValid(oldData.f, now, priceScheduleArr)) {
        // this tradingItem already exists on the state with data
        // that is valid, no need to fetch
        return
      }
      commit("setCIQFetch", { fetch: "fetchingAddQuotes", status: true })
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      const jwt = user.signInUserSession.idToken.jwtToken
      commit(
        "updateCurrentUser",
        { user, from: "ciq initialLoad" },
        { root: true }
      )
      const parsedRes = await ogQuotesFetch(this.$axios, {
        ...payload.ticker,
        jwt,
      })
      parsedRes.f = now
      commit("addDailyChartData", {
        tid: id,
        data: parsedRes,
        type: "Quotes",
      })
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching additional quotes for addTicker",
        },
        error: "addQuotesError", // TODO: change the error type
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingAddQuotes", status: false })
      if (dev) {
        console.log(`addTicker Quotes fetch took ${performance.now() - t0} ms`)
      }
    }
  },
  // actually fetches both ntm and ltm data for the multiples table
  async fetchValuation({ commit }, payload) {
    const t0 = performance.now()
    // payload is the results of algolia search
    try {
      commit("setCIQFetch", { fetch: "fetchingValuation", status: true })
      const body1 = {
        auth: payload.jwt,
        cid: payload.companyid,
        tid: payload.tradingitemid,
        currency: payload.exchangecurrencyid,
        v: restrictResponse ? "v2" : "v1",
        ntmIds: [],
        streetIds: [],
      }

      const DAILY_URL = USE_CDK_CIQ
        ? `${CDK_URL}/daily`
        : `${SLS_CIQ_URL}/${stage}/daily`

      const { data: daily } = await this.$axios.post(DAILY_URL, body1)

      commit("addTickerToDailyChart", {
        chartType: "multiplesChart",
        data: payload,
      })

      let transformed

      if (USE_WORKER_CIQ_VALUATION) {
        await ciqWorkerObj.transformAllValuationData(daily)

        transformed = await ciqWorkerObj.valuation
      } else {
        const { transformAllValuationData } = await import("~/utils/ciq")

        transformed = transformAllValuationData(daily)
      }

      const now = new Date()
      transformed.f = now
      commit("addDailyChartData", {
        tid: payload.tradingitemid,
        data: transformed,
        type: "Multiples",
      })
      commit("setDailyMultiples", { data: transformed.dailyMultiples })

      // dispatch("fetchDailyMultiples", payload)
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching valuation data",
        },
        error: "valuationError",
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingValuation", status: false })
      if (dev) {
        console.log(`fetchValuation took ${performance.now() - t0} ms`)
      }
    }
  },
  async fetchFinancials(
    { state, rootState, commit },
    {
      companyid,
      period,
      reportingtemplatetypeid,
      tradingitemid,
      selectedDateRange,
    }
  ) {
    const t0 = performance.now()
    // payload is the results of a algolia search
    // Check if there is a ticker on the state

    // Knowing there is a ticker, check if the new payload is equal to the
    // If you nest companyId into state.financials object will have to walk the tree
    // instead of the following if loop
    if (
      Object.hasOwn(state.ticker, "companyid") &&
      state.ticker.companyid === companyid &&
      Object.hasOwn(state.financials, period)
    ) {
      // TODO: check if the stored financials value is hot/cold
      const dates = state.financials[period].dates
      commit("setToggle", {
        type: `financialsDateRange`,
        value: setDateRange(dates, rootState.config.allPeriodsDefault),
      })

      if (selectedDateRange?.length > 0) {
        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], dates),
              findClosestDateIndex(selectedDateRange[1], dates),
            ],
          },
          { root: true }
        )
      }
      return
    }

    try {
      const backendPeriod = ciqPeriodMap[period]
      const jwt = await getJWT(this.$Amplify)
      const body = {
        auth: jwt,
        cid: companyid,
        tid: tradingitemid,
        p: backendPeriod,
        repid: reportingtemplatetypeid,
        v: restrictResponse ? "v1" : "v0",
      }
      commit("setCIQFetch", { fetch: "fetchingFinancials", status: true })

      const FINANCIALS_URL = USE_CDK_CIQ
        ? `${CDK_URL}/fin`
        : `${SLS_CIQ_URL}/${stage}/fin`

      const { data } = await this.$axios.post(FINANCIALS_URL, body)

      if (data.dates && data.dates.length > 0) {
        const currenciesArr = data.dates.reduce((acc, date) => {
          // making sure there are no duplicates
          if (acc.filter((i) => i.isocode === date.isocode).length === 0) {
            acc.push(date)
          }
          return acc
        }, [])
        const currenciesLength = currenciesArr.length
        const lastCurrency = {
          code: currenciesArr[currenciesLength - 1]
            ? currenciesArr[currenciesLength - 1].isocode
            : "USD",
          name: currenciesArr[currenciesLength - 1]
            ? currenciesArr[currenciesLength - 1].currencyname
            : "US Dollars",
        }
        const firstCurrency = {
          code: currenciesArr[0] ? currenciesArr[0].isocode : "USD",
          name: currenciesArr[0] ? currenciesArr[0].currencyname : "US Dollars",
        }
        if (currenciesArr.length > 1) {
          // FIXME: reported currency changes
          lastCurrency.code = "MIXED"
          lastCurrency.name = `Mixed ${lastCurrency.name} & ${firstCurrency.name}`
        }
        if (lastCurrency.code === "USD") {
          commit("setCurrencies", {
            type: "financialsCurrencies",
            value: [lastCurrency],
          })
        } else {
          commit("setCurrencies", {
            type: "financialsCurrencies",
            value: [lastCurrency, defaultUsdObj],
          })
        }
      } else {
        // does this mean there is no financial information?
        // should I set an error and stop the rest of this action?
        commit("setCurrencies", {
          type: "financialsCurrencies",
          value: [defaultUsdObj],
        })
        commit("setCIQError", {
          status: {
            error: "Sorry! No financial information for this ticker",
            loc: "fetchFinancials",
          },
        })

        return
      }

      let finData

      if (USE_WORKER_CIQ_FINANCIALS) {
        await ciqWorkerObj.transformFinancialResponse(
          data,
          financialTemplates,
          reportingtemplatetypeid,
          backendPeriod
        )

        finData = await ciqWorkerObj.financials
      } else {
        const { transformFinancialResponse } = await import("~/utils/ciq")

        finData = transformFinancialResponse(
          data,
          financialTemplates,
          reportingtemplatetypeid,
          backendPeriod
        )
      }

      if (selectedDateRange?.length > 0) {
        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], finData.dates),
              findClosestDateIndex(selectedDateRange[1], finData.dates),
            ],
          },
          { root: true }
        )
      } else {
        commit("setToggle", {
          type: `financialsDateRange`,
          value: setDateRange(
            finData.dates,
            rootState.config.allPeriodsDefault
          ),
        })
      }

      commit("setFinancials", {
        data: finData,
        period,
      })
    } catch (error) {
      console.error(error)
      commit("setCIQError", {
        status: {
          error,
          loc: "fetching annual financials",
        },
        error: "financialsError",
      })
    } finally {
      commit("setCIQFetch", { fetch: "fetchingFinancials", status: false })
      if (dev) {
        console.log(`fetchFinancials took ${performance.now() - t0} ms`)
      }
    }
  },
}

const estimateCurrencies = (isoCounter, commit) => {
  const currencies = Object.keys(isoCounter)
    .filter((f) => f !== "null")
    .map((c) => isoCounter[c])
    .sort((a, b) => b.count - a.count)
  const defaultCurrency =
    currencies.length === 1
      ? currencies[0]
      : currencies.reduce(
          (acc, iso) => {
            if (acc.name) {
              acc = `& ${iso.name}`
            } else {
              acc = iso.name
            }
            return acc
          },
          { code: "Mixed" }
        )
  // determine the defaultCurrency
  // set estimatesCurrencies
  if (defaultCurrency.code === "USD") {
    commit("setCurrencies", {
      type: "estimatesCurrencies",
      value: [defaultCurrency],
    })
  } else {
    commit("setCurrencies", {
      type: "estimatesCurrencies",
      value: [defaultCurrency, defaultUsdObj],
    })
  }
}

const fetchAllMultiples = async (axios, payload, user) => {
  const body = {
    auth: user.signInUserSession.idToken.jwtToken,
    cid: payload.companyid,
    tid: payload.tradingitemid,
    currency: payload.exchangecurrencyid,
    v: restrictResponse ? "v2" : "v1",
    ntmIds: [],
    streetIds: [],
  }

  const DAILY_URL = USE_CDK_CIQ
    ? `${CDK_URL}/daily`
    : `${SLS_CIQ_URL}/${stage}/daily`

  const { data } = await axios.post(DAILY_URL, body)

  let transformed

  if (USE_WORKER_CIQ_VALUATION) {
    await ciqWorkerObj.transformAllValuationData(data)

    transformed = await ciqWorkerObj.valuation
  } else {
    const { transformAllValuationData } = await import("~/utils/ciq")

    transformed = transformAllValuationData(data)
  }

  return transformed
}

const quickEstTransform = (datesArr, estimateLineitems, estPeriodType) => {
  const estPeriodToCalPeriod = datesArr.reduce((acc, d) => {
    if (estPeriodType === "1" || estPeriodType === "7") {
      acc[d.estimateconsensusid] = `${d.calendaryear}`
    } else {
      // quarterly, fiscal half, ntm (quarterly)
      acc[d.estimateconsensusid] = `${d.calendaryear}${d.calendarquarter}`
    }
    return acc
  }, {})
  const estRes = estimateLineitems[0]
    .filter((f) => f.formula !== "h3")
    .reduce((acc, row) => {
      const rowId = rowObjToSelectedKey(row)
      const name = row.name.trim()
      // this is assuming no overlap on actuals and estimate periods
      const rowMean = row.mean || {}
      const rowActuals = row.actual || {}
      const actuals = Object.keys(rowActuals).reduce((acc, key) => {
        // FIXME: this is where you tune the interface of the data object
        const a = rowActuals[key]
        const newPeriodId = estPeriodToCalPeriod[a.estimateconsensusid]
        if (newPeriodId) {
          acc[newPeriodId] = a
        } else {
          // ... missing estimateconsensusid to estimateperiod
        }
        return acc
      }, {})
      // now make the dataObject..
      const withMean = Object.keys(rowMean).reduce((acc, key) => {
        const m = rowMean[key]
        const newPeriodId = estPeriodToCalPeriod[m.estimateconsensusid]
        if (newPeriodId) {
          acc[newPeriodId] = m
        } else {
          //
        }
        return acc
      }, actuals)

      withMean.name = name
      acc[rowId] = withMean
      return acc
    }, {})
  const keyToNameMap = Object.keys(estRes).reduce((acc, key) => {
    const finObj = estRes[key]
    let name = finObj.name
    const splitKey = key.split("-") // FIXME: if you change how the key works this will also have to change
    if (splitKey[0] === "dxdt") {
      const prevNameKey = `val-${splitKey[1]}`
      const prevName = acc[prevNameKey]
      name = `${prevName} ${name}`
    }
    acc[key] = name
    return acc
  }, {})
  const nameToKeyMap = Object.keys(keyToNameMap).reduce((acc, key) => {
    const name = keyToNameMap[key]
    acc[name] = key
    return acc
  }, {})
  estRes.keyToName = keyToNameMap
  estRes.nameToKey = nameToKeyMap
  return estRes
}

const fetchAllEstimates = async (axios, payload, user) => {
  const backendPeriod = ciqPeriodMap[payload.period]
  const body = {
    auth: user.signInUserSession.idToken.jwtToken,
    cid: payload.companyid,
    tid: payload.tradingitemid,
    p: backendPeriod,
    v: restrictResponse ? "v1" : "v0",
  }

  const ESTIMATES_URL = USE_CDK_CIQ
    ? `${CDK_URL}/est`
    : `${SLS_CIQ_URL}/${stage}/est`

  const { data } = await axios.post(ESTIMATES_URL, body)

  const estimatePeriods = data.dates
    ? data.dates.filter((p) => {
        const compareValue =
          p.periodtypeid === 1 ? 1001 : p.periodtypeid === 7 ? 10001 : 501
        return p.relativeconstant >= compareValue
      })
    : []
  if (estimatePeriods.length > 0) {
    // transform the actual reponse into an object which has keys corresponding
    // to the dictionary of dataitems and an array of objects that can
    // be used to build the table UI
    // Save these actuals on the store, will use the actual reponse to incorporate
    // the estimates later

    const estimateTemplateCopy = cloneDeep(estimateTemplate)

    let actuals

    if (USE_WORKER_CIQ_ACTUALS) {
      await ciqWorkerObj.transformActualResponse(data, estimateTemplateCopy)

      actuals = await ciqWorkerObj.actuals
    } else {
      const { transformActualResponse } = await import("~/utils/ciq")

      actuals = transformActualResponse(data, estimateTemplateCopy)
    }

    const estMap = cloneDeep(estimateDataItems[3])

    let estimates

    if (USE_WORKER_CIQ_ESTIMATES) {
      await ciqWorkerObj.transformEstimateResponse(
        data,
        actuals,
        estMap,
        backendPeriod
      )

      estimates = await ciqWorkerObj.estimates
    } else {
      const { transformEstimateResponse } = await import("~/utils/ciq")

      estimates = transformEstimateResponse(
        data,
        actuals,
        estMap,
        backendPeriod
      )
    }

    const result = quickEstTransform(
      data.dates,
      estimates.estimates,
      backendPeriod
    )

    return {
      data: result,
      type: "estimates",
      period: payload.period,
      companyid: payload.companyid,
      tradingitemid: payload.tradingitemid,
    }
  }

  throw new Error("Sorry, no estimates for this ticker")
}

const quickFinTransform = (datesArr, financialsLineItems, finPeriodType) => {
  // I don't know if finPeriodtoCalPeriod is needed anymore
  const finPeriodToCalPeriod = datesArr.reduce((acc, d) => {
    if (finPeriodType === "1") {
      if (d.periodtypeid === 4) {
        acc[d.value] = `${d.calendaryear}##${d.calendarquarter}`
      } else {
        acc[d.value] = `${d.calendaryear}##FY`
        // acc[d.value] = `${d.calendaryear}##${d.calendarquarter}`
      }
    } else {
      // quarterly, fiscal half, ntm (quarterly)
      acc[d.value] = `${d.calendaryear}##${d.calendarquarter}`
    }
    return acc
  }, {})
  const finRes = financialsLineItems.reduce((outAcc, lineitems) => {
    const result = lineitems
      .filter((f) => f.formula !== "h3")
      .reduce((acc, row) => {
        const rowId = rowObjToSelectedKey(row)
        const name = row.name.trim()
        // this is assuming no overlap on actuals and estimate periods
        const financials = Object.keys(finPeriodToCalPeriod).reduce(
          (acc, key) => {
            // FIXME: this is where you tune the interface of the data object
            const a = row[key]
            const newPeriodId = finPeriodToCalPeriod[key]
            if (newPeriodId && a) {
              acc[newPeriodId] = a
            } else {
              // ... missing financialperiodid to estimateperiod
            }
            return acc
          },
          {}
        )
        // now make the dataObject..
        financials.name = name

        acc[rowId] = { ...row, ...financials }
        return acc
      }, {})
    return { ...outAcc, ...result }
  }, {})
  const keyToNameMap = Object.keys(finRes).reduce((acc, key) => {
    const finObj = finRes[key]
    let name = finObj.name
    const splitKey = key.split("-") // FIXME: if you change how the key works this will also have to change
    if (splitKey[0] === "dxdt") {
      const prevNameKey = `val-${splitKey[1]}`
      const prevName = acc[prevNameKey]
      name = `${prevName} ${name}`
    }
    acc[key] = name
    return acc
  }, {})
  const nameToKeyMap = Object.keys(keyToNameMap).reduce((acc, key) => {
    const name = keyToNameMap[key]
    acc[name] = key
    return acc
  }, {})
  finRes.keyToName = keyToNameMap
  finRes.nameToKey = nameToKeyMap
  return finRes
}

const fetchAllFinancials = async (axios, payload, user) => {
  const backendPeriod = ciqPeriodMap[payload.period]
  const body = {
    auth: user.signInUserSession.idToken.jwtToken,
    cid: payload.companyid,
    tid: payload.tradingitemid,
    p: backendPeriod,
    repid: payload.reportingtemplatetypeid,
    v: restrictResponse ? "v1" : "v0",
  }

  const CDK_FIN_VERSION = 1
  const FINANCIALS_URL = USE_CDK_CIQ
    ? `${CDK_URL}/v${CDK_FIN_VERSION}/fin`
    : `${SLS_CIQ_URL}/${stage}/fin`

  const { data } = await axios.post(FINANCIALS_URL, body)

  // FIXME: Need some error handling here, possible data.dates is an empty array
  // something like if data.dates.length > 0 then do all of this stuff otherwise there is no info?
  if (data.dates && data.dates.length > 0) {
    let finData

    if (USE_WORKER_CIQ_FINANCIALS) {
      await ciqWorkerObj.transformFinancialResponse(
        data,
        financialTemplates,
        payload.reportingtemplatetypeid,
        backendPeriod
      )

      finData = await ciqWorkerObj.financials
    } else {
      const { transformFinancialResponse } = await import("~/utils/ciq")

      finData = transformFinancialResponse(
        data,
        financialTemplates,
        payload.reportingtemplatetypeid,
        backendPeriod
      )
    }

    const result = quickFinTransform(
      data.dates,
      finData.financials,
      backendPeriod
    )

    return {
      data: result,
      financials: finData,
      period: payload.period,
      companyid: payload.companyid,
    }
  }

  throw new Error("Sorry, no financials for this ticker")
}

const ogQuotesFetch = async (axios, payload) => {
  const body = {
    auth: payload.jwt,
    tid: payload.tradingitemid,
    cid: payload.companyid,
    currency: payload.exchangecurrencyid,
    v: "v1",
  }

  const PRICE_URL = USE_CDK_TRKD
    ? `${CDK_URL}/price`
    : `${SLS_CIQ_URL}/${stage}/price`

  const res = await axios.post(PRICE_URL, body)

  const parsedRes = { price: [] }
  parsedRes.price = res.data.price.map((p) => {
    const parse = {
      d: Date.parse(p.d), // this makes a ms epoch time date for highcharts
      o: parseFloat(p.o),
      h: parseFloat(p.h),
      l: parseFloat(p.l),
      c: parseFloat(p.c),
      af: parseFloat(p.a),
      pc: parseFloat(p.pc),
      v: parseInt(p.v),
    }
    return parse // { ...p, ...parse }
  })
  // company should only contain one row, most recent value for
  // marketcap, TEV, and shares outstanding
  const rawCompany = res.data.company[0]
  const parsedCompany = {}
  if (rawCompany) {
    parsedCompany.marketcap = parseFloat(rawCompany.marketcap)
    parsedCompany.priceclose = parseFloat(rawCompany.priceclose)
    parsedCompany.sharesoutstanding = parseInt(rawCompany.sharesoutstanding)
    parsedCompany.tev = parseFloat(rawCompany.tev)
  }
  // pricingdate: Date.parse(pricingdate),
  parsedRes.company = { ...rawCompany, ...parsedCompany }
  const rawFinArr = res.data?.fin || []
  // WARNING: /price query .fin is currently expecting as
  // expressed in this code block to return objects that
  // contain dataitemname which is a pattern you'll probably be moving
  // away from so if you change this reponse need to just watch out here
  // although you'll probably increment the /price query to v1 so... all good?
  parsedRes.fin = rawFinArr.reduce((acc, finObj) => {
    const name = finObj.dataitemname.toLowerCase().trim()
    acc[name] = finObj
    return acc
  }, {})
  return parsedRes
}

export { state, mutations, actions }
