export const estimatesTabs = [
  { label: "Actuals & Forward Estimates", slug: "est" },
  { label: "Management Guidance", slug: "mgmt" },
  { label: "Earnings Review", slug: "er" },
  { label: "Beats & Misses", slug: "bm" },
  { label: "Estimates Breakdown", slug: "eb" },
]

export const financialTabs = [
  { statement: "Income Statement", keys: [], slug: "is" },
  { statement: "Balance Sheet", keys: [], slug: "bs" },
  { statement: "Cash Flow Statement", keys: [], slug: "cf" },
  { statement: "Ratios", keys: [], slug: "r" },
  { statement: "Segments", keys: [], slug: "seg" },
]

export const dataSourceTabs = (showMorningstar) => {
  const datasources = [
    { text: "Main TIKR Data", value: "ciq", slug: "tikr" },
    { text: "Secondary Data", value: "fmp", slug: "alt" },
  ]

  if (showMorningstar) {
    datasources.push({
      text: "Morningstar (Beta)",
      value: "morningstar",
      slug: "ms",
    })
  }

  return datasources
}

export const valuationTabs = [
  { label: "Multiples", slug: "multi" },
  { label: "Street Targets", slug: "street" },
  { label: "Competitors", slug: "comp" },
]

/**
 * Finds the index of a statement object in an array based on its slug.
 *
 * @param {Array} statementArr - An array of statement objects.
 * @param {string} slug - The slug of the statement to find.
 * @returns {number} The index of the found statement object, or 0 if not found or out of range.
 */
export const findStatementIndex = (statementArr, slug) => {
  const index = statementArr.findIndex((item) => item.slug === slug)
  if (index > 0 && index < statementArr.length) {
    return index
  } else {
    return 0
  }
}
