import StepBase from "~/components/onboarding/StepBase.vue"
import useBaseUtils from "~/functions/useBaseUtils"


export default {
  __name: 'StepAnalystEstimates',
  setup(__props) {

const { I18nFn } = useBaseUtils()

return { __sfc: true,I18nFn, StepBase }
}

}