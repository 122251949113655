const tierRestrictions = {
  free: {
    models: 3,
    historical: [-10],
    forecast: 2,
  },
  plus: {
    models: 20,
    forecast: 3,
  },
  pro: {
    models: 50,
  },
}

const getTierRestrictions = (userTier) => {
  // Lock the same data as free tier if no tier is found
  if (!userTier) {
    return tierRestrictions.free
  }
  if (userTier.endsWith("-free")) {
    return tierRestrictions.free
  }
  if (userTier.endsWith("-plus")) {
    return tierRestrictions.plus
  }
  return tierRestrictions.pro
}

const getModelCount = (allModelsObject = {}) => {
  try {
    return Object.keys(allModelsObject).reduce((acc, key) => {
      const amountOfModels = allModelsObject[key].length || 0
      acc = acc + amountOfModels
      return acc
    }, 0)
  } catch (error) {
    return 0
  }
}

const checkForModelLimit = ({ userTier, amountOfModels }) => {
  if (amountOfModels === 0) {
    return true
  }
  const tierRestrictionsObj = getTierRestrictions(userTier)
  return amountOfModels >= tierRestrictionsObj.models
}

export {
  checkForModelLimit,
  getModelCount,
  getTierRestrictions,
  tierRestrictions,
}
