import { utcParse } from "d3-time-format"

const parseTime = utcParse("%Y-%m-%dT%H:%M:%S.%LZ")

// LINK static/ciq_worker.js:2278
const ntmArrToObj = {
  d: 0,
  did: 1,
  erc: 2,
  v: 3,
  sf: 4,
  s: 5,
  curr: 6,
  iso: 7,
  pc: 8,
  unauth: 9,
}
// LINK static/ciq_worker.js:2291
const streetArrToObj = {
  d: 0,
  did: 1,
  v: 2,
  sf: 3,
  s: 4,
  curr: 5,
  iso: 6,
  pc: 7,
  unauth: 8,
}
// LINK static/ciq_worker.js:2305
const ltmArrtoObj = {
  fifilingdate: 0,
  did: 1,
  v: 2,
  pc: 3,
  isocode: 4,
  unittypeid: 5,
  financialperiodid: 6,
  calendaryear: 7,
  calendarquarter: 8,
  fiscalyear: 9,
  fiscalquarter: 10,
  unauth: 11,
}

// LINK static/ciq_worker.js:1959
const oneArrayToObject = (arrToObjMap = {}, dataArr = []) => {
  return Object.entries(arrToObjMap).reduce((acc, objEntries) => {
    acc[objEntries[0]] = dataArr[objEntries[1]]
    return acc
  }, {})
}

// LINK static/ciq_worker.js:1966
const transformMultiplesDates = (rawDates, lastDateArr) => {
  // FIXME: Do we want the periodTypeId in here?
  const arrToObj = {
    calendaryear: 0,
    calendarquarter: 1,
    financialperiodid: 2,
    financialinstanceid: 3,
    fiperiodenddate: 4,
    fiadvancedate: 5,
    ficurrencyid: 6,
    estimateperiodid: 7,
    periodenddate: 8,
    advancedate: 9,
    relativeconstant: 10,
  }
  // transform rawDates (arrays) to objects with properties that can be called
  const dateObjArr = rawDates.map((resArr) => {
    const dateObj = oneArrayToObject(arrToObj, resArr)
    // dateObj.dateKey = `${dateObj.calendaryear}${dateObj.calendarquarter}`
    if (!dateObj.fiperiodenddate) {
      const year = dateObj.calendaryear
      const qtr = dateObj.calendarquarter
      const qtrToMonthMap = {
        1: 2, // "March"
        2: 5, // "June"
        3: 8, // "September"
        4: 11, // "December"
      }
      const qtrToDayMap = {
        1: 31,
        2: 30,
        3: 30,
        4: 31,
      }
      const month = qtrToMonthMap[qtr]
      const day = qtrToDayMap[qtr]
      const qtrEndDate = new Date(year, month, day)
      const qtrEndStr = qtrEndDate.toISOString()
      dateObj.fiperiodenddate = qtrEndStr
      if (qtr === 4) {
        // increment year, set to end of may
        qtrEndDate.setUTCFullYear(qtrEndDate.getUTCFullYear() + 1)
        qtrEndDate.setUTCMonth(2)
      } else {
        qtrEndDate.setUTCMonth(qtrEndDate.getUTCMonth() + 2) // FIXME: should this be 3
      }
      const qtrAdvanceStr = qtrEndDate.toISOString()
      // create a date for this
      dateObj.fiadvancedate = qtrAdvanceStr
      dateObj.epoch = parseTime(dateObj.fiperiodenddate)
      // dateObj.value = `${dateObj.calendaryear}##${dateObj.calendarquarter}`
      dateObj.value = dateObj.fiperiodenddate
    } else {
      // this must happen, see down about 29 lines you filter the array for some reason
      // console.error("valuation date error no fiperiodenddate: ", dateObj)

      // dateObj.fiadvancedate = qtrAdvanceStr
      dateObj.epoch = parseTime(dateObj.fiperiodenddate)
      // dateObj.value = `${dateObj.calendaryear}##${dateObj.calendarquarter}`
      dateObj.value = dateObj.fiperiodenddate
    }
    // Do you need to add the metaData around
    // hasEstimate, isEstimate, periodenddate: d.pricingDate or d.quarterendDate?
    // FIXME: estimateperiodid needs to be switched to relativeConstant to allow layout on the table
    return dateObj
  })

  const lastPeriod = dateObjArr[dateObjArr.length - 2]
  const plusOnePeriod = dateObjArr[dateObjArr.length - 1]
  const lastObj = { ...lastPeriod, mostRecentDate: true }
  dateObjArr.pop()
  // const lastDateObj = oneArrayToObject(arrToObj, lastDateArr)
  const lastPeriodDate = lastDateArr[6]
  lastObj.fiperiodenddate = lastPeriodDate
  // lastObj.value = `LAST_CLOSE`
  lastObj.value = lastPeriodDate
  lastObj.epoch = parseTime(lastPeriodDate)
  if (plusOnePeriod.fiperiodenddate >= lastObj.fiperiodenddate) {
    // plus one period is ahead of today's date, pop and push
    dateObjArr.push(lastObj)
  } else {
    // modifyPlusOnePeriod to look like lastPeriod
    plusOnePeriod.advancedate = lastObj.advancedate
    plusOnePeriod.periodenddate = lastObj.periodenddate
    plusOnePeriod.ficurrencyid = lastObj.ficurrencyid
    dateObjArr.push(plusOnePeriod)
    dateObjArr.push(lastObj)
  }
  // dateObjArr.push(lastObj)
  // there must be an issue where fiperiodenddate is not always on the date response otherwise I wouldn't be doing this
  const cleanDates = dateObjArr.filter((f) => f.fiperiodenddate)
  return cleanDates
}

/**
 *
 * @param {[Object]} priceArr response from multiples endpoint containing price, tev and mc
d: "2015-01-02T00:00:00.000Z"
mc: "357620.863041"
mccurr: "US Dollar"
mciso: "USD"
mcpc: "1.000000000"
tev: "306203.863041"
q: "529.550000"
qaf: "1.0000000000"
qcurr: "US Dollar"
qiso: "USD"
qpc: "1.000000000"
 */
// LINK static/ciq_worker.js:2064
const createPriceSeries = (priceArr, dateArr = []) => {
  const firstPrice = priceArr[0]
  const firstPriceTime = Date.parse(firstPrice[6])

  // Find the price, marketcap (mc) and enterprise value (ev)
  // for each date column
  const dateArrWithPrice = dateArr
    // date columns must overlap with price history
    .filter((f) => Date.parse(f.fiperiodenddate) < firstPriceTime)
    .map((m) => {
      m.fiEndDate = Date.parse(m.fiperiodenddate)
      return {
        d: m,
        priceclose: {},
        mc: {},
        tev: {},
      }
    })
  let dateIdx = dateArrWithPrice.length
  let dateObj = dateArr[dateIdx]
  // do date columns exist within price history
  // and do those date columns contain financial data
  if (dateObj?.fiperiodenddate) {
    dateObj.fiEndDate = Date.parse(dateObj.fiperiodenddate)
  } else {
    console.error(
      "Date Arr and Price Arr do not overlap allMultiplesUtils/createPriceSeries"
    )
  }

  return priceArr.reduce(
    (acc, p) => {
      const dT = p[6] // text representation of the date
      const dV = Date.parse(p[6]) // epoch value representation of the date

      const mc = parseFloat(p[1]) // marketcap
      const curr = p[2] // marketcap & tev currency name
      const iso = p[3] // marketcap & tev currency ISO code
      const pc = parseFloat(p[4]) // marketcap & tev conversion to USD

      const tev = parseFloat(p[10]) // tev
      let price = {}
      let mcToPush = {}
      let tevToPush = {}

      // if price quote exists, d.q
      if (p[5]) {
        const q = parseFloat(p[5]) // price close
        const af = parseFloat(p[0]) // price close adjustment factor
        const curr = p[7] // priceclose currency name
        const iso = p[8] // price close currency ISO code
        const pc = parseFloat(p[9]) // price close conversion to USD
        price = {
          v: q,
          dT,
          dV,
          af,
          curr,
          iso,
          pc,
          u: 0, // unit using financials unitTypeId
          unauth: false,
        }
        acc.priceclose.push(price)
      }
      // if marketCap exists, p.mc
      if (mc) {
        mcToPush = {
          v: mc,
          dT,
          dV,
          curr,
          iso,
          pc,
          u: 0, // unit using financials unitTypeId
          unauth: false,
        }
        acc.mc.push(mcToPush)
      }
      // if tev exists
      if (tev) {
        tevToPush = {
          v: tev,
          dT,
          dV,
          curr,
          iso,
          pc,
          u: 0, // unit using financials unitTypeId
          unauth: false,
        }
        acc.tev.push(tevToPush)
      }

      if (dateObj?.fiEndDate && dateObj.fiEndDate > dV) {
        const priceDateObj = {
          d: dateObj,
          priceclose: {},
          mc: {},
          tev: {},
        }
        // associate the prior price object with the current date
        const lastPrice = acc.priceclose[acc.priceclose.length - 1]
        if (lastPrice) {
          priceDateObj.priceclose = {
            currencyname: lastPrice.curr,
            dataitemvalue: lastPrice.v,
            estimatescaleid: 0,
            isocode: lastPrice.iso,
            priceclose: lastPrice.pc,
            splitfactor: lastPrice.af,
          }
          // effectivedate: d.periodenddate, // fiperiodenddate
          // estimateday: d.periodenddate, // fiperiodenddate
          // estimaterelativeconstant: d.estimateperiodid, // relativeconstant
          // quarterday: d.quarterendday, // day the quarter ends?
          // todate: d.quarterendday,
        }
        const lastMc = acc.mc[acc.mc.length - 1]
        if (lastMc) {
          priceDateObj.mc = {
            currencyname: lastMc.curr,
            dataitemvalue: lastMc.v,
            estimatescaleid: 0,
            isocode: lastMc.iso,
            priceclose: lastMc.pc,
          }
        }
        const lastTev = acc.tev[acc.tev.length - 1]
        if (lastTev) {
          priceDateObj.tev = {
            currencyname: lastTev.curr,
            dataitemvalue: lastTev.v,
            estimatescaleid: 0,
            isocode: lastTev.iso,
            priceclose: lastTev.pc,
          }
        }

        // push the paired response object into acc.dates
        acc.dates.push(priceDateObj)

        // increment the dateObj to the next obj
        dateIdx = dateIdx + 1
        dateObj = dateArr[dateIdx]
        if (dateObj?.fiperiodenddate) {
          dateObj.fiEndDate = Date.parse(dateObj.fiperiodenddate)
        }
      }

      return acc
    },
    { priceclose: [], mc: [], tev: [], dates: dateArrWithPrice }
  )
}

// LINK static/ciq_worker.js:2473
const assignDataToDateRecursive = (
  dateIdxParam,
  dateArrParam,
  resObjParam,
  key,
  currDataObj,
  lastDataObj,
  checkAnyways = false
) => {
  let dateObj = dateArrParam[dateIdxParam]
  let colDate = new Date(dateObj.fiperiodenddate)
  let dateIncremented = false
  const fiAdvDate = new Date(dateObj.fiadvancedate) // generically here you are accepting any financials data > 1 year after the fiadvancedate for the table
  fiAdvDate.setUTCMonth(fiAdvDate.getUTCMonth() + 4)
  const estAdvDate = new Date(dateObj.advancedate) // generically here you are accepting any estimates data > 1 year after the advancedate for the table
  estAdvDate.setUTCMonth(estAdvDate.getUTCMonth() + 7)
  const priceKeys = ["dates", "mc", "tev", "priceclose"]
  const currentDataDate = new Date(currDataObj.dT)

  if (currentDataDate >= colDate || checkAnyways) {
    // if (currentDataDate === colDate) { // this doesn't appear to exactly work?
    if (
      !currDataObj.est &&
      currentDataDate > fiAdvDate &&
      !priceKeys.includes(key)
    ) {
      // this dataPeriod is outside of the range for this date...
      // advance to the next date and check if it is outside of the range for the next date
    } else if (currDataObj.dT === dateObj.fiperiodenddate) {
      resObjParam[dateObj.fiperiodenddate] = currDataObj
    } else {
      const lastDataDate = new Date(lastDataObj.dT)
      const dateDiff = colDate - lastDataDate
      const allowableTimeDiff = 0.75 * 366 * 24 * 60 * 60 * 1000
      // current dataObj is older than the current data
      // the previous data object is the correct data object
      // for the current dateObj
      if (dateDiff < allowableTimeDiff) {
        resObjParam[dateObj.fiperiodenddate] = lastDataObj
      }
    }
    // increment the dateObj to the next date
    dateIdxParam = dateIdxParam + 1
    dateObj = dateArrParam[dateIdxParam]
    if (dateIdxParam < dateArrParam.length) {
      colDate = new Date(dateObj.fiperiodenddate)
      dateIncremented = true
    }
  }

  if (dateIdxParam === dateArrParam.length - 1) {
    // if next date is the end of the dateArr set it equal to the current
    const endDateTimeDiff = colDate - currentDataDate
    const allowableTimeDiff = 0.75 * 366 * 24 * 60 * 60 * 1000
    if (endDateTimeDiff < allowableTimeDiff) {
      resObjParam[dateObj.fiperiodenddate] = currDataObj
    }
  }

  if (
    dateIncremented &&
    currentDataDate >= colDate
    // currentDataDate >= colDate &&
    // !priceKeys.includes(key)
  ) {
    // kind of guessing this is the correct structure
    // because I don't remember why I added this dateIdx === length condition?
    // guessing it was because the last dateIdx wasn't sticking to anything
    const { dateIdxRes, resObj } = assignDataToDateRecursive(
      dateIdxParam,
      dateArrParam,
      resObjParam,
      key,
      currDataObj,
      lastDataObj
    )
    dateIdxParam = dateIdxRes
    resObjParam = resObj
  }

  return { dateIdxRes: dateIdxParam, resObj: resObjParam }
}

// LINK static/ciq_worker.js:2557
const calculateAdvancedMetrics = (resData = {}, dateArr = []) => {
  // first calculate ncav

  // const ncavObj = dateArr.reduce((acc, dateObj) => {
  //   const dateKey = dateObj.fiperiodenddate
  //   const assetsObj = resData["total current assets"]
  //     ? resData["total current assets"][dateKey]
  //     : null
  //   const liabilitiesObj = resData["total liabilities"]
  //     ? resData["total liabilities"][dateKey]
  //     : null
  //   const minorityInterestObj = resData["minority interest"]
  //     ? resData["minority interest"][dateKey]
  //     : null
  //   const preferredObj = resData["total preferred equity"]
  //     ? resData["total preferred equity"][dateKey]
  //     : null
  //   const sharesObj = resData["weighted average diluted shares outstanding"]
  //     ? resData["weighted average diluted shares outstanding"][dateKey]
  //     : null

  //   const assets = assetsObj ? assetsObj.v : 0
  //   const liabilities = liabilitiesObj ? liabilitiesObj.v : 0
  //   const minorityInterest = minorityInterestObj ? minorityInterestObj.v : 0
  //   const preferred = preferredObj ? preferredObj.v : 0
  //   const shares = sharesObj ? sharesObj.v : 0

  //   const netCurrentAssets = assets - liabilities - minorityInterest - preferred

  //   // if netCurrentAssets = 0 then this if loop should not execute
  //   if (netCurrentAssets && shares) {
  //     const ncav = netCurrentAssets / shares
  //     acc[dateKey] = { v: ncav, est: false, iso: "USD", pc: 1, s: 3 }
  //   }

  //   return acc
  // }, {})

  // if (Object.keys(ncavObj).length > 0) {
  //   resData["ltm_ncav"] = ncavObj
  // }

  // then calculate gross proft revenue * gross margin
  const ntmGpObj = dateArr.reduce((acc, dateObj) => {
    const dateKey = dateObj.fiperiodenddate
    const revenueObj = resData.revenue ? resData.revenue[dateKey] : null
    const marginObj = resData["gross margin (%) mean"]
      ? resData["gross margin (%) mean"][dateKey]
      : null

    const revenue = revenueObj ? revenueObj.v : 0
    const margin = marginObj ? marginObj.v / 100 : 0
    const grossProfit = revenue * margin

    if (grossProfit) {
      const gpObj = { ...revenueObj }
      gpObj.v = grossProfit
      acc[dateKey] = gpObj
    }

    return acc
  }, {})

  if (Object.keys(ntmGpObj).length > 0) {
    resData.ntm_gp = ntmGpObj
  }

  const ntmEpsObj = dateArr.reduce((acc, dateObj) => {
    const dateKey = dateObj.fiperiodenddate
    const priceObj = resData.priceclose ? resData.priceclose[dateKey] : null
    const earningsObj = resData["eps normalized"]
      ? resData["eps normalized"][dateKey]
      : null
    const price = priceObj ? priceObj.v : 0
    const earnings = earningsObj ? earningsObj.v : 0
    const eps = earnings ? price / earnings : 0

    if (eps) {
      acc[dateKey] = { v: eps, est: true, iso: "USD", pc: 1, s: 3 }
    }
    return acc
  }, {})
  if (Object.keys(ntmEpsObj).length > 0) {
    resData.ntm_pe = ntmEpsObj
  }

  // calculate absolute YoY growth of NTM PE
  const ntmEpsDtObj = dateArr.reduce((acc, dateObj, idx, arr) => {
    // ASSUMES QUARTERLY SPLIT ON DATE ARR
    const idxDiff = 4
    if (idx >= idxDiff) {
      const prevDateObj = arr[idx - idxDiff]
      const dateKey = dateObj.fiperiodenddate
      const prevDateKey = prevDateObj.fiperiodenddate

      const prevEpsObj = resData["eps normalized"]
        ? resData["eps normalized"][prevDateKey]
        : null
      const currEpsObj = resData["eps normalized"]
        ? resData["eps normalized"][dateKey]
        : null
      const prevEps = prevEpsObj ? prevEpsObj.v : 0
      const currEps = currEpsObj ? currEpsObj.v : 0

      const difference = currEps - prevEps
      if (difference && prevEps) {
        // const yoyGrowth = (Math.abs(difference) / prevEps) * 100
        const yoyGrowth = (difference / prevEps) * 100

        acc[dateKey] = { v: yoyGrowth, est: true, iso: "USD", pc: 1, s: 3 }
      }
    }
    return acc
  }, {})

  if (Object.keys(ntmEpsDtObj).length > 0) {
    resData.ntmeps_dt = ntmEpsDtObj
  }

  return resData
}

// LINK static/ciq_worker.js:2680
const createTblData = (resData = {}, dateArr = []) => {
  // create result object to store summarizatin of series and return
  // something that yields data objects like: result[did][dT] = {}
  let result = {}
  try {
    const lastDateObj = dateArr[dateArr.length - 1]
    const lastDate = new Date(lastDateObj.fiperiodenddate)
    const dataKeys = Object.keys(resData)
    // check if dates and resdata exist
    if (dateArr && dateArr.length > 0 && resData) {
      // iterate over each dataKey of resData
      dataKeys.forEach((key) => {
        // ignore the dates data key, operate on every other data series
        if (key !== "dates") {
          // add try - catch in here?
          const seriesArr = resData[key] // .filter((f) => f && f.dT) //.filter((f) => f && f.dT)
          // const priceKeys = ["dates", "mc", "tev", "priceclose"]
          // if (priceKeys.includes(key)) {
          //   // seriesArr = seriesArr.filter((f) => f && f.dT) // no dT for price closes?
          // }
          const firstObj = seriesArr[0]
          if (firstObj) {
            const firstDate = new Date(firstObj.dT)
            let dataByDate = {}
            // initalize variables holding current date we
            // are looking for data at or before
            let dateIdx = dateArr.findIndex((dateObj) => {
              const date = new Date(dateObj.fiperiodenddate)
              return date >= firstDate
            })

            if (dateIdx < 0) {
              //  || firstDate <=

              console.log(
                `firstObj Date ${firstDate} vs last dateArr date ${lastDate}`
              )
            }

            if (dateIdx >= 0) {
              // let dateObj = dateArr[dateIdx] // fiEndDate = epoch, fiperiodenddate = UTC str in col header

              seriesArr.forEach((dataObj, dataIdx, dataArr) => {
                const lastDataObj = dataArr[dataIdx - 1]
                // bottom 5 lines of code dependent upon dateObj variable
                // let colDate = new Date(dateObj.fiperiodenddate)
                // const fiAdvDate = new Date(dateObj.fiadvancedate) // generically here you are accepting any financials data > 1 year after the fiadvancedate for the table
                // fiAdvDate.setFullYear(fiAdvDate.getFullYear() + 1)
                // const estAdvDate = new Date(dateObj.advancedate) // generically here you are accepting any estimates data > 1 year after the advancedate for the table
                // estAdvDate.setFullYear(estAdvDate.getFullYear() + 1)
                if (dateIdx < dateArr.length) {
                  const { dateIdxRes, resObj } = assignDataToDateRecursive(
                    dateIdx,
                    dateArr,
                    dataByDate,
                    key,
                    dataObj,
                    lastDataObj
                  )
                  dateIdx = dateIdxRes
                  // dateObj = dateArr[dateIdx]
                  dataByDate = resObj

                  // const currentDataDate = new Date(dataObj.dT)
                  // if (!dataObj.dT || !currentDataDate) {
                  //   console.error(`.dT is not a property of ${key}`)
                  // }
                  // if (currentDataDate >= colDate) {
                  //   // if (currentDataDate === colDate) { // this doesn't appear to exactly work?
                  //   if (
                  //     !dataObj.est &&
                  //     currentDataDate > fiAdvDate &&
                  //     !priceKeys.includes(key)
                  //   ) {
                  //     // this dataPeriod is outside of the range for this date...
                  //     // advance to the next date and check if it is outside of the range for the next date
                  //
                  //   } else if (
                  //     dataObj.est &&
                  //     currentDataDate > estAdvDate &&
                  //     !priceKeys.includes(key)
                  //   ) {
                  //     //
                  //
                  //   }

                  //   if (dataObj.dT === dateObj.fiperiodenddate) {
                  //     dataByDate[dateObj.fiperiodenddate] = dataObj
                  //   } else {
                  //     // current dataObj is older than the current data
                  //     // the previous data object is the correct data object
                  //     // for the current dateObj

                  //     dataByDate[dateObj.fiperiodenddate] = lastDataObj
                  //   }
                  //   // increment the dateObj to the next date
                  //   dateIdx = dateIdx + 1
                  //   dateObj = dateArr[dateIdx]
                  // }
                  // if (dateIdx === dateArr.length - 1) {
                  //   dataByDate[dateObj.fiperiodenddate] = dataObj
                  // }
                  if (dataIdx === dataArr.length - 1) {
                    // you are at the end of the data array
                    const dateLength = dateArr.length - 1
                    if (dateIdx < dateLength) {
                      // const remainingDates = dateArr.slice(
                      //   dateIdx,
                      //   dateLength + 1
                      // )
                      const remainingDateIndexArr = []
                      for (let step = dateIdx; step < dateArr.length; step++) {
                        remainingDateIndexArr.push(step)
                      }
                      remainingDateIndexArr.forEach((idx) => {
                        const { dateIdxRes, resObj } =
                          assignDataToDateRecursive(
                            idx,
                            dateArr,
                            dataByDate,
                            key,
                            dataObj,
                            dataObj,
                            true
                          )
                        dateIdx = dateIdxRes
                        // dateObj = dateArr[dateIdx]
                        dataByDate = resObj
                      })
                    }
                  }
                }
              })

              // if object containing data by utc iso time string
              // has data then save this object to the returned result object
              if (Object.keys(dataByDate).length > 0) {
                result[key] = dataByDate
              }
            }
          }
        }
      })
    }
    // console.log("createTblData result: ", result)
    result = calculateAdvancedMetrics(result, dateArr)
    return result
  } catch (error) {
    console.error("error createTblData: ", error)
    return result
  }
}
// XpressFeed rowMode: Array mapping to propertyNames as of August 20th 2020
/**
 *
 {
  "dates": {
    "calendaryear": 0,
    "calendarquarter": 1,
    "financialperiodid": 2,
    "financialinstanceid": 3,
    "fiperiodenddate": 4,
    "fiadvancedate": 5,
    "ficurrencyid": 6,
    "estimateperiodid": 7,
    "periodenddate": 8,
    "advancedate": 9,
    "relativeconstant": 10
  },
  "price": {
    "qaf": 0,
    "mc": 1,
    "mccurr": 2,
    "mciso": 3,
    "mcpc": 4,
    "q": 5,
    "d": 6,
    "qcurr": 7,
    "qiso": 8,
    "qpc": 9,
    "tev": 10
  },
  "ntm": {
    "d": 0,
    "did": 1,
    "erc": 2,
    "v": 3,
    "sf": 4,
    "s": 5,
    "curr": 6,
    "iso": 7,
    "pc": 8
  },
  "street": {
    "d": 0,
    "did": 1,
    "v": 2,
    "sf": 3,
    "s": 4,
    "curr": 5,
    "iso": 6,
    "pc": 7
  },

  "ltm": {
  "fifilingdate": 0,
  "dataitemid": 1,
  "dataitemvalue": 2,
  "priceclose": 3,
  "isocode": 4,
  "unittypeid": 5,
  "financialperiodid": 6,
  "calendaryear": 7,
  "calendarquarter": 8,
  "fiscalyear": 9,
  "fiscalquarter": 10
}
}
 */
export {
  oneArrayToObject,
  transformMultiplesDates,
  createPriceSeries,
  ntmArrToObj,
  ltmArrtoObj,
  streetArrToObj,
  createTblData,
}
