import { calcEstDerivative, calcEstDiv } from "./estimateMath"

const stage = process.env.LAMBDA_STAGE
const devStage = "dev"
const inspectInitVars = false
/**
 * If I build a new table from scratch I will then have 3 separate
 * to maintain which is unruley to say the least
 * @param {*} res
 * @param {*} ntmTemplate
 */
const transformNtmResponse = (res, ntmTemplate) => {
  // FIXME: eliminate the use of res.ltmDates - everything should now be keyed off of res.dates
  // because res.dates is now created/formed from ltmDates
  if (!res.dates || res.dates.length === 0) {
    // now this error is correct as res.dates is returning the bestLtmDatePeriods
    throw new Error("Sorry, we don't have any Multiples data for this ticker")
  }

  // FIXME: completely remove all reference to this... should be able to
  // do all manipulations with just res.dates
  const ltmData = res.ltmData
  const ltmDates = res.ltmDates.reduce((acc, d) => {
    acc[d.financialperiodid] = d
    return acc
  }, {})

  const dates = res.dates.map((d, idx) => {
    // setting hasEstimate/isEstimate to false because I I'll put the data on "actuals" to create table
    // this code is useful for the estimates table
    // addition of estimateperiodid is what the other tables use
    // if (d.quarterrelativeconstant === 500) {
    //   return {
    //     ...d,
    //     hasEstimate: false,
    //     isEstimate: false,
    //     periodenddate: d.pricingdate,
    //     estimateperiodid: d.quarterrelativeconstant
    //   }
    // }
    if (idx === 0) {
      return {
        ...d,
        hasEstimate: false,
        isEstimate: false,
        periodenddate: d.pricingdate,
        estimateperiodid: d.quarterrelativeconstant,
      }
    } else {
      return {
        ...d,
        hasEstimate: false,
        isEstimate: false,
        periodenddate: d.quarterenddate,
        estimateperiodid: d.quarterrelativeconstant,
      }
    }
  })

  // accumulator function to count the number of different estimate scales
  const addScale = (acc, item) => {
    if (acc[item.estimatescaleid]) {
      ++acc[item.estimatescaleid]
    } else {
      acc[item.estimatescaleid] = 1
    }
  }

  // initiate objects to store data & be available in main function scope
  const isoCounter = {}
  let resData = {}
  // use the date object to add price, mc, tev to the resData object...

  // res.actuals is the ntm response
  // res.actuals is an array
  if (res.actuals.length > 0) {
    resData = res.actuals.reduce((acc, item) => {
      // check the dataitemvalue is not 0 - seems to occur often
      item.dataitemvalue = parseFloat(item.dataitemvalue)
      if (!item.dataitemvalue) {
        return acc
      }
      const currency = item.isocode
      if (currency && isoCounter[currency]) {
        // increase the count!
        ++isoCounter[currency].count
        addScale(isoCounter[currency], item)
      } else {
        // this currency isn't on the counter yet, initalize
        const newCurr = {
          name: item.currencyname,
          code: item.isocode,
          count: 1,
        }
        addScale(newCurr, item)
        isoCounter[currency] = newCurr
      }

      const estimateperiodid = item.estimaterelativeconstant
      if (acc[item.dataitemid]) {
        // dataitemid exists on the accumulator
        // as this is only for actuals, the actual key exists on acc[item.dataitem]
        acc[item.dataitemid].actual[estimateperiodid] = item
      } else {
        // dataitemid doesn't exist on accumulator
        // create an object which will hold the {estimatePeriod: dataitemvalue Object} relationship
        const newObj = {}
        newObj[estimateperiodid] = item

        // create an item to hold the {"actual": {estimatePeriod: dataValueObject}}
        const dataitemidObj = {}
        dataitemidObj.actual = newObj
        // Store the {actual: {firstEstimatePeriod: {dataValueObject}}} object
        // on the item.dataitemid key on the accumulator, first time adding this dataitemid to accumulator
        acc[item.dataitemid] = dataitemidObj
      }
      return acc
    }, {})
  }

  resData.tev = {
    actual: dates.reduce((acc, d) => {
      if (d.estimateperiodid) {
        acc[d.estimateperiodid] = {
          currencyname: d.mccurrencyname,
          dataitemvalue: parseFloat(d.tev),
          effectivedate: d.periodenddate,
          estimateday: d.periodenddate,
          estimaterelativeconstant: d.estimateperiodid,
          estimatescaleid: 0,
          isocode: d.mcisocode,
          priceclose: d.mcpriceclose,
          quarterday: d.quarterendday,
          splitfactor: parseFloat(d.adjustmentfactor),
          todate: d.quarterendday,
        }
        return acc
      } else {
        return acc
      }
    }, {}),
  }
  resData.mc = {
    actual: dates.reduce((acc, d) => {
      if (d.estimateperiodid) {
        acc[d.estimateperiodid] = {
          currencyname: d.mccurrencyname,
          dataitemvalue: parseFloat(d.marketcap),
          effectivedate: d.periodenddate,
          estimateday: d.periodenddate,
          estimaterelativeconstant: d.estimateperiodid,
          estimatescaleid: 0,
          isocode: d.mcisocode,
          priceclose: d.mcpriceclose,
          quarterday: d.quarterendday,
          splitfactor: parseFloat(d.adjustmentfactor),
          todate: d.quarterendday,
        }
        return acc
      } else {
        return acc
      }
    }, {}),
  }
  resData.priceclose = {
    actual: dates.reduce((acc, d) => {
      if (d.estimateperiodid) {
        acc[d.estimateperiodid] = {
          currencyname: d.quotecurrencyname,
          dataitemvalue: parseFloat(d.priceclose),
          effectivedate: d.periodenddate,
          estimateday: d.periodenddate,
          estimaterelativeconstant: d.estimateperiodid,
          estimatescaleid: 3,
          isocode: d.quoteisocode,
          priceclose: d.quotepriceclose,
          quarterday: d.quarterendday,
          splitfactor: parseFloat(d.adjustmentfactor),
          todate: d.quarterendday,
        }
        return acc
      } else {
        return acc
      }
    }, {}),
  }

  // res.dates.forEach(item => {
  //   const estimateperiodid = item.quarterrelativeconstant
  //   if (item.marketcap) {
  //     estimateperiodid
  //     item.marketcap
  //     item.mccurrencyid
  //     item.mcisocode
  //     item.mcpriceclose
  //   }

  //   if (item.tev) {
  //     item.tev
  //     //probably the same
  //   }

  //   if (item.quoteprice) {
  //     item.priceclose // price
  //     item.quotecurrencyname
  //     item.quoteisocode
  //     item.quotepriceclose
  //   }
  // })
  // FIXME: Delete this block when the code is running correctly
  // const estDateToConstant = res.dates.reduce((acc, date) => {
  //   const yearQuarter = `${date.calendaryear}${date.calendarquarter}`
  //   acc[yearQuarter] = date.quarterrelativeconstant
  //   return acc
  // }, {})

  const finperiodidMap = res.dates.reduce((acc, d) => {
    acc[d.financialperiodid] = d.quarterrelativeconstant
    return acc
  }, {})

  // add ltm data to the resData object
  ltmData.forEach((item) => {
    item.dataitemvalue = parseFloat(item.dataitemvalue)
    if (item.dataitemvalue) {
      // do stuff here
      const finPeriod = ltmDates[item.financialperiodid]
      const estimateperiodid = finperiodidMap[item.financialperiodid] // so the estimateperiodid is the estimaterelativeconstant
      // I need to do some transformation of the item in order to create
      // the same form as the estimates data (currency... units?)
      if (resData[item.dataitemid]) {
        resData[item.dataitemid].actual[estimateperiodid] = Object.assign(
          item,
          { priceclose: finPeriod.priceclose }
        ) // FIXME: Why am I adding priceClose to the resData Obj only for ltm?
      } else {
        const newObj = {}
        newObj[estimateperiodid] = Object.assign(item, {
          priceclose: finPeriod.priceclose,
        })

        // create an item to hold the {"actual": {estimatePeriod: dataValueObject}}
        const dataitemidObj = {}
        dataitemidObj.actual = newObj
        // Store the {actual: {firstEstimatePeriod: {dataValueObject}}} object
        // on the item.dataitemid key on the accumulator, first time adding this dataitemid to accumulator
        resData[item.dataitemid] = dataitemidObj
      }
    }
  })

  if (inspectInitVars && stage === devStage) {
    console.log("transformNtm res", res)
  }

  const estimatesLineItems = ntmTemplate
  const estimates = estimatesLineItems.map((statement) => {
    // mapping over each statement contained in estimatesLineItems
    return statement.keys.reduce((acc, lineitem, idx) => {
      // iterating over each lineitem inside of a statement template
      // length of accumulator will be the index value of the lineitem
      // in the event the lineitem is pushed into the accumulator
      const length = acc.length
      if (
        lineitem.formula === "val" ||
        lineitem.formula === "turns" ||
        lineitem.formula === "pct"
      ) {
        // check to see if the lineitem exists in the returned data resData
        if (resData[lineitem.dataitemid]) {
          // so the question here... is statement a reference? Are we manipulating
          // the underlying? I have a feeling it is being passed by reference
          const existingLineItem = statement.keys[idx] // this is a reference to the lineitem we're currently on
          // because the dataitem exists in the response add to the estimatesLineItem template
          // metadata that will allow us when iterating over this array later that the
          // lineitem has actual data associated with it
          statement.keys[idx] = { ...existingLineItem, actualPosition: length }
          // Merge the available dataitemvalue objects (by estimateperiod id) with the lineitem
          // and push into the accumulator
          acc.push(Object.assign({}, resData[lineitem.dataitemid], lineitem))
        }
        // if this lineitem (dataitemid) does not exist on ResData return accumulator
        // meaning that this lineitem will be removed from the object that will
        // be used to layout the table (no empty rows)
        return acc
      }

      if (lineitem.formula === "h3") {
        // this is a header, include it, no need to merge with any data
        // do we need to perform this manipulation of the estimateTemplate?
        // it will always be there...
        const existingLineItem = statement.keys[idx] // this is a reference to the lineitem we're currently on
        statement.keys[idx] = {
          ...existingLineItem,
          actualPosition: length,
        }
        acc.push(lineitem)
        return acc
      }

      if (lineitem.formula === "dxdt") {
        // this is a period on period calculation, thus dataitemid is an array
        // need to look at the first position in dataitemid[0]
        if (resData[lineitem.dataitemid[0]]) {
          // dataitemid was returned in the response
          // modify the lineitem template to signal that this row was been included in the accumulator
          const existingLineItem = statement.keys[idx]
          statement.keys[idx] = {
            ...existingLineItem,
            actualPosition: length,
          }

          // what are we expecting derivative to be... an object associating estimatePeriodIds
          // to dataitemvalue objects where the dataitemvalue is the calculated derivative
          // this is why the function needs... the lineitem
          // is this function making manipulations to the lineitem?
          const derivative = calcEstDerivative(
            lineitem,
            resData,
            dates,
            "actual"
          )
          // these were my outside of vuex modification issues lineitem was the target
          const addCalc = {}
          addCalc.actual = derivative
          // {...lineItemMetaData, {actual: {periodId: {dataObj} } } }
          acc.push(Object.assign({}, lineitem, addCalc))
        }
        return acc
      }
      // A calculation is occuring FIXME: change if condition if you're ever doing more than just 2 things
      // right now there are only two numbers being passed because we're only doing division
      // a list comprehenshion would be great right here.. not quite sure how to check that for javascript
      // ASSUMING THE ONLY POSSIBILITY LEFT IS A CALCULATION SO NOT CHECKING lineitem.formula
      if (
        lineitem.formula === "div" &&
        resData[lineitem.dataitemid[0]] &&
        resData[lineitem.dataitemid[1]]
      ) {
        // modify lineitem to alert next process it is included
        // so what are you going to do when there is actuals data but no estimate?
        // that is a good question
        const existingLineItem = statement.keys[idx]
        statement.keys[idx] = {
          ...existingLineItem,
          actualPosition: length,
        }
        const margin = calcEstDiv(lineitem, resData, dates, "actual")
        // these were my outside of vuex modification issues lineitem was the target
        const addCalc = {}
        addCalc.actual = margin
        acc.push(Object.assign({}, lineitem, addCalc))
        return acc
      }

      return acc
    }, [])
  })

  // FIXME: Delete estimatesLineItems? Don't think I'm doing anything with it..
  return {
    resData,
    dates: dates.reverse(),
    estimates,
    estimatesLineItems,
    isoCounter,
    ltmData,
  }
}

export { transformNtmResponse }
