module.exports = {
  "Market Overview": "Noticias del mercado",
  "Idea Generation": "Generación de ideas",
  "My Watchlists": "Mis listas de seguimiento",
  "Track Investing Gurus": "Seguimiento de gurús de la inversión",
  "Fundamental Analysis": "Análisis fundamental",
  "Company Overview": "Resumen de la empresa",
  "Company News": "Noticias de la compañía",
  "Detailed Financials": "Información financiera detallada",
  Valuation: "Valoración",
  "Analyst Estimates": "Estimaciones de analistas",
  "Call Transcripts": "Transcripciones de conferencias",
  "Company Filings": "Presentaciones de la empresa",
  Ownership: "Accionariado",
  "Account Settings": "Configuraciones de la cuenta",
  "Sign Out": "Desconectar",
  "Search Tickers...": "Buscar Tickers ...",

  "TIKR Terminal": "Terminal TIKR",
  "Support TIKR Beta Promo": "Apoyar la promoción beta de TIKR",
  "Curated Stock Baskets": "Cesta de acciones seleccionadas",
  "Discussion Board": "Panel de discusión",
  Screener: "Screener",
  Search: "Buscar",
  "Public Filings": "Presentaciones públicas",
  "App Config": "Configuración de la aplicación",
  "TIKR Settings": "Configuración de TIKR",
  Settings: "Ajustes",
  "New Version of TIKR Available": "Nueva versión de TIKR disponible",
  "Reload Page": "Recargar página",
  "We've updated our ": "Hemos actualizado nuestros",
  "Terms of Service": "Términos de servicio",
  " and ": " y",
  "Accept Terms": "Aceptar los terminos",
  "Watchlist:": "Lista de seguimiento:",
  "Active Watchlist:": "Lista de seguimiento activa:",
  "Use top search bar to select a ticker to view":
    "Use la barra de búsqueda superior para seleccionar un ticker para ver",
  "(e.g. NFLX)": "(por ejemplo, NFLX)",

  "Add Ticker": "Agregar Ticker",
  "Watchlist Settings": "Configuración de la lista de seguimiento",
  "Add Watchlist": "Agregar lista de seguimiento",
  "Watchlist Columns": "Columnas de lista de seguimiento",
  "Active Watchlist": "Lista de seguimiento activa",
  "Set As Active Watchlist": "Establecer como lista de seguimiento activa",
  "Delete Watchlist": "Eliminar lista de seguimiento",
  "Or Click on heart next to company name to add Ticker to active Watchlist":
    "O haga clic en el corazón junto al nombre de la empresa para agregar el Ticker a la lista de seguimiento activa",
  "Watchlist Title": "Título de la lista de seguimiento",
  "Watchlist Description": "Descripción de la lista de seguimiento",
  Actions: "Comportamiento",
  Watchlist: "Lista de seguimiento",
  "Upcoming Events": "Próximos Eventos",
  "Select a watchlist title above to view the watchlist":
    "Seleccione un título de lista de seguimiento arriba para ver la lista de seguimiento",
  Or: "O",
  "Click 'Add Watchlist' to create a new watchlist":
    "Haga clic en 'Agregar lista de seguimiento' para crear una nueva lista de seguimiento",
  "Delete Ticker": "Eliminar Ticker",
  "Delete List": "Eliminar lista",
  "Last Price": "Ultimo precio",
  "% Change": "% Cambio",
  Name: "Nombre",

  "NTM TEV/REV": "NTM TEV / REV",
  "NTM TEV/EBITDA": "NTM TEV / EBITDA",
  "NTM TEV/EBIT": "NTM TEV / EBIT",
  "NTM P/E": "NTM P / E",
  "NTM MC/FCF": "NTM MC / FCF",
  "NTM P/CFPS": "NTM P / CFPS",
  "NTM P/FFO": "NTM P / FFO",
  "NTM P/AFFO": "NTM P / AFFO",
  "Mean Analyst Target": "Precio objetivo medio de analistas",
  "Mean Analyst NAV": "NAV medio de analistas",
  "LTM TEV/REV": "LTM TEV / REV",
  "LTM TEV/EBITDA": "LTM TEV / EBITDA",
  "LTM TEV/EBIT": "LTM TEV / EBIT",
  "LTM P/Diluted EPS before Extra": "LTM P / EPS diluido antes de extras",
  "LTM P/BV": "LTM P / BV",
  "LTM P/Tangible BV": "LTM P / Tangible BV",
  "LTM TEV/Unlevered FCF": "LTM TEV / FCF sin apalancamiento",
  "LTM MC/Levered FCF": "LTM MC / FCF apalancado",
  "Quote Currency": "Divisa de cotización",
  "Quote Time": "Hora de cotización",
  "Or Click on": "O haga clic en",
  "next to company name to add Ticker to active Watchlist":
    "junto al nombre de la empresa para agregar Ticker a la lista de seguimiento activa",
  "Are you sure you want to delete this watchlist?":
    "¿Está seguro de que desea eliminar esta lista de seguimiento?",
  "Error: Watchlist must have a title":
    "Error: la lista de seguimiento debe tener un título",
  "Error: watchlist is full - cannot add more than":
    "Error: la lista de seguimiento está llena; no se pueden agregar más de",
  "Please Create a new watchlist": "Cree una nueva lista de seguimiento",
  "from exchange": "de intercambio",
  "already exists on this watchlist!":
    "ya existe en esta lista de seguimiento!",
  "Error: You've reached your watchlist limit (max":
    "Error: ha alcanzado el límite de su lista de seguimiento (máx.",
  "Please delete another watchlist and try again":
    "Elimina otra lista de seguimiento e inténtalo de nuevo.",
  "There has been an error - please try again or contact support":
    "Ha habido un error. Vuelva a intentarlo o póngase en contacto con el servicio de asistencia.",
  "New Watchlist Title": "Nuevo título de la lista de seguimiento",
  "This is the title for your new Watchlist (can change later)":
    "Este es el título de su nueva lista de seguimiento (puede cambiarlo más adelante)",
  "Description of what watchlist will contain":
    "Descripción de lo que contendrá la lista de seguimiento",
  "Describe what this watchlist contains (can change later)":
    "Describe qué contiene esta lista de seguimiento (puede cambiarlo más adelante)",
  "Select Stock Tickers": "Seleccione Tickers de acciones",
  Back: "atrás",
  Next: "siguiente",
  "Create Watchlist": "Crear lista de seguimiento",
  "Create New Watchlist": "Crear nueva lista de seguimiento",
  "Add Stocks to": "Agregar acciones a",
  "Please create an active watchlist in 'My Watchlists' before adding a favorite ticker!":
    "¡Cree una lista de seguimiento activa en 'Mis listas de seguimiento' antes de agregar un ticker favorito!",

  "If you want to add any other funds to these lists let us know at <a href='mailto:support@tikr.com'>support@tikr.com</a>":
    "Si desea agregar otros fondos a estas listas, háganoslo saber en <a href='mailto:support@tikr.com'> support@tikr.com </a>",
  "Search Investing Gurus": "Buscar gurús de la inversión",
  "Growth at a Reasonable Price (GARP)":
    "Crecimiento a un precio razonable (GARP)",
  Value: "Valor",
  "Aggressive Growth": "Crecimiento agresivo",
  Other: "Otro",
  "Investor / Fund Name": "Nombre del inversor / fondo",
  "Rows per page": "Filas por página",
  Terms: "Condiciones",
  Privacy: "Intimidad",

  Zoom: "Zoom",
  "October 23, 2020 Close": "Cierre a 23 de octubre de 2020",
  "57% price return over 3 years - 16% CAGR (Compound Annual Growth Rate)":
    "Rentabilidad del precio del 57% durante 3 años - 16% CAGR (Tasa de crecimiento anual compuesta)",
  "Add Tickers to Chart...": "Agregar tickers al gráfico ...",
  "Show Relative Change %": "Mostrar % de cambio relativo",
  "52 WEEK HIGH": "MÁXIMO 52 SEMANAS",
  "52 WEEK LOW": "MÍNIMO 52 SEMANAS",
  "AVERAGE 3 MONTH DAILY VOLUME": "VOLUMEN DIARIO PROMEDIO DE 3 MESES",
  "SHARES OUTSTANDING": "ACCIONES EN CIRCULACIÓN",
  "Short Interest %": "% De interés corto",
  "MARKET CAP (MM)": "CAP DE MERCADO (MM)",
  "TEV (MM)": "TEV (MM)",
  "Show Price Chart": "Mostrar tabla de precios",
  "Business Owner Mode Activated - All Stock Prices Hidden":
    "Modo de propietario de negocio activado: todos los precios de las acciones ocultos",
  "Price Chart Hidden": "Gráfico de precios oculto",
  Overview: "Descripción general",
  News: "Noticias",
  Financials: "Finanzas",
  Estimates: "Estimaciones",
  Consensus: "Consenso",
  Transcripts: "Transcripciones",
  Filings: "Presentaciones",
  Discuss: "Debatir",
  Close: "Cerca",
  " Close": " Cerca",
  "Close Window": "Cerrar ventana",
  "Previous close": "Cierre anterior",
  "IEX real time price": "Precio IEX en tiempo real",
  "15 minute delayed price": "Precio con retraso de 15 minutos",
  "# of No Opinions": "Número de opiniones",
  "NTM = Next Twelve Months &nbsp;&nbsp;&nbsp; LTM = Last Twelve Months":
    "NTM = Próximos doce meses & nbsp; & nbsp; & nbsp; LTM = Últimos doce meses",
  "Total Operating Expenses": "Gastos operativos totales",
  "Restructuring Charges": "Gastos de reestructuración",
  "Annualized Distributions per Unit": "Distribuciones anualizadas por unidad",
  "Trust Income": "Ingresos por fideicomiso",
  "Income From Trading Activities": "Ingresos de actividades comerciales",
  "Gain (Loss) on Sale of Invest. & Securities":
    "Ganancia (pérdida) en la venta de inversiones y valores",
  "Total Other Non Interest Income":
    "Total de otros ingresos distintos de intereses",
  "Occupancy Expense": "Gasto de ocupación",
  "Depreciation & Amortization - (IS) - (Collected)":
    "Depreciación y amortización - (Recaudado)",
  "Allowance For Equity Funds Used During Construction":
    "Provisión para fondos de capital utilizados durante la construcción",
  "Allowance For Borrowed Funds Used During Construction":
    "Provisión para fondos prestados utilizados durante la construcción",
  "Depreciation, Depletion & Amortization":
    "Depreciación, agotamiento y amortización",
  "Total Asset Writedown": "Depreciación de activos totales",
  "Restructuring Activities": "Actividades de reestructuración",
  "Purchase / Sale of Intangible Assets":
    "Compra / Venta de Activos Intangibles",
  "Sale of Property, Plant and Equipment": "Venta de Inmobilizado Material",
  "Net Increase (Decrease) in Deposit Accounts":
    "Aumento (disminución) neto de las cuentas de depósito",
  "Regulatory Assets": "Activos regulatorios",
  "Net Loans": "Préstamos netos",
  "Other Liabilities": "Otros pasivos",
  "Total Other Investments": "Total de otras inversiones",
  "Total investments": "Inversiones totales",
  "Investment In Debt and Equity Securities":
    "Inversión en valores de deuda y acciones",

  "N/A": "N / A",
  "There was an error fetching price quotes and market cap data":
    "Se produjo un error al obtener cotizaciones de precios y datos de capitalización de mercado.",

  "Error Fetching Quotes": "Error al obtener cotizaciones",
  Show: "Show",
  Hide: "Esconder",
  Data: "Datos",
  "Hide OHLC": "Ocultar OHLC",
  "Show OHLC": "Mostrar OHLC",
  "Show Absolute Value": "Mostrar valor absoluto",
  "1d": "1d",
  "3m": "3m",
  "6m": "6m",
  ytd: "este año",
  "1yr": "1 año",
  "3yr": "3 años",
  "5yr": "5 años",
  "10yr": "10 años",
  "price return over": "retorno del precio sobre",
  Volume: "Volumen",
  years: "años",

  "LTM (Last Twelve Months)": "LTM (últimos doce meses)",
  "* Annual Financials in Millions of US Dollar from 12/31/12 to 6/30/20":
    "* Cuentas anuales en millones de dólares estadounidenses desde el 31/12/12 hasta el 30/6/20",
  "Income Statement": "Cuenta de resultados",
  "Balance Sheet": "Balance de situación",
  "Cash Flow Statement": "Estado de flujo de efectivo",
  Ratios: "Ratios",
  "Chart Data": "Datos del gráfico",
  "Clear Chart": "Borrar gráfico",
  "Show Labels": "Mostrar etiquetas",
  "Hide Chart": "Ocultar gráfico",

  "Error Fetching Data": "Error al obtener datos",
  "Select Metric": "Seleccionar métrica",
  "Select Line Item": "Seleccionar línea",
  "Display Currency:": "Divisa de visualización:",
  "Display Units:": "Unidades de visualización:",
  "Decimals to Display:": "Decimales para mostrar:",
  "Reverse Dates": "Invertir fechas",
  "Copy Table": "Copiar tabla",
  "Table Options": "Opciones de tabla",
  Annual: "Anual",
  Quarterly: "Trimestral",
  "Semi Annual": "Semestral",
  LTM: "LTM",
  "Displayed in thousands": "Mostrado en miles",
  k: "k",
  "Displayed in millions": "Mostrado en millones",
  MM: "MM",
  "Displayed in billions": "Mostrado en miles de millones",
  B: "B",
  "Financials in": "Datos financieros en",
  Thousands: "Miles",
  Millions: "Millones",
  Billions: "Miles de millones",
  of: "de",
  "[currencyName]": "[currencyName]",
  from: "de",
  to: "a",
  percent: "por ciento",
  "Copy Table to Clipboard": "Copiar tabla al portapapeles",
  CAGR: "Crecmiento compuesto",
  "Sorry, we have no financial data for":
    "Lo sentimos, no tenemos datos financieros para",
  "This is not the": "Este no es el",
  "primary ticker": "ticker principal",
  "for this company which can lead to differences in per share metrics.":
    "para esta empresa, lo que puede generar diferencias en las métricas por acción.",
  "This is not the <i><a>primary ticker</a></i> for this company which can lead to differences in per share metrics":
    "Este no es el <i> <a> ticker principal </a> </i> de esta empresa, lo que puede generar diferencias en las métricas por acción.",
  "Actuals & Forward Estimates": "Estimaciones reales y futuras",
  E: "E",
  A: "A",

  "Street Targets": "Objetivos de analistas",
  "Price Close": "Precio de cierre",
  "Street Price Targets": "Precio objetivo de analistas",
  "Target Stock Price Mean": "Media del precio objetivo de las acciones",
  "Target Stock Price Median": "Mediana de precio de acción objetivo",
  "Target Stock Price High": "Precio de acción objetivo máximo",
  "Target Stock Price Low": "Precio de acción objetivo mínimo",
  "Target Stock Price (# Est)": "Precio de acción objetivo (# est.)",
  "Street Recommendations": "Recomendaciones de analistas",
  "# of Buys": "# de comprar",
  "# of Outperforms": "# de sobreponderar",
  "# of Holds": "# de mantener",
  "# of Underperforms": "# de infraponderar",
  "# of Sells": "# de vender",
  "Street Estimate": "Estimación de analistas",
  "Net Asset Value / Share Mean": "NAV por acción (media)",
  "Net Asset Value / Share Median": "NAV por acción (mediana)",
  "Net Asset Value / Share High": "NAV por acción (máximo)",
  "Net Asset Value / Share Low": "NAV por acción (mínimo)",
  "Net Asset Value / Share (# Est)": "NAV por acción (# Est)",
  "Target Stock Price / Close Price":
    "Precio de acción objetivo / Precio de cierre",
  "Price / NAV per Share": "Precio / NAV por acción",

  "Summary Statistics": "Resumen estadístico",
  Mean: "Media",
  Median: "Mediana",
  High: "Máximo",
  Low: "Mínimo",
  "Standard Deviation": "Desviación Estándar",
  Count: "Contar",
  "Convert to USD: False (True)": "Convertir a USD: sí (no)",
  "Show Summary Statistics Below Competitors":
    "Mostrar estadísticas resumidas debajo de los competidores",
  true: "sí",
  false: "no",

  "Next Twelve Months": "Próximos doce meses",
  "Last Twelve Months": "Últimos doce meses",
  Event: "Evento",
  "Event Date": "Fecha del evento",
  "Q2 2020 Earnings Call, Aug 05, 2020":
    "Conferencia de resultados del segundo trimestre de 2020, 05 de agosto de 2020",
  "Document Title": "Titulo del documento",
  "Form Name": "Nombre del formulario",
  Language: "Idioma",
  "Financial Period End Date": "Fecha de finalización del período financiero",
  "Release Date": "Fecha de lanzamiento",
  "Filter By Form Name": "Filtrar por nombre de formulario",

  "Error fetching filings": "Error al obtener las presentaciones",
  "Document will open in a new tab":
    "El documento se abrirá en una nueva pestaña",
  "Please make sure popups are not blocked for tikr.com":
    "Asegúrese de que las ventanas emergentes no estén bloqueadas para tikr.com",
  "Fetching Document!": "¡Obteniendo documento!",

  Shareholders: "Accionistas",
  "Insider Transactions": "Transacciones de insiders",
  "Filter by Investor Type": "Filtrar por tipo de inversor",
  "Price Used to Value Shares Held:":
    "Precio utilizado para valorar las acciones en posesión:",
  "Holdings Date": "Fecha de tenencia",
  "Last Close": "Último cierre",
  "Investor Name": "Nombre del inversor",
  "Investor Type": "Tipo de inversor",
  "Investment Advisor": "Asesor de inversiones",
  "Investment Advisor/Hedge Fund": "Asesor de inversiones / Hedge fund",
  "Pension Fund": "Fondo de pensiones",
  "Research Firm": "Empresa de análisis",
  "Hedge Fund": "Hedge fund",
  "Individual Investor": "Inversor individual",
  "Bank and Trust": "Banco y Fideicomiso",
  "Sovereign Wealth Fund": "Fondo soberano",
  Foundation: "Fundación",
  "Insurance Company": "Compañía de seguros",
  Corporation: "Corporación",
  Insider: "Insider",
  "Number of Shares": "Numero de acciones",
  Price: "Precio",
  Date: "Fecha",
  "Number of Shares Outstanding Traded":
    "Número de acciones en circulación negociadas",
  "Insider Title": "Cargo",
  "Chief Executive Officer": "Director ejecutivo",
  Officer: "Oficial",
  Unknown: "Desconocido",
  "Chief Financial Officer": "Director financiero",
  "Executive Vice President": "Cicepresidente ejecutivo",
  Type: "Tipo",
  Filing: "Presentación",
  Investments: "Inversiones",
  "Error Fetching Holdings for Investor ID":
    "Error al obtener datos para la identificación del inversor",
  "Please report to support@tikr.com": "Informe a support@tikr.com",
  "Error: No holdings for": "Error: no hay datos para",
  "Please report to support@tikr.com Error: investorId":
    "Informe a support@tikr.com Error: investorId",
  "no holdings": "sin datos",
  "Company Name": "Nombre de la empresa",
  Ticker: "Ticker",
  "Reported Value of Shares Held (MM)":
    "Valor informado de las acciones en posesión (MM)",
  "% of Shares Outstanding Held": "% de acciones en circulación en posesión",
  "Filing Type": "Tipo de presentación",
  "Investment Managers": "Gestores de inversiones",
  "Endowment Fund": "Fondo de dotación",
  "Finance Company": "Compañía financiera",
  "Private Equity": "Private equity",
  "Venture Capital": "Venture capital",
  "Brokerage Firms": "Casas de bolsa",
  "Independent Research Firm": "Empresa de análisis independiente",
  "Strategic Entities": "Entidades estratégicas",
  "Holding Company": "Holding",
  "Government Agency": "Agencia del gobierno",
  "Institution sub types": "Subtipos de instituciones",
  "Mutual Fund": "Fondo de inversión",
  "Hedge Fund Portfolio": "Cartera de hedge funds",
  "Pension Fund Portfolio": "Cartera de fondos de pensiones",

  "Error fetching shareholders": "Error al obtener accionistas",
  "Value of Shares Held (MM)": "Valor de las acciones en posesión (m)",
  "% Of Shares Outstanding Held": "% De acciones en circulación en posesión",
  "# Shares Held": "# De acciones en posesión",
  "Change in # of Shares Held": "Cambio en el número de acciones en posesión",
  "% Change in # of Shares Held":
    "% De cambio en el número de acciones en posesión",
  "This Holding as % of Firm's Portfolio":
    "Participación como % de la cartera de la empresa",

  "Financial Data Options": "Opciones de datos financieros",
  "Hide All Ticker Share Prices (Business Owner Mode)":
    "Ocultar todos los precios de acciones de Ticker (modo de propietario de negocio)",
  "Hide Ticker Price Chart after navigating away from company overview":
    "Ocultar gráfico de precios de cotización después de navegar fuera de la descripción general de la empresa",
  "Default Year To Start Financials & Estimates Data":
    "Año predeterminado para iniciar datos financieros y estimados",
  "App Layout Options": "Opciones de diseño de la aplicación",
  "Dark Mode Preview": "Vista previa del modo oscuro",
  "Updated Significant Developments Formatting":
    "Formato actualizado de desarrollos significativos",
  "Remove icons from tabs while researching an equity":
    "Elimine los iconos de las pestañas mientras investiga una acción",
  "News Options": "Opciones de noticias",
  "Referral Code": "Código de invitación",
  True: "Cierto",
  False: "Falso",
  "Reset Settings": "Reiniciar ajustes",
  "Default News Language": "Idioma de noticias predeterminado",
  "Include English Stories in News Feeds":
    "Incluir historias en inglés en las fuentes de noticias",
  "App Settings": "Ajustes de Aplicacion",
  "Link copied to clipboard": "Enlace copiado al portapapeles",
  "Click to copy to clipboard": "Haga clic para copiar al portapapeles",
  "There is an error with your Referral Code. Please contact with the error message: 'No Referral Code Found'":
    "Hay un error con su código de invitación. Póngase en contacto con el mensaje de error: 'No se encontró código de referencia'",

  MULTIPLES: "MÚLTIPLOS",
  Multiples: "Múltiplos",
  Competitors: "Competidores",
  "Forward Multiples": "Múltiplos futuros",
  "NTM Total Enterprise Value / Revenues":
    "Valor de empresa / ingresos totales de la empresa NTM",
  "NTM Total Enterprise Value / EBITDA":
    "Valor total empresarial de NTM / EBITDA",
  "NTM Total Enterprise Value / EBIT": "Valor empresarial total de NTM / EBIT",
  "NTM Price / Normalized Earnings": "Precio NTM / Ganancias normalizadas",
  "NTM Market Cap / Free Cash Flow":
    "Capitalización de mercado de NTM / Flujo de caja libre",
  "NTM Price / Cash Flow Per Share": "Precio NTM / Flujo de caja por acción",
  "Trailing Multiples": "Múltiplos finales",
  "LTM Total Enterprise Value / Revenues":
    "Valor / ingresos totales de la empresa de LTM",
  "LTM Total Enterprise Value / EBITDA":
    "Valor total empresarial de LTM / EBITDA",
  "LTM Total Enterprise Value / EBIT": "Valor empresarial total de LTM / EBIT",
  "LTM Price / Diluted EPS (P/E)": "Precio LTM / EPS diluido antes de extras",
  "LTM Price / Book Value per Share": "Precio LTM / Valor contable por acción",
  "LTM Price / Tangible Book Value per Share":
    "Precio LTM / Valor contable tangible por acción",
  "LTM Total Enterprise Value / Unlevered Free Cash Flow":
    "Valor empresarial total de LTM / Flujo de caja libre sin apalancamiento",
  "LTM Market Cap / Levered Free Cash Flow":
    "Capitalización de mercado LTM / Flujo de caja libre apalancado",
  "Price Factors": "Factores de precio",
  "Total Enterprise Value (MM)": "Valor empresarial total (MM)",
  "Market Cap (MM)": "Capitalización bursátil (MM)",
  "Forward Factors": "Factores futuros",
  "NTM Revenues": "Ingresos  NTM",
  "NTM EBITDA": "EBITDA NTM",
  "NTM EBIT": "EBIT NTM",
  "NTM Normalized Earnings Per Share":
    "Ganancias por acción normalizadas de NTM",
  "NTM Free Cash Flow": "Flujo de caja libre NTM",
  "NTM Cash Flow Per Share": "Flujo de caja por acción de NTM",
  "Trailing Factors": "Factores finales",
  "LTM Revenues": "Ingresos LTM",
  "LTM EBITDA": "EBITDA LTM",
  "LTM EBIT": "EBIT LTM",
  "LTM Diluted EPS Before Extra": "EPS diluido LTM antes extra",
  "LTM Book Value per Share": "Valor contable LTM por acción",
  "LTM Tangible Book Value per Share":
    "Valor contable tangible de LTM por acción",
  "LTM Unlevered Free Cash Flow": "Flujo de caja libre sin apalancamiento LTM",
  "LTM Levered Free Cash Flow": "Flujo de caja libre apalancado por LTM",
  "NTM = Next Twelve Months LTM = Last Twelve Months":
    "NTM = Próximos doce meses LTM = Últimos doce meses",

  "Return Ratios:": "Ratios de rentabilidad:",
  "Return on Assets %": "Rentabilidad sobre activos %",
  "Return on Capital %": "Rentabilidad sobre capital %",
  "Return On Equity %": "Rentabilidad sobre recursos propios %",
  "Return on Common Equity %": "Rentabilidad sobre acciones ordinarias %",
  "Margin Analysis:": "Análisis de los márgenes:",
  "Gross Profit Margin %": "Margen de beneficio bruto %",
  "SG&A Margin %": "Margen de gastos de venta, generales y administrativos %",
  "EBITDA Margin %": "Margen EBITDA %",
  "EBITA Margin %": "Margen EBITA %",
  "EBIT Margin %": "Margen EBIT %",
  "EBT Margin %": "Margen EBT %",
  "EBT Excl. Non-Recurring Items Margin %":
    "Margen EBT excluyendo no-recurrentes %",
  "Income From Continuing Operations Margin %":
    "Ingresos por margen de operaciones continuas %",
  "Net Income Margin %": "Margen neto %",
  "Normalized Net Income Margin %": "Margen neto normalizado %",
  "Net Avail. For Common Margin %": "Margen disponible para acciones comunes %",
  "Levered Free Cash Flow Margin %":
    "Margen de flujo de efectivo libre apalancado %",
  "Unlevered Free Cash Flow Margin %":
    "Margen de flujo de efectivo libre sin apalancamiento %",
  "Asset Turnover:": "Rotación de activos:",
  "Asset Turnover": "Rotación de activos",
  "Fixed Assets Turnover": "Rotación de activo fijo",
  "Receivables Turnover": "Rotación de cuentas por cobrar",
  "Inventory Turnover": "Volumen de ventas de inventario",
  "Working Capital Turnover": "Rotación del capital circulante",
  "Short Term Liquidity:": "Liquidez a corto plazo:",
  "Current Ratio": "Ratio de liquidez",
  "Quick Ratio": "Ratio de disposibilidad",
  "Dividend Payout %": "Pago de dividendos %",
  "Dividend Yield %": "Rentabilidad por dividendo %",
  "Op Cash Flow to Current Liab": "Flujo de caja operativo  a pasivo corriente",
  "Avg. Cash Conversion Cycle": "Promedio Ciclo de conversión de efectivo",
  "Avg. Days Sales Outstanding": "Promedio Días de ventas pendientes",
  "Avg. Days Outstanding Inventory": "Promedio Días de inventario pendiente",
  "Avg. Days Payable Outstanding": "Promedio Días a pagar pendientes",
  "Long-Term Solvency:": "Solvencia a largo plazo:",
  "Total Debt / Equity": "Deuda total / Fondos propios",
  "Total Debt / Capital": "Deuda total / Capital total",
  "Total Liabilities / Total Assets": "Pasivo total / Activo total",
  "EBIT / Interest Expense": "EBIT / Gastos por intereses",
  "EBITDA / Interest Expense": "EBITDA / Gastos por intereses",
  "(EBITDA - Capex) / Interest Expense":
    "(EBITDA - Capex) / Gastos por intereses",
  "FFO Interest Coverage": "Cobertura de intereses de FFO",
  "FFO to Total Debt (x)": "FFO a deuda total (x)",
  "Total Debt / EBITDA": "Deuda total / EBITDA",
  "Net Debt / EBITDA": "Deuda Neta / EBITDA",
  "Net Debt / (EBITDA - Capex)": "Deuda Neta / (EBITDA - Capex)",
  "Industry Metrics:": "Métricas de la industria:",
  "Nonperforming Loans / Total Loans %":
    "Préstamos en mora / Préstamos totales %",
  "Nonperforming Loans / Total Assets %": "Préstamos en mora / Activo total %",
  "Nonperforming Assets / Total Assets %":
    "Activos no productivos / Activos totales %",
  "Nonperforming Assets / Equity %": "Activos no productivos / Patrimonio %",
  "Allowance for Credit Losses / Net Charge-offs %":
    "Provisión para Pérdidas Crediticias / Cancelaciones Netas %",
  "Allowance for Credit Losses / Nonperforming Loans %":
    "Estimación para pérdidas crediticias / préstamos en mora %",
  "Allowance for Credit Losses / Total Loans %":
    "Estimación para pérdidas crediticias / Préstamos totales %",
  "Net Charge-offs / Total Average Loans %":
    "Cancelaciones netas / Préstamos promedio totales %",
  "Provision for Loan Losses / Net Charge-offs %":
    "Provisión para pérdidas crediticias / cancelaciones netas %",
  "Earning Assets / Interest Bearing Liabilities %":
    "Activos productivos / pasivos que devengan intereses %",
  "Interest Income / Average Assets %":
    "Ingresos por intereses / Activo promedio %",
  "Interest Expense / Average Assets %":
    "Gasto por intereses / Activo promedio %",
  "Net Interest Income / Average Assets %":
    "Ingresos por intereses netos / Activo promedio %",
  "Total Average Common Equity / Total Average Assets %":
    "Total promedio de capital común / total promedio de activos %",
  "Total Equity + Allowance for Loan Losses / Total Loans %":
    "Capital total + Provisión para pérdidas crediticias / Préstamos totales%",
  "Gross Loans / Total Deposits %": "Préstamos brutos / Depósitos totales %",
  "Tier 1 Capital Ratio %": "Ratio de capital de Tier 1 %",
  "Total Capital Ratio %": "Ratio de capital total %",
  "Core Tier 1 Capital Ratio %": "Relación de capital core Tier 1 %",
  "Tier 2 Capital Ratio %": "Ratio de capital de Tier 2 %",
  "Equity Tier 1 Capital Ratio %": "Equity Tier 1 Capital Ratio %",

  Revenues: "Ingresos",
  "Other Revenues (Summary Subtotal)": "Otros ingresos (subtotal resumido)",
  "Finance Div. Revenues": "Ingresos financieros",
  "Insurance Division Revenues": "Ingresos de la división de seguros",
  "Gain (Loss) on Sale of Assets (Rev)":
    "Ganancia (pérdida) en la venta de activos (Rev)",
  "Gain (Loss) on Sale of Investment (Rev)":
    "Ganancia (pérdida) en venta de inversión (Rev)",
  "Interest And Invest. Income (Rev)": "Intereses e ingresos de inversiones",
  "Other Revenues": "Otros ingresos",
  "Total Revenues": "Ingresos totales",
  "% Change YoY": "% De cambio interanual",
  "Cost Of Revenues": "Costo de ingresos",
  "Cost of Goods Sold": "Coste de los bienes vendidos",
  "Finance Div. Operating Exp.": "Gastos financieros operativos",
  "Insurance Division Operating Expenses":
    "Gastos operativos de la división de seguros",
  "Interest Expense - Finance Division":
    "Gastos por intereses - División de finanzas",
  "Gross Profit": "Beneficio bruto",
  "% Gross Margins": "% Márgenes brutos",
  "Selling General & Admin Expenses (Summary Subtotal)":
    "Gastos de venta generales y administrativos (subtotal resumido)",
  "Selling General & Admin Expenses":
    "Gastos de venta generales y administrativos",
  "Exploration / Drilling Costs": "Gastos de exploración / perforación",
  "Provision for Bad Debts": "Provisión para deudas incobrables",
  "Stock-Based Compensation": "Compensación de stock options",
  "Pre-Opening Costs": "Gastos de pre-apertura",
  "R&D Expense": "Gasto en I + D",
  "R&D Expenses": "Gastos de I + D",
  "Depreciation & Amortization": "Depreciación y amortización",
  "Amortization of Goodwill and Intangible Assets":
    "Amortización de fondos de comercio y activos intangibles",
  "Impairment of Oil Gas & Mineral Properties":
    "Deterioro del petróleo, gas y propiedades minerales",
  "Other Operating Expenses": "Otros gastos operacionales",
  "Operating Income": "Beneficio operativo",
  "% Operating Margins": "% Márgenes operativos",
  "Interest Expense": "Gastos por intereses",
  "Interest and Investment Income": "Ingresos por intereses e inversiones",
  "Interest And Investment Income": "Ingresos por intereses e inversiones",
  "Net Interest Income (Expenses)": "Ingresos (gastos) por intereses netos",
  "Other Non Operating Income (Expenses)":
    "Otros ingresos (gastos) no operativos",
  "Income (Loss) On Equity Invest.":
    "Ingresos (pérdidas) sobre capital invertido.",
  "Currency Exchange Gains (Loss)": "Ganancias (pérdidas) cambiarias",
  "EBT Excl. Unusual Items": "EBT excl. Artículos inusuales",
  "Merger & Restructuring Charges": "Cargos de fusión y reestructuración",
  "Merger & Related Restructuring Charges":
    "Cargos por fusiones y reestructuraciones relacionadas",
  "Impairment of Goodwill": "Deterioro del fondo de comercio",
  "Gain (Loss) on Sale of Investments":
    "Ganancia (pérdida) en la venta de inversiones",
  "Gain (Loss) on Sale of Investments (Rev)":
    "Ganancia (pérdida) en la venta de inversiones (Rev)",
  "Gain (Loss) on Sale Of Investments":
    "Ganancia (pérdida) en la venta de inversiones",
  "Gain (Loss) On Sale Of Assets": "Ganancia (pérdida) en la venta de activos",
  "Gain (Loss) on Sale of Assets": "Ganancia (pérdida) en la venta de activos",
  "Other Unusual Items": "Otros artículos inusuales",
  "Asset Writedown": "Devaluación de activos",
  "In Process R&D Expenses": "Gastos de I + D en proceso",
  "Insurance Settlements": "Liquidaciones de seguros",
  "Legal Settlements": "Acuerdos legales",
  "EBT Incl. Unusual Items": "EBT incl. Artículos extraordinarios",
  "Income Tax Expense": "Gastos de impuestos",
  "Earnings From Continuing Operations":
    "Beneficios por operaciones continuadas",
  "Earnings Of Discontinued Operations":
    "Beneficios por operaciones discontinuadas",
  "Extraordinary Item & Accounting Change":
    "Artículo extraordinario y cambio contable",
  "Net Income to Company": "Beneficio neto de la empresa",
  "Minority Interest": "Intereses minoritario",
  "Net Income": "Beneficio netos",
  "Preferred Dividend and Other Adjustments":
    "Dividendo preferente y otros ajustes",
  "Net Income to Common Incl Extra Items":
    "Beneficio neto a acciones comunes incluidos extradordinarios",
  "% Net Income to Common Incl Extra Items Margins":
    "Margen de beneficio neto a acciones comunes incluidos extradordinarios %",
  "Net Income to Common Excl. Extra Items":
    "Beneficio neto a acciones comunes excluidos extradordinarios",
  "% Net Income to Common Excl. Extra Items Margins":
    "Margen de beneficio neto a acciones comunes excluidos extradordinarios %",
  "Supplementary Data:": "Datos adicionales:",
  "Diluted EPS Excl Extra Items": "BPA diluido sin extraordinarios",
  "Weighted Average Diluted Shares Outstanding":
    "Promedio ponderado de acciones diluidas en circulación",
  "Weighted Average Basic Shares Outstanding":
    "Promedio ponderado de acciones básicas en circulación",
  "Dividends per Share": "Dividendo por acción",
  "Dividends Per Share": "Dividendo por acción",
  "Special Dividends Per Share": "Dividendo especial por acción",
  "Total Dividends Per Share": "Dividendo total por acción",
  "Payout Ratio %": "Dividiendo pagado sobre el beneficio neto %",
  "Basic EPS": "BPA básico",
  "FFO Payout Ratio %": "Dividendo pagado sobre el FFO%",
  FFO: "FFO",
  EBITDA: "EBITDA",
  EBITDAR: "EBITDAR",
  "Selling and Marketing Expense": "Gastos de venta y marketing",
  "General and Administrative Expense": "Gastos generales y administrativos",
  "Effective Tax Rate %": "Tasa efectiva de impuestos %",
  "Distributable Cash per Share (Diluted)":
    "Efectivo distribuible por acción (diluido)",
  "Same Store Sales Growth %": "% De crecimiento de ventas mismas tiendas",

  "Interest Income On Loans": "Ingresos por intereses sobre préstamos",
  "Interest Income On Investments": "Ingresos por intereses sobre inversiones",
  "Interest Income": "Ingresos por intereses",
  "Interest On Deposits": "Intereses sobre depósitos",
  "Total Interest On Borrowings": "Interés total sobre préstamos",
  "Net Interest Income": "Ingreso de Intereses neto",
  "Non Interest Income Total": "Total de ingresos no financieros",
  "Revenues Before Provison For Loan Losses":
    "Ingresos antes de la provisión para préstamos incobrables",
  "Provision For Loan Losses": "Provisión para préstamos incobrables",
  "Compensation And Benefits": "Compensación y beneficios",
  "Total Other Non Interest Expense":
    "Total de otros gastos distintos de intereses",
  "Non Interest Expense": "Gastos no financieros",

  "Premiums and Annuity Revenues": "Primas e ingresos por anualidades",
  "Total Interest And Dividend Income":
    "Ingresos totales por intereses y dividendos",
  "Asset Management Fee": "Tarifa de gestión de activos",
  "Non-Insurance Activities Revenues":
    "Ingresos de actividades no relacionadas con seguros",
  "Policy Benefits": "Beneficios de la póliza",
  "Policy Acquisition /  Underwriting Costs":
    "Costos de adquisición / suscripción de pólizas",

  "Fuel & Purchased Power": "Combustible y energía adquirida",
  "Operations And Maintenance": "Operaciones y mantenimiento",

  "Rental Revenues": "Ingresos por alquiler",
  "Tenant Reimbursements": "Reembolsos de inquilinos",
  "Property Management Fee": "Tarifa de administración de propiedad",
  "Interest Income On Mortgage Securities":
    "Ingresos por intereses sobre valores hipotecarios",
  "Property Expenses": "Gastos de propiedad",

  "Interest and Dividend Income": "Ingresos por intereses y dividendos",
  "Commission and Fees": "Comisiones y tarifas",
  "Mortgage Banking": "Banca hipotecaria",
  "Credit Card Fee": "Tarifas de tarjeta de crédito",
  "Loan Servicing Revenue": "Ingresos por servicios de préstamos",
  "Salaries And Other Employee Benefits":
    "Salarios y otros beneficios para empleados",
  "Cost of Services Provided": "Coste de los servicios prestados",

  "Brokerage Commission": "Comisión de corretaje",
  "Trading and Principal Transactions":
    "Transacciones de brokeraje y por cuenta propia",
  "Underwriting & Investment Banking Fee":
    "Comisión de suscripción y banca de inversión",

  "Impairment of Oil, Gas & Mineral Properties":
    "Deterioro de propiedades de petróleo, gas y minerales",
  "Total Depreciation & Amortization": "Depreciación y amortización total",
  "Amortization of Deferred Charges": "Amortización de cargos diferidos",
  "Minority Interest in Earnings": "Interés minoritario en las ganancias",
  "Other Non-Cash Items": "Otros elementos no monetarios",
  "(Gain) Loss From Sale Of Asset": "(Ganancia) Pérdida por venta de activos",
  "(Gain) Loss on Sale of Investments":
    "(Ganancia) Pérdida por venta de inversiones",
  "Asset Writedown & Restructuring Costs":
    "Deterioro de actiovs y costes de reestructuración",
  "Net (Increase) Decrease in Loans Originated / Sold - Operating":
    "(Incremento) Disminución en Préstamos Originados / Vendidos - Operativos",
  "Provision for Credit Losses": "Provisión para pérdidas crediticias",
  "(Income) Loss On Equity Investments":
    "(Ingresos) Pérdida en inversiones de capital",
  "Tax Benefit from Stock Options":
    "Beneficio fiscal de las opciones sobre acciones",
  "Provision and Write-off of Bad Debts":
    "Provisión y cancelación de deudas incobrables",
  "Net Cash From Discontinued Operations":
    "Efectivo neto de operaciones discontinuadas",
  "Other Operating Activities": "Otras actividades operativas",
  "Change in Trading Asset Securities":
    "Cambio en valores de activos de negociación",
  "Change In Accounts Receivable": "Cambio en cuentas por cobrar",
  "Change In Inventories": "Cambio en inventarios",
  "Change In Accounts Payable": "Cambio en cuentas por pagar",
  "Change In Income Taxes": "Cambio de impuestos sobre la renta",
  "Change In Deferred Taxes": "Cambio en impuestos diferidos",
  "Change in Other Net Operating Assets":
    "Variación en otros activos operativos netos",
  "Cash from Operations": "Efectivo de Operaciones",
  "Memo: Change in Net Working Capital":
    "Nota: Cambio en el capital circulante",
  "Capital Expenditure": "Gastos de capital",
  "Sale of Property, Plant, and Equipment": "Venta de inmobilizado material",
  "Cash Acquisitions": "Adquisiciones con efectivo",
  Divestitures: "Desinversiones",
  "Other Investing Activities (Summary Subtotal)":
    "Otras actividades de inversión (subtotal resumido)",
  "Sale (Purchase) of Real Estate properties":
    "Venta (compra) de propiedades inmobiliarias",
  "Sale (Purchase) of Intangible assets":
    "Venta (compra) de activos intangibles",
  "Investment in Marketable and Equity Securities":
    "Inversión en valores negociables y de renta variable",
  "Net (Increase) Decrease in Loans Originated / Sold - Investing":
    "Disminución (aumento) neta de préstamos originados / vendidos - Inversión",
  "Other Investing Activities": "Otras actividades de inversión",
  "Cash from Investing": "Efectivo de la inversión",
  "Short Term Debt Issued": "Deuda a corto plazo emitida",
  "Long-Term Debt Issued": "Deuda a largo plazo emitida",
  "Total Debt Issued": "Deuda total emitida",
  "Short Term Debt Repaid": "Deuda reembolsada a corto plazo",
  "Long-Term Debt Repaid": "Deuda a largo plazo reembolsada",
  "Total Debt Repaid": "Total de la deuda reembolsada",
  "Issuance of Common Stock": "Emisión de acciones ordinarias",
  "Repurchase of Common Stock": "Recompra de acciones comunes",
  "Issuance of Preferred Stock": "Emisión de acciones preferentes",
  "Repurchase of Preferred Stock": "Recompra de acciones preferentes",
  "Common Dividends Paid": "Dividendos comunes pagados",
  "Preferred Dividends Paid": "Dividendos preferenciales pagados",
  "Common & Preferred Stock Dividends Paid":
    "Dividendos de acciones comunes y preferentes pagados",
  "Other Financing Activities (Summary Subtotal)":
    "Otras actividades de financiación (subtotal resumido)",
  "Special Dividend Paid": "Dividendo especial pagado",
  "Other Financing Activities": "Otras Actividades de Financiamiento",
  "Cash from Financing": "Efectivo de Financiamiento",
  "Foreign Exchange Rate Adjustments": "Ajustes del tipo de cambio de divisas",
  "Miscellaneous Cash Flow Adjustments": "Ajustes varios de flujo de efectivo",
  "Net Change in Cash": "Cambio neto en efectivo",
  "Cash and Cash Equivalents, Beginning of Period":
    "Efectivo y equivalentes de efectivo, comienzo del período",
  "Cash and Cash Equivalents, End of Period":
    "Efectivo y equivalentes de efectivo, fin de período",
  "Cash Interest Paid": "Intereses en efectivo pagados",
  "Cash Taxes Paid": "Impuestos en efectivo pagados",
  "Maintenance CAPEX": "CAPEX de mantenimiento",
  "Cash Flow per Share": "Flujo de caja por acción",

  "Sale / Securitization of Accounts Receivable":
    "Venta / Titulización de cuentas por cobrar",

  "Total Depreciation, Depletion & Amortization":
    "Depreciación, agotamiento y amortización totales",
  "Deferred Policy Acquisition Costs":
    "Coste de adquisición de pólizas diferidas",
  "Amort. Of Deferred Policy Acq. Costs":
    "Amortirazión de coste de póliza diferida",
  "Reinsurance Payable": "Reaseguro por pagar",
  "Change in Unearned Revenues": "Cambio en los ingresos no devengados",
  "Change in insurance Reserves / Liabilities":
    "Cambio en Reservas / Pasivos de seguros",
  "Annuity Receipts": "Recibos de anualidades",
  "Annuity Payments": "Pagos de anualidades",

  "Nuclear Fuel Expenditures": "Gastos de combustible nuclear",
  "Contributions to Nuclear Decommissioning Trust":
    "Contribuciones al Fondo Fiduciario de Desmantelamiento Nuclear",

  "Cash And Equivalents": "Efectivo y equivalentes",
  "Short Term Investments": "Inversiones a corto plazo",
  "Trading Asset Securities": "Activos financieros para vender",
  "Total Cash And Short Term Investments":
    "Efectivo total e inversiones a corto plazo",
  "Accounts Receivable": "Cuentas por cobrar",
  "Other Receivables": "Otros por cobrar",
  "Notes Receivable": "Notas por cobrar",
  "Total Receivables": "Total de cuentas por cobrar",
  Inventory: "Inventario",
  "Prepaid Expenses": "Gastos pagados por anticipado",
  "Finance Division Loans and Leases Current":
    "Préstamos y arrendamientos de la división de finanzas al corriente",
  "Finance Division Other Current Assets":
    "División de Finanzas Otros Activos Circulantes",
  "Other Current Assets (Summary Subtotal)":
    "Otros activos corrientes (subtotal resumido)",
  "Loans Held For Sale": "Préstamos mantenidos para la venta",
  "Deferred Tax Assets Current": "Activos por impuestos diferidos Corrientes",
  "Restricted Cash": "Efectivo restringido",
  "Other Current Assets": "Otro activo corriente",
  "Total Current Assets": "Total de activo corriente",
  "Gross Property Plant And Equipment": "Inmobilizado material bruto",
  "Accumulated Depreciation": "Depreciación acumulada",
  "Net Property Plant And Equipment": "Inmobilizado material neto",
  "Long-Term Investments": "Inversiones a largo plazo",
  "Long-term Investments": "Inversiones a largo plazo",
  Goodwill: "Fondo de comercio",
  "Other Intangibles": "Otros intangibles",
  "Finance Division Loans and Leases Long-Term":
    "División de Finanzas Préstamos y Arrendamientos a Largo Plazo",
  "Finance Division Other Long-Term Assets":
    "División financiera Otros activos a largo plazo",
  "Other Assets": "Otros activos",
  "Accounts Receivable Long-Term": "Cuentas por cobrar a largo plazo",
  "Loans Receivable Long-Term": "Préstamos por cobrar a largo plazo",
  "Deferred Tax Assets Long-Term":
    "Activos por impuestos diferidos a largo plazo",
  "Deferred Charges Long-Term": "Cargos diferidos a largo plazo",
  "Other Long-Term Assets": "Otros activos a largo plazo",
  "Total Assets": "Activo total",
  "Accounts Payable": "Cuentas por pagar",
  "Accrued Expenses": "Gastos devengados",
  "Short-Term Borrowings": "Préstamos de corto plazo",
  "Short-term Borrowings": "Préstamos de corto plazo",
  "Current Portion of Long-Term Debt / Capital Leases":
    "Porción corriente de deuda a largo plazo / arrendamientos de capital",
  "Current Portion of Long-Term Debt":
    "Porción corriente de la deuda a largo plazo",
  "Current Portion of Capital Lease Obligations":
    "Porción corriente de las obligaciones de arrendamiento financiero",
  "Finance Division Debt Current": "Deuda corriente de la División de Finanzas",
  "Finance Division Other Current Liabilities":
    "Otros Pasivos Corrientes de la División de Finanzas ",
  "Other Current Liabilities": "Otros pasivos corrientes",
  "Current Income Taxes Payable": "Impuestos sobre la renta actuales por pagar",
  "Current Unearned Revenue": "Ingresos no devengados actuales",
  "Deferred Tax Liability Current": "Pasivo por impuestos diferidos Corriente",
  "Total Current Liabilities": "Total pasivo corriente",
  "Long-Term Debt": "Deuda a largo plazo",
  "Capital Leases": "Arrendamientos de capitales",
  "Finance Division Debt Non Current":
    "Deuda No Corriente de la División de Finanzas ",
  "Finance Division Other Non Current Liabilities":
    "Otro Pasivo No Corriente de la División de Finanzas ",
  "Unearned Revenue Non Current": "Ingresos no devengados no corrientes",
  "Pension & Other Post Retirement Benefits":
    "Pensión y otros beneficios posteriores a la jubilación",
  "Deferred Tax Liability Non-Current":
    "Pasivo por impuesto diferido no corriente",
  "Deferred Tax Liability Non Current":
    "Pasivo por impuesto diferido no corriente",
  "Other Non Current Liabilities": "Otro pasivo no corrientes",
  "Total Liabilities": "Pasivo Total",
  "Preferred Stock Redeemable": "Acciones preferentes reembolsables",
  "Preferred Stock Non Redeemable": "Acciones preferentes no reembolsables",
  "Preferred Stock Convertible": "Acciones preferentes convertibles",
  "Preferred Stock - Others": "Acciones preferentes - Otros",
  "Total Preferred Equity": "Total preferentes",
  "Common Stock & APIC": "Acciones comunes y prima de suscripción",
  "Common Stock": "Acciones comunes",
  "Additional Paid In Capital": "Prima de suscripción",
  "Retained Earnings": "Beneficio no distribuido",
  "Treasury Stock & Other": "Autocartera y otros",
  "Treasury Stock": "Autocartera",
  "Comprehensive Income and Other": "Resultado integral y otros",
  "Total Common Equity": "Patrimonio neto común total",
  "Total Equity": "Fondos propios totales",
  "Total Liabilities And Equity": "Pasivo total y patrimonio neto",
  "Total Shares Out. on Filing Date":
    "Total de acciones fuera. en la fecha de presentación",
  "Book Value/Share": "Valor contable / Acción",
  "Tangible Book Value": "Valor contable tangible",
  "Tangible Book Value/Share": "Valor contable tangible / acción",
  "Total Debt": "Deuda total",
  "Net Debt": "Deuda neta",
  "Total Minority Interest": "Interés minoritario total",
  "Equity Method Investments": "Inversiones por método de participación",
  Land: "Terrenos",
  Buildings: "Edificios",
  "Construction In Progress": "Construcción en progreso",
  "Full Time Employees": "Empleados a tiempo completo",

  "Investment Securities": "Valores de inversión",
  "Mortgage Backed Securities": "Valores respaldados por hipotecas",
  "Gross Loans": "Préstamos Brutos",
  "Allowance For Loan Losses": "Provisión para préstamos incobrables",
  "Other Adjustments to Gross Loans": "Otros ajustes a los préstamos brutos",
  "Investment in FHLB": "Inversión en FHLB",
  "Investment in Real Estate": "Inversión inmobiliaria",
  "Other Real Estate Owned And Foreclosed":
    "Otros bienes inmuebles en propiedad y ejecutados",
  "Interest Bearing Deposits": "Depósitos que devengan intereses",
  "Institutional Deposits": "Depósitos institucionales",
  "Non-Interest Bearing Deposits": "Depósitos que no devengan intereses",
  "Total Deposits": "Depósitos totales",
  "Federal Home Loan Bank Debt - Long-Term":
    "Deuda bancaria de préstamos hipotecarios federales: a largo plazo",
  "Trust Preferred Securities (BS)": "Valores preferentes fiduciarios (BS)",

  "Investment In Debt Securities": "Inversión en valores de deuda",
  "Investment In Equity and Preferred Securities":
    "Inversión en acciones y valores preferentes",
  "Policy Loans": "Préstamos de políza",
  "Reinsurance Recoverable - (IS)": "Reaseguro recuperable - (IS)",
  "Deferred Policy Acquisition Costs - (BS)":
    "Costos diferidos de adquisición de pólizas - (BS)",
  "Separate Account Assets": "Activos de cuenta separada",
  "Insurance And Annuity Liabilities": "Pasivos por seguros y anualidades",
  "Unpaid Claims": "Reclamaciones impagas",
  "Unearned Premiums": "Primas no devengadas",
  "Reinsurance Payable - (BS)": "Reaseguro por pagar - (BS)",

  "Land Held For Development Or Sale":
    "Terreno mantenido para desarrollo o venta",
  "Total Real Estate Assets": "Activos inmobiliarios totales",
  "Mortgage Loans": "Préstamos hipotecarios",

  "Cash & Securities Segregated": "Efectivo y valores segregados",
  "Securities Purchased Under Agreements To Resell":
    "Valores comprados en virtud de acuerdos para revender",
  "Securities Borrowed": "Valores prestados",

  "Deferred Tax Assets Long-Term (Collected)":
    "Activos por impuestos diferidos a largo plazo (recaudados)",
  "Notes Receivable (Collected)": "Documentos por cobrar (cobrados)",

  Revenue: "Ingresos",
  "% EBITDA Margins": "% Márgenes EBITDA",
  EBIT: "EBIT",
  "% EBIT Margins": "% De márgenes de EBIT",
  "EBT Normalized": "EBT normalizado",
  "% EBT Margins": "% De márgenes EBT",
  "Net Income Normalized": "Benficio neto normalizado",
  "% Net Income Margins": "% De márgenes de beneficio neto",
  "EPS Normalized": "EPS normalizado",
  "EPS (GAAP)": "EPS (GAAP)",
  "Dividend Per Share": "Dividendo por acción",
  "IS Supplementary Data:": "Datos adicionales de IS:",
  "Gross Margin (%)": "Margen bruto (%)",
  "Effective Tax Rate (%)": "Tasa efectiva de impuestos (%)",
  "EBT (Excl. Excep & GW)": "EBT (Excl. Excep y GW)",
  "EBT (GAAP)": "EBT (GAAP)",
  "Net Income (Excl. Excep & GW)": "Ingresos netos (excluidos Excep y GW)",
  "Net Income (GAAP)": "Ingresos netos (GAAP)",
  "Cash EPS": "EPS en efectivo",
  "CF Supplementary Data:": "Datos adicionales de CF:",
  "Cash From Operations": "Efectivo de las operaciones",
  "Free Cash Flow": "Flujo de caja libre",
  "Cash Flow / Share": "Flujo de caja / Acción",
  "BS Supplementary Data:": "Datos adicionales de BS:",
  "Net Debt/EBITDA": "Deuda Neta / EBITDA",
  "Book Value / Share": "Valor contable / Acción",
  "Net Asset Value": "NAV",
  "Net Asset Value / Share": "NAV / Acción",
  "Return on Equity (%)": "Rentabilidad sobre fondos propios (%)",
  "Return on Assets (%)": "Retorno sobre activos (%)",
  "Other Supplementary Data:": "Otros datos complementarios:",
  "Price Target Mean (Bottom Up)":
    "Precio objetivo promedio (de abajo hacia arriba)",
  "Distributable Cash (Income Trusts)": "Efectivo distribuible (income trust)",
  "Distributable Cash / Share (Income Trust)":
    "Efectivo distribuible / Acción  (income trust)",
  "FFO (REIT)": "FFO (REIT)",
  "FFO / Share (REIT)": "FFO / Acción (REIT)",
  "Adjusted FFO / Share (REIT)": "FFO Ajustado / Acción (REIT)",
  "Adjusted FFO (REIT)": "FFO ajustado (REIT)",
  "Same Store Sales Growth (%)": "Crecimiento de ventas mismas tiendas (%)",
  "Average Daily Production (Oil)": "Producción diaria promedio (petróleo)",
  "Average Daily Production (Gas)": "Producción diaria promedio (gas)",
  "Average Daily Production (NGL)": "Producción diaria promedio (LNG)",
  "Total Average Daily Production": "Producción diaria promedio total",
  "Total Production (Oil)": "Producción total (petróleo)",
  "Total Production (Gas)": "Producción total (gas)",
  "Total Production (NGL)": "Producción total (LNG)",
  "Total Production": "Producción total",
  "Revenues (Oil)": "Ingresos (petróleo)",
  "Revenues (Gas)": "Ingresos (Gas)",
  "Revenues (NGL)": "Ingresos (LNG)",
  "Exploration Expense": "Gastos de exploración",

  "Sign In to Your Account": "Iniciar sesión en tu cuenta",
  Email: "Correo electrónico",
  "Enter your email": "Introduce tu correo electrónico",
  Password: "Contraseña",
  "Enter your password": "Ingresa tu contraseña",
  "Forgot Password?": "¿Has olvidado tu contraseña?",
  "Reset Password": "Restablecer la contraseña",
  "Sign In": "Iniciar sesión",
  "No Account?": "¿No tiene cuenta?",
  "Create Account": "Crear una cuenta",
  "Required Input Missing": "Falta la entrada requerida",
  "Email Address Invalid": "Dirección de correo electrónico no válida",
  "Create New Account": "Crear una nueva cuenta",
  "Verify Email": "Verificar correo electrónico",
  "Re-Enter Email": "Volver a introducir correo electrónico",
  "Verify Password": "Verificar contraseña",
  "Re-Enter Password": "Escriba la contraseña otra vez",
  "Invite Code": "Codigo de invitacion",
  "Required: Invite Code": "Obligatorio: Código de invitación",
  "Optional: Invite Code": "Opcional: Código de invitación",
  "Have an account?": "¿Tener una cuenta?",
  "I accept the": "Acepto la",
  "Privacy Policy": "Política de privacidad",
  "and the": "y las",
  "Terms of Use": "Condiciones de uso",
  "Emails Do Not Match": "Los correos no concuerdan",
  "Passwords Do Not Match": "Las contraseñas no coinciden",
  "Must Accept Privacy Policy and Terms of Use":
    "Debe aceptar la política de privacidad y los términos de uso",
  "Verification Code": "Código de verificación",
  "Lost your code? Check Spam Folder or":
    "¿Perdiste tu código? Compruebe la carpeta de correo no deseado o",
  "Resend Code": "Reenvia el codigo",
  "Code Resent to": "Código reenviado a",
  "Back to Sign In": "Volver a Iniciar sesión",
  "User is already confirmed.": "El usuario ya está confirmado.",
  "Code Resent": "Código reenviado",
  "Send Reset Code": "Enviar código de reinicio",
  "Enter Email to Reset Password":
    "Ingrese el correo electrónico para restablecer la contraseña",
  "Confirm Email Verification Code Sent To":
    "Confirme el código de verificación de correo electrónico enviado a",
  "Username cannot be empty": "El nombre de usuario no puede estar vacío",
  "Must be at least 6 characters long": "Debe tener al menos 6 caracteres",
  Required: "Requerido",
  "Incorrect username or password.":
    "Nombre de usuario o contraseña incorrecta.",
  "User does not exist.": "El usuario no existe.",
  "User already exists": "El usuario ya existe",
  "Username/client id combination not found.":
    "No se encontró la combinación de nombre de usuario / ID de cliente.",
  "Invalid verification code provided, please try again.":
    "Se proporcionó un código de verificación no válido. Vuelva a intentarlo.",
  "Financial Data ∀": "Datos financieros ∀",

  Filter: "Filtrar",
  "Clear Filter": "Borrar filtro",
  "Error Fetching News ... Try Refreshing the Page!":
    "Error al obtener noticias ... ¡Intente actualizar la página!",
  "Close Story": "Cerrar noticia",
  "Loading News Story...": "Cargando noticia ...",
  "reuters.com": "reuters.com",
  "Sorry, We couldn't find any news for":
    "Lo sentimos, no pudimos encontrar ninguna noticia para",
  "Significant Developments": "Desarrollos significativos",
  "Error Fetching Significant Developments":
    "Error al obtener desarrollos significativos",
  reuters: "Reuters",
  "More News": "Mas noticias",

  "Hide Labels": "Ocultar etiquetas",
  "Click on row in table below to chart":
    "Haga clic en la fila de la tabla a continuación para crear un gráfico.",
  "Convert to USD:": "Convertir a USD:",
  column: "columna",
  line: "línea",
  Average: "Promedio",
  Last: "Último",

  Region: "Región",
  "Select Region": "Seleccione región",
  "Asia / Pacific": "Asia / Pacífico",
  "United States and Canada": "Estados Unidos y Canadá",
  Europe: "Europa",
  "Africa / Middle East": "África / Medio Oriente",
  "Latin America and Caribbean": "América Latina y el caribe",

  "Select Period": "Seleccionar período",
  "Select Relative Period": "Seleccionar período relativo",
  "Select Fiscal Year": "Seleccionar año fiscal",
  "Select Calendar Year": "Seleccionar año calendario",
  "Select Quarter": "Seleccionar trimestre",
  "Fiscal Year": "Año fiscal",
  FY: "AF",
  "Calendar Year": "Año del calendario",
  CY: "AC",
  "Fiscal Quarter": "Trimestre fiscal",
  FQ: "T",

  "Select Value Type": "Seleccione el tipo de valor",
  "Mean Consensus Estimate": "Estimación media de consenso",
  "Median Consensus Estimate": "Estimación mediana de consenso",
  Actual: "Real",
  "Reported Actual": "Reportado real",
  "High Consensus Estimate": "Estimación de consenso (máximo)",
  "Low Consensus Estimate": "Estimación de consenso (mínimo)",
  "Number of Consensus Estimates": "Número de estimaciones de consenso",
  "Consensus Estimate Standard Deviation":
    "Desviación estándar de estimación de consenso",
  "Management Guidance Mid": "Estimación del management (media)",
  "Management Guidance High": "Estimación del management (máxima)",
  "Management Guidance Low": "Estimación del management (mínima)",
  "Market Capitalization": "Capitalización bursátil",
  "Total Enterprise Value": "Valor de empresa total",
  "Last Close Price": "Último precio de cierre",
  "Year Founded": "Año de fundación",

  "equal to": "igual a",
  "not equal to": "no igual a",
  "greater than": "mayor que",
  "greater than or equal to": "mayor qué o igual a",
  "less than": "menor que",
  "less than or equal to": "menor que o igual a",
  between: "Entre",
  is: "es",
  "is not": "no es",
  "is one of": "es uno de",
  "is not one of": "no es uno de",
  any: "alguna",
  all: "todas",
  and: "y",
  or: "o",
  ">": ">",
  ">=": "> =",
  "<": "<",
  "<=": "<=",
  "=": "=",

  "Sorry! Error searching for": "¡Lo siento! Error al buscar",

  Industry: "Industria",
  "Select Industry": "Seleccionar industria",
  "Capital Markets": "Mercado de capitales",
  "Metals and Mining": "Metales y Minería",
  "Real Estate Management and Development": "Gestión y desarrollo inmobiliario",
  "Food Products": "Productos alimenticios",
  Machinery: "Maquinaria",
  Chemicals: "Productos quimicos",
  "Electronic Equipment, Instruments and Components":
    "Equipos, instrumentos y componentes electrónicos",
  Banks: "Bancos",
  Software: "Software",
  "Oil, Gas and Consumable Fuels": "Petróleo, gas y combustibles consumibles",
  Pharmaceuticals: "Productos farmacéuticos",
  "Textiles, Apparel and Luxury Goods":
    "Textiles, confecciones y artículos de lujo",
  "Construction and Engineering": "Construcción e Ingeniería",
  "Hotels, Restaurants and Leisure": "Hoteles, restaurantes y ocio",
  "IT Services": "Servicios de IT",
  Biotechnology: "Biotecnología",
  "Commercial Services and Supplies": "Servicios y suministros comerciales",
  "Electrical Equipment": "Equipo eléctrico",
  "Semiconductors and Semiconductor Equipment":
    "Semiconductores y equipos semiconductores",
  "Healthcare Equipment and Supplies": "Equipos y suministros sanitarios",
  "Equity Real Estate Investment Trusts (REITs)":
    "Fideicomisos de inversión inmobiliaria (REITs)",
  Media: "Medios de comunicación",
  "Auto Components": "Componentes de automoción",
  Entertainment: "Entretenimiento",
  "Household Durables": "Duraderos para el hogar",
  "Trading Companies and Distributors": "Empresas comerciales y distribuidores",
  "Specialty Retail": "Minorista especializado",
  "Healthcare Providers and Services":
    "Proveedores y servicios de atención médica",
  Insurance: "Seguro",
  "Diversified Financial Services": "Servicios financieros diversificados",
  "Professional Services": "Servicios profesionales",
  "Communications Equipment": "Equipo de comunicaciones",
  "Construction Materials": "Materiales de construcción",
  "Food and Staples Retailing":
    "Venta al por menor de alimentos y productos básicos",
  "Building Products": "Productos de construcción",
  "Containers and Packaging": "Envases y Embalajes",
  "Personal Products": "Productos personales",
  "Independent Power and Renewable Electricity Producers":
    "Productores independientes de energía y electricidad renovable",
  "Diversified Consumer Services": "Servicios al consumidor diversificados",
  "Energy Equipment and Services": "Equipos y servicios energéticos",
  "Consumer Finance": "Financiamiento al consumo",
  "Internet and Direct Marketing Retail":
    "Comercio minorista de Internet y marketing directo",
  Distributors: "Distribuidores",
  "Interactive Media and Services": "Servicios y medios interactivos",
  Beverages: "Bebidas",
  "Technology Hardware, Storage and Peripherals":
    "Tecnología Hardware, almacenamiento y periféricos",
  "Diversified Telecommunication Services":
    "Servicios de telecomunicaciones diversificados",
  "Paper and Forest Products": "Productos de papel y forestales",
  "Road and Rail": "Carretera y ferrocarril",
  "Electric Utilities": "Utilidades electricas",
  "Transportation Infrastructure": "Infraestructura de transporte",
  "Aerospace and Defense": "Aeroespacial y Defensa",
  "Thrifts and Mortgage Finance": "Ahorros e hipotecas",
  "Air Freight and Logistics": "Transporte aéreo y logística",
  "Health Care Technology": "Tecnología para el cuidado de la salud",
  "Industrial Conglomerates": "Conglomerados industriales",
  Marine: "Marina",
  "Leisure Products": "Productos de ocio",
  "Life Sciences Tools and Services":
    "Herramientas y servicios de ciencias biológicas",
  "Multiline Retail": "Minorista multilínea",
  Automobiles: "Automóviles",
  "Gas Utilities": "Servicios públicos de gas",
  "Water Utilities": "Servicios de agua",
  "Wireless Telecommunication Services":
    "Servicios de telecomunicaciones inalámbricas",
  Airlines: "Aerolíneas",
  "Household Products": "Productos domésticos",
  Tobacco: "Tabaco",
  "Mortgage Real Estate Investment Trusts (REITs)":
    "Fideicomisos de inversión inmobiliaria hipotecaria (REITs)",
  "Multi-Utilities": "Servicios públicos",

  "Welcome to TIKR": "Bienvenido a TIKR",
  "<u>YouTube:</u> subscribe to our YT channel and watch our video tutorials <a href='https://www.youtube.com/tikrterminal'>here</a> on how to best use TIKR":
    "<u>YouTube:</u> suscríbete a nuestro canal de YT y mira nuestros tutoriales en vídeo <a href='https://www.youtube.com/tikrterminal'>aquí</a> sobre cómo utilizar mejor TIKR.",
  "<u>Referrals:</u> Invite others to TIKR with your personal referral link below":
    "<u>Referrals:</u> Invita a otros a TIKR con tu enlace de recomendación personal a continuación.",
  "Main TIKR Data": "Datos principales de TIKR",
  "Secondary Data": "Datos secundarios",
  "Get high-quality, accurate financial data with global stocks coverage powered by S&P Global CapitalIQ and Wall Street analysts":
    "Obtenga datos financieros precisos y de alta calidad con cobertura de acciones globales impulsada por S&P Global CapitalIQ y analistas de Wall Street",
  "Unlock greater insights with a secondary source for financials, providing longer-term historical data (though with less detail)":
    "Desbloquea mayores perspectivas con una fuente secundaria para los datos financieros, proporcionando datos históricos a más largo plazo (aunque con menos detalle)",
  "Sorry, the segments table is currently not available for":
    "Lo sentimos, la tabla de segmentos no está disponible actualmente para",
  "try our 'Secondary Data' source on the template selection above":
    "pruebe nuestra fuente 'Datos secundarios' en la selección de plantillas anterior",
  "Our 'Secondary Data' source does not have information for":
    "Nuestra fuente de 'Datos secundarios' no dispone de información para",
  "Upgrade your subscription to copy data. 3 years at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "Actualice su suscripción para copiar datos. 3 años cada vez para 'Datos principales de TIKR' y hasta 30 años para 'Datos secundarios'",
  "Upgrade your subscription to copy data up to 3 years at a time":
    "Actualice su suscripción para copiar datos de hasta 3 años cada vez",
  "You can copy 3 years of data at a time for 'Main TIKR Data' and up to 30 years for 'Secondary Data'":
    "Puede copiar 3 años de datos a la vez para los 'Datos principales de TIKR' y hasta 30 años para los 'Datos secundarios'",
  "You can copy only 3 years of data at a time":
    "Sólo puedes copiar 3 años de datos a la vez",
}
