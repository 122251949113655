import financialsLineitems from "./financials_lineitems"

const forwardMultiplesItems = [
  {
    text: "Forward Total Enterprise Value / Revenues",
    abbr: "Forward TEV/REV",
    value: "fmulti_tevDIVRevenue",
    dataTypeGroup: "Forward Valuation Multiple",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Total Enterprise Value / EBITDA",
    abbr: "Forward TEV/EBITDA",
    value: "fmulti_tevDIVEBITDA",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Total Enterprise Value / EBIT",
    abbr: "Forward TEV/EBIT",
    dataTypeGroup: "Forward Valuation Multiple",
    value: "fmulti_tevDIVEBIT",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Price / Normalized Earnings Per Share",
    abbr: "Forward P/E",
    value: "fmulti_priceDIVEPS Normalized",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Price / Book Value per Share",
    abbr: "Forward P/BVPS",
    value: "fmulti_priceDIVBook Value / Share",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Market Cap / Free Cash Flow",
    abbr: "Forward MC/FCF",
    value: "fmulti_mcDIVFree Cash Flow",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Price / Cash Flow Per Share",
    abbr: "Forward P/CFPS",
    value: "fmulti_priceDIVCash Flow / Share",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Price / FFO Per Share",
    abbr: "Forward P/FFO",
    value: "fmulti_priceDIVFFO / Share (REIT)",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
  {
    text: "Forward Price / AFFO Per Share",
    abbr: "Forward P/AFFO",
    value: "fmulti_priceDIVAdjusted FFO / Share (REIT)",
    dataTypeGroup: "Forward Valuation Multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    selType: "est_multiple",
  },
]

const trailingMultiplesItems = [
  {
    text: "Trailing Total Enterprise Value / Revenues",
    abbr: "Trailing TEV/REV",
    value: "hmulti_tevDIVTotal Revenues",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "28",
  },
  {
    text: "Trailing Total Enterprise Value / EBITDA",
    abbr: "Trailing TEV/EBITDA",
    value: "hmulti_tevDIVEBITDA",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "4051",
  },
  {
    text: "Trailing Total Enterprise Value / EBIT",
    abbr: "Trailing TEV/EBIT",
    value: "hmulti_tevDIVEBIT",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "400",
  },
  {
    text: "Trailing Price / Diluted EPS Before Extra",
    abbr: "Trailing P/Diluted EPS before Extra",
    value: "hmulti_priceDIVDiluted EPS - Continuing Operations",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "142",
  },
  {
    text: "Trailing Price / Book Value per Share",
    abbr: "Trailing P/BVPS",
    value: "hmulti_priceDIVBook Value / Share",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "4020",
  },
  {
    text: "Trailing Price / Tangible Book Value per Share",
    abbr: "Trailing P/Tangible BVPS",
    value: "hmulti_priceDIVTangible Book Value Per Share",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "4156",
  },
  {
    text: "Trailing TEV/Unlevered Free Cash Flow",
    abbr: "Trailing TEV/Unlevered FCF",
    value: "hmulti_tevDIVUnlevered Free Cash Flow",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "4423",
  },
  {
    text: "Trailing Market Cap / Levered Free Cash Flow",
    abbr: "Trailing MC/Levered FCF",
    value: "hmulti_mcDIVLevered Free Cash Flow",
    dataTypeGroup: "Trailing Valuation Multiple",
    selType: "hist_multiple",
    format: "normal",
    formula: "div",
    type: "turns",
    denominator: "4422",
  },
]

const estimateLineitems = [
  {
    text: "Revenue",
    value: "est_Revenue",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "EBIT",
    value: "est_EBIT",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "EPS (GAAP)",
    value: "est_EPS (GAAP)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Income (GAAP)",
    value: "est_Net Income (GAAP)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "EBT Normalized",
    value: "est_EBT Normalized",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "EPS Normalized",
    value: "est_EPS Normalized",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Income Normalized",
    value: "est_Net Income Normalized",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "EBITDA",
    value: "est_EBITDA",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "Return on Equity (%)",
    value: "est_Return on Equity",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "pct",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "EBT (GAAP)",
    value: "est_EBT (GAAP)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "Book Value / Share",
    value: "est_Book Value / Share",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Capital Expenditure",
    value: "est_Capital Expenditure",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Dividend / Share",
    value: "est_DPS",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Cash From Operations",
    value: "est_Cash From Operations",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Depreciation & Amortization",
    value: "est_Depreciation & Amortization",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Gross Margin (%)",
    value: "est_Gross Margin",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "pct",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Interest Expense",
    value: "est_Interest Expense",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Debt",
    value: "est_Net Debt",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Debt / EBITDA",
    value: "est_Net DebtDIVEBITDA",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "turns",
    valueTypeArr: ["v"], // FIXME: only value for now for Net Debt / EBITDA
    // valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Free Cash Flow",
    value: "est_Free Cash Flow",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "Return on Assets (%)",
    value: "est_Return on Assets",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "pct",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Cash Flow / Share",
    value: "est_Cash Flow / Share",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Effective Tax Rate (%)",
    value: "est_Effective Tax Rate",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "pct",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "TEV",
    value: "est_TEV",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Asset Value / Share",
    value: "est_Net Asset Value / Share",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Asset Value",
    value: "est_Net Asset Value",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Cash EPS",
    value: "est_Cash EPS",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Maintenance Capital Expenditure",
    value: "est_Maintenance Capital Expenditure",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Same Store Sales Growth (%)",
    value: "est_Same Store Sales Growth",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "pct",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "FFO (REIT)",
    value: "est_FFO (REIT)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "FFO / Share (REIT)",
    value: "est_FFO / Share (REIT)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "EPS (Excl. Excep & GW)",
    value: "est_EPS (Excl. Excep & GW)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Net Income (Excl. Excep & GW)",
    value: "est_Net Income (Excl. Excep & GW)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "Distributable Cash (Income Trusts)",
    value: "est_Distributable Cash (Income Trusts)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Adjusted FFO (REIT)",
    value: "est_Adjusted FFO (REIT)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Adjusted FFO / Share (REIT)",
    value: "est_Adjusted FFO / Share (REIT)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "EBT (Excl. Excep & GW)",
    value: "est_EBT (Excl. Excep & GW)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "margins", "yoyChange", "cagr"],
  },
  {
    text: "Distributable Cash / Share (Income Trust)",
    value: "est_Distributable Cash / Share (Income Trust)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "float",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Total Average Daily Production",
    value: "est_Total Average Daily Production",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprodrate",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Average Daily Production (Oil)",
    value: "est_Average Daily Production (Oil)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprodrate",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Average Daily Production (Gas)",
    value: "est_Average Daily Production (Gas)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprodrate",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Total Production",
    value: "est_Total Production",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprod",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Exploration Expense",
    value: "est_Exploration Expense",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Total Production (Oil)",
    value: "est_Total Production (Oil)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprod",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Total Production (Gas)",
    value: "est_Total Production (Gas)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprod",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Average Daily Production (NGL)",
    value: "est_Average Daily Production (NGL)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprodrate",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Revenues (Oil)",
    value: "est_Revenues (Oil)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Revenues (Gas)",
    value: "est_Revenues (Gas)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Total Production (NGL)",
    value: "est_Total Production (NGL)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "ogprod",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
  {
    text: "Revenues (NGL)",
    value: "est_Revenues (NGL)",
    hint: "",
    selType: "est_lineitem",
    dataTypeGroup: "Consensus Estimate",
    type: "floatint",
    valueTypeArr: ["v", "yoyChange", "cagr"],
  },
]

const companyAttributes = [
  {
    text: "Ticker",
    value: "ticker",
    hint: "",
    type: "str",
  },
  {
    text: "Last Market Capitalization",
    abbr: "Last Market Cap",
    value: "usdmarketcap",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "floatint",
  },
  {
    text: "Last Total Enterprise Value",
    abbr: "Last TEV",
    value: "usdtev",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "floatint",
  },
  {
    text: "Last Close Price",
    abbr: "Last Price",
    value: "usdc",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "float",
  },
  {
    text: "Stock Price % Change",
    abbr: "Stock Price % Change",
    value: "pricechange",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Historical Price",
    type: "pct",
  },
  {
    text: "Shares Outstanding",
    value: "sharesoutstanding",
    hint: "",
    selType: "company_attr",
    screener: false,
    dataTypeGroup: "Company Attribute",
    type: "floatint",
    unitStr: "MM",
  },
  {
    text: "Quote Currency",
    value: "currencyid",
    hint: "",
    selType: "company_attr",
    dataTypeGroup: "Company Attribute",
    screener: false,
    type: "currencyid",
  },
  {
    text: "Security Type",
    value: "securityfeaturename",
    hint: "",
    selType: "company_attr",
    // screener: true,
    dataTypeGroup: "Company Attribute",
    type: "securityfeaturename,",
  },
  {
    text: "Primary Exchange Name",
    value: "exchangename",
    hint: "",
    selType: "company_attr",
    screener: false,
    dataTypeGroup: "Company Attribute",
    type: "exchangename",
  },
  {
    text: "Primary Exchange",
    value: "exchangesymbol",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "exchangesymbol",
  },
  {
    text: "Country", // which country is this? Exchange Country?
    value: "country",
    hint: "",
    selType: "company_attr",
    // screener: true,
    dataTypeGroup: "Company Attribute",
    type: "country", // I think this is the long name of country
  },
  {
    text: "2 Digit Country Code",
    value: "isocountry2",
    hint: "",
  },
  {
    text: "3 Digit Country Code",
    value: "isocountry3",
    hint: "",
  },
  {
    text: "Date of Local Currency to USD Conversion",
    value: "exdate",
    hint: "",
  },
  {
    text: "dev: snapId",
    value: "snapid",
    hint: "",
  },
  {
    text: "Local Currency to USD Conversion Rate",
    value: "exrate",
    hint: "",
  },
  {
    text: "dev: Best Currency Conversion Date",
    value: "bestexdate",
    hint: "",
  },
  {
    text: "Last Close Date",
    value: "quotedate",
    hint: "",
  },
  {
    text: "Last Close Price",
    value: "c",
    hint: "",
  },
  {
    text: "Last Volume",
    value: "volume",
    hint: "",
  },
  {
    text: "dev: Adjustment Factor",
    value: "adjustmentfactor",
    hint: "",
  },
  {
    text: "Volume Weighted Average Price",
    value: "vwap",
    hint: "",
  },
  {
    text: "Last MC / TEV Date",
    value: "mcdate",
    hint: "",
  },
  {
    text: "Last Market Capitalization",
    value: "marketcap",
    hint: "",
  },
  {
    text: "Last Enterprise Value",
    value: "tev",
    hint: "",
  },
  {
    text: "Last Volume Normalized To USD",
    value: "usdvolume",
    hint: "",
  },
  {
    text: "Company Name",
    value: "companyname",
    hint: "",
  },
  {
    text: "City",
    value: "city",
    hint: "",
  },
  {
    text: "Company Status",
    value: "companystatustypeid",
    hint: "",
    selType: "company_attr",
    // screener: true,
    dataTypeGroup: "Company Attribute",
    type: "companystatustypeid",
  },
  {
    text: "Company Type",
    value: "companytypeid",
    hint: "",
    selType: "company_attr",
    // screener: true,
    dataTypeGroup: "Company Attribute",
    type: "companytypeid",
  },
  {
    text: "Industry",
    value: "simpleindustryid",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "simpleindustry",
  },
  {
    text: "Sector",
    value: "sector",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "sector",
  },
  {
    text: "Year Founded",
    value: "yearfounded",
    hint: "",
    selType: "company_attr",
    // screener: true,
    dataTypeGroup: "Company Attribute",
    type: "int",
  },
  {
    text: "Website",
    value: "webpage",
    hint: "",
  },
  {
    text: "Financial Reporting Template Type",
    value: "reportingtemplatetypeid", // TODO: should I delete this?
    hint: "",
    selType: "company_attr",
    screener: false,
    dataTypeGroup: "Company Attribute",
  },
  {
    text: "Country",
    value: "companycountryid",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "country",
    type: "country",
  },
  {
    text: "Incorporation Country",
    value: "incorporationcountryid",
    hint: "",
    selType: "company_attr",
    screener: false,
    dataTypeGroup: "Company Attribute",
  },
  {
    text: "Global Region",
    value: "region",
    hint: "",
    selType: "company_attr",
    screener: true,
    dataTypeGroup: "Company Attribute",
    type: "region",
  },
  {
    text: "dev: Security Name securityname",
    value: "securityname",
    hint: "",
    type: "str",
  },
  {
    text: "Start Date of Traded Security",
    value: "securitystartdate",
    hint: "",
    type: "date",
  },
]

const priceChangeTimePeriods = [
  {
    text: "1 Week",
    abbr: "1 Week",
    value: "1week",
    hint: "",
  },
  {
    text: "1 Month",
    abbr: "1 Month",
    value: "1mo",
    hint: "",
  },
  {
    text: "3 Months",
    abbr: "3 Months",
    value: "3mo",
    hint: "",
  },
  {
    text: "6 Months",
    abbr: "6 Months",
    value: "6mo",
    hint: "",
  },
  {
    text: "Year To Date",
    abbr: "YTD",
    value: "ytd",
    hint: "",
  },
  {
    text: "1 Year",
    abbr: "1 Year",
    value: "1yr",
    hint: "",
  },
  {
    text: "3 Year",
    abbr: "3 Years",
    value: "3yr",
    hint: "",
  },
  {
    text: "5 Year",
    abbr: "5 Years",
    value: "5yr",
    hint: "",
  },
  {
    text: "10 Year",
    abbr: " 10 Years",
    value: "10yr",
    hint: "",
  },
]

const valueTypes = [
  { value: "v" },
  { value: "yoyChange" },
  { value: "cagr" },
  { value: "margins" },
]

const periodsForValueTypes = {
  v: ["FY", "CY", "NTM", "FQ"],
  yoyChange: ["FY", "CY", "NTM", "FQ"],
  cagr: ["FY", "CY", "FQ"],
  margins: ["FY", "CY", "NTM", "FQ"],
}

const valueTypeMetaObj = {
  v: {
    text: "Value",
    hint: "Value of lineitem",
  },
  yoyChange: {
    text: "% Change YoY",
    hint: "lineitem percent change year on year",
  },
  cagr: { text: "CAGR", hint: "lineitem compounded annual growth rate" },
  margins: { text: "% Margins", hint: "Divided by Revenue" },
}

const unitOptions = {
  float: "USD",
  int: "",
  floatint: "MM USD",
  pct: "%",
  turns: "x",
  date: "",
}

const industryArr = [
  {
    value: "8",
    numCompanies: "279",
    text: "Aerospace and Defense",
    sector: "Industrials",
  },
  {
    value: "17",
    numCompanies: "242",
    text: "Air Freight and Logistics",
    sector: "Industrials",
  },
  {
    value: "18",
    numCompanies: "116",
    text: "Airlines",
    sector: "Industrials",
  },
  {
    value: "22",
    numCompanies: "846",
    text: "Auto Components",
    sector: "Consumer Discretionary",
  },
  {
    value: "23",
    numCompanies: "161",
    text: "Automobiles",
    sector: "Consumer Discretionary",
  },
  {
    value: "46",
    numCompanies: "1694",
    text: "Banks",
    sector: "Financials",
  },
  {
    value: "35",
    numCompanies: "375",
    text: "Beverages",
    sector: "Consumer Staples",
  },
  {
    value: "43",
    numCompanies: "1246",
    text: "Biotechnology",
    sector: "Healthcare",
  },
  {
    value: "9",
    numCompanies: "472",
    text: "Building Products",
    sector: "Industrials",
  },
  {
    value: "50",
    numCompanies: "12598",
    text: "Capital Markets",
    sector: "Financials",
  },
  {
    value: "3",
    numCompanies: "1895",
    text: "Chemicals",
    sector: "Materials",
  },
  {
    value: "15",
    numCompanies: "1041",
    text: "Commercial Services and Supplies",
    sector: "Industrials",
  },
  {
    value: "57",
    numCompanies: "513",
    text: "Communications Equipment",
    sector: "Information Technology",
  },
  {
    value: "10",
    numCompanies: "1393",
    text: "Construction and Engineering",
    sector: "Industrials",
  },
  {
    value: "4",
    numCompanies: "505",
    text: "Construction Materials",
    sector: "Materials",
  },
  {
    value: "49",
    numCompanies: "407",
    text: "Consumer Finance",
    sector: "Financials",
  },
  {
    value: "5",
    numCompanies: "463",
    text: "Containers and Packaging",
    sector: "Materials",
  },
  {
    value: "30",
    numCompanies: "396",
    text: "Distributors",
    sector: "Consumer Discretionary",
  },
  {
    value: "28",
    numCompanies: "441",
    text: "Diversified Consumer Services",
    sector: "Consumer Discretionary",
  },
  {
    value: "48",
    numCompanies: "606",
    text: "Diversified Financial Services",
    sector: "Financials",
  },
  {
    value: "61",
    numCompanies: "351",
    text: "Diversified Telecommunication Services",
    sector: "Communication Services",
  },
  {
    value: "63",
    numCompanies: "299",
    text: "Electric Utilities",
    sector: "Utilities",
  },
  {
    value: "11",
    numCompanies: "1040",
    text: "Electrical Equipment",
    sector: "Industrials",
  },
  {
    value: "59",
    numCompanies: "1800",
    text: "Electronic Equipment, Instruments and Components",
    sector: "Information Technology",
  },
  {
    value: "1",
    numCompanies: "413",
    text: "Energy Equipment and Services",
    sector: "Energy",
  },
  {
    value: "69",
    numCompanies: "826",
    text: "Entertainment",
    sector: "Communication Services",
  },
  {
    value: "52",
    numCompanies: "876",
    text: "Equity Real Estate Investment Trusts (REITs)",
    sector: "Real Estate",
  },
  {
    value: "34",
    numCompanies: "491",
    text: "Food and Staples Retailing",
    sector: "Consumer Staples",
  },
  {
    value: "36",
    numCompanies: "1949",
    text: "Food Products",
    sector: "Consumer Staples",
  },
  {
    value: "64",
    numCompanies: "129",
    text: "Gas Utilities",
    sector: "Utilities",
  },
  {
    value: "42",
    numCompanies: "236",
    text: "Health Care Technology",
    sector: "Healthcare",
  },
  {
    value: "40",
    numCompanies: "885",
    text: "Healthcare Equipment and Supplies",
    sector: "Healthcare",
  },
  {
    value: "41",
    numCompanies: "740",
    text: "Healthcare Providers and Services",
    sector: "Healthcare",
  },
  {
    value: "27",
    numCompanies: "1348",
    text: "Hotels, Restaurants and Leisure",
    sector: "Consumer Discretionary",
  },
  {
    value: "24",
    numCompanies: "804",
    text: "Household Durables",
    sector: "Consumer Discretionary",
  },
  {
    value: "38",
    numCompanies: "110",
    text: "Household Products",
    sector: "Consumer Staples",
  },
  {
    value: "67",
    numCompanies: "447",
    text: "Independent Power and Renewable Electricity Producers",
    sector: "Utilities",
  },
  {
    value: "12",
    numCompanies: "224",
    text: "Industrial Conglomerates",
    sector: "Industrials",
  },
  {
    value: "51",
    numCompanies: "715",
    text: "Insurance",
    sector: "Financials",
  },
  {
    value: "70",
    numCompanies: "391",
    text: "Interactive Media and Services",
    sector: "Communication Services",
  },
  {
    value: "31",
    numCompanies: "395",
    text: "Internet and Direct Marketing Retail",
    sector: "Consumer Discretionary",
  },
  {
    value: "55",
    numCompanies: "1242",
    text: "IT Services",
    sector: "Information Technology",
  },
  {
    value: "25",
    numCompanies: "219",
    text: "Leisure Products",
    sector: "Consumer Discretionary",
  },
  {
    value: "45",
    numCompanies: "215",
    text: "Life Sciences Tools and Services",
    sector: "Healthcare",
  },
  {
    value: "13",
    numCompanies: "1902",
    text: "Machinery",
    sector: "Industrials",
  },
  {
    value: "19",
    numCompanies: "223",
    text: "Marine",
    sector: "Industrials",
  },
  {
    value: "29",
    numCompanies: "867",
    text: "Media",
    sector: "Communication Services",
  },
  {
    value: "6",
    numCompanies: "3658",
    text: "Metals and Mining",
    sector: "Materials",
  },
  {
    value: "68",
    numCompanies: "60",
    text: "Mortgage Real Estate Investment Trusts (REITs)",
    sector: "Financials",
  },
  {
    value: "65",
    numCompanies: "57",
    text: "Multi-Utilities",
    sector: "Utilities",
  },
  {
    value: "32",
    numCompanies: "173",
    text: "Multiline Retail",
    sector: "Consumer Discretionary",
  },
  {
    value: "2",
    numCompanies: "1640",
    text: "Oil, Gas and Consumable Fuels",
    sector: "Energy",
  },
  {
    value: "7",
    numCompanies: "314",
    text: "Paper and Forest Products",
    sector: "Materials",
  },
  {
    value: "39",
    numCompanies: "442",
    text: "Personal Products",
    sector: "Consumer Staples",
  },
  {
    value: "44",
    numCompanies: "1454",
    text: "Pharmaceuticals",
    sector: "Healthcare",
  },
  {
    value: "16",
    numCompanies: "564",
    text: "Professional Services",
    sector: "Industrials",
  },
  {
    value: "53",
    numCompanies: "2277",
    text: "Real Estate Management and Development",
    sector: "Financials",
  },
  {
    value: "20",
    numCompanies: "305",
    text: "Road and Rail",
    sector: "Industrials",
  },
  {
    value: "60",
    numCompanies: "914",
    text: "Semiconductors and Semiconductor Equipment",
    sector: "Information Technology",
  },
  {
    value: "56",
    numCompanies: "1704",
    text: "Software",
    sector: "Information Technology",
  },
  {
    value: "33",
    numCompanies: "767",
    text: "Specialty Retail",
    sector: "Consumer Discretionary",
  },
  {
    value: "58",
    numCompanies: "374",
    text: "Technology Hardware, Storage and Peripherals",
    sector: "Information Technology",
  },
  {
    value: "26",
    numCompanies: "1425",
    text: "Textiles, Apparel and Luxury Goods",
    sector: "Consumer Discretionary",
  },
  {
    value: "47",
    numCompanies: "251",
    text: "Thrifts and Mortgage Finance",
    sector: "Financials",
  },
  {
    value: "37",
    numCompanies: "70",
    text: "Tobacco",
    sector: "Consumer Staples",
  },
  {
    value: "14",
    numCompanies: "792",
    text: "Trading Companies and Distributors",
    sector: "Industrials",
  },
  {
    value: "21",
    numCompanies: "283",
    text: "Transportation Infrastructure",
    sector: "Industrials",
  },
  {
    value: "66",
    numCompanies: "124",
    text: "Water Utilities",
    sector: "Utilities",
  },
  {
    value: "62",
    numCompanies: "123",
    text: "Wireless Telecommunication Services",
    sector: "Communication Services",
  },
]

const sectorArr = [
  {
    text: "Communication Services",
    value: "Communication Services",
    // value: "29##69##70##61##62",
    numCompanies: 2558,
  },
  {
    text: "Consumer Discretionary",
    value: "Consumer Discretionary",
    // value: "26##27##22##24##33##28##30##31##25##32##23",
    numCompanies: 6975,
  },
  {
    text: "Consumer Staples",
    value: "Consumer Staples",
    // value: "36##34##39##35##38##37",
    numCompanies: 3437,
  },
  {
    text: "Energy",
    value: "Energy",
    // value: "2##1",
    numCompanies: 2053,
  },
  {
    text: "Financials",
    value: "Financials",
    // value: "50##53##46##51##48##49##47##68",
    numCompanies: 18608,
  },
  {
    text: "Healthcare",
    value: "Healthcare",
    // value: "44##43##40##41##42##45",
    numCompanies: 4776,
  },
  {
    text: "Industrials",
    value: "Industrials",
    // value: "13##10##15##11##14##16##9##20##21##8##17##12##19##18",
    numCompanies: 8876,
  },
  {
    text: "Information Technology",
    value: "Information Technology",
    // value: "59##56##55##60##57##58",
    numCompanies: 6547,
  },
  {
    text: "Materials",
    value: "Materials",
    // value: "6##3##4##5##7",
    numCompanies: 6835,
  },
  {
    text: "Real Estate",
    value: "Real Estate",
    // value: "52",
    numCompanies: 876,
  },
  {
    text: "Utilities",
    value: "Utilities",
    // value: "67##63##64##66##65",
    numCompanies: 1056,
  },
]

const industryIdToSector = {
  1: "Energy",
  2: "Energy",
  3: "Materials",
  4: "Materials",
  5: "Materials",
  6: "Materials",
  7: "Materials",
  8: "Industrials",
  9: "Industrials",
  10: "Industrials",
  11: "Industrials",
  12: "Industrials",
  13: "Industrials",
  14: "Industrials",
  15: "Industrials",
  16: "Industrials",
  17: "Industrials",
  18: "Industrials",
  19: "Industrials",
  20: "Industrials",
  21: "Industrials",
  22: "Consumer Discretionary",
  23: "Consumer Discretionary",
  24: "Consumer Discretionary",
  25: "Consumer Discretionary",
  26: "Consumer Discretionary",
  27: "Consumer Discretionary",
  28: "Consumer Discretionary",
  29: "Communication Services",
  30: "Consumer Discretionary",
  31: "Consumer Discretionary",
  32: "Consumer Discretionary",
  33: "Consumer Discretionary",
  34: "Consumer Staples",
  35: "Consumer Staples",
  36: "Consumer Staples",
  37: "Consumer Staples",
  38: "Consumer Staples",
  39: "Consumer Staples",
  40: "Healthcare",
  41: "Healthcare",
  42: "Healthcare",
  43: "Healthcare",
  44: "Healthcare",
  45: "Healthcare",
  46: "Financials",
  47: "Financials",
  48: "Financials",
  49: "Financials",
  50: "Financials",
  51: "Financials",
  52: "Real Estate",
  53: "Financials",
  55: "Information Technology",
  56: "Information Technology",
  57: "Information Technology",
  58: "Information Technology",
  59: "Information Technology",
  60: "Information Technology",
  61: "Communication Services",
  62: "Communication Services",
  63: "Utilities",
  64: "Utilities",
  65: "Utilities",
  66: "Utilities",
  67: "Utilities",
  68: "Financials",
  69: "Communication Services",
  70: "Communication Services",
}

const exchangeArr = [
  {
    text: "New York Stock Exchange (NYSE)",
    value: "NYSE",
    mc: 29247949.322785027,
    count: 2354,
  },
  {
    text: "Nasdaq Global Select (NasdaqGS)",
    value: "NasdaqGS",
    mc: 23372612.64576702,
    count: 1508,
  },
  {
    text: "The Stock Exchange of Hong Kong Ltd. (SEHK)",
    value: "SEHK",
    mc: 7132696.128046026,
    count: 2612,
  },
  {
    text: "The Tokyo Stock Exchange (TSE)",
    value: "TSE",
    mc: 6761507.8796460405,
    count: 3370,
  },
  {
    text: "Shanghai Stock Exchange (SHSE)",
    value: "SHSE",
    mc: 6042979.61715333,
    count: 2215,
  },
  {
    text: "NYSE Arca (ARCA)",
    value: "ARCA",
    mc: 5985829.132319999,
    count: 1563,
  },
  {
    text: "Shenzhen Stock Exchange (SZSE)",
    value: "SZSE",
    mc: 5651574.524062437,
    count: 2656,
  },
  {
    text: "London Stock Exchange (LSE)",
    value: "LSE",
    mc: 3721303.677869039,
    count: 1640,
  },
  {
    text: "Euronext Paris (ENXTPA)",
    value: "ENXTPA",
    mc: 3381201.651497225,
    count: 1023,
  },
  {
    text: "XETRA Trading Platform (XTRA)",
    value: "XTRA",
    mc: 2691778.473315184,
    count: 867,
  },
  {
    text: "The Toronto Stock Exchange (TSX)",
    value: "TSX",
    mc: 2595214.2261831015,
    count: 1668,
  },
  {
    text: "Saudi Arabian Stock Exchange (SASE)",
    value: "SASE",
    mc: 2573764.5916412952,
    count: 213,
  },
  {
    text: "SIX Swiss Exchange (SWX)",
    value: "SWX",
    mc: 2169744.723573684,
    count: 429,
  },
  {
    text: "Australian Securities Exchange (ASX)",
    value: "ASX",
    mc: 1946819.0842486348,
    count: 2183,
  },
  {
    text: "Taiwan Stock Exchange (TWSE)",
    value: "TWSE",
    mc: 1944319.7190512111,
    count: 1075,
  },
  {
    text: "Korea Stock Exchange (KOSE)",
    value: "KOSE",
    mc: 1927001.3645966446,
    count: 1305,
  },
  {
    text: "National Stock Exchange of India (NSEI)",
    value: "NSEI",
    mc: 1616777.4078788534,
    count: 634,
  },
  {
    text: "Mumbai Stock Exchange (BSE)",
    value: "BSE",
    mc: 1521796.4650269777,
    count: 4506,
  },
  {
    text: "OMX Nordic Exchange Stockholm (OM)",
    value: "OM",
    mc: 1371967.8324431342,
    count: 730,
  },
  {
    text: "Euronext Amsterdam (ENXTAM)",
    value: "ENXTAM",
    mc: 1352182.7638774777,
    count: 160,
  },
  {
    text: "Nasdaq Global Market (NasdaqGM)",
    value: "NasdaqGM",
    mc: 1172835.8646929981,
    count: 967,
  },
  {
    text: "Bolsa de Valores de Sao Paulo (BOVESPA)",
    value: "BOVESPA",
    mc: 1140676.3204486375,
    count: 630,
  },
  {
    text: "Borsa Italiana (BIT)",
    value: "BIT",
    mc: 785571.6832926964,
    count: 454,
  },
  {
    text: "Bolsas y Mercados Espanoles (BME)",
    value: "BME",
    mc: 755621.3323328209,
    count: 2600,
  },
  {
    text: "OMX Nordic Exchange Copenhagen (CPSE)",
    value: "CPSE",
    mc: 734545.0355804446,
    count: 598,
  },
  {
    text: "The Stock Exchange of Thailand (SET)",
    value: "SET",
    mc: 555025.2872085931,
    count: 826,
  },
  {
    text: "Singapore Exchange (SGX)",
    value: "SGX",
    mc: 522077.9425992523,
    count: 443,
  },
  {
    text: "Micex - Rts (MISX)",
    value: "MISX",
    mc: 514660.9058484892,
    count: 204,
  },
  {
    text: "Indonesia Stock Exchange (IDX)",
    value: "IDX",
    mc: 488706.7682394195,
    count: 778,
  },
  {
    text: "Nasdaq Capital Market (NasdaqCM)",
    value: "NasdaqCM",
    mc: 458072.1176139996,
    count: 1264,
  },
  {
    text: "The Johannesburg Securities Exchange (JSE)",
    value: "JSE",
    mc: 454398.0889040584,
    count: 343,
  },
  {
    text: "Bolsa Mexicana de Valores (BMV)",
    value: "BMV",
    mc: 446210.5401539787,
    count: 164,
  },
  {
    text: "Euronext Brussels (ENXTBR)",
    value: "ENXTBR",
    mc: 445021.2436761749,
    count: 168,
  },
  {
    text: "Oslo Bors (OB)",
    value: "OB",
    mc: 410365.6667290047,
    count: 332,
  },
  {
    text: "Bursa Malaysia (KLSE)",
    value: "KLSE",
    mc: 404154.6467860115,
    count: 972,
  },
  {
    text: "KOSDAQ (KOSDAQ)",
    value: "KOSDAQ",
    mc: 378290.78459748504,
    count: 1507,
  },
  {
    text: "OMX Nordic Exchange Helsinki (HLSE)",
    value: "HLSE",
    mc: 368579.8683086754,
    count: 165,
  },
  {
    text: "Pink Sheets LLC (OTCPK)",
    value: "OTCPK",
    mc: 344800.14587400074,
    count: 5421,
  },
  {
    text: "Abu Dhabi Securities Exchange (ADX)",
    value: "ADX",
    mc: 342378.86846015614,
    count: 70,
  },
  {
    text: "Philippines Stock Exchange (PSE)",
    value: "PSE",
    mc: 261665.50609777612,
    count: 257,
  },
  {
    text: "The Tel-Aviv Stock Exchange (TASE)",
    value: "TASE",
    mc: 241223.78901544894,
    count: 891,
  },
  {
    text: "Taipei Exchange (TPEX)",
    value: "TPEX",
    mc: 230041.5918471218,
    count: 1182,
  },
  {
    text: "Ho Chi Minh Stock Exchange (HOSE)",
    value: "HOSE",
    mc: 208188.20933408776,
    count: 393,
  },
  {
    text: "Warsaw Stock Exchange (WSE)",
    value: "WSE",
    mc: 204320.28351889725,
    count: 779,
  },
  {
    text: "Better Alternative Trading System (BATS)",
    value: "BATS",
    mc: 203985.02317299994,
    count: 504,
  },
  {
    text: "London Stock Exchange AIM Market (AIM)",
    value: "AIM",
    mc: 195447.5474004246,
    count: 804,
  },
  {
    text: "Borsa Istanbul (BIST) (IBSE)",
    value: "IBSE",
    mc: 185902.86032062458,
    count: 433,
  },
  {
    text: "Irish Stock Exchange (ISE)",
    value: "ISE",
    mc: 173706.4837519233,
    count: 31,
  },
  {
    text: "Doha Securities Market (DSM)",
    value: "DSM",
    mc: 171441.11762620154,
    count: 51,
  },
  {
    text: "Santiago Stock Exchange (SNSE)",
    value: "SNSE",
    mc: 168043.48918303658,
    count: 480,
  },
  {
    text: "Wiener Boerse AG (WBAG)",
    value: "WBAG",
    mc: 162693.12241211988,
    count: 79,
  },
  {
    text: "New Zealand Stock Exchange (NZSE)",
    value: "NZSE",
    mc: 130337.8062266273,
    count: 165,
  },
  {
    text: "Kuwait Stock Exchange (KWSE)",
    value: "KWSE",
    mc: 114166.63344852798,
    count: 164,
  },
  {
    text: "Deutsche Boerse AG (DB)",
    value: "DB",
    mc: 105488.81836193638,
    count: 358,
  },
  {
    text: "NYSE MKT LLC (NYSEAM)",
    value: "NYSEAM",
    mc: 100474.79695599987,
    count: 216,
  },
  {
    text: "Bolsa de Valores de Colombia (BVC)",
    value: "BVC",
    mc: 96796.84613048607,
    count: 67,
  },
  {
    text: "Dubai Financial Market (DFM)",
    value: "DFM",
    mc: 95665.62200865752,
    count: 49,
  },
  {
    text: "TSE JASDAQ (JASDAQ)",
    value: "JASDAQ",
    mc: 92482.47179373834,
    count: 702,
  },
  {
    text: "Euronext Lisbon (ENXTLS)",
    value: "ENXTLS",
    mc: 85156.45507278969,
    count: 52,
  },
  {
    text: "TSX Venture Exchange (TSXV)",
    value: "TSXV",
    mc: 77997.02094028797,
    count: 1847,
  },
  {
    text: "Casablanca Stock Exchange (CBSE)",
    value: "CBSE",
    mc: 69530.62763296088,
    count: 73,
  },
  {
    text: "Bolsa de Valores de Lima (BVL)",
    value: "BVL",
    mc: 65444.61144290873,
    count: 124,
  },
  {
    text: "Canadian National Stock Exchange (CNSX)",
    value: "CNSX",
    mc: 61000.38993857848,
    count: 593,
  },
  {
    text: "The Athens Stock Exchange (ATSE)",
    value: "ATSE",
    mc: 57967.85182388448,
    count: 156,
  },
  {
    text: "Dhaka Stock Exchange Ltd. (DSE)",
    value: "DSE",
    mc: 54231.44470793166,
    count: 364,
  },
  {
    text: "Pakistan Stock Exchange Limited (KASE)",
    value: "KASE",
    mc: 51989.58857017255,
    count: 463,
  },
  {
    text: "Cairo and Alexandria Stock Exchange (CASE)",
    value: "CASE",
    mc: 41399.593460153446,
    count: 215,
  },
  {
    text: "Nigerian Stock Exchange (NGSE)",
    value: "NGSE",
    mc: 41399.05538735316,
    count: 160,
  },
  {
    text: "Buenos Aires Stock Exchange (BASE)",
    value: "BASE",
    mc: 34892.39587652098,
    count: 81,
  },
  {
    text: "The Stock Exchange Prague Co. Ltd. (SEP)",
    value: "SEP",
    mc: 30839.431809321955,
    count: 30,
  },
  {
    text: "Bucharest Stock Exchange (BVB)",
    value: "BVB",
    mc: 30262.64184282559,
    count: 97,
  },
  {
    text: "Budapest Stock Exchange (BUSE)",
    value: "BUSE",
    mc: 28094.035552418085,
    count: 68,
  },
  {
    text: "Hamburg Stock Exchange (HMSE)",
    value: "HMSE",
    mc: 27475.59579240147,
    count: 53,
  },
  {
    text: "Nairobi Stock Exchange (NASE)",
    value: "NASE",
    mc: 25333.45547519666,
    count: 59,
  },
  {
    text: "Bahrain Stock Exchange (BAX)",
    value: "BAX",
    mc: 24984.57371978793,
    count: 38,
  },
  {
    text: "Amman Stock Exchange (ASE)",
    value: "ASE",
    mc: 23445.71728170404,
    count: 168,
  },
  {
    text: "Zagreb Stock Exchange (ZGSE)",
    value: "ZGSE",
    mc: 20582.267640973,
    count: 95,
  },
  {
    text: "Muscat Securities Market (MSM)",
    value: "MSM",
    mc: 18352.55690022334,
    count: 109,
  },
  {
    text: "Colombo Stock Exchange (COSE)",
    value: "COSE",
    mc: 17622.248930608024,
    count: 277,
  },
  {
    text: "OMX Nordic Exchange Iceland (ICSE)",
    value: "ICSE",
    mc: 17308.1778123838,
    count: 27,
  },
  {
    text: "Bulgaria Stock Exchange (BUL)",
    value: "BUL",
    mc: 16993.786050832052,
    count: 204,
  },
  {
    text: "Caracas Stock Exchange (CCSE)",
    value: "CCSE",
    mc: 16760.33218573497,
    count: 32,
  },
  {
    text: "Trinidad & Tobago Stock Exchange (TTSE)",
    value: "TTSE",
    mc: 15638.532857016939,
    count: 25,
  },
  {
    text: "Hanoi Stock Exchange (HNX)",
    value: "HNX",
    mc: 15508.992380427242,
    count: 358,
  },
  {
    text: "Kazakhstan Stock Exchange (KAS)",
    value: "KAS",
    mc: 14214.352911450698,
    count: 37,
  },
  {
    text: "Unquoted - United Arab Emirates (UNQ-ARE)",
    value: "UNQ-ARE",
    mc: 13470.9,
    count: 1,
  },
  {
    text: "The Stock Exchange of Mauritius Ltd. (MUSE)",
    value: "MUSE",
    mc: 12257.655047951686,
    count: 92,
  },
  {
    text: "The Jamaica Stock Exchange (JMSE)",
    value: "JMSE",
    mc: 11757.579997033057,
    count: 87,
  },
  {
    text: "Ljubljana Stock Exchange (LJSE)",
    value: "LJSE",
    mc: 11026.982128062487,
    count: 71,
  },
  {
    text: "Bourse de Luxembourg (BDL)",
    value: "BDL",
    mc: 10830.255927507002,
    count: 39,
  },
  {
    text: "Nordic Growth Market (NGM)",
    value: "NGM",
    mc: 10171.63270854557,
    count: 239,
  },
  {
    text: "Singapore Exchange Catalist Market (Catalist)",
    value: "Catalist",
    mc: 9572.369282794618,
    count: 214,
  },
  {
    text: "Belgrade Stock Exchange (BELEX)",
    value: "BELEX",
    mc: 8590.711364732922,
    count: 143,
  },
  {
    text: "Bourse de Beyrouth (BDB)",
    value: "BDB",
    mc: 8372.361444999999,
    count: 10,
  },
  {
    text: "Bourse Regionale des Valeurs Mobilieres (BRVM)",
    value: "BRVM",
    mc: 7991.386697500899,
    count: 44,
  },
  {
    text: "Bourse des Valeurs Mobilieres (BVMT)",
    value: "BVMT",
    mc: 7953.369407548114,
    count: 81,
  },
  {
    text: "Guayaquil Stock Exchange (GYSE)",
    value: "GYSE",
    mc: 6792.672713000001,
    count: 24,
  },
  {
    text: "Bratislava Stock Exchange (BSSE)",
    value: "BSSE",
    mc: 6479.967222156469,
    count: 24,
  },
  {
    text: "OMX Nordic Exchange Vilnius (NSEL)",
    value: "NSEL",
    mc: 6180.356914427745,
    count: 29,
  },
  {
    text: "Nagoya Stock Exchange (NSE)",
    value: "NSE",
    mc: 5764.429800567417,
    count: 63,
  },
  {
    text: "Korea New Exchange (XKON)",
    value: "XKON",
    mc: 5725.206600717328,
    count: 138,
  },
  {
    text: "PFTS Ukraine Stock Exchange (UKR)",
    value: "UKR",
    mc: 5349.063968331694,
    count: 31,
  },
  {
    text: "Malta Stock Exchange (MTSE)",
    value: "MTSE",
    mc: 5198.852821848265,
    count: 27,
  },
  {
    text: "Norway OTC (OTCNO)",
    value: "OTCNO",
    mc: 4750.103035585402,
    count: 44,
  },
  {
    text: "Ghana Stock Exchange (GHSE)",
    value: "GHSE",
    mc: 4536.685646669033,
    count: 30,
  },
  {
    text: "OMX Nordic Exchange Tallinn (TLSE)",
    value: "TLSE",
    mc: 4199.348387974907,
    count: 23,
  },
  {
    text: "Palestine Securities Exchange (PLSE)",
    value: "PLSE",
    mc: 4125.839810410778,
    count: 47,
  },
  {
    text: "Tanzania Stock Exchange (DAR)",
    value: "DAR",
    mc: 4066.32956269903,
    count: 22,
  },
  {
    text: "Cyprus Stock Exchange (CSE)",
    value: "CSE",
    mc: 3583.608937152326,
    count: 79,
  },
  {
    text: "Boerse Muenchen (MUN)",
    value: "MUN",
    mc: 3428.181138596284,
    count: 57,
  },
  {
    text: "Botswana Share Market (BSM)",
    value: "BSM",
    mc: 3117.178294682722,
    count: 23,
  },
  {
    text: "Malawi Stock Exchange (MAL)",
    value: "MAL",
    mc: 2341.3253504201575,
    count: 15,
  },
  {
    text: "ICAP Securities & Derivatives Exchange (ISDX) (OFEX)",
    value: "OFEX",
    mc: 2187.756513364835,
    count: 68,
  },
  {
    text: "Namibian Stock Exchange (NMSE)",
    value: "NMSE",
    mc: 2101.6935445109693,
    count: 16,
  },
  {
    text: "Dusseldorf Stock Exchange (DUSE)",
    value: "DUSE",
    mc: 1991.6389253166049,
    count: 44,
  },
  {
    text: "Sapporo Stock Exchange (SPSE)",
    value: "SPSE",
    mc: 1796.0470054377477,
    count: 16,
  },
  {
    text: "Fukuoka Stock Exchange (FKSE)",
    value: "FKSE",
    mc: 1764.26568862074,
    count: 26,
  },
  {
    text: "Berne Stock Exchange (BRSE)",
    value: "BRSE",
    mc: 1754.6623108214503,
    count: 15,
  },
  {
    text: "National Stock Exchange of Australia (NSX)",
    value: "NSX",
    mc: 1642.982582840901,
    count: 43,
  },
  {
    text: "Bolsa de Madrid (BDM)",
    value: "BDM",
    mc: 1550.54441709078,
    count: 12,
  },
  {
    text: "Uganda Stock Exchange (UGSE)",
    value: "UGSE",
    mc: 1230.2106992250317,
    count: 9,
  },
  {
    text: "OMX Nordic Exchange Riga (RISE)",
    value: "RISE",
    mc: 964.9264469167949,
    count: 12,
  },
  {
    text: "The Bermuda Stock Exchange (BER)",
    value: "BER",
    mc: 956.1676287677748,
    count: 17,
  },
  {
    text: "Lusaka Stock Exchange (LUSE)",
    value: "LUSE",
    mc: 888.9957989443589,
    count: 21,
  },
  {
    text: "Boerse-Stuttgart (BST)",
    value: "BST",
    mc: 868.9649224760327,
    count: 23,
  },
  {
    text: "OTC  - Switzerland (OTCCH)",
    value: "OTCCH",
    mc: 852.465150686729,
    count: 2,
  },
  {
    text: "Borsa Italiana ETFplus (BITE)",
    value: "BITE",
    mc: 794.5271416735708,
    count: 11,
  },
  {
    text: "NASDAQ Dubai Limited (DIFX)",
    value: "DIFX",
    mc: 726.3659472115925,
    count: 4,
  },
  {
    text: "Moscow Exchange Classica (RTS)",
    value: "RTS",
    mc: 712.436359,
    count: 11,
  },
  {
    text: "Unquoted - Vietnam (UNQ-VNM)",
    value: "UNQ-VNM",
    mc: 576.8629810624346,
    count: 123,
  },
  {
    text: "Aequitas Neo Exchange (NEOE)",
    value: "NEOE",
    mc: 500.6529614048344,
    count: 25,
  },
  {
    text: "Zimbabwe Stock Exchange (ZMSE)",
    value: "ZMSE",
    mc: 403.88592952248064,
    count: 9,
  },
  {
    text: "Chi-X Australia (CHIA)",
    value: "CHIA",
    mc: 122.7951073378712,
    count: 2,
  },
  // {
  //   text: "Nile Stock Exchange (NILX)",
  //   value: "NILX",
  //   mc: 68.61576550467831,
  //   count: 1,
  // },
  // {
  //   text: "Mutual Funds (MutualFund)",
  //   value: "MutualFund",
  //   mc: 32.467426,
  //   count: 3,
  // },
  // {
  //   text: 'Other "Grey Market" OTC (OTCUS)',
  //   value: "OTCUS",
  //   mc: 1.512268,
  //   count: 1,
  // },
  // {
  //   text: "Macedonian Stock Exchange (SKOPJE)",
  //   value: "SKOPJE",
  //   mc: 0,
  //   count: 5,
  // },
  // {
  //   text: "Aktietorget (XSAT)",
  //   value: "XSAT",
  //   mc: 0,
  //   count: 1,
  // },
  // {
  //   text: "Ukrainian Exchange (UX)",
  //   value: "UX",
  //   mc: 0,
  //   count: 3,
  // },
  // {
  //   text: "BATS 'Chi-X Europe' (BATS-CHIXE)",
  //   value: "BATS-CHIXE",
  //   mc: 0,
  //   count: 6,
  // },
  // {
  //   text: "Obsolete - formerly Neuchatel (null)",
  //   value: null,
  //   mc: 0,
  //   count: 1,
  // },
  // {
  //   text: "Fund - Germany (F-DEU)",
  //   value: "F-DEU",
  //   mc: 0,
  //   count: 2,
  // },
  // {
  //   text: "China Foreign Exchange Trade System (XCFE)",
  //   value: "XCFE",
  //   mc: 0,
  //   count: 4,
  // },
  // {
  //   text: "OTC Bulletin Board (OTCBB)",
  //   value: "OTCBB",
  //   mc: 0,
  //   count: 2,
  // },
  // {
  //   text: "Bangkok-Alien Market Stock Exchange (THAMSE)",
  //   value: "THAMSE",
  //   mc: 0,
  //   count: 1,
  // },
  // {
  //   text: "Xtrakter - formerly ICMA (International Capital Market Association) (Xtrakter)",
  //   value: "Xtrakter",
  //   mc: 0,
  //   count: 1,
  // },
  // {
  //   text: "Hannover Stock Exchange (HNSE)",
  //   value: "HNSE",
  //   mc: 0,
  //   count: 1,
  // },
]

const countryArr = [
  {
    text: "Afghanistan",
    value: "1",
    abbr: "AFG",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "1",
  },
  {
    text: "Anguilla",
    value: "7",
    abbr: "AIA",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "3",
  },
  {
    text: "Argentina",
    value: "10",
    abbr: "ARG",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "86",
  },
  {
    text: "Australia",
    value: "13",
    abbr: "AUS",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "2125",
  },
  {
    text: "Austria",
    value: "14",
    abbr: "AUT",
    dataTypeGroup: "Europe",
    numcompanies: "76",
  },
  {
    text: "Bahamas",
    value: "16",
    abbr: "BHS",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "7",
  },
  {
    text: "Bahrain",
    value: "17",
    abbr: "BHR",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "40",
  },
  {
    text: "Bangladesh",
    value: "18",
    abbr: "BGD",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "361",
  },
  {
    text: "Barbados",
    value: "19",
    abbr: "BRB",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "6",
  },
  {
    text: "Belgium",
    value: "21",
    abbr: "BEL",
    dataTypeGroup: "Europe",
    numcompanies: "182",
  },
  {
    text: "Belize",
    value: "22",
    abbr: "BLZ",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "2",
  },
  {
    text: "Benin",
    value: "23",
    abbr: "BEN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Bermuda",
    value: "24",
    abbr: "BMU",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "101",
  },
  {
    text: "Bosnia-Herzegovina",
    value: "27",
    abbr: "BIH",
    dataTypeGroup: "Europe",
    numcompanies: "1",
  },
  {
    text: "Botswana",
    value: "28",
    abbr: "BWA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "22",
  },
  {
    text: "Brazil",
    value: "30",
    abbr: "BRA",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "610",
  },
  {
    text: "Bulgaria",
    value: "32",
    abbr: "BGR",
    dataTypeGroup: "Europe",
    numcompanies: "219",
  },
  {
    text: "Burkina Faso",
    value: "33",
    abbr: "BFA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "3",
  },
  {
    text: "Cambodia",
    value: "35",
    abbr: "KHM",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "1",
  },
  {
    text: "Canada",
    value: "37",
    abbr: "CAN",
    dataTypeGroup: "United States and Canada",
    numcompanies: "4358",
  },
  {
    text: "Cayman Islands",
    value: "39",
    abbr: "CYM",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "105",
  },
  {
    text: "Chile",
    value: "42",
    abbr: "CHL",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "470",
  },
  {
    text: "China",
    value: "43",
    abbr: "CHN",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "6067",
  },
  {
    text: "Colombia",
    value: "46",
    abbr: "COL",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "70",
  },
  {
    text: "Costa Rica",
    value: "50",
    abbr: "CRI",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "1",
  },
  {
    text: "Croatia",
    value: "51",
    abbr: "HRV",
    dataTypeGroup: "Europe",
    numcompanies: "95",
  },
  {
    text: "Cyprus",
    value: "53",
    abbr: "CYP",
    dataTypeGroup: "Europe",
    numcompanies: "101",
  },
  {
    text: "Czech Republic",
    value: "54",
    abbr: "CZE",
    dataTypeGroup: "Europe",
    numcompanies: "33",
  },
  {
    text: "Denmark",
    value: "55",
    abbr: "DNK",
    dataTypeGroup: "Europe",
    numcompanies: "561",
  },
  {
    text: "Dominican Republic",
    value: "58",
    abbr: "DOM",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "5",
  },
  {
    text: "Ecuador",
    value: "60",
    abbr: "ECU",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "24",
  },
  {
    text: "Egypt",
    value: "61",
    abbr: "EGY",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "216",
  },
  {
    text: "Estonia",
    value: "64",
    abbr: "EST",
    dataTypeGroup: "Europe",
    numcompanies: "25",
  },
  {
    text: "Falkland Islands",
    value: "66",
    abbr: "FLK",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "1",
  },
  {
    text: "Finland",
    value: "69",
    abbr: "FIN",
    dataTypeGroup: "Europe",
    numcompanies: "173",
  },
  {
    text: "France",
    value: "71",
    abbr: "FRA",
    dataTypeGroup: "Europe",
    numcompanies: "1073",
  },
  {
    text: "French Guiana",
    value: "72",
    abbr: "GUF",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "1",
  },
  {
    text: "Gabon",
    value: "73",
    abbr: "GAB",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Gambia",
    value: "74",
    abbr: "GMB",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Georgia",
    value: "75",
    abbr: "GEO",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "2",
  },
  {
    text: "Germany",
    value: "76",
    abbr: "DEU",
    dataTypeGroup: "Europe",
    numcompanies: "1052",
  },
  {
    text: "Ghana",
    value: "77",
    abbr: "GHA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "30",
  },
  {
    text: "Gibraltar",
    value: "78",
    abbr: "GIB",
    dataTypeGroup: "Europe",
    numcompanies: "5",
  },
  {
    text: "Greece",
    value: "79",
    abbr: "GRC",
    dataTypeGroup: "Europe",
    numcompanies: "189",
  },
  {
    text: "Greenland",
    value: "80",
    abbr: "GRL",
    dataTypeGroup: "Europe",
    numcompanies: "1",
  },
  {
    text: "Hong Kong",
    value: "90",
    abbr: "HKG",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "1793",
  },
  {
    text: "Hungary",
    value: "91",
    abbr: "HUN",
    dataTypeGroup: "Europe",
    numcompanies: "72",
  },
  {
    text: "Iceland",
    value: "92",
    abbr: "ISL",
    dataTypeGroup: "Europe",
    numcompanies: "27",
  },
  {
    text: "India",
    value: "93",
    abbr: "IND",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "5158",
  },
  {
    text: "Indonesia",
    value: "94",
    abbr: "IDN",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "774",
  },
  {
    text: "Ireland",
    value: "97",
    abbr: "IRL",
    dataTypeGroup: "Europe",
    numcompanies: "310",
  },
  {
    text: "Israel",
    value: "98",
    abbr: "ISR",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "982",
  },
  {
    text: "Italy",
    value: "99",
    abbr: "ITA",
    dataTypeGroup: "Europe",
    numcompanies: "397",
  },
  {
    text: "Ivory Coast",
    value: "100",
    abbr: "CIV",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "36",
  },
  {
    text: "Jamaica",
    value: "101",
    abbr: "JAM",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "77",
  },
  {
    text: "Japan",
    value: "102",
    abbr: "JPN",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "4166",
  },
  {
    text: "Jordan",
    value: "103",
    abbr: "JOR",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "171",
  },
  {
    text: "Kazakhstan",
    value: "104",
    abbr: "KAZ",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "42",
  },
  {
    text: "Kenya",
    value: "105",
    abbr: "KEN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "57",
  },
  {
    text: "Kuwait",
    value: "107",
    abbr: "KWT",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "159",
  },
  {
    text: "Kyrgyzstan",
    value: "108",
    abbr: "KGZ",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "1",
  },
  {
    text: "Latvia",
    value: "110",
    abbr: "LVA",
    dataTypeGroup: "Europe",
    numcompanies: "18",
  },
  {
    text: "Lebanon",
    value: "111",
    abbr: "LBN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "10",
  },
  {
    text: "Liechtenstein",
    value: "115",
    abbr: "LIE",
    dataTypeGroup: "Europe",
    numcompanies: "2",
  },
  {
    text: "Lithuania",
    value: "116",
    abbr: "LTU",
    dataTypeGroup: "Europe",
    numcompanies: "32",
  },
  {
    text: "Luxembourg",
    value: "117",
    abbr: "LUX",
    dataTypeGroup: "Europe",
    numcompanies: "229",
  },
  {
    text: "Macau",
    value: "118",
    abbr: "MAC",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "19",
  },
  {
    text: "Macedonia",
    value: "119",
    abbr: "MKD",
    dataTypeGroup: "Europe",
    numcompanies: "6",
  },
  {
    text: "Malawi",
    value: "121",
    abbr: "MWI",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "15",
  },
  {
    text: "Malaysia",
    value: "122",
    abbr: "MYS",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "1044",
  },
  {
    text: "Mali",
    value: "124",
    abbr: "MLI",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Malta",
    value: "125",
    abbr: "MLT",
    dataTypeGroup: "Europe",
    numcompanies: "45",
  },
  {
    text: "Mauritius",
    value: "129",
    abbr: "MUS",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "102",
  },
  {
    text: "Mexico",
    value: "131",
    abbr: "MEX",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "174",
  },
  {
    text: "Monaco",
    value: "134",
    abbr: "MCO",
    dataTypeGroup: "Europe",
    numcompanies: "10",
  },
  {
    text: "Mongolia",
    value: "135",
    abbr: "MNG",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "4",
  },
  {
    text: "Morocco",
    value: "137",
    abbr: "MAR",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "72",
  },
  {
    text: "Mozambique",
    value: "138",
    abbr: "MOZ",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Myanmar",
    value: "139",
    abbr: "MMR",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "2",
  },
  {
    text: "Namibia",
    value: "140",
    abbr: "NAM",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "14",
  },
  {
    text: "Netherlands",
    value: "143",
    abbr: "NLD",
    dataTypeGroup: "Europe",
    numcompanies: "168",
  },
  {
    text: "Netherlands Antilles",
    value: "144",
    abbr: "ANT",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "4",
  },
  {
    text: "New Zealand",
    value: "147",
    abbr: "NZL",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "178",
  },
  {
    text: "Niger",
    value: "149",
    abbr: "NER",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Nigeria",
    value: "150",
    abbr: "NGA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "162",
  },
  {
    text: "Norway",
    value: "154",
    abbr: "NOR",
    dataTypeGroup: "Europe",
    numcompanies: "325",
  },
  {
    text: "Oman",
    value: "155",
    abbr: "OMN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "110",
  },
  {
    text: "Pakistan",
    value: "156",
    abbr: "PAK",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "462",
  },
  {
    text: "Panama",
    value: "158",
    abbr: "PAN",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "3",
  },
  {
    text: "Papua New Guinea",
    value: "159",
    abbr: "PNG",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "5",
  },
  {
    text: "Peru",
    value: "161",
    abbr: "PER",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "131",
  },
  {
    text: "Philippines",
    value: "162",
    abbr: "PHL",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "263",
  },
  {
    text: "Poland",
    value: "164",
    abbr: "POL",
    dataTypeGroup: "Europe",
    numcompanies: "755",
  },
  {
    text: "Portugal",
    value: "166",
    abbr: "PRT",
    dataTypeGroup: "Europe",
    numcompanies: "51",
  },
  {
    text: "Qatar",
    value: "168",
    abbr: "QAT",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "51",
  },
  {
    text: "Reunion",
    value: "169",
    abbr: "REU",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Romania",
    value: "170",
    abbr: "ROU",
    dataTypeGroup: "Europe",
    numcompanies: "92",
  },
  {
    text: "Russia",
    value: "171",
    abbr: "RUS",
    dataTypeGroup: "Europe",
    numcompanies: "234",
  },
  {
    text: "Rwanda",
    value: "172",
    abbr: "RWA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "1",
  },
  {
    text: "Saint Lucia",
    value: "174",
    abbr: "LCA",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "4",
  },
  {
    text: "Saudi Arabia",
    value: "178",
    abbr: "SAU",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "211",
  },
  {
    text: "Senegal",
    value: "179",
    abbr: "SEN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "4",
  },
  {
    text: "Singapore",
    value: "182",
    abbr: "SGP",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "688",
  },
  {
    text: "Slovakia",
    value: "183",
    abbr: "SVK",
    dataTypeGroup: "Europe",
    numcompanies: "25",
  },
  {
    text: "Slovenia",
    value: "184",
    abbr: "SVN",
    dataTypeGroup: "Europe",
    numcompanies: "72",
  },
  {
    text: "South Africa",
    value: "187",
    abbr: "ZAF",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "338",
  },
  {
    text: "South Korea",
    value: "188",
    abbr: "KOR",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "2909",
  },
  {
    text: "Spain",
    value: "189",
    abbr: "ESP",
    dataTypeGroup: "Europe",
    numcompanies: "939",
  },
  {
    text: "Sri Lanka",
    value: "190",
    abbr: "LKA",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "277",
  },
  {
    text: "Sudan",
    value: "191",
    abbr: "SDN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "2",
  },
  {
    text: "Suriname",
    value: "192",
    abbr: "SUR",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "1",
  },
  {
    text: "Sweden",
    value: "194",
    abbr: "SWE",
    dataTypeGroup: "Europe",
    numcompanies: "904",
  },
  {
    text: "Switzerland",
    value: "195",
    abbr: "CHE",
    dataTypeGroup: "Europe",
    numcompanies: "357",
  },
  {
    text: "Taiwan",
    value: "198",
    abbr: "TWN",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "2192",
  },
  {
    text: "Tanzania",
    value: "199",
    abbr: "TZA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "24",
  },
  {
    text: "Thailand",
    value: "200",
    abbr: "THA",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "830",
  },
  {
    text: "Togo",
    value: "201",
    abbr: "TGO",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "2",
  },
  {
    text: "Trinidad & Tobago",
    value: "204",
    abbr: "TTO",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "24",
  },
  {
    text: "Tunisia",
    value: "205",
    abbr: "TUN",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "81",
  },
  {
    text: "Turkey",
    value: "206",
    abbr: "TUR",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "421",
  },
  {
    text: "Uganda",
    value: "209",
    abbr: "UGA",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "9",
  },
  {
    text: "Ukraine",
    value: "210",
    abbr: "UKR",
    dataTypeGroup: "Europe",
    numcompanies: "46",
  },
  {
    text: "United Arab Emirates",
    value: "211",
    abbr: "ARE",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "135",
  },
  {
    text: "United Kingdom",
    value: "212",
    abbr: "GBR",
    dataTypeGroup: "Europe",
    numcompanies: "2209",
  },
  {
    text: "United States",
    value: "213",
    abbr: "USA",
    dataTypeGroup: "United States and Canada",
    numcompanies: "12370",
  },
  {
    text: "Uruguay",
    value: "214",
    abbr: "URY",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "2",
  },
  {
    text: "Venezuela",
    value: "218",
    abbr: "VEN",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "31",
  },
  {
    text: "Vietnam",
    value: "219",
    abbr: "VNM",
    dataTypeGroup: "Asia / Pacific",
    numcompanies: "885",
  },
  {
    text: "British Virgin Islands",
    value: "220",
    abbr: "VGB",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "34",
  },
  {
    text: "Zambia",
    value: "227",
    abbr: "ZMB",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "21",
  },
  {
    text: "Zimbabwe",
    value: "228",
    abbr: "ZWE",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "9",
  },
  {
    text: "Turks & Caicos Islands",
    value: "235",
    abbr: "TCA",
    dataTypeGroup: "Latin America and Caribbean",
    numcompanies: "1",
  },
  {
    text: "Serbia",
    value: "236",
    abbr: "SRB",
    dataTypeGroup: "Europe",
    numcompanies: "143",
  },
  {
    text: "Montenegro",
    value: "238",
    abbr: "MNE",
    dataTypeGroup: "Europe",
    numcompanies: "1",
  },
  {
    text: "Isle of Man",
    value: "239",
    abbr: "IMN",
    dataTypeGroup: "Europe",
    numcompanies: "18",
  },
  {
    text: "Palestinian Authority",
    value: "240",
    abbr: "PSE",
    dataTypeGroup: "Africa / Middle East",
    numcompanies: "44",
  },
  {
    text: "Guernsey",
    value: "244",
    abbr: "GGY",
    dataTypeGroup: "Europe",
    numcompanies: "50",
  },
  {
    text: "Jersey",
    value: "245",
    abbr: "JEY",
    dataTypeGroup: "Europe",
    numcompanies: "60",
  },
]

const regionArr = [
  {
    text: "United States and Canada",
    value: "United States and Canada",
    numCompanies: 16728,
  },
  {
    text: "Asia / Pacific",
    value: "Asia / Pacific",
    numCompanies: 30249,
  },
  {
    text: "Europe",
    value: "Europe",
    numCompanies: 11383,
  },
  {
    text: "Africa / Middle East (Other)",
    value: "Africa / Middle East",
    numCompanies: 3561,
  },
  {
    text: "Latin America and Caribbean (Other)",
    value: "Latin America and Caribbean",
    numCompanies: 1978,
  },
]

const emptyArr = []

const allScreenerItems = emptyArr.concat(
  financialsLineitems,
  forwardMultiplesItems,
  trailingMultiplesItems,
  estimateLineitems,
  companyAttributes
)

const allScreenMap = allScreenerItems.reduce((acc, obj) => {
  acc[obj.value] = obj
  return acc
}, {})

const screenerItemAccumulator = (acc, obj, I18nFn) => {
  if (obj.value) {
    if (obj.text) {
      obj.text = I18nFn(obj.text)
    }
    if (obj.abbr) {
      obj.abbr = I18nFn(obj.abbr)
    }
    acc[obj.value] = obj
  }
  return acc
}

export {
  financialsLineitems,
  forwardMultiplesItems,
  trailingMultiplesItems,
  estimateLineitems,
  companyAttributes,
  valueTypes,
  valueTypeMetaObj,
  periodsForValueTypes,
  unitOptions,
  industryArr,
  regionArr,
  allScreenerItems,
  screenerItemAccumulator,
  allScreenMap,
  priceChangeTimePeriods,
  countryArr,
  exchangeArr,
  sectorArr,
  industryIdToSector,
}

export default estimateLineitems // get this error removed!
