import { first, last } from "lodash"
import { USE_WORKER_FMP_FINANCIALS } from "../feature-toggle"
import currencies from "~/utils/fmp/currencies"
import { findClosestDateIndex, setDateRange } from "~/utils/ciq"
import { defaultUsdObj } from "~/utils/constants/objects"
import { getJWT } from "~/utils/tools"

const devStage = "dev"
const stage = process.env.LAMBDA_STAGE
const dev = devStage === stage
const CDK_URL = process.env.API_URL

const state = () => ({
  loading: {},
  financials: {},
  financialsError: null,
  financialsChart: {},
  financialsChartTickers: {},
  ticker: {},
  quoteCurrencyToggle: 0,
  quoteCurrencies: [],
  estimatesCurrencyToggle: 0,
  estimatesPeriodToggle: 0,
  multiplesCurrencies: [],
  multiplesCurrencyToggle: 0,
  financialsCurrencies: [],
  financialsCurrencyToggle: 0,
})

const mutations = {
  setToggle(state, payload) {
    state[payload.type] = payload.value
  },
  setTicker(state, payload) {
    state.ticker = payload
  },
  setCurrencies(state, payload) {
    state[payload.type] = payload.value
  },
  setFmpFetch(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.loading = { ...state.loading, ...newObj }
  },
  setFmpError(state, payload) {
    state[payload.error] = payload.status
  },
  setFmpStateKey(state, { stateKey, value }) {
    state[stateKey] = value
  },
  resetFMP(state) {
    state.loading = {}
    state.financials = {}
    state.ticker = {}
    state.financialschart = {}
    state.financialsChartTickers = {}
    state.quoteCurrencyToggle = 0
    state.quoteCurrencies = []
    state.estimatesCurrencyToggle = 0
    state.estimatesPeriodToggle = 0
    state.multiplesCurrencies = []
    state.multiplesCurrencyToggle = 0
    state.financialsCurrencies = []
    state.financialsCurrencyToggle = 0
  },
  setFinancials(state, { period, data }) {
    const previousState = state.financials
    const newState = {}
    newState[period] = data
    state.financials = { ...previousState, ...newState }
  },
  // addToChart is redundent with ciq
  addToChart(state, payload) {
    const chartType = payload.chartType // 'financialsChart', 'estimatesChart' or 'multiplesChart'
    const period = payload.period
    const previousState = state[chartType] // previous state of rows to add to chart
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const newRow = {}
    newRow[payload.rowId] = payload.row
    newState[period] = { ...previousPeriodState, ...newRow }

    state[chartType] = { ...previousState, ...newState }
  },
  // removeFromChart redundent with ciq
  removeFromChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period
    const previousState = state[chartType]
    const previousPeriodState = state[chartType][period] || {}

    const newState = {}
    const reduceObj = {}

    const rowRemoved = Object.keys(previousPeriodState)
      .filter((f) => f !== payload.rowId)
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, reduceObj)

    newState[period] = rowRemoved

    state[chartType] = { ...previousState, ...newState }
  },
  // toggleSeriesTye redundent with ciq
  toggleSeriesType(state, payload) {
    try {
      const chartType = payload.chartType
      const period = payload.period
      const rowId = payload.rowId
      const previousState = state[chartType]
      const previousPeriodState = state[chartType][period] || {}

      const newState = {}
      const newRow = {}

      newRow[rowId] = previousPeriodState[rowId]
      newRow[rowId].seriesType = payload.seriesType
      newState[period] = { ...previousPeriodState, ...newRow }

      state[chartType] = { ...previousState, ...newState }
    } catch (error) {
      console.error("error toggling chart seriesType: ", error)
    }
  },
  // clearChart redundent with ciq
  clearChart(state, payload) {
    const chartType = payload.chartType // 'financials' or 'estimates'
    const period = payload.period
    const previousState = state[chartType]

    const newState = {}
    newState[period] = {}
    state[chartType] = { ...previousState, ...newState }
  },
  // // addTickerToTableChart redundent with ciq
  addTickerToTableChart(state, payload) {
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const period = payload.period
    const previousState = state[chartType] || {} // {p1: {}, p2: {}} whole state for all periods

    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {} // need to replace the state {period: {companyid: {data}}}
    const newPeriodState = {}

    if (chartType === "financialsChartTickers") {
      newPeriodState[payload.data.companyid] = payload.data
    } else {
      newPeriodState[payload.data.tradingitemid] = payload.data
    }

    newState[period] = { ...previousPeriodState, ...newPeriodState }

    state[chartType] = { ...previousState, ...newState } // newState: {periodType: {}}
  },
  // removeTickerFromTableChart redundent with ciq
  removeTickerFromTableChart(state, payload) {
    // TODO: verify this works
    const chartType = `${payload.chartType}Tickers` // 'financialsChart' 'estimatesChart', 'multiplesChart' or 'quotesChartTickers
    const previousState = state[chartType]
    const period = payload.period
    const previousPeriodState = previousState[period] || {} // state for just this period

    const newState = {}

    // this function different from removeFromChart because the
    // period doesn't matter for the chart
    newState[period] = Object.keys(previousPeriodState)
      .filter((f) => {
        if (chartType === "financialsChartTickers") {
          return f !== payload.cid
        } else {
          return f !== payload.tid
        }
      })
      .reduce((acc, rowId) => {
        acc[rowId] = previousPeriodState[rowId] || {}
        return acc
      }, {})

    state[chartType] = { ...previousState, ...newState } // just replace the existing key
  },
}

const actions = {
  // payload is the result of the search bar
  initialLoad({ dispatch, state, commit }, payload) {
    // perform initial load actions here
    if (state.ticker.companyid !== payload.companyid) {
      commit("resetFMP")
    }

    commit("setTicker", payload)
    dispatch("fetchFinancials", payload)
  },
  async fetchFinancials(
    { state, rootState, commit },
    {
      fetchKey = "financials",
      errorKey = "financialsError",
      companyid,
      companyname,
      exchangename,
      exchangesymbol,
      isocode,
      primarytickersymbol,
      tickersymbol,
      tradingitemid,
      period = "a",
      selectedDateRange,
    }
  ) {
    commit("setFmpError", {
      status: null,
      error: errorKey,
    })
    commit("setFmpFetch", { key: fetchKey, status: true })
    const maxDate = ["q"].includes(period)

    const fmpDataAvailable =
      Object.hasOwn(rootState.ciq.ticker, "companyid") &&
      rootState.ciq.ticker.companyid === companyid &&
      Object.hasOwn(state.financials, period)

    if (fmpDataAvailable) {
      // TODO: check if the stored financials value is hot/cold
      const dates = state.financials[period].dateArr

      if (selectedDateRange?.length > 0) {
        const finDataAvailableDates = [...dates].reverse()

        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], finDataAvailableDates),
              findClosestDateIndex(
                selectedDateRange[1],
                finDataAvailableDates,
                maxDate
              ),
            ],
          },
          { root: true }
        )
      } else {
        commit("setToggle", {
          type: `financialsDateRange`,
          value: setDateRange(dates, rootState.config.allPeriodsDefault),
        })
      }

      commit("setFmpFetch", { key: fetchKey, status: false })

      return
    }

    try {
      const jwt = await getJWT(this.$Amplify)

      const requestBody = {
        auth: jwt,
        period,
        companyid,
        companyname,
        exchangename,
        exchangesymbol,
        isocode,
        primarytickersymbol: primarytickersymbol || tickersymbol,
        tradingitemid,
        test: !dev,
      }

      const { data: rawFmpFinancials } = await this.$axios.post(
        `${CDK_URL}/altFin`,
        requestBody
      )

      let finData

      if (USE_WORKER_FMP_FINANCIALS) {
        const Comlink = await import("comlink")

        const fmpWorker = new Worker("../fmp_worker.js", {
          name: "fmpWorker",
        })
        const fmpWorkerObj = Comlink.wrap(fmpWorker)

        await fmpWorkerObj.processFmpFinancials(rawFmpFinancials)

        finData = await fmpWorkerObj.financials
      } else {
        const { processFmpFinancials } = await import("~/utils/fmp")
        finData = processFmpFinancials(rawFmpFinancials)
      }

      const dates = finData.dateArr

      const currenciesArr = dates.reduce((acc, date) => {
        if (acc.filter((i) => i?.isocode === date?.isocode).length === 0) {
          acc.push(date)
        }
        return acc
      }, [])

      const firstItemISO = first(currenciesArr).isocode || "USD"
      const lastItemISO = last(currenciesArr).isocode || "USD"

      const firstCurrency = {
        code: firstItemISO,
        name: currencies[firstItemISO],
      }

      const lastCurrency = {
        code: lastItemISO,
        name: currencies[lastItemISO],
      }

      if (currenciesArr.length > 1) {
        // FIXME: reported currency changes
        lastCurrency.code = "MIXED"
        lastCurrency.name = `Mixed ${lastCurrency.name} & ${firstCurrency.name}`
      }

      if (lastCurrency.code === "USD") {
        commit("setCurrencies", {
          type: "financialsCurrencies",
          value: [lastCurrency],
        })
      } else {
        commit("setCurrencies", {
          type: "financialsCurrencies",
          value: [lastCurrency, defaultUsdObj],
        })
      }

      commit("setFinancials", {
        period,
        data: finData,
      })

      // Dates are reversed in the api response
      const finDataAvailableDates = [...finData.dateArr].reverse()

      if (selectedDateRange?.length > 0 && finDataAvailableDates.length > 0) {
        commit(
          "ciq/setToggle",
          {
            type: `financialsDateRange`,
            value: [
              findClosestDateIndex(selectedDateRange[0], finDataAvailableDates),
              findClosestDateIndex(
                selectedDateRange[1],
                finDataAvailableDates,
                maxDate
              ),
            ],
          },
          { root: true }
        )
      }
    } catch (error) {
      if (dev) {
        commit("resetFMP")
        console.error(JSON.stringify(error))
      }
      commit("setFmpError", {
        status: {
          error,
          loc: "fetching financials",
        },
        error: errorKey,
      })
    } finally {
      commit("setFmpFetch", { key: fetchKey, status: false })
    }
  },
}

export { state, mutations, actions }
