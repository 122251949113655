// LINK static/ciq_worker.js:669
const calcEstDerivative = (metricKey, resData, dates, type) => {
  // It is assumed that before running calcEstDerivative resData[dataitem] is not undefined / has been checked
  const dataitem = metricKey
  if (resData[dataitem][type]) {
    const data = Object.assign({}, resData[dataitem]) // make copy of data so you're not messing up reference
    // data equal to the object that holds all of the dataitemvalues by
    // estimateperiodid.
    const idxMap = {
      1: 0, // annual, need 1 data point for calculation
      2: 3, // quarterly, need 4 datapoints for calc
      10: 1, // semiannual need 2 datapoints for calc
      12: 0, // semiannual need 2 datapoints for calc
      7: 0, // calendar year, need 1 datapoint for calc
    }
    const derivatives = dates.reduce((acc, currentPeriod, idx, arr) => {
      // iterate through date array
      const periodTypeId = currentPeriod.periodtypeid
      if (idx <= idxMap[periodTypeId]) {
        // no YoY derivative for this period
        return acc
      }
      const lastPeriod = arr[idx - 1 - idxMap[periodTypeId]]
      const lastPeriodType = lastPeriod.isEstimate ? type : "actual"
      const currentPeriodType = currentPeriod.isEstimate ? type : "actual" // "actual" or type

      const currentData = data[currentPeriodType]
        ? data[currentPeriodType][currentPeriod.value]
        : undefined

      const lastData = data[lastPeriodType]
        ? data[lastPeriodType][lastPeriod.value]
        : undefined

      if (currentData && lastData) {
        const currentValue = parseFloat(currentData.dataitemvalue)
        const lastValue = parseFloat(lastData.dataitemvalue)
        const calc = (currentValue - lastValue) / lastValue
        const isGain = currentValue > lastValue
        const rightSign = isGain ? Math.abs(calc) : calc

        const currentExRate = currentData.priceclose
          ? currentData.priceclose
          : false
        const lastExRate = lastData.priceclose ? lastData.priceclose : false

        let exRightSign = 0
        if (currentExRate && lastExRate) {
          const exCalc =
            (currentValue / currentExRate - lastValue / lastExRate) /
            (lastValue / lastExRate)
          exRightSign = isGain ? Math.abs(exCalc) : exCalc
        } else {
          exRightSign = false
        }

        // might want calc.toString() for consistency across items?
        const newObj = Object.assign(
          {},
          currentData,
          { dataitemvalue: rightSign },
          { fxdataitemvalue: exRightSign },
          { lastValue: lastData }
        )
        if (currentData.unauth || lastData.unauth) {
          newObj.unauth = true
        }

        acc[currentPeriod.value] = newObj
      }

      return acc
    }, {}) // {financialperiodid: (thisFinperiod / lastFinperiod) - 1}
    return derivatives
  } else {
    return {
      error:
        "Shouldn't be inside of calcEstDerivative, resData[dataitem][type]",
    }
  }
}

// LINK static/ciq_worker.js:620
const calcEstDiv = (numeratorKey, denominatorKey, resData, dates, type) => {
  // const formula = lineitem.formula // right now I'm just going to assume its $1 div $2
  // FIXME: if the formula ever changes then you've got to figure out how to handle
  const numeratorData = Object.assign({}, resData[numeratorKey])
  const denominatorData = Object.assign({}, resData[denominatorKey])

  // alright I'm confused.
  // what is numeratorData? {dataitem: {consensusid: {actual: {}, mean: {}, median: {}}}}

  // what are you trying to create?
  // and object that I can merge onto the lineitem that when I have the estimateperiodid
  // and the type of data to display for a row I can pull the exact value that I want
  // but this is a calculation, so I want the calculation
  // does it need to go the other way?
  // {mean: {estimateperiodid: {dataobj}}, actual: {estimateperiodid: {dataObj}} }
  // I think it might in order for spreading over the existing object
  // otherwise I am spreading over the properties of estimateperiodid and erasing whatever was previously stored on there
  // this object contains for the type "actual", "median", "mean"
  // there is a problem though there is already data on

  const division = dates.reduce((acc, date) => {
    const period = date.value
    const currentPeriodType = date.isEstimate ? type : "actual" // "actual" or type
    const numDataObj = numeratorData[currentPeriodType]
      ? numeratorData[currentPeriodType][period]
      : undefined
    const denomDataObj = denominatorData[currentPeriodType]
      ? denominatorData[currentPeriodType][period]
      : undefined
    if (numDataObj && denomDataObj) {
      const calc =
        parseFloat(numDataObj.dataitemvalue) /
        parseFloat(denomDataObj.dataitemvalue)
      const newObj = Object.assign(
        {},
        numDataObj,
        { dataitemvalue: calc },
        { denomObj: denomDataObj }
      )
      if (numDataObj.unauth || denomDataObj.unauth) {
        newObj.unauth = true
      }
      acc[period] = newObj
    }
    return acc
  }, {})
  return division
}

export { calcEstDerivative, calcEstDiv }
