import { isoParse, utcParse } from "d3-time-format"
import debounce from "lodash/debounce"
import { USE_CDK_USER_DATA, USE_CDK_TRKD } from "../feature-toggle"

const parseTime = utcParse("%Y-%m-%dT%H:%M:%SZ")
const stage = process.env.LAMBDA_STAGE
const dev = stage === "dev"

const SLS_CIQ_URL = process.env.NEW_CIQ_LAMBDA_URL
const SLS_SCREEN_URL = process.env.SCREEN_LAMBDA_URL
const SLS_TRKD_URL = process.env.TRKD_LAMBDA_URL
const CDK_URL = process.env.API_URL

const restrictResponse = process.env.USE_TIERS === "true"

const state = () => ({
  token: {
    w: {
      Expiration: "2019-04-03T02:11:28.2282761Z",
      Token:
        "9E81274AD20632D79B96C4FC1ABD48597DA2D7136410730AD6ED32475ACBC4AF1A9D80A98810C8C7B5CFC7844E7C703A738EB98B1D2D29AC7813CC26B5FB9DC3DB30EF2EBA1B86347C7456B095132346B9120FBCB9D278E5B5DFE19DD178A53E",
    },
    n: {
      Expiration: "2019-04-03T02:11:28.2282761Z",
      Token:
        "9E81274AD20632D79B96C4FC1ABD48597DA2D7136410730AD6ED32475ACBC4AF1A9D80A98810C8C7B5CFC7844E7C703A738EB98B1D2D29AC7813CC26B5FB9DC3DB30EF2EBA1B86347C7456B095132346B9120FBCB9D278E5B5DFE19DD178A53E",
    },
  },
  loading: {},
  error: {},
  order: ["test"],
  active: null,
  feedLimit: 10,
  feedResultsObj: {
    test: {
      order: [],
      headlines: {},
      meta: {},
      lang: [],
      news: {},
      u: "someDate",
    },
  },
  feedDataObj: {
    test: { title: "Test Feed store/trkd", topics: [{ code: "DEAL1" }] },
  },
  tokenError: null,
  tickerError: null,
  filingsError: null,
  shareholdersError: null,
  insidersError: null,
  investorHoldingsError: null,
  headlineError: null,
  companyHeadlineError: null,
  newsStoryError: null,
  sigDevsError: null,
  fetchingHeadlines: false,
  fetchingCompanyHeadlines: false,
  fetchingNewsStory: false,
  fetchingSigDevs: false,
  fetchingInvestorHoldings: false,
  fetchingFilings: false,
  fetchingShareholders: false,
  updatingShareholders: false,
  fetchingInsiders: false,
  updatingInsiders: false,
  fetchingToken: false,
  fetchingTicker: false,
  ticker: {},
  tickerShareholders: {},
  tickerInsiders: {},
  tickerFilings: null,
  investorHoldings: {},
  investorHoldingsUnauth: null,
  sigDevs: [],
  marketHeadlines: {},
  marketNews: {},
  companyNews: {},
  companyHeadlines: {},
  recentNewsFetch: 0,
  marketOlder: true,
  companyOlder: true,
})

const updateBackend = async (that, id, obj, type) => {
  try {
    const user = await that.$Amplify.Auth.currentAuthenticatedUser()
    const jwt = user.signInUserSession.idToken.jwtToken

    const body = {
      auth: jwt,
      key: "n",
      id,
    }

    body[type] = obj

    const UPDATE_URL = USE_CDK_USER_DATA
      ? `${CDK_URL}/u`
      : `${SLS_SCREEN_URL}/${stage}/u`

    const { data } = await that.$axios.post(UPDATE_URL, body)

    return data
  } catch (error) {
    //
    if (dev) {
      console.error(`Error updating newsFeed ${id} type ${type}: `, error)
    }
  }
}

const getJwt = async (that, commit) => {
  const user = await that.$Amplify.Auth.currentAuthenticatedUser()
  commit(
    "updateCurrentUser",
    { user, from: "trkd fetchRecentHeadlines" },
    { root: true }
  )
  return user.signInUserSession.idToken.jwtToken
}

const debounceUpdateObj = debounce(updateBackend, 1500)
const debounceUpdateMeta = debounce(updateBackend, 3000)

const mutations = {
  updateResultsObj(state, { res, id }) {
    const newObj = {}
    newObj[id] = res
    state.feedResultsObj = { ...state.feedResultsObj, ...newObj }
  },
  setStateObject(state, { stateKey, newObj }) {
    state[stateKey] = { ...newObj }
  },
  setFeedMeta(state, { order, active }) {
    if (order) {
      state.order = order
    }
    if (active) {
      state.active = active
    }
  },
  setFeedFetch(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.loading = { ...state.loading, ...newObj }
  },
  setFeedError(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.error = { ...state.error, ...newObj }
  },
  setFeedObj(state, { storeKey, key, value }) {
    const newObj = {}
    newObj[key] = value
    state[storeKey] = { ...state[storeKey], ...newObj }
  },
  addFeed(state, payload) {
    state.feedDataObj = { ...state.feedDataObj, ...payload }
  },
  deleteFeed(state, { id }) {
    state.order = state.order.filter((f) => f !== id)
    state.active = state.order[0]
    delete state.feedDataObj[id]
    state.feedDataObj = { ...state.feedDataObj }
  },
  updateFeed(state, { id, dataKey, value }) {
    // this updates an object on the store that is 2 levels
    // deep - 1st level preset screenDataObj second level
    // variable dataKey
    const feedToModify = state.feedDataObj[id]
    feedToModify[dataKey] = value
    // take screenToModify, update here
    const newFeedObj = {}
    newFeedObj[id] = feedToModify

    state.feedDataObj = { ...state.feedDataObj, ...newFeedObj }
    debounceUpdateObj(this, id, state.feedDataObj[id], "d")
  },
  resetTRKD(state) {
    // I'll get back to these.. also need to figure out when this mutation is called
    state.sigDevs = []
    state.companyHeadlines = {}
    // state.error = {}
    state.tickerError = null
    state.fetchingToken = false
    state.fetchingTicker = false
    state.ticker = {}
    state.tickerShareholders = {}
    state.tickerFilings = null
    state.filingsError = null
    state.shareholdersError = null
    state.investorHoldingsError = null // this might not belong here
    state.fetchingInvestorHoldings = false // this might not belong here
    state.fetchingFilings = false
    state.fetchingShareholders = false
    state.updatingShareholders = false
    state.headlineError = null // this might not belong here
    state.companyHeadlineError = null
    state.newsStoryError = null
    state.sigDevsError = null
    state.fetchingHeadlines = false // this might not belong here
    state.fetchingCompanyHeadlines = false
    state.fetchingNewsStory = false
    state.fetchingSigDevs = false
    state.companyNews = {}
    state.companyHeadlines = {}
    state.recentNewsFetch = 0
    state.companyOlder = true
  },
  resetNews(state, payload) {
    const newsType = payload.type
    if (newsType === "marketNews" || newsType === "companyNews") {
      // idk are you going to be independently reseting the object containing news Details?
      // yeah I think you would. focus back to insider transactions
    } else {
      const newObj = {}
      newObj.order = []
      state[newsType] = newObj
    }
  },
  toggleNewsBool(state, payload) {
    // {type: fetchOlderNews, value: true}
    state[payload.type] = payload.value
  },
  toggleNewsReadStatus(state, payload) {
    try {
      // {type: marketHeadlines, id: "urn"}
      const type = payload.type + "Headlines"
      const id = payload.id
      if (state[type]?.headlines?.[id]) {
        // this really seems like overkill
        // const newObj = {}
        // newObj[id] = state[type][id]
        // newObj[id].new = false
        // state[type] = { ...state[type], ...newObj }
        state[type].headlines[id].new = false
      }
    } catch (error) {
      console.error("error toggleNewsReadStatus with payload", payload)
      console.error("error from toggleNewsReadStatus: ", error)
    }
  },
  toggleHeadlineReadStatus(state, { id, hid, status }) {
    try {
      state.feedResultsObj[id].headlines[hid].new = status
    } catch (error) {
      if (dev) {
        console.error("error toggleNewsReadStatus with payload", { id, hid })
        console.error("error from toggleNewsReadStatus: ", error)
      }
    }
  },
  setNewsStories(state, { news = {}, id, fetchType }) {
    const newObj = {}
    const resObj = state.feedResultsObj[id] || {}
    const oldState = resObj.news || {}

    if (fetchType === "older") {
      // oldState takes precedence
      news = { ...news, ...oldState }
    } else {
      news = { ...oldState, ...news }
    }
    resObj.news = news
    newObj[id] = resObj
    state.feedResultsObj = { ...state.feedResultsObj, ...newObj }
  },
  setNewsHeadlines(state, { fetchType, id, meta = {}, lang, headlines = {} }) {
    // Setting the Headlines
    const newObj = {}
    const oldState = state.feedResultsObj[id] || {}
    const oldHeadlines = oldState.headlines
    const oldNews = oldState.news || {}

    let order = Object.keys(headlines)
    let u = new Date()

    if (fetchType === "recent") {
      // replace existing headlines (its more important)
      if (oldHeadlines) {
        const newHeadlines = Object.keys(headlines).reduce((acc, id) => {
          const headline = headlines[id]
          const oldObj = acc[id]
          if (oldObj) {
            // check if the new headline is actually different than the old headline
            if (oldObj.TN !== headline.TN) {
              acc[id] = headline
            }
          } else {
            acc[id] = headline
          }
          return acc
        }, oldHeadlines)

        headlines = newHeadlines
      }

      order = Object.keys(headlines)
        .map((id) => headlines[id])
        .sort((a, b) => b.RT.valueOf() - a.RT.valueOf())
        .map((hl) => hl.ID.split(":").pop())

      // replace meta.Older with payload.meta.Older
      // because this query doesn't have the oldest
      if (oldState.meta?.Older) {
        meta.Older = oldState.meta.Older
      }
    }

    if (fetchType === "older") {
      // replace existing headlines (its more important)
      if (oldHeadlines) {
        const newHeadlines = Object.keys(headlines).reduce((acc, id) => {
          const headline = headlines[id]
          const oldObj = acc[id]
          if (oldObj) {
            // don't add an older headline of the same story
          } else {
            // this older headline isn't on the page yet, add
            acc[id] = headline
          }
          return acc
        }, oldHeadlines)

        headlines = newHeadlines
      }

      order = Object.keys(headlines)
        .map((id) => headlines[id])
        .sort((a, b) => b.RT.valueOf() - a.RT.valueOf())
        .map((hl) => hl.ID.split(":").pop())

      // replace meta.Newer with payload.meta.newer
      // because this query doesn't have the most recent
      if (oldState.meta?.Newer) {
        meta.Newer = oldState.meta.Newer
      }
      u = oldState.u
    }

    newObj[id] = {
      order,
      headlines,
      meta,
      lang,
      news: oldNews,
      u,
    }
    state.feedResultsObj = { ...state.feedResultsObj, ...newObj }
  },
  setNews(state, payload) {
    const fetchType = payload.fetchType
    const lang = payload.lang
    const newsType = payload.type
    if (newsType === "marketNews" || newsType === "companyNews") {
      // Setting the actual news stories
      let news = payload.news || {}
      const oldState = state[newsType]

      if (fetchType === "older") {
        // spread ontop of existing
        news = { ...news, ...oldState }
      }
      news = { ...oldState, ...news }
      state[newsType] = news
    } else {
      // Setting the Headlines
      const oldState = state[newsType]
      const oldHeadlines = oldState.headlines

      const meta = payload.meta
      let headlines = payload.news
      let order = Object.keys(payload.news)
      let u = new Date()

      if (fetchType === "recent") {
        // replace existing headlines (its more important)
        if (oldHeadlines) {
          const newHeadlines = Object.keys(headlines).reduce((acc, id) => {
            const headline = headlines[id]
            const oldObj = acc[id]
            if (oldObj) {
              // check if the new headline is actually different than the old headline
              if (oldObj.TN !== headline.TN) {
                acc[id] = headline
              }
            } else {
              acc[id] = headline
            }
            return acc
          }, oldHeadlines)

          headlines = newHeadlines
        }

        order = Object.keys(headlines)
          .map((id) => headlines[id])
          .sort((a, b) => b.RT.valueOf() - a.RT.valueOf())
          .map((hl) => hl.ID.split(":").pop())

        // replace meta.Older with payload.meta.Older
        // because this query doesn't have the oldest
        if (oldState.meta?.Older) {
          meta.Older = oldState.meta.Older
        }
      }

      if (fetchType === "older") {
        // replace existing headlines (its more important)
        if (oldHeadlines) {
          const newHeadlines = Object.keys(headlines).reduce((acc, id) => {
            const headline = headlines[id]
            const oldObj = acc[id]
            if (oldObj) {
              // don't add an older headline of the same story
            } else {
              // this older headline isn't on the page yet, add
              acc[id] = headline
            }
            return acc
          }, oldHeadlines)

          headlines = newHeadlines
        }

        order = Object.keys(headlines)
          .map((id) => headlines[id])
          .sort((a, b) => b.RT.valueOf() - a.RT.valueOf())
          .map((hl) => hl.ID.split(":").pop())

        // replace meta.Newer with payload.meta.newer
        // because this query doesn't have the most recent
        if (oldState.meta?.Newer) {
          meta.Newer = oldState.meta.Newer
        }
        u = oldState.u
      }

      state[newsType] = {
        order,
        headlines,
        meta,
        lang,
        u,
      }
    }
  },
  setSigDevs(state, payload) {
    if (payload) {
      state.sigDevs = payload
    } else {
      state.sigDevsError = true
    }
  },
  clearTRKDError(state, payload) {
    state[payload.errorType] = null
  },
  setTRKDError(state, payload) {
    state[payload.errorType] = payload.error
  },
  setTRKDFetch(state, payload) {
    state[payload.fetch] = payload.status
  },
  setTicker(state, payload) {
    state.ticker = payload
  },
  setTickerFilings(state, payload) {
    // make set of available formTypes
    state.tickerFilings = payload
  },
  setTickerOwnership(state, payload) {
    const ownershipKey = payload.key || "tickerShareholders"
    state[ownershipKey] = payload.data
  },
  updateTickerShareholders(state, payload) {
    // spread the new information onto the object
    const oldState = Object.assign({}, state.tickerShareholders)
    try {
      const prevShareholders = oldState.Shareholders.Investor // this is an array
      // FIXME: Need to check if all this shit exists?
      const newShareholders = payload.Shareholders.Investor
      oldState.Shareholders.Investor = prevShareholders.concat(newShareholders)
      state.tickerShareholders = oldState
    } catch (error) {
      console.error("Error updating tickerShareholder state: ", error)
      // set an error on the tickerShareholder thing?
      state.shareholdersError = {
        error,
        loc: "Error updating tickerShareholder state",
      }
    }
  },
  clearInvestorHoldings(state) {
    // Should I have some other object here?
    state.investorHoldingsError = null
    state.investorHoldings = {}
    state.investorHoldingsUnauth = null
  },
  setInvestorHoldings(state, payload) {
    state.investorHoldings = payload
  },
  unauthInvestorHoldings(state, { details = {}, numHoldings = 0 }) {
    state.investorHoldingsUnauth = {
      num: numHoldings,
      name: details.Name,
      type: details.HoldingType,
    }
  },
  setToken(state, payload) {
    state.token = payload.token
    if (dev) {
      console.log("trkd token updated")
    }
  },
}

const actions = {
  // changeSymbol is actually initTRKD Change or something like that
  // changeSymbol is called whenever the application is switching to a new companyId/tradingitemid
  async changeSymbol({ dispatch, commit }, payload) {
    // payload.symbol is the ciq.ticker.tickersymbol
    // 0. Reset any previous information
    commit("resetTRKD")
    try {
      // 1. check that you have a valid token, if you don't get one
      // 2. Get the trkd mapping for this ticker
      // const updated = await checkToken(rootState, state, commit, this)
      const user = await this.$Amplify.Auth.currentAuthenticatedUser()
      const jwt = user.signInUserSession.idToken.jwtToken
      payload.jwt = jwt
      commit(
        "updateCurrentUser",
        { user, from: "trkd change Symbol" },
        { root: true }
      )
      // await checkToken(rootState, state, commit, dispatch, this)
      // TODO: only get ricFromCidTid if we don't already have a RIC!
      const { data } = await dispatch("ricFromCidTid", payload)
      // console.log("ricFromCidTid data: ", data)
      if (data.data) {
        // sometimes this comes with this weird data object
        // within the data object, so we need to extract it
        const { data: payloadData, ...rest } = data.data
        const newPayload = {
          ...payloadData,
          ...rest,
        }
        commit("setTicker", newPayload)
        // 3. start pulling the ownership information by RIC
        // TODO: UNCOMMENT THIS
        dispatch("fetchShareholderReport", newPayload)
        // 4. start pulling the filings list by OAPermID
        dispatch("fetchFilings", newPayload)
        // 5. get the sigdevs by RIC
        dispatch("fetchSigDevs", newPayload)
        // 6. get company headlines by RIC
        dispatch("fetchRecentHeadlines", {
          fetchType: "init",
          gridType: "company",
          jwt,
        })
      } else {
        throw new Error("TRKD couldn't match that symbol")
      }
    } catch (error) {
      console.error(error)
      commit("setTRKDError", {
        errorType: "tickerError",
        error: {
          error,
          loc: "Fetching Company Info then getting filings and ownership",
        },
      })
    }
  },

  ricFromCidTid({ state }, payload) {
    // Need to 'sanitize' the payload.symbol (ciq.tickersymbol) so it is compatible with TRKD search
    // assuming that payload always has a current jwt property
    if (payload.symbol) {
      const ticker = payload.symbol.split(".")[0]
      // What else can we pass along in the body that can help improve the TRKD search based on ciq data?
      const body = {
        auth: payload.jwt,
        ticker,
        ...payload,
        from: "ciq2ric",
        v: "v1",
      }

      const TRKDIDS_URL = USE_CDK_TRKD
        ? `${CDK_URL}/trkdids`
        : `${SLS_CIQ_URL}/${stage}/trkdids`

      return this.$axios.post(TRKDIDS_URL, body)
    }

    throw new Error("No Primary Ticker Symbol for trkd")
  },
  async cidTidFromRic({ commit }, payload) {
    try {
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/cidTidFromRic" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      if (payload.ric) {
        const body = {
          auth: jwt,
          ric: payload.ric,
          from: "ric2ciq",
        }

        const CIQIDS_URL = USE_CDK_TRKD
          ? `${CDK_URL}/ciqids`
          : `${SLS_TRKD_URL}/${stage}/ciqids`

        return this.$axios.post(CIQIDS_URL, body)
      }

      throw new Error("No trid to search for cid/tid")
    } catch (error) {
      console.error("Error fetching cidTidFromRic: ", error)
    }
  },
  async getDocument({ commit }, payload) {
    try {
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/getDocument" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      if (payload.id) {
        const body = {
          auth: jwt,
          id: payload.id,
        }

        const GET_DOC_URL = USE_CDK_TRKD
          ? `${CDK_URL}/getDefaultDoc`
          : `${SLS_TRKD_URL}/${stage}/getDefaultDoc`

        return await this.$axios.post(GET_DOC_URL, body)
      }

      throw new Error("Missing documentID to fetch")
    } catch (error) {
      console.error(`Error fetching documentID ${payload.id}: `, error)
    }
  },
  async fetchSigDevs({ state, commit }, payload) {
    // before fetchSigDevs is called resetTRKD is called
    // also assuming that trkd/setTicker has been called
    try {
      commit("setTRKDFetch", { fetch: "fetchingSigDevs", status: true })
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/fetchSigDevs" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      // Can also add body.end for pagination support
      const body = {
        id: state.ticker.PrimaryRIC || state.ticker.RIC,
        auth: jwt,
      }

      const SIGDEVS_URL = USE_CDK_TRKD
        ? `${CDK_URL}/sigdevs`
        : `${SLS_TRKD_URL}/${stage}/sigdevs`

      const data = await this.$axios.post(SIGDEVS_URL, body)

      commit("setSigDevs", data.data.data)
    } catch (error) {
      console.error("Error fetching sigdevs: ", error)
      commit("setTRKDError", {
        errorType: "sigDevsError",
        error: {
          error,
          loc: "Fetching Sig Devs",
        },
      })
    } finally {
      commit("setTRKDFetch", { fetch: "fetchingSigDevs", status: false })
    }
  },
  async fetchHeadlines(
    { state, commit, dispatch, rootState },
    { id, fetchType, topics }
  ) {
    // fetchType = ["older", "recent", "init"]
    const fetchKey = `${id}${fetchType}`
    try {
      commit("setFeedFetch", { key: fetchKey, status: true })
      commit("setFeedError", { key: id, status: null })
      const jwt = await getJwt(this, commit)
      const resObj = state.feedResultsObj[id] || {}
      const resMeta = resObj.meta || {}
      let lang = rootState.config.defaultNewsLang
      if (lang !== "EN" && rootState.config.includeEnglishNews) {
        // Make lang an array
        lang = [lang, "EN"]
      }

      const body = {
        auth: jwt,
        lang,
      }
      if (topics && topics.length > 0) {
        body.topics = topics.map((m) => m.value)
      }

      if (fetchType === "recent") {
        // fetch more recent news... by adding body.start of the correct format... should
        const start = resMeta.Newer
        if (start) {
          body.start = start
        }
      }
      if (fetchType === "older") {
        // fetch older news by adding body.end
        const end = resMeta.Older
        if (end) {
          body.end = end
        }
      }

      const HEADLINES_URL = USE_CDK_TRKD
        ? `${CDK_URL}/headlines`
        : `${SLS_TRKD_URL}/${stage}/headlines`

      const data = await this.$axios.post(HEADLINES_URL, body)

      const hl = data.data.data.HEADLINEML ? data.data.data.HEADLINEML.HL : []

      // TODO: where are the existing stories stored?
      const existingStories = resObj.headlines || {}
      const filteredHeadlines = hl.filter((hl) => {
        if (fetchType === "older") {
          // check if the ID exists, no need to fetch something you
          // already have when getting the history
          const id = hl.ID.split(":").pop()
          if (existingStories[id]) {
            return false
          }
        }
        return true
      })
      const newsStories = filteredHeadlines.map((headline) => headline.ID)

      if (newsStories && newsStories.length > 0) {
        // there are new newsStories for this group of headlines
        dispatch("fetchNewsStory", {
          ids: newsStories,
          id,
          fetchType,
          jwt,
        })

        const headlines = hl.reduce((acc, headline) => {
          headline.new = fetchType === "recent"
          const id = headline.ID.split(":").pop()
          headline.id = id
          headline.RT = isoParse(headline.RT)
          acc[id] = headline
          return acc
        }, {})

        const headlineMeta = data.data.data.Context // {Newer: "", Older: ""}

        commit("setNewsHeadlines", {
          id,
          headlines,
          meta: headlineMeta,
          fetchType,
          lang,
        })
      } else {
        // toggle fetch to false
        // commit("toggleNewsBool", { type: olderKey, value: false })
      }
    } catch (error) {
      commit("setFeedError", { key: id, status: error })
    } finally {
      commit("setFeedFetch", { key: fetchKey, status: false })
    }
  },
  async fetchRecentHeadlines(
    { commit, state, dispatch, rootState },
    { fetchType, gridType, jwt, topics }
  ) {
    if (!jwt) {
      jwt = await getJwt(this, commit)
    }
    const errorKey =
      gridType === "market" ? "headlineError" : "companyHeadlineError"
    const fetchKey =
      gridType === "market" ? "fetchingHeadlines" : "fetchingCompanyHeadlines"
    // FIXME: how should you handle the URL key going forward?
    const olderKey = gridType + "Older"
    try {
      commit("setTRKDFetch", { fetch: fetchKey, status: true })
      commit("setTRKDError", {
        errorType: errorKey,
        error: null,
      })
      let lang = rootState.config.defaultNewsLang
      if (lang !== "EN" && rootState.config.includeEnglishNews) {
        // Make lang an array
        lang = [lang, "EN"]
      }

      const body = {
        auth: jwt,
        lang,
      }

      if (fetchType === "init") {
        commit("toggleNewsBool", { type: olderKey, value: true })
      }

      if (gridType === "company") {
        body.id = state.ticker.PrimaryRIC || state.ticker.RIC
      } else if (topics && topics.length > 0) {
        body.topics = topics
      }

      if (fetchType === "recent") {
        // fetch more recent news... by adding body.start of the correct format... should
        const start = state[gridType + "Headlines"].meta.Newer
        if (start) {
          body.start = start
        }
      }

      if (fetchType === "older") {
        // fetch older news by adding body.end
        const end = state[gridType + "Headlines"].meta.Older
        if (end) {
          body.end = end
        }
      }

      // TODO: add guards on the serverside to protect against incorrect queries
      const HEADLINES_URL = USE_CDK_TRKD
        ? `${CDK_URL}/headlines`
        : `${SLS_TRKD_URL}/${stage}/headlines`

      const data = await this.$axios.post(HEADLINES_URL, body)

      const hl = data.data.data.HEADLINEML ? data.data.data.HEADLINEML.HL : []

      const existingStories = state[gridType + "Headlines"].headlines
      const filteredHeadlines = hl.filter((hl) => {
        if (fetchType === "older") {
          // check if the ID exists, no need to fetch something you
          // already have when getting the history
          const id = hl.ID.split(":").pop()
          if (existingStories[id]) {
            return false
          }
        }
        return true
      })

      if (dev) {
        const companies = filteredHeadlines.reduce((set, hl) => {
          const rics = hl.CO ? hl.CO.split(" ") : []
          rics.forEach((r) => set.add(r))
          return set
        }, new Set())

        const topicCount = {}

        console.log("RICS from news fetch: ", Array.from(companies))
        console.log(
          "TOPICS from news fetch: ",
          Object.entries(topicCount)
            .sort((a, b) => b[1] - a[1])
            .reduce((acc, i) => {
              const key = i[0]
              const value = i[1]
              acc[key] = value
              return acc
            }, {})
        )
      }

      const newsStories = filteredHeadlines.map((headline) => headline.ID)

      if (newsStories && newsStories.length > 0) {
        // perform the rest of the stuff.. you're defintiely coding too fast now
        dispatch("fetchNewsStory", {
          ids: newsStories,
          type: gridType + "News",
          fetchType,
          jwt,
        })

        // const hl = data.data.data.HEADLINEML ? data.data.data.HEADLINEML.HL : []

        const headlines = hl.reduce((acc, headline) => {
          headline.new = fetchType === "recent"
          const id = headline.ID.split(":").pop()
          headline.id = id
          headline.RT = isoParse(headline.RT)
          acc[id] = headline
          return acc
        }, {})

        const headlineMeta = data.data.data.Context // {Newer: "", Older: ""}

        commit("setNews", {
          type: gridType + "Headlines",
          news: headlines,
          meta: headlineMeta,
          fetchType,
          lang,
        })
      } else {
        commit("toggleNewsBool", { type: olderKey, value: false })
      }
    } catch (error) {
      console.error("Error Fetching Headlines: ", error)
      commit("setTRKDError", {
        errorType: errorKey,
        error: {
          error,
          loc: "Fetching Market Headlines",
        },
      })
    } finally {
      commit("setTRKDFetch", { fetch: fetchKey, status: false })
    }
  },
  async fetchNewsStory({ commit }, payload) {
    // payload is an array of newsId's
    if (payload.ids) {
      try {
        commit("setTRKDFetch", {
          fetch: "fetchingNewsStory",
          status: true,
        })
        let jwt = payload.jwt
        if (!jwt) {
          jwt = await getJwt(this, commit)
        }
        // Can also add body.end for pagination support
        const body = {
          id: payload.ids,
          auth: jwt,
        }

        const NEWS_ML_URL = USE_CDK_TRKD
          ? `${CDK_URL}/newsml`
          : `${SLS_TRKD_URL}/${stage}/newsml`

        const data = await this.$axios.post(NEWS_ML_URL, body)

        const newsObj = payload.ids.reduce((acc, id, idx) => {
          acc[id.split(":").pop()] = {
            raw: data.data.data.Story[idx],
          }
          return acc
        }, {})

        if (payload.id) {
          commit("setNewsStories", {
            news: newsObj,
            id: payload.id,
            fetchType: payload.fetchType,
          })
        } else {
          commit("setNews", {
            type: payload.type,
            news: newsObj,
            fetchType: payload.fetchType,
          })
        }
      } catch (error) {
        console.error("error fetching news story: ", error)
        commit("setTRKDError", {
          errorType: "newsStoryError",
          error: {
            error,
            loc: "Fetching News Story",
          },
        })
      } finally {
        commit("setTRKDFetch", {
          fetch: "fetchingNewsStory",
          status: false,
        })
      }
    }
  },
  async fetchFilings({ state, commit }, payload) {
    // fetchFilings IS DEPENDENT on setTicker being called beforehand!
    try {
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/fetchFilings" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      commit("setTRKDFetch", { fetch: "fetchingFilings", status: true })
      if (state.ticker.IssuerOAPermID) {
        const body = {
          id: state.ticker.IssuerOAPermID,
          auth: jwt,
        }

        const LIST_REPORTS_URL = USE_CDK_TRKD
          ? `${CDK_URL}/listReports`
          : `${SLS_TRKD_URL}/${stage}/listReports`

        const data = await this.$axios.post(LIST_REPORTS_URL, body)

        const filingDCN = new Set()
        const res = data.data.data

        const filings = Object.hasOwn(res, "submissionStatusAndInfo")
          ? res.submissionStatusAndInfo.map((sub) => sub.submissionInfo[0])
          : []

        const dedupeFilings = filings
          .reduce((acc, f) => {
            if (filingDCN.has(f.DCN)) {
              // document is already in the accumulator... do nothing
            } else {
              filingDCN.add(f.DCN)
              acc.push(f)
            }
            return acc
          }, [])
          .map((f) => ({
            ...f,
            periodEndDate: parseTime(f.periodEndDate).valueOf(),
            releaseDate: parseTime(f.releaseDate).valueOf(),
            languageCode: f.languageCode ? f.languageCode.toUpperCase() : "",
          }))
        commit("setTickerFilings", {
          filings: dedupeFilings,
          totalCount: res.TotalHit,
          index: res.ESindexServer,
        })
      }
    } catch (error) {
      commit("setTRKDError", {
        errorType: "filingsError",
        error: {
          error,
          loc: "Error getting Filings by OAPermId",
        },
      })
    } finally {
      commit("setTRKDFetch", { fetch: "fetchingFilings", status: false })
    }
  },
  async fetchShareholderReport({ state, commit }, payload) {
    // similar to fetchFilings, fetchShareholdeReport is
    // DEPENDANT on set Ticker being called beforehand!
    let startActive = false
    /**
     * If fetchShareholderReport is called with payload.start then we
     * are requesting paginated data starting at record payload.start.
     * This means that we need to update the investor report instead of
     * clearing it out and such
     */
    if (!state.ticker.RIC) {
      return
    }
    if (payload) {
      startActive = Boolean(payload.start)
    }
    const fetchKey = "fetchingShareholders"

    try {
      commit("setTRKDFetch", { fetch: fetchKey, status: true })
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/fetchShareholderReport" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      const body = {
        ticker: state.ticker.RIC,
        auth: jwt,
      }
      if (startActive) {
        body.start = payload.start
      }
      const SHAREHOLDERS_URL = USE_CDK_TRKD
        ? `${CDK_URL}/shareholders`
        : `${SLS_TRKD_URL}/${stage}/shareholders`

      const data = await this.$axios.post(SHAREHOLDERS_URL, body)

      if (startActive) {
        if (dev) {
          console.log(
            "shareholders fetched in addition to base 200: ",
            data.data.data.SymbolReport
          )
        }
        commit("updateTickerShareholders", data.data.data.SymbolReport)
      } else {
        commit("setTickerOwnership", {
          data: data.data.data.SymbolReport,
          key: "tickerShareholders",
        })
      }
    } catch (error) {
      commit("setTRKDError", {
        errorType: "shareholdersError",
        error: {
          error,
          loc: "Error getting shareholders by RIC",
        },
      })
    } finally {
      commit("setTRKDFetch", { fetch: fetchKey, status: false })
    }
  },
  async fetchInvestorHoldings({ state, commit }, payload) {
    // payload.id is a OApermId
    // if(payload.id ===state.investorHoldings.InvestorDetails ) {}
    if (!payload.id) {
      console.error("fetchInvestorHoldings called without permId")
      return
    }
    const startActive = Boolean(payload.start)
    if (
      Object.hasOwn(state.investorHoldings, "InvestorDetails") &&
      !startActive
    ) {
      const currentId = state.investorHoldings.InvestorDetails.Identifier.Value
      if (payload.id === currentId) {
        // no need to fetch & you're not requesting more information
        return
      }
    }
    try {
      if (!startActive) {
        commit("clearInvestorHoldings")
      }
      commit("setTRKDFetch", {
        fetch: "fetchingInvestorHoldings",
        status: true,
      })
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/fetchInvestorHoldings" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      const body = {
        id: payload.id,
        auth: jwt,
        v: restrictResponse ? "v1" : "v0",
      }
      if (startActive) {
        body.start = payload.start
      }
      const HOLD_URL = USE_CDK_TRKD
        ? `${CDK_URL}/hold`
        : `${SLS_TRKD_URL}/${stage}/hold`

      const data = await this.$axios.post(HOLD_URL, body)

      if (!startActive) {
        commit("setInvestorHoldings", data.data.data.HoldingsReport)
      } else {
        console.log(
          "additional holdings fetched: ",
          data.data.data.HoldingsReport
        )
      }
    } catch (error) {
      if (error.response && error.response.status === 401) {
        console.error("error status code: ", error.response)
        commit("unauthInvestorHoldings", { ...error.response.data })
      } else {
        commit("setTRKDError", {
          errorType: "investorHoldingsError",
          error: {
            error,
            loc: "Error getting Investor Holdings",
          },
        })
      }
    } finally {
      commit("setTRKDFetch", {
        fetch: "fetchingInvestorHoldings",
        status: false,
      })
    }
  },
  async fetchInsiders({ state, commit }, payload) {
    // similar to fetchFilings, fetchShareholdeReport is
    // DEPENDANT on set Ticker being called beforehand!
    let startActive = false
    /**
     * FIXME: need to modify the logic here to work with the insiders endpoint
     * If fetchInsiders is called with payload.startCount then we
     * are requesting paginated data starting at record payload.start.
     * This means that we need to update the investor report instead of
     * clearing it out and such
     */
    if (!state.ticker.RIC) {
      return
    }
    // check if the data has already been fetched and is on the state
    if (
      state.tickerInsiders.Symbol &&
      state.tickerInsiders.Symbol.Value === state.ticker.RIC
    ) {
      return
    }
    if (payload) {
      startActive = Boolean(payload.startCount)
    }
    const fetchKey = "fetchingInsiders"
    try {
      commit("setTRKDError", { errorType: "insidersError", error: null })
      // FIXME: Check if SymbolReport.Symbol.Value === state.ticker.RIC
      commit("setTRKDFetch", { fetch: fetchKey, status: true })
      let jwt = payload.jwt
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        commit(
          "updateCurrentUser",
          { user, from: "trkd/fetchInsiders" },
          { root: true }
        )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      const body = {
        ticker: state.ticker.RIC,
        auth: jwt,
      }
      if (startActive) {
        body.end = payload.end
      }

      const INSIDERS_URL = USE_CDK_TRKD
        ? `${CDK_URL}/insiders`
        : `${SLS_TRKD_URL}/${stage}/insiders`

      const data = await this.$axios.post(INSIDERS_URL, body)

      if (startActive) {
        if (dev) {
          // log stuff here to figureout start active
        }
        commit("updateTickerShareholders", data.data.data.SymbolReport)
      } else {
        commit("setTickerOwnership", {
          data: data.data.data.SymbolReport,
          key: "tickerInsiders",
        })
      }
    } catch (error) {
      commit("setTRKDError", {
        errorType: "insidersError",
        error: {
          error,
          loc: "Error getting insiders by RIC",
        },
      })
    } finally {
      commit("setTRKDFetch", { fetch: fetchKey, status: false })
    }
  },
  updateCollectionMeta({ commit, state }, { stateKey, value }) {
    if (state[stateKey] !== value) {
      commit("setStateKey", { stateKey, value })
      const m = {
        active: state.active,
        order: state.order,
      }
      m[stateKey] = value
      debounceUpdateMeta(this, "na", m, "m")
    }
  },
  updateCollection({ commit }, { id, key, value }) {
    commit("updateFeed", { id, dataKey: key, value })
  },
  async createFeed({ commit, state }, { jwt }) {
    const loadingKey = "createFeed"
    const errorKey = "createFeed"
    try {
      if (!jwt) {
        jwt = await getJwt(this, commit)
      }
      commit("setFeedError", { key: errorKey, value: false })
      commit("setFeedFetch", { key: loadingKey, status: true })

      const m = { order: state.order, active: state.active }
      const body = {
        auth: jwt,
        key: "n",
        m,
      }

      const CREATE_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/c`
        : `${SLS_SCREEN_URL}/${stage}/c`

      const { data } = await this.$axios.post(CREATE_URL, body)

      commit("addFeed", data.d)
      commit("setFeedMeta", data.m)
      const numFeeds = data.m.order ? data.m.order.length : 0 // FIXME: going fast to solve .length access issue
      return numFeeds
    } catch (error) {
      const errorObj = {
        status: {
          error,
          loc: "creating new newsfeed",
        },
      }
      commit("setFeedError", { key: errorKey, value: errorObj })
      return 1
    } finally {
      commit("setFeedFetch", { key: loadingKey, status: false })
    }
  },
  async deleteFeed({ commit, state }, { feedId, jwt }) {
    // delete feed id
    commit("deleteFeed", { id: feedId })
    const errorKey = "deleteFeed"
    try {
      commit("setFeedError", { key: errorKey, value: false })
      const order = state.order.filter((f) => f !== feedId)
      const active = order[0]
      const m = { order, active }

      if (!jwt) {
        jwt = await getJwt(this, commit)
      }

      const body = {
        auth: jwt,
        key: "n",
        m,
        id: feedId,
      }

      const DELETE_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/d`
        : `${SLS_SCREEN_URL}/${stage}/d`

      await this.$axios.post(DELETE_URL, body)
    } catch (error) {
      const errorObj = {
        status: {
          error,
          loc: "deleteFeed from database",
        },
      }
      commit("setFeedError", { key: errorKey, value: errorObj })
    }
  },
  async getAllFeeds({ commit, state }, { jwt }) {
    const loadingKey = "getAll"
    const errorKey = "getAll"
    if (state.loading[loadingKey]) {
      // already fetching
      return
    }
    try {
      commit("setFeedFetch", { key: loadingKey, status: true })
      commit("setFeedError", { key: errorKey, status: null })
      jwt = await getJwt(this, commit)
      const body = {
        auth: jwt,
        key: "n",
      }

      const GET_ALL_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/ga`
        : `${SLS_SCREEN_URL}/${stage}/ga`

      const { data } = await this.$axios.post(GET_ALL_URL, body)

      const feedObj = data.d || {}
      const feedMeta = data.m || { order: [], active: "" }
      const savedOrder = feedMeta.order || []
      const activeId = feedMeta.active

      const feedKeys = Object.keys(feedObj)
      const savedFeedSet = new Set(savedOrder)
      const missingKeys = feedKeys.filter((f) => !savedFeedSet.has(f))
      let order = savedOrder.concat(missingKeys)
      if (order.length === 0) {
        order = feedKeys
      }
      const active = savedOrder.includes(activeId) ? activeId : savedOrder[0]
      commit("setStateObject", { stateKey: "feedDataObj", newObj: feedObj })
      commit("setFeedMeta", { order, active })
    } catch (error) {
      const status = {
        error,
        loc: "fetching all newsfeeds",
      }
      commit("setFeedError", { key: errorKey }, status)
    } finally {
      commit("setFeedFetch", { key: loadingKey, status: false })
    }
  },
}

export { state, mutations, actions }
