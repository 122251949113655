const transformFirstActualResponse = (res) => {
  // allLineItems is an array of json objects which contain all of the dataitems (w/ descriptions & groups)
  // allLineItems is the seed to create the array of objects that contain the data by period
  const allLineItems = res.allkeys
  const resData = res.data.reduce((acc, item) => {
    if (acc[item.dataitemid]) {
      const dataObj = acc[item.dataitemid]
      dataObj[item.estimateperiodid] = {
        v: item.dataitemvalue,
        c: item.initcurrencyiso,
      }
    } else {
      const { estimateperiodid, dataitemvalue, initcurrencyiso } = item
      const newObj = {}
      newObj[estimateperiodid] = {
        v: dataitemvalue,
        c: initcurrencyiso,
      }
      acc[item.dataitemid] = newObj
    }
    return acc
  }, {})

  // all actuals is an array that is given to v-for to build the rows
  const allActuals = allLineItems.reduce((acc, lineitem) => {
    if (resData[lineitem.dataitemid]) {
      acc.push(Object.assign(resData[lineitem.dataitemid], lineitem))
    }
    return acc
  }, [])

  const dates = res.dates.map((d) => {
    return { ...d }
  })
  return {
    allLineItems,
    allActuals,
    dates,
  }
}

export { transformFirstActualResponse }
