import debounce from "lodash/debounce"
import { USE_CDK_SCREENER, USE_CDK_USER_DATA } from "../feature-toggle"
import { createScreenerColumns } from "~/utils/screener"
import {
  allScreenerItems,
  screenerItemAccumulator,
} from "~/components/screener/screener_lineitems"

const stage = process.env.LAMBDA_STAGE
const devStage = stage === "dev"

const SLS_SCREEN_URL = process.env.SCREEN_LAMBDA_URL
const CDK_URL = process.env.API_URL

const restrictResponse = process.env.USE_TIERS === "true"

const state = () => ({
  loading: {},
  error: {},
  numberCompanies: 66352, // can connect this to backend I guess
  conditionLimit: 30,
  initialFetch: false,
  subsequentFetch: false,
  initialError: null,
  subsequentError: null,
  // screenMeta: {
  //   order: ["testid"], // order is an array of uuids related to each screener, screenData
  // },
  order: [],
  active: null,
  screenDataObj: {},
  screenResultsObj: {
    // example of what screenResultsObj will contain
    // testid: {
    // 	condArr: [],
    // 	rows: []
    // }
  },
})

const updateBackend = async (that, id, obj, type) => {
  try {
    const user = await that.$Amplify.Auth.currentAuthenticatedUser()
    const jwt = user.signInUserSession.idToken.jwtToken

    const body = {
      auth: jwt,
      key: "s",
      id,
    }
    body[type] = obj

    const UPDATE_URL = USE_CDK_USER_DATA
      ? `${CDK_URL}/u`
      : `${SLS_SCREEN_URL}/${stage}/u`

    const { data } = await that.$axios.post(UPDATE_URL, body)

    return data
  } catch (error) {
    //
    if (devStage) {
      console.error(`Error updating screener ${id} type ${type}: `, error)
    }
  }
}

// TODO: determine good debounce times
const debounceUpdateObj = debounce(updateBackend, 1500)
const debounceUpdateMeta = debounce(updateBackend, 3000)

const mutations = {
  resetScreenerState(state) {
    state.screenDataObj = {}
    state.screenResultsObj = {}
    state.loading = {}
    state.error = {}
    state.fetch = {}
  },
  updateResultsObj(state, { res, screenId }) {
    const newObj = {}
    newObj[screenId] = res
    state.screenResultsObj = { ...state.screenResultsObj, ...newObj }
  },
  setStateKey(state, { stateKey, value }) {
    state[stateKey] = value
    // if (testLocalStorageMap[stateKey]) {
    //   setLocalStorage(testLocalStorageMap[stateKey], state[stateKey])
    // }
  },
  setScreenMeta(state, { order, active }) {
    if (order) {
      // set order
      state.order = order
    }
    if (active) {
      // set active
      state.active = active
    }
    // TODO: check if it makes sense?
    // or are you already doing this?
    // else {
    //   state.active = state.order[0]
    // }
  },
  setStateObject(state, { stateKey, newObj }) {
    state[stateKey] = { ...state[stateKey], ...newObj }
  },
  setScreenerFetch(state, { key, status }) {
    const newObj = {}
    newObj[key] = status
    state.loading = { ...state.loading, ...newObj }
  },
  setScreenerError(state, { key, status: value }) {
    const newObj = {}
    newObj[key] = value
    state.error = { ...state.error, ...newObj }
  },
  setScreenerObj(state, { storeKey, key, value }) {
    // this is the fully abstracted method of setScreenerFetch
    // update metadata is similar but its 2 levels deep in an object
    const newObj = {}
    newObj[key] = value
    state[storeKey] = { ...state[storeKey], ...newObj }
  },
  addScreen(state, payload) {
    // do the add screener mutation here
    state.screenDataObj = { ...state.screenDataObj, ...payload }
    // TODO: WORK ON PERSISTANCE
    // setLocalStorage("tikrTestScreenDataObj", state.screenDataObj)
    // setLocalStorage("tikrTestScreenOrder", state.order)
    // setLocalStorage("tikrTestactive", state.active)
  },
  deleteScreener(state, { id }) {
    // do the add screener mutation here
    // how to remove the id from the store
    state.order = state.order.filter((f) => f !== id)
    state.active = state.order[0]
    delete state.screenDataObj[id]
    state.screenDataObj = { ...state.screenDataObj } // this is to make sure vue notices?
    // TODO: WORK ON PERSISTANCE
    // setLocalStorage("tikrTestScreenDataObj", state.screenDataObj)
    // setLocalStorage("tikrTestScreenOrder", state.order)
    // setLocalStorage("tikrTestactive", state.active)
  },
  updateCondition(state, { id, type, cond }) {
    const screenToModify = state.screenDataObj[id]
    if (type === "add") {
      const now = new Date()
      // TODO: do you need a better default cond here?
      const defaultCond = {
        created: now.toISOString(),
      }
      screenToModify.condArr.push(defaultCond)
    } else if (type === "delete") {
      screenToModify.condArr = screenToModify.condArr.filter(
        (f) => f.created !== cond.created
      )
    } else if (type === "update") {
      // client side update logic here
      screenToModify.condArr = screenToModify.condArr.map((m) => {
        if (m.created === cond.created) {
          return cond // this is the full updated condition
        }
        return m
      })
      // take screenToModify, persist it
    }

    const newScreenObj = {}
    newScreenObj[id] = screenToModify
    state.screenDataObj = { ...state.screenDataObj, ...newScreenObj }
    // setLocalStorage("tikrTestScreenDataObj", state.screenDataObj)
    debounceUpdateObj(this, id, state.screenDataObj[id], "d")
  },
  updateMetaData(state, { screenId, dataKey, value }) {
    // this updates an object on the store that is 2 levels
    // deep - 1st level preset screenDataObj second level
    // variable dataKey
    const screenToModify = state.screenDataObj[screenId]
    screenToModify[dataKey] = value
    // take screenToModify, update here
    const newScreenObj = {}
    newScreenObj[screenId] = screenToModify

    state.screenDataObj = { ...state.screenDataObj, ...newScreenObj }
    // debounceSetLocalStorage("tikrTestScreenDataObj", state.screenDataObj)
    debounceUpdateObj(this, screenId, state.screenDataObj[screenId], "d")
  },
}

const actions = {
  updateCollectionMeta({ commit, state }, { stateKey, value }) {
    if (state[stateKey] !== value) {
      commit("setStateKey", { stateKey, value })
      const m = {}
      m.active = state.active // this is specific to screener meta/settings
      m.order = state.order // this is specific to screener meta/settings
      m[stateKey] = value
      debounceUpdateMeta(this, "meh", m, "m")
    }
  },
  // following 4 actions are for an individual screen
  addScreenerCondition({ commit }, { id }) {
    commit("updateCondition", { id, type: "add" })
  },
  deleteScreenerCondition({ commit }, { id, cond }) {
    commit("updateCondition", { id, cond, type: "delete" })
  },
  updateScreenerCondition({ commit }, { id, pos, cond }) {
    // payload = {id, type, pos, cond}
    // perform debounce of remote async saving here? or do it in the mutation
    // the mutation should be synchronous, hmm
    commit("updateCondition", { id, pos, cond, type: "update" })
  },
  updateScreenerMetaData({ commit }, { screenId, dataKey, value }) {
    // perform debounce of remote async saving
    commit("updateMetaData", { screenId, dataKey, value })
  },
  // updateScreenerObj({ commit }, { type, screenId }) {
  // 	// commit the appropraite action
  // 	// and then initiate the async dispatch of the backend
  // },
  async createScreen({ commit, state }, { jwt }) {
    const loadingKey = "createScreen"
    const errorKey = "createScreen"
    try {
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        jwt = user.signInUserSession.idToken.jwtToken
      }
      commit("setScreenerError", { key: errorKey, value: false })
      commit("setScreenerFetch", { key: loadingKey, status: true })

      const m = { order: state.order, active: state.active }
      const body = {
        auth: jwt,
        key: "s",
        m,
      }

      const CREATE_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/c`
        : `${SLS_SCREEN_URL}/${stage}/c`

      const { data } = await this.$axios.post(CREATE_URL, body)

      commit("addScreen", data.d)
      commit("setScreenMeta", data.m)
      const numScreens = data.m.order.length
      return numScreens
    } catch (error) {
      const errorObj = {
        status: {
          error,
          loc: "creating new screen",
        },
      }
      commit("setScreenerError", { key: errorKey, value: errorObj })
      return 1
    } finally {
      commit("setScreenerFetch", { key: loadingKey, status: false })
    }
  },
  async deleteScreen({ commit, state }, { screenerId, jwt }) {
    // delete screen id
    commit("deleteScreener", { id: screenerId })
    const errorKey = "deleteScreen"
    try {
      commit("setScreenerError", { key: errorKey, value: false })
      const order = state.order.filter((f) => f !== screenerId)
      const active = order[0]
      const m = { order, active }

      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        jwt = user.signInUserSession.idToken.jwtToken
      }

      const body = {
        auth: jwt,
        key: "s",
        m,
        id: screenerId,
      }

      const DELETE_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/d`
        : `${SLS_SCREEN_URL}/${stage}/d`

      return await this.$axios.post(DELETE_URL, body)
    } catch (error) {
      const errorObj = {
        status: {
          error,
          loc: "deleteScreen from database",
        },
      }
      commit("setScreenerError", { key: errorKey, value: errorObj })
    }
  },
  async getAllScreens({ commit, state }, { jwt }) {
    const loadingKey = "getAll"
    const errorKey = "getAll"
    if (state.loading[loadingKey]) {
      // already fetching
      return
    }
    try {
      if (!jwt) {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        // commit(
        //   "updateCurrentUser",
        //   { user, from: "screener/getAllScreens" },
        //   { root: true }
        // )
        jwt = user.signInUserSession.idToken.jwtToken
      }
      commit("setScreenerError", { key: errorKey, value: false })
      commit("setScreenerFetch", { key: loadingKey, status: true })
      // const lsScreenObj =
      //   getObjectFromLocalStorage("tikrTestScreenDataObj") || {}
      // const lsSavedOrder =
      //   getObjectFromLocalStorage("tikrTestScreenOrder") || []
      // const lsActiveId =
      //   getStrFromLocalStorage("tikrTestactive") || lsSavedOrder[0]

      const body = {
        auth: jwt,
        key: "s",
      }

      const GET_ALL_URL = USE_CDK_USER_DATA
        ? `${CDK_URL}/ga`
        : `${SLS_SCREEN_URL}/${stage}/ga`

      const { data } = await this.$axios.post(GET_ALL_URL, body)

      const screenObj = data.d || {}
      const screenMeta = data.m || { order: [], active: "" }
      const savedOrder = screenMeta.order // becomes state.order
      const activeId = screenMeta.active // becomes state.active

      const screenKeys = Object.keys(screenObj)
      const savedScreenSet = new Set(savedOrder)
      const missingKeys = screenKeys.filter((f) => !savedScreenSet.has(f))
      let order = savedOrder.concat(missingKeys)
      // you've got like 3 different flows of logic
      // colliding right here
      if (order.length === 0) {
        order = screenKeys
      }

      const active = savedOrder.includes(activeId) ? activeId : savedOrder[0]

      commit("setStateObject", {
        stateKey: "screenDataObj",
        newObj: screenObj,
      })
      commit("setScreenMeta", { order, active })
      // commit("setStateKey", {
      //   stateKey: "order",
      //   value: order,
      // })
      // commit("setStateKey", {
      //   stateKey: "active",
      //   value: active,
      // })
    } catch (error) {
      const errorObj = {
        status: {
          error,
          loc: "fetching all screeners",
        },
      }
      commit("setScreenerError", { key: errorKey, value: errorObj })
    } finally {
      commit("setScreenerFetch", { key: loadingKey, status: false })
    }
  },
  async fetchScreenResult({ state, commit }, { auth, req, screenId } = {}) {
    if (state.loading[screenId]) {
      // loading[screenId] = boolean indicating if a screen is fetching
      // currently fetching a screen, don't fetch another
      return
    }
    const t0 = performance.now()
    try {
      // commit("setScreenerFetch", { key: screenId, status: true })
      commit("setScreenerFetch", { key: screenId, status: true })
      commit("updateMetaData", { screenId, dataKey: "error", value: null })
      commit("updateResultsObj", { screenId, res: null })
      const body = {}
      // set auth token for request
      if (auth) {
        body.auth = auth
      } else {
        const user = await this.$Amplify.Auth.currentAuthenticatedUser()
        const jwt = user.signInUserSession.idToken.jwtToken
        body.auth = jwt
      }
      body.req = req
      body.id = screenId
      body.v = restrictResponse ? "v2" : "v1"
      // body.v = restrictResponse ? "v1" : "v1"
      const I18nFn = (text) => {
        return this.$Amplify.I18n.get(text)
      }
      const screenItemMap = allScreenerItems.reduce((returnAcc, itemObj) => {
        return screenerItemAccumulator(returnAcc, itemObj, I18nFn)
      }, {})

      const SCREENER_URL = USE_CDK_SCREENER
        ? `${CDK_URL}/fs`
        : `${SLS_SCREEN_URL}/${stage}/fs`

      const { data } = await this.$axios.post(SCREENER_URL, body)

      if (data.rowCount === 0) {
        commit("updateMetaData", {
          screenId,
          dataKey: "error",
          value: this.$Amplify.I18n.get(
            `Your screen returned 0 results. Please expand your search`
          ),
        })
        return
      }

      // on successful fetch, reset table to the first page of results
      commit("updateMetaData", {
        screenId,
        dataKey: "page",
        value: 1,
      })
      // TODO: check if the current itemsPerPage is contained within the number of results?
      if (data.numComp) {
        commit("setStateKey", {
          stateKey: "numberCompanies",
          value: data.numComp,
        })
      }

      data.newColumns = createScreenerColumns(req, data, screenItemMap, I18nFn)
      const now = new Date()
      data.created = now
      // TODO: transform res.rows to Array of Arrays to Array of Objects
      const fields = data.fields.map((f) => f.name)

      data.rows = data.rows.map((r) => {
        const returnObj = {}
        fields.forEach((f, idx) => {
          returnObj[f] = r[idx]
        })
        return returnObj
      })
      // think about reducing
      commit("updateResultsObj", { screenId, res: data })
    } catch (error) {
      if (error.response) {
        console.error("error status code: ", error.response)
        if (error.response.status === 413) {
          //
          commit("updateMetaData", {
            screenId,
            dataKey: "error",
            value: `${this.$Amplify.I18n.get(
              `Error: Too many results for screen. Please narrow your search.`
            )} ${error.response.data.count} ${this.$Amplify.I18n.get(
              `companies.`
            )}`,
          })

          return
        } else if (error.response.status === 414) {
          commit("updateMetaData", {
            screenId,
            dataKey: "error",
            value: `${this.$Amplify.I18n.get(
              `Error: Screener Timeout. Support has been notified. Try removing a condition from the screen.`
            )}`,
          })
          return
        }
      }
      // CORS error
      console.error("CORS error")
      commit("updateMetaData", {
        screenId,
        dataKey: "error",
        value: this.$Amplify.I18n.get(
          `An error has occured running your screen. Please take a screenshot of the conditions and email the screenshot to support@tikr.com`
        ),
      })
    } finally {
      commit("setScreenerFetch", { key: screenId, status: false })
      if (stage === devStage) {
        console.log(`fetchScreenResult took ${performance.now() - t0} ms`)
      }
    }
  },
}

export { state, mutations, actions }
