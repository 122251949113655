import useBaseUtils from "~/functions/useBaseUtils";
var useCommonStrings = function useCommonStrings() {
  var _useBaseUtils = useBaseUtils(),
    I18nFn = _useBaseUtils.I18nFn;
  var nonAvailableString = computed(function () {
    return I18nFn("N/A");
  });
  var priceHiddenString = computed(function () {
    return I18nFn("Price Hidden");
  });
  return {
    nonAvailableString: nonAvailableString,
    priceHiddenString: priceHiddenString
  };
};
export default useCommonStrings;