import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _aa3855f2 = () => interopDefault(import('../pages/account.vue' /* webpackChunkName: "pages/account" */))
const _6bef8b6c = () => interopDefault(import('../pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _5e8497e6 = () => interopDefault(import('../pages/account/dev.vue' /* webpackChunkName: "pages/account/dev" */))
const _65bba252 = () => interopDefault(import('../pages/account/oldsubs.vue' /* webpackChunkName: "pages/account/oldsubs" */))
const _1eb373d5 = () => interopDefault(import('../pages/account/referral.vue' /* webpackChunkName: "pages/account/referral" */))
const _e5ce512a = () => interopDefault(import('../pages/account/subs.vue' /* webpackChunkName: "pages/account/subs" */))
const _0c2ad8fb = () => interopDefault(import('../pages/account/success.vue' /* webpackChunkName: "pages/account/success" */))
const _35033d3c = () => interopDefault(import('../pages/addWatchlist.vue' /* webpackChunkName: "pages/addWatchlist" */))
const _67fe7b48 = () => interopDefault(import('../pages/basket.vue' /* webpackChunkName: "pages/basket" */))
const _5b812450 = () => interopDefault(import('../pages/config.vue' /* webpackChunkName: "pages/config" */))
const _e86a9fcc = () => interopDefault(import('../pages/confirm.vue' /* webpackChunkName: "pages/confirm" */))
const _e30a158e = () => interopDefault(import('../pages/forgot.vue' /* webpackChunkName: "pages/forgot" */))
const _14770cae = () => interopDefault(import('../pages/investor.vue' /* webpackChunkName: "pages/investor" */))
const _7e162a35 = () => interopDefault(import('../pages/investors.vue' /* webpackChunkName: "pages/investors" */))
const _7d9c04c3 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _ed5b2cde = () => interopDefault(import('../pages/markets.vue' /* webpackChunkName: "pages/markets" */))
const _4cd5677c = () => interopDefault(import('../pages/mfa.vue' /* webpackChunkName: "pages/mfa" */))
const _5b5491fe = () => interopDefault(import('../pages/model-preview/index.vue' /* webpackChunkName: "pages/model-preview/index" */))
const _76c970fa = () => interopDefault(import('../pages/models/index.vue' /* webpackChunkName: "pages/models/index" */))
const _6a1cbbbc = () => interopDefault(import('../pages/portfolio.vue' /* webpackChunkName: "pages/portfolio" */))
const _57bb13c0 = () => interopDefault(import('../pages/pricing.vue' /* webpackChunkName: "pages/pricing" */))
const _aa10b4d8 = () => interopDefault(import('../pages/privacypolicy.vue' /* webpackChunkName: "pages/privacypolicy" */))
const _9a109070 = () => interopDefault(import('../pages/refid.vue' /* webpackChunkName: "pages/refid" */))
const _0d5f3939 = () => interopDefault(import('../pages/register.vue' /* webpackChunkName: "pages/register" */))
const _2da7c1af = () => interopDefault(import('../pages/screener.vue' /* webpackChunkName: "pages/screener" */))
const _7656ad3e = () => interopDefault(import('../pages/search.vue' /* webpackChunkName: "pages/search" */))
const _2fab6df0 = () => interopDefault(import('../pages/stock.vue' /* webpackChunkName: "pages/stock" */))
const _1691e5ae = () => interopDefault(import('../pages/stock/about.vue' /* webpackChunkName: "pages/stock/about" */))
const _476ef228 = () => interopDefault(import('../pages/stock/estimates.vue' /* webpackChunkName: "pages/stock/estimates" */))
const _4f3c0c8a = () => interopDefault(import('../pages/stock/filings.vue' /* webpackChunkName: "pages/stock/filings" */))
const _327e9239 = () => interopDefault(import('../pages/stock/financials.vue' /* webpackChunkName: "pages/stock/financials" */))
const _6dc5f64d = () => interopDefault(import('../pages/stock/model/index.vue' /* webpackChunkName: "pages/stock/model/index" */))
const _55b45704 = () => interopDefault(import('../pages/stock/multiples.vue' /* webpackChunkName: "pages/stock/multiples" */))
const _ae14ce7c = () => interopDefault(import('../pages/stock/news.vue' /* webpackChunkName: "pages/stock/news" */))
const _8695dfe0 = () => interopDefault(import('../pages/stock/ownership.vue' /* webpackChunkName: "pages/stock/ownership" */))
const _66d94a93 = () => interopDefault(import('../pages/stock/ownership/index.vue' /* webpackChunkName: "pages/stock/ownership/index" */))
const _7aa5168c = () => interopDefault(import('../pages/stock/ownership/insiders.vue' /* webpackChunkName: "pages/stock/ownership/insiders" */))
const _85104db2 = () => interopDefault(import('../pages/stock/ownership/summary.vue' /* webpackChunkName: "pages/stock/ownership/summary" */))
const _50568980 = () => interopDefault(import('../pages/stock/simple-model/index.vue' /* webpackChunkName: "pages/stock/simple-model/index" */))
const _353f9ee5 = () => interopDefault(import('../pages/stock/transcript.vue' /* webpackChunkName: "pages/stock/transcript" */))
const _765fdede = () => interopDefault(import('../pages/stock/transcripts.vue' /* webpackChunkName: "pages/stock/transcripts" */))
const _5df21fe1 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _39423847 = () => interopDefault(import('../pages/watchlist.vue' /* webpackChunkName: "pages/watchlist" */))
const _62a81e7c = () => interopDefault(import('../pages/legal/cookiepolicy.vue' /* webpackChunkName: "pages/legal/cookiepolicy" */))
const _968ac006 = () => interopDefault(import('../pages/legal/gdpraddendum.vue' /* webpackChunkName: "pages/legal/gdpraddendum" */))
const _1aaf363e = () => interopDefault(import('../pages/legal/privacypolicy.vue' /* webpackChunkName: "pages/legal/privacypolicy" */))
const _133ef47e = () => interopDefault(import('../pages/legal/subprocessor.vue' /* webpackChunkName: "pages/legal/subprocessor" */))
const _40b1fa8b = () => interopDefault(import('../pages/legal/terms.vue' /* webpackChunkName: "pages/legal/terms" */))
const _490aa924 = () => interopDefault(import('../pages/models/company.vue' /* webpackChunkName: "pages/models/company" */))
const _98173ca8 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account",
    component: _aa3855f2,
    children: [{
      path: "",
      component: _6bef8b6c,
      name: "account"
    }, {
      path: "dev",
      component: _5e8497e6,
      name: "account-dev"
    }, {
      path: "oldsubs",
      component: _65bba252,
      name: "account-oldsubs"
    }, {
      path: "referral",
      component: _1eb373d5,
      name: "account-referral"
    }, {
      path: "subs",
      component: _e5ce512a,
      name: "account-subs"
    }, {
      path: "success",
      component: _0c2ad8fb,
      name: "account-success"
    }]
  }, {
    path: "/addWatchlist",
    component: _35033d3c,
    name: "addWatchlist"
  }, {
    path: "/basket",
    component: _67fe7b48,
    name: "basket"
  }, {
    path: "/config",
    component: _5b812450,
    name: "config"
  }, {
    path: "/confirm",
    component: _e86a9fcc,
    name: "confirm"
  }, {
    path: "/forgot",
    component: _e30a158e,
    name: "forgot"
  }, {
    path: "/investor",
    component: _14770cae,
    name: "investor"
  }, {
    path: "/investors",
    component: _7e162a35,
    name: "investors"
  }, {
    path: "/login",
    component: _7d9c04c3,
    name: "login"
  }, {
    path: "/markets",
    component: _ed5b2cde,
    name: "markets"
  }, {
    path: "/mfa",
    component: _4cd5677c,
    name: "mfa"
  }, {
    path: "/model-preview",
    component: _5b5491fe,
    name: "model-preview"
  }, {
    path: "/models",
    component: _76c970fa,
    name: "models"
  }, {
    path: "/portfolio",
    component: _6a1cbbbc,
    name: "portfolio"
  }, {
    path: "/pricing",
    component: _57bb13c0,
    name: "pricing"
  }, {
    path: "/privacypolicy",
    component: _aa10b4d8,
    name: "privacypolicy"
  }, {
    path: "/refid",
    component: _9a109070,
    name: "refid"
  }, {
    path: "/register",
    component: _0d5f3939,
    name: "register"
  }, {
    path: "/screener",
    component: _2da7c1af,
    name: "screener"
  }, {
    path: "/search",
    component: _7656ad3e,
    name: "search"
  }, {
    path: "/stock",
    component: _2fab6df0,
    name: "stock",
    children: [{
      path: "about",
      component: _1691e5ae,
      name: "stock-about"
    }, {
      path: "estimates",
      component: _476ef228,
      name: "stock-estimates"
    }, {
      path: "filings",
      component: _4f3c0c8a,
      name: "stock-filings"
    }, {
      path: "financials",
      component: _327e9239,
      name: "stock-financials"
    }, {
      path: "model",
      component: _6dc5f64d,
      name: "stock-model"
    }, {
      path: "multiples",
      component: _55b45704,
      name: "stock-multiples"
    }, {
      path: "news",
      component: _ae14ce7c,
      name: "stock-news"
    }, {
      path: "ownership",
      component: _8695dfe0,
      children: [{
        path: "",
        component: _66d94a93,
        name: "stock-ownership"
      }, {
        path: "insiders",
        component: _7aa5168c,
        name: "stock-ownership-insiders"
      }, {
        path: "summary",
        component: _85104db2,
        name: "stock-ownership-summary"
      }]
    }, {
      path: "simple-model",
      component: _50568980,
      name: "stock-simple-model"
    }, {
      path: "transcript",
      component: _353f9ee5,
      name: "stock-transcript"
    }, {
      path: "transcripts",
      component: _765fdede,
      name: "stock-transcripts"
    }]
  }, {
    path: "/terms",
    component: _5df21fe1,
    name: "terms"
  }, {
    path: "/watchlist",
    component: _39423847,
    name: "watchlist"
  }, {
    path: "/legal/cookiepolicy",
    component: _62a81e7c,
    name: "legal-cookiepolicy"
  }, {
    path: "/legal/gdpraddendum",
    component: _968ac006,
    name: "legal-gdpraddendum"
  }, {
    path: "/legal/privacypolicy",
    component: _1aaf363e,
    name: "legal-privacypolicy"
  }, {
    path: "/legal/subprocessor",
    component: _133ef47e,
    name: "legal-subprocessor"
  }, {
    path: "/legal/terms",
    component: _40b1fa8b,
    name: "legal-terms"
  }, {
    path: "/models/company",
    component: _490aa924,
    name: "models-company"
  }, {
    path: "/",
    component: _98173ca8,
    name: "index"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
