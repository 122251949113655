import last from "lodash/last"
import cloneDeep from "lodash/cloneDeep"
import { utcParse } from "d3-time-format"
import {
  addTwoFinLineItems,
  calcFinDerivative,
  calcFinDiv,
} from "./financialsMath"
import { findMetricKey } from "./common"
import asRptSegTemplate from "~/utils/templates/asRptSegmentTemplate"

const parseTime = utcParse("%Y-%m-%dT%H:%M:%S.%LZ")

const transformFinancialDates = (res) => {
  return res.dates.map((d) => {
    try {
      d.epoch = parseTime(d.fiperiodenddate)
      d.timeVal = Date.parse(d.fiperiodenddate)
      d.exchangerate = parseFloat(d.priceclose)
      const finPeriodType = d.periodtypeid
      if (finPeriodType === 1) {
        // annual, fiscal year
        // d.value = d.financialperiodid
        d.value = `${d.calendaryear}##FY`
        // d.value = `${d.calendaryear}##${d.calendarquarter}`
      } else {
        // quarterly, semiannual, LTM, or YTD
        // d.value = d.financialperiodid
        d.value = `${d.calendaryear}##${d.calendarquarter}`
      }
    } catch (error) {
      console.error("error processing financial date:", error)
    }
    return d
  })
}

const transformResData = (dates, res) => {
  const finPeriodMap = dates.reduce((acc, d) => {
    const ciqFinPeriodId = d.financialperiodid
    acc[ciqFinPeriodId] = d
    return acc
  }, {})

  const resData = res.data.reduce((acc, item) => {
    // FIXME: parseFloat(item.dataitemvalue) and check !item.dataitemvalue
    if (item.dataitemvalue === "0.000000") {
      return acc
    }
    // FIXME: this is where I could standardize to Estimates
    const {
      dataitemid,
      dataitemvalue,
      // financialcollectionid,
      financialperiodid,
      unittypeid,
      unauth,
    } = item
    const dateObj = finPeriodMap[financialperiodid]
    const dateKey = dateObj.value // this is how you can change the resObj dates to be human readable
    const metricKey = findMetricKey(dataitemid)
    if (!metricKey) {
      console.log("No financials metricKey: ", item)
    }

    const metricObj = {
      v: parseFloat(dataitemvalue), // FIXME: parse the value
      u: unittypeid,
      iso: dateObj.isocode,
      pc: dateObj.priceclose ? parseFloat(dateObj.priceclose) : 1,
      timeVal: Date.parse(dateObj.fiperiodenddate),
      unauth,
    }

    if (acc[metricKey]) {
      const dataObj = acc[metricKey]
      dataObj[dateKey] = metricObj
    } else {
      const newObj = {}
      // FIXME: this is where I could standardize to Estimates
      // TODO: CHANGE THIS TO ALSO INCLUDE currency info
      newObj[dateKey] = metricObj
      acc[metricKey] = newObj
    }
    return acc
  }, {})
  return { finPeriodMap, resData }
}

// Seems like this code is a little redundent - outputs what is input
// leaving it here as a hook to filter out unneccesary information from the response
const transformLineItemArray = (resData, dates, requestPeriod) => {
  const createLineitemArray = (acc, lineitem) => {
    if (
      lineitem.formula === "val" ||
      lineitem.formula === "turns" ||
      lineitem.formula === "pct"
    ) {
      const metricKey = findMetricKey(lineitem.dataitemid)
      // lineitem.formula = "val" implies response is just a dataitemid
      // check to see if the lineitem exists
      if (resData[metricKey]) {
        acc.push(Object.assign(resData[metricKey], lineitem)) // spreading lineitem into resData
      } else {
        lineitem.tikrdisplay = "0"
        acc.push(lineitem)
      }
      return acc
    } else if (lineitem.formula === "h3") {
      acc.push(lineitem)
    } else if (lineitem.formula === "dxdt") {
      // this is a period on period calculation
      // FIXME: If I change the xlsx to json conversion so it doesn't create an array then I don't
      // need this repetitive line because its failing
      const metricKey = findMetricKey(lineitem.dataitemid[0])
      if (resData[metricKey]) {
        const derivative = calcFinDerivative(
          metricKey,
          resData,
          dates,
          requestPeriod
        )
        acc.push(Object.assign(derivative, lineitem))
      } else {
        lineitem.tikrdisplay = "0"
        acc.push(lineitem)
      }
      return acc
    } else if (lineitem.formula === "sum") {
      const firstMetricKey = findMetricKey(lineitem.dataitemid[0])
      const secondMetricKey = findMetricKey(lineitem.dataitemid[1])
      if (resData[firstMetricKey] && resData[secondMetricKey]) {
        const firstObj = resData[firstMetricKey]
        const secondObj = resData[secondMetricKey]
        const sum = addTwoFinLineItems(firstObj, secondObj, dates)
        const lineitemObj = Object.assign({}, lineitem, sum)
        const metricKey = `${lineitem.name.toLowerCase()}_sum`
        // console.log("metricKey: ", metricKey)
        resData[metricKey] = lineitemObj
        acc.push(lineitemObj) // I no longer like this order of Object.assign... sum should be last it is most important
      } else {
        lineitem.tikrdisplay = "0"
        acc.push(lineitem)
      }
      return acc
    } else if (lineitem.formula === "div") {
      const numeratorKey = findMetricKey(lineitem.dataitemid[0])
      const denominatorKey = findMetricKey(lineitem.dataitemid[1])

      if (resData[numeratorKey] && resData[denominatorKey]) {
        // A calculation is occuring FIXME: change if condition if you're ever doing more than just 2 things
        // right now there are only two numbers being passed because we're only doing division
        // a list comprehenshion would be great right here.. not quite sure how to check that for javascript
        // FIXME: if(lineitem.formula === "div") ... what you've done feels lazy
        const margin = calcFinDiv(numeratorKey, denominatorKey, resData, dates)
        acc.push(Object.assign(margin, lineitem))
      }
    } else {
      // there is no data for this lineitem, set tikrdisplay to "0"
      // which should enable us to display all rows with a toggle
      lineitem.tikrdisplay = "0"
      acc.push(lineitem)
    }
    return acc
  }
  return createLineitemArray
}

const transformFinancials = (
  statementTemplate,
  templateId,
  createLineitemArray
) => {
  const financialsLineItems = cloneDeep(statementTemplate)

  const financials = financialsLineItems.map((statement) => {
    return statement[templateId]
      ? statement[templateId].keys.reduce(createLineitemArray, [])
      : statement.keys.reduce(createLineitemArray, [])
  })
  return { financialsLineItems, financials }
}

const transformAsReptSegData = (res, finPeriodMap) => {
  const rawAsRptSegmentData = res.rpt
  let asRptSegData = {}
  if (rawAsRptSegmentData && rawAsRptSegmentData.length > 0) {
    // if the length > 0 check needed? How to handle no segments returned?
    asRptSegData = rawAsRptSegmentData.reduce((acc, rawObj) => {
      const {
        dataitemid: did,
        dataitemvalue: strV,
        financialperiodid,
        segmentname: segName,
        unittypeid,
        unauth,
      } = rawObj
      const dateObj = finPeriodMap[financialperiodid]

      if (dateObj) {
        const dateKey = dateObj.value // this is how you can change the resObj dates to be human readable
        const metricKey = did
        const segDataObj = {
          v: Number(strV), // FIXME: parse this value
          u: unittypeid, // I think dailyMultiples stores this as s
          iso: dateObj.isocode,
          pc: dateObj.priceclose ? parseFloat(dateObj.priceclose) : 1,
          timeVal: Date.parse(dateObj.fiperiodenddate),
          unauth,
        }
        const newPeriodObj = {}
        newPeriodObj[dateKey] = segDataObj

        if (acc[metricKey]) {
          if (acc[metricKey][segName]) {
            acc[metricKey][segName][dateKey] = segDataObj
          } else {
            acc[metricKey][segName] = newPeriodObj
          }
        } else {
          acc[metricKey] = {}
          acc[metricKey][segName] = newPeriodObj
        }
      }

      return acc
    }, {})
  }
  return asRptSegData
}

const transformMostRecentKeys = (dates, requestPeriod) => {
  const filteredDateArrayForActivePeriod = dates.filter(
    (date) => !date.isEstimate && date.periodtypeid === Number(requestPeriod)
  )
  const filteredDatesLtm = dates.filter(
    (date) => !date.isEstimate && date.periodtypeid === 4
  )

  const mostRecentDateKey = last(filteredDateArrayForActivePeriod)?.value
  const mostRecentLtmKey = last(filteredDatesLtm)?.value
  return { mostRecentDateKey, mostRecentLtmKey }
}

const transformAsRptSegments = (asRptSegData) => {
  const asRptSegments = asRptSegTemplate.keys.reduce((acc, rowObj) => {
    if (rowObj.formula === "h3" || asRptSegData[rowObj.dataitemid]) {
      acc.push(rowObj)
    }
    return acc
  }, [])
  return asRptSegments
}

const transformFinancialResponse = (
  res,
  statementTemplate,
  templateId,
  requestPeriod
) => {
  const dates = transformFinancialDates(res)
  const { finPeriodMap, resData } = transformResData(dates, res)

  const createLineitemArray = transformLineItemArray(
    resData,
    dates,
    requestPeriod
  )

  // tikrDisplay by financial statement
  const { financialsLineItems, financials } = transformFinancials(
    statementTemplate,
    templateId,
    createLineitemArray
  )

  // FIXME: remove resData? Remove extranous data?
  const asRptSegData = transformAsReptSegData(res, finPeriodMap)
  const asRptSegments = transformAsRptSegments(asRptSegData)

  const { mostRecentDateKey, mostRecentLtmKey } = transformMostRecentKeys(
    dates,
    requestPeriod
  )

  return {
    dates,
    financials,
    financialsLineItems,
    resData,
    asRptSegData,
    asRptSegments,
    mostRecentDateKey,
    mostRecentLtmKey,
  }
}
export { transformFinancialResponse, transformFinancialDates, transformResData }
